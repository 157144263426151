export const TRANSLATIONS_BA = {
	LandingPage_Title: "Dobro došli na portal Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Odaberite državu/regiju",
	Header_ContactUs: "Obratite nam se",
	Header_Search: "Pretraži",
	Header_Menu: "Izbornik",
	Header_LogInOrCreateYourAccount: "Prijavite se ili izradite korisnički račun",
	Header_LogIn: "Prijava",
	Header_Cart: "Košarica",
	Header_ShoppingCart: "Košarica",
	Header_LogOut: "Odjava",
	Header_ChangeDetails: "Promjena pojedinosti",
	Footer_ExploreOurTraining: "Istražite naše obuke",
	Footer_TrainingAtYourFingertips: "Obuka nadohvat ruke",
	Footer_DontMissAnyStory: "Nemojte propustiti nijednu priču",
	Footer_ExploreOurStories: "Istražite naše priče",
	Footer_GetInTouchWithUs: "Javite nam se",
	Footer_SendUsAMessage: "Pošaljite nam poruku",
	Footer_ToContactForm: "Na obrazac za kontakt",
	Footer_InventedForLife: "Tehnologija za život",
	Footer_GeneralContactInfo: "Općeniti podaci za kontakt",
	Footer_ProductSecurity_Psirt: "Sigurnost proizvoda (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Inovacije, patenti i licencije",
	Footer_PurchasingLogistics: "Nabava i logistika",
	Footer_CorporateInformation: "Informacije o korporaciji",
	Footer_LegalNotice: "Pravna napomena",
	Footer_DataProtectionNotice: "Obavijest o zaštiti podataka",
	Footer_PrivacySettings: "Postavke privatnosti",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, sva prava pridržana",
	Footer_StillLookingForSomething: "I dalje tražite nešto?",
	Footer_TrainingServices: "Usluge obuke",
	Footer_Courses: "Tečajevi",
	Footer_Programs: "Programi",
	Footer_EventsCalendar: "Kalendar događaja",
	Footer_TrainingCenters: "Centri za obuku",
	Footer_Dashboard: "Nadzorna ploča",
	Footer_MyDashboard: "Moja nadzorna ploča",
	Footer_ShoppingCart: "Košarica",
	Footer_YourShoppingCart: "Vaša košarica",
	Footer_Administration: "Administracija",
	Footer_Employees: "Zaposlenici",
	Footer_Wholesalers: "Veletrgovci",
	Footer_Transactions: "Transakcije",
	Footer_Workshops: "Radionice",
	Footer_WorkLikeABoschApplyNow: "Radite  – Prijavite se odmah.",
	Footer_BoschGlobalCareers: "Globalne karijere u Boschu",
	Footer_PurchasingAndLogistics: "Nabava i logistika",
	Footer_BecomeABusinessPartner: "Postanite poslovni partner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Kako se naša obuka razvija da bi pomogla vašem poduzeću",
	Footer_Language: "Jezik",
	MenuPopup_MyCart: "Moja košarica",
	Stories_OurTrainingStories: "Naše priče o obuci",
	Stories_ConnectingExperts: "Povezujemo stručnjake i entuzijaste, sanjare i realizatore, inovatore i utjecajne osobe – istražite i iskusite",
	Stories_TopStories: "Najbolje priče",
	Stories_AtHome: "Kod kuće",
	Stories_Careers: "Karijere",
	Stories_History: "Povijest",
	Stories_IndustryAndTrades: "Industrija i struke",
	Stories_Mobility: "Mobilnost",
	Stories_Research: "Istraživanje",
	Stories_Sustainability: "Održivost",
	Stories_AllStories: "Sve priče",
	Stories_BlogATrainersDay: "BLOG | Dan u životu voditelja obuke",
	Stories_ADayinLifeOfATrainer: "Dan u životu voditelja obuke",
	Stories_BoschServiceTraining: "Obuka za servis društva Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "PRIČA | Pokreće nas zajednička snaga",
	Stories_StoryHowCanWeBenefitYourBusiness: "PRIČA | Kako možemo pomoći vašem poduzeću",
	Stories_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	Stories_AModernTrainingApproach: "Svijet mrežne obuke",
	Stories_StoryAVirtualOnlineTrainingWorld: "PRIČA | Vaše prilike za mrežnu obuku",
	Stories_SubscribeToRSSFeed: "Pretplati se na RSS sadržaj",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "O nama",
	Homepage_AutomotiveAftermarket: "Odjel autoopreme",
	Homepage_GetInTouch: "Javite nam se",
	Homepage_LatestNews: "Otkrijte zašto nas vodi želja za znanjem",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Naša otvorena radna mjesta",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Pokreće nas zajednička snaga",
	BoschServiceTraining_BoschServiceTraining: "Obuka za servis društva Bosch",
	BoschServiceTraining_ShareThisOn: "Podijeli na",
	BoschServiceTraining_TheChallenge: "Izazov: savladati nove tehnologije",
	BoschServiceTraining_WorldwidePresence: "Globalna prisutnost",
	BoschServiceTraining_TechnicalTraining: "Tečajevi za tehničku obuku društva Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Ponude i prilike koje nudimo u društvu Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Nudimo tečajeve za obuku koji su prikladni za sve ciljne skupine: od početnika do profesionalaca. Ponude uključuju jednodnevne i višednevne tečajeve za obuku. Tečajevi za obuku dostupni su za različita tehnička područja i kao serijske obuke (kao što su ubrizgavanje benzina, ubrizgavanje dizela, električni sustavi). U okviru tečajeva za tehničku obuku društvo Bosch prenosi sveobuhvatno znanje i iskustvo o svim standardnim sustavima vozila društva Bosch i ostalih proizvođača (također se obrađuje na tečajevima za obuku). Sadržaj je posebno usklađen sa zahtjevima zaposlenika radionica.",
	BoschServiceTraining_TrainedEmployeesDesc: "Obučeni zaposlenici očita su prednost za radionice za motorna vozila. Zato što današnja vozila postaju sve složenija, a nove tehnologije stalno predstavljaju nove izazove. Zaposlenici radionica mogu učinkovito i isplativo obavljati dijagnostičke radove, održavanje i popravke na aktualnim modelima vozila samo zahvaljujući potrebnoj stručnosti.",
	BoschServiceTraining_RegardlessOfHow: "Bez obzira na sposobnost i iskustvo stručnjaka za motorna vozila uvijek postoje situacije u kojima će se susresti sa sustavima nove tehnologije vozila ili nepoznatim kvarovima. U tom smislu uklanjanje smetnji i rješavanje problema može biti posebno dugotrajno i ne mora nužno dovesti do uspješnih rezultata. U najgorem se slučaju kupci moraju snalaziti bez svojih vozila znatno duže nego što su očekivali.",
	BoschServiceTraining_ItIsPrecisely: "Upravo ovdje Bosch uskače sa svojom obukom za servis kojom se poboljšava kvaliteta servisa i ubrzavaju postupci. Trenutačne obuke društva Bosch pružaju podršku za brže pronalaženje problema, istodobno popravljanje većeg broja vozila i skraćivanje vremena čekanja za vaše klijente. Stoga vam sustavni postupci koje možete usvojiti od društva Bosch jamče brži i produktivniji rad.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Već više od 125 godina Bosch je cijenjeni dobavljač originalnih dijelova opreme, komponenata i sustava za brojne vodeće proizvođače automobila iz cijeloga svijeta. To iskustvo prati jedinstveno znanje o najnovijim tehnologijama na kojem se temelji potpora društva Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Centri za obuku za servis društva Bosch nalaze se u svim dijelovima svijeta: od Španjolske do Turske, Južnoafričke Republike, Australije i Brazila. Bosch svojim zaposlenicima nudi obuku usmjerenu na praksu koja je uvijek ukorak s najnovijim tehnologijama.",
	BoschServiceTraining_AgileCompany: "Agilno društvo",
	BoschServiceTraining_Collaboration: "Suradnja",
	BoschServiceTraining_ConnectedMobility: "Povezana mobilnost",
	BoschServiceTraining_ArtificialIntelligence: "Umjetna inteligencija",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Razgovor mislioca",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Dijagnostička i ispitna oprema",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Osnove tehnologije za motorna vozila",
	BoschServiceTraining_Icon_Gasoline: "Benzin",
	BoschServiceTraining_Icon_Diesel: "Dizel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternativni pogoni",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrotehnika i elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Karoserija i udobnost",
	BoschServiceTraining_Icon_ChassisAndBraking: "Šasija i kočenje",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Prijenos i pogonski sklop",
	BoschServiceTraining_Icon_CommercialVehicles: "Gospodarska vozila",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Internetski tečajevi za obuku",
	BoschServiceTraining_Icon_BusinessSkills: "Poslovne vještine",
	BoschServiceTraining_Icon_Accreditations: "Akreditacije",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programi tečajeva za obuku",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "ImePrezimeVoditeljaObuke",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Dan u životu voditelja obuke",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Svaki je dan pustolovina",
	ADayInTheLifeOfATrainer_Summary: "Sažetak",
	ADayInTheLifeOfATrainer_TheDayJourney: "Tijek dana",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Svakog se dana mnogo radi, ali to je uvijek vrijedno iskustvo. Obuka polaznika sa sobom nosi veliku odgovornost jer znate da drže našu budućnost u svojim rukama, ali istodobno pruža zadovoljstvo i uzbuđenje. Kada vidimo kako polaznici napreduju sa svakom lekcijom iz dana u dan i iz tjedna u tjedan, znamo da imamo zajednički cilj: proširiti njihovo znanje predstavljanjem moderne tehnologije koja vodi u budućnost!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Na kraju svakog dana sjednemo zajedno i osvrnemo se na to što smo taj dan radili. Dani brzo prolaze, ali to je dokaz da to što postižemo nije samo vrijedno iskustvo za polaznike, nego i za nas voditelje obuka.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Na kraju dana možemo otići kući zadovoljni jer smo odradili odličan posao!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Početak",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Priprema i posljednje provjere materijala za obuku. Provjera važne pošte",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Počinje obuka",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Dobro došli, sudioniče. Predstavljanje dnevnog rasporeda. Upoznajte skupinu razumijevanjem dinamike svakog pojedinca.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorija",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Potrebna je određena količina interakcije sa skupinom kako bi obuka bila što zanimljivija. Pobrinite se da postoje zadaci povezani s teorijom kako biste dodatno uključili skupinu",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pauza za ručak",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktični dio",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Obuka se nastavlja u radionici uz nekoliko praktičnih zadataka. Kao voditelj obuke uvijek morate biti prisutni i dostupni kao i uvijek za sve slučajeve i smjernice",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Ponavljanje",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Vratite se u učionicu i izvedite sažetak kako biste razgovarali o obuci toga dana. Odgovorite na sva postavljena pitanja. Počnite pripremu za sljedeće sesije obuke.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Posljednji dan",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Sudionicima se uručuju potvrde o sudjelovanju.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Doma,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Kako možemo pomoći vašem poduzeću",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Neprestano se razvijamo kako bismo bili sigurni da pružamo kvalitetnu i najsuvremeniju obuku",
	BoschTrainingBenefits_TheBoschServiceTraining: "Centar za obuku za servis društva Bosch želi vam pomoći u izgradnji vašeg poduzeća s pomoću svog opsežnog i iznimno prikladnog portfelja tehničkih/poslovnih obuka i tečajeva daljnjeg školovanja s certificiranim kvalifikacijama. Na sljedećoj se stranici nalazi pregled naših programa obuke i trenutačnih prilika za daljnje školovanje. Radujemo se susretu s vama i vašim zaposlenicima na našim tečajevima za obuku i seminarima.",
	BoschTrainingBenefits_LongStandingExperience: "Dugogodišnje iskustvo s opsežnim znanjem o svim sustavima na području automobilske tehnologije.",
	BoschTrainingBenefits_QuickAndEfficient: "Brza i učinkovita rješenja za brojne probleme.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Prednosti i koristi",
	BoschTrainingBenefits_LMS: "Sustav za upravljanje učenjem – Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Sustav Bosch Training Solutions pokriva cjelokupni asortiman automobilskih područja. Specijalizirajte se za određena područja ili proširite svoje vještine uz ponude obuke društva Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Usvojeno znanje omogućuje pružanje usluga popravaka, održavanja i servisiranja.",
	BoschTrainingBenefits_TheHighDegree: "Velik udio praktičnog sadržaja i rad u malim skupinama jamči učenje usmjereno ostvarivanju ciljeva",
	BoschTrainingBenefits_ParticipationInTraining: "Sudjelovanje u obuci povećava motivaciju i zadovoljstvo zaposlenika, a dodatne kvalifikacije privlače kupce",
	BoschTrainingBenefits_IncreasedSales: "Povećana prodaja zahvaljujući znanju o sustavima različitih marki vozila",
	BoschTrainingBenefits_MethodicalProcedures: "Metodički postupci smanjuju vrijeme čekanja za kupce",
	BoschTrainingBenefits_HighlyQualified: "Visokokvalificirani predavači prenose sustavne postupke",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Utvrđivanje rješenja za obuku koje odgovara vama ili vašem poduzeću",
	BoschTrainingBenefits_DirectOnlineBooking: "Izravna mrežna rezervacija i upravljanje tečajevima za vas ili vaše zaposlenike",
	BoschTrainingBenefits_OnlineAccess: "Mrežni pristup obrazovnim materijalima",
	BoschTrainingBenefits_AccessToYourOwn: "Pristup osobnoj povijesti obuke i certifikatima",
	BoschTrainingBenefits_ToolsForManagement: "Alati za upravljanje vlastitim razvojem karijere",
	BoschTrainingBenefits_ManagerHasFull: "Upravitelj ima potpun pregled nad tečajevima svojih zaposlenika unutar sustava",
	BoschTrainingBenefits_Access24hours: "Cjelodnevni pristup svih sedam dana u tjednu",
	ModernTrainingApproach_KnowledgeIsKey: "Znanje je ključni čimbenik uspjeha za svaku radionicu. Zbog toga Bosch nudi sveobuhvatni program stručnog osposobljavanja i razvoja. Njime omogućuje polaznicima da steknu pravo znanje na pravom mjestu u pravo vrijeme, odnosno znanje koje im je potrebno za profesionalno održavanje i popravak vozila kupaca. Bosch je dodao prijenos internetskog medijskog videosadržaja (engl. webcasting) kao novi dio modula paketa za e-učenje koji je već u ponudi. Sastoji se od sesija obuka za servis osmišljenih za internetski prijenos.",
	ModernTrainingApproach_ANewLearning: "Nova prilika za učenje",
	ModernTrainingApproach_YourOnlineTraining: "Vaše prilike za mrežnu obuku",
	ModernTrainingApproach_RecentlyTheWorld: "Svijet se nedavno promijenio; pogledajte kako mi pomažemo",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Svijet mrežne obuke",
	ModernTrainingApproach_NewTeachingMethod: "Nova metoda učenja",
	ModernTrainingApproach_InAdditionTo: "Osim postojećih obuka uživo dodali smo obuke s prijenosom internetskog medijskog videosadržaja uživo u program obuke. Naš voditelj obuke stoji vam na raspolaganju s opsežnim teorijskim znanjem u virtualnoj učionici ili s praktičnim primjerima. Kao i uvijek, dobit ćete mnogo informacija o sustavima vozila i odgovarajućim proizvodima društva Bosch. Naravno da možete i postavljati pitanja. Dobit ćete izravne odgovore na pitanja tijekom prijenosa internetskog medijskog videosadržaja uživo. Prijenosi internetskog medijskog videosadržaja uživo traju između 60 i 120 minuta. Potrebna je stabilna internetska veza. Potražite prijenos internetskog medijskog videosadržaja koji vam odgovara izravno u našem katalogu za obuke tako da unesete „Webcast” (Internetski medijski videosadržaj) polje za pretraživanje.",
	ModernTrainingApproach_BoschWebcastingHeader: "Prijenos internetskog medijskog videosadržaja društva Bosch: Inovativan i praktičan",
	ModernTrainingApproach_BoschWebcastingText: "Da bi polaznik sudjelovao u prijenosu internetskog medijskog videosadržaja društva Bosch, prvo se treba uključiti biranjem broja. Individualni pristupni kod šalje se prije sesije da bi se omogućila prijava. Do 200 sudionika može istodobno sudjelovati s raznih lokacija. Obuku drži voditelj obuke, a pitanja se mogu postavljati u bilo kojem trenutku putem funkcije dopisivanja uživo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitalni dodatak za obuku u učionici",
	ModernTrainingApproach_TheDigitalSupplementText: "Prijenos internetskog medijskog videosadržaja i obuka u učionici savršeno se nadopunjavaju. U obama slučajevima obuku drži kvalificirani voditelj obuke. Prijenosi internetskog medijskog videosadržaja prikladni su za teme koje se mogu prenositi digitalno i ne zahtijevaju rad na vozilu. To je, na primjer, upoznavanje određenih značajki softvera ESI[tronic] novih sustava pomoći vozaču. Obuke uživo još uvijek su prvi izbor kad je riječ o učinkovitom timskom radu u radionici ili praktičnom radu uživo na tehnologiji vozila.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Internetski prijenos može biti jeftiniji",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Troškovi po sudioniku obično su manji kod prijenosa internetskog medijskog videosadržaja nego kod slične obuke u učionici. Razlog se krije u tome što više sudionika može imati koristi od obuke, dok je napor povezan s obukom znatno manji (ne morate putovati na mjesto održavanja obuke pa kući i trošite manje vremena).",
	ModernTrainingApproach_BookAndProfitHeading: "Rezervirajte i profitirajte",
	ModernTrainingApproach_BookAndProfitText: "Prijenosi internetskog medijskog videosadržaja mogu se rezervirati na našem mrežnom mjestu na isti način kao i naši ostali tečajevi za obuku i prilike za učenje. Možete plaćati svaki prijenos internetskog medijskog videosadržaja zasebno ili uzeti godišnju pretplatu za određeni broj prijenosa internetskog medijskog videosadržaja.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Usporedba prijenosa internetskog medijskog videosadržaja i obuke u učionici društva Bosch",
	ModernTrainingApproach_Webcasting: "Prijenos internetskog medijskog videosadržaja",
	ModernTrainingApproach_Classroom: "Učionica",
	ModernTrainingApproach_WebcastingP1: "Može istodobno sudjelovati do 200 sudionika s bilo kojeg mjesta na svijetu",
	ModernTrainingApproach_WebcastingP2: "Niska razina složenosti i relativno niski troškovi te putovanje nije potrebno",
	ModernTrainingApproach_WebcastingP3: "Idealno za specifične i sažete pojedinačne teme",
	ModernTrainingApproach_WebcastingP4: "Radionice mogu držati prijenos internetskog medijskog videosadržaja za svoje zaposlenike bilo gdje i bilo kad",
	ModernTrainingApproach_WebcastingP5: "Između 50 i 70 % manje vremena potrebno nego za obuke u učionici",
	ModernTrainingApproach_ClassroomTrainingP1: "Može se istodobno sastati do 16 sudionika",
	ModernTrainingApproach_ClassroomTrainingP2: "Putni troškovi i općenito veći troškovi po osobi",
	ModernTrainingApproach_ClassroomTrainingP3: "Idealno za optimizaciju suradnje ili učenja izravno na vozilu",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktične vježbe i interakcija s voditeljem obuke",
	ModernTrainingApproach_ClassroomTrainingP5: "Voditelj obuke može bolje prilagoditi gradivo koje će prenijeti sudionicima prema stvarnim potrebama sudionika",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ponude mrežnih obuka (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Danas je samostalno učenje za računalom sastavni dio prijenosa znanja. Sloboda pri odabiru vremena i mjesta, mogućnost neograničenog ponavljanja gradiva, individualna brzina učenja i interaktivni obrazovni sadržaji čine ovu metodu nezaobilaznim obrazovnim alatom.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Svaki element mrežne obuke (engl. web-based training, WBT) pažljivo je strukturiran. Osim što pružaju široku bazu znanja, ti tečajevi mogu poslužiti kao učinkovita priprema za programe obuke u centrima za obuku društva Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtualna simulacija dijagnostike nudi korisniku mogućnosti igranja uloga za svakodnevne situacije u radionici kao što je tijek rada kod postupka rješavanja problema s pomoću dijagnostičkih uređaja društva Bosch u virtualnoj radionici.",
	ModernTrainingApproach_Advantages: "Prednosti",
	ModernTrainingApproach_AdvantagesP1: "Brza i učinkovita metoda prijenosa znanja",
	ModernTrainingApproach_AdvantagesP2: "Raznovrsno učenje",
	ModernTrainingApproach_AdvantagesP3: "Možete upotrebljavati koliko god često želite",
	ModernTrainingApproach_AdvantagesP4: "Uložena minimalna količina vremena i novca",
	ModernTrainingApproach_AdvantagesP5: "Individualna brzina učenja",
	ModernTrainingApproach_AdvantagesP6: "Fleksibilno vrijeme učenja",
	ModernTrainingApproach_AdvantagesP7: "Mogućnost ponavljanja",
	ModernTrainingApproach_AdvantagesP8: "Nema putnih troškova",
	ModernTrainingApproach_AdvantagesP9: "Nema problema s kapacitetom ili kolizija u rasporedu",
	Dashboard_CoursesViewAndManageYourCourses: "TEČAJEVI | Prikaži i upravljaj svojim tečajevima",
	Dashboard_MyCourses: "Moji tečajevi",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM OBUKE | Prikaži svoje programe obuke",
	Dashboard_MyTrainingProgram: "Moj program obuke",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "e-UČENJE | Prikaži i upravljaj svojim portfeljom e-učenja",
	Dashboard_MyELearning: "Moje e-učenje",
	Dashboard_AccountManageYourAccount: "KORISNIČKI RAČUN | Upravljaj svojim korisničkim računom",
	Dashboard_MyAccount: "Moj korisnički račun",
	Dashboard_MyDashboard: "Moja nadzorna ploča",
	Dashboard_ManageYourAccount: "Upravljaj svojim korisničkim računom",
	Dashboard_Workshops: "Radionice",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACIJA | Upravljaj svojim radionicama",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACIJA | Upravljaj svojim timovima",
	Dashboard_Administration: "Administracija",
	Dashboard_ViewYourCourses: "Prikaži svoje tečajeve",
	Dashboard_ViewYourTrainingPrograms: "Prikaži svoje programe obuke",
	Dashboard_TrainingPrograms: "Programi obuke",
	Dashboard_ViewAndEditYourDetails: "Prikaži i uredi svoje pojedinosti",
	Dashboard_ViewYourELearningCourses: "Prikaži svoje tečajeve e-učenja",
	Dashboard_Pending: "Na čekanju",
	Dashboard_RequiresAdministratorSignOff: "(potrebna je potvrda administratora)",
	Dashboard_HistoricalRecords: "Povijesni zapisnik",
	Dashboard_Registered: "Registrirano",
	Dashboard_WaitingList: "Lista čekanja",
	Dashboard_RecommendedForYou: "Preporučeno za vas",
	Dashboard_AccountDetails: "Pojedinosti o korisničkom računu",
	Dashboard_ChangeProfileImage: "promijeni profilnu sliku",
	Dashboard_Title: "Titula",
	Dashboard_Role: "Uloga",
	Dashboard_FirstName: "Ime",
	Dashboard_LastName: "Prezime",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobilni telefon",
	Dashboard_Email: "E-adresa",
	Dashboard_Language: "Jezik",
	Dashboard_Save: "Spremi",
	Dashboard_Student: "Polaznik",
	Dashboard_WorkshopTrainingAdministrator: "Administrator obuke za radionicu",
	Dashboard_WholesalerTrainingAdministrator: "Administrator obuke za veletrgovca",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administracija društva",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Upravljaj radionicama...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Upravljaj zaposlenicima...",
	Dashboard_CompanyAdministration_Workshop: "Radionica",
	Dashboard_CompanyAdministration_Address: "Adresa",
	Dashboard_CompanyAdministration_Zip: "Poštanski broj",
	Dashboard_CompanyAdministration_City: "Grad",
	Dashboard_CompanyAdministration_Manage: "Upravljaj",
	Dashboard_AdministrationSection_AdministrationTitle: "Administracija",
	Dashboard_AdministrationSection_ManageTheTeam: "Upravljaj timom",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACIJA | Upravljaj zaposlenicima",
	Dashboard_AdministrationSection_Employees: "Zaposlenici",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACIJA | Upravljaj veletrgovcima",
	Dashboard_AdministrationSection_Wholesalers: "Veletrgovci",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACIJA | Upravljaj transakcijama",
	Dashboard_AdministrationSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administracija zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Upravljaj zaposlenicima...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registriraj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Zapisnik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Uredi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Zaposlenik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Dodaj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Izradi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrirano",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Na čekanju",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(potrebna je potvrda administratora)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Povijesni zapisnik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista čekanja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Popis trenutačnih zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Upravljanje zaposlenicima",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Ime",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Korisnički račun zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Prikaži i uredi pojedinosti zaposlenika...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Postavke",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Dopusti osobni korisnički račun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Nadogradnja administracije radionica za zaposlenike posredničkog poslužitelja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Dopusti zaposleniku registraciju na događaje obuke",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Pošalji pozivnicu putem e-pošte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktiviraj korisnički račun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Spremi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Pošalji",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Uredi",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Upravljanje veletrgovcima",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Upravljaj svojim veletrgovcima...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Popis odobrenih veletrgovaca",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Ime",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Izbriši",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Izradi",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Dodaj",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Izrada novog korisničkog računa za zaposlenika",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Izradi pojedinosti za nove zaposlenike...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Dodaj novog administratora odobrenih veletrgovaca",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Izradi pojedinosti za nove veletrgovce...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Odaberi odobrene veletrgovce iz padajućeg izbornika...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Prikaži i upravljaj svojim transakcijama...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Natrag",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Broj računa",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Opis",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Način plaćanja",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Iznos",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Preuzmi",
	TrainingCentres_TrainingCentres: "Centri za obuku",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za obuku nalaze se diljem svijeta",
	TrainingCentres_CountryCategory: "Kategorija države",
	TrainingCentres_CityCategory: "Kategorija grada",
	TrainingCentres_Country: "Država",
	TrainingCentres_City: "Grad",
	TrainingCatalogue_TrainingCatalogue: "Katalog tečajeva za obuku",
	TrainingCatalogue_LearningAtTheHighestLevel: "Usvajanje znanja na najvišoj razini",
	TrainingCatalogue_StartDate: "Početni datum",
	TrainingCatalogue_EndDate: "Završni datum",
	TrainingCatalogue_TrainingName: "Naziv obuke",
	TrainingCatalogue_Language: "Jezik",
	TrainingCatalogue_Days: "Broj dana",
	TrainingCatalogue_Seats: "Broj mjesta",
	TrainingCatalogue_Price: "Cijena",
	TrainingCatalogue_Location: "Lokacija",
	TrainingCatalogue_PartNumber: "Broj dijela",
	TrainingCatalogue_TrainingCourse: "Tečaj za obuku",
	TrainingCatalogue_Category: "Kategorija",
	TrainingCatalogue_TrainingCategory: "Kategorija obuke",
	TrainingCatalogue_CourseName: "Naziv tečaja",
	TrainingCatalogue_TotalDays: "Ukupni broj dana",
	TrainingCatalogue_Venue: "Mjesto",
	TrainingCatalogue_Details: "Pojedinosti",
	TrainingCatalogue_Prerequisites: "Preduvjeti",
	TrainingCatalogue_CourseType: "Vrsta tečaja",
	TrainingCatalogue_LearningMethod: "Metoda učenja",
	TrainingCatalogue_AddToWaitingList: "Dodaj na listu čekanja",
	ShoppingCart_ShoppingCart: "Košarica",
	ShoppingCart_ReviewAndManage: "Pregledaj i upravljaj svojim pretplatama",
	ShoppingCart_YouHaveAddedItem: "U košaricu ste dodali sljedeće artikle",
	ShoppingCart_Item: "Artikl",
	ShoppingCart_Quantity: "Količina",
	ShoppingCart_PricePerItem: "Cijena po artiklu",
	ShoppingCart_TotalPrice: "Ukupna cijena",
	ShoppingCart_VatIsIncluded: "Bez PDV-a //s ili bez?",
	ShoppingCart_Redeem: "Ako nemate kredita, kliknite ovdje za otkup",
	ShoppingCart_Purchase: "Ako nemate kredita, kliknite ovdje za kupnju",
	ShoppingCart_Checkout: "Potvrda kupnje",
	ShoppingCart_ContinueShopping: "Nastavi s kupnjom",
	ShoppingCart_TotalPriceInclVat: "Ukupna cijena s PDV-om",
	ShoppingCart_Apply: "Primijeni",
	ShoppingCart_ShoppingCartEmpty: "Vaša je košarica prazna",
	ShoppingCart_VatIncluded: "S PDV-om",
	ShoppingCart_ManageYourShopping: "Upravljaj svojom kupnjom",
	ShoppingCart_Participant: "Sudionik",
	ShoppingCart_Complete: "Dovrši",
	ShoppingCart_NetPrice: "Neto cijena",
	ShoppingCart_Workshop: "Radionica",
	CheckOut_PreviewYourOrder: "Pregledaj svoju narudžbu",
	CheckOut_YourShoppingCart: "Košarica",
	CheckOut_Preview: "Pregled",
	CheckOut_Payment: "Plaćanje",
	CheckOut_Summary: "Sažetak",
	CheckOut_Back: "Natrag",
	CheckOut_PleaseReadAndAgreeOurTerms: "Pročitajte i prihvatite naše odredbe i uvjete",
	CheckOut_IHaveReadTheTermsAndCondition: "Pročitao/pročitala sam [uvjete] te ih razumijem i prihvaćam",
	CheckOut_YesIAgree: "Da, slažem se s odredbama i uvjetima",
	CheckOut_Continue: "Nastavi",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Čestitamo! Primili smo vašu narudžbu",
	CheckOut_OrderConfirmation: "Primit ćete konačnu potvrdu narudžbe kad se vaš zahtjev uspješno obradi",
	CheckOut_WeWillContactYouDirectly: "Obratit ćemo vam se izravno ako imamo pitanja u vezi s vašim zahtjevom",
	CheckOut_AddToCart: "Dodaj u košaricu",
	CheckOut_CreditCart: "Kreditna košarica",
	CheckOut_DirectDebit: "Izravno zaduženje",
	CheckOut_TrainingCredits: "Krediti za obuku",
	CheckOut_PreferredWholesaler: "Preferirani veletrgovac",
	EventCalendar_EventCalendar: "Kalendar događaja",
	EventCalendar_TrainingEventsForAllYourNeeds: "Događaji za obuku za sve vaše potrebe",
	TrainingCourse_TrainingCourse: "Katalog tečajeva za obuku",
	TrainingCourse_LearningAtTheHighestLevel: "Usvajanje znanja na najvišoj razini",
	TrainingProgram_Title: "Katalog programa obuke",
	TrainingProgram_SubTitle: "Usvajanje znanja na najvišoj razini",
	AccountDeactivationModal_Title: "Jeste li sigurni da želite deaktivirati ovaj korisnički račun?",
	AccountDeactivationModal_Line1: "Deaktivacija ovog korisničkog računa trajna je i njome će se ukloniti sav sadržaj, uključujući usluge, zahtjeve, transakcije i stanja računa, članove tima i postavke profila.",
	AccountDeactivationModal_Line2Part1: "Ako je unutar aktivnog razdoblja pretplate, pogledajte naša pravila otkazivanja u našim",
	AccountDeactivationModal_Line2Part2: "odredbama i uvjetima",
	AccountDeactivationModal_Line2Part3: "prije podnošenja zahtjeva.",
	AccountDeactivationModal_Line3: "Jeste li sigurni da želite podnijeti zahtjev za deaktivaciju korisničkog računa?",
	AccountDeactivationModal_Cancel: "Odustani",
	AccountDeactivationModal_Send: "Pošalji",
	CurrentUserAccountDeactivationModal_Title: "Jeste li sigurni da želite deaktivirati svoj korisnički račun?",
	CurrentUserAccountDeactivationModal_Line1: "Deaktivacija vašeg korisničkog računa trajna je i njome će se ukloniti sav sadržaj, uključujući usluge, zahtjeve, transakcije i stanja računa, članove tima i postavke profila.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Ako ste unutar aktivnog razdoblja pretplate, pogledajte naša pravila otkazivanja u našim",
	CurrentUserAccountDeactivationModal_Line3: "Jeste li sigurni da želite podnijeti zahtjev za deaktivaciju svojeg korisničkog računa?",
	CurrentUserAccountDeactivationModal_Yes: "Da",
	CurrentUserAccountDeactivationModal_No: "Ne",
	CourseWithdrawalModal_Title: "Otkazivanje tečaja",
	CourseWithdrawalModal_Line1: "Jeste li sigurni da se želite povući iz tečaja?",
	CourseWithdrawalModal_Line2Part1: "Povlačenja podliježu naknadama za otkazivanje i naplaćuju se u skladu s",
	CourseWithdrawalModal_Line2Part2Link: "odredbama i uvjetima",
	CourseWithdrawalModal_Line2Part3: "naše usluge.",
	CourseWithdrawalModal_Yes: "Da, povuci se",
	CourseWithdrawalModal_No: "Ne, otkaži",
	Settings_Settings: "Postavke",
	Settings_ManageYourSettings: "Upravljaj postavkama",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Moji identifikacijski podaci za prijavu u sustav Bosch Global",
	Settings_Edit: "Uredi",
	Settings_CompanyDetails: "Pojedinosti o društvu",
	Settings_RequestAccountDeletion: "Podnesi zahtjev za brisanje korisničkog računa",
	Settings_AccountDeletionText: "Ako izbrišete korisnički račun, izbrisat će se svi vaši podaci (uključujući adresu i podatke o plaćanju) i više neće biti dostupni.",
	Settings_DeleteAccountRequestConfirmation: "Potvrda zahtjeva za brisanje korisničkog računa",
	Settings_DeleteAccountRequestConfirmationText: "Primili smo vaš zahtjev za brisanje korisničkog računa, stoga ćemo vam se obratiti putem e-pošte ili telefona za konačnu potvrdu",
	RecommendedHotels_RecommendedHotels: "Preporučeni hoteli",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za obuku nalaze se diljem svijeta",
	Error_Common: "Došlo je do pogreške!",
	Error_404: "Stranica koju ste posjetili ne postoji",
	Error_401: "Nemate pristup ovoj stranici",
	Error_500: "Poslužitelj javlja pogrešku",
	Dashboard_Courses_Table_Course: "Tečaj",
	Dashboard_Courses_Table_Date: "Datum [Dani]",
	Dashboard_Courses_Table_Venue: "Mjesto",
	Dashboard_Courses_Table_TrainingType: "Vrsta obuke",
	Dashboard_Courses_Table_Action: "Radnja",
	Dashboard_Courses_Table_TasksToComplete: "Morate izvršiti sljedeće zadatke",
	Dashboard_Courses_Table_Withdraw: "Povuci se",
	Dashboard_Courses_Table_Remove: "Ukloni",
	Dashboard_Courses_Table_View: "Prikaži",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Nema zapisa za prikaz",
	Dashboard_Courses_Table_CompletionDate: "Datum završetka",
	Dashboard_Courses_Table_Actions_Assignments: "Zadaci",
	Dashboard_Courses_Table_Actions_Certification: "Certifikati",
	Dashboard_Courses_Table_Actions_Evaluations: "Ocjenjivanja",
	Dashboard_Courses_Table_Actions_Assessments: "Procjene",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Dodaj u kalendar",
	Dashboard_Courses_Table_CourseDetails_Task: "Zadatak",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Rok",
	ContactUs_Headline: "Obratite nam se",
	ContactUs_HowCanWeHelp: "Kako vam možemo pomoći?",
	ContactUs_EmailTab: "E-adresa",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Pismo",
	ContactUs_Phone_Headline: "Nazovite nas!",
	ContactUs_Phone_Description: "Radujemo se vašem pozivu i rado ćemo vam pomoći. Dostupni smo za vas 24 sata dnevno, 7 dana tjedno.",
	ContactUs_Phone_PhoneNumber: "Upotrijebite kontaktni obrazac kako biste nam poslali poruku.",
	ContactUs_Phone_ChargeNotice: "Naknade se razlikuju ovisno o vašem pružatelju usluga i državi.",
	ContactUs_Letter_Headline: "Pošaljite pismo!",
	ContactUs_Letter_Description: "Imate li pitanja, zahtjeve ili prijedloge? Radujemo se vašem upitu.",
	ContactUs_Email_Headline: "Pošaljite e-poštu!",
	ContactUs_Email_Description: "Pošaljite nam poruku tako da ispunite obrazac u nastavku. Jedan od naših savjetnika rado će vam se javiti.",
	ContactUs_Email_InputName: "Ime*",
	ContactUs_Email_InputCompany: "Društvo",
	ContactUs_Email_InputCountry: "Država*",
	ContactUs_Email_InputEmail: "E-adresa*",
	ContactUs_Email_InputMessage: "Poruka*",
	ContactUs_Email_DataProtection: "Obavijest o zaštiti podataka*",
	ContactUs_Email_DataProtectionConfirm: "Pročitao/la sam pravila o zaštiti privatnosti.*",
	ContactUs_Email_SecurityCheck: "Sigurnosna provjera",
	ContactUs_Email_SecurityCodeConfirm: "Unesite ispravne znakove koje vidite u gornjem okviru.",
	ContactUs_Email_SecurityCodeInvalid: "Odgovor na tekst „captcha” koji ste unijeli nije ispravan.",
	ContactUs_Email_MandatoryNotice: "Ispunite obvezna polja.",
	ContactUs_Email_SuccessMessage: "Vaša je poruka poslana",
	ContactUs_Email_TakenNoteDataProtection1: "Pročitao/la sam",
	ContactUs_Email_TakenNoteDataProtection2: "Pravila o zaštiti privatnosti.*",
	ContactUs_Email_SubmitButton: "Predaj",
	ContactUs_Email_FieldErrorMessage: "Ispunite obvezna polja.",
	ContactUs_Email_SuccessModal_OK: "U redu",
	AccountInReviewModal_Title: "Vaš korisnički račun trenutačno se pregledava!",
	AccountInReviewModal_Paragraph1: "Hvala vam za registraciju osobnog računa na sustavu Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Naše predano osoblje za obuku trenutačno pregledava i konfigurira vaš korisnički račun. Konačnu ćete potvrdu primiti poštom nakon što vaš korisnički račun bude uspješno konfiguriran.",
	AccountInReviewModal_Paragraph3: "U međuvremenu možete slobodno pregledavati našu mrežnu stranicu uz ograničen pristup.",
	AccountInReviewModal_Paragraph4: "Ako imate bilo kakvih pitanja ili ako se vaš korisnički račun ne obradi u roku od 48 sati, ne računajući vikende i praznike, možete nam se obratiti",
	AccountInReviewModal_ContactUsLink: "ovdje",
	AccountInReviewModal_OKButton: "U redu, pusti me unutra!",
	Header_YesButton: "Da",
	Header_NoButton: "Ne",
	Header_LogOutText: "Želite li se odjaviti?",
	Footer_EnquiryText: "Radujemo se vašem upitu,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Odredbe i uvjeti",
	Footer_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	FooterSearch_SearchFieldPlaceholder: "Pretraživanje",
	TrainingCatalogue_Action: "Radnja",
	TrainingCatalogue_Duration: "Trajanje",
	TrainingCatalogue_Information: "Informacije",
	Settings_PersonalDetails: "Osobni podaci",
	Reset_All_Filters: "Ponovno postavi sve filtre",
	EventTable_Days: "Broj dana",
	SearchField_AllResults: "Svi rezultati",
	TrainingCentresLayout_ViewDetails: "Prikaz pojedinosti",
	Dropdown_Placeholder: "Odaberite...",
	WaitlistPopup_SelectButtonText: "Odaberi",
	WaitlistPopup_StartDate: "Početni datum",
	WaitlistPopup_EndDate: "Završni datum",
	WaitlistPopup_Language: "Jezik",
	WaitlistPopup_City: "Grad",
	WaitlistPopup_Workshop: "Radionica",
	WaitlistPopup_Student: "Polaznik",
	WaitlistPopup_PreferredLocation: "Preferirana lokacija",
	WaitlistPopup_SignUpCourseWaitingList: "Prijavi se na listu čekanja za tečaj",
	WaitlistPopup_SignUpWithoutSelectingDate: "Prijavi se na listu čekanja bez odabira datuma",
	WaitlistPopup_GeneralWaitList: "Opća lista čekanja",
	WatinglistPopup_CourseLabel: "Tečaj",
	Common_ContinueButton: "Nastavi",
	UserAccountDeactivationModal_ConfirmLabel: "Potvrdi",
	Filter_FilterLabel: "Odaberi",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Već ste na listi čekanja, želite li ukloniti postojeću registraciju i izraditi novu?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Da, ukloni",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ne, otkaži",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Otkazivanje tečaja",
	WaitinglistPopup_SignUpButtonText: "Prijava",
	MyAccount_FirstNameRequied: "Potrebno je ime",
	MyAccount_LastNameRequired: "Potrebno je prezime",
	MyAccount_RoleRequired: "Potrebna je uloga",
	MyAccount_MobileRequired: "Potreban je mobilni telefon",
	MyAccount_EmailRequired: "Potrebna je adresa e-pošte",
	MyAccount_LanguageRequired: "Potreban je jezik",
	MyAccount_UpdateSuccessMessage: "Pojedinosti o vašem korisničkom računu uspješno su ažurirane!",
	MyAccount_UpdateSuccessModal_ButtonText: "Da, zatvori",
	Infor_CorporateInformation_Header: "Korporacijske informacije",
	Infor_LegalNotice_Header: "Pravna napomena",
	Infor_PrivacyPolicy_Header: "Obavijest o zaštiti podataka (Pravila o zaštiti privatnosti)",
	CreateEmployeeAccountModification_FormValidation_Role: "Potrebna je uloga",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Potrebno je ime",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Potrebno je prezime",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Potreban je mobilni telefon",
	CreateEmployeeAccountModification_FormValidation_Email: "Potrebna je adresa e-pošte",
	CreateEmployeeAccountModification_FormValidation_Language: "Potreban je jezik",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Poziv za pridruživanje sustavu Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Uspješno ste dodali novog člana u svoj tim!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Želite li da s vašim novim članom tima podijelimo pozivnicu putem e-pošte s uputama kako mogu izraditi vlastiti osobni račun za sustav Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ne",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Da, pošalji pozivnicu",
	ShoppingCart_Venue_Fee: "Naknada za mjesto",
	ShoppingCart_Trainning_Name: "Naziv obuke",
	ShoppingCart_Available_Credits: "Dostupni bodovi",
	ConfirmItsMeModal_Hi: "Bok!",
	ConfirmItsMeModal_Description1: "Uspješno ste ispunili pozivnicu koju je podijelio administrator vašeg društva za izradu vlastitog osobnog računa za sustav Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Prije nastavka potvrdite ili ažurirajte podatke o svojem korisničkom računu.",
	ConfirmItsMeModal_Description3: "Nakon potvrde morat ćete se ponovno prijaviti kako biste dovršili izradu korisničkog računa.",
	ConfirmItsMeModal_CompanyDetails: "Pojedinosti o društvu",
	ConfirmItsMeModal_YourDetails: "Vaše pojedinosti",
	ConfirmItsMeModal_YourDetails_Title: "Naslov",
	ConfirmItsMeModal_YourDetails_Function: "Funkcija",
	ConfirmItsMeModal_YourDetails_Name: "Ime",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobilni telefon",
	ConfirmItsMeModal_YourDetails_Email: "E-adresa",
	ConfirmItsMeModal_YourDetails_Language: "Jezik",
	ConfirmItsMeModal_ConfirmButton: "Potvrdi",
	SelectWholesalerModal_WholesalerGroupLabel: "Veleprodajni trgovac",
	SelectWholesalerModal_WholesalerBranchLabel: "Trgovina",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Odaberite",
	ApplyButton: "Primijeni",
	SelectWholesalerModal_Title: "Izaberite svojeg veleprodajnog trgovca",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Želite li ukloniti svoju registraciju?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Vaša je registracija uspješno uklonjena!",
	WaitlistPopup_AddToWaitListSuccess: "Vaša je registracija uspješno dodana!",
	ErrorCommon_Header: "GREŠKA",
	CommonButton_Close: "Zatvori",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Za nastavak zatvorite ovaj prozor!",
	EmbeddedVideoPlayer_StartButtonText: "Početak",
	EmbeddedVideoPlayer_RefresherButtonText: "Obnavljanje naučenog",
	DeactivateWholesalerModal_ConfirmMessage: "Jeste li sigurni da želite ukloniti ovog veleprodajnog trgovca?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Uspješno slanje pozivnice e-poštom!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Ponovno pozovi",
	ContactUs_Mobile_HR: "+38598 429693",
	ContactUs_Address_HR: "Robert Bosch d.o.o., Auto oprema, 10040 Zagreb, Hrvatska",
	ConfirmItsMeModal_CompanyDetails_Name: "Naziv društva",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresa",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Poštanski broj društva",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-adresa",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Uspješno povlačenje iz tečaja!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Izrada korisničkog računa nije uspjela. Korisnički račun već postoji!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Ažuriranje korisničkog računa nije uspjelo. Korisnički račun već postoji!",
	Footer_ExploreTrainingStories: "Istražite sve naše priče o obuci",
	Breadcrumb_Home: "Početna stranica",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Priče",
	Breadcrumb_Stories_BoschServiceTraining: "Boscheva obuka za servis",
	Breadcrumb_Stories_ADayInLife: "Dan u životu voditelja obuke",
	Breadcrumb_Stories_BoschTrainingBenefits: "Prednosti Boscheve obuke",
	Breadcrumb_Stories_TrainingApproach: "Moderan pristup obuci",
	Breadcrumb_Settings: "Postavke",
	Breadcrumb_ShoppingCart: "Košarica",
	Breadcrumb_Checkout: "Naplata",
	Breadcrumb_OrderSummary: "Sažetak narudžbe",
	Breadcrumb_Exception: "Iznimka",
	Breadcrumb_Dashboard: "Nadzorna ploča",
	Breadcrumb_MyCourses: "Moji tečajevi",
	Breadcrumb_MyELearning: "Moje e-učenje",
	Breadcrumb_CompanyAdministration: "Administracija društva",
	Breadcrumb_EmployeeManagement: "Upravljanje zaposlenicima",
	Breadcrumb_EmployeeAccount: "Korisnički račun zaposlenika",
	Breadcrumb_EmployeeCreation: "Izrada zaposlenika",
	Breadcrumb_Administration: "Administracija",
	Breadcrumb_EmployeeAdministration: "Administracija zaposlenika",
	Breadcrumb_WholesalerManagement: "Upravljanje veletrgovcima",
	Breadcrumb_NewWholesalerAccountCreation: "Izrada novog korisničkog računa veletrgovca",
	Breadcrumb_Transactions: "Transakcije",
	Breadcrumb_MyAccount: "Moj korisnički račun",
	Breadcrumb_TrainingCentres: "Centri za obuku",
	Breadcrumb_EventCalendar: "Kalendar događaja",
	Breadcrumb_TrainingCourseCatalogue: "Katalog tečajeva za obuku",
	Breadcrumb_RecommendedHotels: "Preporučeni hoteli",
	Breadcrumb_CorporateInformation: "Informacije o korporaciji",
	Breadcrumb_LegalNotice: "Pravna napomena",
	Breadcrumb_DataProtectionNotice: "Obavijest o zaštiti podataka",
	Breadcrumb_PrivacyPolicy: "Pravila privatnosti",
	Breadcrumb_TermCondition: "Odredbe i uvjeti",
	Breadcrumb_Terms: "Uvjeti",
	Breadcrumb_PrivacyStatement: "Izjava o privatnosti",
	Breadcrumb_Cookies: "Kolačići",
	Breadcrumb_Provider: "Pružatelj",
	Breadcrumb_RegisterAccount: "Registriraj korisnički račun",
	Stories_ExploreMore: "Istraži više",
	CheckOutLayout_ShoppingCartHeader: "Košarica",
	CheckOutLayout_AddedItemCartMessage: "U košaricu ste dodali sljedeće artikle",
	CheckOutLayout_CheckYourCartMessage: "Provjerite košaricu",
	ModernTrainingApproach_DateText: "28. 2. 2022.",
	ADayInLifeofATrainer_DateText: "28. 2. 2022.",
	BoschTrainingBenefits_DateText: "28. 2. 2022.",
	BoschServiceTraining_DateText: "28. 2. 2022.",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Pretraži zaposlenike",
	CheckOutLayout_OrderWasNotPlaced_Message: "Žao nam je! Vaša narudžba nije izvršena",
	CheckOutLayout_RefusalReason: "Razlog odbijanja",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Plaćanje nije uspjelo. Pokušajte ponovno s drugim načinom plaćanja",
	CheckOutLayout_SubmittingApiFailed_Message: "Podnošenje API-ja nije uspjelo",
	CartItem_ChooseWorkshop_Message: "Odaberite radionicu kako biste odabrali sudionika",
	CartItem_ChooseParticipant_Message: "Odaberite sudionika za nastavak",
	CompanyAdministrationTable_SearchBox_Placeholder: "Pretraži radionice",
	CartItem_RemoveCartItem_ConfirmText: "Želite li ukloniti ovaj artikl iz košarice?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkcija",
	AddToCartSuccessModal_Message: "Vaša je košarica ažurirana!",
	AddToCartSuccessModal_ContinueButtonText: "Nastavi s kupnjom",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Moja košarica",
	AddVoucher_Header: "Dodaj kupon",
	AddVoucher_InputPlaceholder: "Unesi kôd i primijeni ga",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Nažalost, trenutačno nemamo zakazanih događaja. Prijavite se na listu čekanja pa ćemo vas obavijestiti kada događaj postane dostupan",
	ADYEN_ERR_2_: "Transakcija je odbijena.",
	ADYEN_ERR_3_: "Pogreška u upućivanju",
	ADYEN_ERR_4_: "Transakcija nije obrađena zbog greške na strani primatelja.",
	ADYEN_ERR_5_: "Kartica koja je upotrijebljena za transakciju je blokirana, dakle nije upotrebljiva.",
	ADYEN_ERR_6_: "Kartica koja je upotrijebljena za transakciju je istekla. Dakle, nije upotrebljiva.",
	ADYEN_ERR_7_: "Tijekom postupka transakcije došlo je do neusklađenosti iznosa.",
	ADYEN_ERR_8_: "Navedeni broj kartice netočan je ili nevaljan.",
	ADYEN_ERR_9_: "Nije moguće stupiti u kontakt s bankom kupca radi autorizacije transakcije.",
	ADYEN_ERR_10_: "Banka kupca ne podržava ili ne dopušta ovu vrstu transakcije.",
	ADYEN_ERR_11_: "Provjera autentičnosti s pomoću protokola 3D Secure nije izvršena ili nije izvršena uspješno.",
	ADYEN_ERR_12_: "Na kartici nema dovoljno novca za pokriće iznosa koji treba platiti. Taj problem može nastati zbog ograničenja mjesečnog iznosa za plaćanje na kartici. Pokušajte upotrijebiti drugu karticu, npr. MasterCard, ili se obratite svojoj banci kako biste ponovno postavili mjesečno ograničenje na kartici.",
	ADYEN_ERR_14_: "Moguća prijevara.",
	ADYEN_ERR_15_: "Transakcija je otkazana.",
	ADYEN_ERR_16_: "Kupac je otkazao transakciju prije nego što se izvršila.",
	ADYEN_ERR_17_: "Navedeni PIN netočan je ili nevaljan.",
	ADYEN_ERR_18_: "Kupac je unio netočan PIN više od tri puta zaredom.",
	ADYEN_ERR_19_: "Nije moguće potvrditi navedeni PIN.",
	ADYEN_ERR_20_: "Moguća prijevara.",
	ADYEN_ERR_21_: "Transakcija nije ispravno predana na obradu.",
	ADYEN_ERR_22_: "Provjera rizika označila je transakciju kao prijevaru (ocjena rizika >= 100); operacija je stoga otkazana.",
	ADYEN_ERR_23_: "Sljedeći kodovi odbijanja povezani su s ovim razlogom odbijanja:  Transakcija nije dopuštena izdavatelju / vlasniku kartice",
	ADYEN_ERR_24_: "Navedeni CVC (sigurnosni kȏd kartice) nije valjan.",
	ADYEN_ERR_25_: "Sljedeći kodovi odbijanja povezani su s ovim razlogom odbijanja:  Kartica nije valjana u ovoj državi",
	ADYEN_ERR_26_: "R1: Opoziv naloga za autorizaciju / „R3: Opoziv svih naloga za autorizaciju” / „R0: Zaustavi nalog za plaćanje”",
	ADYEN_ERR_27_: "Ovaj je odgovor povezan sa svim kodovima odgovora koji se ne mogu pouzdano povezati. Na taj je način lakše razlikovati opće odbijanje (naprimjer, Mastercard „05: Nemoj prihvatiti” odgovor) od konkretnijih odbijanja.",
	ADYEN_ERR_28_: "Prekoračen je dopušteni iznos za isplatu na kartici kupca.",
	ADYEN_ERR_29_: "Prekoračen je broj dopuštenih isplata na kartici kupca.",
	ADYEN_ERR_31_: "Izdavatelj je prijavio da za transakciju postoji sumnja na prijevaru.",
	ADYEN_ERR_32_: "Podaci o adresi koje je kupac unio su netočni.",
	ADYEN_ERR_33_: "Banka kupca zahtijeva da kupac unese mrežni PIN.",
	ADYEN_ERR_34_: "Banka kupca zahtijeva čekovni račun za dovršetak kupnje.",
	ADYEN_ERR_35_: "Banka kupca zahtijeva štedni račun za dovršetak kupnje.",
	ADYEN_ERR_36_: "Banka kupca zahtijeva da kupac unese mobilni PIN.",
	ADYEN_ERR_37_: "Kupac je odustao od transakcije nakon što je pokušao beskontaktno platiti i od njega se tražilo da isproba drugi način unosa kartice (PIN ili provlačenje).",
	ADYEN_ERR_38_: "Izdavatelj je odbio zahtjev za izuzeće od provjere autentičnosti i zahtijeva provjeru autentičnosti za transakciju. Pokušajte ponovno s protokolom 3D Secure.",
	ADYEN_ERR_39_: "Izdavatelj ili shema nisu mogli priopćiti ishod putem paketa RReq",
	ConfirmGeolocationModal_ConfirmButton: "Nastaviti",
	ConfirmGeolocationModal_CancelButton: "Promijeniti",
	ConfirmGeolocationModal_CurrentCountrySelection: "Vaš trenutni odabir zemlje je:",
	ConfirmGeolocationModal_AskChangeCountry: "Želite li ostati na ovoj stranici ili promijeniti svoju državu?",
	Addtocart_Modal_AddToCartFailTitle: "Dodavanje sudionika nije uspjelo",
	Addtocart_Modal_AlreadyInCart: "Sudionik je već registriran za ovaj događaj",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Politika otkazivanja",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Odustani",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Nastavi"
};