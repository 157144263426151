import { Card, CardContent, CardGroup, Label } from "semantic-ui-react";
import "./FooterCards.css";
import { FC, Fragment } from "react";
import { useTranslation } from 'react-i18next';

const FooterCardStories: FC = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <CardGroup centered style={{ margin: "5%" }}>
                <Card className="footer-card">
                    <CardContent className="footer-card-content">
                        <Label className="footer-card-label">
                            {t('Footer_DontMissAnyStory')}!
                        </Label>
                        <Label className="footer-card-label-link" size="medium">
                            <a
                                style={{ display: "flex" }}
                                href="/contacts"
                            >
                                {t("Stories_SubscribeToRSSFeed")}
                                
                                    <i className="external alternate icon" style={{marginLeft:"4px"}}></i>
                            </a>
                        </Label>

                    </CardContent>
                </Card>
            </CardGroup>
        </Fragment>
    )
}


export default FooterCardStories;