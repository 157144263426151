import { FC } from "react";
import { Icon, Button } from "semantic-ui-react";
import "./FooterAdditionalLinks.css";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as TwitterX } from "../../assets/svg/twitter_x.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/linkedin.svg";
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg";
import { ReactComponent as Up } from "../../assets/svg/up.svg";
import { ReactComponent as ExternalLink } from "../../assets/svg/externallink.svg";
import LanguageSelectorDropDown from "../LanguageSelector/LanguageSelectorDropDown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectCountryCode,
  selectLanguage,
} from "../../redux-toolkit/authReducer";

const FooterAdditionalLinks: FC = () => {
  const { t } = useTranslation();
  const currentCountryCode = useSelector(selectCountryCode);
  const selectedLanguage = useSelector(selectLanguage);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  const termAndConditionCode = () => {
    if (!!selectedLanguage && selectedLanguage.trim() !== "") {
      return selectedLanguage.toLowerCase().trim();
    }
    return "en";
  };

  return (
    <div className="o-footer">
      <div className="grid-responsive-width">
        <div className="o-footer__top">
          <div className="o-footer__claim">{t("Footer_InventedForLife")}</div>
          {!window.location.href.includes("/regions") && (
            <div className="m-language-selector">
              {/* <a
                className="a-default"
                href={RedirectHelper.getDefaultClientRoot() + "/regions"}
              >
                <Icon className="a-icon">
                  <Globe />
                </Icon>
                <span>{t("Footer_Language")}</span>
              </a> */}

              <LanguageSelectorDropDown cssClassName="footer-language-selector" />
            </div>
          )}
          <ul className="o-footer__links">
            <li>
              <div className="a-link a-link--simple">
                <a href="https://www.bosch.com/contact/">
                  {t("Footer_GeneralContactInfo")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple -external">
                <a href="https://psirt.bosch.com/">
                  {t("FooterAdditionalLinks_Navigation_ProductSecurity")}{" "}
                  <Icon>
                    {" "}
                    <ExternalLink />{" "}
                  </Icon>{" "}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple">
                <a href="https://www.bosch.com/licenses-and-patents/">
                  {t("FooterAdditionalLinks_Navigation_Patents")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple -external">
                <a href="https://www.bosch.com/company/supply-chain/">
                  {t("FooterAdditionalLinks_Navigation_Logistics")}
                </a>
              </div>
            </li>
          </ul>
          {/* <div className="o-footer__share">
            <Button
              as="a"
              basic
              icon={
                <Icon>
                  <Facebook />
                </Icon>
              }
              onClick={() =>
                openLinkInNewTab("https://www.facebook.com/BoschGlobal")
              }
            ></Button>
            <Button
              as="a"
              basic
              icon={
                <Icon>
                  <Youtube />
                </Icon>
              }
              onClick={() =>
                openLinkInNewTab("https://www.youtube.com/BoschGlobal")
              }
            ></Button>
            <Button
              as="a"
              basic
              icon={
                <Icon>
                  <Twitter />
                </Icon>
              }
              onClick={() =>
                openLinkInNewTab("https://www.twitter.com/BoschGlobal")
              }
            ></Button>
            <Button
              as="a"
              basic
              icon={
                <Icon>
                  <Linkedin />
                </Icon>
              }
              onClick={() =>
                openLinkInNewTab("https://www.linkedin.com/company/bosch/")
              }
            ></Button>
            <Button
              as="a"
              basic
              icon={
                <Icon>
                  <Instagram />
                </Icon>
              }
              onClick={() =>
                openLinkInNewTab("https://www.instagram.com/boschglobal/")
              }
            ></Button>
          </div> */}
          <div className="o-footer__share">
            <Button
              as="a"
              href="https://www.facebook.com/BoschGlobal"
              basic
              icon={
                <Icon>
                  <Facebook />
                </Icon>
              }
            ></Button>
            <Button
              as="a"
              href="https://www.youtube.com/BoschGlobal"
              basic
              icon={
                <Icon>
                  <Youtube />
                </Icon>
              }
            ></Button>
            <Button
              as="a"
              href="https://www.twitter.com/BoschGlobal"
              basic
              icon={
                <Icon>
                  <TwitterX />
                </Icon>
              }
            ></Button>
            <Button
              as="a"
              href="https://www.linkedin.com/company/bosch/"
              basic
              icon={
                <Icon>
                  <Linkedin />
                </Icon>
              }
            ></Button>
            <Button
              as="a"
              href="https://www.instagram.com/boschglobal/"
              basic
              icon={
                <Icon>
                  <Instagram />
                </Icon>
              }
            ></Button>
          </div>
        </div>
        <div className="a-divider" />
        <div className="o-footer__bottom">
          <ul className="o-footer__links">
            <li>
              <div className="a-link a-link--simple">
                <a href="/data-protection-notice/0">
                  {t("Footer_CorporateInformation")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple">
                <a href="/data-protection-notice/1">
                  {t("Footer_LegalNotice")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple">
                <a href="/data-protection-notice/2">
                  {t("Footer_DataProtectionNotice")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple">
                <a href={`/term-condition/${termAndConditionCode()}`}>
                  {t("Footer_TermsAndConditions")}
                </a>
              </div>
            </li>
            <li>
              <div className="a-link a-link--simple">
                <a href="javascript:BoschPrivacy.show()">
                  {t("Footer_PrivacySettings")}
                </a>
              </div>
            </li>
          </ul>
          <div className="o-footer__copyright">
            {t("FooterAdditionalLinks_Copyright")}
          </div>
          {/* <div className="o-footer__copyright">
            <Icon>
              <Copyright />
            </Icon>{" "}
            {t("Footer_RobertBoschGmbh2021_AllRightsReserved")}
          </div> */}
          <Button
            className="o-footer__back-to-top-btn"
            basic
            onClick={scrollToTop}
            icon={
              <Icon>
                <Up />
              </Icon>
            }
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default FooterAdditionalLinks;
