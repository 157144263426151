export const TRANSLATIONS_HU_HU = {
	LandingPage_Title: "Üdvözli Önt a Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Ország kiválasztása",
	Header_ContactUs: "Kapcsolat",
	Header_Search: "Keresés",
	Header_Menu: "Menü",
	Header_LogInOrCreateYourAccount: "Jelentkezzen be vagy regisztráljon",
	Header_LogIn: "Bejelentkezés",
	Header_Cart: "Kosár",
	Header_ShoppingCart: "Bevásárló kosár",
	Header_LogOut: "Kijelentkezés",
	Header_ChangeDetails: "Adatmódosítás",
	Footer_ExploreOurTraining: "Fedezze fel képzéseinket",
	Footer_TrainingAtYourFingertips: "Képzés, ami karnyújtásra van Öntől",
	Footer_DontMissAnyStory: "Ne hagyjon ki egy történetet sem",
	Footer_ExploreOurStories: "Fedezze fel történeteinket",
	Footer_GetInTouchWithUs: "Lépjen velünk kapcsolatba",
	Footer_SendUsAMessage: "Üzenjen nekünk",
	Footer_ToContactForm: "Kapcsolatfelvételi űrlap",
	Footer_InventedForLife: "Életre tervezve",
	Footer_GeneralContactInfo: "Általános elérhetőség",
	Footer_ProductSecurity_Psirt: "Termék biztonság (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovációk, szabadalmak és licenszek",
	Footer_PurchasingLogistics: "Vásárlás & logisztika",
	Footer_CorporateInformation: "Impresszum",
	Footer_LegalNotice: "Jogi nyilatkozat",
	Footer_DataProtectionNotice: "Adatvédelmi nyilatkozat",
	Footer_PrivacySettings: "Adatvédelmi beállítások",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, minden jog fenntartva",
	Footer_StillLookingForSomething: "Még mindig keres valamit",
	Footer_TrainingServices: "Oktatás Szolgáltatások",
	Footer_Courses: "Tanfolyamok",
	Footer_Programs: "Programok",
	Footer_EventsCalendar: "Eseménynaptár",
	Footer_TrainingCenters: "Oktatási Központok",
	Footer_Dashboard: "Irányítópult",
	Footer_MyDashboard: "Irányítópultom",
	Footer_ShoppingCart: "Bevásárló kosár",
	Footer_YourShoppingCart: "Bevásárló kosara",
	Footer_Administration: "Adminisztráció",
	Footer_Employees: "Munkavállalók",
	Footer_Wholesalers: "Nagykereskedők",
	Footer_Transactions: "Tranzakciók",
	Footer_Workshops: "Műhelyek",
	Footer_WorkLikeABoschApplyNow: "Dolgozz #LikeABosch - Jelentkezz most.",
	Footer_BoschGlobalCareers: "Bosch Globális Karrierek",
	Footer_PurchasingAndLogistics: "Vásárlás és Logisztika",
	Footer_BecomeABusinessPartner: "Legyen üzleti partnerünk!",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Képzéseinket folyamatosan fejlesztjük annak érdekében, hogy Ön is fejleszthesse vállalkozását",
	Footer_Language: "Ország kiválasztása",
	MenuPopup_MyCart: "Kosaram",
	Stories_OurTrainingStories: "Képzési történeteink",
	Stories_ConnectingExperts: "Szakértők és rajongók, álmodozók és cselekvők, újítók és befolyásolók összekapcsolása – fedezze fel és tapasztalja meg!",
	Stories_TopStories: "Top történetek",
	Stories_AtHome: "Otthon",
	Stories_Careers: "Karrierek",
	Stories_History: "Történet",
	Stories_IndustryAndTrades: "Ipar és kereskedelem",
	Stories_Mobility: "Mobilitás",
	Stories_Research: "Kutatás",
	Stories_Sustainability: "Fenntarthatóság",
	Stories_AllStories: "Minden történet",
	Stories_BlogATrainersDay: "BLOG Egy oktató napja",
	Stories_ADayinLifeOfATrainer: "Egy nap egy oktató életében",
	Stories_BoschServiceTraining: "Bosch Szervizképzések",
	Stories_StoryWhatDrivesYouDrivesUs: "TÖRTÉNET Ami Önt hajtja, az hajt minket!",
	Stories_StoryHowCanWeBenefitYourBusiness: "TÖRTÉNET  Hogyan segíthetünk vállalkozásának",
	Stories_HowOurTrainingEvolves: "Hogyan fejlődnek képzéseink",
	Stories_AModernTrainingApproach: "Az online képzés világa",
	Stories_StoryAVirtualOnlineTrainingWorld: "TÖRTÉNET Online képzési lehetőségei",
	Stories_SubscribeToRSSFeed: "Iratkozzon fel az RSS-re",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Rólunk",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Kapcsolatfelvétel",
	Homepage_LatestNews: "Tudja meg, miért hajt bennünket a tudásszomj",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Nyitott pozícióink",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Ami téged hajt, az hajt minket",
	BoschServiceTraining_BoschServiceTraining: "Bosch szervizképzés",
	BoschServiceTraining_ShareThisOn: "Ossza meg",
	BoschServiceTraining_TheChallenge: "A Kihívás: Új technológiák elsajátítása",
	BoschServiceTraining_WorldwidePresence: "Jelenlét világszerte",
	BoschServiceTraining_TechnicalTraining: "Műszaki tanfolyamok a Bosch-tól",
	BoschServiceTraining_TheOffersAndOpportunities: "A Bosch által kínált ajánlatok és lehetőségek",
	BoschServiceTraining_TechnicalCoursesDesc: "Minden célcsoport számára – akár kezdő, akár profi – vannak megfelelő tanfolyamok. A kínálat egy- és több napos képzéseket is tartalmaz. A tanfolyamok különböző műszaki területeken és képzési sorozatokban zajlanak (például benzinbefecskendezésű, dízelbefecskendezésű, elektromos rendszerek). A műszaki képzéseken a Bosch széleskörű szaktudást ad át az összes szabványos járműrendszerhez, illetve más gyártók Bosch rendszerei szintén szóba kerülnek a képzéseken. A tartalom kifejezetten a műhely dolgozóinak igényeihez igazodik.",
	BoschServiceTraining_TrainedEmployeesDesc: "A képzett munkavállalók nyilvánvaló előnyt jelentenek az autószervizek számára. Mert a mai járművek egyre összetettebbek, és az új technológiák folyamatosan új kihívásokat jelentenek. Csak a szükséges szakértelem teszi lehetővé, hogy a műhely mukavállalói hatékonyan és költséghatékonyan végezzenek diagnosztikai, karbantartási és javítási munkákat az aktuális járműmodelleken.",
	BoschServiceTraining_RegardlessOfHow: "Függetlenül attól, hogy mennyire jó és tapasztalt autóipari szakemberek – mindig vannak olyan helyzetek, amikor új járműtechnikai rendszerekkel vagy ismeretlen hibákkal kerülnek kapcsolatba. Ebben a tekintetben a hibaelhárítás és problémamegoldás különösen időigényessé válhat, vagy esetleg nem jár sikerrel. A legrosszabb esetben az ügyfeleknek a vártnál lényegesen tovább kell nélkülözniük járművüket.",
	BoschServiceTraining_ItIsPrecisely: "A Bosch éppen ezen a ponton valósítja meg szervizképzését: javítja a szolgáltatás minőségét és gyorsítja a folyamatokat. A Bosch jelenlegi képzése segítséget nyújt a problémák gyorsabb lokalizálásához, több jármű egyidejű javításához, valamint ügyfelei várakozási idejének csökkentéséhez. Így a Bosch-nál tanult szisztematikus eljárások gyorsabb és produktívabb munkát biztosítanak.",
	BoschServiceTraining_WorldwidePresenceDesc1: "A Bosch több mint 125 éve a világ számos vezető autógyártója számára az eredeti berendezések alkatrészeinek és rendszereinek preferált szállítója. Ez a tapasztalat a legújabb technológiák egyedülálló tudásával jár, amely a Bosch támogatás alapjául szolgál.",
	BoschServiceTraining_WorldwidePresenceDesc2: "A Bosch Service Oktató Központok világszerte megtalálhatók: Spanyolországtól Törökországig, Dél-Afriától, Ausztráliától egészen Brazíliáig – A Bosch gyakorlatorientált képzést kínál munkavállalóinak, amely mindig összhangban van a legmodernebb technológiákkal.",
	BoschServiceTraining_AgileCompany: "Agilis Vállalat",
	BoschServiceTraining_Collaboration: "Együttműködés",
	BoschServiceTraining_ConnectedMobility: "Csatlakoztatott Mobilitás",
	BoschServiceTraining_ArtificialIntelligence: "Mesterséges Intelligencia",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Párbeszéd tapasztalt vezetőkkel",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnosztikai és Tesztberendezések",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Autóipari technológiai alapok",
	BoschServiceTraining_Icon_Gasoline: "Benzinbefecskendezés",
	BoschServiceTraining_Icon_Diesel: "Dízelbefecskendezés",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternatív hajtási rendszerek",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektromos és elektronikai rendszerek",
	BoschServiceTraining_Icon_BodyAndComfort: "Karosszéria- és komfortelektronika",
	BoschServiceTraining_Icon_ChassisAndBraking: "Gépjármű fék és mechanika",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Sebességváltó és hajtáslánc",
	BoschServiceTraining_Icon_CommercialVehicles: "Haszongépjármű",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online Képzési Tanfolyomaok",
	BoschServiceTraining_Icon_BusinessSkills: "Üzleti készségek",
	BoschServiceTraining_Icon_Accreditations: "Akkreditációk",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Képzési tanfolyam programok",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "OktatóKeresztnévVezetéknév",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Egy oktató napja",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Mindennap egy kaland",
	ADayInTheLifeOfATrainer_Summary: "Összefoglaló",
	ADayInTheLifeOfATrainer_TheDayJourney: "Egy nap utazása",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Minden nap egy mozgalmas nap, de mindig egy hasznos élmény. Az öröm, hogy a tanulókat abban a tudatban képezzük, hogy náluk van a jövőnk kulcsa, felelősségteljes, de egyben izgalmas is. A tanulók napi és heti rendszerességgel történő növekedése azt bizonyítja számunkra, hogy egy közös cél érdekében dolgozunk, tudásuk fejlesztésén, miközben beépítjük a jövőt hajtó modern technológiát!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Minden egyes nap végén leülünk és átgondoljuk az aznapi munkát. Nagyon gyorsan telnek a napok, de ez már önmagában is bizonyítja, hogy amit elérünk, az nemcsak a tanulóknak, hanem nekünk, oktatóknak is kifizetődő.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "A nap végén elégedetten mehetünk haza, hogy remek munkát végeztünk!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Kezdés",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "A képzési anyag előkészítése és végső ellenőrzése. Fontos levelek/E-mailek ellenőrzése",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Képzés kezdés",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Résztvevők üdvözlése. Napirend bemutatása. Csapattal való ismerkedés megértve a résztvevők dinamikáját.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Elmélet",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Bizonyos mértékű interakcióra van szükség a csoporttól ahhoz, hogy a képzés a lehető legérdekesebb legyen. Biztosítani kell azt, hogy az elmélettel összefonódó feladatok legyenek, a csoport aktív együttműködése érdekében",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Ebédszünet",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Gyakorlat",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "A képzés a műhelyben folytatódik néhány gyakorlati feladattal. Oktatóként mindig követelmény, hogy jelen legyünk és rendelkezésre álljunk minden eshetőségre és útmutatásra",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Összefoglalás",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Visszatérés az osztályterembe, az aznapi képzés átbeszélésére. Válaszolni minden felmerülő kérdésre. Felkészülés megkezdése a következő tanfolyamokra.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Utolsó nap",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "A résztvevők részvételi igazolást kapnak.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Otthon",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Hogyan segíthetünk vállalkozása javára",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Hogyan fejlődnek képzéseink",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Folyamatosan fejlődünk, hogy minőségi, naprakész képzést biztosítsunk",
	BoschTrainingBenefits_TheBoschServiceTraining: "A Bosch Service Oktató Központ széleskörű és rendkívül releváns műszaki/üzleti képzési és továbbképzési portfóliójával, tanúsított képesítésekkel kíván segítséget nyújtani Önnek vállalkozása fejlesztésében. A következő oldalakon áttekintést nyújtunk képzési programunkról, valamint aktuális továbbképzési lehetőségeinkről. Szeretettel várjuk Önt és munkatársait képzéseinken és szemináriumainkon.",
	BoschTrainingBenefits_LongStandingExperience: "Sokéves tapasztalat és széleskörű szaktudás az összes rendszerről és az autótechnika területén egyaránt.",
	BoschTrainingBenefits_QuickAndEfficient: "Gyors és hatékony megoldások számos problémára.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Előnyök és hasznok",
	BoschTrainingBenefits_LMS: "Oktatásitányítási rendszer - Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "A Bosch Training Solutions az autóipari területek teljes skáláját lefedi. Szakosodjon meghatározott területekre, vagy bővítse kompetenciáját a Bosch képzési ajánlataival.",
	BoschTrainingBenefits_AcquiredKnowledge: "A megszerzett ismeretek megkönnyítik a javítási, karbantartási és szervizmunkákat",
	BoschTrainingBenefits_TheHighDegree: "A gyakorlati tartalom magas aránya és a kis csoportok garantálják a célorientált tanulást",
	BoschTrainingBenefits_ParticipationInTraining: "A képzésben való részvétel növeli az alkalmazottak motivációját és elégedettségét, a kiegészítő képesítések pedig meggyőzőek az ügyfelek részére",
	BoschTrainingBenefits_IncreasedSales: "Az eladások növelése a különböző autógyártói rendszerek ismerete révén",
	BoschTrainingBenefits_MethodicalProcedures: "A módszeres megközelítés lecsökkenti az ügyfelek várakozási idejét",
	BoschTrainingBenefits_HighlyQualified: "A magasan képzett oktatók szisztematikus eljárásokat közvetítenek",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Az Ön vagy vállalkozása számára megfelelő képzési megoldás azonosítása",
	BoschTrainingBenefits_DirectOnlineBooking: "Tanfolyamok közvetlen online foglalása és menedzselése akár saját maga, akár alkalmazottai számára",
	BoschTrainingBenefits_OnlineAccess: "Online hozzáférés a tanulási dokumentációhoz",
	BoschTrainingBenefits_AccessToYourOwn: "Hozzáférés saját személyes képzéstörténetéhez és bizonyítványaihoz",
	BoschTrainingBenefits_ToolsForManagement: "Eszközök a saját szakmai karrier fejlesztésének irányításához",
	BoschTrainingBenefits_ManagerHasFull: "A műhelyvezető teljes áttekintést kap a munkavállalók tanfolyamairól a rendszeren belül",
	BoschTrainingBenefits_Access24hours: "24 órás elérhetőség a hét minden napján",
	ModernTrainingApproach_KnowledgeIsKey: "A tudás minden műhely sikerének kulcstényezője. Ezért a Bosch átfogó szakmai képzési fejlesztési programot kínál. Ez lehetővé teszi a tanulók számára, hogy a megfelelő időben és helyen pontosan a megfelelő tudást sajátítsák el – olyan ismereteket, amelyek szükségesek ahhoz, hogy az ügyfél járművét szakszerűen karbantartsák és javítsák. A Bosch a már kínált e-learning csomag új modul részeként hozzáadta a webcasting szolgáltatást. Melyek kifejezetten streamelésre tervezett tanfolyami képzésekből állnak.",
	ModernTrainingApproach_ANewLearning: "Egy új tanulási lehetőség",
	ModernTrainingApproach_YourOnlineTraining: "Online képzési lehetőségeid",
	ModernTrainingApproach_RecentlyTheWorld: "Megváltozott a világ, nézd meg, mit teszünk azért, hogy segítsünk",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Az online képzés világa",
	ModernTrainingApproach_NewTeachingMethod: "Új oktatási módszer",
	ModernTrainingApproach_InAdditionTo: "A meglévő személyes oktatásokon kívül élő webcast képzést is beiktattunk a képzési programba. Oktatónk széleskörű elméleti tudással a virtuális tanteremben, vagy gyakorlati példákkal áll az Ön rendelkezésére. Szokás szerint sok információt kap a járműrendszerekről és a megfelelő Bosch termékekről. Természetesen lehetőséged van kérdéseket feltenni. Ezekre a kérdésekre közvetlenül az élő webközvetítésben kapunk választ. Az élő webközvetítések 60-120 percig tartanak. Stabil internetkapcsolat szükséges. Keressen megfelelő webcastot közvetlenül a képzési katalógusunkban a \Webcast\ beírásával a keresőmezőbe.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch webcasting: Innovatív és praktikus",
	ModernTrainingApproach_BoschWebcastingText: "A Bosch webcastban való részvételhez először a résztvevőnek be kell jelentkeznie. A munkamenet előtt egyéni hozzáférési kód kerül elküldésre a bejelentkezés engedélyezéséhez. Egyszerre akár 200 résztvevő is részt vehet, tetszőleges számú helyről. A képzést oktató vezeti, kérdéseket pedig az élő chat funkción keresztül bármikor fel lehet tenni.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "A tantermi képzés digitális kiegészítése",
	ModernTrainingApproach_TheDigitalSupplementText: "A webcast és a tantermi képzés tökéletesen kiegészíti egymást. Mindkét esetben szakképzett oktató vezeti a képzést. A webcastok alkalmasak olyan témákra, amelyek digitálisan továbbíthatók anélkül, hogy a járművön kellene dolgozni. Például egy új vezetőtámogató rendszer bizonyos ESI[tronic] funkcióinak megismerése. Ha hatékony csapatmunkáról van szó a műhelyben vagy gyakorlati munkáról a járműtechnológián, továbbra is a személyes képzés az első választás.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "A streamelés olcsóbb lehet",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Az egy résztvevőre jutó költségek általában alacsonyabbak webcasting esetén, mint egy hasonló tantermi tanfolyamnál. Ennek az az oka, hogy több résztvevő profitálhat belőle, és a képzéssel járó erőfeszítés lényegesen alacsonyabb (nincs utazás a helyszínre és vissza, és kevesebb idő szükséges).",
	ModernTrainingApproach_BookAndProfitHeading: "Foglalj és profitálj",
	ModernTrainingApproach_BookAndProfitText: "Weboldalunkon webcastok is foglalhatók, csakúgy, mint a többi képzési és tanulási ajánlatunk. Választhat, hogy vagy egyéni díjat fizet minden egyes webcast után, vagy éves előfizetést köt bizonyos számú webcastra.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "A Bosch Webcasting és a tantermi képzés összehasonlítása",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Tanterem",
	ModernTrainingApproach_WebcastingP1: "Egyszerre akár 200 résztvevő is részt vehet a világ bármely pontjáról",
	ModernTrainingApproach_WebcastingP2: "Alacsony összetettség és viszonylag alacsony költségek, nincs szükség utazásra",
	ModernTrainingApproach_WebcastingP3: "Ideális egyes konkrét és tömör témákhoz",
	ModernTrainingApproach_WebcastingP4: "A műhelyek bárhol és bármikor futtathatnak webcastot munkatársaiknak",
	ModernTrainingApproach_WebcastingP5: "50-70%-kal kevesebb idő szükséges, mint a tantermi képzésekhez",
	ModernTrainingApproach_ClassroomTrainingP1: "Egyszerre legfeljebb 16 résztvevő találkozik",
	ModernTrainingApproach_ClassroomTrainingP2: "Utazási költségek és általánosságban magasabb költségek személyenként",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideális az együttműködés optimalizálásához vagy közvetlenül a járművön való tanuláshoz",
	ModernTrainingApproach_ClassroomTrainingP4: "Gyakorlati feladatok és megbeszélés lehetősége az oktatóval",
	ModernTrainingApproach_ClassroomTrainingP5: "Az oktató itt jobban tudja adaptálni a tanítandó ismereteket a résztvevők tényleges igényeihez",
	ModernTrainingApproach_OnlineTrainingOfferings: "Online képzési kínálat (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Ma a tudásátadás szerves részét képezi az önálló számítógépes tanulás. Az idő és a hely, a korlátlan ismételhetőség, az egyéni tanulási sebesség és az interaktív tanulási tartalmak megválasztásának szabadásga nélkülözhetetlen tanulási eszközzé teszik ezt a módszert.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Minden Web-Alapú Képzési (WBT) elemet gondosan strukturáltak. Amellett, hogy széles tudásbázist biztosítanak, ezek a kurzusok hatékony felkészítésként is szolgálhatnak a Bosch oktató központjaiban folyó képzési programokra.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "A virtuális diagnosztikai szimuláció a felhasználói szerepjáték lehetőségét kínálja olyan napi műhelyhelyzetekben, mint például a Bosch diagnosztikai eszközöket használó hibaelhárítási eljárás munkafolyamata egy virtuális műhelyben.",
	ModernTrainingApproach_Advantages: "Előnyök",
	ModernTrainingApproach_AdvantagesP1: "Tudásátadás gyors és hatékony módja",
	ModernTrainingApproach_AdvantagesP2: "Változatos tanulás",
	ModernTrainingApproach_AdvantagesP3: "Tetszőleges gyakorisággal használható",
	ModernTrainingApproach_AdvantagesP4: "Minimális idő és pénz",
	ModernTrainingApproach_AdvantagesP5: "Egyéni tanulási sebesség",
	ModernTrainingApproach_AdvantagesP6: "Rugalmas tanulási idő",
	ModernTrainingApproach_AdvantagesP7: "Ismételhetőség",
	ModernTrainingApproach_AdvantagesP8: "Nincs utazási költség",
	ModernTrainingApproach_AdvantagesP9: "Nincs kapacitás vagy menetrend ütközés",
	Dashboard_CoursesViewAndManageYourCourses: "TANFOLYAMOK I Tanfolyamai megtekintése és kezelése",
	Dashboard_MyCourses: "Tanfolyamaim",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "KÉPZÉSI PROGRAM I Képzési programjainak megtekintése",
	Dashboard_MyTrainingProgram: "Képzési programom",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING eLearning portfóliójának megtekintése és kezelése",
	Dashboard_MyELearning: "eLearning-em",
	Dashboard_AccountManageYourAccount: "FIÓK I Fiókja kezelése",
	Dashboard_MyAccount: "Fiókom",
	Dashboard_MyDashboard: "Irányítópult",
	Dashboard_ManageYourAccount: "Fiókom kezelése",
	Dashboard_Workshops: "Műhelyek",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISZTRÁCIÓ I Kezelje műhelyét",
	Dashboard_AdministrationManageYourTeams: "ADMINISZTRÁCIÓ I Kezelje csapatát",
	Dashboard_Administration: "Adminisztrárció",
	Dashboard_ViewYourCourses: "Tanfolyamaid megtekintése",
	Dashboard_ViewYourTrainingPrograms: "Képzési programjaid megtekintése",
	Dashboard_TrainingPrograms: "Képzési programok",
	Dashboard_ViewAndEditYourDetails: "Adataid megtekintése és szerkesztése",
	Dashboard_ViewYourELearningCourses: "eLearning kurzusaid megtekintése",
	Dashboard_Pending: "Függőben",
	Dashboard_RequiresAdministratorSignOff: "(Adminisztrátori kijelentkezés szükséges)",
	Dashboard_HistoricalRecords: "Előzmények",
	Dashboard_Registered: "Regisztrált",
	Dashboard_WaitingList: "Várólista",
	Dashboard_RecommendedForYou: "Neked javasolt",
	Dashboard_AccountDetails: "Fiók adatok",
	Dashboard_ChangeProfileImage: "profilkép megváltoztatása",
	Dashboard_Title: "Megnevezés",
	Dashboard_Role: "Funkció",
	Dashboard_FirstName: "Keresztnév",
	Dashboard_LastName: "Vezetéknév",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobil",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Nyelv",
	Dashboard_Save: "Mentés",
	Dashboard_Student: "Tanuló",
	Dashboard_WorkshopTrainingAdministrator: "Műhely Képzés Adminisztrátor",
	Dashboard_WholesalerTrainingAdministrator: "Nagykereskedői Képzés Adminisztrátor",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Cég adminisztráció",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Kezelje műhelyét…",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Kezelje alkalmazottait…",
	Dashboard_CompanyAdministration_Workshop: "Műhely",
	Dashboard_CompanyAdministration_Address: "Cím",
	Dashboard_CompanyAdministration_Zip: "Irányítószám",
	Dashboard_CompanyAdministration_City: "Város",
	Dashboard_CompanyAdministration_Manage: "Kezelés",
	Dashboard_AdministrationSection_AdministrationTitle: "Adminisztráció",
	Dashboard_AdministrationSection_ManageTheTeam: "Csapat kezelése",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISZTRÁCIÓ I Kezelje munkavállalóit",
	Dashboard_AdministrationSection_Employees: "Munkavállalók",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISZTRÁCIÓ I Kezelje nagykereskedőit",
	Dashboard_AdministrationSection_Wholesalers: "Nagykereskedők",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISZTRÁCIÓ I Kezelje tranzakcióit",
	Dashboard_AdministrationSection_Transactions: "Tranzakciók",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Munkavállalói adminisztráció",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Kezelje munkavállalóit…",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Regisztráció",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Jegyzetek",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Szerkesztés",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Munkavállaló",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Új hozzáadása",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Létrehozás",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Regisztrált",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Függő",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(Adminisztrátori kijelentkezés szükséges)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Előzmények",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Várólista",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Jelenlegi munkavállaló lista",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Munkavállaló menedzsment",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Név",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Munkavállalói fiók",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Munkavállalói adatok megtekintése és szerkesztése…",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Beállítások",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Személyes Bosch Automotive Training Solutions fiók engedélyezése",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Frissítse a munkavállalót cég adminisztrátorrá",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Képzés regisztráció engedélyezése",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Email meghívó küldése",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Fiók Deaktiválása",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Mentés",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Meghívás",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Szerkesztés",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Nagykereskedő menedzsment",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Kezelje nagykereskedőit",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Jóváhagyott nagykereskedői lista",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Név",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Eltávolítás",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Létrehozás",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Hozzáadás",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Új munkavállalói fiók létrehozása",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Adatok létrehozása az új munkavállalók számára",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Új jóváhagyott nagykereskedői adminisztrátor hozzáadása",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Adatok létrehozása az új nagykereskedők számára",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Válasszon ki jóváhagyott nagykereskedőt a legördülő menüből…",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Tranzakciók",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Tranzakcióim megtekintése és kezelése",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Vissza",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Számlaszám",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Leírás",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Fizetési mód",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Dátum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Összeg",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Letöltés",
	TrainingCentres_TrainingCentres: "Oktató Központok",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Oktató központjaink az egész világon megtalálhatók",
	TrainingCentres_CountryCategory: "Ország kategória",
	TrainingCentres_CityCategory: "Város kategória",
	TrainingCentres_Country: "Ország",
	TrainingCentres_City: "Város",
	TrainingCatalogue_TrainingCatalogue: "Képzési tanfolyam katalógus",
	TrainingCatalogue_LearningAtTheHighestLevel: "Tanulás a legmagasabb szinten",
	TrainingCatalogue_StartDate: "Kezdés időpontja",
	TrainingCatalogue_EndDate: "Befejezés időpontja",
	TrainingCatalogue_TrainingName: "Képzés megnevezése",
	TrainingCatalogue_Language: "Nyelv",
	TrainingCatalogue_Days: "Napok",
	TrainingCatalogue_Seats: "Szabad helyek",
	TrainingCatalogue_Price: "Ár",
	TrainingCatalogue_Location: "Helyszín",
	TrainingCatalogue_PartNumber: "Cikkszám",
	TrainingCatalogue_TrainingCourse: "Képzési tanfolyam",
	TrainingCatalogue_Category: "Kategória",
	TrainingCatalogue_TrainingCategory: "Képzési kategória",
	TrainingCatalogue_CourseName: "Tanfolyam megnevezése",
	TrainingCatalogue_TotalDays: "Összes nap",
	TrainingCatalogue_Venue: "Helyszín",
	TrainingCatalogue_Details: "Részletek",
	TrainingCatalogue_Prerequisites: "Előfeltételek",
	TrainingCatalogue_CourseType: "Tanfolyam típusa",
	TrainingCatalogue_LearningMethod: "Tanulási módszer",
	TrainingCatalogue_AddToWaitingList: "Hozzáadás a várólistához",
	ShoppingCart_ShoppingCart: "Bevásárló kosár",
	ShoppingCart_ReviewAndManage: "Nézze meg és kezelje feliratkozásait",
	ShoppingCart_YouHaveAddedItem: "A következő tételeket adta hozzá kosarához",
	ShoppingCart_Item: "Tétel",
	ShoppingCart_Quantity: "Mennyiség",
	ShoppingCart_PricePerItem: "Ár tételenként",
	ShoppingCart_TotalPrice: "Végösszeg",
	ShoppingCart_VatIsIncluded: "Áfa nélkül",
	ShoppingCart_Redeem: "Ha nincs kreditje, kattintson ide a beváltásért",
	ShoppingCart_Purchase: "Ha nincs kreditje, kattintson ide a vásárláshoz",
	ShoppingCart_Checkout: "Pénztár",
	ShoppingCart_ContinueShopping: "Vásárlás folytatása",
	ShoppingCart_TotalPriceInclVat: "Végösszeg Áfával",
	ShoppingCart_Apply: "Jelentkezés",
	ShoppingCart_ShoppingCartEmpty: "Bevásárló kosara üres",
	ShoppingCart_VatIncluded: "ÁFA(tartalmazza)",
	ShoppingCart_ManageYourShopping: "Kezelje vásárlását",
	ShoppingCart_Participant: "Résztvevő",
	ShoppingCart_Complete: "Befejez",
	ShoppingCart_NetPrice: "Nettó ár",
	ShoppingCart_Workshop: "Műhely",
	CheckOut_PreviewYourOrder: "Tekintse meg rendelését",
	CheckOut_YourShoppingCart: "Kosár",
	CheckOut_Preview: "Előnézet",
	CheckOut_Payment: "Fizetés",
	CheckOut_Summary: "Összefoglaló",
	CheckOut_Back: "Vissza",
	CheckOut_PleaseReadAndAgreeOurTerms: "Kérjük, olvassa el és fogadja el felhasználói feltételeinket",
	CheckOut_IHaveReadTheTermsAndCondition: "Elolvastam [ÁSZF], megértettem és elfogadom azokat",
	CheckOut_YesIAgree: "Igen - Elfogadom a felhasználói feltételeket",
	CheckOut_Continue: "Folytatás",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Gratulálunk! Megkaptuk rendelését",
	CheckOut_OrderConfirmation: "Rendelésének sikeres feldolgozását követően megkapja a végleges megrendelés visszaigazolását",
	CheckOut_WeWillContactYouDirectly: "Közvetlenül felvesszük Önnel a kapcsolatot, ha bármilyen kérdésünk van a rendelésével kapcsolatban",
	CheckOut_AddToCart: "Kosárba",
	CheckOut_CreditCart: "Kredit kosár",
	CheckOut_DirectDebit: "Közvetlen terhelés",
	CheckOut_TrainingCredits: "Képzési kreditek",
	CheckOut_PreferredWholesaler: "Preferált nagykereskedők",
	EventCalendar_EventCalendar: "Eseménynaptár",
	EventCalendar_TrainingEventsForAllYourNeeds: "Képzési események minden igényt kielégítve",
	TrainingCourse_TrainingCourse: "Képzési tanfolyam katalógus",
	TrainingCourse_LearningAtTheHighestLevel: "Tanulás a legmagasabb szinten",
	TrainingProgram_Title: "Képzési program katalógus",
	TrainingProgram_SubTitle: "Tanulás a legmagasabb szinten",
	AccountDeactivationModal_Title: "Biztosan deaktiválja a fiókot?",
	AccountDeactivationModal_Line1: "A fiók deaktiválása végleges, és eltávolítja az összes tartalmat, beleértve a tranzakciókat, az előzményeket, a csapattagokat és a profilbeállításokat.",
	AccountDeactivationModal_Line2Part1: "Ha egy aktív előfizetési időszakon belül van, kérjük, olvassa el lemondási szabályzatunkat itt",
	AccountDeactivationModal_Line2Part2: "Felhasználási feltételek",
	AccountDeactivationModal_Line2Part3: "mielőtt benyújtja kérelmét.",
	AccountDeactivationModal_Line3: "Biztosan benyújtja a  kérelmet a fiók deaktiválására?",
	AccountDeactivationModal_Cancel: "Vissza",
	AccountDeactivationModal_Send: "Küldés",
	CurrentUserAccountDeactivationModal_Title: "Biztosan deaktiválja a fiókot?",
	CurrentUserAccountDeactivationModal_Line1: "A fiók deaktiválása végleges, és eltávolítja az összes tartalmat, beleértve a tranzakciókat, az előzményeket, a csapattagokat és a profilbeállításokat.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Ha aktív előfizetési időszakon belül van, kérjük, olvassa el lemondási szabályzatunkat itt",
	CurrentUserAccountDeactivationModal_Line3: "Biztosan benyújtja a  kérelmet fiókja deaktiválására?",
	CurrentUserAccountDeactivationModal_Yes: "Igen",
	CurrentUserAccountDeactivationModal_No: "Nem",
	CourseWithdrawalModal_Title: "Tanfolyam lemondása",
	CourseWithdrawalModal_Line1: "Biztosan lemondja a tanfolyamot?",
	CourseWithdrawalModal_Line2Part1: "A visszamondásokért lemondási díjat számítanak fel, és az alábbiak szerint kerülnek felszámításra",
	CourseWithdrawalModal_Line2Part2Link: "ÁSZF",
	CourseWithdrawalModal_Line2Part3: "a szolgáltatásunkról.",
	CourseWithdrawalModal_Yes: "Igen, lemondom",
	CourseWithdrawalModal_No: "Nem, mégse",
	Settings_Settings: "Beállítások",
	Settings_ManageYourSettings: "Beállítások kezelése",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Bosch Global ID bejelentkezési információm",
	Settings_Edit: "Szerkesztés",
	Settings_CompanyDetails: "Cégadatok",
	Settings_RequestAccountDeletion: "Fiók törlés igénylése",
	Settings_AccountDeletionText: "Ha törli fiókját, minden adata (beleértve a címét és a fizetési adatait) törlődik, és többé nem lesz elérhető.",
	Settings_DeleteAccountRequestConfirmation: "Fiiók igénylés megerősítésének törlése",
	Settings_DeleteAccountRequestConfirmationText: "Megkaptuk fióktörlési igényét",
	RecommendedHotels_RecommendedHotels: "Ajánlott Hotelek",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Oktató központjaink az egész világon megtalálhatók",
	Error_Common: "Valami hiba történt!",
	Error_404: "A meglátogatott oldal nem létezik",
	Error_401: "Nincs jogosultsága az oldalhoz",
	Error_500: "A szerver hibát jelez",
	Dashboard_Courses_Table_Course: "Tanfolyam",
	Dashboard_Courses_Table_Date: "Időtartam",
	Dashboard_Courses_Table_Venue: "Helyszín",
	Dashboard_Courses_Table_TrainingType: "Képzés típusa",
	Dashboard_Courses_Table_Action: "Tevékenység",
	Dashboard_Courses_Table_TasksToComplete: "A következő feladatokat kell elvégeznie",
	Dashboard_Courses_Table_Withdraw: "Visszavonás",
	Dashboard_Courses_Table_Remove: "Eltávolítás",
	Dashboard_Courses_Table_View: "Nézet",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Nincs megjeleníthető tartalom",
	Dashboard_Courses_Table_CompletionDate: "Teljesítési dátum",
	Dashboard_Courses_Table_Actions_Assignments: "Beosztások",
	Dashboard_Courses_Table_Actions_Certification: "Bizonyítvány",
	Dashboard_Courses_Table_Actions_Evaluations: "Értékelések",
	Dashboard_Courses_Table_Actions_Assessments: "Kiértékelések",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Hozzáadás naptárhoz",
	Dashboard_Courses_Table_CourseDetails_Task: "Feladat",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Esedékes",
	ContactUs_Headline: "Kapcsolat",
	ContactUs_HowCanWeHelp: "Miben segíthetünk?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Levél",
	ContactUs_Phone_Headline: "Hívjon minket!",
	ContactUs_Phone_Description: "Várjuk hívását, és szívesen segítünk. 24 órában, a hét minden napján állunk rendelkezésére.",
	ContactUs_Phone_PhoneNumber: "Kérjük, használja a kapcsolatfelvételi űrlapot üzenet küldéséhez.",
	ContactUs_Phone_ChargeNotice: "A díjak a szolgáltatótól és az országtól függően változnak.",
	ContactUs_Letter_Headline: "Levél küldése!",
	ContactUs_Letter_Description: "Kérdései, kérései vagy javaslatai vannak? Várjuk érdeklődését.",
	ContactUs_Email_Headline: "E-mail küldése!",
	ContactUs_Email_Description: "Küldje el nekünk üzenetét az alábbi űrlap kitöltésével. Tanácsadónk szívesen felveszi Önnel a kapcsolatot.",
	ContactUs_Email_InputName: "Név*",
	ContactUs_Email_InputCompany: "Cég",
	ContactUs_Email_InputCountry: "Ország*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Üzenet*",
	ContactUs_Email_DataProtection: "Adatvédelmi tájékoztató*",
	ContactUs_Email_DataProtectionConfirm: "Elolvastam az Adatvédelmi tájékoztatót*",
	ContactUs_Email_SecurityCheck: "Biztonsági ellenőrzés",
	ContactUs_Email_SecurityCodeConfirm: "Kérjük, írja be a megfelelő karaktereket a fenti mezőből.",
	ContactUs_Email_SecurityCodeInvalid: "A beírt captcha szöveges válasz helytelen.",
	ContactUs_Email_MandatoryNotice: "Kérjük töltse ki a kötelező mezőket.",
	ContactUs_Email_SuccessMessage: "Üzenete el lett küldve",
	ContactUs_Email_TakenNoteDataProtection1: "Elolvastam az",
	ContactUs_Email_TakenNoteDataProtection2: "Adatvédelmi tájékoztatót.*",
	ContactUs_Email_SubmitButton: "Küldés",
	ContactUs_Email_FieldErrorMessage: "Kérjük töltse ki a kötelező mezőket.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Fiókja jelenleg ellenőrzés alatt áll!",
	AccountInReviewModal_Paragraph1: "Köszönjük személyes fiók regisztrációját a Bosch Automotive Training Solutions-ba.",
	AccountInReviewModal_Paragraph2: "A felelős kollégáink jelenleg felülvizsgálják és konfigurálják fiókját. A fiók sikeres konfigurálása után e-mailben megkapja a végső visszaigazolást.",
	AccountInReviewModal_Paragraph3: "Közben nyugodtan böngéssze weboldalunkat korlátozott hozzáféréssel.",
	AccountInReviewModal_Paragraph4: "Ha bármilyen kérdése van, vagy ha fiókja 48 órán belül nem kerül feldolgozásra, a hétvégék és ünnepnapok kivételével, lépjen velünk kapcsolatba",
	AccountInReviewModal_ContactUsLink: "itt",
	AccountInReviewModal_OKButton: "Oké, engedj be!",
	Header_YesButton: "Igen",
	Header_NoButton: "Nem",
	Header_LogOutText: "Ki akar jelentkezni?",
	Footer_EnquiryText: "Várjuk érdeklődését,",
	Footer_Telephone: "Telefon",
	Footer_TermsAndConditions: "ÁSZF",
	Footer_HowOurTrainingEvolves: "Hogyan fejlődnek képzéseink",
	FooterSearch_SearchFieldPlaceholder: "Keresés",
	TrainingCatalogue_Action: "Tevékenység",
	TrainingCatalogue_Duration: "Időtartam",
	TrainingCatalogue_Information: "Információ",
	Settings_PersonalDetails: "Személyes adatok",
	Reset_All_Filters: "Szűrők visszaállítása",
	EventTable_Days: "Napok",
	SearchField_AllResults: "Minden eredmény",
	TrainingCentresLayout_ViewDetails: "Részletek megtekintése",
	Dropdown_Placeholder: "Választ…",
	WaitlistPopup_SelectButtonText: "Kiválaszt",
	WaitlistPopup_StartDate: "Kezdés időpontja",
	WaitlistPopup_EndDate: "Befejezés időpontja",
	WaitlistPopup_Language: "Nyelv",
	WaitlistPopup_City: "Város",
	WaitlistPopup_Workshop: "Műhely",
	WaitlistPopup_Student: "Tanuló",
	WaitlistPopup_PreferredLocation: "Preferált helyszín",
	WaitlistPopup_SignUpCourseWaitingList: "Feliratkozás a tanfolyam várólistájára",
	WaitlistPopup_SignUpWithoutSelectingDate: "Feliratkozás a várólistára dátum választása nélkül",
	WaitlistPopup_GeneralWaitList: "Általános várólista",
	WatinglistPopup_CourseLabel: "Tanfolyam",
	Common_ContinueButton: "Tovább",
	UserAccountDeactivationModal_ConfirmLabel: "Jóváhagyás",
	Filter_FilterLabel: "Szűrő",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Ön már a várólistán van, szeretné eltávolítani meglévő regisztrációját, és újat létrehozni?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Igen, eltávolítom",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nem, mégse",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Tanfolyam visszavonása",
	WaitinglistPopup_SignUpButtonText: "Feliratkozás",
	MyAccount_FirstNameRequied: "Keresztnév szükséges",
	MyAccount_LastNameRequired: "Vezetéknév szükséges",
	MyAccount_RoleRequired: "Funkció szükséges",
	MyAccount_MobileRequired: "Mobil szükséges",
	MyAccount_EmailRequired: "Email szükséges",
	MyAccount_LanguageRequired: "Nyelv szükséges",
	MyAccount_UpdateSuccessMessage: "Fiókja adatai sikeresen frissültek!",
	MyAccount_UpdateSuccessModal_ButtonText: "Igen, bezárom",
	Infor_CorporateInformation_Header: "Impresszum",
	Infor_LegalNotice_Header: "Jogi nyilatkozat",
	Infor_PrivacyPolicy_Header: "Adatvédelmi beállítások",
	CreateEmployeeAccountModification_FormValidation_Role: "Funkció szükséges",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Keresztnév szükséges",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Vezetéknév szükséges",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobil szükséges",
	CreateEmployeeAccountModification_FormValidation_Email: "Email szükséges",
	CreateEmployeeAccountModification_FormValidation_Language: "Nyelv szükséges",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Meghívó a Bosch Automotive Training Solutions-hoz",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Sikeresen hozzáadott egy új tagot csapatához!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Szeretné, ha megosztanánk egy e-mailes meghívót új csapattagjával, amelyben útmutatást adunk arról, hogyan hozhatja létre saját személyes fiókját a Bosch Automotive Training Solutions-hoz?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nem",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Igen, meghívó küldése",
	ShoppingCart_Venue_Fee: "Helyszín díj",
	ShoppingCart_Trainning_Name: "Képzés neve",
	ShoppingCart_Available_Credits: "Elérhető kreditek",
	ConfirmItsMeModal_Hi: "Üdv",
	ConfirmItsMeModal_Description1: "Sikeresen teljesítette a vállalat adminisztrátora által megosztott meghívást, hogy hozzon létre saját személyes fiókot a Bosch Automotive Training Solutions-ban.",
	ConfirmItsMeModal_Description2: "Mielőtt folytatná, kérjük, erősítse meg vagy frissítse fiókja adatait.",
	ConfirmItsMeModal_Description3: "A megerősítést követően újra be kell jelentkeznie a fiók létrehozásának befejezéséhez.",
	ConfirmItsMeModal_CompanyDetails: "Cég adatai",
	ConfirmItsMeModal_YourDetails: "Saját adatai",
	ConfirmItsMeModal_YourDetails_Title: "Megnevezés",
	ConfirmItsMeModal_YourDetails_Function: "Funkció",
	ConfirmItsMeModal_YourDetails_Name: "Név",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobil",
	ConfirmItsMeModal_YourDetails_Email: "Email",
	ConfirmItsMeModal_YourDetails_Language: "Nyelv",
	ConfirmItsMeModal_ConfirmButton: "Jóváhagyás",
	SelectWholesalerModal_WholesalerGroupLabel: "Nagykereskedő",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Kérjük válasszon",
	ApplyButton: "Alkalmaz",
	SelectWholesalerModal_Title: "Válasszon nagykereskedőt",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "El akarja távolítani regisztrációját?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Regisztrációja sikeresen eltávolítva!",
	WaitlistPopup_AddToWaitListSuccess: "Regisztrációja sikeresen hozzáadva!",
	ErrorCommon_Header: "HIBA",
	CommonButton_Close: "Bezárás",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Kérjük zárja be az ablakot a folytatáshoz!",
	EmbeddedVideoPlayer_StartButtonText: "Kezdés",
	EmbeddedVideoPlayer_RefresherButtonText: "Frissítés",
	DeactivateWholesalerModal_ConfirmMessage: "Biztosan eltávolítja ezt a nagykereskedőt?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Meghívó email sikeresen elküldve!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Meghívás újra",
	ContactUs_Mobile_HU: "36706845056",
	ContactUs_Address_HU: "Robert Bosch Kft., Bosch Oktató Központ, Gyömrői út 104. Budapest, MAGYARORSZÁG",
	ConfirmItsMeModal_CompanyDetails_Name: "Cég neve",
	ConfirmItsMeModal_CompanyDetails_Address: "Cím",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Cég irányítószáma",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Tanfolyam visszavonása sikeres!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "A fiók létrehozása nem sikerült. A fiók már létezik!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "A fiók frissítése nem sikerült. A fiók már létezik!",
	Footer_ExploreTrainingStories: "Fedezze fel az összes képzési történetünket",
	Breadcrumb_Home: "Otthon",
	Breadcrumb_Contact: "Kapcsolat",
	Breadcrumb_Stories: "Történetek",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch szervizképzések",
	Breadcrumb_Stories_ADayInLife: "Egy nap egy oktató életében",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch képzés előnyei",
	Breadcrumb_Stories_TrainingApproach: "Egy modern képzési megközelítés",
	Breadcrumb_Settings: "Beállítások",
	Breadcrumb_ShoppingCart: "Bevásárló kosár",
	Breadcrumb_Checkout: "Pénztár",
	Breadcrumb_OrderSummary: "Rendelés összefoglaló",
	Breadcrumb_Exception: "Kivétel",
	Breadcrumb_Dashboard: "Irányítópult",
	Breadcrumb_MyCourses: "Tanfolyamaim",
	Breadcrumb_MyELearning: "eLeraning-em",
	Breadcrumb_CompanyAdministration: "Cég adminisztráció",
	Breadcrumb_EmployeeManagement: "Munkavállaló menedzsment",
	Breadcrumb_EmployeeAccount: "Munkavállalói fiók",
	Breadcrumb_EmployeeCreation: "Munkavállaló készítése",
	Breadcrumb_Administration: "Adminisztráció",
	Breadcrumb_EmployeeAdministration: "Munkavállalói adminisztráció",
	Breadcrumb_WholesalerManagement: "Nagykereskedő menedzsment",
	Breadcrumb_NewWholesalerAccountCreation: "Új nagykereskedői fiók készítése",
	Breadcrumb_Transactions: "Tranzakciók",
	Breadcrumb_MyAccount: "Fiókom",
	Breadcrumb_TrainingCentres: "Oktató központok",
	Breadcrumb_EventCalendar: "Eseménynaptár",
	Breadcrumb_TrainingCourseCatalogue: "Képzési tanfolyam katalógus",
	Breadcrumb_RecommendedHotels: "Ajánlott Hotelek",
	Breadcrumb_CorporateInformation: "Impresszum",
	Breadcrumb_LegalNotice: "Jogi nyilatkozat",
	Breadcrumb_DataProtectionNotice: "Adatvédelmi beállítások",
	Breadcrumb_PrivacyPolicy: "Adatvédelmi beállítások",
	Breadcrumb_TermCondition: "ÁSZF",
	Breadcrumb_Terms: "Feltételek",
	Breadcrumb_PrivacyStatement: "Adatvédelmi tájékoztató",
	Breadcrumb_Cookies: "Sütik",
	Breadcrumb_Provider: "Szolgáltató",
	Breadcrumb_RegisterAccount: "Fiók regisztráció",
	Stories_ExploreMore: "Tudjon meg többet",
	CheckOutLayout_ShoppingCartHeader: "Bevásárló kosár",
	CheckOutLayout_AddedItemCartMessage: "Az alábbi tételt adta hozzá kosarához",
	CheckOutLayout_CheckYourCartMessage: "Kosár ellenőrzése",
	ModernTrainingApproach_DateText: "28.02.2022",
	ADayInLifeofATrainer_DateText: "28.02.2022",
	BoschTrainingBenefits_DateText: "28.02.2022",
	BoschServiceTraining_DateText: "28.02.2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Munkavállalók keresése",
	CheckOutLayout_OrderWasNotPlaced_Message: "Elnézést! A rendelése nem került a kosárba",
	CheckOutLayout_RefusalReason: "Elutasítás oka",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Sikertelen fizetés. Kérjük, próbálja újra egy másik fizetési móddal",
	CheckOutLayout_SubmittingApiFailed_Message: "API elküldése sikertelen",
	CartItem_ChooseWorkshop_Message: "Kérjük, válassza ki a műhelyt a résztvevő kiválasztásához",
	CartItem_ChooseParticipant_Message: "A folytatáshoz válassza ki a résztvevőt",
	CompanyAdministrationTable_SearchBox_Placeholder: "Műhelyek keresése",
	CartItem_RemoveCartItem_ConfirmText: "El akarja távolítani a tételt a kosárból?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkció",
	AddToCartSuccessModal_Message: "Kosara frissült!",
	AddToCartSuccessModal_ContinueButtonText: "Vásárlás folytatása",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Bevásárló kosaram",
	AddVoucher_Header: "Voucher hozzáadása",
	AddVoucher_InputPlaceholder: "Kód beírása és alkalmazása",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Sajnos jelenleg nincs ütemezett eseményünk ezen képzés keretében. Kérjük, regisztráljon a várólistára, és értesítjük, amint elérhetővé válik az esemény.",
	ADYEN_ERR_2_: "A tranzakció elutasítva.",
	ADYEN_ERR_3_: "Hivatkozási hiba",
	ADYEN_ERR_4_: "A tranzakció a beszerző oldalán fellépő hiba miatt nem ment végbe.",
	ADYEN_ERR_5_: "A tranzakcióhoz használt kártya zárolt, ezért használhatatlan.",
	ADYEN_ERR_6_: "A tranzakcióhoz használt kártya lejárt, ezért használhatatlan.",
	ADYEN_ERR_7_: "A tranzakciós folyamat során összegeltérés történt.",
	ADYEN_ERR_8_: "A megadott kártyaszám hibás vagy érvénytelen.",
	ADYEN_ERR_9_: "Nem lehetséges felvenni a kapcsolatot a vásárló bankjával a tranzakció engedélyezése érdekében.",
	ADYEN_ERR_10_: "A vásárló bankja nem támogatja vagy nem engedélyezi az ilyen típusú tranzakciókat.",
	ADYEN_ERR_11_: "A 3D Secure hitelesítés nem lett végrehajtva, vagy a végrehajtás sikertelen volt,",
	ADYEN_ERR_12_: "A kártyán nincs elég pénz a fizetendő összeg fedezésére. Ez a probléma a kártya havi fizetendő összegének korlátja miatt fordulhat elő. Kérjük, próbáljon meg másik kártyát, például MasterCard-ot használni, vagy lépjen kapcsolatba bankjával a kártya havi limitjének visszaállítása érdekében.",
	ADYEN_ERR_14_: "Lehetséges csalás.",
	ADYEN_ERR_15_: "A tranzakciót törölték.",
	ADYEN_ERR_16_: "A vásárló a befejezés előtt törölte a tranzakciót.",
	ADYEN_ERR_17_: "A megadott PIN kód hibás vagy érvénytelen.",
	ADYEN_ERR_18_: "A vásárló egymást követő több mint három alkalommal hibás PIN kódot adott meg.",
	ADYEN_ERR_19_: "A megadott PIN kód érvényesítése nem lehetséges.",
	ADYEN_ERR_20_: "Lehetséges csalás.",
	ADYEN_ERR_21_: "A tranzakciót nem megfelelően nyújtották be feldolgozásra.",
	ADYEN_ERR_22_: "A kockázatellenőrzés csalásként jelölte meg a tranzakciót (kockázati pontszám >= 100); ezért a művelet megszakad.",
	ADYEN_ERR_23_: "A következő elutasított kódok az alábbi elutasítási okhoz vannak társítva:  A tranzakció nem engedélyezett a kibocsátó/kártyabirtokos számára",
	ADYEN_ERR_24_: "A megadott CVC kód (kártya biztonsági kódja) érvénytelen.",
	ADYEN_ERR_25_: "A következő elutasítási kódok az alábbi elutasítási okhoz vannak társítva:  Érvénytelen kártya ebben az országban",
	ADYEN_ERR_26_: "R1: Engedélyezési megbízás visszavonása / \"R3: Összes engedélyezési megbízás visszavonása\" / \"R0: Fizetési megbízás leállítása\"",
	ADYEN_ERR_27_: "Ez a válasz leképezi mindazokat a válaszkódokat, amelyeket nem lehet megbízhatóan leképezni. Ez megkönnyíti az általános elutasítások megkülönböztetését (például a Mastercard „05: Ne tisztelje” válaszát) a konkrétabb elutasításoktól.",
	ADYEN_ERR_28_: "A vásárló a kártyájára engedélyezett pénzfelvételi összeget túllépte.",
	ADYEN_ERR_29_: "A vásárló túllépte a kártyájához engedélyezett pénzfelvételek számát.",
	ADYEN_ERR_31_: "A kibocsátó csalás gyanújaként jelentette a tranzakciót.",
	ADYEN_ERR_32_: "A vásárló által megadott címadatok hibásak.",
	ADYEN_ERR_33_: "A vásárló bankja megköveteli a vásárlótól az online PIN kód megadását.",
	ADYEN_ERR_34_: "A vásárló bankjának csekkszámlára van szüksége a vásárlás befejezéséhez.",
	ADYEN_ERR_35_: "A vásárló bankjának takarékszámlára van szüksége a vásárlás befejezéséhez.",
	ADYEN_ERR_36_: "A vásárló bankja megköveteli a vásárlótól a mobil PIN kód megadását.",
	ADYEN_ERR_37_: "A vásárló félbehagyta a tranzakciót, miután megkísérelte az érintés nélküli fizetést, és arra lett kérve, hogy próbáljon ki egy másik kártyabeviteli módot (PIN-kód vagy csúsztatás).",
	ADYEN_ERR_38_: "A kibocsátó elutasította a hitelesítési felmentési kérelmet, és hitelesítést kér a tranzakcióhoz. Próbálja újra a 3D Secure segítségével.",
	ADYEN_ERR_39_: "A kibocsátó vagy a rendszer nem tudta közölni az eredményt az RReq-en keresztül",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "A keresztnév ne tartalmazzon speciális karaktereket (pl.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "A keresztnév kevesebb, mint 75 karakter kell legyen",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "A vezetéknév ne tartalmazzon speciális karaktereket (pl.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "A vezetéknév kevesebb, mint 75 karakter kell legyen",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "A mobil szám ne tartalmazzon speciális karaktereket (pl.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "A mobil szám kevesebb, mint 45 karakter kell legyen",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "A telefonszám ne tartalmazzon speciális karaktereket (pl: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "A telefonszám kevesebb, mint 45 karakter kell legyen",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Érvénytelen E-mail cím",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Az E-mail cím kevesebb, mint 100 karakter kell legyen",
	ConfirmGeolocationModal_ConfirmButton: "Tovább",
	ConfirmGeolocationModal_CancelButton: "Változtatás",
	ConfirmGeolocationModal_CurrentCountrySelection: "Jelenleg kiválasztott ország:",
	ConfirmGeolocationModal_AskChangeCountry: "Szeretne továbbra is ezen az oldalon maradni, vagy módosítani kívánja a beállított országot?",
	Addtocart_Modal_AddToCartFailTitle: "A résztvevő már regisztrált erre az tanfolyamra",
	Addtocart_Modal_AlreadyInCart: "A résztvevő már regisztrált erre az eseményre",
	TrainingCatalogue_DateRange: "Időintervallum",
	TrainingCatalogue_DateRange_placeholder: "Válasszon időintervallum szűrőt",
	Dashboard_MyPendingCourses: "Függő tanfolyamaim",
	Dashboard_MyWaitingList: "Várólistám",
	Dashboard_LatestNews: "Legújabb HÍrek",
	SettingsMainPage_EditAccountButton: "Fiók szerkesztése",
	SettingsMainPage_EditBoschId: "Bosch ID szerkesztése",
	EventInfoView_EventDetailTab: "Esemény részletek",
	EventInfoView_AssignmentsTab: "Beosztások",
	EventInfoView_EvaluationTab: "Értékelés",
	EventInfoView_AttachmentTab: "Csatolmányok",
	EventInfoView_CourseDescriptionTab: "Leírás",
	EventInfoView_DescriptionTab_General: "Általános",
	EventInfoView_DescriptionTab_PartLabel: "Rész",
	AssignmentTab_Description: "Itt minden olyan információt megtalál, amelyre szüksége lesz a tanfolyam elvégzéséhez.",
	AssignmentTab_Columns_Download: "Letöltés",
	AssignmentTab_Columns_Upload: "Feltöltés",
	AssignmentTab_Columns_TaskComplete: "Feladat teljesítése",
	EventDetailTab_Location_Message_Video: "Ez egy online esemény, amely a \"Videó elindítása\" link segítségével érhető el.",
	EventDetailTab_Location_Message_WebBased: "Ez egy online esemény, amely a \"WBT elindítása\" link segítségével érhető el.",
	EventDetailTab_Location_Message_WebCast: "Ez egy online esemény, amely a \"Csatlakozás most\" link segítségével érhető el.",
	EventDetailTab_Link_Video: "Video elindítása",
	EventDetailTab_Link_Video_Remark: "A videó elindításával új ablakot nyit meg. Az ablak bezárását követően a rendszer visszairányítja erre az oldalra.",
	EventDetailTab_Link_WebBased: "WBT elindítása",
	EventDetailTab_Link_WebBased_Remark: "A WBT elindításával új ablakot nyit meg. Az ablak bezárását követően a rendszer visszairányítja erre az oldalra.",
	EventDetailTab_Link_WebCast: "Csatlakozás most",
	EventDetailTab_Link_WebCast_Remark: "A Csatlakozás most link az esemény kezdete előtt 30 perccel lesz aktív.",
	EventDetailTab_Title_Status: "Státusz",
	EventDetailTab_Title_Duration: "Időtartam (óra)",
	EventDetailTab_Title_Trainers: "Oktató(k)",
	EventDetailTab_Title_Language: "Nyelv",
	EventDetailTab_Title_Score: "Eredmény (%)",
	EventDetailTab_Title_Calendar: "Hozzáadás naptárhoz",
	EventDetailTab_Title_Certificate: "Bizonyítvány letöltése",
	EventDetailTab_Title_Messages: "Üzenetek",
	EventDetailTab_Title_StartDate: "Kezdés időpontja",
	EventDetailTab_Title_AvailableFrom: "Elérhető",
	EventDetailTab_Title_Time: "Idő",
	EventDetailTab_Title_EndDate: "Befejezés időpontja",
	EventDetailTab_Title_ExpiresOn: "Lejárat dátuma",
	EventDetailTab_Title_Room: "Férőhely",
	EventDetailTab_Title_Method: "Módszer",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Helyszín",
	AttachmentTab_EmptyScreenDescription: "Ehhez az eseményhez nincsenek mellékletek.",
	AttachmentTab_Columns_Download: "Letöltés",
	AttachmentTab_Description: "Itt talál néhány fontos információt, amelyek segíthetnek a tanfolyamon",
	EvaluationTab_Annotation: "Örömmel fogadjuk visszajelzését. Kérjük, szánjon időt az alábbi értékelés kitöltésére, hogy segítsen nekünk szolgáltatásaink folyamatos fejlesztésében. Köszönjük.",
	EvaluationTab_Notice: "Az értékelések a következők",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Kérjük, válaszoljon minden kötelező kérdésre, mielőtt elküldi rendezvény értékelését",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "A visszajelzése sikeresen továbbítva lett",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Az értékelés benyújtása meghiúsult",
	TrainingCatalogueLayout_ExportButtonText: "Exportálás",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Az értékelés befejeződött vagy lejárt.",
	EventDetailTab_Link_CopiedTooltip: "Vágólapra másolva",
	EventDetailTab_QRCode_ExplanationText_WBT: "Beolvasás a WBT elindításához",
	EventDetailTab_QRCode_ExplanationText_Video: "Beolvasás a Video elindításához",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Beolvasás a Webcast elindításához",
	Footer_TrainingCoure_Headline: "Képzési tanfolyamok a Bosch-tól",
	Footer_TrainingCourse_Content: "Világhírű Szupermárkaként és több mint egy évszázados innovációs tapasztalattal rendelkezve, a Bosch autóipari rendszerek, alkatrészek és műszaki megoldások szállítójaként világszerte mércét állított fel. Erősségünk nagy részét a járműgyártókkal fennálló szoros együttműködésünk adja, akik számára a legkorszerűbb rendszereket és alkatrészeket tervezzük, fejlesztjük és gyártjuk világszerte. Következésképpen a Bosch az automotive aftermarket termékek és támogatások területen erős történelmi hagyományokkal bír az alkatrészek, diagnosztikai megoldások és műhelyszolgáltatások változatos portfóliójával. A tanulás és a fejlődés biztos kulcsa a sikernek, és ezt a filozófiát a képzések széles skálája támasztja alá, az autótechnika, a járműrendszerek és a diagnosztikai szakértelem minden területén.",
	SearchField_NoRecordsFound: "Nincsenek előzmények",
	TrainingCatalogue_FreeTextSearch: "Szabad szavas",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Keresés",
	CheckoutLayout_WholeSalerGroup: "Válasszon nagykereskedő csoportot",
	CreditInsufficientModal_Message: "Nincs elegendő kerete ennek a fizetési módnak a használatához. Kérjük, próbálja újra egy másik fizetési móddal.",
	ShoppingCart_Required_Credits: "Végösszeg",
	MaintainingInfo_Message1: "A weboldal karbantartás alatt áll.",
	MaintainingInfo_Message2: "Hamarosan visszatérünk.",
	CountryValidationModal_Message: "Nem tudott bejelentkezni [CountryA]-ba, mivel fiókja itt jött létre: [CountryB].",
	CountryValidationModal_ButtonText: "Tovább ide: [Ország]",
	EventTable_LoginRequired: "Bejelentkezés a regisztrációhoz",
	TrainingPartTableLayout_Date: "Dátum",
	TrainingPartTableLayout_StartTime: "Kezdés ideje",
	TrainingPartTableLayout_EndTime: "Befejezés ideje",
	TrainingPartTableLayout_Duration: "Időtartam (óra)",
	TrainingPartTableLayout_TrainingMethod: "Képzési módszer",
	MyCoursesTableContents_ActiveHeader: "Aktív tanfolyamok",
	MyCoursesTableContents_ActiveTabHeader: "Aktív",
	MyCoursesTableContents_ScheduledHeader: "Ütemezett tanfolyamok",
	MyCoursesTableContents_ScheduledTabHeader: "Ütemezett",
	MyCoursesTableContents_OnlineHeader: "Online tanfolyamok",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Törölt tanfolyamok",
	MyCoursesTableContents_CancelledTabHeader: "Törölt",
	OnlineCoursesTable_Title_Progress: "Folyamat",
	ScheduledCoursesTable_Title_Date: "Dátum (napok)",
	CoursesTable_Title_Completed: "Teljesített",
	Footer_TrainingProgram: "Képzési programok",
	Breadcrumb_TrainingProgram: "Képzési programok",
	TrainingProgram_Title_Table: "Megnevezés",
	TrainingProgram_TrainingProgram: "Képzési programok",
	TrainingProgram_TargetGroup: "Célcsoport",
	TrainingProgram_MaxLength: "Max. hosszúság",
	TrainingProgram_Information: "Információ",
	TrainingProgram_Detail: "Részletek",
	TrainingProgram_Modules: "Modulok",
	TrainingProgram_Course: "Tanfolyamok",
	TrainingProgram_PartNumber: "Cikkszám",
	TrainingProgram_Max_Length: "Maximum hosszúság",
	TrainingProgram_Duration: "Időtartam",
	TrainingProgram_Exam: "Vizsga",
	TrainingProgram_Assign_Popup: "Regisztrálás a képzési programra",
	TrainingProgram_Student: "Tanuló",
	TrainingProgram_Assign: "Hozzárendelés",
	TrainingProgram_Popup_Remove_Title: "Képzés lemondása?",
	TrainingProgram_Description: "Leírás",
	TrainingProgram_Days: "Napok",
	TrainingProgram_Type: "Típus",
	TrainingProgram_Action: "Tevékenység",
	TrainingProgram_Enrolled: "már beiratkozott erre a képzési programra",
	TrainingProgram_SelfAssign_Enrolled: "Ön már hozzá van rendelve ehhez a képzési programhoz",
	TrainingProgram_Warning: "Kérjük, regisztráljon másik tanulót, vagy válasszon másik képzési programot.",
	TrainingProgram_Workshop: "Műhely",
	TrainingProgram_Durations: "évek",
	TrainingProgram_Duration_DaysLabel: "napok",
	TrainingProgram_HasExam_Yes: "Igen",
	TrainingProgram_HasExam_No: "Nem",
	AssignModal_SuccessModal_Header: "Gratulálunk!",
	AssignModal_SuccessModal_Message: "sikeresen beiratkozott erre a képzési programra.",
	AssignModal_SelfAssign_SuccessModal_Message: "Sikeresen hozzá lett rendelve ehhez a képzési programhoz.",
	Dashboard_MyTraningProgram: "Képzési programom",
	Dashboard_MyTraningProgram_Title: "Megnevezés",
	Dashboard_MyTraningProgram_Status: "Státusz",
	Dashboard_MyTraningProgram_Table_View: "Nézet",
	Dashboard_MyTraningProgram_Register: "Regisztráció",
	Dashboard_MyTraningProgram_Records: "Előzmények",
	Dashboard_MyTrainingProgram_In_Progress: "Folyamatban",
	Dashboard_MyTrainingProgram_Accredited: "Akkreditált",
	Dashboard_Program_Modal_Withdraw: "Visszavonás",
	Dashboard_Program_Modal_Grade: "Fokozat",
	Dashboard_MyTrainingProgram_Registered: "Regisztrált",
	Dashboard_MyTrainingProgram_Completedd: "Teljesített",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Igen",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nem",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Kérjük, vegye figyelembe, hogy a visszalépés azt jelenti, hogy Ön a teljes képzési programtól, nem csak egy egyéni képzéstől fog kilépni.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Biztosan visszalép a képzési progamtól?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Függő",
	WithdrawProgramModal_Withdraw_Success_Message: "Sikeresen visszalépett a képzési programból!",
	Common_Warning_Title: "Figyelem!",
	Common_Error_Title: "Upsz, valami elromlott!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Kosárba",
	AddEventFromCatalogToCartModal_HoursText: "órák",
	AddElearningToCartSuccessModal_Message: "hozzá lett adva kosarához!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "A kiválasztott résztvevő regisztrálva van erre a tanfolyamra, vagy a tanfolyam már bekerült a kosárba.",
	EventDetailTab_SubjectForChange_Message: "A változtatás tárgya",
	Dashboard_MyTrainingProgram_Status_Failed: "Megbukott",
	Dashboard_MyTrainingProgram_Status_Passed: "Átment",
	Dashboard_MyTrainingProgram_Status_Merit: "Elvégezve",
	Dashboard_MyTrainingProgram_Status_Distinction: "Kitüntetés",
	Dashboard_Employee_Courses: "Tanfolyamok",
	Dashboard_Employee_Program: "Képzési program",
	Dashboard_Employee_WaitList: "Várólista",
	Dashboard_Employee_Account: "Fiók",
	Course_Price_Free: "Szabad",
	Dashboard_Employee_Account_Update_Success_Message: "Sikeresen frissítette munkavállalói információit!",
	Dashboard_Supplier: "Beszállító",
	Event_Detail_Comment: "Jelenleg nincsenek üzenetek",
	DownLoad_PDF: "PDF letöltés",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Pénztárcám",
	Wallet: "Pénztárca",
	Credits_Balance: "Kreditek egyenlege",
	MyFullCalendar_Today: "Ma",
	Header_Available_Languages: "Elérhető nyelvek",
	Header_CurrentRegion: "Jelenleg itt van",
	Header_ChangeRegions: "Meg akarja változtatni az országot?",
	Header_Anonymous: "Szervizképzés",
	Header_Anonymous_Login: "Bejelentkezés/Regisztráció",
	Header_RegisterCourse: "Kurzus regisztráció",
	Homepage_Start: "Kezdjük",
	Homepage_Login: "Bejelentkezés",
	MainPage_Title: "A tökéletes műhelymegoldás",
	MainPage_Content: "Az Ön műhelye, az Ön döntése.",
	MainPage_ImageTitle: "Bosch Automotive Training Solutions",
	MainPage_IntroContent: "Lehetővé teszi a modern műhely számára, hogy minden típusú járműnél professzionálisan, megbízhatóan és hatékonyan dolgozzon a diagnosztikai eljárások, hibaelhárítás, javítás és szervizelés során.",
	BoschNews_TrainingNews: "Képzési hírek",
	Service_Assist_Workshop: "Növelje tapasztalatait a Workshop Service Assist segítségével",
	Enhance_Title_CDM3_v2: "Mit tehetek az alkalmazással?",
	Enhance_Body_CDM3_Item_1: "Foglaljon tanfolyamokat és tartsa fenn tanulási útját",
	Enhance_Body_CDM3_Item_2: "Képzési anyagok és tanfolyami bizonyítványok letöltése",
	Enhance_Body_CDM3_Item_3: "eLearning tanfolyamok elindítása",
	Enhance_Body_CDM3_Item_4: "Hozzáférés a különböző Automoitve szolgáltatásokhoz mobilalkalmazáson belül",
	Enhance_Body_CDM3_Item_5: "Élő videó közvetítés a Visual Connect Pro segítségével",
	Enhance_Body_CDM3_Item_6: "Ingyenes eszköz az automatikus járműellenőrzéshez",
	Training_Stories: "Képzési történetek",
	SettingsMyAccount_JoinCompanyButton: "Csatlakozás a céghez",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Csatlakozás a céghez",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Kérjük, adja meg aktiváló kódját alább",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Megjegyzés! Az aktiváló kód beszerzéséhez kapcsolatba kell lépnie a cég adminisztrátorával.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Az aktiválás feldolgozása sikeresen megtörtént, és fiókja össze van kapcsolva vállalatával.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Kérjük, kattintson a Tovább gombra a webhelyre való belépéshez.",
	ContinueButton: "Tovább",
	WelcomeBatsModal_Title: "Fiókja jelenleg ellenőrzés alatt áll!",
	WelcomeBatsModal_ActivationLink: "Kattintson ide, ha rendelkezik aktiválási kóddal a céghez való csatlakozáshoz!",
	WelcomeBatsModal_ActivationLinkNotes: "Megjegyzés! Az aktiváló kód beszerzéséhez kapcsolatba kell lépnie a cég adminisztrátorával.",
	AccountInReviewModal_Description_Paragraph1: "Köszönjük, hogy személyes fiókot regisztrált a Bosch Automotive Training Solutions-ban.",
	AccountInReviewModal_Description_Paragraph2: "A felelős kollégáink jelenleg felülvizsgálják és konfigurálják fiókját. A fiók sikeres konfigurálása után e-mailben megkapja a végső visszaigazolást.",
	AccountInReviewModal_CloseButton: "Oké, engedj be!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nem tudtuk ellenőrizni aktiváló kódját és e-mail címét.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Kérjük, próbálja újra, vagy kérjen segítséget a cég adminisztrátortól.",
	TryAgain_Button: "Próbálja újra",
	Error_Title: "Upsz, valami elromlott!",
	SettingsMyProfileActivationSuccessModal_Title: "Siker!",
	AccountInReviewModal_Description_Paragraph3: "Közben nyugodtan böngéssze weboldalunkat korlátozott hozzáféréssel.",
	AccountInReviewModal_Description_Paragraph4: "Ha bármilyen kérdése van, vagy ha fiókja 48 órán belül nem kerül feldolgozásra, a hétvégék és ünnepnapok kivételével, lépjen kapcsolatba velünk [itt]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Egy kis útmutatás, ami segít…",
	Support_FAQ_Headline: "Gyakran ismételt kérdések",
	Support_FAQs_section1_title: "Általános",
	Support_FAQs_section1_question1: "Mi az a Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "A Bosch Training Solutions egy olyan platform, amely átfogó áttekintést nyújt a műszaki képzésekről, és lehetővé teszi képzések lefoglalását és kezelését.",
	Support_FAQs_section1_question2: "Használhatom meglévő Bosch fiókomat (singleKey ID) a Bosch Training Solutions-ba való bejelentkezéshez?",
	Support_FAQs_section1_question2_answer1: "Igen, a \"My Bosch ID\" azonosítóval létrehozott összes fiókkal elérhető.",
	Support_FAQs_section1_question3: "Használhatom a Bosch Training Solutions számára létrehozott fiókot más Bosch szolgáltatásokhoz vagy alkalmazásokhoz?",
	Support_FAQs_section1_question3_answer1: "Igen, személyes Bosch ID azonosítóját számos más Bosch alkalmazáshoz is használhatja – mind szakmailag, mind magánéletben, mint például e-bike vagy okos otthon esetében.",
	Support_FAQs_section2_title: "Oktatás",
	Support_FAQs_section2_question1: "Hogyan foglalhatok oktatást?",
	Support_FAQs_section2_question1_answer1: "Jelentkezzen be meglévő fiókjával VAGY regisztráljon fiókot a portálon. Nyissa meg a \"Képzési szolgáltatások\" VAGY \"Eseménynaptár\" menüpontot, válasszon ki egy képzést, és kattintson a kártya hozzáadása gombra.",
	Support_FAQs_section2_question2: "Nem áll rendelkezésre megfelelő képzési időpont. Mit tehetek?",
	Support_FAQs_section2_question2_answer1: "Lehetősége van felvenni magát egy \"általános\" várólistára (időpont kiválasztása nélkül), és csapatunk azonnal tájékoztatja Önt, amint egy új képzés elérhető.",
	Support_FAQs_section2_question3: "Hol találok áttekintést a lefoglalt tanfolyamaimról?",
	Support_FAQs_section2_question3_answer1: "Bejelentkezést követően az irányítópulton megtalálja a teljes áttekintést az összes lefoglalt és befejezett tanfolyamról.",
	Support_FAQs_section3_title: "Beállítások",
	Support_FAQs_section3_question1: "Hogyan tudom megváltoztatni az Email címet és jelszót?",
	Support_FAQs_section3_question1_answer1: "Be kell jelentkeznie a oldalra, és át kell váltania személyes profiljára. (Az Irányítópulton belül megtalálhatja fiókadatait, vagy ha a fejlécben a következő gombra kattint",
	Support_FAQs_section3_question1_answer3: "Itt lehetősége van Bosch ID adatainak módosítására",
	Support_FAQs_section3_question2: "Mit tehetek, ha elfelejtettem a jelszavamat?",
	Support_FAQs_section3_question2_answer1: "Kérjük, használja a bejelentkezés/regisztráció gombot, és adja meg e-mail címét",
	Support_FAQs_section3_question2_answer2: "Ha a Tovább gombra kattint, lehetősége van rákattintani az \"Elfelejtett jelszó\" elemre.",
	Support_FAQs_section3_question3: "Hogyan lehet műhelyfiókot létrehozni?",
	Support_FAQs_section3_question3_answer1: "Ha műhely besorolást szeretne kapni, forduljon a helyi képzési adminisztrációhoz, pl. a honlapon található kapcsolatfelvételi űrlap használatával.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Ország kiválasztása",
	MaintenanceBanner_Message_1: "A Bosch Automotive Training Solutions oldal frissítése a következő napon történik meg: [maintenance_schedule]. A frissítés várhatóan 30 percet vesz igénybe. A frissítés ideje alatt az oldal nem lesz elérhető.",
	MaintenanceBanner_Message_2: "\nTovábbi segítségért forduljon hozzánk e-mailben [támogató_email]",
	MaintenancePage_Message_1: "A Bosch Automotive Training Solutions oldal jelenleg frissítés alatt áll",
	MaintenancePage_Message_2: "Az oldal várhatóan [maintenance_online_date] napon, [maintenance_online_time] időpontban újra elérhető lesz",
	MenuHeader_Support: "Támogatás",
	Course_Detail_No_Prerequisites: "Nincs előfeltétel",
	TableCell_Hours: "Órák",
	Dashboard_MenuItem_Dashboard: "Irányítópult",
	Dashboard_MenuItem_Curricula: "Képzés",
	Dashboard_MenuItem_Certificates: "Bizonyítványok",
	Dashboard_MenuItem_Team: "Csapat",
	Dashboard_MenuItem_Workshops: "Műhelyek",
	Dashboard_MenuItem_External: "Külsős",
	Dashboard_MenuItem_Wallet: "Pénztárca",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Közelgő tanfolyamai",
	Dashboard_Headline_YourELearningCourses: "eLearning tanfolyamai",
	Dashboard_Headline_TeamOverview: "Csapat áttekintése",
	Dashboard_Headline_YourOverview: "Áttekintés",
	Dashboard_Headline_YourWishlist: "Kívánságlistám",
	Dashboard_Headline_ActivePrograms: "Aktív programok",
	Dashboard_Headline_CompletedPrograms: "Teljesített programok",
	Dashboard_Headline_YourCertificates: "Bizonyítványai",
	Dashboard_Headline_Transactions: "Tranzakciók",
	Dashboard_Table_NoRecordsToDisplay: "Nincs megjeleníthető tartalom",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "órák",
	Dashboard_ELearningCourses_TableHeader_Title: "Megnevezés",
	Dashboard_ELearningCourses_TableHeader_Duration: "Időtartam (óra)",
	Dashboard_ELearningCourses_ActionButton_View: "Nézet",
	Dashboard_UpcomingCourses_TableHeader_Title: "Megnevezés",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Dátum (napok)",
	Dashboard_UpcomingCourses_TableHeader_Location: "Helyszín",
	Dashboard_UpcomingCourses_ActionButton_View: "Nézet",
	Dashboard_YourOverview_TableHeader_Title: "Megnevezés",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Dátum / Időtartam",
	Dashboard_YourOverview_TableHeader_Location: "Helyszín",
	Dashboard_YourOverview_TableHeader_Status: "Státusz",
	Dashboard_YourOverview_ActionButton_View: "Nézet",
	Dashboard_YourWishlist_TableHeader_Title: "Megnevezés",
	Dashboard_YourWishlist_TableHeader_Action: "Tevékenység",
	Dashboard_YourWishlist_ViewAllRecords: "Összes dokumentum megtekintése",
	Dashboard_TeamOverview_TableHeader_Employee: "Munkavállaló",
	Dashboard_TeamOverview_TableHeader_Title: "Megnevezés",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Dátum / Időtartam",
	Dashboard_TeamOverview_TableHeader_Location: "Helyszín",
	Dashboard_TeamOverview_TableHeader_Status: "Státusz",
	Dashboard_TeamOverview_ActionButton_View: "Nézet",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Keresés",
	Dashboard_ActiveProgram_TableHeader_Title: "Megnevezés",
	Dashboard_ActiveProgram_TableHeader_Status: "Státusz",
	Dashboard_ActiveProgram_ActionButton_View: "Nézet",
	Dashboard_CompletedProgram_TableHeader_Title: "Megnevezés",
	Dashboard_CompletedProgram_TableHeader_Status: "Státusz",
	Dashboard_CompletedProgram_ActionButton_View: "Nézet",
	Dashboard_Certificates_TableHeader_Title: "Megnevezés",
	Dashboard_Certificates_TableHeader_Date: "Dátum",
	Dashboard_Certificates_TableHeader_Status: "Státusz",
	Dashboard_Certificates_TableHeader_Action: "Tevékenység",
	Dashboard_Certificates_ActionButton_Download: "Letöltés",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Számlaszám",
	Dashboard_Transactions_TableHeader_Description: "Leírás",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Fizetési mód",
	Dashboard_Transactions_TableHeader_Date: "Dátum",
	Dashboard_Transactions_TableHeader_Amount: "Összeg",
	Dashboard_Transactions_ActionButton_Download: "Letöltés",
	Breadcrumb_MyTraining: "Oktatásaim",
	Breadcrumb_MyTraining_CourseDetails: "Tanfolyam részletei",
	Breadcrumb_MyTraining_Curricula: "Képzés",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Képzési program részletei",
	Breadcrumb_MyTraining_Team: "Csapat",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Munkavállaló megtekintése",
	MyTraining_Headerbar_CourseDetails: "Tanfolyam részletei",
	MyTraining_Headerbar_TrainingProgramDetails: "Képzési program részletei",
	TrainingProgramDetails_Label_Status: "Státusz",
	TrainingProgramDetails_Label_OverallProgress: "Összesített fejlődés",
	TrainingProgramDetails_ProgramItems_Headline: "Program tételek",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Megnevezés",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Státusz",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Fokozat",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Dátum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Nézet",
	TrainingProgramDetails_Status_InProgress: "Folyamatban",
	TrainingProgramDetails_Status_Completed: "Teljesítve",
	CourseDetails_Label_Status: "Státusz",
	CourseDetails_Label_Duration: "Időtartam",
	CourseDetails_Label_Trainer: "Oktató",
	CourseDetails_Label_Language: "Nyelv",
	CourseDetails_Label_Score: "Eredmény:",
	CourseDetails_Label_StartDate: "Kezdés időpontja",
	CourseDetails_Label_EndDate: "Befejezés időpontja",
	CourseDetails_Label_Venue: "Helyszín",
	CourseDetails_Label_Address: "Cím",
	CourseDetails_Label_Grade: "Fokozat",
	CourseDetails_Action_Withdraw: "Visszavonás",
	CourseDetails_Action_Evaluate: "Tanfolyam értékelése",
	CourseDetails_Action_Evaluate_Description: "Kérjük, értékelje ezt a tanfolyamot a bizonyítvány elkészítéséhez!",
	CourseDetails_Action_DownloadCertificate: "Bizonyítvány letöltése",
	CourseDetails_Action_JoinWebcast: "Csatlakozás a Webcast-ba",
	CourseDetails_Action_StartCourse: "Tanfolyam kezdése",
	CourseDetails_Action_RestartCourse: "Tanfolyam újrakezdése",
	CourseDetails_Action_RepeatCourse_Description: "Kérjük, ismételje meg és teljesítse ezt a tanfolyamot a bizonyítvány letöltéséhez!",
	CourseDetails_PartSpecificDetails_Headline: "Rész specifikus adatok",
	CourseDetails_PartSpecificDetails_Tab_Part: "Rész",
	CourseDetails_PartSpecificDetails_Description: "Leírás",
	CourseDetails_PartSpecificDetails_StartDate: "Kezdés időpontja",
	CourseDetails_PartSpecificDetails_EndDate: "Befejezés időpontja",
	CourseDetails_PartSpecificDetails_StartTime: "Kezdés ideje",
	CourseDetails_PartSpecificDetails_EndTime: "Befejezés ideje",
	CourseDetails_PartSpecificDetails_Method: "Módszer",
	Dashboard_Team_TableHeader_Name: "Név",
	Dashboard_Team_TableHeader_Role: "Funkció",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Személyes fiók",
	Dashboard_Team_ActionButton_Select: "Kiválaszt",
	Dashboard_Team_PersonalAccount_Activated: "Aktív",
	Dashboard_Team_PersonalAccount_NotActivated: "Nem aktív",
	Dashboard_Team_ActionButton_AddMember: "Tag hozzáadása",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Keresés",
	Dashboard_External_TableHeader_Name: "Név",
	Dashboard_External_ActionButton_AddNew: "Új hozzáadása",
	Dashboard_External_Headline: "Jóváhagyott külsős hozzáférés",
	Dashboard_Workshops_Headline: "Műhelyek",
	Dashboard_Workshops_TableHeader_Company: "Cég",
	Dashboard_Workshops_TableHeader_Address: "Cím",
	Dashboard_Workshops_TableHeader_Postcode: "Irányítószám",
	Dashboard_Workshops_TableHeader_City: "Város",
	Dashboard_Workshops_ActionButton_Select: "Kiválaszt",
	Wallet_ServiceCredits: "Szolgáltatási kreditek",
	Wallet_Headline_CreditBalance: "Kredit egyenleg",
	Wallet_Headline_CreditActivity: "Kredit aktivitás",
	Wallet_Headline_BillingTransactions: "Számlázási tranzakciók",
	Wallet_BillingTransaction_TableHeader_Order: "Rendelés",
	Wallet_BillingTransaction_TableHeader_Date: "Dátum",
	Wallet_BillingTransaction_TableHeader_Amount: "Összeg",
	Wallet_BillingTransaction_TableHeader_Action: "Tevékenység",
	Wallet_CreditActivity_TableHeader_Date: "Dátum",
	Wallet_CreditActivity_TableHeader_Description: "Leírás",
	Wallet_CreditActivity_TableHeader_User: "Felhasználó",
	Wallet_CreditActivity_TableHeader_Amount: "Összeg",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, minden jog fenntartva",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Termék biztonság (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovációk, szabadalmak és licenszek",
	FooterAdditionalLinks_Navigation_Logistics: "Vásárlás és logisztika",
	MenuHeader_MyTraining: "Képzésem",
	MenuHeader_TrainingServices: "Képzés szolgáltatások",
	MenuHeader_OurLocations: "Helyszíneink",
	MenuHeader_ContactsUs: "Kapcsolat",
	MenuHeader_Logout: "Kijelentkezés",
	MenuHeader_FAQ: "GYIK",
	MyProfile_MyBoschId: "My Bosch ID",
	MyProfile_AccountDeletion: "Fiók törlése",
	MyProfile_Edit: "Email megváltoztatása vagy jelszó szerkesztése",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Felhasználói profil szerkesztése",
	BackButton: "Vissza",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Várjuk hívását, és szívesen segítünk. Állunk rendelkezésére a nap 24 órájában, a hét minden napján.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Írja be a megfelelő karaktereket a fenti mezőben látható módon.",
	ContactUs_Email_MandatoryNotice_V3: "Töltse ki a szükséges mezőket.",
	ContactUs_Email_SendButton: "Küldés",
	Breadcrumb_TrainingCenters: "Oktató Központok",
	Training_Centres_Book_Btn: "Kurzus foglalása",
	Training_centres_booking_code: "Foglalás száma",
	Training_centers_header_bar_title: "Oktató Központjaink",
	Training_centers_filter_item: "Központ",
	TeamEdit_PersonalAccountToolTop: "Ezen funkció engedélyezése lehetővé teszi, hogy a munkavállaló hozzáférjen ehhez a webhelyhez. A felhasználó létrehozhat egy személyes fiókot, és része lehet az Ön vállalati fiókjának. Amint engedélyésre kerül, meghívót küldhet egy aktiváló kóddal a munkavállaló részére. A meghívót az e-mail címre küldjük.",
	TeamEdit_Update: "A cég adminisztrátori funkció engedélyezésével ez automatikusan teljes hozzáférést biztosít a munkavállaló számára a vállalati fiókjához. A funkció engedélyezéséhez a felhasználónak ki kell jelentkeznie, majd vissza kell jelentkeznie a webhelyre, ha a felhasználó már be van jelentkezve.",
	TeamEdit_PersonalAccount: "Személyes Bosch Training Solutions webfiók engedélyezése",
	TeamEdit_SendInvitation: "Meghívó küldése",
	TeamEdit_Deactivate: "Deaktiválás",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "A mobilnak számokból kell állnia",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "A telefonnak számokból kell állnia",
	TeamEdit_Activate: "Aktív",
	TeamEdit_NotActivated: "Nem aktivált",
	TeamEdit_StatusPopup: "Az állapot „Aktív” lesz, ha a felhasználó sikeresen létrehozott egy személyes fiókot. Ha az állapot „Nem aktív”, az azt jelenti, hogy a felhasználó nem fejezte be a meghívást.",
	Training_Catalog_Courses: "Tanfolyamok",
	Training_Catalog_Course: "Tanfolyam",
	Reset_Filter: "Szűrő visszaállítása",
	TrainingCatalogue_CourseCatalogue: "Tanfolyam katalógus",
	CheckoutHeader_CartItemCount_Text: "[count] tétel van a kosarában",
	CheckoutHeader_CartItemsCount_Text: "[count] tétel van a kosarában",
	ShoppingCart_PriceItem: "Tétel ára",
	ShoppingCartItemParticipants_AddParticipant_Button: "Résztvevők hozzáadása",
	ShoppingCartTotalPrice_SubTotalLabel: "Részösszeg",
	ShoppingCartTotalPrice_TaxesLabel: "Adók",
	ShoppingCartTotalPrice_TotalLabel: "Teljes összeg",
	ShoppingCartTotalPrice_IncludedVATLabel: "[vat_amount] áfát tartalmaz",
	AddVoucher_AvailablePaymentMethods: "Elérhető online fizetési módok",
	ShoppingCartItemRow_VenueFeeLabel: "[díj_összege] helyszín díja résztvevőként",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Résztvevő hozzáadása",
	AddParticipantsModal_SelectEmployeeLabel: "Résztvevő hozzáadása",
	AddParticipantsModal_SelectWorkshopLabel: "Műhely kiválasztása",
	ShoppingCartTotalPrice_ContinueButtonText: "Tovább a pénztárba",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Nincs hozzáadott résztvevő!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Egy vagy több tanfolyam van a kosarában résztvevő nélkül.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Kérjük, adjon hozzá egy résztvevőt a fizetés folytatásához.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Résztvevők szerkesztése",
	AddParticipantsModal_ParticipantTable_Name: "Név",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Művelet",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nincsenek résztvevők…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Az e-mail cím nincs konfigurálva a kiválasztott résztvevőnél",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "A kiválasztott résztvevő profiljában nincs beállítva e-mail cím. Az e-mail cím megadása kötelező a tanfolyamra való regisztrációhoz.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Adjon hozzá e-mail címet a folytatáshoz, vagy törölje azt egy másik résztvevő kiválasztásához.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "E-mail cím hozzáadása",
	CancelButton: "Vissza",
	AddParticipantsModal_AddEmployeeButtonText: "Alkalmazott hozzáadása",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Egy tanfolyam törlésére készül a kosarából",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Olyan tanfolyamot próbál eltávolítani a kosarából, amelyhez résztvevők vannak hozzáadva.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Kattintson a \"Tovább\" gombra, ha tovább szeretne lépni, vagy kattintson a \"Mégse\" gombra.",
	ShoppingCartItemHeader_Participants: "Résztvevő(k)",
	ShoppingCartItemHeader_ParticipantPrice: "Ár/férőhely",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Megjelenítés [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Megjelenítés kreditekben",
	ShoppingCart_CreditsPriceUnit: "Kreditek",
	CheckOutHeader_Step1_Question: "Hogyan szeretne fizetni?",
	CheckOutHeader_Step2_Question: "Nézze át rendelését",
	CheckOutHeader_Step3_Question: "Fejezze be megrendelését",
	CheckOutHeader_Step4_Question: "Foglalása sikeres volt!",
	CheckOutHeader_Step_Payment: "Fizetés",
	CheckOutHeader_Step_Review: "Áttekintés",
	CheckOutHeader_Step_Place_Order: "Rendelés leadása",
	CheckOutRightPanel_Review_Order_Btn: "Rendelés áttekintése",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Rendelés leadása",
	CheckoutRightPanelContent_OrderSummary_Title: "Megrendelés összesítő",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Bevásárló kosár szerkesztése",
	ShoppingCart_Participants: "Résztvevők",
	CheckOutStepPayment_Payment_Methods_Title: "Fizetési mód kiválasztása",
	CheckOutStepPayment_Credit_Title: "Kredit vagy terhelés",
	CheckOutStepPayment_Wholesaler_Title: "Nagykereskedő",
	CheckOutStepPayment_ServiceCredit_Title: "Szolgáltatási kreditek",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "[elérhető kredit] kredite van",
	CheckOutStepPayment_BoschAccount_Title: "Bosch Fiók",
	Checkout_Second_PaymentMethod_Headline: "Fizetési mód",
	CheckOutStepReview_Edit: "Szerkesztés",
	CheckOutStepReview_Terms_Label: "Felhasználói feltételek és lemondási szabályzat",
	CheckOutStepReview_Terms_Text: "Elolvastam, megértettem és elfogadom az értékesítés felhasználói feltételeit és lemondási feltételeit.",
	SelectSecondPaymentMethodModal_Title: "Másodlagos fizetési mód kiválasztása",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Fizetendő [amount]",
	StepPaymentWholesaler_Title: "Nagykereskedő kiválasztása",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "ÁSZF elfogadása",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Kérjük, a folytatás előtt olvassa el és fogadja el felhasználói feltételeinket és lemondási feltételeinket.",
	CheckoutDescription_ThankYou: "Köszönjük, hogy megrendelést adott le a Bosch Automotive Training Solutions-on!",
	CheckoutDescription_Message: "Bitjeink és bytejaink már megkezdték megrendelésének feldolgozását. Eközben kérjük, hagyja alapjáraton a motort.",
	CheckoutDesctiption_Text: "E-mailben küldünk Önnek egy végső visszaigazolást, amely tartalmazza a foglalás minden részletét.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "A kiválasztott műhely eltávolítására készül",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "A kiválasztott műhely eltávolításával az összes hozzáadott résztvevő visszaáll. Ez a változás a kosarában lévő összes tanfolyamot érinti.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Kattintson a \"Tovább\" gombra, ha folytatni kívánja, vagy kattintson a \"Mégse\" gombra a művelet leállításához.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Nincs elég kreditje. A jóváírás egyenlege [elérhető_kredit] kredit, de a fizetés folytatásához [kosár_teljes_kreditek] kreditre van szüksége. Kérjük, válasszon másik fizetési módot, vagy távolítsa el a termékeket a kosárból.",
	Training_centers_filter_items: "Központok",
	Training_Programs_Filter_Item: "Programok",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Év(ek)",
	Training_Program_Detail_Title: "Képzési tanfolyam programok",
	AccountButton_Workshops_MenuItem: "Műhelyek",
	AccountButton_Wallet_MenuItem: "Pénztárca",
	Header_MyBoschProfile_Header: "MyBosch Fiók",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Elérhető",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Résztvevő hozzáadása/szerkesztése",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "Áfát tartalmazza",
	TrainingCatalogue_Length: "Hosszúság",
	TrainingCatalogue_Method: "Módszer",
	Course_Price_Credits: "Kreditek",
	Catalogue_Grid_Register_Button: "Regisztráció",
	Catalogue_Credit_Tilte: "Kattintson ide az árak kreditben való megtekintésére",
	Catalogue_Price_Tilte: "Kattintson ide az árak euróban való megtekintésére",
	Catalogue_Grid_Vat: "Áfa nélkül",
	EventCalendar_Seats: "Férőhelyek",
	MenuHeader_Calendar: "Naptár",
	MenuHeader_Catalog: "Katalógus",
	TableCell_Minutes: "Percek",
	ListFilter_NoResult: "Nincs eredmény",
	Course_Description: "Tanfolyam leírás",
	Training_course_detail: "Social Media Alapok",
	Training_course_detail_add_waiting: "Hozzáadás az általános várólistához",
	Register_now_btn: "Regisztráljon most!",
	Register_now_btn_add_event_to_cart: "Regisztráljon most!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Összes dokumentum megtekintése",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Kevesebb megtekintése",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Összes dokumentum megtekintése",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Kevesebb megtekintése",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Összes dokumentum megtekintése",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Kevesebb megtekintése",
	TeamCreate_TitleError: "Megnevezés szükséges",
	Dashboard_Headerbar_BookCourse: "Tanfolyam foglalása",
	Dashboard_Headerbar_ExitProxy: "Vissza az áttekintéshez",
	Dashboard_Headerbar_EvaluateCourse: "Tanfolyam értékelése",
	Dashboard_Headerbar_EvaluateCourse_Note: "Kérjük, értékelje ezt a tanfolyamot a bizonyítvány elkészítéséhez!",
	Dashboard_Headerbar_DownloadCertificate: "Bizonyítvány letöltése",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Visszavonás",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Visszavonás",
	Dashboard_Headerbar_WelcomeMessage: "Üdvözöljük a tanulási irányítópulton",
	Dashboard_Headerbar_ProxingAs: "Áttekintés",
	Dashboard_TrainingProgram_Status_Completed: "Teljesítve",
	Dashboard_TrainingProgram_Status_InProgress: "Folyamatban",
	External_SelectWholesalerModal_Title: "Külső felhasználói hozzáférés hozzáadása",
	External_SelectWholesalerModal_Company_Label: "Cég",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Kérjük válasszon",
	External_SelectWholesalerModal_Outlet_Placeholder: "Kérjük válasszon",
	External_SelectWholesalerModal_ApplyButton: "Jelentkezés",
	External_SelectWholesalerModal_CancelButton: "Mégse",
	External_RemoveWholesalerModal_ConfirmLabel: "Megerősítés",
	External_SelectWholesalerModal_ConfirmMessage: "Biztosan eltávolítja ezt a nagykereskedőt?",
	External_SelectWholesalerModal_YesButton: "Igen",
	External_SelectWholesalerModal_NoButton: "Nem",
	Training_program_team_member: "Csapattag",
	Training_program_assign_training_program: "Képzési program hozzárendelése",
	Assign_modal_student_confirmation_text1: "Arra készül, hogy hozzárendelje a [Training Program Name] programot a tanulási útjához.",
	Assign_modal_student_confirmation_text2: "Szeretné folytatni?",
	MyFullCalendar_Jan: "Január",
	MyFullCalendar_Feb: "Február",
	MyFullCalendar_Mar: "Március",
	MyFullCalendar_Apr: "Április",
	MyFullCalendar_May: "Május",
	MyFullCalendar_Jun: "Június",
	MyFullCalendar_Jul: "Július",
	MyFullCalendar_Aug: "Augusztus",
	MyFullCalendar_Sep: "Szeptember",
	MyFullCalendar_Oct: "Október",
	MyFullCalendar_Nov: "November",
	MyFullCalendar_Dec: "December",
	MenuHeader_CourseCatalog: "Tanfolyam katalógus",
	MenuHeader_Event: "Eseménynaptár",
	MenuHeader_Program: "Képzési program",
	MenuHeader_Services: "Képzési szolgáltatások",
	MenuHeader_Log_out: "Kijelentkezés",
	Filter_Location: "Helyszín",
	TeamEdit_UpgradeToCompanyAdministrator: "Frissítsen cég adminisztrátorra",
	TeamEdit_PersonalWebAccountStatusIs: "A személyes webfiók állapota",
	Header_CreateNewEmployee: "Új munkavállaló létrehozása",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Nincs szabad hely!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "A kiválasztott eseményen nincs szabad hely.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "A folytatáshoz válasszon másik eseményt, vagy távolítson el egy már felvett résztvevőt.",
	Common_SuccessModal_Title: "Gratulálunk!",
	TrainingProgramDetails_Merit_StatusText: "Elvégezve",
	TrainingProgramDetails_Resit_StatusText: "Ismétlés",
	EmployeeProfile_UserExistErrorMessage: "Ez a fiók már létezik!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Sikertelen fizetés. Kérjük, próbálja újra, vagy használjon másik fizetési módot.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Sikeresen deaktiválta a kiválasztott munkavállalót!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Mentve!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Változtatásait elmentettük.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Az eLearning tanfolyamokat visszavonni nem lehet. Kérjük, vegye fel velünk a kapcsolatot, ha további részletekre van szüksége.",
	Dashboard_Team_PersonalAccount_Pending: "Függő",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Munkavállaló létrehozva!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Az munkavállaló létrehozása sikeresen befejeződött.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Ez a beállítás lehetővé teszi, hogy korlátozza a munkavállalók regisztrációját a képzési eseményekre. Csak a céges adminisztrátorok regisztrálhatják a munkavállalót képzési eseményekre, ha ez a beállítás nincs kiválasztva.",
	Training_catalog_sort_by: "Sorrend",
	CourseDetail_EvaluationRestriction_Title: "Képzés értékelése nem lehetséges!",
	CourseDetail_EvaluationRestriction_Message: "Képzési értékelést csak a tanuló saját maga nyújthat be. \nKérjük, utasítsa a tanulót, hogy jelentkezzen be a Bosch Automotive Training Solutions-ba, és fejezze be a képzés értékelését.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Ha a tanulónak még nincs fiókja, a  felhasználói profil csapattag beállításaiban küldhet egy meghívót.",
	Breadcrumb_Download_Page: "Letöltés",
	Download_Table_Category_Cell: "Kategória",
	Download_Table_Title_Cell: "Megnevezés",
	Download_File_Filter_Item: "Fájl",
	Download_File_Filter_Items: "Fájlok",
	Download_Header: "Letöltési felület",
	DownloadArea_Button_Download: "Letöltés",
	Popup_Title_Error: "Hiba",
	Popup_Title_Success: "Siker",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Figyelem!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "A következő potenciális események már meglévő regisztrációi nem kerülnek törlésre, és egyenként kell lemondani azokat.",
	TrainingProgram_PDF_Export: "PDF exportálása",
	TrainingProgram_PDF_Export_Participant: "Résztvevő:",
	TrainingProgram_PDF_Export_Status: "Státusz:",
	TrainingProgram_PDF_Export_Progress: "Összesített fejlődés:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Képzés megnevezése",
	TrainingProgram_PDF_Export_TableHeader_Status: "Státusz",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Fokozat",
	TrainingProgram_PDF_Export_TableHeader_Date: "Dátum",
	AccountButton_CompanyChange_MenuItem: "Cég megváltoztatása",
	SelectCompanyModal_Title: "Cég kiválasztása",
	CloseButton: "Bezár",
	Change_Button: "Változtatás",
	ChangeManagerModal_Content: "Felhasználó választása",
	ChangeCompanyModal_HeadOffice: "(Központ)",
	SelectCompanyModal_Content: "Kérjük válasszon céget a bejelentkezéshez…",
	AlertSuccess_Title: "Siker",
	CheckOutStepPayment_SecondaryPayment_Title: "Másodlagos fizetési mód kiválasztása",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Másodlagos fizetési mód hozzáadása",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Kérjük, adja meg alább a másodlagos fizetési módot, vagy válasszon másik fizetési módot fent.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Nincs elegendő szolgáltatási kredit a számláján a kosár tételeinek kifizetéséhez. A választott fizetési mód használatának folytatásához ki kell fizetnie a különbözetet. A különbség [különbség_összeg] (áfa nélkül).",
	ShoppingCartTotalPrice_TotalInCredit: "Végösszeg kreditekben",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Elérhető kreditjei",
	ShoppingCartTotalPrice_YourDifference: "Különbözet",
	ShoppingCartTotalPrice_SubtotalExTax: "Részösszeg áfa nélkül",
	ShoppingCartTotalPrice_SubtotalInTax: "Részösszeg áfával",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Másodlagos fizetési mód",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Fizetési mód szerkesztése",
	Checkout_Second_PaymentMethod: "Másodlagos fizetési mód",
	Breadcrumb_TrainingCourseDetails: "Képzési tanfolyam részletek",
	DownloadArea_FailedToDownload_ErrorMessage: "Nem sikerült letölteni a dokumentumot. Kérjük, próbálja újra, vagy kérjen segítséget a cég adminisztrátortól.",
	DownloadArea_FileNotAvailable_ErrorMessage: "A fájl letöltése tilos. Kérjük, próbálja újra, vagy kérjen segítséget a cég adminisztrátortól.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "A fájl letöltése tilos. Kérjük, próbálja újra, vagy kérjen segítséget a cég adminisztrátortól.",
	SSO_Redirecting_Message: "Ön átirányításra kerül a BATS portálra...",
	SSO_Redirecting_ParameterErrorMessage: "Az ország és/vagy a nyelv nincs megadva. Kérjük, próbálja újra, vagy kérjen segítséget a cég adminisztrátortól.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "A jelentkezési kérelmet/kérelmeket nem fogadták el.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Ennek oka lehet, hogy egy vagy több tanfolyamra a tanfolyam regisztrációs határnapjain belül van. Kérjük, használja a „Kapcsolat” részt, ha bármilyen kérdése vagy kérése van.",
	TimeSlotPicker_DateFormat_Title: "Dátum formátum",
	UpdateAccountSettingsConfirmationModal_Title: "A dátumformátum módosítására készül!",
	UpdateAccountSettingsConfirmationModal_Message1: "A dátumformátum az Ön választásának megfelelően jelenik meg, és bármikor módosítható.",
	UpdateAccountSettingsConfirmationModal_Message2: "Szeretné folytatni?",
	ProfileSummary_Settings_Title: "Beállítások",
	UpdateAccountSettings_DateFormat_Title: "Dátumformátum beállításai",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nem sikerült frissíteni a dátumformátumot.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "A dátumformátum beállítása sikeresen frissítve",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "lemondási szabályzat",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Vissza",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Folytatás"
};