import { FC, useState, useEffect } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Down } from "../../assets/svg/down.svg";
import "./LanguageSelectorDropDown.css";
import { useDispatch, useSelector } from "react-redux";
import {
  authSetLanguage,
  selectAuthState,
} from "../../redux-toolkit/authReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import {
  resetAssignedTrainingPrograms,
  resetTrainingPrograms,
} from "../../redux-toolkit/trainingProgramReducer";
import { resetContactPersons } from "../../redux-toolkit/contactReducer";
import { resetTrainingCourses } from "../../redux-toolkit/trainingCourseReducer";
import { resetTrainingEvents } from "../../redux-toolkit/trainingEventReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";
import { resetEventEnrollmentSummaryList, resetMyTrainingEvents } from "../../redux-toolkit/dashboardReducer";
interface LanguageSelectorDropDownProps {
  cssClassName: string;
}

const LanguageSelectorDropDown: FC<LanguageSelectorDropDownProps> = (
  props: any
) => {
  // const languageOptions = [
  //     { key: "en", text: "English", value: "en" },
  //     { key: "de", text: "Deutsch", value: "de" },
  // ]
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const languageOptions =
    !!authState.languages && authState.languages.length > 0
      ? authState.languages.map((x) => {
          return {
            key: x.localized,
            text: x.localized,
            value: x.lcid,
          };
        })
      : [
          {
            key: "English",
            text: "English",
            value: "en-US",
          },
        ];

  // const [languageSelected, setLanguageSelected] = useState<string>("");
  const [cssClassName, setCssClassName] = useState<string>("");
  const { i18n } = useTranslation();

  useEffect(() => {
    //let languageFromLocalStorage = i18n.language;
    //let languageFullName = languageOptions.find((val: any) => val && val.key === languageFromLocalStorage)
    // setLanguageSelected(languageFullName ? languageFullName.text : 'English');
    setCssClassName(props?.cssClassName ? props?.cssClassName : "");
  }, [i18n.language]);

  const switchLanguage = (event: any, data: any) => {
    i18n.changeLanguage(data.value.trim());
    if (props.openCallback) props.openCallback(false);
    dispatch(authSetLanguage(data.value));

    // Force to reload data
    reloadPersonalData();
    forceToReloadData();
  };

  const reloadPersonalData = () => {
    // clear all assigned training programs
    dispatchReduxToolkit(resetAssignedTrainingPrograms(undefined));

    // clear all assigned training events
    dispatchReduxToolkit(resetMyTrainingEvents(undefined));

    // clear all contact persons
    dispatchReduxToolkit(resetContactPersons(undefined));

    // force to reload team EventEnrollmentSummaryList data.
    dispatchReduxToolkit(resetEventEnrollmentSummaryList(undefined));
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  return (
    <Dropdown
      className={cssClassName}
      floating
      labeled
      options={languageOptions}
      value={i18n.language}
      icon={
        <Icon
          className="language-selector-dropdown-icon"
          style={{ float: "right" }}
        >
          <Down />
        </Icon>
      }
      onChange={switchLanguage}
    ></Dropdown>
  );
};

export default LanguageSelectorDropDown;
