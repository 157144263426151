export const TRANSLATIONS_TH = {
	LandingPage_Title: "ยินดีต้อนรับสู่ Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "เลือกประเทศ / ภูมิภาคของคุณ",
	Header_ContactUs: "ติดต่อเรา",
	Header_Search: "ค้นหา",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "เข้าสู่ระบบหรือสร้างบัญชีของคุณ",
	Header_LogIn: "เข้าสู่ระบบ",
	Header_Cart: "รถเข็น",
	Header_ShoppingCart: "ตะกร้าสินค้า",
	Header_LogOut: "ออกจากระบบ",
	Header_ChangeDetails: "เปลี่ยนรายละเอียด",
	Footer_ExploreOurTraining: "สำรวจการฝึกอบรมของเรา",
	Footer_TrainingAtYourFingertips: "ฝึกได้เพียงปลายนิ้ว",
	Footer_DontMissAnyStory: "ไม่พลาดทุกเรื่องราว",
	Footer_ExploreOurStories: "สำรวจเรื่องราวของเรา",
	Footer_GetInTouchWithUs: "ติดต่อกับพวกเรา",
	Footer_SendUsAMessage: "ส่งข้อความหาเรา",
	Footer_ToContactForm: "แบบฟอร์มการติดต่อ",
	Footer_InventedForLife: "คิดค้นมาเพื่อชีวิต",
	Footer_GeneralContactInfo: "ข้อมูลติดต่อทั่วไป",
	Footer_ProductSecurity_Psirt: "ความปลอดภัยของผลิตภัณฑ์ (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "นวัตกรรมสิทธิบัตรและใบอนุญาต",
	Footer_PurchasingLogistics: "การจัดซื้อและโลจิสติกส์",
	Footer_CorporateInformation: "ข้อมูลองค์กร",
	Footer_LegalNotice: "ประกาศทางกฎหมาย",
	Footer_DataProtectionNotice: "ประกาศเกี่ยวกับการคุ้มครองข้อมูล",
	Footer_PrivacySettings: "การตั้งค่าความเป็นส่วนตัว",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Роберт Бош ЕООД, Всички права запазени",
	Footer_StillLookingForSomething: "ยังคงมองหาบางสิ่งบางอย่าง",
	Footer_TrainingServices: "บริการฝึกอบรม",
	Footer_Courses: "หลักสูตร",
	Footer_Programs: "โปรแกรม",
	Footer_EventsCalendar: "ปฏิทินกิจกรรม",
	Footer_TrainingCenters: "ศูนย์ฝึกอบรม",
	Footer_Dashboard: "แผงควบคุม",
	Footer_MyDashboard: "แดชบอร์ดของฉัน",
	Footer_ShoppingCart: "ตะกร้าสินค้า",
	Footer_YourShoppingCart: "ตะกร้าสินค้าของคุณ",
	Footer_Administration: "การบริหาร",
	Footer_Employees: "พนักงาน",
	Footer_Wholesalers: "ผู้ค้าส่ง",
	Footer_Transactions: "ธุรกรรม",
	Footer_Workshops: "เวิร์คช็อป",
	Footer_WorkLikeABoschApplyNow: "ทำงาน  - สมัครเลย",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "การจัดซื้อและการขนส่ง",
	Footer_BecomeABusinessPartner: "มาเป็นพันธมิตรทางธุรกิจ",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "การฝึกอบรมของเราพัฒนาขึ้นเพื่อช่วยธุรกิจของคุณอย่างไร",
	Footer_Language: "เลือกประเทศ",
	MenuPopup_MyCart: "รถเข็นของฉัน",
	Stories_OurTrainingStories: "เรื่องราวการฝึกอบรมของเรา",
	Stories_ConnectingExperts: "เชื่อมต่อผู้เชี่ยวชาญและผู้ที่ชื่นชอบนักฝันและนักประดิษฐ์และผู้มีอิทธิพล - สำรวจและสัมผัส",
	Stories_TopStories: "เรื่องเด่น",
	Stories_AtHome: "ที่บ้าน",
	Stories_Careers: "อาชีพ",
	Stories_History: "ประวัติศาสตร์",
	Stories_IndustryAndTrades: "อุตสาหกรรมและการค้า",
	Stories_Mobility: "ความคล่องตัว",
	Stories_Research: "การวิจัย",
	Stories_Sustainability: "ความยั่งยืน",
	Stories_AllStories: "เรื่องราวทั้งหมด",
	Stories_BlogATrainersDay: "บล็อก | วันเทรนเนอร์",
	Stories_ADayinLifeOfATrainer: "หนึ่งวันในชีวิตของเทรนเนอร์",
	Stories_BoschServiceTraining: "การฝึกอบรมการบริการของบ๊อช",
	Stories_StoryWhatDrivesYouDrivesUs: "เรื่องราว | อะไรเป็นแรงผลักดันให้คุณขับเคลื่อนเรา",
	Stories_StoryHowCanWeBenefitYourBusiness: "เรื่องราว | เราจะให้ประโยชน์กับธุรกิจของคุณได้อย่างไร",
	Stories_HowOurTrainingEvolves: "การฝึกอบรมของเรามีวิวัฒนาการอย่างไร",
	Stories_AModernTrainingApproach: "โลกแห่งการฝึกอบรมออนไลน์",
	Stories_StoryAVirtualOnlineTrainingWorld: "เรื่องราว | โอกาสในการฝึกอบรมออนไลน์ของคุณ",
	Stories_SubscribeToRSSFeed: "สมัครรับฟีด RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "เกี่ยวกับเรา",
	Homepage_AutomotiveAftermarket: "อะไหล่รถยนต์",
	Homepage_GetInTouch: "ได้รับการติดต่อ",
	Homepage_LatestNews: "ค้นหาว่าทำไมเราถึงถูกขับเคลื่อนด้วยความกระหายในความรู้",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "ตำแหน่งงานว่างของเรา",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "อะไรเป็นแรงผลักดันให้คุณขับเคลื่อนเรา",
	BoschServiceTraining_BoschServiceTraining: "การฝึกอบรมการบริการของบ๊อช",
	BoschServiceTraining_ShareThisOn: "แบ่งปันสิ่งนี้บน",
	BoschServiceTraining_TheChallenge: "ความท้าทาย: การเรียนรู้เทคโนโลยีใหม่ๆ",
	BoschServiceTraining_WorldwidePresence: "การแสดงตนทั่วโลก",
	BoschServiceTraining_TechnicalTraining: "หลักสูตรฝึกอบรมด้านเทคนิคจาก Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "ข้อเสนอและโอกาสที่ Bosch มอบให้",
	BoschServiceTraining_TechnicalCoursesDesc: "มีหลักสูตรอบรมที่เหมาะสมกับกลุ่มเป้าหมายทุกกลุ่ม ไม่ว่าจะเป็นมือใหม่หรือมือโปร ข้อเสนอนี้รวมถึงหลักสูตรฝึกอบรมหนึ่งวันและหลักสูตรฝึกอบรมเป็นเวลาหลายวัน หลักสูตรฝึกอบรมนี้เปิดสอนในหัวข้อทางเทคนิคและชุดการฝึกอบรมที่แตกต่างกัน (เช่น ระบบไฟฟ้าแบบฉีดเชื้อเพลิงแบบฉีดน้ำมันดีเซล) ในหลักสูตรฝึกอบรมด้านเทคนิค Bosch ได้มอบองค์ความรู้ที่ครอบคลุมเกี่ยวกับระบบยานยนต์มาตรฐานทั้งหมดจากระบบของ Bosch จากผู้ผลิตรายอื่น ยังได้กล่าวถึงในหลักสูตรการฝึกอบรมอีกด้วย เนื้อหาตรงกับความต้องการของพนักงานเวิร์คช็อปเป็นพิเศษ",
	BoschServiceTraining_TrainedEmployeesDesc: "พนักงานที่ผ่านการฝึกอบรมเป็นประโยชน์อย่างชัดเจนสำหรับการประชุมเชิงปฏิบัติการยานยนต์ เนื่องจากยานพาหนะในปัจจุบันมีความซับซ้อนมากขึ้นเรื่อย ๆ และเทคโนโลยีใหม่ ๆ นำเสนอความท้าทายใหม่ ๆ อย่างต่อเนื่อง เฉพาะความเชี่ยวชาญที่จำเป็นเท่านั้นที่ทำให้พนักงานของศูนย์บริการสามารถดำเนินการบำรุงรักษาและซ่อมแซมการวินิจฉัยในรถรุ่นปัจจุบันได้อย่างมีประสิทธิภาพและคุ้มค่า",
	BoschServiceTraining_RegardlessOfHow: "ไม่ว่าผู้เชี่ยวชาญด้านยานยนต์จะดีและมีประสบการณ์เพียงใด - มักจะมีสถานการณ์ที่พวกเขาจะต้องติดต่อกับระบบเทคโนโลยียานยนต์ใหม่หรือข้อผิดพลาดที่ไม่ทราบสาเหตุ ในเรื่องนี้การแก้ไขปัญหาและการแก้ปัญหาอาจใช้เวลานานเป็นพิเศษหรืออาจไม่แสดงผลสำเร็จ ในกรณีที่เลวร้ายที่สุด ลูกค้าต้องทำโดยไม่มีรถนานเกินคาด",
	BoschServiceTraining_ItIsPrecisely: "ณ จุดนี้เองที่ Bosch ได้นำ Service Training มารองรับ นั่นคือ การปรับปรุงคุณภาพการบริการและการเร่งกระบวนการ การฝึกอบรมในปัจจุบันของ Bosch ให้การสนับสนุนในการระบุปัญหาได้รวดเร็วขึ้น การซ่อมแซมยานพาหนะจำนวนมากขึ้นในเวลาเดียวกัน และลดเวลารอรับสายของลูกค้าของคุณ ดังนั้นขั้นตอนที่เป็นระบบที่เรียนรู้จาก Bosch จึงมั่นใจได้ว่างานจะเร็วและได้ประสิทธิผลมากกว่า",
	BoschServiceTraining_WorldwidePresenceDesc1: "เป็นเวลากว่า 125 ปี ที่บ๊อชเป็นซัพพลายเออร์ที่ต้องการสำหรับส่วนประกอบและระบบชิ้นส่วนอุปกรณ์ดั้งเดิมสำหรับผู้ผลิตรถยนต์ชั้นนำทั่วโลก ประสบการณ์นี้มาพร้อมกับความรู้เฉพาะตัวเกี่ยวกับเทคโนโลยีล่าสุดที่ทำหน้าที่เป็นพื้นฐานสำหรับการสนับสนุนของ Bosch",
	BoschServiceTraining_WorldwidePresenceDesc2: "ศูนย์บริการฝึกอบรมของบ๊อชสามารถพบได้ทุกที่ทั่วโลก ตั้งแต่สเปนจนถึงไก่งวง แอฟริกาใต้ ออสเตรเลียไปจนถึงบราซิล - บ๊อชมีการฝึกอบรมพนักงานที่มุ่งเน้นการปฏิบัติจริง ซึ่งสอดคล้องกับเทคโนโลยีล้ำสมัยอยู่เสมอ",
	BoschServiceTraining_AgileCompany: "บริษัท Agile",
	BoschServiceTraining_Collaboration: "การทำงานร่วมกัน",
	BoschServiceTraining_ConnectedMobility: "ความคล่องตัวที่เชื่อมต่อ",
	BoschServiceTraining_ArtificialIntelligence: "ปัญญาประดิษฐ์",
	BoschServiceTraining_ThoughtLeadersInDialogue: "ผู้นำทางความคิดในบทสนทนา",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "อุปกรณ์วินิจฉัยและทดสอบ",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "ความรู้พื้นฐานด้านเทคโนโลยียานยนต์",
	BoschServiceTraining_Icon_Gasoline: "น้ำมันเบนซิน",
	BoschServiceTraining_Icon_Diesel: "ดีเซล",
	BoschServiceTraining_Icon_AlternativeDrives: "ไดรฟ์ทางเลือก",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "ไฟฟ้าและอิเล็กทรอนิกส์",
	BoschServiceTraining_Icon_BodyAndComfort: "ร่างกายและความสบาย",
	BoschServiceTraining_Icon_ChassisAndBraking: "แชสซีส์และระบบเบรก",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "ระบบส่งกำลังและระบบขับเคลื่อน",
	BoschServiceTraining_Icon_CommercialVehicles: "รถยนต์เพื่อการพาณิชย์",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "หลักสูตรฝึกอบรมออนไลน์",
	BoschServiceTraining_Icon_BusinessSkills: "ทักษะทางธุรกิจ",
	BoschServiceTraining_Icon_Accreditations: "ได้รับการรับรอง",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "หลักสูตรการอบรม",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "เทรนเนอร์FirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "หนึ่งวันในชีวิตของเทรนเนอร์",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "ทุกวันคือการผจญภัย",
	ADayInTheLifeOfATrainer_Summary: "สรุป",
	ADayInTheLifeOfATrainer_TheDayJourney: "การเดินทางของวันนี้",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "ทุกวันเป็นวันที่วุ่นวาย แต่ก็เป็นประสบการณ์ที่คุ้มค่าเสมอ ความสุขของการฝึกอบรมนักเรียนโดยรู้ว่าพวกเขาถือกุญแจสู่อนาคตของเราคือความรับผิดชอบ แต่ในขณะเดียวกันก็น่าตื่นเต้นมาก การได้เห็นนักเรียนเติบโตขึ้นในแต่ละบทเรียนทุกวันและทุกสัปดาห์พิสูจน์ให้เราเห็นแล้วว่าเรากำลังดำเนินการเพื่อมุ่งสู่เป้าหมายร่วมกันเพื่อปรับปรุงความรู้ของพวกเขาในขณะที่ผสมผสานเทคโนโลยีสมัยใหม่ที่ขับเคลื่อนอนาคต!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "ในตอนท้ายของแต่ละวัน เราได้นั่งลงและไตร่ตรองถึงวันทำงาน วันเวลาผ่านไปอย่างรวดเร็ว แต่นี่เป็นข้อพิสูจน์ว่าสิ่งที่เราบรรลุไม่ได้เป็นเพียงรางวัลสำหรับนักเรียนเท่านั้น แต่สำหรับเราในฐานะผู้ฝึกสอนด้วย",
	ADayInTheLifeOfATrainer_WeCanGoHome: "เราสามารถกลับบ้านได้ในตอนท้ายของวัน พอใจที่เราได้ทำผลงานที่ยอดเยี่ยม!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "เริ่ม",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "การเตรียมและตรวจสอบขั้นสุดท้ายของเอกสารการฝึกอบรม ตรวจสอบจดหมายสำคัญ",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "เริ่มอบรม",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "ยินดีต้อนรับผู้เข้าร่วม การนำเสนอกำหนดการประจำวัน ทำความรู้จักกับกลุ่มโดยทำความเข้าใจพลวัตของแต่ละคน",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "ทฤษฎี",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "จำเป็นต้องมีปฏิสัมพันธ์กับกลุ่มจำนวนหนึ่งเพื่อทำให้การฝึกอบรมน่าสนใจที่สุด ตรวจสอบให้แน่ใจว่ามีงานที่เกี่ยวข้องกับทฤษฎีเพื่อดึงดูดกลุ่มต่อไป",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "พักกลางวัน",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "ใช้ได้จริง",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "การฝึกอบรมยังคงดำเนินต่อไปในเวิร์กช็อปโดยมีงานจริงบางส่วน ในฐานะผู้ฝึกสอน จำเป็นต้องมีเสมอและพร้อมเสมอสำหรับเหตุการณ์และคำแนะนำใดๆ",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "สรุป",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "กลับไปที่ห้องเรียนสั้น ๆ เพื่อหารือเกี่ยวกับการฝึกอบรมของวันนั้น ตอบคำถามใด ๆ ที่อาจเกิดขึ้น เริ่มการเตรียมตัวสำหรับการฝึกอบรมครั้งต่อไป",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "วันสุดท้าย",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "ใบรับรองการเข้าร่วมจะถูกนำเสนอต่อผู้เข้าร่วม",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "บ้าน,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "เราจะช่วยธุรกิจคุณให้เป็นประโยชน์ได้อย่างไร",
	BoschTrainingBenefits_HowOurTrainingEvolves: "การฝึกอบรมของเรามีวิวัฒนาการอย่างไร",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "เรากำลังพัฒนาอย่างต่อเนื่องเพื่อให้แน่ใจว่าเราส่งมอบการฝึกอบรมที่มีคุณภาพที่ทันสมัย",
	BoschTrainingBenefits_TheBoschServiceTraining: "ศูนย์บริการฝึกอบรมของ Bosch ต้องการช่วยเหลือคุณในการพัฒนาธุรกิจของคุณผ่านพอร์ตโฟลิโอการฝึกอบรมด้านเทคนิค/ธุรกิจและหลักสูตรการศึกษาเพิ่มเติมที่กว้างขวางและมีความเกี่ยวข้องสูงพร้อมด้วยคุณวุฒิที่ผ่านการรับรอง หน้าต่อไปนี้จะให้ภาพรวมของโปรแกรมการฝึกอบรมของเรารวมถึงโอกาสในการศึกษาต่อในปัจจุบัน เราหวังว่าจะได้พบคุณและพนักงานของคุณในหลักสูตรการฝึกอบรมและการสัมมนาของเรา",
	BoschTrainingBenefits_LongStandingExperience: "ด้วยประสบการณ์อันยาวนานและความรู้ความชำนาญที่ครอบคลุมทุกระบบและในด้านเทคโนโลยียานยนต์",
	BoschTrainingBenefits_QuickAndEfficient: "วิธีแก้ปัญหาที่รวดเร็วและมีประสิทธิภาพสำหรับปัญหามากมาย",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "ข้อดีและประโยชน์",
	BoschTrainingBenefits_LMS: "ระบบการจัดการการเรียนรู้ - โซลูชั่นการฝึกอบรมของ Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "ได้มาซึ่งความรู้สิ่งอำนวยความสะดวก ซ่อมแซม บำรุงรักษา และงานบริการ",
	BoschTrainingBenefits_TheHighDegree: "เนื้อหาเชิงปฏิบัติระดับสูงและกลุ่มย่อยรับประกันการเรียนรู้ที่มุ่งเน้นเป้าหมาย",
	BoschTrainingBenefits_ParticipationInTraining: "การเข้าร่วมการฝึกอบรมช่วยเพิ่มแรงจูงใจและความพึงพอใจของพนักงาน และคุณสมบัติเพิ่มเติมโน้มน้าวใจลูกค้า",
	BoschTrainingBenefits_IncreasedSales: "เพิ่มยอดขายผ่านความรู้ระบบข้ามกลุ่ม",
	BoschTrainingBenefits_MethodicalProcedures: "ระเบียบวิธีช่วยลดเวลารอรับลูกค้า",
	BoschTrainingBenefits_HighlyQualified: "อาจารย์ผู้ทรงคุณวุฒิถ่ายทอดกระบวนการอย่างเป็นระบบ",
	BoschTrainingBenefits_IdentificationOftheTraining: "การระบุโซลูชันการฝึกอบรมที่เหมาะสมกับคุณหรือธุรกิจของคุณ",
	BoschTrainingBenefits_DirectOnlineBooking: "การจองออนไลน์โดยตรงและการจัดการหลักสูตรทั้งสำหรับตัวคุณเองหรือพนักงานของคุณ",
	BoschTrainingBenefits_OnlineAccess: "การเข้าถึงเอกสารการเรียนรู้ออนไลน์",
	BoschTrainingBenefits_AccessToYourOwn: "เข้าถึงประวัติและใบรับรองการฝึกอบรมส่วนบุคคลของคุณเอง",
	BoschTrainingBenefits_ToolsForManagement: "เครื่องมือสำหรับการจัดการการพัฒนาอาชีพของคุณเอง",
	BoschTrainingBenefits_ManagerHasFull: "ผู้จัดการมีภาพรวมของหลักสูตรพนักงานภายในระบบ",
	BoschTrainingBenefits_Access24hours: "เข้าถึงได้ตลอด 24 ชั่วโมง 7 วันต่อสัปดาห์",
	ModernTrainingApproach_KnowledgeIsKey: "ความรู้เป็นปัจจัยความสำเร็จที่สำคัญสำหรับการประชุมเชิงปฏิบัติการใดๆ นั่นคือเหตุผลที่ Bosch เสนอโครงการพัฒนาการฝึกอบรมวิชาชีพอย่างครอบคลุม ซึ่งจะช่วยให้นักเรียนได้รับความรู้ที่ถูกต้องในเวลาและสถานที่ที่เหมาะสม ซึ่งเป็นความรู้ที่จำเป็นสำหรับการซ่อมบำรุงและซ่อมแซมรถของลูกค้าอย่างมืออาชีพ Bosch ได้เพิ่มการออกอากาศทางเว็บเป็นส่วนโมดูลใหม่ของแพ็คเกจอีเลิร์นนิงที่มีให้แล้ว ประกอบด้วยเซสชันการฝึกอบรมด้านบริการที่ออกแบบมาสำหรับการสตรีมโดยเฉพาะ",
	ModernTrainingApproach_ANewLearning: "โอกาสในการเรียนรู้ครั้งใหม่",
	ModernTrainingApproach_YourOnlineTraining: "โอกาสในการฝึกอบรมออนไลน์ของคุณ",
	ModernTrainingApproach_RecentlyTheWorld: "ล่าสุดโลกเปลี่ยนไปแล้ว มาดูสิ่งที่เราช่วยกัน",
	ModernTrainingApproach_TheOnlineTrainingWorld: "โลกแห่งการฝึกอบรมออนไลน์",
	ModernTrainingApproach_NewTeachingMethod: "วิธีการสอนแบบใหม่",
	ModernTrainingApproach_InAdditionTo: "นอกจากการฝึกอบรมแบบเห็นหน้ากันที่มีอยู่แล้ว เรายังได้รวมการฝึกอบรมเว็บคาสต์แบบสดไว้ในโปรแกรมการฝึกอบรมอีกด้วย ผู้ฝึกสอนของเราพร้อมให้บริการด้วยความรู้เชิงทฤษฎีที่ครอบคลุมในห้องเรียนเสมือนจริงหรือพร้อมตัวอย่างเชิงปฏิบัติ ตามปกติ คุณจะได้รับข้อมูลมากมายเกี่ยวกับระบบรถยนต์และผลิตภัณฑ์ของ Bosch ที่เกี่ยวข้อง แน่นอนว่าคุณยังมีโอกาสถามคำถาม คำถามเหล่านี้จะได้รับคำตอบโดยตรงในการถ่ายทอดสดทางเว็บ การถ่ายทอดสดทางเว็บใช้เวลาประมาณ 60 ถึง 120 นาที จำเป็นต้องมีการเชื่อมต่ออินเทอร์เน็ตที่เสถียร ค้นหาเว็บคาสต์ที่เหมาะสมโดยตรงในแคตตาล็อกการฝึกอบรมของเราโดยป้อน \Webcast\ ในช่องค้นหา",
	ModernTrainingApproach_BoschWebcastingHeader: "เว็บคาสต์ของ Bosch: สร้างสรรค์และใช้งานได้จริง",
	ModernTrainingApproach_BoschWebcastingText: "ในการเข้าร่วมเว็บคาสต์ของ Bosch อันดับแรก ผู้เข้าร่วมต้องโทรเข้า รหัสการเข้าถึงแต่ละรายการจะถูกส่งไปล่วงหน้าของเซสชั่นเพื่อเปิดใช้งานการเข้าสู่ระบบ ผู้เข้าร่วมสูงสุด 200 คนสามารถเข้าร่วมได้พร้อมกันจากสถานที่ต่างๆ การฝึกอบรมนำโดยผู้ฝึกสอน และสามารถส่งคำถามได้ตลอดเวลาผ่านฟังก์ชันแชทสด",
	ModernTrainingApproach_TheDigitalSupplementHeading: "ดิจิทัลเสริมเพื่อการฝึกอบรมในห้องเรียน",
	ModernTrainingApproach_TheDigitalSupplementText: "เว็บคาสต์และการฝึกอบรมในห้องเรียนช่วยเติมเต็มซึ่งกันและกันได้อย่างลงตัว ในทั้งสองกรณี ผู้ฝึกสอนที่มีคุณสมบัติเหมาะสมจะเป็นผู้นำการฝึกอบรม เว็บคาสต์เหมาะสำหรับหัวข้อที่สามารถส่งแบบดิจิทัลโดยไม่จำเป็นต้องทำงานบนรถ ตัวอย่างเช่น ทำความรู้จักกับคุณสมบัติ ESI[tronic] บางอย่างของระบบช่วยเหลือผู้ขับขี่ใหม่ เมื่อพูดถึงการทำงานเป็นทีมอย่างมีประสิทธิภาพในเวิร์กช็อปหรือการทำงานจริงในการฝึกอบรมเทคโนโลยียานยนต์แบบเห็นหน้ากันยังคงเป็นตัวเลือกแรก",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "สตรีมมิ่งถูกกว่า",
	ModernTrainingApproach_StreamingCanBeCheaperText: "ค่าใช้จ่ายต่อผู้เข้าร่วมมักจะต่ำกว่าเมื่อใช้งานเว็บคาสต์มากกว่าหลักสูตรในห้องเรียนที่เทียบเท่ากัน เหตุผลก็คือผู้เข้าร่วมสามารถได้รับประโยชน์จากสิ่งนี้มากขึ้น และความพยายามที่เกี่ยวข้องกับการฝึกอบรมก็ลดลงอย่างมาก (ไม่ต้องเดินทางไปและกลับจากสถานที่และใช้เวลาน้อยลง)",
	ModernTrainingApproach_BookAndProfitHeading: "จองและกำไร",
	ModernTrainingApproach_BookAndProfitText: "คุณสามารถจองเว็บคาสต์บนเว็บไซต์ของเราได้เช่นเดียวกับหลักสูตรฝึกอบรมและข้อเสนอการเรียนรู้อื่นๆ ของเรา คุณมีทางเลือกว่าจะจ่ายค่าธรรมเนียมรายบุคคลสำหรับการออกอากาศทางเว็บแต่ละครั้งหรือสมัครสมาชิกรายปีสำหรับเว็บคาสต์ตามจำนวนที่กำหนด",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "เปรียบเทียบเว็บคาสต์และการฝึกอบรมในห้องเรียนของ Bosch",
	ModernTrainingApproach_Webcasting: "เว็บคาสติ้ง",
	ModernTrainingApproach_Classroom: "ห้องเรียน",
	ModernTrainingApproach_WebcastingP1: "สามารถเข้าร่วมได้มากถึง 200 คนพร้อมกันจากทุกที่ทั่วโลก",
	ModernTrainingApproach_WebcastingP2: "ความซับซ้อนต่ำและค่าใช้จ่ายค่อนข้างต่ำ ไม่ต้องเดินทาง",
	ModernTrainingApproach_WebcastingP3: "เหมาะอย่างยิ่งสำหรับหัวข้อที่เฉพาะเจาะจงและรัดกุม",
	ModernTrainingApproach_WebcastingP4: "เวิร์กช็อปสามารถจัดทำเว็บคาสต์สำหรับพนักงานได้ทุกที่ทุกเวลา",
	ModernTrainingApproach_WebcastingP5: "ใช้เวลาน้อยกว่าการฝึกอบรมในชั้นเรียนประมาณ 50-70%",
	ModernTrainingApproach_ClassroomTrainingP1: "พบกับผู้เข้าร่วมสูงสุด 16 คนพร้อมกัน",
	ModernTrainingApproach_ClassroomTrainingP2: "ค่าเดินทางและค่าใช้จ่ายต่อคนโดยทั่วไปสูงขึ้น",
	ModernTrainingApproach_ClassroomTrainingP3: "เหมาะอย่างยิ่งเมื่อต้องการเพิ่มประสิทธิภาพการทำงานร่วมกันหรือการเรียนรู้โดยตรงบนยานพาหนะ",
	ModernTrainingApproach_ClassroomTrainingP4: "แบบฝึกหัดและปฏิสัมพันธ์กับผู้ฝึกสอน",
	ModernTrainingApproach_ClassroomTrainingP5: "ผู้ฝึกสอนสามารถปรับความรู้ที่จะสอนตามความต้องการที่แท้จริงของผู้เข้าร่วมได้ดียิ่งขึ้น",
	ModernTrainingApproach_OnlineTrainingOfferings: "ข้อเสนอการฝึกอบรมออนไลน์ (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "ทุกวันนี้การเรียนรู้อย่างอิสระที่คอมพิวเตอร์เป็นส่วนสำคัญของการถ่ายทอดความรู้ อิสระในการเลือกเวลาและสถานที่ที่สามารถทำซ้ำได้ไม่จำกัด ความเร็วของการเรียนรู้และเนื้อหาการเรียนรู้แบบโต้ตอบทำให้วิธีการนี้เป็นเครื่องมือการเรียนรู้ที่ขาดไม่ได้",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "องค์ประกอบ Web-Based Training (WBT) แต่ละองค์ประกอบได้รับการจัดโครงสร้างอย่างระมัดระวัง นอกจากการให้ฐานความรู้ในวงกว้างแล้ว หลักสูตรเหล่านี้ยังเป็นการเตรียมความพร้อมสำหรับโปรแกรมการฝึกอบรมที่ศูนย์ฝึกอบรมของ Bosch อีกด้วย",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "การจำลองการวินิจฉัยเสมือนจริงมอบความเป็นไปได้ในการสวมบทบาทกับผู้ใช้ในสถานการณ์การประชุมเชิงปฏิบัติการประจำวัน เช่น เวิร์กโฟลว์ของขั้นตอนการแก้ไขปัญหาโดยใช้อุปกรณ์การวินิจฉัยของ Bosch ในเวิร์กช็อปเสมือนจริง",
	ModernTrainingApproach_Advantages: "ข้อดี",
	ModernTrainingApproach_AdvantagesP1: "วิธีการให้ความรู้ที่รวดเร็วและมีประสิทธิภาพ",
	ModernTrainingApproach_AdvantagesP2: "การเรียนรู้ที่หลากหลาย",
	ModernTrainingApproach_AdvantagesP3: "ใช้ได้บ่อยเท่าที่ต้องการ",
	ModernTrainingApproach_AdvantagesP4: "เวลาและเงินน้อยที่สุด",
	ModernTrainingApproach_AdvantagesP5: "ความเร็วในการเรียนรู้ส่วนบุคคล",
	ModernTrainingApproach_AdvantagesP6: "เวลาเรียนที่ยืดหยุ่น",
	ModernTrainingApproach_AdvantagesP7: "ความสามารถในการทำซ้ำ",
	ModernTrainingApproach_AdvantagesP8: "ไม่มีค่าใช้จ่ายในการเดินทาง",
	ModernTrainingApproach_AdvantagesP9: "ไม่มีข้อขัดแย้งด้านความจุหรือกำหนดเวลา",
	Dashboard_CoursesViewAndManageYourCourses: "หลักสูตร | ดูและจัดการหลักสูตรของคุณ",
	Dashboard_MyCourses: "หลักสูตรของฉัน",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "โปรแกรมการฝึกอบรม | ดูโปรแกรมการฝึกอบรมของคุณ",
	Dashboard_MyTrainingProgram: "โปรแกรมการฝึกอบรมของฉัน",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "อีเลิร์นนิ่ง | ดูและจัดการพอร์ตโฟลิโอ eLearning ของคุณ",
	Dashboard_MyELearning: "eLearning ของฉัน",
	Dashboard_AccountManageYourAccount: "บัญชี | จัดการบัญชีของคุณ",
	Dashboard_MyAccount: "บัญชีของฉัน",
	Dashboard_MyDashboard: "แดชบอร์ดของฉัน",
	Dashboard_ManageYourAccount: "จัดการบัญชีของคุณ",
	Dashboard_Workshops: "เวิร์คช็อป",
	Dashboard_AdministrationManageYourWorkshop: "การบริหาร | จัดการเวิร์กช็อปของคุณ",
	Dashboard_AdministrationManageYourTeams: "การบริหาร | จัดการทีมของคุณ",
	Dashboard_Administration: "การบริหาร",
	Dashboard_ViewYourCourses: "ดูหลักสูตรของคุณ",
	Dashboard_ViewYourTrainingPrograms: "ดูโปรแกรมการฝึกอบรมของคุณ",
	Dashboard_TrainingPrograms: "โปรแกรมการฝึกอบรม",
	Dashboard_ViewAndEditYourDetails: "ดูและแก้ไขรายละเอียดของคุณ",
	Dashboard_ViewYourELearningCourses: "ดูหลักสูตร eLearning ของคุณ",
	Dashboard_Pending: "รอดำเนินการ",
	Dashboard_RequiresAdministratorSignOff: "(ต้องออกจากระบบผู้ดูแลระบบ)",
	Dashboard_HistoricalRecords: "บันทึกประวัติศาสตร์",
	Dashboard_Registered: "ลงทะเบียน",
	Dashboard_WaitingList: "รายการรอ",
	Dashboard_RecommendedForYou: "แนะนำสำหรับคุณ",
	Dashboard_AccountDetails: "รายละเอียดบัญชี",
	Dashboard_ChangeProfileImage: "เปลี่ยนรูปโปรไฟล์",
	Dashboard_Title: "ชื่อ",
	Dashboard_Role: "บทบาท",
	Dashboard_FirstName: "ชื่อจริง",
	Dashboard_LastName: "นามสกุล",
	Dashboard_Telephone: "โทรศัพท์",
	Dashboard_Mobile: "มือถือ",
	Dashboard_Email: "อีเมล",
	Dashboard_Language: "ภาษา",
	Dashboard_Save: "บันทึก",
	Dashboard_Student: "นักเรียน",
	Dashboard_WorkshopTrainingAdministrator: "ผู้ดูแลการฝึกอบรมเชิงปฏิบัติการ",
	Dashboard_WholesalerTrainingAdministrator: "ผู้บริหารการฝึกอบรมผู้ค้าส่ง",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "การบริหารบริษัท",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "จัดการเวิร์กช็อปของคุณ...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "จัดการพนักงานของคุณ...",
	Dashboard_CompanyAdministration_Workshop: "เวิร์คช็อป",
	Dashboard_CompanyAdministration_Address: "ที่อยู่",
	Dashboard_CompanyAdministration_Zip: "ซิป",
	Dashboard_CompanyAdministration_City: "เมือง",
	Dashboard_CompanyAdministration_Manage: "จัดการ",
	Dashboard_AdministrationSection_AdministrationTitle: "การบริหาร",
	Dashboard_AdministrationSection_ManageTheTeam: "คุมทีม",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "การบริหาร | จัดการพนักงานของคุณ",
	Dashboard_AdministrationSection_Employees: "พนักงาน",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "การบริหาร | จัดการผู้ค้าส่งของคุณ",
	Dashboard_AdministrationSection_Wholesalers: "ผู้ค้าส่ง",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "การบริหาร | จัดการธุรกรรมของคุณ",
	Dashboard_AdministrationSection_Transactions: "ธุรกรรม",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "การบริหารพนักงาน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "จัดการพนักงานของคุณ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "ลงทะเบียน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "บันทึก",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "แก้ไข",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "พนักงาน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "เพิ่ม",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "สร้าง",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "ลงทะเบียน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "รอดำเนินการ",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(ต้องออกจากระบบผู้ดูแลระบบ)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "บันทึกประวัติศาสตร์",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "รายการรอ",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "รายชื่อพนักงานปัจจุบัน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "การจัดการพนักงาน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "ชื่อ",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "บัญชีพนักงาน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "ดูและแก้ไขรายละเอียดพนักงาน...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "การตั้งค่า",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "อนุญาตบัญชีส่วนตัว",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "การอัพเกรดพร็อกซีการบริหารการประชุมเชิงปฏิบัติการของพนักงาน",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "อนุญาตให้พนักงานลงทะเบียนสำหรับกิจกรรมการฝึกอบรม",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "ส่งอีเมลคำเชิญ",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "ปิดการใช้งานบัญชี",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "บันทึก",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "ส่ง",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "แก้ไข",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "การจัดการผู้ค้าส่ง",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "จัดการผู้ค้าส่งของคุณ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "รายชื่อผู้ค้าส่งที่ได้รับอนุมัติ",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "ชื่อ",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "ลบ",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "สร้าง",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "เพิ่ม",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "การสร้างบัญชีพนักงานใหม่",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "สร้างรายละเอียดพนักงานใหม่...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "เพิ่มผู้ดูแลระบบผู้ค้าส่งที่ได้รับอนุมัติใหม่",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "สร้างรายละเอียดสำหรับผู้ค้าส่งรายใหม่...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "เลือกผู้ค้าส่งที่ได้รับอนุมัติจากเมนูแบบเลื่อนลง...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "ธุรกรรม",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "ดูและจัดการธุรกรรมของคุณ...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "กลับ",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "เลขใบสั่งของ",
	Dashboard_AdministrationSection_TransactionsSection_Description: "คำอธิบาย",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "วิธีการชำระเงิน",
	Dashboard_AdministrationSection_TransactionsSection_Date: "วันที่",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "จำนวน",
	Dashboard_AdministrationSection_TransactionsSection_Download: "ดาวน์โหลด",
	TrainingCentres_TrainingCentres: "ศูนย์ฝึกอบรม",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "ศูนย์ฝึกอบรมของเราตั้งอยู่ทั่วโลก",
	TrainingCentres_CountryCategory: "หมวดหมู่ประเทศ",
	TrainingCentres_CityCategory: "หมวดหมู่เมือง",
	TrainingCentres_Country: "ประเทศ",
	TrainingCentres_City: "เมือง",
	TrainingCatalogue_TrainingCatalogue: "แคตตาล็อกหลักสูตรฝึกอบรม",
	TrainingCatalogue_LearningAtTheHighestLevel: "การเรียนรู้ในระดับสูงสุด",
	TrainingCatalogue_StartDate: "วันที่เริ่มต้น",
	TrainingCatalogue_EndDate: "วันที่สิ้นสุด",
	TrainingCatalogue_TrainingName: "ชื่อการอบรม",
	TrainingCatalogue_Language: "ภาษา",
	TrainingCatalogue_Days: "วัน",
	TrainingCatalogue_Seats: "ที่นั่ง",
	TrainingCatalogue_Price: "ราคา",
	TrainingCatalogue_Location: "ที่ตั้ง",
	TrainingCatalogue_PartNumber: "ส่วนจำนวน",
	TrainingCatalogue_TrainingCourse: "คอร์สอบรม",
	TrainingCatalogue_Category: "หมวดหมู่",
	TrainingCatalogue_TrainingCategory: "หมวดหมู่การฝึกอบรม",
	TrainingCatalogue_CourseName: "ชื่อหลักสูตร",
	TrainingCatalogue_TotalDays: "จำนวนวันทั้งหมด",
	TrainingCatalogue_Venue: "สถานที่",
	TrainingCatalogue_Details: "รายละเอียด",
	TrainingCatalogue_Prerequisites: "ข้อกำหนดเบื้องต้น",
	TrainingCatalogue_CourseType: "ประเภทหลักสูตร",
	TrainingCatalogue_LearningMethod: "วิธีการเรียนรู้",
	TrainingCatalogue_AddToWaitingList: "เพิ่มในรายการรอ",
	ShoppingCart_ShoppingCart: "ตะกร้าสินค้า",
	ShoppingCart_ReviewAndManage: "ตรวจสอบและจัดการการสมัครของคุณ",
	ShoppingCart_YouHaveAddedItem: "คุณได้เพิ่มรายการต่อไปนี้ในรถเข็นของคุณ",
	ShoppingCart_Item: "รายการ",
	ShoppingCart_Quantity: "ปริมาณ",
	ShoppingCart_PricePerItem: "ราคาต่อรายการ",
	ShoppingCart_TotalPrice: "ราคารวม",
	ShoppingCart_VatIsIncluded: "ไม่รวม ภาษีมูลค่าเพิ่ม",
	ShoppingCart_Redeem: "หากคุณไม่มีเครดิต คลิกที่นี่ เพื่อแลกรับ",
	ShoppingCart_Purchase: "หากคุณไม่มีเครดิตคลิกที่นี่เพื่อซื้อ",
	ShoppingCart_Checkout: "เช็คเอาท์",
	ShoppingCart_ContinueShopping: "ช้อปปิ้งต่อ",
	ShoppingCart_TotalPriceInclVat: "ราคารวม ภาษีมูลค่าเพิ่ม",
	ShoppingCart_Apply: "นำมาใช้",
	ShoppingCart_ShoppingCartEmpty: "ตะกร้าช้อปปิ้งของคุณว่างเปล่า",
	ShoppingCart_VatIncluded: "รวมภาษีมูลค่าเพิ่มแล้ว)",
	ShoppingCart_ManageYourShopping: "จัดการการช้อปปิ้งของคุณ",
	ShoppingCart_Participant: "ผู้เข้าร่วม",
	ShoppingCart_Complete: "สมบูรณ์",
	ShoppingCart_NetPrice: "ราคาขาดตัว",
	ShoppingCart_Workshop: "เวิร์คช็อป",
	CheckOut_PreviewYourOrder: "ดูตัวอย่างคำสั่งซื้อของคุณ",
	CheckOut_YourShoppingCart: "รถเข็น",
	CheckOut_Preview: "ดูตัวอย่าง",
	CheckOut_Payment: "การชำระเงิน",
	CheckOut_Summary: "สรุป",
	CheckOut_Back: "กลับ",
	CheckOut_PleaseReadAndAgreeOurTerms: "โปรดอ่านและยอมรับข้อกำหนดและเงื่อนไขของเรา",
	CheckOut_IHaveReadTheTermsAndCondition: "ฉันได้อ่าน[ข้อกำหนดและเงื่อนไขแล้ว] และเข้าใจและยอมรับข้อกำหนดเหล่านี้",
	CheckOut_YesIAgree: "ใช่ - ฉันยอมรับข้อกำหนดและเงื่อนไข",
	CheckOut_Continue: "ดำเนินการต่อ",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "ยินดีด้วย! เราได้รับคำสั่งซื้อของคุณแล้ว",
	CheckOut_OrderConfirmation: "คุณจะได้รับการยืนยันคำสั่งซื้อครั้งสุดท้ายเมื่อคำขอของคุณได้รับการดำเนินการเรียบร้อยแล้ว",
	CheckOut_WeWillContactYouDirectly: "เราจะติดต่อคุณโดยตรงในกรณีที่มีคำถามเกี่ยวกับคำขอของคุณ",
	CheckOut_AddtoCart: "ใส่ในรถเข็น",
	CheckOut_CreditCart: "รถเข็นเครดิต",
	CheckOut_DirectDebit: "การหักบัญชีธนาคาร",
	CheckOut_TrainingCredits: "เครดิตการฝึกอบรม",
	CheckOut_PreferredWholesaler: "ผู้ค้าส่งที่ต้องการ",
	EventCalendar_EventCalendar: "ปฏิทินกิจกรรม",
	EventCalendar_TrainingEventsForAllYourNeeds: "กิจกรรมฝึกอบรมสำหรับทุกความต้องการของคุณ",
	TrainingCourse_TrainingCourse: "แคตตาล็อกหลักสูตรฝึกอบรม",
	TrainingCourse_LearningAtTheHighestLevel: "การเรียนรู้ในระดับสูงสุด",
	TrainingProgram_Title: "แคตตาล็อกโปรแกรมการฝึกอบรม",
	TrainingProgram_SubTitle: "การเรียนรู้ในระดับสูงสุด",
	AccountDeactivationModal_Title: "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานบัญชีนี้",
	AccountDeactivationModal_Line1: "การปิดใช้งานบัญชีนี้เป็นแบบถาวรและจะลบเนื้อหาทั้งหมดรวมถึงธุรกรรมคำขอบริการและยอดคงเหลือของสมาชิกในทีมและการตั้งค่าโปรไฟล์",
	AccountDeactivationModal_Line2Part1: "หากอยู่ภายในระยะเวลาการสมัครใช้งาน โปรดดูนโยบายการยกเลิกของเราใน",
	AccountDeactivationModal_Line2Part2: "ข้อตกลงและเงื่อนไข",
	AccountDeactivationModal_Line2Part3: "ก่อนส่งคำขอของคุณ",
	AccountDeactivationModal_Line3: "คุณแน่ใจหรือไม่ว่าต้องการส่งคำขอปิดใช้งานบัญชีนี้",
	AccountDeactivationModal_Cancel: "ยกเลิก",
	AccountDeactivationModal_Send: "ส่ง",
	CurrentUserAccountDeactivationModal_Title: "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานบัญชีของคุณ",
	CurrentUserAccountDeactivationModal_Line1: "การปิดใช้งานบัญชีของคุณจะมีผลถาวรและจะลบเนื้อหาทั้งหมดรวมถึงธุรกรรมคำขอบริการและยอดคงเหลือของสมาชิกในทีมและการตั้งค่าโปรไฟล์",
	CurrentUserAccountDeactivationModal_Line2Part1: "หากคุณอยู่ในระยะเวลาการสมัครใช้งาน โปรดดูนโยบายการยกเลิกของเราใน",
	CurrentUserAccountDeactivationModal_Line3: "คุณแน่ใจหรือไม่ว่าต้องการส่งคำขอปิดใช้งานบัญชีของคุณ",
	CurrentUserAccountDeactivationModal_Yes: "ใช่",
	CurrentUserAccountDeactivationModal_No: "เลขที่",
	CourseWithdrawalModal_Title: "การยกเลิกของคุณยังไม่สิ้นสุดก่อนที่จะได้รับการอนุมัติจากฝ่ายบริหารการฝึกอบรมของเรา",
	CourseWithdrawalModal_Line1: "คุณจะได้รับการยืนยันขั้นสุดท้ายทางอีเมลเมื่อได้รับการอนุมัติ ในกรณีที่เรามีคำถามใด ๆ เราจะติดต่อคุณโดยตรง คุณแน่ใจหรือไม่ว่าต้องการส่งคำขอถอนเงินของคุณ?",
	CourseWithdrawalModal_Line2Part1: "โปรดอ่านของเรา",
	CourseWithdrawalModal_Line2Part2Link: "นโยบายการยกเลิก",
	CourseWithdrawalModal_Line2Part3: "ดำเนินการต่อด้วยคำขอถอนเงิน?",
	CourseWithdrawalModal_Yes: "ใช่",
	CourseWithdrawalModal_No: "เลขที่",
	Settings_Settings: "การตั้งค่า",
	Settings_ManageYourSettings: "จัดการการตั้งค่าของคุณ",
	Settings_Profile: "ประวัติโดยย่อ",
	Settings_MyBoschGlobalIdLoginInformation: "ข้อมูลการเข้าสู่ระบบ Bosch Global ID ของฉัน",
	Settings_Edit: "แก้ไข",
	Settings_CompanyDetails: "รายละเอียดบริษัท",
	Settings_RequestAccountDeletion: "ขอลบบัญชี",
	Settings_AccountDeletionText: "หากคุณลบบัญชี ข้อมูลทั้งหมดของคุณ (รวมถึงที่อยู่และข้อมูลการชำระเงิน) จะถูกลบและจะไม่สามารถใช้งานได้อีกต่อไป",
	Settings_DeleteAccountRequestConfirmation: "ลบการยืนยันคำขอบัญชี",
	Settings_DeleteAccountRequestConfirmationText: "เราได้รับคำขอให้ลบบัญชีของคุณแล้ว และจะติดต่อคุณทางอีเมลหรือโทรศัพท์เพื่อยืนยันขั้นสุดท้าย",
	RecommendedHotels_RecommendedHotels: "โรงแรมแนะนำ",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "ศูนย์ฝึกอบรมของเราตั้งอยู่ทั่วโลก",
	Error_Common: "อะไรบางอย่างผิดปกติ!",
	Error_404: "ไม่มีหน้าที่คุณเยี่ยมชม",
	Error_401: "คุณไม่มีสิทธิ์เข้าถึงหน้านี้",
	Error_500: "เซิร์ฟเวอร์กำลังรายงานข้อผิดพลาด",
	Dashboard_Courses_Table_Course: "คอร์ส",
	Dashboard_Courses_Table_Date: "วันที่ [วัน]",
	Dashboard_Courses_Table_Venue: "สถานที่",
	Dashboard_Courses_Table_TrainingType: "ประเภทการฝึก",
	Dashboard_Courses_Table_Action: "หนังบู๊",
	Dashboard_Courses_Table_TasksToComplete: "คุณมีงานที่ต้องดำเนินการดังต่อไปนี้",
	Dashboard_Courses_Table_Withdraw: "ถอน",
	Dashboard_Courses_Table_Remove: "ลบ",
	Dashboard_Courses_Table_View: "ดู",
	Dashboard_Courses_Table_NoRecordsToDisplay: "ไม่มีบันทึกที่จะแสดง",
	Dashboard_Courses_Table_CompletionDate: "วันที่เสร็จสมบูรณ์",
	Dashboard_Courses_Table_Actions_Assignments: "การมอบหมาย",
	Dashboard_Courses_Table_Actions_Certification: "ใบรับรอง",
	Dashboard_Courses_Table_Actions_Evaluations: "การประเมินผล",
	Dashboard_Courses_Table_Actions_Assessments: "การประเมินผล",
	Dashboard_Courses_Table_Actions_AddToCalendar: "เพิ่มในปฏิทิน",
	Dashboard_Courses_Table_CourseDetails_Task: "งาน",
	Dashboard_Courses_Table_CourseDetails_DueDate: "วันครบกำหนด",
	ContactUs_Headline: "ติดต่อเรา",
	ContactUs_HowCanWeHelp: "เราจะช่วยคุณได้อย่างไร?",
	ContactUs_EmailTab: "อีเมล",
	ContactUs_PhoneTab: "โทรศัพท์",
	ContactUs_LetterTab: "จดหมาย",
	ContactUs_Phone_Headline: "โทรหาเรา!",
	ContactUs_Phone_Description: "เราหวังเป็นอย่างยิ่งว่าจะได้โทรหาคุณและยินดีที่จะช่วยเหลือคุณ เราพร้อมให้บริการคุณตลอด 24 ชั่วโมง 7 วันต่อสัปดาห์",
	ContactUs_Phone_PhoneNumber: "กรุณาใช้แบบฟอร์มการติดต่อเพื่อส่งข้อความถึงเรา",
	ContactUs_Phone_ChargeNotice: "ค่าบริการแตกต่างกันไปขึ้นอยู่กับผู้ให้บริการและประเทศของคุณ",
	ContactUs_Letter_Headline: "ส่งจดหมาย!",
	ContactUs_Letter_Description: "คุณมีคำถาม คำขอ หรือข้อเสนอแนะหรือไม่? เราหวังว่าจะได้รับการสอบถามของคุณ",
	ContactUs_Email_Headline: "ส่งอีเมล์!",
	ContactUs_Email_Description: "ส่งข้อความของคุณโดยกรอกแบบฟอร์มด้านล่าง หนึ่งในที่ปรึกษาของเรายินดีที่จะติดต่อคุณ",
	ContactUs_Email_InputName: "ชื่อ*",
	ContactUs_Email_InputCompany: "บริษัท",
	ContactUs_Email_InputCountry: "ประเทศ*",
	ContactUs_Email_InputEmail: "อีเมล*",
	ContactUs_Email_InputMessage: "ข้อความ*",
	ContactUs_Email_DataProtection: "ประกาศการคุ้มครองข้อมูล*",
	ContactUs_Email_DataProtectionConfirm: "ฉันได้อ่านนโยบายความเป็นส่วนตัวแล้ว*",
	ContactUs_Email_SecurityCheck: "ด่านตรวจสอบความปลอดภัย",
	ContactUs_Email_SecurityCodeConfirm: "โปรดป้อนอักขระที่ถูกต้องที่คุณเห็นในช่องด้านบน",
	ContactUs_Email_SecurityCodeInvalid: "คำตอบข้อความแคปช่าที่คุณป้อนไม่ถูกต้อง",
	ContactUs_Email_MandatoryNotice: "กรุณากรอกข้อมูลในฟิลด์บังคับ",
	ContactUs_Email_SuccessMessage: "ส่งข้อความของคุณแล้ว",
	ContactUs_Email_TakenNoteDataProtection1: "ฉันได้อ่าน",
	ContactUs_Email_TakenNoteDataProtection2: "นโยบายความเป็นส่วนตัว.*",
	ContactUs_Email_SubmitButton: "ส่ง",
	ContactUs_Email_FieldErrorMessage: "กรุณากรอกข้อมูลในฟิลด์บังคับ",
	ContactUs_Email_SuccessModal_OK: "ตกลง",
	AccountInReviewModal_Title: "บัญชีของคุณอยู่ในระหว่างการตรวจสอบ!",
	AccountInReviewModal_Paragraph1: "ขอขอบคุณที่ลงทะเบียนบัญชีส่วนตัวใน Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "ขณะนี้เจ้าหน้าที่ฝึกอบรมเฉพาะทางของเรากำลังตรวจสอบและกำหนดค่าบัญชีของคุณ คุณจะได้รับการยืนยันขั้นสุดท้ายทางไปรษณีย์ เมื่อบัญชีของคุณได้รับการกำหนดค่าสำเร็จแล้ว",
	AccountInReviewModal_Paragraph3: "ในระหว่างนี้ โปรดอย่าลังเลที่จะเรียกดูเว็บไซต์ของเราโดยมีการจำกัดการเข้าถึง",
	AccountInReviewModal_Paragraph4: "หากคุณมีคำถามใดๆ หรือหากบัญชีของคุณไม่ได้รับการประมวลผลภายใน 48 ชั่วโมง ยกเว้นวันหยุดสุดสัปดาห์และวันหยุดนักขัตฤกษ์ คุณสามารถติดต่อเรา",
	AccountInReviewModal_ContactUsLink: "ที่นี่",
	AccountInReviewModal_OKButton: "โอเค ให้ฉันเข้าไป!",
	Header_YesButton: "ใช่",
	Header_NoButton: "ไม่",
	Header_LogOutText: "คุณต้องการที่จะออกจากระบบ?",
	Footer_EnquiryText: "เราหวังว่าจะได้รับคำถามของคุณ",
	Footer_Telephone: "โทรศัพท์,",
	Footer_TermsAndConditions: "ข้อตกลงและเงื่อนไข",
	Footer_HowOurTrainingEvolves: "การฝึกอบรมของเรามีวิวัฒนาการอย่างไร",
	FooterSearch_SearchFieldPlaceholder: "ค้นหา",
	TrainingCatalogue_Action: "หนังบู๊",
	TrainingCatalogue_Duration: "ระยะเวลา",
	TrainingCatalogue_Information: "ข้อมูล",
	Settings_PersonalDetails: "ข้อมูลส่วนตัว",
	Reset_All_Filters: "รีเซ็ตตัวกรองทั้งหมด",
	EventTable_Days: "วัน",
	SearchField_AllResults: "ผลลัพธ์ทั้งหมด",
	TrainingCentresLayout_ViewDetails: "ดูรายละเอียด",
	Dropdown_Placeholder: "เลือก...",
	WaitlistPopup_SelectButtonText: "เลือก",
	WaitlistPopup_StartDate: "วันที่เริ่มต้น",
	WaitlistPopup_EndDate: "วันที่สิ้นสุด",
	WaitlistPopup_Language: "ภาษา",
	WaitlistPopup_City: "เมือง",
	WaitlistPopup_Workshop: "เวิร์คช็อป",
	WaitlistPopup_Student: "นักเรียน",
	WaitlistPopup_PreferredLocation: "สถานที่ที่ต้องการ",
	WaitlistPopup_SignUpCourseWaitingList: "ลงทะเบียนรายวิชารอรายการ",
	WaitlistPopup_SignUpWithoutSelectingDate: "ลงทะเบียนเข้ารายการรอโดยไม่ต้องเลือกวันที่",
	WaitlistPopup_GeneralWaitList: "รายการรอทั่วไป",
	WatinglistPopup_CourseLabel: "คอร์ส",
	Common_ContinueButton: "ดำเนินการต่อ",
	UserAccountDeactivationModal_ConfirmLabel: "ยืนยัน",
	Filter_FilterLabel: "กรอง",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "คุณอยู่ในรายชื่อรอแล้ว ต้องการลบการลงทะเบียนที่มีอยู่แล้วสร้างใหม่หรือไม่",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "ใช่ เอาออก",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "ไม่ ยกเลิก",
	WaitinglistPopup_RemoveWaitlistItem_Title: "ยกเลิกคอร์ส",
	WaitinglistPopup_SignUpButtonText: "ลงชื่อ",
	MyAccount_FirstNameRequied: "ต้องระบุชื่อ",
	MyAccount_LastNameRequired: "ต้องระบุนามสกุล",
	MyAccount_RoleRequired: "บทบาทเป็นสิ่งจำเป็น",
	MyAccount_MobileRequired: "ต้องใช้มือถือ",
	MyAccount_EmailRequired: "จำเป็นต้องใช้อีเมล",
	MyAccount_LanguageRequired: "ภาษาเป็นสิ่งจำเป็น",
	MyAccount_UpdateSuccessMessage: "รายละเอียดบัญชีของคุณอัปเดตสำเร็จแล้ว!",
	MyAccount_UpdateSuccessModal_ButtonText: "ใช่ ปิด",
	Infor_CorporateInformation_Header: "ข้อมูลองค์กร",
	Infor_LegalNotice_Header: "ประกาศทางกฎหมาย",
	Infor_PrivacyPolicy_Header: "ประกาศการคุ้มครองข้อมูล (นโยบายความเป็นส่วนตัว)",
	CreateEmployeeAccountModification_FormValidation_Role: "บทบาทเป็นสิ่งจำเป็น",
	CreateEmployeeAccountModification_FormValidation_Firstname: "ต้องระบุชื่อ",
	CreateEmployeeAccountModification_FormValidation_Lastname: "ต้องระบุนามสกุล",
	CreateEmployeeAccountModification_FormValidation_Mobile: "ต้องใช้มือถือ",
	CreateEmployeeAccountModification_FormValidation_Email: "จำเป็นต้องใช้อีเมล",
	CreateEmployeeAccountModification_FormValidation_Language: "ภาษาเป็นสิ่งจำเป็น",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "ขอเชิญเข้าร่วม Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "คุณได้เพิ่มสมาชิกใหม่ให้กับทีมของคุณสำเร็จแล้ว!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "คุณต้องการให้เราแบ่งปันคำเชิญทางอีเมลกับสมาชิกในทีมใหม่ของคุณ พร้อมคำแนะนำในการสร้างบัญชีส่วนตัวสำหรับ Bosch Automotive Training Solutions หรือไม่",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "ไม่",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "ใช่ ส่งคำเชิญ",
	ShoppingCart_Venue_Fee: "ค่าสถานที่",
	ShoppingCart_Trainning_Name: "ชื่อการอบรม",
	ShoppingCart_Available_Credits: "เครดิตที่มีอยู่",
	ConfirmItsMeModal_Hi: "สวัสดี",
	ConfirmItsMeModal_Description1: "คุณสำเร็จตามคำเชิญที่แชร์โดยผู้ดูแลระบบของบริษัทของคุณ เพื่อสร้างบัญชีส่วนตัวของคุณเองบน Bosch Automotive Training Solutions",
	ConfirmItsMeModal_Description2: "ก่อนดำเนินการต่อ โปรดยืนยันหรืออัปเดตรายละเอียดบัญชีของคุณ",
	ConfirmItsMeModal_Description3: "หลังจากยืนยัน คุณจะต้องเข้าสู่ระบบอีกครั้งเพื่อสร้างบัญชีของคุณให้เสร็จสมบูรณ์",
	ConfirmItsMeModal_CompanyDetails: "รายละเอียดบริษัท",
	ConfirmItsMeModal_YourDetails: "รายละเอียดของคุณ",
	ConfirmItsMeModal_YourDetails_Title: "ชื่อ",
	ConfirmItsMeModal_YourDetails_Function: "การทำงาน",
	ConfirmItsMeModal_YourDetails_Name: "ชื่อ",
	ConfirmItsMeModal_YourDetails_Mobile: "มือถือ",
	ConfirmItsMeModal_YourDetails_Email: "อีเมล",
	ConfirmItsMeModal_YourDetails_Language: "ภาษา",
	ConfirmItsMeModal_ConfirmButton: "ยืนยัน",
	SelectWholesalerModal_WholesalerGroupLabel: "ผู้ค้าส่ง",
	SelectWholesalerModal_WholesalerBranchLabel: "เอาท์เล็ท",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "โปรดเลือก",
	ApplyButton: "นำมาใช้",
	SelectWholesalerModal_Title: "เลือกผู้ค้าส่งของคุณ",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "คุณต้องการที่จะลบการลงทะเบียนของคุณ?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "การลงทะเบียนของคุณถูกลบสำเร็จ!",
	WaitlistPopup_AddToWaitListSuccess: "เพิ่มการลงทะเบียนของคุณสำเร็จแล้ว!",
	ErrorCommon_Header: "ข้อผิดพลาด",
	CommonButton_Close: "ปิด I",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "กรุณาปิดหน้าต่างนี้เพื่อดำเนินการต่อ!",
	EmbeddedVideoPlayer_StartButtonText: "เริ่ม",
	EmbeddedVideoPlayer_RefresherButtonText: "ทบทวน",
	DeactivateWholesalerModal_ConfirmMessage: "คุณแน่ใจหรือไม่ว่าต้องการลบผู้ค้าส่งรายนี้",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "ส่งอีเมลคำเชิญสำเร็จ!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "เชิญอีกครั้ง",
	ContactUs_Mobile_TH: "+66(2)0128888-0",
	ContactUs_Address_TH: "เอฟวายไอ เซ็นเตอร์ อาคาร 1 ชั้น 4,เลขที่ 2525 ถนนพระราม 4 แขวงคลองเตย ,กรุงเทพมหานคร 10110 ,ประเทศไทย",
	ConfirmItsMeModal_CompanyDetails_Name: "ชื่อ บริษัท",
	ConfirmItsMeModal_CompanyDetails_Address: "ที่อยู่",
	ConfirmItsMeModal_CompanyDetails_Postcode: "รหัสไปรษณีย์ของบริษัท",
	ConfirmItsMeModal_CompanyDetails_Phone: "โทรศัพท์",
	ConfirmItsMeModal_CompanyDetails_Email: "อีเมล",
	CourseWithdrawalModal_WithdrawSuccessMessage: "ถอนตัวจากหลักสูตรสำเร็จ!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "การสร้างบัญชีล้มเหลว มีบัญชีอยู่แล้ว!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "อัปเดตบัญชีล้มเหลว มีบัญชีอยู่แล้ว!",
	Footer_ExploreTrainingStories: "สำรวจเรื่องราวการฝึกอบรมทั้งหมดของเรา",
	Breadcrumb_Home: "บ้าน",
	Breadcrumb_Contact: "ติดต่อ",
	Breadcrumb_Stories: "เรื่อง",
	Breadcrumb_Stories_BoschServiceTraining: "การฝึกอบรมการบริการของบ๊อช",
	Breadcrumb_Stories_ADayInLife: "หนึ่งวันในชีวิตของเทรนเนอร์",
	Breadcrumb_Stories_BoschTrainingBenefits: "ประโยชน์ของการฝึกอบรม Bosch",
	Breadcrumb_Stories_TrainingApproach: "แนวทางการฝึกอบรมที่ทันสมัย",
	Breadcrumb_Settings: "การตั้งค่า",
	Breadcrumb_ShoppingCart: "ตะกร้าสินค้า",
	Breadcrumb_Checkout: "เช็คเอาท์",
	Breadcrumb_OrderSummary: "สรุปคำสั่งซื้อ",
	Breadcrumb_Exception: "ข้อยกเว้น",
	Breadcrumb_Dashboard: "แผงควบคุม",
	Breadcrumb_MyCourses: "หลักสูตรของฉัน",
	Breadcrumb_MyELearning: "eLearning ของฉัน",
	Breadcrumb_CompanyAdministration: "การบริหารบริษัท",
	Breadcrumb_EmployeeManagement: "การจัดการพนักงาน",
	Breadcrumb_EmployeeAccount: "บัญชีพนักงาน",
	Breadcrumb_EmployeeCreation: "การสร้างพนักงาน",
	Breadcrumb_Administration: "การบริหาร",
	Breadcrumb_EmployeeAdministration: "การบริหารพนักงาน",
	Breadcrumb_WholesalerManagement: "การจัดการผู้ค้าส่ง",
	Breadcrumb_NewWholesalerAccountCreation: "การสร้างบัญชีผู้ค้าส่งใหม่",
	Breadcrumb_Transactions: "ธุรกรรม",
	Breadcrumb_MyAccount: "บัญชีของฉัน",
	Breadcrumb_TrainingCentres: "ศูนย์ฝึกอบรม",
	Breadcrumb_EventCalendar: "ปฏิทินกิจกรรม",
	Breadcrumb_TrainingCourseCatalogue: "แคตตาล็อกหลักสูตรฝึกอบรม",
	Breadcrumb_RecommendedHotels: "โรงแรมแนะนำ",
	Breadcrumb_CorporateInformation: "ข้อมูลองค์กร",
	Breadcrumb_LegalNotice: "ประกาศทางกฎหมาย",
	Breadcrumb_DataProtectionNotice: "ประกาศเกี่ยวกับการคุ้มครองข้อมูล",
	Breadcrumb_PrivacyPolicy: "นโยบายความเป็นส่วนตัว",
	Breadcrumb_TermCondition: "ข้อกำหนดและเงื่อนไข",
	Breadcrumb_Terms: "เงื่อนไข",
	Breadcrumb_PrivacyStatement: "คำชี้แจงสิทธิ์ส่วนบุคคล",
	Breadcrumb_Cookies: "คุ้กกี้",
	Breadcrumb_Provider: "ผู้ให้บริการ",
	Breadcrumb_RegisterAccount: "ลงทะเบียนบัญชี",
	Stories_ExploreMore: "สำรวจเพิ่มเติม",
	CheckOutLayout_ShoppingCartHeader: "ตะกร้าสินค้า",
	CheckOutLayout_AddedItemCartMessage: "คุณได้เพิ่มรายการต่อไปนี้ในรถเข็นของคุณแล้ว",
	CheckOutLayout_CheckYourCartMessage: "ตรวจสอบรถเข็นของคุณ",
	ModernTrainingApproach_DateText: "1/10/2021 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "ค้นหาพนักงาน",
	CheckOutLayout_OrderWasNotPlaced_Message: "ขออภัย! ไม่มีการสั่งซื้อของคุณ",
	CheckOutLayout_RefusalReason: "เหตุผลในการปฏิเสธ",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "การชำระเงินล้มเหลว โปรดลองอีกครั้งด้วยวิธีการชำระเงินอื่น",
	CheckOutLayout_SubmittingApiFailed_Message: "การส่ง API ล้มเหลว",
	CartItem_ChooseWorkshop_Message: "โปรดเลือกเวิร์กช็อปเพื่อเลือกผู้เข้าร่วม",
	CartItem_ChooseParticipant_Message: "โปรดเลือกเข้าร่วมเพื่อดำเนินการต่อ",
	CompanyAdministrationTable_SearchBox_Placeholder: "ค้นหาเวิร์กชอป",
	CartItem_RemoveCartItem_ConfirmText: "คุณต้องการเอาสินค้านี้ออกจากรถเข็นหรือไม่?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "การทำงาน",
	AddToCartSuccessModal_Message: "Masukkan kode dan terapkan",
	AddToCartSuccessModal_ContinueButtonText: "ช้อปปิ้งต่อ",
	AddToCartSuccessModal_MyShoppingCartButtonText: "รถเข็นของฉัน",
	AddVoucher_Header: "เพิ่มบัตรกำนัล",
	AddVoucher_InputPlaceholder: "ใส่รหัสและสมัคร",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "ขออภัย เราไม่มีกิจกรรมตามกำหนดการของหลักสูตรการฝึกอบรมนี้ในขณะนี้ โปรดลงทะเบียนในรายชื่อรอ และเราจะแจ้งให้คุณทราบเมื่อมีกิจกรรม",
	ADYEN_ERR_2_: "ธุรกรรมถูกปฏิเสธ",
	ADYEN_ERR_3_: "ข้อผิดพลาดในการอ้างอิง",
	ADYEN_ERR_4_: "ธุรกรรมไม่ผ่านเนื่องจากมีข้อผิดพลาดเกิดขึ้นที่ฝั่งผู้ซื้อ",
	ADYEN_ERR_5_: "บัตรที่ใช้ทำรายการถูกบล็อค ใช้งานไม่ได้",
	ADYEN_ERR_6_: "บัตรที่ใช้ทำรายการหมดอายุ ดังนั้นจึงใช้ไม่ได้",
	ADYEN_ERR_7_: "จำนวนเงินไม่ตรงกันเกิดขึ้นระหว่างกระบวนการธุรกรรม",
	ADYEN_ERR_8_: "หมายเลขบัตรที่ระบุไม่ถูกต้องหรือไม่ถูกต้อง",
	ADYEN_ERR_9_: "ไม่สามารถติดต่อธนาคารของผู้ซื้อเพื่ออนุมัติการทำธุรกรรมได้",
	ADYEN_ERR_10_: "ธนาคารของนักช้อปไม่รองรับหรือไม่อนุญาตให้ทำธุรกรรมประเภทนี้",
	ADYEN_ERR_11_: "การตรวจสอบสิทธิ์ 3D Secure ไม่ได้ดำเนินการ หรือดำเนินการไม่สำเร็จ",
	ADYEN_ERR_12_: "บัตรไม่มีเงินเพียงพอที่จะครอบคลุมจำนวนเงินที่ต้องชำระ ปัญหานี้อาจเกิดขึ้นเนื่องจากการจำกัดจำนวนเงินที่ต้องชำระรายเดือนในบัตรของคุณ โปรดลองใช้บัตรอื่น เช่น MasterCard หรือติดต่อธนาคารของคุณเพื่อรีเซ็ตวงเงินบัตรรายเดือนของคุณ",
	ADYEN_ERR_14_: "การฉ้อโกงที่เป็นไปได้",
	ADYEN_ERR_15_: "ธุรกรรมถูกยกเลิก",
	ADYEN_ERR_16_: "นักช้อปได้ยกเลิกการทำธุรกรรมก่อนที่จะเสร็จสิ้น",
	ADYEN_ERR_17_: "PIN ที่ระบุไม่ถูกต้องหรือไม่ถูกต้อง",
	ADYEN_ERR_18_: "ผู้ซื้อระบุ PIN ที่ไม่ถูกต้องมากกว่าสามครั้งติดต่อกัน",
	ADYEN_ERR_19_: "ไม่สามารถตรวจสอบหมายเลข PIN ที่ระบุได้",
	ADYEN_ERR_20_: "การฉ้อโกงที่เป็นไปได้",
	ADYEN_ERR_21_: "ธุรกรรมไม่ได้ถูกส่งอย่างถูกต้องสำหรับการประมวลผล",
	ADYEN_ERR_22_: "การตรวจสอบความเสี่ยงระบุว่าธุรกรรมดังกล่าวเป็นการฉ้อโกง (คะแนนความเสี่ยง >= 100) การดำเนินการจึงถูกยกเลิก",
	ADYEN_ERR_23_: "รหัสที่ถูกปฏิเสธต่อไปนี้จะเชื่อมโยงกับเหตุผลในการปฏิเสธนี้: ไม่อนุญาตให้ทำธุรกรรมกับผู้ออกบัตร/ผู้ถือบัตร",
	ADYEN_ERR_24_: "CVC ที่ระบุ (รหัสความปลอดภัยของการ์ด) ไม่ถูกต้อง",
	ADYEN_ERR_25_: "รหัสการปฏิเสธต่อไปนี้เชื่อมโยงกับเหตุผลในการปฏิเสธนี้: บัตรไม่ถูกต้องในประเทศนี้",
	ADYEN_ERR_26_: "R1: การเพิกถอนคำสั่งอนุญาต / \"R3: การเพิกถอนคำสั่งอนุญาตทั้งหมด\" / \"R0: หยุดคำสั่งชำระเงิน\"",
	ADYEN_ERR_27_: "การตอบสนองนี้จะจับคู่รหัสตอบกลับทั้งหมดที่ไม่สามารถจับคู่ได้อย่างน่าเชื่อถือ วิธีนี้ช่วยให้บอกการปฏิเสธทั่วไปได้ง่ายขึ้น (เช่น คำตอบของ Mastercard \"05: Do not honor\") จากคำตอบที่เจาะจงมากขึ้น",
	ADYEN_ERR_28_: "เกินจำนวนเงินที่อนุญาตสำหรับบัตรนักช้อป",
	ADYEN_ERR_29_: "จำนวนการถอนที่อนุญาตสำหรับบัตรนักช้อปเกินแล้ว",
	ADYEN_ERR_31_: "ผู้ออกรายงานธุรกรรมว่าสงสัยว่าเป็นการฉ้อโกง",
	ADYEN_ERR_32_: "ข้อมูลที่อยู่ที่นักช้อปป้อนไม่ถูกต้อง",
	ADYEN_ERR_33_: "ธนาคารของนักช้อปกำหนดให้นักช็อปป้อน PIN ออนไลน์",
	ADYEN_ERR_34_: "ธนาคารของนักช้อปต้องมีบัญชีเช็คเพื่อดำเนินการซื้อให้เสร็จสมบูรณ์",
	ADYEN_ERR_35_: "ธนาคารของนักช้อปต้องมีบัญชีออมทรัพย์เพื่อดำเนินการซื้อให้เสร็จสมบูรณ์",
	ADYEN_ERR_36_: "ธนาคารของนักช้อปกำหนดให้นักช็อปป้อน PIN มือถือ",
	ADYEN_ERR_37_: "นักช้อปยกเลิกการทำธุรกรรมหลังจากพยายามชำระเงินแบบไม่ต้องสัมผัส และได้รับแจ้งให้ลองใช้วิธีการป้อนบัตรแบบอื่น (PIN หรือรูด)",
	ADYEN_ERR_38_: "ผู้ออกปฏิเสธคำขอยกเว้นการตรวจสอบสิทธิ์และต้องมีการตรวจสอบสิทธิ์สำหรับธุรกรรม ลองอีกครั้งด้วย 3D Secure",
	ADYEN_ERR_39_: "ผู้ออกหรือโครงการไม่สามารถสื่อสารผลลัพธ์ผ่าน RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "ชื่อต้องไม่มีอักขระพิเศษ (เช่น @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "ชื่อต้องน้อยกว่า 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "นามสกุลต้องไม่มีอักขระพิเศษ (เช่น: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "นามสกุลต้องน้อยกว่า75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "เบอร์มือถือต้องไม่มีสัญลักษณ์พิเศษ (เช่น @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "เบอร์มือถือต้องน้อยกว่า 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "หมายเลขโทรศัพท์ต้องไม่มีสัญลักษณ์พิเศษ (เช่น @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "หมายเลขโทรศัพท์ต้องน้อยกว่า 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "ที่อยู่อีเมลไม่ถูกต้อง",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "อีเมลต้องน้อยกว่า 100",
	ConfirmGeolocationModal_ConfirmButton: "ดำเนินการต่อ",
	ConfirmGeolocationModal_CancelButton: "เปลี่ยน",
	ConfirmGeolocationModal_CurrentCountrySelection: "การเลือกประเทศปัจจุบันของคุณคือ:",
	ConfirmGeolocationModal_AskChangeCountry: "คุณต้องการที่จะอยู่บนไซต์นี้หรือเปลี่ยนการตั้งค่าประเทศของคุณ?",
	Addtocart_Modal_AddToCartFailTitle: "เพิ่มผู้เข้าร่วมไม่สำเร็จ",
	Addtocart_Modal_AlreadyInCart: "ผู้เข้าร่วมลงทะเบียนเข้าร่วมกิจกรรมนี้แล้ว",
	TrainingCatalogue_DateRange: "ช่วงวันที่",
	TrainingCatalogue_DateRange_placeholder: "เลือกตัวกรองช่วงวันที่",
	Dashboard_MyPendingCourses: "หลักสูตรที่รอดำเนินการของฉัน",
	Dashboard_MyWaitingList: "รายการรอของฉัน",
	Dashboard_LatestNews: "ข่าวล่าสุด",
	SettingsMainPage_EditAccountButton: "แก้ไขบัญชี",
	SettingsMainPage_EditBoschId: "แก้ไข Bosch ID",
	EventInfoView_EventDetailTab: "รายละเอียดกิจกรรม",
	EventInfoView_AssignmentsTab: "การมอบหมาย",
	EventInfoView_EvaluationTab: "การประเมิน",
	EventInfoView_AttachmentTab: "เอกสารแนบ",
	EventInfoView_CourseDescriptionTab: "คำอธิบาย",
	EventInfoView_DescriptionTab_General: "ทั่วไป",
	EventInfoView_DescriptionTab_PartLabel: "ส่วนหนึ่ง",
	AssignmentTab_Description: "ที่นี่คุณจะพบข้อมูลทั้งหมดที่จำเป็นสำหรับการเรียนหลักสูตรนี้",
	AssignmentTab_Columns_Download: "ดาวน์โหลด",
	AssignmentTab_Columns_Upload: "ที่อัพโหลด",
	AssignmentTab_Columns_TaskComplete: "งานเสร็จสมบูรณ์",
	EventDetailTab_Location_Message_Video: "นี่คือกิจกรรมออนไลน์ที่สามารถเข้าถึงได้โดยใช้ลิงก์ \"เปิดวิดีโอ\"",
	EventDetailTab_Location_Message_WebBased: "นี่คือกิจกรรมออนไลน์ที่สามารถเข้าถึงได้โดยใช้ลิงก์ \"Launch WBT\"",
	EventDetailTab_Location_Message_WebCast: "นี่คือกิจกรรมออนไลน์ที่สามารถเข้าถึงได้โดยใช้ลิงก์ \"เข้าร่วมเลย\"",
	EventDetailTab_Link_Video: "เปิดตัววิดีโอ",
	EventDetailTab_Link_Video_Remark: "*การเปิดวิดีโอจะเป็นการเปิดหน้าต่างใหม่ คุณจะถูกเปลี่ยนเส้นทางกลับมาที่หน้านี้เมื่อหน้าต่างปิดลง",
	EventDetailTab_Link_WebBased: "เปิดตัว WBT",
	EventDetailTab_Link_WebBased_Remark: "*การเปิด WBT จะเป็นการเปิดหน้าต่างใหม่ คุณจะถูกเปลี่ยนเส้นทางกลับมาที่หน้านี้เมื่อหน้าต่างปิดลง",
	EventDetailTab_Link_WebCast: "เข้าร่วมเดี๋ยวนี้",
	EventDetailTab_Link_WebCast_Remark: "*ลิงก์เข้าร่วมตอนนี้จะใช้งานได้ 30 นาทีก่อนเริ่มกิจกรรม",
	EventDetailTab_Title_Status: "สถานะ",
	EventDetailTab_Title_Duration: "ระยะเวลา (ชม.)",
	EventDetailTab_Title_Trainers: "เทรนเนอร์",
	EventDetailTab_Title_Language: "ภาษา",
	EventDetailTab_Title_Score: "คะแนน (%)",
	EventDetailTab_Title_Calendar: "เพิ่มในปฏิทิน",
	EventDetailTab_Title_Certificate: "ดาวน์โหลดใบรับรอง",
	EventDetailTab_Title_Messages: "ข้อความ",
	EventDetailTab_Title_StartDate: "วันที่เริ่มต้น",
	EventDetailTab_Title_AvailableFrom: "มีจำหน่ายตั้งแต่",
	EventDetailTab_Title_Time: "เวลา",
	EventDetailTab_Title_EndDate: "วันที่สิ้นสุด",
	EventDetailTab_Title_ExpiresOn: "หมดอายุเมื่อ",
	EventDetailTab_Title_Room: "ห้อง",
	EventDetailTab_Title_Method: "วิธี",
	EventDetailTab_Title_Link: "ลิงค์*",
	EventDetailTab_Title_Location: "ที่ตั้ง",
	AttachmentTab_EmptyScreenDescription: "ไม่มีไฟล์แนบสำหรับกิจกรรมนี้",
	AttachmentTab_Columns_Download: "ดาวน์โหลด",
	AttachmentTab_Description: "ที่นี่คุณจะพบข้อมูลสำคัญที่จะช่วยคุณในหลักสูตรนี้",
	EvaluationTab_Annotation: "เราให้ความสำคัญกับความคิดเห็นของคุณ และยินดีรับฟังความคิดเห็นจากคุณเพื่อดูว่าเราดำเนินการเป็นอย่างไร โปรดใช้เวลาในการกรอกแบบประเมินต่อไปนี้เพื่อช่วยให้เราปรับปรุงบริการของเราเพื่อคุณอย่างต่อเนื่อง ขอขอบคุณ.",
	EvaluationTab_Notice: "เรตติ้งมีดังนี้",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "โปรดตอบคำถามบังคับทั้งหมดก่อนส่งการประเมินกิจกรรมของคุณ",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "ส่งความคิดเห็นของคุณเรียบร้อยแล้ว",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "การส่งแบบประเมินล้มเหลว",
	TrainingCatalogueLayout_ExportButtonText: "ส่งออก",
	EvaluationTab_FeedbackHasBeenGiven_Message: "การประเมินเสร็จสิ้นหรือหมดอายุแล้ว",
	EventDetailTab_Link_CopiedTooltip: "คัดลอกไปยังคลิปบอร์ดแล้ว",
	EventDetailTab_QRCode_ExplanationText_WBT: "สแกนเพื่อเปิด อบรมออนไลน์",
	EventDetailTab_QRCode_ExplanationText_Video: "สแกนเพื่อเปิดวิดีโอ",
	EventDetailTab_QRCode_ExplanationText_Webcast: "สแกนเพื่อเปิด Webcast",
	Footer_TrainingCoure_Headline: "หลักสูตรอบรมโดย Bosch",
	Footer_TrainingCourse_Content: "ในฐานะซูเปอร์แบรนด์ที่มีชื่อเสียงระดับโลกและด้วยประสบการณ์ด้านนวัตกรรมกว่าศตวรรษ บ๊อชได้กำหนดมาตรฐานทั่วโลกในฐานะผู้ให้บริการระบบยานยนต์ ชิ้นส่วน และโซลูชันทางเทคนิค จุดแข็งส่วนใหญ่ของเรามาจากความร่วมมืออย่างใกล้ชิดกับผู้ผลิตรถยนต์ ซึ่งเราออกแบบ พัฒนา และผลิตระบบและส่วนประกอบที่ล้ำสมัยทั่วโลก ด้วยเหตุนี้ บ๊อชจึงได้สร้างประวัติศาสตร์ที่แข็งแกร่งในผลิตภัณฑ์หลังการขายยานยนต์ และสนับสนุนกลุ่มผลิตภัณฑ์ชิ้นส่วน โซลูชันการวินิจฉัย และบริการเวิร์กช็อปที่หลากหลาย การเรียนรู้และการพัฒนาคือกุญแจสู่ความสำเร็จอย่างแน่นอน และปรัชญานี้ได้รับการสนับสนุนจากหลักสูตรการฝึกอบรมที่หลากหลาย ในทุกด้านของเทคโนโลยียานยนต์ ระบบยานยนต์ และความเชี่ยวชาญด้านการวินิจฉัย",
	SearchField_NoRecordsFound: "ไม่พบบันทึก",
	TrainingCatalogue_FreeTextSearch: "ข้อความฟรี",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "ค้นหา",
	CheckoutLayout_WholeSalerGroup: "เลือกกลุ่มผู้ค้าส่ง",
	CreditInsufficientModal_Message: "คุณมีเครดิตไม่เพียงพอสำหรับการใช้วิธีการชำระเงินนี้ โปรดลองอีกครั้งด้วยวิธีการชำระเงินอื่น",
	ShoppingCart_Required_Credits: "ราคารวม",
	MaintainingInfo_Message1: "เว็บไซต์นี้อยู่ในโหมดบำรุงรักษา",
	MaintainingInfo_Message2: "เราจะกลับมาเร็ว ๆ นี้",
	CountryValidationModal_Message: "คุณไม่สามารถเข้าสู่ [CountryA] เนื่องจากบัญชีของคุณถูกสร้างขึ้นใน [CountryB]",
	CountryValidationModal_ButtonText: "ดำเนินการต่อไปยัง [Country]",
	EventTable_LoginRequired: "เข้าสู่ระบบเพื่อลงทะเบียน",
	TrainingPartTableLayout_Date: "วันที่",
	TrainingPartTableLayout_StartTime: "เวลาเริ่มต้น",
	TrainingPartTableLayout_EndTime: "เวลาสิ้นสุด",
	TrainingPartTableLayout_Duration: "ระยะเวลา (ชม.)",
	TrainingPartTableLayout_TrainingMethod: "วิธีการฝึกอบรม",
	MyCoursesTableContents_ActiveHeader: "หลักสูตรที่ใช้งานอยู่",
	MyCoursesTableContents_ActiveTabHeader: "คล่องแคล่ว",
	MyCoursesTableContents_ScheduledHeader: "หลักสูตรตามกำหนดเวลา",
	MyCoursesTableContents_ScheduledTabHeader: "กำหนดการ",
	MyCoursesTableContents_OnlineHeader: "หลักสูตรออนไลน์",
	MyCoursesTableContents_OnlineTabHeader: "ออนไลน์",
	MyCoursesTableContents_CancelledHeader: "หลักสูตรที่ถูกยกเลิก",
	MyCoursesTableContents_CancelledTabHeader: "ยกเลิก",
	OnlineCoursesTable_Title_Progress: "ความคืบหน้า",
	ScheduledCoursesTable_Title_Date: "วันที่ [วัน]",
	CoursesTable_Title_Completed: "สมบูรณ์",
	Footer_TrainingProgram: "โปรแกรมการฝึกอบรม",
	Breadcrumb_TrainingProgram: "โปรแกรมการฝึกอบรม",
	TrainingProgram_Title_Table: "ชื่อ",
	TrainingProgram_TrainingProgram: "โปรแกรมการฝึกอบรม",
	TrainingProgram_TargetGroup: "กลุ่มเป้าหมาย",
	TrainingProgram_MaxLength: "สูงสุด ความยาว",
	TrainingProgram_Information: "ข้อมูล",
	TrainingProgram_Detail: "รายละเอียด",
	TrainingProgram_Modules: "โมดูล",
	TrainingProgram_Course: "หลักสูตร",
	TrainingProgram_PartNumber: "ส่วนจำนวน",
	TrainingProgram_Max_Length: "ความยาวสูงสุด",
	TrainingProgram_Duration: "ระยะเวลา",
	TrainingProgram_Exam: "การตรวจสอบ",
	TrainingProgram_Assign_Popup: "ลงทะเบียนสำหรับโปรแกรมการฝึกอบรม",
	TrainingProgram_Student: "นักเรียน",
	TrainingProgram_Assign: "กำหนด",
	TrainingProgram_Popup_Remove_Title: "ยกเลิกการฝึกอบรม?",
	TrainingProgram_Description: "คำอธิบาย",
	TrainingProgram_Days: "วัน",
	TrainingProgram_Type: "พิมพ์",
	TrainingProgram_Action: "การกระทำ",
	TrainingProgram_Enrolled: "ได้สมัครเข้าอบรมหลักสูตรนี้แล้ว",
	TrainingProgram_SelfAssign_Enrolled: "คุณได้รับมอบหมายให้เข้าร่วมโปรแกรมการฝึกอบรมนี้แล้ว",
	TrainingProgram_Warning: "โปรดลงทะเบียนนักเรียนคนอื่นหรือเลือกโปรแกรมการฝึกอบรมอื่น",
	TrainingProgram_Workshop: "การประชุมเชิงปฏิบัติการ",
	TrainingProgram_Durations: "ปี",
	TrainingProgram_Duration_DaysLabel: "วัน",
	TrainingProgram_HasExam_Yes: "ใช่",
	TrainingProgram_HasExam_No: "เลขที่",
	AssignModal_SuccessModal_Header: "ยินดีด้วย!",
	AssignModal_SuccessModal_Message: "ได้ลงทะเบียนเข้าร่วมโปรแกรมการฝึกอบรมนี้เรียบร้อยแล้ว",
	AssignModal_SelfAssign_SuccessModal_Message: "คุณได้รับมอบหมายให้เข้าร่วมโปรแกรมการฝึกอบรมเรียบร้อยแล้ว",
	Dashboard_MyTraningProgram: "โปรแกรมการฝึกอบรมของฉัน",
	Dashboard_MyTraningProgram_Title: "ชื่อ",
	Dashboard_MyTraningProgram_Status: "สถานะ",
	Dashboard_MyTraningProgram_Table_View: "ดู",
	Dashboard_MyTraningProgram_Register: "ลงทะเบียน",
	Dashboard_MyTraningProgram_Records: "บันทึกประวัติศาสตร์",
	Dashboard_MyTrainingProgram_In_Progress: "กำลังดำเนินการ",
	Dashboard_MyTrainingProgram_Accredited: "ได้รับการรับรอง",
	Dashboard_Program_Modal_Withdraw: "ถอน",
	Dashboard_Program_Modal_Grade: "ระดับ",
	Dashboard_MyTrainingProgram_Registered: "ลงทะเบียนแล้ว",
	Dashboard_MyTrainingProgram_Completedd: "สมบูรณ์",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "ใช่",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "เลขที่",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "โปรดทราบว่าการถอนตัวหมายความว่าคุณจะถอนตัวจากโปรแกรมการฝึกอบรมทั้งหมด ไม่ใช่แค่จากหลักสูตรการฝึกอบรมรายบุคคล",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "คุณยังต้องการถอนตัวจากโปรแกรมการฝึกอบรมหรือไม่?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "รอดำเนินการ",
	WithdrawProgramModal_Withdraw_Success_Message: "คุณถอนตัวจากโปรแกรมการฝึกอบรมสำเร็จแล้ว!",
	Common_Warning_Title: "คำเตือน!",
	Common_Error_Title: "อ๊ะ เกิดข้อผิดพลาด!",
	AddEventFromCatalogToCartModal_AddToCartButton: "หยิบใส่ตะกร้า",
	AddEventFromCatalogToCartModal_HoursText: "ชั่วโมง",
	AddElearningToCartSuccessModal_Message: "ถูกเพิ่มไปยังตะกร้าสินค้าของคุณแล้ว!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "ผู้เข้าร่วมที่เลือกได้รับการลงทะเบียนในหลักสูตรนี้ หรือเพิ่มหลักสูตรลงในรถเข็นแล้ว",
	EventDetailTab_SubjectForChange_Message: "เรื่องการเปลี่ยนแปลง",
	Dashboard_MyTrainingProgram_Status_Failed: "ล้มเหลว",
	Dashboard_MyTrainingProgram_Status_Passed: "ผ่าน",
	Dashboard_MyTrainingProgram_Status_Merit: "บุญ",
	Dashboard_MyTrainingProgram_Status_Distinction: "ความแตกต่าง",
	Dashboard_Employee_Courses: "หลักสูตร",
	Dashboard_Employee_Program: "โครงการอบรม",
	Dashboard_Employee_WaitList: "รอรายการ",
	Dashboard_Employee_Account: "บัญชี",
	Course_Price_Free: "ฟรี",
	Dashboard_Employee_Account_Update_Success_Message: "คุณอัปเดตข้อมูลพนักงานของคุณสำเร็จแล้ว!",
	Dashboard_Supplier: "ตัวแทนจำหน่าย",
	Event_Detail_Comment: "ขณะนี้ไม่มีข้อความ",
	DownLoad_PDF: "ดาวน์โหลด PDF",
	TrainingCatalogue_Currency: "สกุลเงิน",
	My_Wallet: "กระเป๋าเงินของฉัน",
	Wallet: "กระเป๋าสตางค์",
	Credits_Balance: "ยอดเครดิตการฝึกอบรม",
	MyFullCalendar_Today: "วันนี้",
	Header_Available_Languages: "ภาษาที่ใช้ได้",
	Header_CurrentRegion: "ขณะนี้คุณอยู่ใน",
	Header_ChangeRegions: "คุณต้องการเปลี่ยนแปลงประเทศหรือไม่?",
	Header_Anonymous: "การฝึกอบรมการบริการ",
	Header_Anonymous_Login: "เข้าสู่ระบบ / สมัครสมาชิก",
	Header_RegisterCourse: "อบรมหนังสือ",
	Homepage_Start: "ลงทะเบียน",
	Homepage_Login: "เข้าสู่ระบบ",
	MainPage_Title: "สุดยอดโซลูชันเวิร์กชอป",
	MainPage_Content: "เวิร์คช็อปของคุณ คุณเลือกได้",
	MainPage_ImageTitle: "โซลูชันการฝึกอบรมด้านยานยนต์ของบ๊อช",
	MainPage_IntroContent: "ช่วยให้การประชุมเชิงปฏิบัติการที่ทันสมัยสามารถทำงานได้อย่างมืออาชีพ เชื่อถือได้และมีประสิทธิภาพภายในขั้นตอนการวินิจฉัย การแก้ไขปัญหา การซ่อมแซมและการบริการยานพาหนะทุกประเภท",
	BoschNews_TrainingNews: "ข่าวการฝึกอบรม",
	Service_Assist_Workshop: "ยกระดับประสบการณ์ของคุณด้วย Workshop Service Assist",
	Enhance_Title_CDM3_v2: "ฉันสามารถทำอะไรกับแอพได้บ้าง?",
	Enhance_Body_CDM3_Item_1: "จองหลักสูตรและรักษาเส้นทางการเรียนรู้ของคุณ",
	Enhance_Body_CDM3_Item_2: "ดาวน์โหลดเอกสารประกอบการฝึกอบรมและใบรับรองหลักสูตร",
	Enhance_Body_CDM3_Item_3: "เปิดหลักสูตรอีเลิร์นนิง",
	Enhance_Body_CDM3_Item_4: "เข้าถึงบริการยานยนต์ต่าง ๆ ภายในแอปพลิเคชันมือถือ",
	Enhance_Body_CDM3_Item_5: "การส่งสัญญาณวิดีโอสดด้วย Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "เครื่องมือฟรีสำหรับการสแกนรถอัตโนมัติ",
	Training_Stories: "เรื่องราวการฝึกอบรม",
	SettingsMyAccount_JoinCompanyButton: "เข้าร่วมธุรกิจ",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "เข้าร่วมธุรกิจ",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "กรุณาใส่รหัสเปิดใช้งานของคุณด้านล่าง",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "หมายเหตุ: โปรดติดต่อผู้ดูแลระบบ BATS ของธุรกิจของคุณเพื่อรับรหัสเปิดใช้งานบนเว็บไซต์ BATS",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "การเปิดใช้งานของคุณได้รับการประมวลผลเรียบร้อยแล้ว และตอนนี้บัญชีของคุณเชื่อมโยงกับธุรกิจของคุณแล้ว",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "กรุณาคลิกดำเนินการต่อเพื่อเข้าสู่เว็บไซต์",
	ContinueButton: "ดำเนินการต่อ",
	WelcomeBatsModal_Title: "บัญชีของคุณอยู่ระหว่างการตรวจสอบ!",
	WelcomeBatsModal_ActivationLink: "คลิกที่นี่หากคุณมีรหัสเปิดใช้งานเพื่อเข้าร่วมบัญชีธุรกิจของคุณ!",
	WelcomeBatsModal_ActivationLinkNotes: "หมายเหตุ: คุณต้องติดต่อผู้ดูแลระบบของธุรกิจของคุณเพื่อรับรหัสเปิดใช้งาน",
	AccountInReviewModal_Description_Paragraph1: "ขอขอบคุณที่ลงทะเบียนบัญชีส่วนตัวกับ Bosch Automotive Training Solutions",
	AccountInReviewModal_Description_Paragraph2: "เจ้าหน้าที่ฝึกอบรมเฉพาะของเรากำลังตรวจสอบและกำหนดค่าบัญชีของคุณ คุณจะได้รับการยืนยันขั้นสุดท้ายทางอีเมล เมื่อบัญชีของคุณได้รับการกำหนดค่าเรียบร้อยแล้ว",
	AccountInReviewModal_CloseButton: "โอเค ให้ฉันเข้าไป!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "เราไม่สามารถตรวจสอบรหัสเปิดใช้งานและที่อยู่อีเมลของคุณได้",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบธุรกิจของคุณเพื่อขอความช่วยเหลือ",
	TryAgain_Button: "ลองอีกครั้ง",
	Error_Title: "อ๊ะ เกิดข้อผิดพลาด!",
	SettingsMyProfileActivationSuccessModal_Title: "ความสำเร็จ!",
	AccountInReviewModal_Description_Paragraph3: "ในขณะเดียวกัน โปรดอย่าลังเลที่จะเรียกดูไซต์เว็บไซต์ของเราด้วยการเข้าถึงที่จำกัด",
	AccountInReviewModal_Description_Paragraph4: "หากคุณมีคำถามใดๆ หรือหากบัญชีของคุณไม่ได้รับการประมวลผลภายใน 72 ชั่วโมง ยกเว้นวันหยุดสุดสัปดาห์และวันหยุดนักขัตฤกษ์ คุณสามารถติดต่อเราได้ [ที่นี่]",
	Support_Headline: "โซลูชันการฝึกอบรมด้านยานยนต์ของบ๊อช",
	Support_SubHeadline: "คำแนะนำบางอย่างที่จะช่วยคุณ...",
	Support_FAQ_Headline: "คำถามที่พบบ่อย",
	Support_FAQs_section1_title: "ทั่วไป",
	Support_FAQs_section1_question1: "โซลูชั่นการฝึกอบรมด้านยานยนต์ของบ๊อช (BATS) คืออะไร?",
	Support_FAQs_section1_question1_answer1: "โซลูชันการฝึกอบรมด้านยานยนต์ของบ๊อชเป็นแพลตฟอร์มที่ให้ภาพรวมที่ครอบคลุมเกี่ยวกับการฝึกอบรมด้านเทคนิค และช่วยให้คุณสามารถจองและจัดการหลักสูตรการฝึกอบรมได้",
	Support_FAQs_section1_question2: "ฉันสามารถใช้บัญชี Bosch ที่มีอยู่ของฉัน (SingleKey ID) เพื่อเข้าสู่ระบบ Bosch Automotive Training Solutions (BATS) ได้หรือไม่",
	Support_FAQs_section1_question2_answer1: "ใช่ BATS สามารถเข้าถึงได้ด้วย \"My Bosch ID\"",
	Support_FAQs_section1_question3: "ฉันสามารถใช้บัญชีของฉันที่สร้างขึ้นสำหรับ Bosch Automotive Training Solutions สำหรับบริการหรือแอปพลิเคชันอื่นๆ ของ Bosch ได้หรือไม่",
	Support_FAQs_section1_question3_answer1: "ได้ คุณยังสามารถใช้ Bosch ID ส่วนบุคคลของคุณสำหรับแอปพลิเคชันอื่นๆ ของ Bosch ที่หลากหลาย ทั้งแบบมืออาชีพและแบบส่วนตัว เช่น จักรยานไฟฟ้าหรือบ้านอัจฉริยะ",
	Support_FAQs_section2_title: "การฝึกอบรม",
	Support_FAQs_section2_question1: "ฉันจะจองการฝึกอบรมได้อย่างไร",
	Support_FAQs_section2_question1_answer1: "เข้าสู่ระบบด้วยบัญชีที่มีอยู่ของคุณหรือลงทะเบียนเข้าสู่พอร์ทัล ไปที่ \"Training Services\" หรือ \"Events Calendar\" เลือกหลักสูตรฝึกอบรม แล้วคลิกปุ่ม Add to cart (ไอคอนตะกร้าสินค้า)",
	Support_FAQs_section2_question2: "ไม่มีวันฝึกอบรมที่เหมาะสม ฉันจะทำอย่างไร",
	Support_FAQs_section2_question2_answer1: "คุณมีความเป็นไปได้ที่จะเข้าร่วมในรายการรอ \"ทั่วไป\" (โดยไม่ต้องเลือกวันที่) และทีมงานของเราจะแจ้งให้คุณทราบทันทีที่มีการฝึกอบรมใหม่",
	Support_FAQs_section2_question3: "ฉันจะดูภาพรวมของหลักสูตรที่จองไว้ได้ที่ไหน",
	Support_FAQs_section2_question3_answer1: "คุณสามารถดูภาพรวมทั้งหมดของหลักสูตรที่จองและเสร็จสิ้นแล้วได้ในแดชบอร์ดของคุณผ่านทาง: การฝึกอบรมของฉัน > ภาพรวมของคุณ",
	Support_FAQs_section3_title: "การตั้งค่า",
	Support_FAQs_section3_question1: "ฉันจะเปลี่ยนอีเมลและรหัสผ่านได้อย่างไร",
	Support_FAQs_section3_question1_answer1: "คุณต้องเข้าสู่ระบบพอร์ทัลก่อน จากนั้นคุณสามารถเปลี่ยนเป็นข้อมูลโปรไฟล์ส่วนตัวของคุณผ่านทาง: การฝึกอบรมของฉัน > บัญชี Bosch ของฉัน",
	Support_FAQs_section3_question1_answer3: "คุณจะสามารถเปลี่ยนรายละเอียด (อีเมล/รหัสผ่าน) ของ Bosch ID ของคุณได้ที่นั่น",
	Support_FAQs_section3_question2: "ฉันจะทำอย่างไรเมื่อลืมรหัสผ่าน?",
	Support_FAQs_section3_question2_answer1: "กรุณาใช้ปุ่มเข้าสู่ระบบหรือลงทะเบียนและกรอกที่อยู่อีเมลของคุณ",
	Support_FAQs_section3_question2_answer2: "หากคุณคลิกดำเนินการต่อ คุณมีโอกาสที่จะคลิก \"ลืมรหัสผ่าน\"",
	Support_FAQs_section3_question3: "จะตั้งค่าบัญชีผู้จัดการเวิร์กชอปได้อย่างไร",
	Support_FAQs_section3_question3_answer1: "หากคุณต้องการเข้าถึงข้อมูลการฝึกอบรมพนักงานธุรกิจของคุณทั้งหมด คุณจะต้องมีบทบาทเป็นผู้จัดการเวิร์กช็อป โปรดติดต่อหน่วยงานฝึกอบรมในพื้นที่ของคุณเช่น คุณสามารถใช้แบบฟอร์มการติดต่อบนเว็บไซต์",
	LandingPage_Header_Title: "โซลูชันการฝึกอบรมด้านยานยนต์ของบ๊อช",
	LandingPage_ChooseCountry_Title: "เลือกประเทศ / ภูมิภาคของคุณ",
	MaintenanceBanner_Message_1: "พอร์ทัลโซลูชันการฝึกอบรมด้านยานยนต์ของ Bosch จะได้รับการอัปเดตใน [maintenance_schedule] การอัปเดตคาดว่าจะใช้เวลา 30 นาที พอร์ทัลจะไม่สามารถใช้งานได้ระหว่างการอัปเดต",
	MaintenanceBanner_Message_2: "หากต้องการความช่วยเหลือเพิ่มเติม โปรดติดต่อเราทางอีเมล [support_email]",
	MaintenancePage_Message_1: "พอร์ทัลโซลูชันการฝึกอบรมด้านยานยนต์ของ Bosch กำลังได้รับการอัปเดต",
	MaintenancePage_Message_2: "เราคาดว่าพอร์ทัลจะใช้งานได้อีกครั้งในวันที่ [maintenance_online_date] เวลา [maintenance_online_time]",
	MenuHeader_Support: "สนับสนุน",
	Course_Detail_No_Prerequisites: "ไม่มีข้อกำหนดเบื้องต้น",
	TableCell_Hours: "ชั่วโมง",
	Dashboard_MenuItem_Dashboard: "แผงควบคุม",
	Dashboard_MenuItem_Curricula: "โปรแกรมการฝึกอบรม",
	Dashboard_MenuItem_Certificates: "ใบรับรอง",
	Dashboard_MenuItem_Team: "ทีม",
	Dashboard_MenuItem_Workshops: "การประชุมเชิงปฏิบัติการ",
	Dashboard_MenuItem_External: "ตัวแทนจำหน่าย",
	Dashboard_MenuItem_Wallet: "กระเป๋าสตางค์",
	Dashboard_MenuItem_Profile: "ประวัติโดยย่อ",
	Dashboard_Headline_YourUpcomingCourses: "หลักสูตรที่กำลังจะมาถึงของคุณ",
	Dashboard_Headline_YourELearningCourses: "หลักสูตรอีเลิร์นนิงของคุณ",
	Dashboard_Headline_TeamOverview: "ภาพรวมของทีม",
	Dashboard_Headline_YourOverview: "ภาพรวมของคุณ",
	Dashboard_Headline_YourWishlist: "โปรแกรมการฝึกอบรมของคุณ",
	Dashboard_Headline_ActivePrograms: "ใบรับรองของคุณ",
	Dashboard_Headline_CompletedPrograms: "โปรแกรมที่เสร็จสมบูรณ์",
	Dashboard_Headline_YourCertificates: "ใบรับรองของคุณ",
	Dashboard_Headline_Transactions: "ธุรกรรม",
	Dashboard_Table_NoRecordsToDisplay: "ไม่มีบันทึกที่จะแสดง",
	Dashboard_TableColumn_Location_Online: "ออนไลน์",
	Dashboard_TableColumn_Duration_Hours: "ชั่วโมง",
	Dashboard_ELearningCourses_TableHeader_Title: "ชื่อ",
	Dashboard_ELearningCourses_TableHeader_Duration: "ระยะเวลา (ชม.)",
	Dashboard_ELearningCourses_ActionButton_View: "ดู",
	Dashboard_UpcomingCourses_TableHeader_Title: "ชื่อ",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "วันที่ [วัน]",
	Dashboard_UpcomingCourses_TableHeader_Location: "ที่ตั้ง",
	Dashboard_UpcomingCourses_ActionButton_View: "ดู",
	Dashboard_YourOverview_TableHeader_Title: "ชื่อ",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "วันที่ / ระยะเวลา",
	Dashboard_YourOverview_TableHeader_Location: "ที่ตั้ง",
	Dashboard_YourOverview_TableHeader_Status: "สถานะ",
	Dashboard_YourOverview_ActionButton_View: "ดู",
	Dashboard_YourWishlist_TableHeader_Title: "ชื่อ",
	Dashboard_YourWishlist_TableHeader_Action: "การกระทำ",
	Dashboard_YourWishlist_ViewAllRecords: "ดูบันทึกทั้งหมด",
	Dashboard_TeamOverview_TableHeader_Employee: "พนักงาน",
	Dashboard_TeamOverview_TableHeader_Title: "ชื่อ",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "วันที่ / ระยะเวลา",
	Dashboard_TeamOverview_TableHeader_Location: "ที่ตั้ง",
	Dashboard_TeamOverview_TableHeader_Status: "สถานะ",
	Dashboard_TeamOverview_ActionButton_View: "ดู",
	Dashboard_TeamOverview_SearchBox_Placeholder: "ค้นหา",
	Dashboard_ActiveProgram_TableHeader_Title: "ชื่อ",
	Dashboard_ActiveProgram_TableHeader_Status: "สถานะ",
	Dashboard_ActiveProgram_ActionButton_View: "ดู",
	Dashboard_CompletedProgram_TableHeader_Title: "ชื่อ",
	Dashboard_CompletedProgram_TableHeader_Status: "สถานะ",
	Dashboard_CompletedProgram_ActionButton_View: "ดู",
	Dashboard_Certificates_TableHeader_Title: "ชื่อ",
	Dashboard_Certificates_TableHeader_Date: "วันที่",
	Dashboard_Certificates_TableHeader_Status: "สถานะ",
	Dashboard_Certificates_TableHeader_Action: "การกระทำ",
	Dashboard_Certificates_ActionButton_Download: "ดาวน์โหลด",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "เลขใบสั่งของ",
	Dashboard_Transactions_TableHeader_Description: "คำอธิบาย",
	Dashboard_Transactions_TableHeader_PaymentMethod: "วิธีการชำระเงิน",
	Dashboard_Transactions_TableHeader_Date: "วันที่",
	Dashboard_Transactions_TableHeader_Amount: "จำนวน",
	Dashboard_Transactions_ActionButton_Download: "ดาวน์โหลด",
	Breadcrumb_MyTraining: "การฝึกอบรมของฉัน",
	Breadcrumb_MyTraining_CourseDetails: "รายละเอียดหลักสูตร",
	Breadcrumb_MyTraining_Curricula: "โปรแกรมการฝึกอบรม",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "รายละเอียดโปรแกรมการฝึกอบรม",
	Breadcrumb_MyTraining_Team: "ทีม",
	Breadcrumb_MyTraining_Team_ViewEmployee: "ดูพนักงาน",
	MyTraining_Headerbar_CourseDetails: "รายละเอียดหลักสูตร",
	MyTraining_Headerbar_TrainingProgramDetails: "รายละเอียดโปรแกรมการฝึกอบรม",
	TrainingProgramDetails_Label_Status: "สถานะ",
	TrainingProgramDetails_Label_OverallProgress: "ความคืบหน้าโดยรวม",
	TrainingProgramDetails_ProgramItems_Headline: "รายการโปรแกรม",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "ชื่อ",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "สถานะ",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "ระดับ",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "วันที่",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "ดู",
	TrainingProgramDetails_Status_InProgress: "กำลังดำเนินการ",
	TrainingProgramDetails_Status_Completed: "สมบูรณ์",
	CourseDetails_Label_Status: "สถานะ",
	CourseDetails_Label_Duration: "ระยะเวลา",
	CourseDetails_Label_Trainer: "เทรนเนอร์",
	CourseDetails_Label_Language: "ภาษา",
	CourseDetails_Label_Score: "คะแนน:",
	CourseDetails_Label_StartDate: "วันที่เริ่มต้น",
	CourseDetails_Label_EndDate: "วันที่สิ้นสุด",
	CourseDetails_Label_Venue: "สถานที่จัดงาน",
	CourseDetails_Label_Address: "ที่อยู่",
	CourseDetails_Label_Grade: "ระดับ:",
	CourseDetails_Action_Withdraw: "ถอน",
	CourseDetails_Action_Evaluate: "ประเมินหลักสูตร",
	CourseDetails_Action_Evaluate_Description: "โปรดประเมินหลักสูตรนี้เพื่อสร้างใบรับรองของคุณ",
	CourseDetails_Action_DownloadCertificate: "ดาวน์โหลดใบรับรอง",
	CourseDetails_Action_JoinWebcast: "เข้าร่วมเว็บคาสต์",
	CourseDetails_Action_StartCourse: "เริ่มหลักสูตร",
	CourseDetails_Action_RestartCourse: "เริ่มหลักสูตรใหม่",
	CourseDetails_Action_RepeatCourse_Description: "ไม่สามารถดาวน์โหลดใบรับรองได้เนื่องจากไม่ผ่านเกณฑ์ โปรดทำซ้ำและผ่านหลักสูตรนี้เพื่อดาวน์โหลดใบรับรองของคุณ",
	CourseDetails_PartSpecificDetails_Headline: "รายละเอียดเฉพาะส่วน",
	CourseDetails_PartSpecificDetails_Tab_Part: "ส่วนหนึ่ง",
	CourseDetails_PartSpecificDetails_Description: "คำอธิบาย",
	CourseDetails_PartSpecificDetails_StartDate: "วันที่เริ่มต้น",
	CourseDetails_PartSpecificDetails_EndDate: "วันที่สิ้นสุด",
	CourseDetails_PartSpecificDetails_StartTime: "เวลาเริ่มต้น",
	CourseDetails_PartSpecificDetails_EndTime: "เวลาสิ้นสุด",
	CourseDetails_PartSpecificDetails_Method: "วิธี",
	Dashboard_Team_TableHeader_Name: "ชื่อ",
	Dashboard_Team_TableHeader_Role: "บทบาท",
	Dashboard_Team_TableHeader_Email: "อีเมล",
	Dashboard_Team_TableHeader_PersonalAccount: "บัญชีส่วนตัว",
	Dashboard_Team_ActionButton_Select: "เลือก",
	Dashboard_Team_PersonalAccount_Activated: "คล่องแคล่ว",
	Dashboard_Team_PersonalAccount_NotActivated: "ไม่ทำงาน",
	Dashboard_Team_ActionButton_AddMember: "เพิ่มสมาชิกทีม",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "ค้นหา",
	Dashboard_External_TableHeader_Name: "ชื่อ",
	Dashboard_External_ActionButton_AddNew: "เพิ่มตัวแทนจำหน่าย",
	Dashboard_External_Headline: "รายชื่อผู้ค้าส่งที่ได้รับอนุมัติ",
	Dashboard_Workshops_Headline: "การประชุมเชิงปฏิบัติการ",
	Dashboard_Workshops_TableHeader_Company: "บริษัท",
	Dashboard_Workshops_TableHeader_Address: "ที่อยู่",
	Dashboard_Workshops_TableHeader_Postcode: "รหัสไปรษณีย์",
	Dashboard_Workshops_TableHeader_City: "เมือง",
	Dashboard_Workshops_ActionButton_Select: "เลือก",
	Wallet_ServiceCredits: "เครดิตการฝึกอบรม",
	Wallet_Headline_CreditBalance: "ยอดเครดิตการฝึกอบรม",
	Wallet_Headline_CreditActivity: "กิจกรรมเครดิตการฝึกอบรม",
	Wallet_Headline_BillingTransactions: "ธุรกรรมการเรียกเก็บเงิน",
	Wallet_BillingTransaction_TableHeader_Order: "คำสั่ง",
	Wallet_BillingTransaction_TableHeader_Date: "วันที่",
	Wallet_BillingTransaction_TableHeader_Amount: "จำนวน",
	Wallet_BillingTransaction_TableHeader_Action: "การกระทำ",
	Wallet_CreditActivity_TableHeader_Date: "วันที่",
	Wallet_CreditActivity_TableHeader_Description: "คำอธิบาย",
	Wallet_CreditActivity_TableHeader_User: "ผู้ใช้",
	Wallet_CreditActivity_TableHeader_Amount: "จำนวน",
	FooterAdditionalLinks_Copyright: "© โรเบิร์ต บ๊อช สงวนลิขสิทธิ์",
	FooterAdditionalLinks_Navigation_ProductSecurity: "ความปลอดภัยของผลิตภัณฑ์ (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "นวัตกรรม สิทธิบัตร และใบอนุญาต",
	FooterAdditionalLinks_Navigation_Logistics: "การจัดซื้อและโลจิสติกส์",
	MenuHeader_MyTraining: "การฝึกอบรมของฉัน",
	MenuHeader_TrainingServices: "แคตตาล็อกและปฏิทินการฝึกอบรม",
	MenuHeader_OurLocations: "สถานที่ของเรา",
	MenuHeader_ContactsUs: "ติดต่อเรา",
	MenuHeader_Logout: "ออกจากระบบ",
	MenuHeader_FAQ: "คำถามที่พบบ่อย",
	MyProfile_MyBoschId: "รหัส Bosch ของฉัน",
	MyProfile_AccountDeletion: "การลบบัญชี",
	MyProfile_Edit: "เปลี่ยนอีเมลหรือรหัสผ่าน",
	MyProfile_Profile: "บัญชี Bosch ของฉัน",
	MyProfile_EditUserProfile: "แก้ไขโปรไฟล์ผู้ใช้",
	BackButton: "กลับ",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "เราหวังว่าจะได้รับสายจากคุณและยินดีที่จะช่วยเหลือคุณ",
	ContactUs_Email_SecurityCodeConfirm_V3: "ป้อนอักขระที่ถูกต้องตามที่ระบุในช่องด้านบน",
	ContactUs_Email_MandatoryNotice_V3: "กรุณากรอกข้อมูลในฟิลด์ที่จำเป็นทั้งหมด",
	ContactUs_Email_SendButton: "ส่ง",
	Breadcrumb_TrainingCenters: "ศูนย์ฝึกอบรม",
	Training_Centres_Book_Btn: "อบรมหนังสือ",
	Training_centres_booking_code: "รหัสการจอง",
	Training_centers_header_bar_title: "ศูนย์ฝึกอบรมของเรา",
	Training_centers_filter_item: "ศูนย์",
	TeamEdit_PersonalAccountToolTop: "การเปิดใช้งานคุณลักษณะนี้ทำให้พนักงานสามารถสร้างบัญชีส่วนบุคคลและเป็นส่วนหนึ่งของบัญชีธุรกิจของคุณได้ เมื่อเปิดใช้งานแล้ว คุณสามารถส่งคำเชิญทางอีเมลพร้อมรหัสเปิดใช้งานไปยังพนักงานได้ พนักงานต้องป้อนรหัสเปิดใช้งานเมื่อลงทะเบียนเพื่อเชื่อมโยงกับบัญชีธุรกิจของคุณ",
	TeamEdit_Update: "การเปิดใช้งานคุณลักษณะผู้ดูแลระบบของบริษัทจะทำให้พนักงานรายนี้สามารถเข้าถึงบัญชีธุรกิจของคุณได้อย่างเต็มที่ การเปิดใช้งานคุณลักษณะนี้กำหนดให้ผู้ใช้ออกจากระบบและกลับเข้าสู่เว็บไซต์ หากผู้ใช้เข้าสู่ระบบแล้ว",
	TeamEdit_PersonalAccount: "อนุญาตบัญชีเว็บ Bosch Training Solutions ส่วนบุคคล",
	TeamEdit_SendInvitation: "ส่งคำเชิญชวน",
	TeamEdit_Deactivate: "ปิดการใช้งาน",
	TeamEdit_Telephone: "โทรศัพท์",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "มือถือต้องมีแต่ตัวเลข",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "โทรศัพท์ต้องประกอบด้วยตัวเลขเท่านั้น",
	TeamEdit_Activate: "คล่องแคล่ว",
	TeamEdit_NotActivated: "ไม่ทำงาน",
	TeamEdit_StatusPopup: "สถานะจะแสดงเป็น 'ใช้งานอยู่' หากผู้ใช้สร้างบัญชีส่วนตัวสำเร็จ หากสถานะถูกตั้งค่าเป็น 'ไม่ใช้งาน' หมายความว่าผู้ใช้ไม่มีบัญชี - คุณสามารถส่งคำเชิญให้พวกเขาทำสิ่งนี้ให้เสร็จสิ้นหากคุณยังไม่ได้ดำเนินการ",
	Training_Catalog_Courses: "หลักสูตร",
	Training_Catalog_Course: "คอร์ส",
	Reset_Filter: "รีเซ็ตตัวกรอง",
	TrainingCatalogue_CourseCatalogue: "แคตตาล็อกหลักสูตรการฝึกอบรม",
	CheckoutHeader_CartItemCount_Text: "คุณมี [count] รายการในตะกร้าสินค้าของคุณ",
	CheckoutHeader_CartItemsCount_Text: "คุณมี [count] รายการในตะกร้าสินค้าของคุณ",
	ShoppingCart_PriceItem: "ราคาสินค้า",
	ShoppingCartItemParticipants_AddParticipant_Button: "เพิ่มผู้เข้าร่วม",
	ShoppingCartTotalPrice_SubTotalLabel: "ผลรวมย่อย",
	ShoppingCartTotalPrice_TaxesLabel: "ภาษี",
	ShoppingCartTotalPrice_TotalLabel: "ทั้งหมด",
	ShoppingCartTotalPrice_IncludedVATLabel: "รวม VAT จำนวน [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "วิธีการชำระเงินออนไลน์ที่มีอยู่",
	ShoppingCartItemRow_VenueFeeLabel: "ค่าธรรมเนียมสถานที่ [fee_amount] ต่อผู้เข้าร่วม",
	ShoppingCartItemRow_OnlineLabel: "ออนไลน์",
	AddParticipantsModal_Title: "เพิ่มผู้เข้าร่วม",
	AddParticipantsModal_SelectEmployeeLabel: "เพิ่มผู้เข้าร่วม",
	AddParticipantsModal_SelectWorkshopLabel: "เลือกการประชุมเชิงปฏิบัติการ",
	ShoppingCartTotalPrice_ContinueButtonText: "ดำเนินการต่อเพื่อชำระเงิน",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "ยังไม่ได้เพิ่มผู้เข้าร่วม!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "คุณมีหนึ่งหลักสูตรขึ้นไปในตะกร้าสินค้าของคุณโดยไม่มีผู้เข้าร่วม",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "โปรดเพิ่มผู้เข้าร่วมเพื่อดำเนินการชำระเงินต่อ",
	ShoppingCartItemParticipants_ShowMoreLinkText: "แก้ไขผู้เข้าร่วม ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "ชื่อ",
	AddParticipantsModal_ParticipantTable_Email: "อีเมล",
	AddParticipantsModal_ParticipantTable_Action: "การกระทำ",
	AddParticipantsModal_ParticipantTable_NoParticipant: "ไม่มีผู้เข้าร่วม…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "ไม่ได้กำหนดค่าที่อยู่อีเมลสำหรับผู้เข้าร่วมที่เลือก",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "ผู้เข้าร่วมที่เลือกไม่มีที่อยู่อีเมลที่กำหนดค่าในโปรไฟล์ ที่อยู่อีเมลเป็นสิ่งจำเป็นสำหรับการลงทะเบียนหลักสูตรฝึกอบรม",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "คลิก 'เพิ่มอีเมล' เพื่อดำเนินการต่อหรือ 'ยกเลิก' เพื่อเลือกผู้เข้าร่วมรายอื่น",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "เพิ่มอีเมล",
	CancelButton: "ยกเลิก",
	AddParticipantsModal_AddEmployeeButtonText: "เพิ่มพนักงาน",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "คุณกำลังจะลบหลักสูตรออกจากรถเข็นของคุณ",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "คุณกำลังพยายามลบหลักสูตรที่มีผู้เข้าร่วมเพิ่มออกจากรถเข็นของคุณ",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "คลิก \"ดำเนินการต่อ\" หากคุณต้องการดำเนินการต่อ หรือคลิก \"ยกเลิก\"",
	ShoppingCartItemHeader_Participants: "ผู้เข้าร่วม)",
	ShoppingCartItemHeader_ParticipantPrice: "ราคาต่อที่นั่ง",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "แสดงใน [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "แสดงในเครดิต",
	ShoppingCart_CreditsPriceUnit: "เครดิต",
	CheckOutHeader_Step1_Question: "คุณต้องการชำระเงินอย่างไร",
	CheckOutHeader_Step2_Question: "ตรวจสอบคำสั่งซื้อของคุณ",
	CheckOutHeader_Step3_Question: "กรอกคำสั่งซื้อของคุณ",
	CheckOutHeader_Step4_Question: "การจองของคุณสำเร็จแล้ว!",
	CheckOutHeader_Step_Payment: "การชำระเงิน",
	CheckOutHeader_Step_Review: "ทบทวน",
	CheckOutHeader_Step_Place_Order: "สถานที่การสั่งซื้อ",
	CheckOutRightPanel_Review_Order_Btn: "ตรวจสอบคำสั่งซื้อ",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "สถานที่การสั่งซื้อ",
	CheckoutRightPanelContent_OrderSummary_Title: "สรุปการสั่งซื้อ",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "แก้ไขตะกร้าสินค้า",
	ShoppingCart_Participants: "ผู้เข้าร่วม)",
	CheckOutStepPayment_Payment_Methods_Title: "เลือกวิธีการชำระเงิน",
	CheckOutStepPayment_Credit_Title: "เครดิตหรือเดบิต",
	CheckOutStepPayment_Wholesaler_Title: "ผู้ค้าส่ง",
	CheckOutStepPayment_ServiceCredit_Title: "เครดิตการฝึกอบรม",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "คุณมี [available_credit] เครดิตการฝึกอบรม",
	CheckOutStepPayment_BoschAccount_Title: "บัญชีบ๊อช",
	Checkout_Second_PaymentMethod_Headline: "วิธีการชำระเงิน",
	CheckOutStepReview_Edit: "แก้ไข",
	CheckOutStepReview_Terms_Label: "ข้อกำหนดและเงื่อนไขและนโยบายการยกเลิก",
	CheckOutStepReview_Terms_Text: "ฉันได้อ่าน ทำความเข้าใจ และยอมรับ [ข้อกำหนด เงื่อนไข และนโยบายการยกเลิก] ของการขาย",
	SelectSecondPaymentMethodModal_Title: "เลือกวิธีการชำระเงินสำรอง",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "จ่าย [amount]",
	StepPaymentWholesaler_Title: "เลือกตัวแทนจำหน่าย",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "ยอมรับข้อกำหนดและเงื่อนไข",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "โปรดอ่านและยอมรับข้อกำหนดและเงื่อนไขและนโยบายการยกเลิกของเราก่อนดำเนินการต่อ",
	CheckoutDescription_ThankYou: "ขอขอบคุณที่ส่งคำสั่งซื้อของคุณเกี่ยวกับโซลูชั่นการฝึกอบรมด้านยานยนต์ของบ๊อช!",
	CheckoutDescription_Message: "บิตและไบต์ของเราได้เริ่มประมวลผลคำสั่งซื้อของคุณแล้ว ในขณะเดียวกัน โปรดวางเครื่องยนต์ไว้ที่รอบเดินเบา",
	CheckoutDesctiption_Text: "เราจะส่งการยืนยันขั้นสุดท้ายให้คุณทางอีเมล รวมถึงรายละเอียดการจองทั้งหมดของคุณ",
	WorkshopSelection_RemoveWorkshopWarning_Title: "คุณกำลังจะลบเวิร์กชอปที่เลือก",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "การลบเวิร์กชอปที่เลือก ผู้เข้าร่วมที่เพิ่มทั้งหมดจะถูกลบออก",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "คลิก \"ดำเนินการต่อ\" หากคุณต้องการดำเนินการต่อ หรือคลิก \"ยกเลิก\" เพื่อหยุดการดำเนินการนี้",
	CheckOutStepPayment_Insufficient_Credit_Message: "คุณมีเครดิตไม่เพียงพอ ยอดเครดิตของคุณคือ [available_credit] เครดิต แต่คุณต้องมีเครดิต [cart_total_credits] เพื่อดำเนินการชำระเงิน โปรดเลือกวิธีการชำระเงินอื่นหรือลบสินค้าออกจากตะกร้าสินค้าของคุณ",
	Training_centers_filter_items: "ศูนย์",
	Training_Programs_Filter_Item: "โปรแกรม",
	Training_Program_Filter_Item: "โปรแกรม",
	Training_Program_Year: "ปี)",
	Training_Program_Detail_Title: "โครงการอบรมหลักสูตร",
	AccountButton_Workshops_MenuItem: "การประชุมเชิงปฏิบัติการ",
	AccountButton_Wallet_MenuItem: "กระเป๋าสตางค์",
	Header_MyBoschProfile_Header: "โปรไฟล์ MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "มีอยู่",
	AddParticipantsModal_AddOrEditParticipantsTitle: "เพิ่ม/แก้ไขผู้เข้าร่วม",
	ShoppingCart_CreditPriceUnit: "เครดิต",
	Common_IncludedVAT: "รวม สสส",
	TrainingCatalogue_Length: "ความยาว",
	TrainingCatalogue_Method: "วิธี",
	Course_Price_Credits: "เครดิต",
	Catalogue_Grid_Register_Button: "ลงทะเบียน",
	Catalogue_Credit_Tilte: "คลิกที่นี่เพื่อดูราคาในเครดิต",
	Catalogue_Price_Tilte: "คลิกที่นี่เพื่อดูราคาในสกุลเงิน EUR",
	Catalogue_Grid_Vat: "SST ไม่รวม",
	EventCalendar_Seats: "ที่นั่ง",
	MenuHeader_Calendar: "ปฏิทิน",
	MenuHeader_Catalog: "แคตตาล็อก",
	TableCell_Minutes: "นาที",
	ListFilter_NoResult: "ไม่มีผลลัพธ์",
	Course_Description: "คำอธิบายรายวิชา",
	Training_course_detail: "ข้อมูลเบื้องต้นเกี่ยวกับโซเชียลมีเดีย",
	Training_course_detail_add_waiting: "เพิ่มในรายการรอทั่วไป",
	Register_now_btn: "สมัครตอนนี้ !",
	Register_now_btn_add_event_to_cart: "สมัครตอนนี้ !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "ดูบันทึกทั้งหมด",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "ดูน้อยลง",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "ดูบันทึกทั้งหมด",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "ดูน้อยลง",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "ดูบันทึกทั้งหมด",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "ดูน้อยลง",
	TeamCreate_TitleError: "ต้องระบุชื่อเรื่อง",
	Dashboard_Headerbar_BookCourse: "จองคอร์ส",
	Dashboard_Headerbar_ExitProxy: "ออกจากพร็อกซี",
	Dashboard_Headerbar_EvaluateCourse: "ประเมินหลักสูตร",
	Dashboard_Headerbar_EvaluateCourse_Note: "โปรดประเมินหลักสูตรนี้เพื่อสร้างใบรับรองของคุณ!",
	Dashboard_Headerbar_DownloadCertificate: "ดาวน์โหลดใบรับรอง",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "ถอน",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "ถอน",
	Dashboard_Headerbar_WelcomeMessage: "ยินดีต้อนรับสู่แดชบอร์ดการเรียนรู้ของคุณ",
	Dashboard_Headerbar_ProxingAs: "มอบฉันทะเป็น",
	Dashboard_TrainingProgram_Status_Completed: "สมบูรณ์",
	Dashboard_TrainingProgram_Status_InProgress: "กำลังดำเนินการ",
	External_SelectWholesalerModal_Title: "เพิ่มการเข้าถึงของผู้ใช้ภายนอก",
	External_SelectWholesalerModal_Company_Label: "บริษัท",
	External_SelectWholesalerModal_Outlet_Label: "เต้าเสียบ",
	External_SelectWholesalerModal_Company_Placeholder: "โปรดเลือก",
	External_SelectWholesalerModal_Outlet_Placeholder: "โปรดเลือก",
	External_SelectWholesalerModal_ApplyButton: "นำมาใช้",
	External_SelectWholesalerModal_CancelButton: "ยกเลิก",
	External_RemoveWholesalerModal_ConfirmLabel: "การยืนยัน",
	External_SelectWholesalerModal_ConfirmMessage: "คุณแน่ใจหรือไม่ว่าต้องการนำผู้ค้าส่งรายนี้ออก",
	External_SelectWholesalerModal_YesButton: "ใช่",
	External_SelectWholesalerModal_NoButton: "เลขที่",
	Training_program_team_member: "สมาชิกในทีม",
	Training_program_assign_training_program: "กำหนดโปรแกรมการฝึกอบรม",
	Assign_modal_student_confirmation_text1: "คุณกำลังจะกำหนด [Training Program Name] ให้กับเส้นทางการเรียนรู้ของคุณ",
	Assign_modal_student_confirmation_text2: "คุณต้องการดำเนินการต่อหรือไม่",
	MyFullCalendar_Jan: "ม.ค",
	MyFullCalendar_Feb: "ก.พ",
	MyFullCalendar_Mar: "มี.ค",
	MyFullCalendar_Apr: "เม.ย",
	MyFullCalendar_May: "อาจ",
	MyFullCalendar_Jun: "มิ.ย",
	MyFullCalendar_Jul: "ก.ค",
	MyFullCalendar_Aug: "ส.ค",
	MyFullCalendar_Sep: "ก.ย",
	MyFullCalendar_Oct: "ต.ค",
	MyFullCalendar_Nov: "พ.ย",
	MyFullCalendar_Dec: "ธ.ค",
	MenuHeader_CourseCatalog: "แคตตาล็อกหลักสูตรการฝึกอบรม",
	MenuHeader_Event: "ปฏิทินการฝึกอบรม",
	MenuHeader_Program: "โปรแกรมการฝึกอบรม",
	MenuHeader_Services: "บริการฝึกอบรม",
	MenuHeader_Log_out: "ออกจากระบบ",
	Filter_Location: "ที่ตั้ง",
	TeamEdit_UpgradeToCompanyAdministrator: "อัปเกรดเป็นผู้ดูแลระบบของบริษัท",
	TeamEdit_PersonalWebAccountStatusIs: "สถานะบัญชีเว็บส่วนบุคคลคือ",
	Header_CreateNewEmployee: "สร้างพนักงานใหม่",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "ไม่มีที่นั่ง!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "ไม่มีที่นั่งว่างในงานที่เลือก",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "โปรดเลือกกิจกรรมอื่นหรือลบผู้เข้าร่วมที่เพิ่มแล้วเพื่อดำเนินการต่อ",
	Common_SuccessModal_Title: "ยินดีด้วย!",
	TrainingProgramDetails_Merit_StatusText: "บุญ",
	TrainingProgramDetails_Resit_StatusText: "นั่งใหม่",
	EmployeeProfile_UserExistErrorMessage: "มีบัญชีอยู่แล้ว!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "การชำระเงินล้มเหลว โปรดลองอีกครั้งหรือใช้วิธีการชำระเงินอื่น",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "คุณปิดการใช้งานพนักงานที่เลือกสำเร็จแล้ว!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "รอดแล้ว!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "การเปลี่ยนแปลงของคุณได้รับการบันทึก.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "ไม่สามารถถอนออกจากหลักสูตรอีเลิร์นนิงได้ โปรดติดต่อเราหากต้องการรายละเอียดเพิ่มเติม",
	Dashboard_Team_PersonalAccount_Pending: "รอดำเนินการ",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "สร้างพนักงานแล้ว!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "สร้างพนักงานใหม่ของคุณเรียบร้อยแล้ว",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "การตั้งค่านี้ทำให้คุณสามารถจำกัดไม่ให้พนักงานลงทะเบียนกิจกรรมการฝึกอบรมได้ เฉพาะผู้ดูแลระบบของบริษัทเท่านั้นที่จะสามารถลงทะเบียนพนักงานในกิจกรรมการฝึกอบรมได้เมื่อไม่ได้เลือกการตั้งค่านี้",
	Training_catalog_sort_by: "เรียงตาม",
	CourseDetail_EvaluationRestriction_Title: "ประเมินผลการฝึกไม่ได้!",
	CourseDetail_EvaluationRestriction_Message: "การประเมินผลการฝึกอบรมสามารถส่งได้โดยนักเรียนเท่านั้น โปรดแนะนำให้นักเรียนเข้าสู่ระบบ Bosch Automotive Training Solutions และทำการประเมินการฝึกอบรมให้เสร็จสิ้น",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "หากนักเรียนยังไม่มีบัญชี คุณสามารถแชร์คำเชิญได้ในการตั้งค่าโปรไฟล์ผู้ใช้ของสมาชิกในทีม",
	Breadcrumb_Download_Page: "ดาวน์โหลด",
	Download_Table_Category_Cell: "หมวดหมู่",
	Download_Table_Title_Cell: "ชื่อ",
	Download_File_Filter_Item: "ไฟล์",
	Download_File_Filter_Items: "ไฟล์",
	Download_Header: "พื้นที่ดาวน์โหลด",
	DownloadArea_Button_Download: "ดาวน์โหลด",
	Popup_Title_Error: "ข้อผิดพลาด",
	Popup_Title_Success: "ความสำเร็จ",
	Popup_Title_Info: "ข้อมูล",
	Popup_Title_Warning: "คำเตือน!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "มีการลงทะเบียนกิจกรรมต่อไปนี้อยู่แล้ว และจะไม่ถูกยกเลิกโดยอัตโนมัติโดยการถอนตัวจากโปรแกรมการฝึกอบรม โปรดยกเลิกทีละรายการ",
	TrainingProgram_PDF_Export: "ส่งออก PDF",
	TrainingProgram_PDF_Export_Participant: "ผู้เข้าร่วม:",
	TrainingProgram_PDF_Export_Status: "สถานะ:",
	TrainingProgram_PDF_Export_Progress: "ความคืบหน้าโดยรวม:",
	TrainingProgram_PDF_Export_TableHeader_Title: "ชื่อการฝึกอบรม",
	TrainingProgram_PDF_Export_TableHeader_Status: "สถานะ",
	TrainingProgram_PDF_Export_TableHeader_Grade: "ระดับ",
	TrainingProgram_PDF_Export_TableHeader_Date: "วันที่",
	AccountButton_CompanyChange_MenuItem: "การเปลี่ยนแปลงบริษัท",
	SelectCompanyModal_Title: "เลือกบริษัท",
	CloseButton: "ปิด",
	Change_Button: "เปลี่ยน",
	ChangeManagerModal_Content: "เลือกผู้ใช้",
	ChangeCompanyModal_HeadOffice: "(สำนักงานใหญ่)",
	SelectCompanyModal_Content: "กรุณาเลือกบริษัทเพื่อเข้าสู่ระบบ...",
	AlertSuccess_Title: "ความสำเร็จ",
	CheckOutStepPayment_SecondaryPayment_Title: "เลือกวิธีการชำระเงินรอง",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "เพิ่มวิธีการชำระเงินรอง",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "โปรดเพิ่มวิธีการชำระเงินสำรองด้านล่างหรือเลือกวิธีการชำระเงินอื่นด้านบน",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "คุณมีเครดิตบริการไม่เพียงพอที่จะชำระค่าสินค้าในรถเข็น หากต้องการใช้วิธีการชำระเงินที่เลือกไว้ต่อไป คุณต้องได้รับเครดิตบริการเพิ่มหรือใช้วิธีการชำระเงินเพิ่มเติมเพื่อชำระส่วนต่าง ความแตกต่างคือ [difference_amount] (ไม่รวม gst)",
	ShoppingCartTotalPrice_TotalInCredit: "ราคารวมเป็นเครดิต",
	ShoppingCartTotalPrice_YourAvaliableCredit: "เครดิตที่มีอยู่ของคุณ",
	ShoppingCartTotalPrice_YourDifference: "ความแตกต่าง",
	ShoppingCartTotalPrice_SubtotalExTax: "ผลรวมย่อยไม่รวม GST",
	ShoppingCartTotalPrice_SubtotalInTax: "รวมผลรวมย่อย GST",
	CheckOutStepPayment_Second_Payment_Methods_Title: "วิธีการชำระเงินรอง",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "แก้ไขวิธีการชำระเงิน",
	Checkout_Second_PaymentMethod: "วิธีการชำระเงินที่สอง",
	Breadcrumb_TrainingCourseDetails: "รายละเอียดหลักสูตรการฝึกอบรม",
	DownloadArea_FailedToDownload_ErrorMessage: "การดาวน์โหลดเอกสารล้มเหลว โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของบริษัทเพื่อขอความช่วยเหลือ",
	DownloadArea_FileNotAvailable_ErrorMessage: "ไฟล์ดาวน์โหลดไม่พร้อมใช้งาน โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของบริษัทเพื่อขอความช่วยเหลือ",
	DownloadArea_FileAccessForbidden_ErrorMessage: "ห้ามดาวน์โหลดไฟล์ โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของบริษัทเพื่อขอความช่วยเหลือ",
	SSO_Redirecting_Message: "คุณกำลังเปลี่ยนเส้นทางไปยังพอร์ทัล BATS...",
	SSO_Redirecting_ParameterErrorMessage: "ไม่ได้ระบุประเทศและ/หรือภาษา โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของบริษัทเพื่อขอความช่วยเหลือ",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "ไม่ยอมรับคำขอลงทะเบียน",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "อาจเนื่องมาจากอยู่ภายในเกณฑ์วันลงทะเบียนของหลักสูตรสำหรับหลักสูตรหนึ่งหลักสูตรขึ้นไป โปรดใช้พื้นที่ \"ติดต่อเรา\" หากคุณมีคำถามหรือคำขอใด ๆ",
	TimeSlotPicker_DateFormat_Title: "รูปแบบวันที่",
	UpdateAccountSettingsConfirmationModal_Title: "คุณกำลังจะเปลี่ยนรูปแบบวันที่!",
	UpdateAccountSettingsConfirmationModal_Message1: "รูปแบบวันที่จะแสดงตามที่คุณเลือกและสามารถเปลี่ยนแปลงได้ตลอดเวลา",
	UpdateAccountSettingsConfirmationModal_Message2: "คุณต้องการดำเนินการต่อหรือไม่?",
	ProfileSummary_Settings_Title: "การตั้งค่า",
	UpdateAccountSettings_DateFormat_Title: "การตั้งค่ารูปแบบวันที่",
	UpdateAccountSettings_DateFormat_ErrorMessage: "ไม่สามารถอัปเดตรูปแบบวันที่ได้",
	UpdateAccountSettings_DateFormat_SuccessMessage: "อัปเดตการตั้งค่ารูปแบบวันที่เรียบร้อยแล้ว",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "ดาวน์โหลดใบรับรอง",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "ประกาศนียบัตรหลักสูตรการฝึกอบรม",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "การดาวน์โหลดล้มเหลว กรุณาลองอีกครั้ง. หากปัญหายังคงอยู่ โปรดติดต่อเราผ่านแบบฟอร์มติดต่อของเรา",
	CourseWithdrawalModal_PendingWithdraw_Title: "เกินเกณฑ์การถอนเงิน",
	CourseWithdrawalModal_PendingWithdraw_Message1: "เกินเกณฑ์ในการถอนตัวจากหลักสูตรนี้แล้ว และต้องมีการดำเนินการด้วยตนเองโดยฝ่ายบริหารการฝึกอบรมของเรา",
	CourseWithdrawalModal_PendingWithdraw_Message2: "การยืนยันครั้งสุดท้ายจะถูกส่งทางอีเมล",
	CourseWithdrawalModal_PendingWithdraw_Message3: "โปรดดู [นโยบายการยกเลิก] ของเราสำหรับรายละเอียดเพิ่มเติม",
	TrainingCourse_Course_NotAvailable_Title: "หลักสูตรการฝึกอบรมไม่พร้อมใช้งาน",
	TrainingCourse_Course_NotAvailable_Message: "หลักสูตรการฝึกอบรมไม่พร้อมใช้งาน โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบของบริษัทของคุณเพื่อขอความช่วยเหลือเพิ่มเติม",
	TrainingCourse_NotAvailable_Message_1: "คุณกำลังพยายามดูหลักสูตรการฝึกอบรมที่ไม่พร้อมให้บริการ",
	TrainingCourse_NotAvailable_Message_2: "โปรดกลับไปที่ [แคตตาล็อกการฝึกอบรม] ของเราเพื่อค้นหาหลักสูตรที่มีอยู่ทั้งหมด",
	LanguageSelection_SelectALanguage: "เลือกภาษา",
	LanguageSelection_SelectYourCountry: "เลือกประเทศของคุณ",
	Checkout_PaymentMethod_Headline: "วิธีการชำระเงิน",
	Download_Categories_Catalogue: "แคตตาล็อก",
	Download_Categories_Calendar: "ปฏิทิน",
	Download_Categories_General: "ทั่วไป",
	Download_Categories_Information: "ข้อมูล",
	Download_Categories_Userguides: "คู่มือผู้ใช้",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "นโยบายการยกเลิกการจอง",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "ยกเลิก",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "ต่อเนื่อง",
	Duration_Seconds: "วินาที",
	Duration_Day: "วัน",
	Duration_Hour: "ชั่วโมง",
	Duration_Minute: "นาที",
	Duration_Second: "ที่สอง",
	EventCalendar_ExternalLink_Tooltips: "ซื้อผ่าน [ชื่อลงทะเบียนภายนอก]",
	EventCalendar_ExternalLink_Seat_NA: "N/A",
	EventCalendar_ExternalLink_Cancel: "ยกเลิก",
	EventCalendar_ExternalLink_Continue: "ต่อเนื่อง",
	EventCalendar_ExternalLinkModal_Title: "คุณกําลังจะถูกเปลี่ยนเส้นทาง...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "กิจกรรมที่เลือกจะถูกขายผ่าน [ชื่อการลงทะเบียนภายนอก]",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "การดําเนินการต่อจะนําคุณไปยังเว็บไซต์ภายนอก",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "คุณต้องการดําเนินการต่อหรือไม่?",
	Team_ContactPerson_FirstName_Tooltips: "ชื่อนักเรียนสามารถแก้ไขได้โดยทีมบริหารการฝึกอบรมของ Bosch เท่านั้น โปรดติดต่อเราผ่านแบบฟอร์มการติดต่อบนเว็บไซต์นี้เพื่อการสนับสนุนเพิ่มเติม",
	Team_ContactPerson_LastName_Tooltips: "ชื่อนักเรียนสามารถแก้ไขได้โดยทีมบริหารการฝึกอบรมของ Bosch เท่านั้น โปรดติดต่อเราผ่านแบบฟอร์มการติดต่อบนเว็บไซต์นี้เพื่อการสนับสนุนเพิ่มเติม",
	Withdraw_ExternalLink_Cancel: "ยกเลิก",
	Withdraw_ExternalLink_Confirm: "ยืนยัน",
	Withdraw_ExternalLinkModal_Title: "คุณกําลังจะถูกเปลี่ยนเส้นทาง",
	Withdraw_ExternalLinkModal_Message: "การถอนเงินสําหรับหลักสูตรนี้จัดการโดยผู้ให้บริการภายนอก",
	Withdraw_ExternalLinkModal_Confirmation: "การคลิก \"ยืนยัน\" จะเป็นการเปิดแท็บใหม่และเปลี่ยนเส้นทางคุณไปยังเว็บไซต์ภายนอก",
	Button_AddToWaitingList_Tooltips: "เพิ่มในรายการรอ",
	Button_AddToCart_Tooltips: "หยิบใส่ตะกร้า",
	Button_TrainingCourseDetail_Tooltips: "ดูรายละเอียด",
	Button_TrainingProgramDetail_Tooltips: "ดูรายละเอียด",
	Button_AssignTrainingProgram_Tooltips: "กําหนดโปรแกรมการฝึกอบรม",
	Button_DeleteFromCart_Tooltips: "ลบออกจากรถเข็น",
	Button_RemoveParticipant_Tooltips: "ลบผู้เข้าร่วม",
	Button_DownloadBillingTransaction_Tooltips: "ดาวน์โหลด",
	Button_RemoveExternal_Tooltips: "ถอด"
};