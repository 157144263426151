export const TRANSLATIONS_NL_BE = {
	LandingPage_Title: "Welkom bij Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Kies uw land/regio",
	Header_ContactUs: "Neem contact met ons op",
	Header_Search: "Zoeken",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Login of maak een account aan",
	Header_LogIn: "Login",
	Header_Cart: "Winkelwagen",
	Header_ShoppingCart: "Winkelwagen",
	Header_LogOut: "Uitloggen",
	Header_ChangeDetails: "Details veranderen",
	Footer_ExploreOurTraining: "Ontdek onze trainingen",
	Footer_TrainingAtYourFingertips: "Trainen binnen handbereik",
	Footer_DontMissAnyStory: "Mis geen enkel verhaal",
	Footer_ExploreOurStories: "Ontdek onze verhalen",
	Footer_GetInTouchWithUs: "Neem contact met ons op",
	Footer_SendUsAMessage: "Stuur ons een bericht",
	Footer_ToContactForm: "Contactformulier",
	Footer_InventedForLife: "Technologie voor het leven",
	Footer_GeneralContactInfo: "Algemene contactgegevens",
	Footer_ProductSecurity_Psirt: "Product beveiliging (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Licenties en patenten",
	Footer_PurchasingLogistics: "Inkoop en logistiek",
	Footer_CorporateInformation: "Bedrijfsinformatie",
	Footer_LegalNotice: "Juridische mededeling",
	Footer_DataProtectionNotice: "Kennisgeving gegevensbescherming",
	Footer_PrivacySettings: "Privacy instellingen",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch N.V. 2023, alle rechten voorbehouden",
	Footer_StillLookingForSomething: "Zoekt u nog iets?",
	Footer_TrainingServices: "Trainingscataloog",
	Footer_Courses: "Trainingen",
	Footer_Programs: "Programma",
	Footer_EventsCalendar: "Trainingskalender",
	Footer_TrainingCenters: "Training centra",
	Footer_Dashboard: "Dashboard",
	Footer_MyDashboard: "Mijn dashboard",
	Footer_ShoppingCart: "Winkelwagen",
	Footer_YourShoppingCart: "Uw winkelwagen",
	Footer_Administration: "Administratie",
	Footer_Employees: "Werknemers",
	Footer_Wholesalers: "Leveranciers",
	Footer_Transactions: "Transacties",
	Footer_Workshops: "Werkplaatsen",
	Footer_WorkLikeABoschApplyNow: "Werk  - solliciteer nu",
	Footer_BoschGlobalCareers: "Wereldwijde carrières bij Bosch",
	Footer_PurchasingAndLogistics: "Inkoop en logistiek",
	Footer_BecomeABusinessPartner: "Wordt een zakenpartner",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Hoe onze trainingen vernieuwen om uw bedrijf te helpen",
	Footer_Language: "Land selecteren",
	MenuPopup_MyCart: "Mijn winkelwagen",
	Stories_OurTrainingStories: "Onze trainingsverhalen",
	Stories_ConnectingExperts: "Verbinden van experts en enthousiastelingen, dromers en doeners, innovators en influencers - ontdek en ervaar",
	Stories_TopStories: "Top verhalen",
	Stories_AtHome: "Thuis",
	Stories_Careers: "Carrières",
	Stories_History: "Geschiedenis",
	Stories_IndustryAndTrades: "Industrie en handel",
	Stories_Mobility: "Mobiliteit",
	Stories_Research: "Onderzoek",
	Stories_Sustainability: "Verduurzaming",
	Stories_AllStories: "Alle verhalen",
	Stories_BlogATrainersDay: "BLOG | Dag van een trainer",
	Stories_ADayinLifeOfATrainer: "Een dag in het leven van een trainer",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "Verhaal | wat u drijft, drijft ons",
	Stories_StoryHowCanWeBenefitYourBusiness: "Verhaal | Hoe kunnen wij uw bedrijf verder helpen?",
	Stories_HowOurTrainingEvolves: "Hoe onze trainingen evolueren",
	Stories_AModernTrainingApproach: "De wereld van online trainen",
	Stories_StoryAVirtualOnlineTrainingWorld: "Verhaal | uw online trainingsmogelijkheden",
	Stories_SubscribeToRSSFeed: "Abboneer op RSS feed",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Over ons",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Kom in contact",
	Homepage_LatestNews: "Ontdek waarom we gedreven worden door de honger naar kennis",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Onze vacatures",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Wat u drijft, drijft ons",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Deel dit op",
	BoschServiceTraining_TheChallenge: "De uitdaging: nieuwe technologie de baas zijn",
	BoschServiceTraining_WorldwidePresence: "Wereldwijde aanwezigheid",
	BoschServiceTraining_TechnicalTraining: "Technische trainingen van Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "De aanbiedingen en kansen die Bosch biedt",
	BoschServiceTraining_TechnicalCoursesDesc: "Voor elke doelgroep zijn er passende trainingen - of u een beginner bent of een expert. Het aanbod bestaat uit eendaagse trainingen en trainingen die een aantal dagen duren. In de trainingen worden diverse technische aspecten behandelend in verschillende trajecten (zoals benzinetraject en dieseltraject). In de technische trainingen geeft Bosch uitgebreide toelichting op alle standaard voertuigsystemen van Bosch systemen en niet-Bosch systemen. De inhoud is speciaal afgestemd op de wensen van de mechaniekers.",
	BoschServiceTraining_TrainedEmployeesDesc: "Getrainde medewerkers zijn een duidelijk voordeel voor uw werkplaats. Doordat de hedendaagse wagens steeds complexer worden en de vernieuwing van technologie elkaar blijft opvolgen, zorgt dit voor constante nieuwe uitdagingen. Alleen met de nodige kennis kunnen mechaniekers blijven diagnosticeren en werkzaamheden aan hedendaagse wagens uitvoeren op een efficiënte en kostenbesparende manier.",
	BoschServiceTraining_RegardlessOfHow: "Ongeacht hoe goed en gekwalificeerd automotive specialisen zijn - er zijn altijd situaties waarin  nieuwe voertuigsystemen of onbekende fouten op een wagen zich voordoen. In deze situaties kan het oplossen van problemen bijzonder tijdrovend worden of zelfs geen succesvolle resultaten opleveren. In het ergste geval zijn klanten voor een lange tijd hun wagen kwijt zonder gewenst resultaat en is de wagen niet gerepareerd.",
	BoschServiceTraining_ItIsPrecisely: "Juist op dit punt zet Bosch technische trainingen in: om de servicekwaliteit te verbeteren en processen te versnellen. De huidige Bosch trainingen bieden ondersteuning in het lokaliseren van problemen, het sneller repareren van de auto en meer auto's in dezelfde tijd te repareren zodat de wachttijd van de klant wordt verkort. Zo zorgt Bosch via systematische procedures voor sneller en productiever werk.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Voor meer dan 125 jaar is Bosch de voorkeursleverancier voor originele onderdelen, componenten en systemen voor veel toonaangevende autofabrikanten wereldwijd. Deze ervaring komt met unieke kennis over de nieuwste technologieën die als basis dienen voor Bosch-ondersteuning",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Centra zijn overal ter wereld te vinden: van Spanje tot Turkije, Zuid Afrika, Australië en Brazilië - Bosch biedt haar medewerkers praktijkgerichte opleidingen aan die in lijn staan met de modernste technologie.",
	BoschServiceTraining_AgileCompany: "Flexibel bedrijf",
	BoschServiceTraining_Collaboration: "Samenwerking",
	BoschServiceTraining_ConnectedMobility: "Verbonden mobiliteit",
	BoschServiceTraining_ArtificialIntelligence: "Kunstmatige intelligentie",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Gedachtenleiders in dialoog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnose- en testapparatuur",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Technische fundamenten in de automotive",
	BoschServiceTraining_Icon_Gasoline: "Benzine",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "E-mobility",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektriciteit en elektronica",
	BoschServiceTraining_Icon_BodyAndComfort: "Comforttechnologie",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassis en remmen",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmissie en aandrijflijnen",
	BoschServiceTraining_Icon_CommercialVehicles: "PassThru",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online trainingen",
	BoschServiceTraining_Icon_BusinessSkills: "Ondernemingsvaardigheden",
	BoschServiceTraining_Icon_Accreditations: "Certificering",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Trainingsprogramma",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerVoornaamAchternaam",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Een dag in het leven van een trainer",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Elke dag is een nieuw avontuur",
	ADayInTheLifeOfATrainer_Summary: "Samenvatting",
	ADayInTheLifeOfATrainer_TheDayJourney: "Het dagboek",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Iedere dag is een drukke dag maar altijd met een leuke ervaring. Het plezier om cursisten op te leiden, wetende dat zij de sleutel voor de toekomst in handen hebben is een verantwoordelijkheid en tegelijkertijd zo opwindend. De studenten zien groeien met elke les op dagelijkse- of wekelijkse basis bewijst voor ons dat we werken aan een gemeenschappelijk doel terwijl we de moderne technologie integreren die de toekomst drijft!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Aan het einde van iedere dag gaan we bij elkaar zitten en bespreken we werkdag. De dagen gaan zo snel voorbij, maar deze bespreking is het bewijs dat wat bereiken niet alleen belonend is voor de curisten, maar ook voor ons als trainers.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We kunnen aan het einde van de dag tevreden naar huis, wetende dat we een ons werk goed hebben gedaan.",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Voorbereiding en laatste controle voor trainingsmateriaal. Controleren van belangrijke e-mails",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Training begint",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Welkom heten van deelnemers. Agenda voor de dag. De groep leren kennen door de dynamiek van iedere deelnemer te begrijpen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Theorie",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Enige mate van interactie met de groep is nodig om de training zo interessant mogelijk te maken. Zorg ervoor dat er opdrachten zijn over de theorie om de groep nog beter bij de training te betrekken.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lunch pauze",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktijk",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "De training wordt voortgezet met praktijkopdrachten. Voor een trainer is het altijd belangrijk om altijd aanwezig en beschikbaar te zijn voor eventuele hulp.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Samenvatting",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Terug naar de klas om de training van de dag te bespreken. Beantwoorden van alle vragen die zich kunnen voordoen. Beginnen met de voorbereiding voor de  volgende trainingen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Einde van de dag",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "De deelnamecertificaten worden overhandigd aan de deelnemers.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Thuis",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Hoe kunnen we bedrijf ten goede komen?",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Hoe onze trainingen evolueren",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Wij zijn voortdurend in ontwikkeling om ervoor te zorgen dat de training up to date zijn en van hoge kwaliteit",
	BoschTrainingBenefits_TheBoschServiceTraining: "Het Bosch Service Training Center wilt u graag helpen bij het ontwikkelen van uw bedrijf door middel van een uitgebreid en zeer relevant portfolio over technische trainingen en vervolgopleidingen met gecertificeerde kwalificaties. Op de volgende pagina's vindt u een overzicht van ons trainingsprogramma en de actuele vervolgopleidingen. Wij zien u en uw medewerkers graag op onze trainingen.",
	BoschTrainingBenefits_LongStandingExperience: "Langdurige ervaring met en uitgebreide kennis van alle systemen op het gebied van autotechniek.",
	BoschTrainingBenefits_QuickAndEfficient: "Problemen",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "De voordelen",
	BoschTrainingBenefits_LMS: "Learining Management Systeem - Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions dekt het volledige gebied van automotive af. Specialiseer u op  specifieke gebieden of verbreed uw competentie met het opleidingsaanbod van Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Opgedane kennis, faciliteiten, reparatie, onderhoud en service werkzaamheden",
	BoschTrainingBenefits_TheHighDegree: "De hoge mate van praktijk en kleine groepen garanderen doelgericht leren",
	BoschTrainingBenefits_ParticipationInTraining: "Deelname aan training verhoogt de motivatie en tevredenheid van uw medewerkers en de aanvullende kwalificaties overtuigen uw klanten",
	BoschTrainingBenefits_IncreasedSales: "Verhoogde omzetten door systeemkennis van verschillende merken",
	BoschTrainingBenefits_MethodicalProcedures: "Methodische procedures verlagen de wachttijd voor uw klanten",
	BoschTrainingBenefits_HighlyQualified: "Hoog gekwalificeerde trainers brengen systematische kennis over",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificatie voor de trainingsoplossing die geschikt is voor u of uw medewerkers",
	BoschTrainingBenefits_DirectOnlineBooking: "Directe online boeking en beheer over de trainingen voor uzelf of uw medewerkers",
	BoschTrainingBenefits_OnlineAccess: "Online toegang tot leerdocumentatie",
	BoschTrainingBenefits_AccessToYourOwn: "Toegang tot uw persoonlijke trainingshistorie en certificaten",
	BoschTrainingBenefits_ToolsForManagement: "Handvatten voor het beheren van uw eigen loopbaanontwikkeling",
	BoschTrainingBenefits_ManagerHasFull: "Manager heeft een compleet overzicht van de trainingen voor de werknemers in het systeem",
	BoschTrainingBenefits_Access24hours: "Toegang 24 uur per dag 7 dagen in de week",
	ModernTrainingApproach_KnowledgeIsKey: "Kennis is de sleutel tot succes voor iedere werkplaats. Daarom biedt Bosch een uitgebreid programma aan voor professionele training en ontwikkeling. Dit stelt cursisten in staat om op het juiste moment de juiste kennis op de juiste plek te zoeken - kennis die nodig is om de wagen van de klant te kunnen onderhouden en/of te repareren. Bosch heeft webcasting toegevoegd aan het reeds aangeboden E-learning pakket. Dit zijn service trainingen die speciaal zijn ontworpen voor streaming.",
	ModernTrainingApproach_ANewLearning: "Een nieuwe mogelijkheid voor leren",
	ModernTrainingApproach_YourOnlineTraining: "Uw online training mogelijkheden",
	ModernTrainingApproach_RecentlyTheWorld: "De wereld is recentelijk veranderd. Zie wat wij doen om te helpen",
	ModernTrainingApproach_TheOnlineTrainingWorld: "De wereld van online trainen",
	ModernTrainingApproach_NewTeachingMethod: "Nieuwe leermethode",
	ModernTrainingApproach_InAdditionTo: "Als aanvulling op de live training hebben wij  webcasting training toegevoegd aan ons trainingsprogramma. Onze trainer staat tot uw beschikking met uitgebreide theoretische kennis in het virtuele klaslokaal of in de praktijk. Zoals gewoonlijk ontvangt u veel information over de systemen op de wagen en de bijbehorende Bosch producten. Natuurlijk zijn er ook mogelijkheden om vragen te stellen. De vragen worden direct tijdens de webcast beantwoord. De webcasten duren 1 à 2 uur. Een stabiele internetverbinding is een vereiste. Zoek direct voor een passende webcast training in uw trainingscatalogus door in het zoekveld de term \Webcast\ in te voeren.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch webcasting: innovatief en praktisch",
	ModernTrainingApproach_BoschWebcastingText: "Om deel te kunnen nemen aan een Bosch webcast training moet de cursist eerst inbellen. Een individuele toegangscode wordt verstuurd voordat de training begint zodat u kunt inloggen. Er kunnen tot wel 200 deelnemers mee doen op hetzelfde moment vanaf elke locatie. De training wordt gegeven door een trainer en vragen kunnen direct live gesteld worden via de chatbox",
	ModernTrainingApproach_TheDigitalSupplementHeading: "De digitale aanvulling voor klassikaal trainen",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting en klassikale trainingen vullen elkaar perfect aan. In beide gevallen wordt de training door een gekwalificeerde trainer gegeven. Webcasts zijn perfect voor onderwerpen die digitaal kunnen worden uitgelegd zonder dat praktijk nodig is. Een voorbeeld hiervan is kennis overdragen over ESI[tronic] 2.0 of kenmerken van nieuwe ADAS systemen. Wanneer het aankomt op efficient samenwerken in de werkplaats of praktisch werken aan een auto blijft de voorkeur uitgaan naar klassikale trainingen.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streamen kan goedkoper zijn",
	ModernTrainingApproach_StreamingCanBeCheaperText: "De kosten per deelnemer zijn meestal lager bij webcasting dan bij klassikale trainingen. De reden hiervoor is dat meer cursisten kunnen deelnemen bij webcasting en de inspanningen voor de trainer normaal gesproken lager zijn (geen reistijd naar en van de locatie en minder tijd voor de training nodig).",
	ModernTrainingApproach_BookAndProfitHeading: "Boek en profiteer",
	ModernTrainingApproach_BookAndProfitText: "Webcasts kunnen geboekt worden via onze website op dezelfde manier als onze andere trainingen. U heeft de keuze tussen per webcast betalen of een abonnement te nemen op een aantal webcasten.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch webcasting- en klassikale traininge vergeleken",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Klaslokaal",
	ModernTrainingApproach_WebcastingP1: "Meer dan 200 cursisten kunnen tegelijk deelnemen van overal ter wereld",
	ModernTrainingApproach_WebcastingP2: "Lagere complexiteit en relevant lagere kosten doordat er geen reistijd meer nodig is.",
	ModernTrainingApproach_WebcastingP3: "Ideaal voor specifieke en beknopte individuele onderwerpen",
	ModernTrainingApproach_WebcastingP4: "Werkplaatsen kunnen een webcast afspelen voor de medewerkers altijd en overal",
	ModernTrainingApproach_WebcastingP5: "50% to 70% minder tijd nodig in vergelijking met een klassikale training",
	ModernTrainingApproach_ClassroomTrainingP1: "Tot wel 16 deelnemers ontmoeten elkaar tegelijk",
	ModernTrainingApproach_ClassroomTrainingP2: "Reiskosten en over het algemeen hogere kosten per persoon",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideaal als het gaat om optimaliseren van de samenwerking of direct leren op een wagen",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktische oefeningen en interactie met de trainer",
	ModernTrainingApproach_ClassroomTrainingP5: "De trainer kan de over te brengen kennis beter aanpassen aan de werkelijke behoeften van de cursisten",
	ModernTrainingApproach_OnlineTrainingOfferings: "Online trainingsaanbod (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Vandaag de dag is zelfstandig leren via de computer een intergraal onderdeel van kennisoverdracht. De vrijheid om het tijdstip en de locatie te bepalen, onbeperkt herhalen van de training en interactieve leer ontent maken deze methode onmisbaar voor trainen.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Ieder Web-Based training (WBT) element is zorgvuldig gestructureerd. Deze trainingen bieden niet aleen een brede basiskennis aan, maar kunnen ook dienen als een effectieve voorbereiding op trainingsprogramma's in Bosch Training Centra.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "De virtuele diagnosesimulatie biedt de gebruiker rollenspelmogelijkheden voor dagelijkse werkplaatssituaties, zoals de manier waarop een probleemoplossingsprocedure wordt opgezet met Bosch diagnose apparatuur in een virtuele werkplaats.",
	ModernTrainingApproach_Advantages: "Voordelen",
	ModernTrainingApproach_AdvantagesP1: "Een snelle en efficiënte methode van kennisoverdracht",
	ModernTrainingApproach_AdvantagesP2: "Gevarieerd leren",
	ModernTrainingApproach_AdvantagesP3: "Kan zo vaak worden gebruikt als gewenst",
	ModernTrainingApproach_AdvantagesP4: "Minimale tijd en kosten",
	ModernTrainingApproach_AdvantagesP5: "Individuele snelheid in het leerproces",
	ModernTrainingApproach_AdvantagesP6: "Flexibele leertijden",
	ModernTrainingApproach_AdvantagesP7: "De kracht van herhaling",
	ModernTrainingApproach_AdvantagesP8: "Geen reiskosten",
	ModernTrainingApproach_AdvantagesP9: "Geen capaciteit- of plan conflicten",
	Dashboard_CoursesViewAndManageYourCourses: "Trainingen | beheer uw trainingen of zie ze in",
	Dashboard_MyCourses: "Mijn trainingen",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "Trainingsprogramma | Bekijk uw trainingsprogramma",
	Dashboard_MyTrainingProgram: "Mijn trainingsprogramma",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "E-learning | Kijk in en beheer uw E-learning portfolio",
	Dashboard_MyELearning: "Mijn E-learning",
	Dashboard_AccountManageYourAccount: "Account | beheer uw account",
	Dashboard_MyAccount: "Mijn account",
	Dashboard_MyDashboard: "Mijn dashboard",
	Dashboard_ManageYourAccount: "Beheer uw account",
	Dashboard_Workshops: "Werkplaatsen",
	Dashboard_AdministrationManageYourWorkshop: "Administratie | beheer uw werkplaats",
	Dashboard_AdministrationManageYourTeams: "Administratie | beheer uw team",
	Dashboard_Administration: "Administratie",
	Dashboard_ViewYourCourses: "Bekijk uw trainingen",
	Dashboard_ViewYourTrainingPrograms: "Bekijk uw trainingsprogramma",
	Dashboard_TrainingPrograms: "Trainingsprogramma",
	Dashboard_ViewAndEditYourDetails: "Bekijk en bewerk uw details",
	Dashboard_ViewYourELearningCourses: "Bekijk uw E-learning trainingen",
	Dashboard_Pending: "Lopend",
	Dashboard_RequiresAdministratorSignOff: "(vereist afmelding door beheerder)",
	Dashboard_HistoricalRecords: "Trainingshistoriek",
	Dashboard_Registered: "Geregistreerde trainingen",
	Dashboard_WaitingList: "Wachtlijst",
	Dashboard_RecommendedForYou: "Aanbevolen voor u",
	Dashboard_AccountDetails: "Account details",
	Dashboard_ChangeProfileImage: "Verander profielfoto",
	Dashboard_Title: "Titel",
	Dashboard_Role: "Functie",
	Dashboard_FirstName: "Voornaam",
	Dashboard_LastName: "Achternaam",
	Dashboard_Telephone: "Telefoon",
	Dashboard_Mobile: "Mobiel",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Taal",
	Dashboard_Save: "Opslaan",
	Dashboard_Student: "Cursist",
	Dashboard_WorkshopTrainingAdministrator: "Beheerder trainingen werkplaats",
	Dashboard_WholesalerTrainingAdministrator: "Beheerder trainingen groothandel",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Bedrijfsadministratie",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Beheer uw werkplaatsen",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Beheer uw medewerkers",
	Dashboard_CompanyAdministration_Workshop: "Werkplaats",
	Dashboard_CompanyAdministration_Address: "Adres",
	Dashboard_CompanyAdministration_Zip: "Postcode",
	Dashboard_CompanyAdministration_City: "Plaats",
	Dashboard_CompanyAdministration_Manage: "Beheer",
	Dashboard_AdministrationSection_AdministrationTitle: "Administratie",
	Dashboard_AdministrationSection_ManageTheTeam: "Beheer het team",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "Administratie | beheer uw medewerkers",
	Dashboard_AdministrationSection_Employees: "Medewerkers",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "Administratie | beheer uw groothandelaren",
	Dashboard_AdministrationSection_Wholesalers: "Groothandelaren",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "Administratie | beheer uw transacties",
	Dashboard_AdministrationSection_Transactions: "Transacties",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Werknemer administratie",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Beheer uw medewerkers …",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registreer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Trainingsinfo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Bewerk",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Medewerker",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Toevoegen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Maken",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Geregistreerd",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Lopend",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(vereist afmelding door beheerder)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historische gegevens",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Wachtlijst",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Actuele werknemerslijst",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Werknemer beheren",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Naam",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Account medewerker",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Bekijk en bewerk medewerker details …",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Instellingen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Sta persoonlijk BATS account toe",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Werknemer werkplaats administratie vervanging upgrade",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Sta werknemer toe om in te schrijven voor trainingen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Stuur uitnodiging per e-mail",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Account deactiveren",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Opslaan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Verzenden",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Bewerken",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Groothandel beheren",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Beheer uw groothandelaren",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Goedgekeurde groothandelslijst",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Naam",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Verwijderen",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Maak",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Toevoegen",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Account maken voor nieuwe medewerker",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Gegevens aanmaken voor nieuwe medewerkers...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Nieuw erkende groothandelbeheerder toevoegen",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Maak details voor nieuwe groothandelaren …",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Kies goedgekeurde groothandel uit het drop-down menu",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transacties",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Bekijk en beheer uw transacties",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Terug",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Factuurnummer",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Omschrijving",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Betalingswijze",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Bedrag",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Downloaden",
	TrainingCentres_TrainingCentres: "Trainingscentra",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Onze trainingscentra zijn wereldwijd vindbaar",
	TrainingCentres_CountryCategory: "Categorie land",
	TrainingCentres_CityCategory: "Categorie plaats",
	TrainingCentres_Country: "Land",
	TrainingCentres_City: "Plaats",
	TrainingCatalogue_TrainingCatalogue: "Trainingscatalogus",
	TrainingCatalogue_LearningAtTheHighestLevel: "Leren op het hoogste niveau",
	TrainingCatalogue_StartDate: "Start datum",
	TrainingCatalogue_EndDate: "Eind datum",
	TrainingCatalogue_TrainingName: "Naam training",
	TrainingCatalogue_Language: "Taal",
	TrainingCatalogue_Days: "Dagen",
	TrainingCatalogue_Seats: "Beschikbare plaatsen",
	TrainingCatalogue_Price: "Prijs",
	TrainingCatalogue_Location: "Locatie",
	TrainingCatalogue_PartNumber: "Onderdeelnummer",
	TrainingCatalogue_TrainingCourse: "Training",
	TrainingCatalogue_Category: "Categorie",
	TrainingCatalogue_TrainingCategory: "Trainingscategorie",
	TrainingCatalogue_CourseName: "Titel van de training",
	TrainingCatalogue_TotalDays: "Totaal aantal dagen",
	TrainingCatalogue_Venue: "Locatie",
	TrainingCatalogue_Details: "Details",
	TrainingCatalogue_Prerequisites: "Vereisten",
	TrainingCatalogue_CourseType: "Type training",
	TrainingCatalogue_LearningMethod: "Leermethode",
	TrainingCatalogue_AddToWaitingList: "Toevoegen aan wachtlijst",
	ShoppingCart_ShoppingCart: "Winkelwagen",
	ShoppingCart_ReviewAndManage: "Bekijk en beheer uw inschrijvingen",
	ShoppingCart_YouHaveAddedItem: "U heeft de volgende items toegevoegd aan uw winkelwagen",
	ShoppingCart_Item: "Item",
	ShoppingCart_Quantity: "Hoeveelheid",
	ShoppingCart_PricePerItem: "Prijs per item",
	ShoppingCart_TotalPrice: "Totaalprijs",
	ShoppingCart_VatIsIncluded: "Excl. btw",
	ShoppingCart_Redeem: "Als u geen tegoed meer heeft, klik dan hier om er bij te kopen",
	ShoppingCart_Purchase: "Als u geen tegoed heeft, klik dan hier om te kopen",
	ShoppingCart_Checkout: "Afrekenen",
	ShoppingCart_ContinueShopping: "Doorgaan met winkelen",
	ShoppingCart_TotalPriceInclVat: "Totale prijs incl. BTW",
	ShoppingCart_Apply: "Van toepassing",
	ShoppingCart_ShoppingCartEmpty: "Uw winkelwagen is momenteel leeg",
	ShoppingCart_VatIncluded: "BTW (inclusief)",
	ShoppingCart_ManageYourShopping: "Beheer uw inkopen",
	ShoppingCart_Participant: "Deelnemer",
	ShoppingCart_Complete: "Voltooid",
	ShoppingCart_NetPrice: "Netto prijs",
	ShoppingCart_Workshop: "Werkplaats",
	CheckOut_PreviewYourOrder: "Bekijk een voorbeeld van uw bestelling",
	CheckOut_YourShoppingCart: "Kaart",
	CheckOut_Preview: "Bekijk een voorbeeld",
	CheckOut_Payment: "Betalingswijze",
	CheckOut_Summary: "Samenvatting",
	CheckOut_Back: "Terug",
	CheckOut_PleaseReadAndAgreeOurTerms: "Lees en ga akkoord met onze algemene voorwaarden",
	CheckOut_IHaveReadTheTermsAndCondition: "Ik heb de [algemene voorwaarden] gelezen, begrepen en ga hiermee akkoord",
	CheckOut_YesIAgree: "Ja - ik ga akkoord met de algemene voorwaarden",
	CheckOut_Continue: "Doorgaan",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Gefeliciteerd! We hebben uw bestelling goed ontvangen",
	CheckOut_OrderConfirmation: "U ontvangt de bevestiging van de bestelling als uw aanvraag volledig is goedgekeurd",
	CheckOut_WeWillContactYouDirectly: "We nemen direct contact met u op als we vragen hebben over uw verzoek",
	CheckOut_AddToCart: "Toevoegen aan winkelwagen",
	CheckOut_CreditCart: "Creditcard",
	CheckOut_DirectDebit: "Automatische incasso",
	CheckOut_TrainingCredits: "Trainingstegoed",
	CheckOut_PreferredWholesaler: "Voorkeursgroothandel",
	EventCalendar_EventCalendar: "Trainingskalender",
	EventCalendar_TrainingEventsForAllYourNeeds: "Trainingen voor al uw behoeftes",
	TrainingCourse_TrainingCourse: "Trainingscataloog",
	TrainingCourse_LearningAtTheHighestLevel: "Leren op het hoogste niveau",
	TrainingProgram_Title: "Trainingsbrochure",
	TrainingProgram_SubTitle: "Leren op het hoogste niveau",
	AccountDeactivationModal_Title: "Weet u zeker dat u uw account wilt deactiveren?",
	AccountDeactivationModal_Line1: "Het deactiveren van uw account is permanent en zal als gevolg hebben dat alle content inclusief service aanvragen, transacties, saldi van teamleden en uw profiel instellingen worden verwijderd.",
	AccountDeactivationModal_Line2Part1: "Als het binnen een actieve abonnementsperiode valt, raadpleeg dan ons annuleringsbeleid in onze",
	AccountDeactivationModal_Line2Part2: "Algemen voorwaarden",
	AccountDeactivationModal_Line2Part3: "Voordat u uw verzoek indient",
	AccountDeactivationModal_Line3: "Weet u zeker dat u een verzoek tot het deactiveren van uw account wilt invoeren?",
	AccountDeactivationModal_Cancel: "Annuleren",
	AccountDeactivationModal_Send: "Verzenden",
	CurrentUserAccountDeactivationModal_Title: "Weet u zeker dat u uw account wilt deactiveren?",
	CurrentUserAccountDeactivationModal_Line1: "Het deactiveren van uw account is permanent en zal als gevolg hebben dat alle content inclusief service aanvragen, transacties, saldi van teamleden en uw profiel instellingen worden verwijderd.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Als het binnen een actieve abonnementsperiode valt, raadpleeg dan ons annuleringsbeleid in onze",
	CurrentUserAccountDeactivationModal_Line3: "Weet u zeker dat u een verzoek tot het deactiveren van uw account wilt invoeren?",
	CurrentUserAccountDeactivationModal_Yes: "Ja",
	CurrentUserAccountDeactivationModal_No: "Nee",
	CourseWithdrawalModal_Title: "Training annuleren",
	CourseWithdrawalModal_Line1: "Weet u zeker dat u zich wilt uitschrijven voor deze training?",
	CourseWithdrawalModal_Line2Part1: "Uitschrijvingskosten kunnen toegepast worden. Deze worden volgens de annulatie voorwaarden doorbelast. Deze vindt u terug in de",
	CourseWithdrawalModal_Line2Part2Link: "Algemene voorwaarden",
	CourseWithdrawalModal_Line2Part3: "van onze dienst",
	CourseWithdrawalModal_Yes: "Ja, annuleren",
	CourseWithdrawalModal_No: "Nee, stopzetten",
	Settings_Settings: "Instellingen",
	Settings_ManageYourSettings: "Beheer uw instellingen",
	Settings_Profile: "Profiel",
	Settings_MyBoschGlobalIdLoginInformation: "Mijn Bosch Global ID inloggegevens",
	Settings_Edit: "Wijzigen",
	Settings_CompanyDetails: "Bedrijfsdetails",
	Settings_RequestAccountDeletion: "Verzoek om account te verwijderen",
	Settings_AccountDeletionText: "Als u uw account verwijderd, dan zal alle data (inclusief uw adres en betalingsgegevens) worden verwijderd en niet langer beschikbaar zijn.",
	Settings_DeleteAccountRequestConfirmation: "Bevestiging verzoek om account te verwijderen",
	Settings_DeleteAccountRequestConfirmationText: "Wij hebben uw aanvraag om uw account te verwijderen ontvangen en zullen contact met u opnemen per e-mail of telefoon voor de finale beslissing",
	RecommendedHotels_RecommendedHotels: "Aanbevolen hotels",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Onze trainingscentra zijn wereldwijd vindbaar",
	Error_Common: "Er is iets fout gegaan!",
	Error_404: "De pagina die u wilt bezoeken bestaat niet",
	Error_401: "U heeft geen toegang tot deze pagina",
	Error_500: "De server meldt een fout",
	Dashboard_Courses_Table_Course: "Training",
	Dashboard_Courses_Table_Date: "Datum []",
	Dashboard_Courses_Table_Venue: "Locatie",
	Dashboard_Courses_Table_TrainingType: "Type training",
	Dashboard_Courses_Table_Action: "Actie",
	Dashboard_Courses_Table_TasksToComplete: "U moet de volgende taken nog afronden",
	Dashboard_Courses_Table_Withdraw: "Uitschrijven",
	Dashboard_Courses_Table_Remove: "Verwijderen",
	Dashboard_Courses_Table_View: "Overzicht",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Geen beschikbare gegevens",
	Dashboard_Courses_Table_CompletionDate: "Einddatum",
	Dashboard_Courses_Table_Actions_Assignments: "Toegekend",
	Dashboard_Courses_Table_Actions_Certification: "Certificering",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluaties",
	Dashboard_Courses_Table_Actions_Assessments: "Beoordelingen",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Toevoegen aan agenda",
	Dashboard_Courses_Table_CourseDetails_Task: "Taak",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Deadline",
	ContactUs_Headline: "Neem contact met ons op",
	ContactUs_HowCanWeHelp: "Hoe kunnen wij u helpen?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Tel",
	ContactUs_LetterTab: "Brief",
	ContactUs_Phone_Headline: "Bel ons!",
	ContactUs_Phone_Description: "We kijken uit naar uw telefoontje en willen u graag helpen.",
	ContactUs_Phone_PhoneNumber: "Gebruik het contactformulier om ons een bericht te sturen.",
	ContactUs_Phone_ChargeNotice: "Kosten hangen af van uw serviceprovider en land.",
	ContactUs_Letter_Headline: "Stuur ons een brief!",
	ContactUs_Letter_Description: "Heeft u vragen, verzoeken of suggesties voor ons? Wij kijken uit naar uw feedback.",
	ContactUs_Email_Headline: "Stuur ons een e-mail.",
	ContactUs_Email_Description: "Stuur ons een bericht door het formulier hieronder in te vullen. Een van onze medwerkers neemt graag contact met u op.",
	ContactUs_Email_InputName: "Naam*",
	ContactUs_Email_InputCompany: "Bedrijf",
	ContactUs_Email_InputCountry: "Land*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Bericht",
	ContactUs_Email_DataProtection: "Verklaring gegevensbescherming*",
	ContactUs_Email_DataProtectionConfirm: "Ik heb het privacybeleid gelezen*",
	ContactUs_Email_SecurityCheck: "Veiligheidscontrole",
	ContactUs_Email_SecurityCodeConfirm: "Vul de karakters in die u in de box hierboven ziet.",
	ContactUs_Email_SecurityCodeInvalid: "De captcha is niet juist",
	ContactUs_Email_MandatoryNotice: "Gelieve de verplichte velden in te vullen",
	ContactUs_Email_SuccessMessage: "Uw bericht werd doorgestuurd",
	ContactUs_Email_TakenNoteDataProtection1: "Ik heb de ... gelezen",
	ContactUs_Email_TakenNoteDataProtection2: "Privacybeleid",
	ContactUs_Email_SubmitButton: "Indienen",
	ContactUs_Email_FieldErrorMessage: "Gelieve de verplichte velden in te vullen",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Uw account werd naar de revisie doorgestuurd",
	AccountInReviewModal_Paragraph1: "Bedankt om een persoonlijk account te hebben aangemaakt op Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "Ons trainingsteam is uw account aan het herzien en aan het configureren. U ontvangt een bevestigingsmail van zodra uw account geconfigureerd werd.",
	AccountInReviewModal_Paragraph3: "Neemt u inmiddels de tijd om op onze site te ontdekken met een beperkte toegang",
	AccountInReviewModal_Paragraph4: "Voor al uw vragen of indien uw account binnen de 48 uren (verlofdagen niet inbegrepen) niet behandeld werd, mag u gerust contact opnemen met ons.",
	AccountInReviewModal_ContactUsLink: "Hier",
	AccountInReviewModal_OKButton: "OK, doorgaan!",
	Header_YesButton: "Ja",
	Header_NoButton: "Neen",
	Header_LogOutText: "Wilt u zich uitloggen?",
	Footer_EnquiryText: "Wij kijken uit naar uw verzoek.",
	Footer_Telephone: "Telefoon",
	Footer_TermsAndConditions: "Algmene voorwaarden",
	Footer_HowOurTrainingEvolves: "Ontwikkeling van onze cursussen",
	FooterSearch_SearchFieldPlaceholder: "Zoeken",
	TrainingCatalogue_Action: "Actie",
	TrainingCatalogue_Duration: "Duur",
	TrainingCatalogue_Information: "Informatie",
	Settings_PersonalDetails: "Persoonlijke gegevens",
	Reset_All_Filters: "Reset van alle filters",
	EventTable_Days: "dagen",
	SearchField_AllResults: "Alle resultaten",
	TrainingCentresLayout_ViewDetails: "Bekijk details",
	Dropdown_Placeholder: "Kies...",
	WaitlistPopup_SelectButtonText: "Selecteer",
	WaitlistPopup_StartDate: "Start datum",
	WaitlistPopup_EndDate: "Eind datum",
	WaitlistPopup_Language: "Taal",
	WaitlistPopup_City: "Plaats",
	WaitlistPopup_Workshop: "Werkplaats",
	WaitlistPopup_Student: "Student",
	WaitlistPopup_PreferredLocation: "Voorkeurslocatie",
	WaitlistPopup_SignUpCourseWaitingList: "Inschrijven op wachlijst voor training",
	WaitlistPopup_SignUpWithoutSelectingDate: "Inschrijven op wachtlijst zonder voorkeursdatum",
	WaitlistPopup_GeneralWaitList: "Algemene wachtlijst",
	WatinglistPopup_CourseLabel: "Training",
	Common_ContinueButton: "Doorgaan",
	UserAccountDeactivationModal_ConfirmLabel: "Bevestigen",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "U staat al op de wachtlijst, wilt u uw bestaande inschrijving op de wachtlijst verwijderen en een nieuwe aanmelding maken?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ja, verwijder",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nee, annuleer",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Training annuleren",
	WaitinglistPopup_SignUpButtonText: "Inschrijven",
	MyAccount_FirstNameRequied: "Voornaam is verplicht",
	MyAccount_LastNameRequired: "Achternaam is verplicht",
	MyAccount_RoleRequired: "Functie is verplicht",
	MyAccount_MobileRequired: "Mobiel is verplicht",
	MyAccount_EmailRequired: "E-mail is verplicht",
	MyAccount_LanguageRequired: "Taal is verplicht",
	MyAccount_UpdateSuccessMessage: "Uw account details zijn succesvol geupdated",
	MyAccount_UpdateSuccessModal_ButtonText: "Ja, sluiten",
	Infor_CorporateInformation_Header: "Bedrijfsinformatie",
	Infor_LegalNotice_Header: "Juridische kennisgeving",
	Infor_PrivacyPolicy_Header: "Gegevensbeschermingverklaring (Privacybeleid)",
	CreateEmployeeAccountModification_FormValidation_Role: "Functie is verplicht",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Voornaam is verplicht",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Achternaam is verplicht",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobiel is verplicht",
	CreateEmployeeAccountModification_FormValidation_Email: "E-mail is verplicht",
	CreateEmployeeAccountModification_FormValidation_Language: "Taal is verplicht",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Uitnodiging om deel te nemen aan Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "U heeft succesvol een nieuw teamlid toegevoegd!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Wilt u een uitnodiging via mail delen met uw nieuwe teamlid met instructies hoe een persoonlijk account voor Bosch Automotive Training Solutions gecreeërd moet worden?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nee",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ja, stuur uitnodiging",
	ShoppingCart_Venue_Fee: "Locatiekosten",
	ShoppingCart_Trainning_Name: "Naam training",
	ShoppingCart_Available_Credits: "Beschikbaar tegoed",
	ConfirmItsMeModal_Hi: "Hi",
	ConfirmItsMeModal_Description1: "U heeft met succes de uitnodiging voltooid die door uw bedrijf is gedeeld om uw eigen persoonlijke account op Bosch Automotive Training Solutios te maken",
	ConfirmItsMeModal_Description2: "Bevestig of update uw account voordat u verder gaat.",
	ConfirmItsMeModal_Description3: "Na bevestigen dient u opnieuw in te loggen om de aanmaak van het account te voltooien.",
	ConfirmItsMeModal_CompanyDetails: "Bedrijfsdetails",
	ConfirmItsMeModal_YourDetails: "Uw details",
	ConfirmItsMeModal_YourDetails_Title: "Titel",
	ConfirmItsMeModal_YourDetails_Function: "Functie",
	ConfirmItsMeModal_YourDetails_Name: "Naam",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobiel",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Taal",
	ConfirmItsMeModal_ConfirmButton: "Bevestigen",
	SelectWholesalerModal_WholesalerGroupLabel: "Groothandel",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Kies",
	ApplyButton: "Toepassen",
	SelectWholesalerModal_Title: "Kies uw groothandel",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Wilt u uw registratie verwijderen?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Uw registratie is succesvol verwijderd!",
	WaitlistPopup_AddToWaitListSuccess: "Uw registratie is succesvol bewerkt!",
	ErrorCommon_Header: "Foutmelding",
	CommonButton_Close: "Sluiten",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive E-learning",
	MyELearning_VideoPlayerClosingMessage: "Sluit alstublieft dit venster om door te gaan",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Vernieuwen",
	DeactivateWholesalerModal_ConfirmMessage: "Weet u zeker dat u deze groothandel wilt verwijderen?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "De uitnodiging is succesvol verzonden",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Opnieuw uitnodigen",
	ContactUs_Mobile_BE: "+32 (0)2 525 5132",
	ContactUs_Mobile_NL: "+32 (0)2 525 5132",
	ContactUs_Address_BE: "Robert Bosch N.V.,Henri Genessestraat 1,Brussel 1070,België",
	ContactUs_Address_NL: "Robert Bosch B.V.,Hanzeweg 31,3771 NG Barneveld,Netherland",
	ConfirmItsMeModal_CompanyDetails_Name: "Bedrijfsnaam",
	ConfirmItsMeModal_CompanyDetails_Address: "Adres",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Postcode bedrijf",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefoon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "De training is succesvol afgemeld",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Het aanmaken van het account is niet gelukt. Dit account bestaat al.",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Het updaten van het accunt is niet gelukt. Dit acount bestaat al.",
	Footer_ExploreTrainingStories: "Ontdek al onze verhalen",
	Breadcrumb_Home: "Startpagina",
	Breadcrumb_Contact: "Contact",
	Breadcrumb_Stories: "Verhalen",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Een dag in het leven van de trainer",
	Breadcrumb_Stories_BoschTrainingBenefits: "Voordelen Bosch trainingen",
	Breadcrumb_Stories_TrainingApproach: "Een moderne trainingsaanpak",
	Breadcrumb_Settings: "Instellingen",
	Breadcrumb_ShoppingCart: "Winkelwagen",
	Breadcrumb_Checkout: "Uitchecken",
	Breadcrumb_OrderSummary: "Besteloverzicht",
	Breadcrumb_Exception: "Uitzondering",
	Breadcrumb_Dashboard: "Dashboard",
	Breadcrumb_MyCourses: "Mijn trainingen",
	Breadcrumb_MyELearning: "Mijn E-learning",
	Breadcrumb_CompanyAdministration: "Bedrijfsadministratie",
	Breadcrumb_EmployeeManagement: "Beheer van werknemers",
	Breadcrumb_EmployeeAccount: "Acount medewerker",
	Breadcrumb_EmployeeCreation: "Medewerker toevoegen",
	Breadcrumb_Administration: "Beheer",
	Breadcrumb_EmployeeAdministration: "Beheer medewerker",
	Breadcrumb_WholesalerManagement: "Groothandel beheren",
	Breadcrumb_NewWholesalerAccountCreation: "Nieuw account groothandel",
	Breadcrumb_Transactions: "Transacties",
	Breadcrumb_MyAccount: "Mijn account",
	Breadcrumb_TrainingCentres: "Trainingcenter",
	Breadcrumb_EventCalendar: "Trainingsplanning",
	Breadcrumb_TrainingCourseCatalogue: "Trainingscataloog",
	Breadcrumb_RecommendedHotels: "Aanbevolen hotels",
	Breadcrumb_CorporateInformation: "Bedrijfsinformatie",
	Breadcrumb_LegalNotice: "Juridische kennisgeving",
	Breadcrumb_DataProtectionNotice: "Kennisgeving databescherming",
	Breadcrumb_PrivacyPolicy: "Privacybeleid",
	Breadcrumb_TermCondition: "Algemene voorwaarden",
	Breadcrumb_Terms: "Voorwaarden",
	Breadcrumb_PrivacyStatement: "Privacyverklaring",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Aanbieder",
	Breadcrumb_RegisterAccount: "Account registreren",
	Stories_ExploreMore: "Ontdek meer",
	CheckOutLayout_ShoppingCartHeader: "Winkelwagen",
	CheckOutLayout_AddedItemCartMessage: "U heeft de volgende items toegevoegd aan uw winkelwagen",
	CheckOutLayout_CheckYourCartMessage: "Controleer uw winkelwagen",
	ModernTrainingApproach_DateText: "28.02.2022",
	ADayInLifeofATrainer_DateText: "28.02.2022",
	BoschTrainingBenefits_DateText: "28.02.2022",
	BoschServiceTraining_DateText: "28.02.2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Medewerkers zoeken",
	CheckOutLayout_OrderWasNotPlaced_Message: "Sorry uw order is niet geplaatst",
	CheckOutLayout_RefusalReason: "Reden van afwijzing",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Betaling is niet gelukt. Selecteer een andere betaalwijze",
	CheckOutLayout_SubmittingApiFailed_Message: "Bevestiging van API is niet gelukt",
	CartItem_ChooseWorkshop_Message: "Kies een werkplaats om een medewerker te kunnen kiezen",
	CartItem_ChooseParticipant_Message: "Kies een medewerker om door te gaan",
	CompanyAdministrationTable_SearchBox_Placeholder: "Zoek voor werkplaatsen",
	CartItem_RemoveCartItem_ConfirmText: "Wilt u dit item uit uw winkelwagen verwijderen?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Functie",
	AddToCartSuccessModal_Message: "Uw winkelmand werd bijgewerkt",
	AddToCartSuccessModal_ContinueButtonText: "Doorgaan met winkelen",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mijn winkelwagen",
	AddVoucher_Header: "Voucher toevoegen",
	AddVoucher_InputPlaceholder: "Voer uw code in en bevestig",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Helaas hebben we op dit moment geen geplande sessies voor deze training. Schrijf u in op de wachtlijst, en wij informeren u zodra er een sessie beschikbaar komt.",
	ADYEN_ERR_2_: "The betaling is afgewezen",
	ADYEN_ERR_3_: "Redenen voor afwijzingen",
	ADYEN_ERR_4_: "De transactie is afgewezen door een storing bij de overnemende partij.",
	ADYEN_ERR_5_: "De gebruikte kaart voor deze transactie is geblokkeerd en daarom onbruikbaar.",
	ADYEN_ERR_6_: "De gebruikte kaart voor deze transactie is vervallen en daarom onbruikbaar.",
	ADYEN_ERR_7_: "Een bedragsdiscrepantie is voorgekomen tijdens de transactie.",
	ADYEN_ERR_8_: "Het kaartnummer is niet correct of verlopen.",
	ADYEN_ERR_9_: "Het is niet mogelijk om contact op te nemen met de bank van de gebruiker om de transactie goed te keuren.",
	ADYEN_ERR_10_: "De bank van de gebruiker staat dit type transactie niet toe of ondersteund deze niet.",
	ADYEN_ERR_11_: "3D beveiligingsverificatie is niet uitgevoerd of niet succesvol uitgevoerd.",
	ADYEN_ERR_12_: "Uw heeft niet genoeg saldo op uw kaart voor deze transactie door te voeren",
	ADYEN_ERR_14_: "Mogelijke fraude.",
	ADYEN_ERR_15_: "De transactie werd geannuleerd.",
	ADYEN_ERR_16_: "De koper heeft de transactie afgezegd voordat het voltooid was.",
	ADYEN_ERR_17_: "De pincode is onjuist of verlopen.",
	ADYEN_ERR_18_: "De gebruiker heeft meer dan drie keer achter elkaar een verkeerde PIN code ingevoerd.",
	ADYEN_ERR_19_: "Het is niet mogelijk om de pincode goed te keuren.",
	ADYEN_ERR_20_: "Mogelijke fraude.",
	ADYEN_ERR_21_: "De transactie is niet goed ingediend voor verwerking.",
	ADYEN_ERR_22_: "De risicocontrole markeerde de transactie als fraudegevoelig (risico score >= 100); hierom is de bewerking geannuleerd.",
	ADYEN_ERR_23_: "De volgende codes zijn reden voor de weigering: transactie is niet toegestaan door uitgever/kaarthouder.",
	ADYEN_ERR_24_: "De CVC code (card security code) is niet geldig.",
	ADYEN_ERR_25_: "De volgende weigeringscodes zijn toegewezen aan deze weigeringsreden:  Ongeldige kaart in dit land",
	ADYEN_ERR_26_: "R1: Herroeping van autorisatieopdracht / \"R3: Herroeping van alle autorisatieopdrachten / \"R0: Stop betalingsopdracht",
	ADYEN_ERR_27_: "Deze responsmap geeft alle responsen weer die niet in kaart gebracht konden worden. Dit maakt het makkelijker om generieke weigeringen (bijvoorbeeld Mastercard \"05: Niet honoreren response) te onderscheiden van specifieke responsen.",
	ADYEN_ERR_28_: "Het toegestane opnamebedrag is overschreden.",
	ADYEN_ERR_29_: "Het aantal opnames dat is toegestaan voor deze kaart is overschreden.",
	ADYEN_ERR_31_: "De uitgever rapporteert de transactie als mogelijke fraude.",
	ADYEN_ERR_32_: "De ingevoerde adresgegevens zijn niet correct",
	ADYEN_ERR_33_: "De bank van de gebruiker vereist een online pincode.",
	ADYEN_ERR_34_: "De bank van de gebruiker vereist een bankrekening om de bestelling te voltooien.",
	ADYEN_ERR_35_: "De bank van de gebruiker vereist een spaarrekening om de bestelling te voltooien.",
	ADYEN_ERR_36_: "De bank van de gebruiker vereist dat de gebruiker een mobiele pincode invoert.",
	ADYEN_ERR_37_: "De gebruiker heeft de betaling afgebroken nadat hij een contactloze betaling heeft geprobeerd en werd gevraagd een andere kaartinvoermethode te proberen (pincode of swipe).",
	ADYEN_ERR_38_: "De uitgever heeft het verzoek om vrijstelling van authenticatie afgewezen en vereist authenticatie voor de transactie. Probeer het opnieuw met 3D beveiliging.",
	ADYEN_ERR_39_: "De uitgever of de regeling kon het resultaat niet communiceren via Rreq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "De voornaam mag geen speciale karakters bevatten (bv : @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "De voornaam mag maximum 75 karakters lang zijn",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "De achternaam mag geen speciale karakters bevatten (bv : @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "De achternaam mag maximum 75 karakters lang zijn",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Het mobiel nummer mag geen speciale karakters bevatten (bv : @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Het mobiel nummer mag maximum 45 karakters lang zijn",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Het telefoonnummer mag geen speciale karakters bevatten (bv : @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Het telefoonnummer mag maximum 45karakters lang zijn",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Het email adres is niet correct",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Het email adres mag maximum 100 karakters lang zijn",
	ConfirmGeolocationModal_ConfirmButton: "Doorgaan",
	ConfirmGeolocationModal_CancelButton: "Wijziging",
	ConfirmGeolocationModal_CurrentCountrySelection: "Uw huidige landselectie is:",
	ConfirmGeolocationModal_AskChangeCountry: "Wenst u op deze site verder te gaan of uw landselectie wijzigen?",
	Addtocart_Modal_AddToCartFailTitle: "Deelnemer toevoegen mislukt",
	Addtocart_Modal_AlreadyInCart: "Deelnemer al geregistreerd voor deze training",
	TrainingCatalogue_DateRange: "Periode",
	TrainingCatalogue_DateRange_placeholder: "Filter periode selecteren",
	Dashboard_MyPendingCourses: "Aangevraagde trainingen",
	Dashboard_MyWaitingList: "Mijn wachtlijst",
	Dashboard_LatestNews: "Laatste nieuws",
	SettingsMainPage_EditAccountButton: "Account bewerken",
	SettingsMainPage_EditBoschId: "Bosch ID bewerken",
	EventInfoView_EventDetailTab: "Detail evenement",
	EventInfoView_AssignmentsTab: "Opdrachten",
	EventInfoView_EvaluationTab: "Evaluatie",
	EventInfoView_AttachmentTab: "Bijlagen",
	EventInfoView_CourseDescriptionTab: "Beschrijving",
	EventInfoView_DescriptionTab_General: "Algemeen",
	EventInfoView_DescriptionTab_PartLabel: "Deel",
	AssignmentTab_Description: "U vindt hier alle informatie die u nodig heeft om de cursus te voltooien",
	AssignmentTab_Columns_Download: "Downloaden",
	AssignmentTab_Columns_Upload: "Uploaden",
	AssignmentTab_Columns_TaskComplete: "Taak voltooid",
	EventDetailTab_Location_Message_Video: "Dit is een online sessie die toegankelijk is met de link  \"Video opstarten\"",
	EventDetailTab_Location_Message_WebBased: "Dit is een online sessie die toegankelijk is met de link  \"WBT opstarten\"",
	EventDetailTab_Location_Message_WebCast: "Dit is een online sessie die toegankelijk is met de link \"Nu deelnemen\"",
	EventDetailTab_Link_Video: "Video opstarten",
	EventDetailTab_Link_Video_Remark: "Het opstarten van deze video zal een nieuw venster openen. U wordt nadien terug naar deze pagina gestuurd eens het venster gesloten werd",
	EventDetailTab_Link_WebBased: "WBT opstarten",
	EventDetailTab_Link_WebBased_Remark: "Het opstarten van deze WBT zal een nieuw venster openen. U wordt nadien terug naar deze pagina gestuurd eens het venster gesloten werd",
	EventDetailTab_Link_WebCast: "Nu deelnemen",
	EventDetailTab_Link_WebCast_Remark: "De link \"Nu deelnemen\" zal 30 minuten voor de start van het evenement toegankelijk zijn",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Duurtijd",
	EventDetailTab_Title_Trainers: "Trainer(s)",
	EventDetailTab_Title_Language: "taal",
	EventDetailTab_Title_Score: "Score (%)",
	EventDetailTab_Title_Calendar: "Toevoegen aan agenda",
	EventDetailTab_Title_Certificate: "Attest uploaden",
	EventDetailTab_Title_Messages: "Berichten",
	EventDetailTab_Title_StartDate: "Startdatum",
	EventDetailTab_Title_AvailableFrom: "Beschikbaar vanaf",
	EventDetailTab_Title_Time: "tijdstip",
	EventDetailTab_Title_EndDate: "Einddatum",
	EventDetailTab_Title_ExpiresOn: "Vervalt op",
	EventDetailTab_Title_Room: "Lokaal",
	EventDetailTab_Title_Method: "Methode",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Lokatie",
	AttachmentTab_EmptyScreenDescription: "Er zijn geen bijlagen voor dit event",
	AttachmentTab_Columns_Download: "Downloaden",
	AttachmentTab_Description: "U vindt hier informatie die u zal helpen met deze training",
	EvaluationTab_Annotation: "Wij waarderen uw feedback en willen graag uw mening kennen over onze manier van doen. Hiervoor verzoeken wij u de evaluatie in te vullen zodat wij ons steeds kunnen verbeteren. Alvast bedankt!",
	EvaluationTab_Notice: "De scores zijn",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Gelieve op alle verplichte vragen te antwoorden voor u uw evaluatie doorstuurd",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Uw feedback werd succesvol aangelegd",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Het verzenden van uw evaluatie is mislukt",
	TrainingCatalogueLayout_ExportButtonText: "Export",
	EvaluationTab_FeedbackHasBeenGiven_Message: "De evaluatie werd voltooid of is verlopen",
	EventDetailTab_Link_CopiedTooltip: "Kopiëren naar klembord",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scannen om WBT te starten",
	EventDetailTab_QRCode_ExplanationText_Video: "Scannen om video te starten",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scannen om Webcast te starten",
	Footer_TrainingCoure_Headline: "De trainingen worden mogelijk gemaakt door Bosch",
	Footer_TrainingCourse_Content: "Als een wereldberoemd krachtig merk met meer dan een eeuw ervaring in innovatie, heeft Bosch de wereldwijde standaard gezet als aanbieder in automotive systemen, onderdelen en technische oplossingen. Een groot deel van deze kracht komt voort uit nauwe samenwerking met autofabrikanten, voor wie we wereldwijd geavanceerde systemen en componenten ontwerpen, ontwikkelen en produceren. Daarnaast heeft Bosch een sterke geschiedenis opgebouwd in automotive aftermarktet en ondersteuning met een breed aanbod aan onderdelen, diagnostische oplossingen en werkplaats ondersteuning. Leren en ontwikkelen zijn hierbij zeker de sleutels tot succes en met deze filosofie biedt Bosch een breed assortiment aan voor trainingen in alle vlakken van de automotive technologie, voertuigen systemen en diagnose.",
	SearchField_NoRecordsFound: "Geen items gevonden",
	TrainingCatalogue_FreeTextSearch: "Vrije tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Zoeken",
	CheckoutLayout_WholeSalerGroup: "Kies grossier",
	CreditInsufficientModal_Message: "U heeft onvoldoende tegoed om deze betaalmethode te gebruiken. Probeer het opnieuw met een andere betaalmethode.",
	ShoppingCart_Required_Credits: "Totale prijs",
	MaintainingInfo_Message1: "De website is in onderhoudsmodus.",
	MaintainingInfo_Message2: "We zullen snel terugkomen.",
	CountryValidationModal_Message: "U kon niet inloggen op [CountryA] omdat uw account is aangemaakt in [CountryB].",
	CountryValidationModal_ButtonText: "Ga verder naar [Country]",
	EventTable_LoginRequired: "Log in om te registreren",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Starttijd",
	TrainingPartTableLayout_EndTime: "Eindtijd",
	TrainingPartTableLayout_Duration: "Duur (uur)",
	TrainingPartTableLayout_TrainingMethod: "Trainingsmethode",
	MyCoursesTableContents_ActiveHeader: "Actieve cursussen",
	MyCoursesTableContents_ActiveTabHeader: "Actief",
	MyCoursesTableContents_ScheduledHeader: "Geplande cursussen",
	MyCoursesTableContents_ScheduledTabHeader: "Gepland",
	MyCoursesTableContents_OnlineHeader: "Online cursussen",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Geannuleerde cursussen",
	MyCoursesTableContents_CancelledTabHeader: "Geannuleerd",
	OnlineCoursesTable_Title_Progress: "Voortgang",
	ScheduledCoursesTable_Title_Date: "Datum [dagen]",
	CoursesTable_Title_Completed: "Voltooid",
	Footer_TrainingProgram: "Training programmas",
	Breadcrumb_TrainingProgram: "Training programmas",
	TrainingProgram_Title_Table: "Titel",
	TrainingProgram_TrainingProgram: "Training programmas",
	TrainingProgram_TargetGroup: "Doelgroep",
	TrainingProgram_MaxLength: "Maximaal Lengte",
	TrainingProgram_Information: "Informatie",
	TrainingProgram_Detail: "Details",
	TrainingProgram_Modules: "Modules",
	TrainingProgram_Course: "Cursussen",
	TrainingProgram_PartNumber: "Onderdeel nummer",
	TrainingProgram_Max_Length: "Maximaal Lengte",
	TrainingProgram_Duration: "Looptijd",
	TrainingProgram_Exam: "Examen",
	TrainingProgram_Assign_Popup: "Aanmelden voor trainingsprogramma",
	TrainingProgram_Student: "Deelnemer",
	TrainingProgram_Assign: "Toewijzen",
	TrainingProgram_Popup_Remove_Title: "Opleiding annuleren?",
	TrainingProgram_Description: "Beschrijving",
	TrainingProgram_Days: "Dagen",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Actie",
	TrainingProgram_Enrolled: "is al ingeschreven voor dit trainingsprogramma",
	TrainingProgram_SelfAssign_Enrolled: "Je bent al toegewezen aan dit trainingsprogramma",
	TrainingProgram_Warning: "Schrijf een andere cursist in of kies een ander opleidingsprogramma.",
	TrainingProgram_Workshop: "werkplaats",
	TrainingProgram_Durations: "Jaren",
	TrainingProgram_Duration_DaysLabel: "Dagen",
	TrainingProgram_HasExam_Yes: "Ja",
	TrainingProgram_HasExam_No: "Nee",
	AssignModal_SuccessModal_Header: "Gefeliciteerd!",
	AssignModal_SuccessModal_Message: "succesvol is ingeschreven voor dit trainingsprogramma.",
	AssignModal_SelfAssign_SuccessModal_Message: "Je bent met succes toegewezen aan het trainingsprogramma.",
	Dashboard_MyTraningProgram: "Mijn trainingsprogramma",
	Dashboard_MyTraningProgram_Title: "Titel",
	Dashboard_MyTraningProgram_Status: "Toestand",
	Dashboard_MyTraningProgram_Table_View: "Bekijken",
	Dashboard_MyTraningProgram_Register: "Inschrijving",
	Dashboard_MyTraningProgram_Records: "Historiek",
	Dashboard_MyTrainingProgram_In_Progress: "Aan de gang",
	Dashboard_MyTrainingProgram_Accredited: "Geaccrediteerd",
	Dashboard_Program_Modal_Withdraw: "Terugtrekken",
	Dashboard_Program_Modal_Grade: "Cijfer",
	Dashboard_MyTrainingProgram_Registered: "Ingeschreven",
	Dashboard_MyTrainingProgram_Completedd: "Voltooid",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ja",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nee",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Houd er rekening mee dat u zich terugtrekt uit het volledige trainingsprogramma en niet alleen uit een individuele training.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Wil je je toch terugtrekken uit het trainingsprogramma?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "In afwachting",
	WithdrawProgramModal_Withdraw_Success_Message: "Je hebt je succesvol teruggetrokken uit het trainingsprogramma!",
	Common_Warning_Title: "Waarschuwing!",
	Common_Error_Title: "Oeps, er is iets misgegaan!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Voeg toe aan winkelwagen",
	AddEventFromCatalogToCartModal_HoursText: "Uren",
	AddElearningToCartSuccessModal_Message: "is toegevoegd aan uw winkelwagen!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "De geselecteerde deelnemer is geregistreerd voor deze cursus, of de cursus is al toegevoegd aan het winkelwagen.",
	EventDetailTab_SubjectForChange_Message: "Onderwerp voor verandering",
	Dashboard_MyTrainingProgram_Status_Failed: "Mislukt",
	Dashboard_MyTrainingProgram_Status_Passed: "Geslaagd",
	Dashboard_MyTrainingProgram_Status_Merit: "Verdienste",
	Dashboard_MyTrainingProgram_Status_Distinction: "Onderscheid",
	Dashboard_Employee_Courses: "Cursussen",
	Dashboard_Employee_Program: "trainingsprogramma",
	Dashboard_Employee_WaitList: "Wachtlijst",
	Dashboard_Employee_Account: "Account",
	Course_Price_Free: "Vrij",
	Dashboard_Employee_Account_Update_Success_Message: "U heeft uw werknemersgegevens succesvol bijgewerkt!",
	Dashboard_Supplier: "Leverancier",
	Event_Detail_Comment: "Er zijn momenteel geen berichten",
	DownLoad_PDF: "PDF downloaden",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Mijn portemonnee",
	Wallet: "Portemonnee",
	Credits_Balance: "Krediet balans",
	MyFullCalendar_Today: "Vandaag",
	Header_Available_Languages: "Beschikbare talen",
	Header_CurrentRegion: "Momenteel bent u in",
	Header_ChangeRegions: "Wilt u van land wisselen?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Login/Registreer",
	Header_RegisterCourse: "Inschrijven voor een training",
	Homepage_Start: "Aan de slag",
	Homepage_Login: "Inloggen",
	MainPage_Title: "De beste oplossing voor de werkplaats",
	MainPage_Content: "Uw werkplaats, uw keuze",
	MainPage_ImageTitle: "Bosch Automotive Training Solutions",
	MainPage_IntroContent: "Maakt het mogelijk voor de moderne werkplaats om professioneel, betrouwbaar, en efficiënt te werken met diagnose procedures, reparaties en services om problemen op te lossen van diverse automerken",
	BoschNews_TrainingNews: "Training nieuws",
	Service_Assist_Workshop: "Verbeter uw ervaring met Workshop Service Assisent",
	Enhance_Title_CDM3_v2: "Wat kan ik doen met de app?",
	Enhance_Body_CDM3_Item_1: "Boek trainingen en onderhoud uw leertraject",
	Enhance_Body_CDM3_Item_2: "Download training materiaal en deelname attesten",
	Enhance_Body_CDM3_Item_3: "Start Elearning trainingen",
	Enhance_Body_CDM3_Item_4: "Toegang tot diverse automotive services met de mobiele applicatie",
	Enhance_Body_CDM3_Item_5: "Live video overdracht met Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Gratis tool voor automatische scan van wagens",
	Training_Stories: "Training verhalen",
	SettingsMyAccount_JoinCompanyButton: "Word lid van het bedrijf",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Word lid van het bedrijf",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Voer uw activatiecode hieronder in",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Let op! U dient contact op te nemen met de bedrijfsadministratie voor een activatiecode",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Uw activiatiecode is succesvol verwerkt en uw account is nu gelinkt aan uw bedrijf",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Klik op doorgaan om naar de website te gaan",
	ContinueButton: "Doorgaan",
	WelcomeBatsModal_Title: "Uw account wordt momenteel behandeld",
	WelcomeBatsModal_ActivationLink: "Klik hier als u een activatiecode heeft om lid te worden van het bedrijf",
	WelcomeBatsModal_ActivationLinkNotes: "Let op! U dient contact op te nemen met de bedrijfsadministratie om een activatiecode te krijgen",
	AccountInReviewModal_Description_Paragraph1: "Bedankt voor het aanmaken van een persoonlijk account op Bosch Automotive Training Solutions",
	AccountInReviewModal_Description_Paragraph2: "Ons team is uw account aan het contorleren en keuren. U krijgt automatisch een e-mail als uw account is goedgekeurd",
	AccountInReviewModal_CloseButton: "Oké, laat mij naar binnen",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Helaas konden wij uw e-mail en activatiecode niet goedkeuren",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Probeer het opnieuw of neem contact op met uw bedrijfsadministratie",
	TryAgain_Button: "Probeer opnieuw",
	Error_Title: "Oeps, er ging iets mis",
	SettingsMyProfileActivationSuccessModal_Title: "Succes!",
	AccountInReviewModal_Description_Paragraph3: "Voel u ondertussen vrij om onze website te bekijken",
	AccountInReviewModal_Description_Paragraph4: "Heeft u vragen of is uw account binnen 48 uur niet verwerkt (met uitzondering van weekend- en feestdagen) neem dan contact met ons op",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Wat informatie om u verder te helpen...",
	Support_FAQ_Headline: "Veel gestelde vragen",
	Support_FAQs_section1_title: "Algemeen",
	Support_FAQs_section1_question1: "Wat is Boch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions is een platform die een uitgebreid overzicht geeft van technische trainingen en het mogelijk maakt om trainingen te boeken en te beheren",
	Support_FAQs_section1_question2: "Kan ik mijn bestaande Bosch account (singleKey ID) gebruiken om in te loggen bij Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Ja, de website is toegankelijk met alle Bosch accounts die u heeft",
	Support_FAQs_section1_question3: "Kan ik een account die is aangemaakt voor Bosch Training Solutions ook gebruiken voor andere Bosch service applicaties?",
	Support_FAQs_section1_question3_answer1: "Ja, u kan uw persoonlijke Bosch ID ook gebruiken voor allerlei andere Bosch applicaties - zowel zakelijk als privé bijvoorbeeld voor uw e-bike of smart home.",
	Support_FAQs_section2_title: "Training",
	Support_FAQs_section2_question1: "Hoe kan ik een training boeken?",
	Support_FAQs_section2_question1_answer1: "Login met uw bestaande account OF registreer uw account op het platform. Ga naar \"Training Services\" OF \"Training Calendar\" en selecteer de training door op de toevoegen aan winkelwagen knop te klikken.",
	Support_FAQs_section2_question2: "Er is geen geschikte datum vrij voor de training. Wat moet ik nu doen?",
	Support_FAQs_section2_question2_answer1: "U heeft de mogelijkheid om u in te schrijven op de \"general\" wachtlijst (zonder specifieke datum) en ons team neemt contact met u op zodra er nieuwe datums beschikbaar zijn.",
	Support_FAQs_section2_question3: "Waar kan ik een overzicht van mijn geboekte trainingen vinden?",
	Support_FAQs_section2_question3_answer1: "U kunt een compleet overzicht van alle geboekte en gevolgde trainingen vinden als u bent ingelogd in uw dashboard.",
	Support_FAQs_section3_title: "Instellingen",
	Support_FAQs_section3_question1: "Hoe kan ik mijn e-mail en wachtwoord wijzigen?",
	Support_FAQs_section3_question1_answer1: "U moet inloggen op het platform en uw persoonlijke account aanpassen. (In uw dashboard vindt u uw persoonlijke informatie of als u op de knop hierboven klikt)",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Daar heeft u de mogelijkheid om de details van uw persoonlijke Bosch ID te wijzigen",
	Support_FAQs_section3_question2: "Wat kan ik doen als ik mijn wachtwoord ben vergeten?",
	Support_FAQs_section3_question2_answer1: "Gebruik de login/registreer knop en vul uw e-mail adres in",
	Support_FAQs_section3_question2_answer2: "Als u klikt op doorgaan ziet u de mogelijkheid om te klikken op \"Wachtwoord vergeten\"",
	Support_FAQs_section3_question3: "Hoe maakt ik een bedrijfsaccount aan?",
	Support_FAQs_section3_question3_answer1: "Als u de werkplaats rol wilt inschakelen voor uw account, neem dan contact op met onze trainingsadministratie of gebruik het contactformulier op de website",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Kies uw land/regio",
	MaintenanceBanner_Message_1: "De Bosch Automotive Training Solutions platform wordt momenteel geüpdated in [maintenance_schedule] en de update duurt 30 minuten. Helaas is het platform niet bereikbaar in deze periode",
	MaintenanceBanner_Message_2: "\nVoor verdere ondersteuning neem contact met ons op via e-mail [support_email]",
	MaintenancePage_Message_1: "Momenteel wordt de Bosch Autotmotive Training Solutions platform geüpdated",
	MaintenancePage_Message_2: "Wij verwachten dat het platform weer live is op [maintenance_online_date] om [maintenance_online_time]",
	MenuHeader_Support: "Ondersteuning",
	Course_Detail_No_Prerequisites: "Geen voorwaarden",
	TableCell_Hours: "Uren",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Curriculum",
	Dashboard_MenuItem_Certificates: "Attesten",
	Dashboard_MenuItem_Team: "Team",
	Dashboard_MenuItem_Workshops: "Werkplaatsen",
	Dashboard_MenuItem_External: "Extern",
	Dashboard_MenuItem_Wallet: "Portefeuille",
	Dashboard_MenuItem_Profile: "Profiel",
	Dashboard_Headline_YourUpcomingCourses: "Uw geboekte trainingen",
	Dashboard_Headline_YourELearningCourses: "Uw Elearning trainingen",
	Dashboard_Headline_TeamOverview: "Team overzicht",
	Dashboard_Headline_YourOverview: "Uw overzicht",
	Dashboard_Headline_YourWishlist: "Uw wensenlijst",
	Dashboard_Headline_ActivePrograms: "Active programma's",
	Dashboard_Headline_CompletedPrograms: "Voltooide programma's",
	Dashboard_Headline_YourCertificates: "Uw attesten",
	Dashboard_Headline_Transactions: "Transacties",
	Dashboard_Table_NoRecordsToDisplay: "Geen gegevens om te laten zien",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "Uren",
	Dashboard_ELearningCourses_TableHeader_Title: "Titel",
	Dashboard_ELearningCourses_TableHeader_Duration: "Tijdsduur (uren)",
	Dashboard_ELearningCourses_ActionButton_View: "Zien",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum (dagen)",
	Dashboard_UpcomingCourses_TableHeader_Location: "Locatie",
	Dashboard_UpcomingCourses_ActionButton_View: "Zien",
	Dashboard_YourOverview_TableHeader_Title: "Titel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum/ duur",
	Dashboard_YourOverview_TableHeader_Location: "Locatie",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Zien",
	Dashboard_YourWishlist_TableHeader_Title: "Titel",
	Dashboard_YourWishlist_TableHeader_Action: "Actie",
	Dashboard_YourWishlist_ViewAllRecords: "Bekijk alle gegevens",
	Dashboard_TeamOverview_TableHeader_Employee: "Werknemer",
	Dashboard_TeamOverview_TableHeader_Title: "Titel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum / duur",
	Dashboard_TeamOverview_TableHeader_Location: "Locatie",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Zien",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Zoeken",
	Dashboard_ActiveProgram_TableHeader_Title: "Titel",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Zien",
	Dashboard_CompletedProgram_TableHeader_Title: "Titel",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Zien",
	Dashboard_Certificates_TableHeader_Title: "Titel",
	Dashboard_Certificates_TableHeader_Date: "Datum",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Actie",
	Dashboard_Certificates_ActionButton_Download: "Dowload",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Documentnummer",
	Dashboard_Transactions_TableHeader_Description: "Omschrijving",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Betaalmethode",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Bedrag",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "Mijn training",
	Breadcrumb_MyTraining_CourseDetails: "Training details",
	Breadcrumb_MyTraining_Curricula: "Curriculum",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Training programma details",
	Breadcrumb_MyTraining_Team: "Team",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Bekijk medewerker",
	MyTraining_Headerbar_CourseDetails: "Training details",
	MyTraining_Headerbar_TrainingProgramDetails: "Training programma details",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Algemene proces",
	TrainingProgramDetails_ProgramItems_Headline: "Programma onderdelen",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titel",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Score :",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Zien",
	TrainingProgramDetails_Status_InProgress: "In behandeling",
	TrainingProgramDetails_Status_Completed: "Voltooid",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Duur",
	CourseDetails_Label_Trainer: "Trainer",
	CourseDetails_Label_Language: "Taal",
	CourseDetails_Label_Score: "Score :",
	CourseDetails_Label_StartDate: "Start datum",
	CourseDetails_Label_EndDate: "Eind datum",
	CourseDetails_Label_Venue: "Locatie",
	CourseDetails_Label_Address: "Adres",
	CourseDetails_Label_Grade: "Score :",
	CourseDetails_Action_Withdraw: "Uitschrijven",
	CourseDetails_Action_Evaluate: "Training evalueren",
	CourseDetails_Action_Evaluate_Description: "Evalueer de training om uw deelname attest te krijgen",
	CourseDetails_Action_DownloadCertificate: "Download deelname attest",
	CourseDetails_Action_JoinWebcast: "Neem deel aan webcast",
	CourseDetails_Action_StartCourse: "Start training",
	CourseDetails_Action_RestartCourse: "Herstart training",
	CourseDetails_Action_RepeatCourse_Description: "Herkans en slaag voor de training op uw deelname attest te downloaden",
	CourseDetails_PartSpecificDetails_Headline: "Onderdeel specifieke details",
	CourseDetails_PartSpecificDetails_Tab_Part: "Onderdeel",
	CourseDetails_PartSpecificDetails_Description: "Beschrijving",
	CourseDetails_PartSpecificDetails_StartDate: "Start datum",
	CourseDetails_PartSpecificDetails_EndDate: "Eind datum",
	CourseDetails_PartSpecificDetails_StartTime: "Start tijd",
	CourseDetails_PartSpecificDetails_EndTime: "Eind tijd",
	CourseDetails_PartSpecificDetails_Method: "Methode",
	Dashboard_Team_TableHeader_Name: "Naam",
	Dashboard_Team_TableHeader_Role: "Rol",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Persoonlijk account",
	Dashboard_Team_ActionButton_Select: "Selecteer",
	Dashboard_Team_PersonalAccount_Activated: "Activeren",
	Dashboard_Team_PersonalAccount_NotActivated: "Niet geactiveerd",
	Dashboard_Team_ActionButton_AddMember: "Deelnemer toevoegen",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Zoeken",
	Dashboard_External_TableHeader_Name: "Naam",
	Dashboard_External_ActionButton_AddNew: "Nieuw toevoegen",
	Dashboard_External_Headline: "Keur externe toegang goed",
	Dashboard_Workshops_Headline: "Werkplaatsen",
	Dashboard_Workshops_TableHeader_Company: "Bedrijf",
	Dashboard_Workshops_TableHeader_Address: "Adres",
	Dashboard_Workshops_TableHeader_Postcode: "Postcode",
	Dashboard_Workshops_TableHeader_City: "Plaats",
	Dashboard_Workshops_ActionButton_Select: "Selecteer",
	Wallet_ServiceCredits: "Service tegoeden",
	Wallet_Headline_CreditBalance: "Saldo tegoed",
	Wallet_Headline_CreditActivity: "Activiteiten tegoed",
	Wallet_Headline_BillingTransactions: "Gefactureerde transacties",
	Wallet_BillingTransaction_TableHeader_Order: "Bestelling",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Bedrag",
	Wallet_BillingTransaction_TableHeader_Action: "Actie",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Beschrijving",
	Wallet_CreditActivity_TableHeader_User: "Gebruiker",
	Wallet_CreditActivity_TableHeader_Amount: "Bedrag",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, alle rechten voorbehouden",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Productveiligheid (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovaties, patenten en licenties",
	FooterAdditionalLinks_Navigation_Logistics: "Aankoop en logistiek",
	MenuHeader_MyTraining: "Mijn training",
	MenuHeader_TrainingServices: "Trainingscataloog",
	MenuHeader_OurLocations: "Onze sites",
	MenuHeader_ContactsUs: "Neem contact op met ons",
	MenuHeader_Logout: "Afmelden",
	MenuHeader_FAQ: "Meest gestelde vragen",
	MyProfile_MyBoschId: "Min Bosch ID",
	MyProfile_AccountDeletion: "Account verwijderen",
	MyProfile_Edit: "Email wijzigen of account bewerken",
	MyProfile_Profile: "MijnBosch",
	MyProfile_EditUserProfile: "Gebruikersprofiel bewerken",
	BackButton: "Terug",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Wij wachten uw oproep af en zullen u met plezier verder helpen. Wij zijn 24/7 bereikbaar.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Voer de juiste tekens in, zoals aangegeven in het vak hierboven.",
	ContactUs_Email_MandatoryNotice_V3: "Vul alle verplichte velden in",
	ContactUs_Email_SendButton: "Opsturen",
	Breadcrumb_TrainingCenters: "Training centrums",
	Training_Centres_Book_Btn: "Boek een training",
	Training_centres_booking_code: "Boekingscode",
	Training_centers_header_bar_title: "Onze training centrums",
	Training_centers_filter_item: "Centrum",
	TeamEdit_PersonalAccountToolTop: "Door deze functie in te schakelen krijgt de werknemer toegang tot deze website. De gebruiker kan een persoonlijke account aanmaken en deel uitmaken van uw bedrijfsaccount. Eenmaal ingeschakeld, kunt u een uitnodiging met een activeringscode naar de werknemer sturen. De uitnodiging wordt naar het emailadres gestuurd.",
	TeamEdit_Update: "Als u de functie bedrijfsbeheerder inschakelt, krijgt deze werknemer automatisch volledige toegang tot uw bedrijfsaccount. Het inschakelen van deze functie vereist dat de gebruiker uitlogt en weer inlogt op de website, als de gebruiker al is ingelogd.",
	TeamEdit_PersonalAccount: "Persoonlijk Bosch Training Solutions webaccount toestaan",
	TeamEdit_SendInvitation: "Uitnodiging versturen",
	TeamEdit_Deactivate: "Uitschakelen",
	TeamEdit_Telephone: "Telefoon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Het mobiel nr moet nummers zijn",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Het telefoon nr moet nummers zijn",
	TeamEdit_Activate: "Actief",
	TeamEdit_NotActivated: "Niet actief",
	TeamEdit_StatusPopup: "De status wordt weergegeven als 'Actief' als de gebruiker met succes een persoonlijke account heeft aangemaakt. Als de status is ingesteld als 'Niet actief', betekent dit dat de gebruiker uw uitnodiging niet heeft voltooid.",
	Training_Catalog_Courses: "Opleidingen",
	Training_Catalog_Course: "Opleiding",
	Reset_Filter: "Filter resetten",
	TrainingCatalogue_CourseCatalogue: "Trainig cataloog",
	CheckoutHeader_CartItemCount_Text: "U heeft [count] artikelen in uw winkelmand",
	CheckoutHeader_CartItemsCount_Text: "U heeft [count] artikelen in uw winkelmand",
	ShoppingCart_PriceItem: "Prijs",
	ShoppingCartItemParticipants_AddParticipant_Button: "Deelnemers toevoegen",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotaal",
	ShoppingCartTotalPrice_TaxesLabel: "Taxen",
	ShoppingCartTotalPrice_TotalLabel: "Totaal",
	ShoppingCartTotalPrice_IncludedVATLabel: "BTW inbegrepen op [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Online betalingsmethodes beschikbaar",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Deelnemer toevoegen",
	AddParticipantsModal_SelectEmployeeLabel: "Deelnemer toevoegen",
	AddParticipantsModal_SelectWorkshopLabel: "Selecteer werkplaats",
	ShoppingCartTotalPrice_ContinueButtonText: "Doorgaan met betalen",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "De deelnemer werd niet toegevoegd!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "U heeft een of meerdere cursussen in uw winkelwagen zonder deelnemer.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Voeg een deelnemer toe om verder te gaan met betalen",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Wijzig deelnemers ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Naam",
	AddParticipantsModal_ParticipantTable_Email: "Email",
	AddParticipantsModal_ParticipantTable_Action: "Actie",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Geen deelnemers…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Emailadres niet geconfigureerd bij geselecteerde deelnemer",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "De geselecteerde deelnemer heeft geen emailadres geconfigureerd in zijn profiel. Een emailadres is verplicht om in te schrijven voor een training.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Voeg een Email adres toe om verder te gaan of annuleer om een andere medewerker te selecteren",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Voeg een email adres toe",
	CancelButton: "Annuleer",
	AddParticipantsModal_AddEmployeeButtonText: "Voeg een medewerker toe",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "U staat op het punt een cursus uit uw winkelwagen te verwijderen",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "U probeert een cursus met toegevoegde deelnemers uit uw winkelwagen te verwijderen.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klik op \"Continue\" als u wilt doorgaan of klik op \"Cancel\".",
	ShoppingCartItemHeader_Participants: "Deelnemer(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Prijs per eenheid",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Weergave en [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Weergave in Tegoeden",
	ShoppingCart_CreditsPriceUnit: "Tegoeden",
	CheckOutHeader_Step1_Question: "Hoe wenst u te betalen?",
	CheckOutHeader_Step2_Question: "Kijk uw bestelling na",
	CheckOutHeader_Step3_Question: "Vervoledig uw bestelling",
	CheckOutHeader_Step4_Question: "Uw inschrijving werd met succes doorgevoerd!",
	CheckOutHeader_Step_Payment: "Betaling",
	CheckOutHeader_Step_Review: "Nakijken",
	CheckOutHeader_Step_Place_Order: "Bestelling plaatsen",
	CheckOutRightPanel_Review_Order_Btn: "Bestelling nakijken",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Bestelling plaatsen",
	CheckoutRightPanelContent_OrderSummary_Title: "Samenvatting van de bestelling",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Wijzig winkelwagen",
	ShoppingCart_Participants: "Deelnemers",
	CheckOutStepPayment_Payment_Methods_Title: "Selecteer betalingswijze",
	CheckOutStepPayment_Credit_Title: "Credit of debet",
	CheckOutStepPayment_Wholesaler_Title: "Groothandelaar",
	CheckOutStepPayment_ServiceCredit_Title: "Service tegoeden",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "U heeft [available_credit] tegoeden beschikbaar",
	CheckOutStepPayment_BoschAccount_Title: "Bosch account",
	Checkout_Second_PaymentMethod_Headline: "Betalingswijze",
	CheckOutStepReview_Edit: "Bewerken",
	CheckOutStepReview_Terms_Label: "Voorwaarden en annuleringbeleid",
	CheckOutStepReview_Terms_Text: "Ik heb de [terms, conditions and cancellation policy] van de verkoop gelezen en begrepen en ga ermee akkoord.",
	SelectSecondPaymentMethodModal_Title: "Selecteer secundaire betalingsmethode",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Betaal [amount]",
	StepPaymentWholesaler_Title: "Selecteer groothandelaar",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Algemene voorwaarden aanvaarden",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Lees en accepteer onze voorwaarden en annuleringsvoorwaarden voordat u verder gaat.",
	CheckoutDescription_ThankYou: "Bedankt voor het plaatsen van een bestelling op Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Onze bits en bytes zijn al begonnen met het verwerken van uw bestelling. Houd ondertussen uw motor stationair.",
	CheckoutDesctiption_Text: "Wij sturen u per mail een definitieve bevestiging met alle details van uw bestelling.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "U staat op het punt de geselecteerde workshop te verwijderen",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Door de geselecteerde workshop te verwijderen, worden alle toegevoegde deelnemers gereset. Deze wijziging heeft gevolgen op alle cursussen in uw winkelwagen.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klik op \"Continue\" als u wilt doorgaan of klik op \"Cancel\" om deze actie te stoppen.",
	CheckOutStepPayment_Insufficient_Credit_Message: "U heeft onvoldoende tegoeden. Uw Tegoed saldo is [available_credit] tegoeden, maar u heeft [cart_total_credits] tegoeden nodig om af te rekenen. Selecteer een andere betaalmethode of verwijder artikelen uit uw winkelwagen.",
	Training_centers_filter_items: "Centrum",
	Training_Programs_Filter_Item: "Programmas",
	Training_Program_Filter_Item: "Programma",
	Training_Program_Year: "Jaar (jaren)",
	Training_Program_Detail_Title: "Training programmas",
	AccountButton_Workshops_MenuItem: "Workshops",
	AccountButton_Wallet_MenuItem: "Portefeuille",
	Header_MyBoschProfile_Header: "Mijn Bosch profiel",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Beschikbaar",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Deelnemer toevoegen / bewerken",
	ShoppingCart_CreditPriceUnit: "Crediet",
	Common_IncludedVAT: "inclusief BTW",
	TrainingCatalogue_Length: "Duur van de opleiding",
	TrainingCatalogue_Method: "Trainingsmethode",
	Course_Price_Credits: "Credieten",
	Catalogue_Grid_Register_Button: "Inschrijven",
	Catalogue_Credit_Tilte: "Klik hier om de prijs in Credieten te zien",
	Catalogue_Price_Tilte: "Klik hier om de prijs in EUR te zien",
	Catalogue_Grid_Vat: "inclusief BTW",
	EventCalendar_Seats: "Beschikbare plaatsen",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Kataloog",
	ListFilter_NoResult: "Geen resultaat",
	Course_Description: "Beschrijving van de opleiding",
	Training_course_detail: "Inhoud van de opleiding",
	Training_course_detail_add_waiting: "Inschrijven op de wachtlijst",
	Register_now_btn: "Nu inschrijven !",
	Register_now_btn_add_event_to_cart: "Nu inschrijven !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Algemeen overzicht",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Minder tonen",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Algemeen overzicht",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Minder tonen",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Algemeen overzicht",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Minder tonen",
	TeamCreate_TitleError: "Titel is vereist",
	Dashboard_Headerbar_BookCourse: "Opleiding boeken",
	Dashboard_Headerbar_ExitProxy: "Exit proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluatie van de opleiding",
	Dashboard_Headerbar_EvaluateCourse_Note: "Gelieve de opleiding te evalueren om uw deelname attest te ontvangen !",
	Dashboard_Headerbar_DownloadCertificate: "Deename attest uploaden",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Zich afmelden",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Zich afmelden",
	Dashboard_Headerbar_WelcomeMessage: "Welkom op uw dashboard voor de opleidingen",
	Dashboard_Headerbar_ProxingAs: "Proxying als",
	Dashboard_TrainingProgram_Status_Completed: "Voltooid",
	Dashboard_TrainingProgram_Status_InProgress: "Aan de gang",
	External_SelectWholesalerModal_Title: "Externe gebruikerstoegang toevoegen",
	External_SelectWholesalerModal_Company_Label: "Bedrijf",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Selecteer",
	External_SelectWholesalerModal_Outlet_Placeholder: "Selecteer",
	External_SelectWholesalerModal_ApplyButton: "Toepassen",
	External_SelectWholesalerModal_CancelButton: "Afzeggen",
	External_RemoveWholesalerModal_ConfirmLabel: "Bevestiging",
	External_SelectWholesalerModal_ConfirmMessage: "Bent u zeker dat u deze groothandelijk wilt verwijderen?",
	External_SelectWholesalerModal_YesButton: "Ja",
	External_SelectWholesalerModal_NoButton: "Neen",
	Training_program_team_member: "Teamgenoot",
	Training_program_assign_training_program: "Trainingsprogramma toewijzen",
	Assign_modal_student_confirmation_text1: "U bent op het punt [Training Program Name] toe te wijzen aan uw leertraject",
	Assign_modal_student_confirmation_text2: "Wenst u verder te gaan?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mei",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sept",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Trainingskataloog",
	MenuHeader_Event: "Trainingskalender",
	MenuHeader_Program: "Trainingsprogramma",
	MenuHeader_Services: "Services",
	MenuHeader_Log_out: "Uitloggen",
	Filter_Location: "Locatie",
	TeamEdit_UpgradeToCompanyAdministrator: "Upgrade naar bedrijfsbeheerder",
	TeamEdit_PersonalWebAccountStatusIs: "Persoonlijke webaccountstatus is",
	Header_CreateNewEmployee: "Nieuwe medewerker toevoegen",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Geen plaatsen meer beschikbaar",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Er zijn geen beschikbare plaatsen meer voor de gekozen sessie.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Selecteer een andere sessie of verwijder een reeds toegevoegde deelnemer om verder te gaan.",
	Common_SuccessModal_Title: "Gefeliciteerd!",
	EmployeeProfile_UserExistErrorMessage: "Dit account bestaat al!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Betaling mislukt. Probeer het opnieuw of gebruik een andere betaalmethode.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Je hebt de geselecteerde werknemer met succes gedeactiveerd!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Opgeslagen!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Uw wijzigingen werden opgeslagen.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "U kunt zich niet uitschrijven voor een eLearning. Neem contact op met ons als u meer informatie wenst.",
	Dashboard_Team_PersonalAccount_Pending: "In behandeling",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Medewerker aangemaakt!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Het toevoegen van de medewerker is gelukt.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Met deze instelling kan u voorkomen dat een medewerker zich zelf inschrijvft voor een training. Enkel bedrijfsbeheerders kunnen de werknemer registreren voor een training als deze instelling niet is geselecteerd.",
	Training_catalog_sort_by: "Sorteer op",
	CourseDetail_EvaluationRestriction_Title: "Trainingsevaluatie niet mogelijk!",
	CourseDetail_EvaluationRestriction_Message: "Trainingsevaluaties kunnen alleen door de cursist zelf worden ingediend. \nInstrueer de cursist om in te loggen bij Bosch Automotive Training Solutions en de trainingsevaluatie te voltooien.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Als de student nog geen account heeft, kan een uitnodiging worden gedeeld in de gebruikersprofielinstellingen van het teamlid.",
	Breadcrumb_Download_Page: "Downloaden",
	Download_Table_Category_Cell: "Categorie",
	Download_Table_Title_Cell: "Titel",
	Download_File_Filter_Item: "Bestand",
	Download_File_Filter_Items: "Bestanden",
	Download_Header: "Download gebied",
	DownloadArea_Button_Download: "Downloaden",
	Popup_Title_Error: "Fout",
	Popup_Title_Success: "Succes",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Opgelet!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Reeds bestaande inschrijvingen voor evenementen worden niet geannuleerd en moeten individueel worden geannuleerd.",
	TrainingProgram_PDF_Export: "PDF Export",
	TrainingProgram_PDF_Export_Participant: "Deelnemer :",
	TrainingProgram_PDF_Export_Status: "Status :",
	TrainingProgram_PDF_Export_Progress: "Algemene vooruitgang :",
	TrainingProgram_PDF_Export_TableHeader_Title: "Trainingstitel",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Niveau",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datum",
	AccountButton_CompanyChange_MenuItem: "Bedrijfsverandering",
	SelectCompanyModal_Title: "Selecteer een bedrijf",
	CloseButton: "Sluiten",
	Change_Button: "Wijzigen",
	ChangeManagerModal_Content: "Selecteer een gebruiker",
	ChangeCompanyModal_HeadOffice: "Hoofdkantoor",
	SelectCompanyModal_Content: "Gelieve een bedrijf te selecteren om in te loggen…",
	AlertSuccess_Title: "Succes",
	CheckOutStepPayment_SecondaryPayment_Title: "Selecteer een secundaire betalingsmethode",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Voeg een secundaire betalingsmethode toe",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Voeg hieronder een secundaire betaalmethode toe of selecteer hierboven een andere betaalmethode.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "U heeft onvoldoende Servicecredits op uw account om de items in uw winkelwagen te betalen. Als u de geselecteerde betaalmethode wilt blijven gebruiken, moet u het verschil betalen. Het verschil is [difference_amount] (excl. btw).",
	ShoppingCartTotalPrice_TotalInCredit: "Totaal in credits",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Uw beschikbare credits",
	ShoppingCartTotalPrice_YourDifference: "Verschil",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotaal excl. BTW",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotaal BTW inbegrepen",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Secundaire betalingsmethode",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Betalingsmethode bewerken",
	Checkout_Second_PaymentMethod: "Tweede betalingsmethode",
	Breadcrumb_TrainingCourseDetails: "Details van de training",
	DownloadArea_FailedToDownload_ErrorMessage: "Het is niet gelukt om het document te downloaden. Probeer het opnieuw of neem contact op met uw Company Administrator voor assistentie.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Downloadbestand is niet beschikbaar. Probeer het opnieuw of neem contact op met uw Company Administrator voor assistentie.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Downloadbestand is verboden. Probeer het opnieuw of neem contact op met uw Company Administrator voor assistentie.",
	SSO_Redirecting_Message: "U wordt doorgestuurd naar het Trainingsportaal...",
	SSO_Redirecting_ParameterErrorMessage: "Land en/of taal zijn niet aangegeven. Probeer het opnieuw of neem contact op met uw bedrijfsbeheerder voor hulp.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Inschrijvingsverzoek(en) niet aanvaard.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Dit kan te wijten zijn aan het feit dat de inschrijvingsperiode voor één of meerdere cursussen nog niet is bereikt. Gebruik de optie \"Contact\" als u vragen of verzoeken heeft.",
	TimeSlotPicker_DateFormat_Title: "Datumnotatie",
	UpdateAccountSettingsConfirmationModal_Title: "U staat op het punt de datumnotatie te wijzigen!",
	UpdateAccountSettingsConfirmationModal_Message1: "Het datumformaat wordt weergegeven volgens uw selectie en kan op elk moment worden gewijzigd.",
	UpdateAccountSettingsConfirmationModal_Message2: "Wilt u doorgaan?",
	ProfileSummary_Settings_Title: "Instellingen",
	UpdateAccountSettings_DateFormat_Title: "Instellingen voor datumformaat",
	UpdateAccountSettings_DateFormat_ErrorMessage: "De datumnotatie kon niet bijgewerkt worden",
	UpdateAccountSettings_DateFormat_SuccessMessage: "De datumnotatie-instelling is succesvol bijgewerkt.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Download het attest",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Attest trainingsprogramma",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Het downloaden van het document is mislukt. Probeer het opnieuw of neem contact op met de beheerder van uw bedrijf voor hulp.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Intrekkingsdrempel overschreden",
	CourseWithdrawalModal_PendingWithdraw_Message1: "De drempel voor terugtrekking uit deze cursus is overschreden en vereist handmatige verwerking door onze Opleidingsadministratie.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "De definitieve bevestiging wordt per e-mail verzonden.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Raadpleeg ons [annuleringsbeleid] voor meer informatie.",
	TrainingCourse_Course_NotAvailable_Title: "De training is niet beschikbaar.",
	TrainingCourse_Course_NotAvailable_Message: "De training is niet beschikbaar. Probeer het opnieuw of neem contact op met uw Company Administrator voor assistentie.",
	TrainingCourse_NotAvailable_Message_1: "Je opent een training die niet beschikbaar is.",
	TrainingCourse_NotAvailable_Message_2: "Probeer opnieuw of bekijk de andere mogelijkheden via [training courses]",
	LanguageSelection_SelectALanguage: "Selecteer uw taal",
	LanguageSelection_SelectYourCountry: "Selecteer uw land",
	Checkout_PaymentMethod_Headline: "Betalingsmethode",
	Download_Categories_Catalogue: "Catalogus",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Algemeen",
	Download_Categories_Information: "Informatie",
	Download_Categories_Userguides: "Gebruikershandleiding",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Annuleringsbeleid",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Annuleren",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Doorgaan",
	Duration_Seconds: "Seconden",
	Duration_Day: "Dag",
	Duration_Hour: "Uur",
	Duration_Minute: "Minuut",
	Duration_Second: "Seconde",
	EventCalendar_ExternalLink_Tooltips: "Kopen via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n.v.t.",
	EventCalendar_ExternalLink_Cancel: "Annuleren",
	EventCalendar_ExternalLink_Continue: "Verdergaan",
	EventCalendar_ExternalLinkModal_Title: "Je wordt zo meteen doorgestuurd",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Het geselecteerde evenement wordt verkocht via [externalRegistrationName]",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Doorgaan leidt je naar een externe website",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Wilt u verdergaan?",
	Team_ContactPerson_FirstName_Tooltips: "Namen van medewerkers kunnen alleen worden gewijzigd door het Bosch training administration team. Neem voor verdere ondersteuning contact met ons op via het contactformulier op deze website",
	Team_ContactPerson_LastName_Tooltips: "Namen van medewerkers kunnen alleen worden gewijzigd door het Bosch training administration team. Neem voor verdere ondersteuning contact met ons op via het contactformulier op deze website",
	Withdraw_ExternalLink_Cancel: "Annuleren",
	Withdraw_ExternalLink_Confirm: "Bevestigen",
	Withdraw_ExternalLinkModal_Title: "Je wordt zo meteen doorgestuurd",
	Withdraw_ExternalLinkModal_Message: "Terugtrekkingen voor deze cursus worden afgehandeld door een externe aanbieder",
	Withdraw_ExternalLinkModal_Confirmation: "Als je op \"Bevestigen\" klikt, wordt een nieuw tabblad geopend en word je doorgestuurd naar een externe website",
	Button_AddToWaitingList_Tooltips: "Toevoegen aan de wachtlijst",
	Button_AddToCart_Tooltips: "Toevoegen aan uw winkelmand",
	Button_TrainingCourseDetail_Tooltips: "Zie details",
	Button_TrainingProgramDetail_Tooltips: "Zie detail",
	Button_AssignTrainingProgram_Tooltips: "Een trainingsprogramma toewijzen",
	Button_DeleteFromCart_Tooltips: "Verwijderen uit uw winkelmand",
	Button_RemoveParticipant_Tooltips: "Deelnemer verwijderen",
	Button_DownloadBillingTransaction_Tooltips: "Downloaden",
	Button_RemoveExternal_Tooltips: "Verwijderen"
};