export const TRANSLATIONS_RO = {
	LandingPage_Title: "Bune ați venit la Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Selectati țara / regiunea",
	Header_ContactUs: "Contactați-ne",
	Header_Search: "Caută",
	Header_Menu: "Meniu",
	Header_LogInOrCreateYourAccount: "Conectați-vă sau creați un cont nou",
	Header_LogIn: "Conectare",
	Header_Cart: "Coș",
	Header_ShoppingCart: "Coș de cumpărături",
	Header_LogOut: "Deconectare",
	Header_ChangeDetails: "Modificați detaliile",
	Footer_ExploreOurTraining: "Vizualizați oferta de cursuri",
	Footer_TrainingAtYourFingertips: "Cursuri la îndemâna ta",
	Footer_DontMissAnyStory: "Nu rata nicio postare",
	Footer_ExploreOurStories: "Explorați postările noastre",
	Footer_GetInTouchWithUs: "Contactează-ne",
	Footer_SendUsAMessage: "Trimite-ne un mesaj",
	Footer_ToContactForm: "Către formularul de contact",
	Footer_InventedForLife: "Tehnică pentru o viață",
	Footer_GeneralContactInfo: "Informații generale de contact",
	Footer_ProductSecurity_Psirt: "Securitatea produsului (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Licenţe şi patente",
	Footer_PurchasingLogistics: "Achizitii si logistica",
	Footer_CorporateInformation: "Informații companie",
	Footer_LegalNotice: "Informaţii legale",
	Footer_DataProtectionNotice: "Notificare privind protectia datelor",
	Footer_PrivacySettings: "Setări de confidenţialitate",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch SRL, toate drepturile rezervate",
	Footer_StillLookingForSomething: "Nu ai găsit ce căutai",
	Footer_TrainingServices: "Oferta de cursuri Bosch",
	Footer_Courses: "Catalog cursuri",
	Footer_Programs: "Programe",
	Footer_EventsCalendar: "Calendar de școlarizări",
	Footer_TrainingCenters: "Centru de training",
	Footer_Dashboard: "Panou de control",
	Footer_MyDashboard: "Panoul meu de control",
	Footer_ShoppingCart: "Coș de cumpărături",
	Footer_YourShoppingCart: "Coșul tău de cumpărături",
	Footer_Administration: "Administrare cont",
	Footer_Employees: "Angajați",
	Footer_Wholesalers: "Distribuitori",
	Footer_Transactions: "Tranzacții",
	Footer_Workshops: "Ateliere",
	Footer_WorkLikeABoschApplyNow: "Lucrează  - Aplica acum.",
	Footer_BoschGlobalCareers: "Cariere Bosch la nivel mondial",
	Footer_PurchasingAndLogistics: "Achiziţii şi logistică",
	Footer_BecomeABusinessPartner: "Deveniți partener de afaceri.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Modul în care cursurile noastre de formare vă influențează favorabil afacerea",
	Footer_Language: "Selectați țara",
	MenuPopup_MyCart: "Coșul meu",
	Stories_OurTrainingStories: "Postările noastre despre training-uri",
	Stories_ConnectingExperts: "Conectează experți și entuziaști, visători și realizatori, inovatori și influențatori - explorați și experimentați",
	Stories_TopStories: "Cele mai bune articole",
	Stories_AtHome: "Acasă",
	Stories_Careers: "Cariere",
	Stories_History: "Istoric",
	Stories_IndustryAndTrades: "Industrie și meserii",
	Stories_Mobility: "Mobilitate",
	Stories_Research: "Cercetare",
	Stories_Sustainability: "Sustenabilitate",
	Stories_AllStories: "Toate postările",
	Stories_BlogATrainersDay: "BLOG | Ziua unui trainer",
	Stories_ADayinLifeOfATrainer: "O zi din viața unui trainer",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "POSTARE | Ceea ce te motivează, ne inspiră pe noi",
	Stories_StoryHowCanWeBenefitYourBusiness: "POSTARE | Cum putem să-ți sprijinim afacerea",
	Stories_HowOurTrainingEvolves: "Cum se desfăsoară cursurile noastre",
	Stories_AModernTrainingApproach: "Lumea cursurilor online",
	Stories_StoryAVirtualOnlineTrainingWorld: "POSTARE | Oportunitățile dvs. de formare online",
	Stories_SubscribeToRSSFeed: "Abonați-vă la fluxul RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Despre noi",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Contact",
	Homepage_LatestNews: "Aflați de ce suntem conduși de setea de cunoaștere",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Locuri de muncă",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Ceea ce te conduce pe tine, ne conduce pe noi",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Distribuie acest lucru pe",
	BoschServiceTraining_TheChallenge: "Provocare: Stăpânirea noilor tehnologii",
	BoschServiceTraining_WorldwidePresence: "Prezență la nivel mondial",
	BoschServiceTraining_TechnicalTraining: "Cursuri tehnice de la Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Ofertele și oportunitățile pe care le oferim noi, cei de la Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Există cursuri de formare potrivite pentru fiecare persoană - indiferent dacă este începător sau profesionist. Oferta include atăt cursuri de formare de o singură zi, cât și cursuri de formare pe mai multe zile. Cursurile de formare sunt oferite în diferite domenii tehnice și serii de formare (cum ar fi sistemele electrice, de injecție diesel, de injecție de benzină, etc.). În cursurile de pregătire tehnică, Bosch oferă un know-how vast în toate sistemele standard, atât de la Bosch, cât și  de la alți producători. Conținutul este adaptat în mod special cerințelor angajaților service-ului.",
	BoschServiceTraining_TrainedEmployeesDesc: "Angajații instruiți reprezintă un avantaj evident pentru atelierele auto. Pentru că vehiculele de astăzi devin din ce în ce mai complexe, iar noile tehnologii prezintă în mod constant noi provocări. Doar expertiza necesară permite angajaților atelierului să efectueze lucrări de diagnosticare, întreținere și reparații la modelele actuale de vehicule, într-un mod eficient și rentabil.",
	BoschServiceTraining_RegardlessOfHow: "Indiferent de cât de buni și experimentați sunt specialiștii auto – întotdeauna există situații în care aceștia vor intra în contact cu sisteme tehnologice noi sau cu defecțiuni necunoscute. În aceat context, depanarea și rezolvarea problemelor poate fi extrem de consumatoare de timp sau, eventual, nu dă rezultate scontate. În acest scenariu, clienții trebuie să se descurce fără vehiculul lor mai mult timp decât se așteptă.",
	BoschServiceTraining_ItIsPrecisely: "Acesta este momentul în care Bosch își arată cu adevărat valoarea: îmbunătățirea calității serviciilor și accelerarea proceselor. Cursurile susținute de către Bosch oferă asistență în localizarea problemelor mai rapid, repararea mai multor vehicule în același timp și reducerea timpilor de așteptare ale clienților dumneavoastră. Astfel, procedurile sistematice învațate la Bosch asigură o muncă mai rapidă și mai productivă.",
	BoschServiceTraining_WorldwidePresenceDesc1: "De mai bine de 125 de ani, Bosch a fost furnizorul preferat pentru componente si sisteme pentru mulți dintre cei mai importanți producători de automobile din întreaga lume. Această experiență vine la pachet cu cunoștințe unice despre cele mai noi tehnologii utilizate pe vehicule și servesc drept bază pentru asistența Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Centrele de instruire Bosch Service pot fi găsite peste tot în lume: din Spania până în Turcia, din Africa de Sud, prin Australia, și până în Brazilia - Bosch oferă partenerilor săi cursuri de formare orientate spre practică, care sunt întotdeauna în conformitate cu tehnologiile de ultimă generație.",
	BoschServiceTraining_AgileCompany: "Companie agila",
	BoschServiceTraining_Collaboration: "Colaborare",
	BoschServiceTraining_ConnectedMobility: "Mobilitate conectată",
	BoschServiceTraining_ArtificialIntelligence: "Inteligență artificială",
	BoschServiceTraining_ThoughtLeadersInDialogue: "În dialog cu formatorii de opinie",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnoză și echipamente de testare",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Bazele tehnologiei autovehiculelor",
	BoschServiceTraining_Icon_Gasoline: "Benzină",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Sisteme de propulsie alternativă",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Electrică și electronică",
	BoschServiceTraining_Icon_BodyAndComfort: "Sisteme de confort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Sisteme de siguranță",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Tren de rulare și transmisie",
	BoschServiceTraining_Icon_CommercialVehicles: "Vehicule comerciale",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Cursuri online",
	BoschServiceTraining_Icon_BusinessSkills: "Abilitati in afaceri",
	BoschServiceTraining_Icon_Accreditations: "Acreditări",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programe de training",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerPrenumeNume",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "O zi din viața unui treiner",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Fiecare zi este o aventură",
	ADayInTheLifeOfATrainer_Summary: "Rezumat",
	ADayInTheLifeOfATrainer_TheDayJourney: "Desfăsurătorul zilei",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Fiecare zi este o zi plină, dar este întotdeauna o experiență plină de satisfacții - satisfacția de a pregăti studenții știind că ei dețin cheia viitorului nostru. Aceasta este o responsabilitate, dar în același timp o munca atât de plăcută și incitantă. Vederea cursanților care se dezvoltă cu fiecare lecție, zilnic și săptămânal, ne dovedește că avem un obiectiv comun, și anume acela de a le îmbunătăți cunoștințele, încorporând în același timp tehnologia modernă care conduce viitorul!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "La sfârșitul fiecărei zile ne așezăm și reflectăm asupra celor petrecute. Zilele trec atât de repede, dar aceasta este dovada în sine că ceea ce realizăm nu este plin de satisfacții doar pentru studenți, ci și pentru noi, ca formatori.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Putem merge acasă la sfârșitul zilei mulțumiți că am făcut o treabă minunata!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start.",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Pregătirea și verificările finale ale materialului de training. Verificarea mesajelor.",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Trainingul începe:",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Întampinare participanților, prezentarea programului zilnic și a normelor de siguranță. Cunoașterea grupului prin înțelegerea dinamicii și experienței fiecărui individ.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorie:",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Este necesară o anumită interacțiune cu grupul pentru ca trainingul să fie cât mai interesant și interactiv. Asigurarea că există sarcini împletite cu teoria pentru a ține implicat grupul este obligatorie.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pauză de masă",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Practica:",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Trainingul continuă în atelier cu câteva sarcini practice. Ca trainer, este necesar să fii prezent și disponibil pentru orice îndrumare, dar și pentru precauție.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Recapitulare:",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Înapoi în bănci pentru un rezumat, pentru a discuta despre cum s-a desfățurat ziua. Răspunderea tuturor întrebărilor venite de la participanți.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "In ultima zi:",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Sesiunea de feedback este oblicatorie pentru a putea înbunătății urmatoarele cursuri. Certificatele de participare sunt înmânate participanților. Urmează curățenia și aranjarea materialelor...",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Acasă,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Cum vă putem ajuta afacerea",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Cum decurge scolarizarea noastră",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Evoluăm în mod constant pentru a ne asigura că oferim cursuri de calitate în fiecare zi",
	BoschTrainingBenefits_TheBoschServiceTraining: "Centrul de scolarizare Bosch ar dori să vă ajute în dezvoltarea afacerii dumneavoastră prin portofoliul său extins și extrem de relevant de cursuri de formare tehnică și de formare continuă cu calificări certificate. Următoarele pagini vă oferă o prezentare generală a programului nostru de formare, precum și a oportunităților actuale de educație. Așteptăm cu nerăbdare să vă întâlnim pe dumneavoastră și pe angajații dumneavoastră la cursurile și seminariile noastre de formare.",
	BoschTrainingBenefits_LongStandingExperience: "Experiență vasta și know-how de ultimă generație în domeniul tehnologiei auto, precum și în toate sistemele.",
	BoschTrainingBenefits_QuickAndEfficient: "Soluții rapide și eficiente pentru multe probleme.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Avantaje și beneficii",
	BoschTrainingBenefits_LMS: "Sistemul de management al învățării - soluții de formare Bosch",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions acoperă întreaga gamă din domeniul auto. Specializați-vă în domenii specifice sau extindeți-vă competențele cu ofertele de formare de la Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Cunoștințele dobândite ajută lucrările de mentenanță și reparațiile.",
	BoschTrainingBenefits_TheHighDegree: "Gradul ridicat de practică și grupurile mici garantează o învățare adegvată",
	BoschTrainingBenefits_ParticipationInTraining: "Participarea la cursurile de formare crește motivația și satisfacția angajaților, iar calificările suplimentare conving clienții",
	BoschTrainingBenefits_IncreasedSales: "Creșterea vânzărilor datorită cunostințelor din diverse domenii",
	BoschTrainingBenefits_MethodicalProcedures: "Procedurile metodice reduc timpul de așteptare al clienților",
	BoschTrainingBenefits_HighlyQualified: "Traineri competenți transmit proceduri sistematice",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificarea soluției de scolarizare potrivită pentru dvs. sau afacerea dvs",
	BoschTrainingBenefits_DirectOnlineBooking: "Rezervarea cursurilor online și gestionarea acestora pentru dumneavoastră, sau pentru angajații dumneavoastră",
	BoschTrainingBenefits_OnlineAccess: "Acces online la documentația de training",
	BoschTrainingBenefits_AccessToYourOwn: "Acces la propriul istoric de traininguri și certificate",
	BoschTrainingBenefits_ToolsForManagement: "Instrumente pentru managementul propriei dezvoltări profesionale",
	BoschTrainingBenefits_ManagerHasFull: "Managerul are o privire de ansamblu asupra cursurilor angajaților",
	BoschTrainingBenefits_Access24hours: "Acces 24 de ore, 7 zile pe săptămână",
	ModernTrainingApproach_KnowledgeIsKey: "Cunoștințele sunt un factor cheie pentru orice atelier. De aceea Bosch oferă un program vast de formare profesională. Acest lucru le permite participanților să dobândească cunoștințele potrivite la momentul și locul potrivit - cunoștințe necesare pentru a putea întreține și repara vehiculul clientului în mod profesional. Bosch a adăugat scolarizarile online ca un nou modul parte a pachetului deja oferit. Acestea constau în sesiuni de școlarizare pentru servicii concepute special pentru streaming.",
	ModernTrainingApproach_ANewLearning: "Noi oportunități de învățare",
	ModernTrainingApproach_YourOnlineTraining: "Oportunitățile dvs. de școlarizare online",
	ModernTrainingApproach_RecentlyTheWorld: "Recent, lumea s-a schimbat, vezi ce facem pentru a ajuta",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Lumea cursurilor online",
	ModernTrainingApproach_NewTeachingMethod: "Noi metode de învățare",
	ModernTrainingApproach_InAdditionTo: "În plus față de instruirea existentă, fizică, față în față, am inclus în programul de școlarizare cursuri de webcast live. Trainerul nostru vă stă la dispoziție cu  cunoștințe teoretice vaste în sala de clasă virtuală precum și cu exemple practice. Ca de obicei, veți primi o mulțime de informații despre sistemele vehiculelor și produsele Bosch. Bineînțeles că aveți și posibilitatea de a pune întrebări și de a primi răspunsuri direct în transmisiunea live. Acestea durează între 60 și 240 de minute, în funcție de complexitatea subiectului. Pentru participare este necesară o conexiune stabilă și puternică la internet. Alegeți cursul preferat direct din catalogul nostru de formare introducând \Webcast\ în câmpul de căutare.",
	ModernTrainingApproach_BoschWebcastingHeader: "Cursuri online: inovator și practic",
	ModernTrainingApproach_BoschWebcastingText: "Pentru a participa la un online Bosch mai întâi, participantul trebuie să se înregistreze. Un cod de acces individual este trimis înainte de sesiune pentru a permite autentificarea. Este posibilă participarea simultană de până la 30 de participanți simultan, din tot atâtea locații. Școlarizarea este susținută de un trainer, live, iar întrebările pot fi trimise oricând prin intermediul funcției de chat.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Informații suplimentare pentru școlarizări",
	ModernTrainingApproach_TheDigitalSupplementText: "Sesiunile de training față în față și cele live se completează perfect. În ambele cazuri un trainer calificat conduce școlarizarea. Sesiunile de scolarizare via internet sunt potrivite pentru subiecte care pot fi transmise online, fără a fi nevoie să lucrați la vehicul. De exemplu, cunoașterea anumitor caracteristici ESI[tronic] ale unui nou sistem de asistență pentru șofer. Când vine vorba de munca în echipă, eficiență sau lucrul practic pe sistemul unui vehicul, instruirea față în față devine prioritară.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Online este mai ieftin",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Costurile per participant sunt mai mici cu transmisiile online comparativ cu cursurile susținute în sala de curs. Motivul este că mai mulți participanți pot beneficia de aceasta, iar efortul asociat instruirii este semnificativ mai mic. În același timp costurile dumneavoastră scad (fără cheltuieli de deplasare către și de la locație, iar timpul asociat acestora mai mic).",
	ModernTrainingApproach_BookAndProfitHeading: "Rezervă și profită",
	ModernTrainingApproach_BookAndProfitText: "Cursurile online pot fi rezervate pe site-ul nostru la fel ca celelalte cursuri.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Comparație între cursurile online și cele de formare în clasă",
	ModernTrainingApproach_Webcasting: "Cursuri online",
	ModernTrainingApproach_Classroom: "Cursuri în clasă (față în față)",
	ModernTrainingApproach_WebcastingP1: "Pot participa până la 30 de participanți în același timp, de oriunde din lume",
	ModernTrainingApproach_WebcastingP2: "Complexitate redusă și costuri relativ scăzute, nu necesită călătorii",
	ModernTrainingApproach_WebcastingP3: "Ideal pentru subiecte individuale specifice și concise",
	ModernTrainingApproach_WebcastingP4: "Atelierele pot vizualiza un curs online pentru angajații lor oriunde și oricând",
	ModernTrainingApproach_WebcastingP5: "Cu 50-70% mai puțin timp comparativ cu sesiunile de training la clasă",
	ModernTrainingApproach_ClassroomTrainingP1: "Până la 12 participanți în același timp, în format fizic",
	ModernTrainingApproach_ClassroomTrainingP2: "Cheltuieli de deplasare și, în general, costuri mai mari de persoană",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal atunci când vine vorba de optimizarea colaborării sau de învățare direct pe un vehicul",
	ModernTrainingApproach_ClassroomTrainingP4: "Exerciții practice și interacțiune cu trainerul",
	ModernTrainingApproach_ClassroomTrainingP5: "Trainerul poate adapta mai bine cunoștințele care urmează să fie predate în funcție de nevoile reale ale participanților",
	ModernTrainingApproach_OnlineTrainingOfferings: "Oferte de formare online (WBT)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "În ziua de azi, învățarea individuală la computer este parte integrantă a transferului de cunoștințe. Libertatea de a alege timpul și locul, repetabilitate nelimitată de viteza individuală de învățare și conținutul de învățare interactiv face din această metodă un instrument de studiu indispensabil.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Fiecare element Web-Based Training (WBT) a fost atent structurat. Pe lângă faptul că oferă o bază largă de cunoștințe, aceste cursuri pot servi ca pregătire eficientă pentru programele de formare din centrele de școlarizare Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Simularea unei diagnosticări virtuale oferă utilizatorului posibilitatea exersării diferitelor situații din atelier, precum procedura necesară utilizării unui tester de diagnosticare Bosch.",
	ModernTrainingApproach_Advantages: "Avantaje",
	ModernTrainingApproach_AdvantagesP1: "O metodă rapidă și eficientă de a furniza cunoștințe",
	ModernTrainingApproach_AdvantagesP2: "Învățare diversificată",
	ModernTrainingApproach_AdvantagesP3: "Poate fi folosit ori de câte ori se dorește",
	ModernTrainingApproach_AdvantagesP4: "Cost de timp si financiar minim",
	ModernTrainingApproach_AdvantagesP5: "Viteza individuală de învățare",
	ModernTrainingApproach_AdvantagesP6: "Program de studiu flexibil",
	ModernTrainingApproach_AdvantagesP7: "Repetabilitate",
	ModernTrainingApproach_AdvantagesP8: "Fără costuri de deplasare",
	ModernTrainingApproach_AdvantagesP9: "Fără conflicte de capacitate sau de programare",
	Dashboard_CoursesViewAndManageYourCourses: "CURSURI | Vizualizați și gestionați cursurile dvs",
	Dashboard_MyCourses: "Cursurile mele",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM DE FORMARE | Vizualizați programele dvs. de școlarizare",
	Dashboard_MyTrainingProgram: "Programa mea de training",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "ONLINE | Vizualizați și gestionați portofoliul dvs. de școlarizări online",
	Dashboard_MyELearning: "Cursurile mele online",
	Dashboard_AccountManageYourAccount: "CONT | Administreză contul",
	Dashboard_MyAccount: "Contul meu",
	Dashboard_MyDashboard: "Privire de ansamblu",
	Dashboard_ManageYourAccount: "Gestionați contul dvs.",
	Dashboard_Workshops: "Atelier",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRARE | Gestionați atelierul",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRARE | Gestionați echipele",
	Dashboard_Administration: "Administrare",
	Dashboard_ViewYourCourses: "Vizualizați cursurile dvs.",
	Dashboard_ViewYourTrainingPrograms: "Vizualizați programele dvs de training",
	Dashboard_TrainingPrograms: "Programe de training",
	Dashboard_ViewAndEditYourDetails: "Vizualizați și editați detaliile dvs",
	Dashboard_ViewYourELearningCourses: "Vizualizați cursurile dvs. online",
	Dashboard_Pending: "În așteptare",
	Dashboard_RequiresAdministratorSignOff: "(necesită semnarea administratorului)",
	Dashboard_HistoricalRecords: "Historical Records",
	Dashboard_Registered: "Înscris",
	Dashboard_WaitingList: "Listă de așteptare",
	Dashboard_RecommendedForYou: "Recomandat pentru dvs.",
	Dashboard_AccountDetails: "Detalii cont",
	Dashboard_ChangeProfileImage: "schimbă imaginea de profil",
	Dashboard_Title: "Titlu",
	Dashboard_Role: "Rol",
	Dashboard_FirstName: "Prenume",
	Dashboard_LastName: "Nume",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobil",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Limbă",
	Dashboard_Save: "Salvare",
	Dashboard_Student: "Student",
	Dashboard_WorkshopTrainingAdministrator: "Workshop Training Administrator",
	Dashboard_WholesalerTrainingAdministrator: "Wholesaler Training Administrator",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administrare companie",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Gestionați atelierul...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Gestionați angajații...",
	Dashboard_CompanyAdministration_Workshop: "Atelier",
	Dashboard_CompanyAdministration_Address: "Adresă",
	Dashboard_CompanyAdministration_Zip: "Cod poștal",
	Dashboard_CompanyAdministration_City: "Oraș",
	Dashboard_CompanyAdministration_Manage: "Administra",
	Dashboard_AdministrationSection_AdministrationTitle: "Administrare",
	Dashboard_AdministrationSection_ManageTheTeam: "Gestionați echipa",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRARE | Gestionați angajații",
	Dashboard_AdministrationSection_Employees: "Angajați",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRARE | Gestionați distribuitorii",
	Dashboard_AdministrationSection_Wholesalers: "Distribuitor",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRARE | Gestionați tranzacțiile",
	Dashboard_AdministrationSection_Transactions: "Tranzacții",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administrare angajați",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Gestionare angajați...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Înscriere",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Înregistrări",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Editare",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Angajat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Adăugare",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Creere",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Înscris",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "În așteptare",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(necesită semnarea administratorului)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historical Records",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Listă de așteptare",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Lista angajaților actuali",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Managementul angajaților",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nume",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Cont angajat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Vizualizare și editare detalii angajați...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Setări",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Permite conturi personale",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Editati persoana de contact în lipsa managerului de atelier",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Permiteți angajaților să se înregistreze la școlarizări",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Trimiteți invitația prin e-mail",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Dezactivarea cont",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Salvare",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Invită",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Editare",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Managementul distribuitorului",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Gestionați distribuitorii dvs...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Lista cu distribuitori agreați",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nume",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Ștergere",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Creare",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Adaugare",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Creare cont angajat nou",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Adăugați detalii pentru noii angajați...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Adăugați un nou distribuitor agreat ca  administrator",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Adăugați detalii pentru noii distribuitori...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Alegeți un distribuitor agreat din meniul urmator...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Tranzacții",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Vizualizați și gestionați tranzacțiile dvs....",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Înapoi",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Numarul facturii",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Descriere",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Metodă de plată",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Dată",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Cantitate",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Descărcare",
	TrainingCentres_TrainingCentres: "Centru de școlarizare",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Centrele noastre de școlarizare sunt situate pe tot globul",
	TrainingCentres_Country: "Țară",
	TrainingCentres_City: "Oraș",
	TrainingCatalogue_TrainingCatalogue: "Catalog cu programul de școlarizari",
	TrainingCatalogue_LearningAtTheHighestLevel: "Învățare la cel mai înalt nivel",
	TrainingCatalogue_StartDate: "Dată de începere",
	TrainingCatalogue_EndDate: "Dată de terminare",
	TrainingCatalogue_TrainingName: "Nume curs",
	TrainingCatalogue_Language: "Limba",
	TrainingCatalogue_Days: "Zile",
	TrainingCatalogue_Seats: "Nr. de locuri",
	TrainingCatalogue_Price: "Pret",
	TrainingCatalogue_Location: "Locație",
	TrainingCatalogue_PartNumber: "Cod comandă",
	TrainingCatalogue_TrainingCourse: "Curs de școlarizare",
	TrainingCatalogue_Category: "Categorie",
	TrainingCatalogue_TrainingCategory: "Categorii de training",
	TrainingCatalogue_CourseName: "Program de training",
	TrainingCatalogue_TotalDays: "Nr. total de zile",
	TrainingCatalogue_Venue: "Locație",
	TrainingCatalogue_Details: "Detaliile companiei",
	TrainingCatalogue_Prerequisites: "Pre-condiții",
	TrainingCatalogue_CourseType: "Tip de curs",
	TrainingCatalogue_LearningMethod: "Metoda de învățare",
	TrainingCatalogue_AddToWaitingList: "Adăugați pe lista de așteptare",
	ShoppingCart_ShoppingCart: "Coș de cumpărături",
	ShoppingCart_ReviewAndManage: "Vizualizați și gestionați abonamentele",
	ShoppingCart_YouHaveAddedItem: "Ați adăugat următoarele articole în coș",
	ShoppingCart_Item: "Articol",
	ShoppingCart_Quantity: "Cantitate",
	ShoppingCart_PricePerItem: "Preț pe articol",
	ShoppingCart_TotalPrice: "Preț total",
	ShoppingCart_VatIsIncluded: "Fără TVA",
	ShoppingCart_Redeem: "Dacă nu aveți credit, faceți clic aici pentru a-l valorifica",
	ShoppingCart_Purchase: "Dacă nu aveți credit, faceți clic aici pentru a cumpăra",
	ShoppingCart_Checkout: "Verifică",
	ShoppingCart_ContinueShopping: "Continuă cumpăraturile",
	ShoppingCart_TotalPriceInclVat: "Preț total cu TVA inclus",
	ShoppingCart_Apply: "Aplicare",
	ShoppingCart_ShoppingCartEmpty: "Coșul de cumpăraturi este gol",
	ShoppingCart_VatIncluded: "TVA",
	ShoppingCart_ManageYourShopping: "Gestionare cumpărături",
	ShoppingCart_Participant: "Participant",
	ShoppingCart_Complete: "Efectuat",
	ShoppingCart_NetPrice: "Preț net",
	ShoppingCart_Workshop: "Atelier",
	CheckOut_PreviewYourOrder: "Previzualizare comandă",
	CheckOut_YourShoppingCart: "Coșul meu",
	CheckOut_Preview: "Previzualizare",
	CheckOut_Payment: "Plată",
	CheckOut_Summary: "Rezumat",
	CheckOut_Back: "Înapoi",
	CheckOut_PleaseReadAndAgreeOurTerms: "Vă rugăm să citiți și să fiți de acord cu termenii și condițiile noastre",
	CheckOut_IHaveReadTheTermsAndCondition: "Am citit [termenii și condițiile], le înțeleg și le accept",
	CheckOut_YesIAgree: "Da - sunt de acord cu termenii și condițiile",
	CheckOut_Continue: "Continuare",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Felicitări! Am primit comanda dvs",
	CheckOut_OrderConfirmation: "Veți primi confirmarea finală a comenzii după ce solicitarea dvs. va fi procesată cu succes",
	CheckOut_WeWillContactYouDirectly: "Vă vom contacta direct în cazul în care avem întrebări legate de solicitarea dumneavoastră",
	CheckOut_AddToCart: "Adaugă în coș",
	CheckOut_CreditCart: "Card de credit",
	CheckOut_DirectDebit: "Plata directa",
	CheckOut_TrainingCredits: "Credite de training",
	CheckOut_PreferredWholesaler: "Distribuitor preferat",
	EventCalendar_EventCalendar: "Calendar de evenimente",
	EventCalendar_TrainingEventsForAllYourNeeds: "Cursuri pentru toate nevoile tale",
	TrainingCourse_TrainingCourse: "Catalog cu programul de școlarizari",
	TrainingCourse_LearningAtTheHighestLevel: "Învățare la cel mai înalt nivel",
	TrainingProgram_Title: "Catalog cu programa de școlarizari",
	TrainingProgram_SubTitle: "Învățare la cel mai înalt nivel",
	AccountDeactivationModal_Title: "Sunteți sigur că doriți dezactivarea contului?",
	AccountDeactivationModal_Line1: "Dezactivarea contului este permanentă și va elimina toate informațiile, inclusiv achizițiile de servicii, soldurile membrilor echipei și setările profilului.",
	AccountDeactivationModal_Line2Part1: "Dacă sunteți înscris la cursuri, vă rugăm să consultați pagina noastră cu politica anulare",
	AccountDeactivationModal_Line2Part2: "termeni și condiții",
	AccountDeactivationModal_Line2Part3: "înainte de trimeterea cererii",
	AccountDeactivationModal_Line3: "Sunteți sigur că doriți să trimiteți cererea de dezactivare a contului?",
	AccountDeactivationModal_Cancel: "Anulează",
	AccountDeactivationModal_Send: "Trimite",
	CurrentUserAccountDeactivationModal_Title: "Sunteți sigur că doriți dezactivarea contului?",
	CurrentUserAccountDeactivationModal_Line1: "Dezactivarea contului este permanentă și va elimina toate informațiile, inclusiv achizițiile de servicii, soldurile membrilor echipei și setările profilului.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Dacă sunteți înscris la cursuri, vă rugăm să consultați pagina noastră cu politica anulare",
	CurrentUserAccountDeactivationModal_Line3: "Sigur doriți să trimiteți cererea pentru dezactivarea contului?",
	CurrentUserAccountDeactivationModal_Yes: "Da",
	CurrentUserAccountDeactivationModal_No: "Nu",
	CourseWithdrawalModal_Title: "Anularea cursului",
	CourseWithdrawalModal_Line1: "Sunteți sigur că doriți să vă retrageți de la curs?",
	CourseWithdrawalModal_Line2Part1: "Retragerile sunt supuse unor taxe de anulare și vor fi taxate conform",
	CourseWithdrawalModal_Line2Part2Link: "termenilor și condițiilor",
	CourseWithdrawalModal_Line2Part3: "serviciilor noastre.",
	CourseWithdrawalModal_Yes: "Da, mă retrag",
	CourseWithdrawalModal_No: "Nu, anulează",
	Settings_Settings: "Setări",
	Settings_ManageYourSettings: "Gestionați setările",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Informațiile mele de conectare Bosch Global ID",
	Settings_Edit: "Editare",
	Settings_CompanyDetails: "Detaliile companiei",
	Settings_RequestAccountDeletion: "Solicitați ștergerea contului",
	Settings_AccountDeletionText: "Dacă ștergeți contul, toate datele dumneavoastră (inclusiv adresa și datele de plată) vor fi șterse și nu vor mai fi disponibile.",
	Settings_DeleteAccountRequestConfirmation: "Anulati solicitarea de ștergere a contului",
	Settings_DeleteAccountRequestConfirmationText: "Am primit cererea dvs. de ștergere a contului și vă vom contacta prin e-mail sau telefon pentru confirmarea finală",
	RecommendedHotels_RecommendedHotels: "Hoteluri recomandate",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Centrele noastre de școlarizare sunt situate pe tot globul",
	Error_Common: "Ceva n-a mers bine!",
	Error_404: "Pagina nu există",
	Error_401: "Nu aveți acces la această pagină",
	Error_500: "Serverul raportează o eroare",
	Dashboard_Courses_Table_Course: "Curs",
	Dashboard_Courses_Table_Date: "Data [zile]",
	Dashboard_Courses_Table_Venue: "Locație",
	Dashboard_Courses_Table_TrainingType: "Tip training",
	Dashboard_Courses_Table_Action: "Actiune",
	Dashboard_Courses_Table_TasksToComplete: "Aveți următoarele sarcini de finalizat",
	Dashboard_Courses_Table_Withdraw: "Retragere",
	Dashboard_Courses_Table_Remove: "Înlaturare",
	Dashboard_Courses_Table_View: "Vedere",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Nu există inregistrări de afișat",
	Dashboard_Courses_Table_CompletionDate: "Data finalizării",
	Dashboard_Courses_Table_Actions_Assignments: "Atribuții",
	Dashboard_Courses_Table_Actions_Certification: "Certificări",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluări",
	Dashboard_Courses_Table_Actions_Assessments: "Evaluări",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Adauga în calendar",
	Dashboard_Courses_Table_CourseDetails_Task: "Sarcină",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Pâna la data",
	ContactUs_Headline: "Contactează-ne",
	ContactUs_HowCanWeHelp: "Cu ce vă putem ajuta?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Scrisoare",
	ContactUs_Phone_Headline: "Sună-ne!",
	ContactUs_Phone_Description: "Așteptăm cu nerăbdare apelul dvs. și vom fi bucuroși să vă ajutăm. Vă stăm la dispoziție de luni până vineri, între orele 09:00 - 17:00.",
	ContactUs_Phone_PhoneNumber: "Vă rugăm să folosiți formularul de contact pentru a ne trimite un mesaj.",
	ContactUs_Phone_ChargeNotice: "Pot exista costuri suplimentare în funcție de furnizorul dvs. de servicii și de țară.",
	ContactUs_Letter_Headline: "Trimite o scrisoare!",
	ContactUs_Letter_Description: "Aveți întrebări, solicitări sau sugestii? Așteptăm cu nerăbdare solicitarea dvs:",
	ContactUs_Email_Headline: "Trimite un email!",
	ContactUs_Email_Description: "Trimite-ne mesajul tău completând formularul de mai jos. Unul dintre colegii noștri te ca contaca bucuros.",
	ContactUs_Email_InputName: "Nume*",
	ContactUs_Email_InputCompany: "Companie",
	ContactUs_Email_InputCountry: "Țară*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Mesaj*",
	ContactUs_Email_DataProtection: "Notă privind protecția datelor*",
	ContactUs_Email_DataProtectionConfirm: "Am citit Politica de confidențialitate.*",
	ContactUs_Email_SecurityCheck: "Verificare de securitate",
	ContactUs_Email_SecurityCodeConfirm: "Vă rugăm să introduceți caracterele corecte pe care le vedeți în caseta de mai sus.",
	ContactUs_Email_SecurityCodeInvalid: "Răspunsul textului captcha pe care l-ați introdus este incorect.",
	ContactUs_Email_MandatoryNotice: "Vă rugăm să completați câmpurile obligatorii.",
	ContactUs_Email_SuccessMessage: "Mesajul dvs. a fost trimis",
	ContactUs_Email_TakenNoteDataProtection1: "Am citit",
	ContactUs_Email_TakenNoteDataProtection2: "Politica de confidențialitate.*",
	ContactUs_Email_SubmitButton: "Trimite",
	ContactUs_Email_FieldErrorMessage: "Vă rugăm să completați câmpurile obligatorii.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Contul dvs. este în prezent în curs de revizuire!",
	AccountInReviewModal_Paragraph1: "Vă mulțumim că ați înregistrat un cont personal pe Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Personalul nostru dedicat de instruire examinează și configurează în prezent contul dvs. Veți primi confirmarea finală prin e-mail, odată ce contul dvs. a fost configurat cu succes.",
	AccountInReviewModal_Paragraph3: "Între timp, vă rugăm să nu ezitați să navigați pe site-ul nostru, dar cu acces limitat.",
	AccountInReviewModal_Paragraph4: "Dacă aveți întrebări sau dacă contul dvs. nu este procesat în 48 de ore, cu excepția weekendurilor și sărbătorilor, ne puteți contacta",
	AccountInReviewModal_ContactUsLink: "aici",
	AccountInReviewModal_OKButton: "OK, lasă-mă să intru!",
	Header_YesButton: "Da",
	Header_NoButton: "Nu",
	Header_LogOutText: "Doriți să vă deconectați?",
	Footer_EnquiryText: "Așteptăm cu interes întrebarea dvs.,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Termeni și condiții",
	Footer_HowOurTrainingEvolves: "Cum decurge scolarizarea noastră",
	FooterSearch_SearchFieldPlaceholder: "Caută",
	TrainingCatalogue_Action: "Acțiune",
	TrainingCatalogue_Duration: "Durată",
	TrainingCatalogue_Information: "Informații",
	Settings_PersonalDetails: "Detalii personale",
	Reset_All_Filters: "Resetează toate filtrele",
	EventTable_Days: "Zile",
	SearchField_AllResults: "Toate rezultatele",
	TrainingCentresLayout_ViewDetails: "Vezi detalii",
	Dropdown_Placeholder: "Alege...",
	WaitlistPopup_SelectButtonText: "Selecteză",
	WaitlistPopup_StartDate: "Dată începere",
	WaitlistPopup_EndDate: "Dată încheiere",
	WaitlistPopup_Language: "Limbă",
	WaitlistPopup_City: "Oraș",
	WaitlistPopup_Workshop: "Atelier",
	WaitlistPopup_Student: "Participant",
	WaitlistPopup_PreferredLocation: "Locație preferată",
	WaitlistPopup_SignUpCourseWaitingList: "Înscrieți-vă pe lista de așteptare",
	WaitlistPopup_SignUpWithoutSelectingDate: "Înscrieți-vă pe lista de așteptare fără a selecta o dată",
	WaitlistPopup_GeneralWaitList: "Lista de așteptare generală",
	WatinglistPopup_CourseLabel: "Curs",
	Common_ContinueButton: "Continuă",
	UserAccountDeactivationModal_ConfirmLabel: "Confirmă",
	Filter_FilterLabel: "Filtru",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Sunteți deja pe lista de așteptare. Doriți să eliminați înregistrarea existentă și să faceți una nouă?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Da, elimina",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nu, anulează",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Anulare curs",
	WaitinglistPopup_SignUpButtonText: "Inscrie-te",
	MyAccount_FirstNameRequied: "Este necesar prenumele",
	MyAccount_LastNameRequired: "Este necesar numele de familie",
	MyAccount_RoleRequired: "Este necesar rolul",
	MyAccount_MobileRequired: "Este necesar numarul de telefon",
	MyAccount_EmailRequired: "Este necesar adresa de email",
	MyAccount_LanguageRequired: "Este necesar limba",
	MyAccount_UpdateSuccessMessage: "Detaliile contului dvs. au fost actualizate cu succes!",
	MyAccount_UpdateSuccessModal_ButtonText: "Da, închide",
	Infor_CorporateInformation_Header: "Informații despre companie",
	Infor_LegalNotice_Header: "informații legale",
	Infor_PrivacyPolicy_Header: "Notificare privind protecția datelor (politica de confidențialitate)",
	CreateEmployeeAccountModification_FormValidation_Role: "Este necesar rolul",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Este necesar prenumele",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Este necesar numele de familie",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Este necesar numarul de telefon",
	CreateEmployeeAccountModification_FormValidation_Email: "Este necesar adresa de email",
	CreateEmployeeAccountModification_FormValidation_Language: "Este necesar limba",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitație de a vă înscrie la Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Ai adăugat cu succes un nou membru în echipa ta!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Doriți să trimitem o invitație prin e-mail noului membru al echipei, cu instrucțiuni despre cum își pot crea propriul cont personal pentru Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nu",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Da, trimite email",
	ShoppingCart_Venue_Fee: "Taxa de locație",
	ShoppingCart_Trainning_Name: "Nume training",
	ShoppingCart_Available_Credits: "Credite disponibile",
	ConfirmItsMeModal_Hi: "Bună",
	ConfirmItsMeModal_Description1: "Ați finalizat cu succes invitația trimisă de administratorul companiei dvs. de a vă crea propriul cont personal pe Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Înainte de a continua, confirmați sau actualizați detaliile contului.",
	ConfirmItsMeModal_Description3: "După confirmare, vi se va cere să vă autentificați din nou pentru a finaliza crearea contului.",
	ConfirmItsMeModal_CompanyDetails: "Detaliile companiei",
	ConfirmItsMeModal_YourDetails: "Detaliile dvs.",
	ConfirmItsMeModal_YourDetails_Title: "Titlu",
	ConfirmItsMeModal_YourDetails_Function: "Funcție",
	ConfirmItsMeModal_YourDetails_Name: "Nume",
	ConfirmItsMeModal_YourDetails_Mobile: "Telefon mobil",
	ConfirmItsMeModal_YourDetails_Email: "Email",
	ConfirmItsMeModal_YourDetails_Language: "Limbă",
	ConfirmItsMeModal_ConfirmButton: "Confirmă",
	SelectWholesalerModal_WholesalerGroupLabel: "Distribuitor",
	SelectWholesalerModal_WholesalerBranchLabel: "Filială",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Vă rugăm selectați",
	ApplyButton: "Aplică",
	SelectWholesalerModal_Title: "Alege distribuitorul",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Doriți să vă ștergeți înregistrarea?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Înregistrarea dvs. a fost ștearsă cu succes!",
	WaitlistPopup_AddToWaitListSuccess: "Înregistrarea dvs. a fost adăugată cu succes!",
	ErrorCommon_Header: "EROARE",
	CommonButton_Close: "Închide",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Vărugăm să închideți fereastra pentru a continua",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Reîncărcare",
	DeactivateWholesalerModal_ConfirmMessage: "Sunteți sigur că doriți sa stergeăi acest distribuitor?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Trimitere cu succes a e-mailul de invitație!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Invită din nou",
	ContactUs_Mobile_RO: "+40 214057606",
	ContactUs_Address_RO: "Robert Bosch S.R.L.,Str. Horia Macelariu nr. 30-34,013937 Bucuresti, Romania",
	ConfirmItsMeModal_CompanyDetails_Name: "Nume companie",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresă",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Cod poștal",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Retragere de la curs efectuată!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Crearea contului a eșuat. Contul deja există!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Actualizarea contului a eșuat. Contul deja există!",
	Footer_ExploreTrainingStories: "Explorați toate postările noastre despre cursuri",
	Breadcrumb_Home: "Acasă",
	Breadcrumb_Contact: "Contact",
	Breadcrumb_Stories: "Postări",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "O zi din viața unui trainer",
	Breadcrumb_Stories_BoschTrainingBenefits: "Beneficiile cursurilor Bosch",
	Breadcrumb_Stories_TrainingApproach: "O abordare modernă a cursurilor",
	Breadcrumb_Settings: "Setări",
	Breadcrumb_ShoppingCart: "Coș de cumpărături",
	Breadcrumb_Checkout: "Verifică",
	Breadcrumb_OrderSummary: "Sumar comandă",
	Breadcrumb_Exception: "Excepție",
	Breadcrumb_Dashboard: "Panou de control",
	Breadcrumb_MyCourses: "Cursurile mele",
	Breadcrumb_MyELearning: "Cursurile mele online",
	Breadcrumb_CompanyAdministration: "Administrare companie",
	Breadcrumb_EmployeeManagement: "Managementul angajaților",
	Breadcrumb_EmployeeAccount: "Cont angajat",
	Breadcrumb_EmployeeCreation: "Creare cont angajat",
	Breadcrumb_Administration: "Administrare",
	Breadcrumb_EmployeeAdministration: "Administrare cont angajat",
	Breadcrumb_WholesalerManagement: "Management distribuitor",
	Breadcrumb_NewWholesalerAccountCreation: "Crearea unui nou cont de distribuitor",
	Breadcrumb_Transactions: "Tranzacții",
	Breadcrumb_MyAccount: "Contul meu",
	Breadcrumb_TrainingCentres: "Centre de training",
	Breadcrumb_EventCalendar: "Calendar de evenimente",
	Breadcrumb_TrainingCourseCatalogue: "Catalog cu programul de școlarizari",
	Breadcrumb_RecommendedHotels: "Hoteluri recomandate",
	Breadcrumb_CorporateInformation: "Informații companie",
	Breadcrumb_LegalNotice: "Informaţii legale",
	Breadcrumb_DataProtectionNotice: "Notificare privind protectia datelor",
	Breadcrumb_PrivacyPolicy: "Politică de confidențialitate",
	Breadcrumb_TermCondition: "Termeni și condiții",
	Breadcrumb_Terms: "Termeni",
	Breadcrumb_PrivacyStatement: "Declarație de confidențialitate",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Furnizor",
	Breadcrumb_RegisterAccount: "Înregistrare Cont",
	Stories_ExploreMore: "Explorează",
	CheckOutLayout_ShoppingCartHeader: "Coș de cumpărături",
	CheckOutLayout_AddedItemCartMessage: "Ați adăugat următoarele în coș",
	CheckOutLayout_CheckYourCartMessage: "Verifică coșul de cumpăraturi",
	ModernTrainingApproach_DateText: "1/10/2021 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "9/29/2021",
	BoschTrainingBenefits_DateText: "9/30/2021",
	BoschServiceTraining_DateText: "9/28/2021",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Caută angajat",
	CheckOutLayout_OrderWasNotPlaced_Message: "Ne pare rău! Comanda dvs. nu a fost transmisă",
	CheckOutLayout_RefusalReason: "Motiv de refuz",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Plată eșuată. Vă rugăm să reîncercați cu o altă metodă de plată",
	CheckOutLayout_SubmittingApiFailed_Message: "Trimiterea API a eșuat",
	CartItem_ChooseWorkshop_Message: "Vă rugăm să alegeți atelierul pentru a alege un participant",
	CartItem_ChooseParticipant_Message: "Vă rugăm să alegeți un participant pentru a continua",
	CompanyAdministrationTable_SearchBox_Placeholder: "Caută atelier",
	CartItem_RemoveCartItem_ConfirmText: "Doriți să eliminați acest articol din coș?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funcție",
	AddToCartSuccessModal_Message: "Coșul de cumpărături a fost actualizat",
	AddToCartSuccessModal_ContinueButtonText: "Continuă cumpărăturile",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Coșul meu de cumpărături",
	AddVoucher_Header: "Adaugă voucher",
	AddVoucher_InputPlaceholder: "Introdu codul și aplică",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Din păcate, momentan nu avem niciun eveniment programat pentru acest curs de formare. Vă rugăm să vă înscrieți pe lista de așteptare și vă vom informa odată ce un eveniment devine disponibil.",
	ADYEN_ERR_2_: "Tranzactie refuzată",
	ADYEN_ERR_3_: "Eroare referințe",
	ADYEN_ERR_4_: "Tranzacția nu a avut loc din cauza unei erori.",
	ADYEN_ERR_5_: "Card blocat. Prin urmare, este inutilizabil.",
	ADYEN_ERR_6_: "Card expirat. Prin urmare, este inutilizabil.",
	ADYEN_ERR_7_: "În timpul tranzacției a aparut o eroare de nepotrivire a sumei.",
	ADYEN_ERR_8_: "Număr card incorect sau invalid.",
	ADYEN_ERR_9_: "Nu este posibilă contactarea bancii cumpărătorului pentru a autoriza tranzacția.",
	ADYEN_ERR_10_: "Banca cumpărătorului nu acceptă sau nu permite acest tip de tranzacție.",
	ADYEN_ERR_11_: "Autentificarea 3D Secure nu a fost executată sau nu s-a executat cu succes.",
	ADYEN_ERR_12_: "Fonduri insuficiente, sau valoarea introdusa este mai mare decât limita cardului. Vă rugăm să încercați să utilizați un alt card, sau să contactați banca pentru a reseta limita cardului.",
	ADYEN_ERR_14_: "Fraudă posibilă.",
	ADYEN_ERR_15_: "Tranzacția a fost anulată.",
	ADYEN_ERR_16_: "Cumpăratorul a anulat tranzactia înaintea finalizării acesteia.",
	ADYEN_ERR_17_: "PIN invalid/incorect.",
	ADYEN_ERR_18_: "Pinul utilizat a fost introdus gresit de mai mult de trei ori la rând",
	ADYEN_ERR_19_: "Nu este posibilă verificarea codului PIN",
	ADYEN_ERR_20_: "Fraudă posibilă.",
	ADYEN_ERR_21_: "Tranzacția nu a fost trimisă corect pentru procesare.",
	ADYEN_ERR_22_: "Sistemul de verificare a riscului a marcat tranzacția ca fiind frauduloasă (scor de risc >= 100); operațiune anulată.",
	ADYEN_ERR_23_: "Tranzacție refuzată: Tranzacția nu este permisă emitentului/titularului de card",
	ADYEN_ERR_24_: "Cod CVC invalid",
	ADYEN_ERR_25_: "Tranzacție refuzată: Card invalid in aceasă țară.",
	ADYEN_ERR_26_: "R1: Ordin de revocare a autorizației / ''R3: Ordin de revocare a tuturor autorizațiilor” / „R0: Ordin de oprire a plății”",
	ADYEN_ERR_27_: "Acest răspuns generalizează toate acele coduri de răspuns care nu pot fi mapate în mod fiabil. Acest lucru face mai ușor să distingeți refuzurile generice (de exemplu, răspunsul Mastercard „05: Nu onorați”) de cele mai specifice.",
	ADYEN_ERR_28_: "Fonduri insuficiente.",
	ADYEN_ERR_29_: "Numarul de retrageri de pe card a fost depășit.",
	ADYEN_ERR_31_: "Emitentul a raportat tranzacția ca fiind suspectă de fraudă.",
	ADYEN_ERR_32_: "Datele de contact introduse de cumpărător sunt incorecte.",
	ADYEN_ERR_33_: "Banca solicită cumpărătorului să introducă un PIN online.",
	ADYEN_ERR_34_: "Banca solicită verificarea contului curent pentru a finaliza achiziția.",
	ADYEN_ERR_35_: "Banca solicita un cont de economii pentru a finaliza achiziția.",
	ADYEN_ERR_36_: "Banca solicită cumpărătorului să introducă un cod PIN mobil.",
	ADYEN_ERR_37_: "Cumpărătorul a abandonat tranzacția după ce a încercat o plată contactless și a fost solicitat să încerce o altă metodă de introducere a cardului (PIN sau glisare).",
	ADYEN_ERR_38_: "Emitentul cardului a refuzat cererea de scutire de autentificare și solicită autentificarea pentru finalizarea tranzacției. Reîncercați cu 3D Secure.",
	ADYEN_ERR_39_: "Emitentul cardului sau schema nu a putut comunica rezultatul prin RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Prenumele nu trebuie să includă caractere speciale (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Prenumele trebuie să aibe mai puțin de 75 de caractere",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Numele nu trebuie să includă caractere speciale (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Numele trebuie să aibe mai puțin de 75 de caractere",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Numarul de telefon mobil nu trebuie să includă caractere speciale (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Numarul de telefon mobil trebuie să aibe mai puțin de 45 de caractere",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Numarul de telefon nu trebuie să includă caractere speciale (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Numarul de telefon trebuie să aibe mai puțin de 45 de caractere",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Adresa de email invalidă",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Adesa de email",
	ConfirmGeolocationModal_ConfirmButton: "Continua",
	ConfirmGeolocationModal_CancelButton: "Schimbare",
	ConfirmGeolocationModal_CurrentCountrySelection: "Țara dvs. actuală este:",
	ConfirmGeolocationModal_AskChangeCountry: "Doriți să rămâneți pe acest site sau să vă schimbați preferința de țară?",
	Addtocart_Modal_AddToCartFailTitle: "Adăugarea unui participant nu a reușit",
	Addtocart_Modal_AlreadyInCart: "Participantul s-a înregistrat deja la acest eveniment",
	TrainingCatalogue_DateRange: "Interval de date",
	TrainingCatalogue_DateRange_placeholder: "Selectați filtrul de interval de date",
	Dashboard_MyPendingCourses: "Cursurile mele în așteptare",
	Dashboard_MyWaitingList: "Lista mea de așteptare",
	Dashboard_LatestNews: "Ultimele știri",
	SettingsMainPage_EditAccountButton: "Editează contul",
	SettingsMainPage_EditBoschId: "Editeaza contul Bosch ID",
	EventInfoView_EventDetailTab: "Detalii eveniment",
	EventInfoView_AssignmentsTab: "Sarcini",
	EventInfoView_EvaluationTab: "Evaluare",
	EventInfoView_AttachmentTab: "Atașamente",
	EventInfoView_CourseDescriptionTab: "Descriere",
	EventInfoView_DescriptionTab_General: "General",
	EventInfoView_DescriptionTab_PartLabel: "Parte",
	AssignmentTab_Description: "Aici veți găsi toate informațiile de care aveți nevoie pentru a finaliza acest curs.",
	AssignmentTab_Columns_Download: "Descărcare",
	AssignmentTab_Columns_Upload: "Încărcare",
	AssignmentTab_Columns_TaskComplete: "Sarcina finalizată",
	EventDetailTab_Location_Message_Video: "Acesta este un eveniment online care poate fi accesat utilizând linkul „Lansați videoclipul\”.",
	EventDetailTab_Location_Message_WebBased: "Acesta este un eveniment online care poate fi accesat utilizând linkul „Lansați videoclipul\”.",
	EventDetailTab_Location_Message_WebCast: "Acesta este un eveniment online care poate fi accesat utilizând linkul „Alătură-te acum\”.",
	EventDetailTab_Link_Video: "Lansați videoclipul",
	EventDetailTab_Link_Video_Remark: "*Lansarea videoclipului va deschide o nouă fereastră. Veți fi redirecționat înapoi la această pagină odată ce fereastra va fi închisă.",
	EventDetailTab_Link_WebBased: "Lanseaza WBT",
	EventDetailTab_Link_WebBased_Remark: "*Lansarea WBT va deschide o nouă fereastră. Veți fi redirecționat înapoi la această pagină odată ce fereastra va fi închisă.",
	EventDetailTab_Link_WebCast: "Alătură-te acum",
	EventDetailTab_Link_WebCast_Remark: "*Linkul Alătură-te acum va fi activ cu 30 de minute înainte de începerea evenimentului.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Durată (ore)",
	EventDetailTab_Title_Trainers: "Trainer",
	EventDetailTab_Title_Language: "Limbă",
	EventDetailTab_Title_Score: "Scor (%)",
	EventDetailTab_Title_Calendar: "Adaugă în calendar",
	EventDetailTab_Title_Certificate: "Descarcă certificat",
	EventDetailTab_Title_Messages: "Mesaje",
	EventDetailTab_Title_StartDate: "Dată de start",
	EventDetailTab_Title_AvailableFrom: "Disponibil din",
	EventDetailTab_Title_Time: "Timp",
	EventDetailTab_Title_EndDate: "Dată de inchidere",
	EventDetailTab_Title_ExpiresOn: "Expira la",
	EventDetailTab_Title_Room: "Camera",
	EventDetailTab_Title_Method: "Metodă",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Locație",
	AttachmentTab_EmptyScreenDescription: "Nu există atașamente pentru acest eveniment",
	AttachmentTab_Columns_Download: "Descarcare",
	AttachmentTab_Description: "Aici veți găsi câteva informații importante care vă vor ajuta cu acest curs",
	EvaluationTab_Annotation: "Apreciem feedback-ul dvs. și ne-ar plăcea să-l ob/inem de la dvs. pentru a vedea cum ne descurcăm. Vă rugăm să completați următoarea evaluare pentru a ne ajuta să îmbunătățim serviciile noastre. Mulțumim.",
	EvaluationTab_Notice: "Evaluările sunt după cum urmează",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Vă rugăm să răspundeți la toate întrebările obligatorii înainte de a trimite evaluarea",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Feedbackul dvs. a fost trimis cu succes",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Trimiterea feedbackul a eșuat",
	TrainingCatalogueLayout_ExportButtonText: "Exportă",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Evaluarea a fost finalizată sau a expirat.",
	EventDetailTab_Link_CopiedTooltip: "Copiat în Clipboard",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scanați pentru a lansa WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scanați pentru a lansa Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scanați pentru a lansa Webcast",
	Footer_TrainingCoure_Headline: "Cursuri oferite de către Bosch",
	Footer_TrainingCourse_Content: "În calitate de Superbrand de renume mondial și cu peste un secol de experiență în inovare, Bosch a stabilit standardele la nivel global ca furnizor de sisteme, piese și soluții tehnice auto. O mare parte din puterea noastră provine din parteneriatele noastre strânse cu producătorii de vehicule, pentru care proiectăm, dezvoltăm și producem sisteme și componente de ultimă generație în întreaga lume. În consecință, Bosch și-a consolidat o istorie puternică în domeniul produselor aftermarket și al asistenței auto cu un portofoliu divers de piese, soluții de diagnosticare și servicii de atelier. Învățarea și dezvoltarea sunt cheile sigure ale succesului, iar această filozofie este susținută de o gamă largă de cursuri de formare, în toate domeniile tehnologiei auto, sistemelor vehiculelor și expertizei de diagnosticare.",
	SearchField_NoRecordsFound: "Nu au fost găsite înregistrări",
	TrainingCatalogue_FreeTextSearch: "Text",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Caută",
	CheckoutLayout_WholeSalerGroup: "Alege distribuitorul",
	CreditInsufficientModal_Message: "Nu aveți credit suficient pentru utilizarea acestei metode de plată. Vă rugăm să încercați din nou cu o altă metodă de plată.",
	ShoppingCart_Required_Credits: "Pretul total",
	MaintainingInfo_Message1: "Site-ul este în modul de întreținere.",
	MaintainingInfo_Message2: "Vom reveni in curand.",
	CountryValidationModal_Message: "Nu v-ați putut autentifica în [CountryA] deoarece contul dvs. a fost creat în [CountryB].",
	CountryValidationModal_ButtonText: "Continuați către [Country]",
	EventTable_LoginRequired: "Autentificați-vă pentru a vă înscrie",
	TrainingPartTableLayout_Date: "Data",
	TrainingPartTableLayout_StartTime: "Dată de începere",
	TrainingPartTableLayout_EndTime: "Dată de închidere",
	TrainingPartTableLayout_Duration: "Durata (ore)",
	TrainingPartTableLayout_TrainingMethod: "Metoda de training",
	MyCoursesTableContents_ActiveHeader: "Cursuri active",
	MyCoursesTableContents_ActiveTabHeader: "Urmează",
	MyCoursesTableContents_ScheduledHeader: "Sumar cursuri",
	MyCoursesTableContents_ScheduledTabHeader: "Sumar",
	MyCoursesTableContents_OnlineHeader: "Cursuri online",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Cursuri anulate",
	MyCoursesTableContents_CancelledTabHeader: "Anulate",
	OnlineCoursesTable_Title_Progress: "Progres",
	ScheduledCoursesTable_Title_Date: "Dată incepere (zile)",
	CoursesTable_Title_Completed: "Complete",
	Footer_TrainingProgram: "Programe de training",
	Breadcrumb_TrainingProgram: "Programe de training",
	TrainingProgram_Title_Table: "Titlu",
	TrainingProgram_TrainingProgram: "Programe de training",
	TrainingProgram_TargetGroup: "Adresat către",
	TrainingProgram_MaxLength: "Lungime maxima",
	TrainingProgram_Information: "Informație",
	TrainingProgram_Detail: "Detalii",
	TrainingProgram_Modules: "Module",
	TrainingProgram_Course: "Cursuri",
	TrainingProgram_PartNumber: "Codul cursului",
	TrainingProgram_Max_Length: "Lungime maximă",
	TrainingProgram_Duration: "Durată",
	TrainingProgram_Exam: "Examinare",
	TrainingProgram_Assign_Popup: "Înregistrați-vă la programul de formare",
	TrainingProgram_Student: "Participant",
	TrainingProgram_Assign: "Atribuit",
	TrainingProgram_Popup_Remove_Title: "Anulare curs?",
	TrainingProgram_Description: "Descriere",
	TrainingProgram_Days: "Zile",
	TrainingProgram_Type: "Tip",
	TrainingProgram_Action: "Actiune",
	TrainingProgram_Enrolled: "a fost deja înscris la acest program de formare",
	TrainingProgram_SelfAssign_Enrolled: "Sunteți deja înscris la acest program de formare",
	TrainingProgram_Warning: "Vă rugăm să înregistrați un alt participant sau să selectați un alt program de formare.",
	TrainingProgram_Workshop: "Atelier",
	TrainingProgram_Durations: "ani",
	TrainingProgram_Duration_DaysLabel: "zile",
	TrainingProgram_HasExam_Yes: "Da",
	TrainingProgram_HasExam_No: "Nu",
	AssignModal_SuccessModal_Header: "Felicitari!",
	AssignModal_SuccessModal_Message: "a fost înscris cu succes la acest program de formare.",
	AssignModal_SelfAssign_SuccessModal_Message: "Ai fost înscris cu succes la programul de formare.",
	Dashboard_MyTraningProgram: "Programul meu de cursuri",
	Dashboard_MyTraningProgram_Title: "Titlu",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Vedere",
	Dashboard_MyTraningProgram_Register: "Înscriere",
	Dashboard_MyTraningProgram_Records: "Înregistrări istorice",
	Dashboard_MyTrainingProgram_In_Progress: "În curs de desfășurare",
	Dashboard_MyTrainingProgram_Accredited: "Acreditat",
	Dashboard_Program_Modal_Withdraw: "Retragere",
	Dashboard_Program_Modal_Grade: "Notă",
	Dashboard_MyTrainingProgram_Registered: "Înscris",
	Dashboard_MyTrainingProgram_Completedd: "Încheiat",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Da",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nu",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Vă rugăm să rețineți că retragerea înseamnă că vă veți retrage din programul complet de formare, nu doar dintr-un curs de formare individual.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Doriți totuși să vă retrageți din programul de formare?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "În așteptare",
	WithdrawProgramModal_Withdraw_Success_Message: "V-ați retras cu succes din programul de formare!",
	Common_Warning_Title: "Atenționare!",
	Common_Error_Title: "Ups, ceva nu a mers bine!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Adaugă în coș",
	AddEventFromCatalogToCartModal_HoursText: "ore",
	AddElearningToCartSuccessModal_Message: "a fost adăugat în coșul de cumpărături!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Participantul selectat a fost înregistrat la acest curs, sau cursul a fost deja adăugat în coș.",
	EventDetailTab_SubjectForChange_Message: "Subiect pentru modificare",
	Dashboard_MyTrainingProgram_Status_Failed: "Eșuat",
	Dashboard_MyTrainingProgram_Status_Passed: "Trecut",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distincţie",
	Dashboard_Employee_Courses: "Cursuri",
	Dashboard_Employee_Program: "Program de training",
	Dashboard_Employee_WaitList: "Listă de așteptare",
	Dashboard_Employee_Account: "Cont",
	Course_Price_Free: "Gratuit",
	Dashboard_Employee_Account_Update_Success_Message: "Ați încărcat cu succes datele pentru angajatul dumneavoastă!",
	Dashboard_Supplier: "Distribuitor",
	Event_Detail_Comment: "Nu exista nici un mesaj",
	DownLoad_PDF: "PDF Download",
	TrainingCatalogue_Currency: "Moneda de schimb",
	My_Wallet: "Portofelul meu",
	Wallet: "Portofel",
	Credits_Balance: "Soldul creditelor",
	MyFullCalendar_Today: "Astazi",
	Header_Available_Languages: "Limbi disponibile",
	Header_CurrentRegion: "Sunteți în prezent în",
	Header_ChangeRegions: "Vrei sa schimbi tara?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Autentificare/Înregistrare",
	Header_RegisterCourse: "Înregistrează-te la curs",
	Homepage_Start: "Începe",
	Homepage_Login: "Conectare",
	MainPage_Title: "Soluția finala a atelierului",
	MainPage_Content: "Atelierul tău, alegerea ta.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Permite atelierului modern să lucreze profesional, fiabil și eficient în cadrul procedurilor de diagnosticare, depanare, reparații și service pentru toate tipurile de vehicule.",
	BoschNews_TrainingNews: "Noutați training",
	Service_Assist_Workshop: "Îmbunătățiți-vă cunoștințele cu Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Ce pot face cu aplicația?",
	Enhance_Body_CDM3_Item_1: "Rezervați cursuri și mențineți-vă cunoștințele la zi",
	Enhance_Body_CDM3_Item_2: "Descărcați materiale și diplomele de curs",
	Enhance_Body_CDM3_Item_3: "Lansați cursul de eLearning",
	Enhance_Body_CDM3_Item_4: "Accesați diverse servicii Auto din cadrul aplicației mobile",
	Enhance_Body_CDM3_Item_5: "Transmisie video live cu Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Instrument gratuit pentru scanarea automată a vehiculelor",
	Training_Stories: "Povești din training",
	SettingsMyAccount_JoinCompanyButton: "Alăturați-vă companiei",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Alăturați-vă companiei",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Vă rugăm să introduceți codul de activare mai jos",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Notă! Trebuie să contactați administratorul companiei pentru a obține un cod de activare.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Activarea contului a fost procesată cu succes, iar contul tău este acum conectat la contul companiei.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Dați clic pe Continuați pentru a intra pe site.",
	ContinueButton: "Continuă",
	WelcomeBatsModal_Title: "Contul dvs. este în curs de analiză!",
	WelcomeBatsModal_ActivationLink: "Faceți clic aici dacă aveți un Cod de Activare pentru a vă alătura unei Companii!",
	WelcomeBatsModal_ActivationLinkNotes: "Notă! Trebuie să contactați administratorul Companiei pentru a obține un cod de activare.",
	AccountInReviewModal_Description_Paragraph1: "Vă mulțumim că ați înregistrat un cont personal pe Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Personalul nostru examinează și configurează în prezent contul dvs. Veți primi confirmarea finală prin e-mail, odată ce contul dvs. a fost configurat cu succes.",
	AccountInReviewModal_CloseButton: "Bine, lasă-mă să intru!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nu am reușit să vă validăm codul de activare și adresa de e-mail.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Vă rugăm să încercați din nou sau să contactați Administratorul Companiei pentru asistență.",
	TryAgain_Button: "Încearcă din nou",
	Error_Title: "Ups! Ceva nu a mers bine!",
	SettingsMyProfileActivationSuccessModal_Title: "Succes!",
	AccountInReviewModal_Description_Paragraph3: "Între timp, nu ezitați să navigați pe site-ul nostru cu acces limitat.",
	AccountInReviewModal_Description_Paragraph4: "Dacă aveți întrebări sau dacă contul dvs. nu este procesat în 48 de ore, cu excepția weekend-urilor și sărbătorilor, ne puteți contacta [aici]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Câteva îndrumări care să vă ajute...",
	Support_FAQ_Headline: "Întrebări uzuale",
	Support_FAQs_section1_title: "General",
	Support_FAQs_section1_question1: "Ce este Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions este o platformă care oferă o imagine de ansamblu amplă despre trainingurile tehnice și permite rezervarea și gestionarea trainingurilor dumneavoastră.",
	Support_FAQs_section1_question2: "Pot folosi contul meu Bosch existent (ID-ul singleKey) pentru a mă autentifica la Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Da, este accesibil cu toate conturile create cu „ID-ul meu Bosch”.",
	Support_FAQs_section1_question3: "Pot folosi un cont creat pentru Bosch Training Solutions cu alte servicii sau aplicații Bosch?",
	Support_FAQs_section1_question3_answer1: "Da, puteți utiliza ID-ul dvs. personal Bosch și pentru o varietate de alte aplicații Bosch - atât profesional, cât și privat, cum ar fi bicicleta electrică sau casa inteligentă.",
	Support_FAQs_section2_title: "Training",
	Support_FAQs_section2_question1: "Cum pot rezerva un curs?",
	Support_FAQs_section2_question1_answer1: "Conectați-vă cu contul dvs. existent SAU înregistrați un cont în portal. Accesați \"Oferta de cursuri Bosch\" SAU \"Calendarul de școlarizari\" selectați un antrenament și faceți clic pe butonul de adăugare la card.",
	Support_FAQs_section2_question2: "Nu este disponibilă o dată adecvată de formare. Ce pot face?",
	Support_FAQs_section2_question2_answer1: "Aveți posibilitatea să vă înscrieți pe o listă de așteptare „generală” (fără a selecta o dată) iar echipa noastră vă va informa imediat ce este disponibil un nou training.",
	Support_FAQs_section2_question3: "Unde pot găsi o prezentare generală a cursurilor rezervate?",
	Support_FAQs_section2_question3_answer1: "Puteți găsi o prezentare completă a tuturor cursurilor rezervate și finalizate după autentificare în Panoul de control",
	Support_FAQs_section3_title: "Setări",
	Support_FAQs_section3_question1: "Cum îmi pot schimba adresa de e-mail și parola?",
	Support_FAQs_section3_question1_answer1: "Trebuie să vă conectați la portal și să vă schimbați profilul personal. (În Panoul de control puteți găsi informațiile de profil sau dacă faceți clic pe următorul buton din antet)",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Acolo aveți posibilitatea de a modifica detaliile ID-ului dumneavoastră Bosch",
	Support_FAQs_section3_question2: "Ce pot face când mi-am uitat parola?",
	Support_FAQs_section3_question2_answer1: "Vă rugăm să utilizați butonul de autentificare/înregistrare și să completați adresa dvs. de e-mail",
	Support_FAQs_section3_question2_answer2: "Dacă dați clic pe Continuați, aveți posibilitatea să faceți clic pe „Parola uitată”",
	Support_FAQs_section3_question3: "Cum se configurează un cont de atelier?",
	Support_FAQs_section3_question3_answer1: "Dacă doriți să obțineți un rol de atelier, vă rugăm să contactați administratorul de traininguri local, de ex. puteti folosi formularul de contact de pe site.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Alegeți țara/regiunea dvs",
	MaintenanceBanner_Message_1: "Portalul Bosch Automotive Training Solutions va fi actualizat la [maintenance_schedule]. Actualizarea este de așteptat să dureze 30 de minute. Portalul nu va fi disponibil în timpul actualizării.",
	MaintenanceBanner_Message_2: "\nPentru asistență suplimentară, vă rugăm să ne contactați prin e-mail [support_email]",
	MaintenancePage_Message_1: "Portalul Bosch Automotive Training Solutions este în prezent în curs de actualizare",
	MaintenancePage_Message_2: "Ne așteptăm ca portalul să fie disponibil din nou pe [maintenance_online_date] la [maintenance_online_time]",
	MenuHeader_Support: "Suport",
	Course_Detail_No_Prerequisites: "Fără condiții prealabile",
	TableCell_Hours: "Ore",
	Dashboard_MenuItem_Dashboard: "Panou de control",
	Dashboard_MenuItem_Curricula: "Școlarizari viitoare",
	Dashboard_MenuItem_Certificates: "Certificate",
	Dashboard_MenuItem_Team: "Echipa mea",
	Dashboard_MenuItem_Workshops: "Ateliere",
	Dashboard_MenuItem_External: "Extern",
	Dashboard_MenuItem_Wallet: "Portofel",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Cursurile tale viitoare",
	Dashboard_Headline_YourELearningCourses: "Cursurile dvs. de eLearning",
	Dashboard_Headline_TeamOverview: "Prezentare generală a echipei",
	Dashboard_Headline_YourOverview: "Privire de ansamblu",
	Dashboard_Headline_YourWishlist: "Lista ta de dorințe",
	Dashboard_Headline_ActivePrograms: "Programe active",
	Dashboard_Headline_CompletedPrograms: "Programe finalizate",
	Dashboard_Headline_YourCertificates: "Certificatele dvs",
	Dashboard_Headline_Transactions: "Tranzacții",
	Dashboard_Table_NoRecordsToDisplay: "Nici o înregistrare de afișat",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "ore",
	Dashboard_ELearningCourses_TableHeader_Title: "Titlu",
	Dashboard_ELearningCourses_TableHeader_Duration: "Durată (ore)",
	Dashboard_ELearningCourses_ActionButton_View: "Vizualizare",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titlu",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Data [Days]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Locație",
	Dashboard_UpcomingCourses_ActionButton_View: "Vizualizare",
	Dashboard_YourOverview_TableHeader_Title: "Titlu",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Data/Durata",
	Dashboard_YourOverview_TableHeader_Location: "Locație",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Vizualizare",
	Dashboard_YourWishlist_TableHeader_Title: "Titlu",
	Dashboard_YourWishlist_TableHeader_Action: "Acțiune",
	Dashboard_YourWishlist_ViewAllRecords: "Vedeți toate înscrierile",
	Dashboard_TeamOverview_TableHeader_Employee: "Angajat",
	Dashboard_TeamOverview_TableHeader_Title: "Titlu",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Data/Durata",
	Dashboard_TeamOverview_TableHeader_Location: "Locație",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Vizualizare",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Căutare",
	Dashboard_ActiveProgram_TableHeader_Title: "Titlu",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Vizualizare",
	Dashboard_CompletedProgram_TableHeader_Title: "Titlu",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Vizualizare",
	Dashboard_Certificates_TableHeader_Title: "Titlu",
	Dashboard_Certificates_TableHeader_Date: "Dată",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Acțiune",
	Dashboard_Certificates_ActionButton_Download: "Descarcă",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Număr de factură",
	Dashboard_Transactions_TableHeader_Description: "Descriere",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Modalitate de plată",
	Dashboard_Transactions_TableHeader_Date: "Dată",
	Dashboard_Transactions_TableHeader_Amount: "Cantitate",
	Dashboard_Transactions_ActionButton_Download: "Descarcă",
	Breadcrumb_MyTraining: "Cursurile mele",
	Breadcrumb_MyTraining_CourseDetails: "Detalii Curs",
	Breadcrumb_MyTraining_Curricula: "Curricula",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detalii program de training",
	Breadcrumb_MyTraining_Team: "Echipă",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Vedeți angajatul",
	MyTraining_Headerbar_CourseDetails: "Detalii Curs",
	MyTraining_Headerbar_TrainingProgramDetails: "Detalii program de training",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Progres total",
	TrainingProgramDetails_ProgramItems_Headline: "Elementele programului",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titlu",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Nota",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Dată",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Vedere",
	TrainingProgramDetails_Status_InProgress: "În desfășurare",
	TrainingProgramDetails_Status_Completed: "Efectuat",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Durată",
	CourseDetails_Label_Trainer: "Trainer",
	CourseDetails_Label_Language: "Limbă",
	CourseDetails_Label_Score: "Scor:",
	CourseDetails_Label_StartDate: "Data de începere",
	CourseDetails_Label_EndDate: "Data de încheiere",
	CourseDetails_Label_Venue: "Locul de desfășurare",
	CourseDetails_Label_Address: "Adresă",
	CourseDetails_Label_Grade: "Nota",
	CourseDetails_Action_Withdraw: "Retragere",
	CourseDetails_Action_Evaluate: "Evaluează cursul",
	CourseDetails_Action_Evaluate_Description: "Vă rugăm să evaluați acest curs pentru a vă genera diploma!",
	CourseDetails_Action_DownloadCertificate: "Descărcați diploma",
	CourseDetails_Action_JoinWebcast: "Alăturați-vă Webcastului",
	CourseDetails_Action_StartCourse: "Începe cursul",
	CourseDetails_Action_RestartCourse: "Reporniți cursul",
	CourseDetails_Action_RepeatCourse_Description: "Vă rugăm să repetați și să parcurgeți acest curs pentru a vă descărca diploma!",
	CourseDetails_PartSpecificDetails_Headline: "Detalii specifice",
	CourseDetails_PartSpecificDetails_Tab_Part: "Parte",
	CourseDetails_PartSpecificDetails_Description: "Descriere",
	CourseDetails_PartSpecificDetails_StartDate: "Data de început",
	CourseDetails_PartSpecificDetails_EndDate: "Data de încheiere",
	CourseDetails_PartSpecificDetails_StartTime: "Ora de începere",
	CourseDetails_PartSpecificDetails_EndTime: "Ora de încheiere",
	CourseDetails_PartSpecificDetails_Method: "Metodă",
	Dashboard_Team_TableHeader_Name: "Nume",
	Dashboard_Team_TableHeader_Role: "Rol",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Cont personal",
	Dashboard_Team_ActionButton_Select: "Selectați",
	Dashboard_Team_PersonalAccount_Activated: "Activat",
	Dashboard_Team_PersonalAccount_NotActivated: "Neactivat",
	Dashboard_Team_ActionButton_AddMember: "Adăugați membru",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Căutare",
	Dashboard_External_TableHeader_Name: "Nume",
	Dashboard_External_ActionButton_AddNew: "Adăugă",
	Dashboard_External_Headline: "Acces extern aprobat",
	Dashboard_Workshops_Headline: "Ateliere",
	Dashboard_Workshops_TableHeader_Company: "Companie",
	Dashboard_Workshops_TableHeader_Address: "Adresă",
	Dashboard_Workshops_TableHeader_Postcode: "Cod poștal",
	Dashboard_Workshops_TableHeader_City: "Oraș",
	Dashboard_Workshops_ActionButton_Select: "Selectați",
	Wallet_ServiceCredits: "Credite de servicii",
	Wallet_Headline_CreditBalance: "Sold credit",
	Wallet_Headline_CreditActivity: "Activitate pe credit",
	Wallet_Headline_BillingTransactions: "Tranzacții de facturare",
	Wallet_BillingTransaction_TableHeader_Order: "Comandă",
	Wallet_BillingTransaction_TableHeader_Date: "Dată",
	Wallet_BillingTransaction_TableHeader_Amount: "Cantitate",
	Wallet_BillingTransaction_TableHeader_Action: "Acțiune",
	Wallet_CreditActivity_TableHeader_Date: "Dată",
	Wallet_CreditActivity_TableHeader_Description: "Descriere",
	Wallet_CreditActivity_TableHeader_User: "Utilizator",
	Wallet_CreditActivity_TableHeader_Amount: "Cantitate",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, toate drepturile rezervate",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Siguranța  produsului (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovații, brevete și licențe",
	FooterAdditionalLinks_Navigation_Logistics: "Achizitii si logistică",
	MenuHeader_MyTraining: "Școlarizările mele",
	MenuHeader_TrainingServices: "Servicii de formare",
	MenuHeader_OurLocations: "Locațiile noastre",
	MenuHeader_ContactsUs: "Contacteaza-ne",
	MenuHeader_Logout: "Deconectare",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Id-ul meu Bosch",
	MyProfile_AccountDeletion: "Ștergerea contului",
	MyProfile_Edit: "Schimbați adresa de e-mail sau editați parola",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Editați profilul utilizatorului",
	BackButton: "Înapoi",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Așteptăm cu nerăbdare apelul dvs. și vom fi bucuroși să vă ajutăm. Vă stăm la dispoziție de luni până vineri, între orele 09:00 - 17:00.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Introduceți caracterele corecte, așa cum sunt indicate în caseta de mai sus.",
	ContactUs_Email_MandatoryNotice_V3: "Completați toate câmpurile obligatorii.",
	ContactUs_Email_SendButton: "Trimite",
	Breadcrumb_TrainingCenters: "Centre de training",
	Training_Centres_Book_Btn: "Rezervă un curs",
	Training_centres_booking_code: "Cod rezervare",
	Training_centers_header_bar_title: "Centrele noastre de training",
	Training_centers_filter_item: "Centru",
	TeamEdit_PersonalAccountToolTop: "Activarea acestei funcții permite angajatului să acceseze acest site web. Utilizatorul va putea să-și creeze un cont personal care va face parte din contul dumneavoastră corporativ. Odată activat, puteți trimite angajatului o invitație cu un cod de activare. Invitația va fi trimisă la adresa de e-mail.",
	TeamEdit_Update: "Activarea funcției de administrator al companiei oferă automat acestui angajat acces deplin la contul dvs. corporativ. Activarea acestei funcții, dacă utilizatorul este deja conectat, necesită ca utilizatorul să se deconecteze și să revină pe site.",
	TeamEdit_PersonalAccount: "Permiteți cont personal pe Bosch Training Solutions",
	TeamEdit_SendInvitation: "Trimite invitatia",
	TeamEdit_Deactivate: "Dezactivați",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Nr de telefon trebuie să contina numai numere",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Nr de telefon trebuie să contina numai numere",
	TeamEdit_Activate: "Activ",
	TeamEdit_NotActivated: "Inactiv",
	TeamEdit_StatusPopup: "Starea va fi afișată ca „Activ” dacă utilizatorul a creat cu succes un cont personal. Dacă la stare este afișat „Inactiv”, înseamnă că utilizatorul nu a finalizat invitația.",
	Training_Catalog_Courses: "Cursuri",
	Training_Catalog_Course: "Curs",
	Reset_Filter: "Resetați filtrul",
	TrainingCatalogue_CourseCatalogue: "Catalogul cursurilor",
	CheckoutHeader_CartItemCount_Text: "Aveți [count] articol în coșul de cumpărături",
	CheckoutHeader_CartItemsCount_Text: "Aveți [count] articole în coșul de cumpărături",
	ShoppingCart_PriceItem: "Pretul articolului",
	ShoppingCartItemParticipants_AddParticipant_Button: "Adăugați participanți",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Taxe",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Include TVA de [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Metode de plată online disponibile",
	ShoppingCartItemRow_VenueFeeLabel: "Taxa de locație [fee_amount] per participant",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Adăugați un participant",
	AddParticipantsModal_SelectEmployeeLabel: "Adăugați un participant",
	AddParticipantsModal_SelectWorkshopLabel: "Selectați Atelier",
	ShoppingCartTotalPrice_ContinueButtonText: "Continuați și finalizați achiziția",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Participantul nu a fost adăugat!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Aveți unul sau mai multe cursuri în coșul de cumpărături fără un participant.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Vă rugăm să adăugați un participant pentru a continua plata.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Editați participanții ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nume",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Acțiune",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nici un participant...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Adresa de e-mail nu a fost configurată pentru participantul selectat",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Participantul selectat nu are o adresă de e-mail configurată în profilul său. Adresa de e-mail este obligatorie pentru înregistrarea la un curs de formare.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Adăugați adresa de e-mail pentru a continua sau anulați pentru a selecta un alt participant.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Adăugați e-mail",
	CancelButton: "Anulare",
	AddParticipantsModal_AddEmployeeButtonText: "Adăugați angajat",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Sunteți pe cale să ștergeți un curs din coș",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Încercați să eliminați din coș un curs la care există participanți adăugați.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Faceți clic pe „Continuați” dacă doriți să continuați sau faceți clic pe „Anulați”.",
	ShoppingCartItemHeader_Participants: "Participant(i)",
	ShoppingCartItemHeader_ParticipantPrice: "Pret pe loc",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Afișați în [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Afișați în credite",
	ShoppingCart_CreditsPriceUnit: "Credite",
	CheckOutHeader_Step1_Question: "Cum ai dori să platești?",
	CheckOutHeader_Step2_Question: "Verifică comanda",
	CheckOutHeader_Step3_Question: "Finalizează comanda",
	CheckOutHeader_Step4_Question: "Rezervare făcută cu succes!",
	CheckOutHeader_Step_Payment: "Plată",
	CheckOutHeader_Step_Review: "Sumar comandă",
	CheckOutHeader_Step_Place_Order: "Plasați comanda",
	CheckOutRightPanel_Review_Order_Btn: "Verificați comanda",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Plasați comanda",
	CheckoutRightPanelContent_OrderSummary_Title: "Rezumat comandă",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Editați coșul de cumpărături",
	ShoppingCart_Participants: "Participanții",
	CheckOutStepPayment_Payment_Methods_Title: "Selecteaza metoda de plata",
	CheckOutStepPayment_Credit_Title: "Credit sau debit",
	CheckOutStepPayment_Wholesaler_Title: "Distribuitor",
	CheckOutStepPayment_ServiceCredit_Title: "Credite de servicii",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Aveți disponibile [available_credit] credite",
	CheckOutStepPayment_BoschAccount_Title: "Cont Bosch",
	Checkout_Second_PaymentMethod_Headline: "Modalitate de plată",
	CheckOutStepReview_Edit: "Editează",
	CheckOutStepReview_Terms_Label: "Termeni, condiții și politica de anulare",
	CheckOutStepReview_Terms_Text: "Am citit, am înțeles și sunt de acord cu [termenii, condițiile și politica de anulare] de vânzare.",
	SelectSecondPaymentMethodModal_Title: "Selectați metoda de plată secundară",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Plătiți [amount]",
	StepPaymentWholesaler_Title: "Selectați un distribuitor",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Acceptați Termenii și Condițiile",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Vă rugăm să citiți și să acceptați termenii, condițiile și politica noastră de anulare înainte de a continua.",
	CheckoutDescription_ThankYou: "Vă mulțumim că ați trimis comanda la Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Neuronii noștri au început deja procesarea comenzii dvs. Între timp, vă rugăm să țineți motorul la ralanti.",
	CheckoutDesctiption_Text: "Vă vom trimite o confirmare finală prin e-mail, incluzând toate detaliile rezervării dumneavoastră.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Sunteți pe cale să eliminați atelierul selectat",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Prin eliminarea atelierului selectat, toți participanții adăugați vor fi resetați. Această modificare va afecta toate cursurile din coșul de cumpărături.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Faceți clic pe \"Continuați\" dacă doriți să continuați sau faceți clic pe \"Anulați\" pentru a opri această acțiune.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Aveți insuficiente credite. Soldul dvs. este de [available_credit] credite, dar aveți nevoie de [cart_total_credits] credite pentru a continua achiziția. Vă rugăm să selectați o altă metodă de plată sau să eliminați articole din coșul de cumpărături.",
	Training_centers_filter_items: "Centre",
	Training_Programs_Filter_Item: "Programe",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "An",
	Training_Program_Detail_Title: "Programe de training",
	AccountButton_Workshops_MenuItem: "Atelier",
	AccountButton_Wallet_MenuItem: "Portofel",
	Header_MyBoschProfile_Header: "Profilul meu Bosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponibilitate",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Adaugă/editează participantul",
	ShoppingCart_CreditPriceUnit: "Credit",
	Common_IncludedVAT: "Inclusiv TVA",
	TrainingCatalogue_Length: "Durată",
	TrainingCatalogue_Method: "Metodă",
	Course_Price_Credits: "Credite",
	Catalogue_Grid_Register_Button: "Înscriere",
	Catalogue_Credit_Tilte: "Apasă aici pentru a vedea prețul in Credite",
	Catalogue_Price_Tilte: "Apasă aici pentru a vedea prețul in RON",
	Catalogue_Grid_Vat: "Exclusiv TVA",
	EventCalendar_Seats: "Locuri",
	MenuHeader_Calendar: "Calensar",
	MenuHeader_Catalog: "Catalog",
	TableCell_Minutes: "Minute",
	ListFilter_NoResult: "Nici un rezultat",
	Course_Description: "Descrierea cursului",
	Training_course_detail: "Bazele rețelelor sociale",
	Training_course_detail_add_waiting: "Adaugă în lista de așteptare generală",
	Register_now_btn: "Înscrie-te acum!",
	Register_now_btn_add_event_to_cart: "Înscrie-te acum!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Vezi toate înregistrările",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Vezi mai puțin",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Vezi toate înregistrările",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Vezi mai puțin",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Vezi toate înregistrările",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Vezi mai puțin",
	TeamCreate_TitleError: "Este necesară o modalitate de adresare",
	Dashboard_Headerbar_BookCourse: "Rezervă cursul",
	Dashboard_Headerbar_ExitProxy: "Editează Proxi",
	Dashboard_Headerbar_EvaluateCourse: "Evaluază curs",
	Dashboard_Headerbar_EvaluateCourse_Note: "Vă rugăm să evaluați acest curs pentru a vă genera diploma!",
	Dashboard_Headerbar_DownloadCertificate: "Descarcă diploma",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Retragere",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Retragere",
	Dashboard_Headerbar_WelcomeMessage: "Bun venit în pagina principală",
	Dashboard_Headerbar_ProxingAs: "Proxi ca",
	Dashboard_TrainingProgram_Status_Completed: "Efectuat",
	Dashboard_TrainingProgram_Status_InProgress: "În curs",
	External_SelectWholesalerModal_Title: "Adaugă un utilizator extern",
	External_SelectWholesalerModal_Company_Label: "Companie",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Vă rugăm selectați",
	External_SelectWholesalerModal_Outlet_Placeholder: "Vă rugăm selectați",
	External_SelectWholesalerModal_ApplyButton: "Aplică",
	External_SelectWholesalerModal_CancelButton: "Anuleză",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmă",
	External_SelectWholesalerModal_ConfirmMessage: "Doriți să ștergeți acest distribuitor?",
	External_SelectWholesalerModal_YesButton: "Da",
	External_SelectWholesalerModal_NoButton: "Nu",
	Training_program_team_member: "Membru al echipei",
	Training_program_assign_training_program: "Atribuiți un program de training",
	Assign_modal_student_confirmation_text1: "Sunteți pe cale să atribuiți [Training Program Name] programului dvs. de învățare.",
	Assign_modal_student_confirmation_text2: "Doriți să continuați?",
	MyFullCalendar_Jan: "Ian",
	MyFullCalendar_Feb: "Febr",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mai",
	MyFullCalendar_Jun: "Iun",
	MyFullCalendar_Jul: "Iul",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Oct",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Catalog cursuri",
	MenuHeader_Event: "Calendar cursuri",
	MenuHeader_Program: "Program cursuri",
	MenuHeader_Services: "Servicii de formare",
	MenuHeader_Log_out: "Delogare",
	Filter_Location: "Locație",
	TeamEdit_UpgradeToCompanyAdministrator: "Actualizează Administratorul companiei",
	TeamEdit_PersonalWebAccountStatusIs: "Starea contului web personal este",
	Header_CreateNewEmployee: "Adaugă un nou angajat",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Nu există locuri disponibile!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Nu există locuri disponibile pentru cursul selectat",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Selectați alt eveniment sau eliminați un participant deja adăugat pentru a continua.",
	Common_SuccessModal_Title: "Felicitări!",
	TrainingProgramDetails_Merit_StatusText: "Merit",
	TrainingProgramDetails_Resit_StatusText: "Resit",
	EmployeeProfile_UserExistErrorMessage: "Contul exista deja!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Plată eșuată! Vă rugăm sa reincercați sau utilizați o altă metodă de plată.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Ați dezactivat cu succes angajatul selectat!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Salvat!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Modificările dvs. au fost salvate.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Retragerea nu este posibilă la cursurile eLearning. Vă rugăm să ne contactați dacă sunt necesare detalii suplimentare.",
	Dashboard_Team_PersonalAccount_Pending: "In așteptare",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Angajat creat!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Crearea angajatului dvs. a fost finalizată cu succes.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Această setare vă permite să restricționați un angajat de la înregistrarea la evenimente de formare. Doar administratorul companiei va putea înregistra angajatul la evenimente de formare atunci când această setare a fost deselectată.",
	Training_catalog_sort_by: "Sortrează dupa",
	CourseDetail_EvaluationRestriction_Title: "Nu este posibilă evaluarea trainingului!",
	CourseDetail_EvaluationRestriction_Message: "Evaluarea trainingului poate fi făcută doar de către participant. Vă rugăm să sfătuiți persoana participantă sa se conecteze în portalul Bosch Automotive Training Solutions  și să completeze evaluarea trainingului.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "În cazul în care participantul nu are un cont valid, poate fi făcută o invitație din setarile de utilizator de",
	Breadcrumb_Download_Page: "Descărcare",
	Download_Table_Category_Cell: "Categorie",
	Download_Table_Title_Cell: "Titlu",
	Download_File_Filter_Item: "Fișier",
	Download_File_Filter_Items: "Fișiere",
	Download_Header: "Zonă de descărcare",
	DownloadArea_Button_Download: "Descărcare",
	Popup_Title_Error: "Eroare",
	Popup_Title_Success: "Succes",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Atenționare!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "În urma eventualelor înregistrări la evenimente deja existente, nu vor fi anulate și vor trebui anulate individual.",
	TrainingProgram_PDF_Export: "Export PDF",
	TrainingProgram_PDF_Export_Participant: "Paticipant:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Progres total:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Titlu training",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Grad",
	TrainingProgram_PDF_Export_TableHeader_Date: "Data",
	AccountButton_CompanyChange_MenuItem: "Schimbare companie",
	SelectCompanyModal_Title: "Selectare companie",
	CloseButton: "Inchide",
	Change_Button: "Schimbă",
	ChangeManagerModal_Content: "Selectează utilizator",
	ChangeCompanyModal_HeadOffice: "(Sediu central)",
	SelectCompanyModal_Content: "Selectați o companie pentru autentificare...",
	AlertSuccess_Title: "Succes",
	CheckOutStepPayment_SecondaryPayment_Title: "Selectați o metodă de plată alternativă",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Adăugați o metodă de plată alternativă",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Vă rugăm să adăugați o metodă de plată alternativă de mai jos sau să selectați o altă metodă de plată de mai sus.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Nu aveți suficiente credite în cont pentru a plăti articolele din coș. Pentru a continua să utilizați metoda de plată selectată, trebuie să plătiți diferența. Diferența este [difference_amount] (fără TVA).",
	ShoppingCartTotalPrice_TotalInCredit: "Preț total in credite",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Creditele dvs. disponibile",
	ShoppingCartTotalPrice_YourDifference: "Diferență",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal fară taxe incluse",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal cu taxe incluse",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Metodă de plată alternativă",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Editați metoda de plată",
	Checkout_Second_PaymentMethod: "Metodă de plată secundară",
	Breadcrumb_TrainingCourseDetails: "Detaliile cursului",
	DownloadArea_FailedToDownload_ErrorMessage: "Nu s-a putut descărca documentul. Vă rugăm să încercați din nou sau să contactați administratorul companiei pentru asistență.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Descărcarea fișierului nu este disponibilă. Vă rugăm să încercați din nou sau să contactați administratorul companiei pentru asistență.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Descărcarea fișierului este interzisă. Vă rugăm să încercați din nou sau să contactați administratorul companiei pentru asistență.",
	SSO_Redirecting_Message: "Veți fi redirecționat către portalul Bosch Automotive Training Solutions...",
	SSO_Redirecting_ParameterErrorMessage: "Țara și/sau Limba nu sunt selectate. Vă rugăm sa contactați Administratorul Companiei pentru asistență.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Cererea/Cererile de înscriere nu au fost acceptate.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Acest lucru se poate datora faptului că se încadrează în pragul de zile de înregistrare a cursului pentru unul sau mai multe dintre cursuri. Vă rugăm să utilizați zona „Contactați-ne” dacă aveți întrebări sau solicitări.",
	TimeSlotPicker_DateFormat_Title: "Formatul datei",
	UpdateAccountSettingsConfirmationModal_Title: "Urmează să schimbați formatul datei!",
	UpdateAccountSettingsConfirmationModal_Message1: "Formatul datei va fi schimbat comform alegerii dvs. și poate fi schimbat în orice moment.",
	UpdateAccountSettingsConfirmationModal_Message2: "Doriți să continuați?",
	ProfileSummary_Settings_Title: "Setari",
	UpdateAccountSettings_DateFormat_Title: "Setări format dată",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nu s-a reușit actualizarea formatului de dată.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Setarea pentru formatul datei a fost actualizată cu succes.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Descarcă Certificat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certificat de program de formare",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Nu s-a putut descărca documentul. Vă rugăm să încercați din nou sau să contactați Administratorul Companiei pentru asistență.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Se aplică Retragerea în așteptare, deoarece retragerea a depășit pragul de anulare. Cursantul va primi informații suplimentare prin e-mail, odată ce cererea a fost aprobată/respinsă.",
	TrainingCourse_Course_NotAvailable_Title: "Cursul de formare nu este disponibil.",
	TrainingCourse_Course_NotAvailable_Message: "Cursul de formare nu este disponibil. Vă rugăm să încercați din nou sau să contactați Administratorul Companiei pentru asistență.",
	TrainingCourse_NotAvailable_Message_1: "Accesați un curs de formare care nu este disponibil.",
	TrainingCourse_NotAvailable_Message_2: "Vă rugăm să încercați din nou sau să accesați [cursuri de formare] pentru a descoperi mai multe cursuri de formare.",
	LanguageSelection_SelectALanguage: "Selectați limba",
	LanguageSelection_SelectYourCountry: "Selectați țara",
	Checkout_PaymentMethod_Headline: "Metodă de plată",
	Download_Categories_Catalogue: "Catalog",
	Download_Categories_Calendar: "Calendar",
	Download_Categories_General: "Feneral",
	Download_Categories_Information: "informații",
	Download_Categories_Userguides: "Ghid de utilizare",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "politica de anulare",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Anulează",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Continuare",
	Duration_Seconds: "Secunde",
	Duration_Day: "Zi",
	Duration_Hour: "Ora",
	Duration_Minute: "Minute",
	Duration_Second: "Secunde",
	EventCalendar_ExternalLink_Tooltips: "Achiziționați prin [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Anulează",
	EventCalendar_ExternalLink_Continue: "Continuă",
	EventCalendar_ExternalLinkModal_Title: "Urmează să fiți redirecționat...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Evenimentul selectat urmează a fi achiziționat prin [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Continuînd urmează să fiți redirecționat către un website extern",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Doriți să continuați?",
	Team_ContactPerson_FirstName_Tooltips: "Numele participanților la cursuri pot fi modificate numai de către echipa de administrare Bosch. Vă rugăm să ne contactați prin formularul de contact de pe acest site pentru asistență suplimentară.",
	Team_ContactPerson_LastName_Tooltips: "Numele participanților la cursuri pot fi modificate numai de către echipa de administrare Bosch. Vă rugăm să ne contactați prin formularul de contact de pe acest site pentru asistență suplimentară.",
	Withdraw_ExternalLink_Cancel: "Anulează",
	Withdraw_ExternalLink_Confirm: "Confirmă",
	Withdraw_ExternalLinkModal_Title: "Urmează să fiți redirecționat",
	Withdraw_ExternalLinkModal_Message: "Retragerile pentru acest curs sunt gestionate de un furnizor extern.",
	Withdraw_ExternalLinkModal_Confirmation: "Apasînd „Confirmare”, se va deschide o filă nouă și vă va redirecționa către un site web extern.",
	Button_AddToWaitingList_Tooltips: "Adaugă pe lista de așteptare",
	Button_AddToCart_Tooltips: "Adaugă în coș",
	Button_TrainingCourseDetail_Tooltips: "Vizualizați detalii",
	Button_TrainingProgramDetail_Tooltips: "Vizualizați detalii",
	Button_AssignTrainingProgram_Tooltips: "Alocați unui program de training",
	Button_DeleteFromCart_Tooltips: "Ștergeți din coș",
	Button_RemoveParticipant_Tooltips: "Stergeti participantul",
	Button_DownloadBillingTransaction_Tooltips: "Descarcare",
	Button_RemoveExternal_Tooltips: "Inlaturati"
};