export const TRANSLATIONS_LV = {
	LandingPage_Title: "Laipni lūdzam sadaļā Bosch apmācības risinājumi autorūpniecībai!",
	LandingPage_SubTitle_ChooseCountry: "Valsts/reģiona izvēle",
	Header_ContactUs: "Saziņa ar mums",
	Header_Search: "Meklēšana",
	Header_Menu: "Izvēlne",
	Header_LogInOrCreateYourAccount: "Piesakieties vai izveidojiet kontu",
	Header_LogIn: "Pieteikties",
	Header_Cart: "Grozs",
	Header_ShoppingCart: "Iepirkumu grozs",
	Header_LogOut: "Atteikties",
	Header_ChangeDetails: "Mainīt informāciju",
	Footer_ExploreOurTraining: "Izpētiet mūsu apmācības piedāvājumu",
	Footer_TrainingAtYourFingertips: "Viegli pieejama apmācība",
	Footer_DontMissAnyStory: "Nepalaidiet garām nevienu stāstu",
	Footer_ExploreOurStories: "Izpētiet mūsu stāstus",
	Footer_GetInTouchWithUs: "Sazinieties ar mums",
	Footer_SendUsAMessage: "Nosūtiet mums ziņu",
	Footer_ToContactForm: "Uz saziņas formu",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Vispārīga kontaktinformācija",
	Footer_ProductSecurity_Psirt: "Produktu drošība (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Jaunievedumu patenti un licences",
	Footer_PurchasingLogistics: "Iepirkumi un loģistika",
	Footer_CorporateInformation: "Korporatīvā informācija",
	Footer_LegalNotice: "Juridisks paziņojums",
	Footer_DataProtectionNotice: "Paziņojums par datu aizsardzību",
	Footer_PrivacySettings: "Konfidencialitātes iestatījumi",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch SIA, visas tiesības aizsargātas",
	Footer_StillLookingForSomething: "Vai meklējat vēl ko citu?",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kursi",
	Footer_Programs: "Programmas",
	Footer_EventsCalendar: "Plānotie pasākumi",
	Footer_TrainingCenters: "Apmācības centri",
	Footer_Dashboard: "Informācijas panelis",
	Footer_MyDashboard: "Mans informācijas panelis",
	Footer_ShoppingCart: "Iepirkumu grozs",
	Footer_YourShoppingCart: "Jūsu iepirkumu grozs",
	Footer_Administration: "Administrācija",
	Footer_Employees: "Darbinieki",
	Footer_Wholesalers: "Vairumtirgotāji",
	Footer_Transactions: "Darījumi",
	Footer_Workshops: "Darbnīcas",
	Footer_WorkLikeABoschApplyNow: "Darbs  — piesakieties tūlīt.",
	Footer_BoschGlobalCareers: "Bosch karjeras iespējas visā pasaulē",
	Footer_PurchasingAndLogistics: "Iepirkumi un loģistika",
	Footer_BecomeABusinessPartner: "Kļūstiet par biznesa partneri!",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Mūsu apmācības piedāvājuma attīstība atbilstoši jūsu uzņēmuma vajadzībām",
	Footer_Language: "Valsts atlase",
	MenuPopup_MyCart: "Mans grozs",
	Stories_OurTrainingStories: "Mūsu apmācības stāsti",
	Stories_ConnectingExperts: "Iespēja satikties ekspertiem un entuziastiem, sapņotājiem un darītājiem, kā arī izgudrotājiem un viedokļu līderiem — izziniet un piedzīvojiet",
	Stories_TopStories: "Populārākie stāsti",
	Stories_AtHome: "Mājās",
	Stories_Careers: "Karjera",
	Stories_History: "Vēsture",
	Stories_IndustryAndTrades: "Rūpniecība un tirdzniecība",
	Stories_Mobility: "Mobilitāte",
	Stories_Research: "Pētniecība",
	Stories_Sustainability: "Ilgtspējība",
	Stories_AllStories: "Visi stāsti",
	Stories_BlogATrainersDay: "EMUĀRS | Lektoru diena",
	Stories_ADayinLifeOfATrainer: "Diena lektora dzīvē",
	Stories_BoschServiceTraining: "Bosch servisa apmācības",
	Stories_StoryWhatDrivesYouDrivesUs: "STĀSTS | Kas ir svarīgi jums, ir svarīgi arī mums",
	Stories_StoryHowCanWeBenefitYourBusiness: "STĀSTS | Kā varam palīdzēt jūsu uzņēmumam",
	Stories_HowOurTrainingEvolves: "Kā attīstās mūsu apmācības piedāvājums",
	Stories_AModernTrainingApproach: "Tiešsaistes apmācības pasaule",
	Stories_StoryAVirtualOnlineTrainingWorld: "STĀSTS | Tiešsaistes apmācības iespējas",
	Stories_SubscribeToRSSFeed: "RSS plūsmas abonēšana",
	Title_Main: "Bosch apmācības risinājumi autorūpniecībai",
	Homepage_AboutUs: "Par mums",
	Homepage_AutomotiveAftermarket: "Automobiļu pēcpārdošana",
	Homepage_GetInTouch: "Sazināties",
	Homepage_LatestNews: "Uzziniet, kāpēc mūs vada zināšanu alkas",
	Homepage_MainTitle: "Bosch apmācības risinājumi autorūpniecībai",
	Homepage_OurOpenPositions: "Mūsu vakances",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Kas ir svarīgi jums, ir svarīgi arī mums",
	BoschServiceTraining_BoschServiceTraining: "Bosch servisa apmācības",
	BoschServiceTraining_ShareThisOn: "Dalieties ar šo šeit:",
	BoschServiceTraining_TheChallenge: "Izaicinājums. Jaunu tehnoloģiju apguve",
	BoschServiceTraining_WorldwidePresence: "Klātbūtne visā pasaulē",
	BoschServiceTraining_TechnicalTraining: "Bosch tehniskās apmācības kursi",
	BoschServiceTraining_TheOffersAndOpportunities: "Piedāvājumi un iespējas, ko piedāvā Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Mēs piedāvājam atbilstošus apmācības kursus katrai mērķa grupai — gan iesācējiem, gan profesionāļiem. Piedāvājumā ietilpst vienas dienas apmācības kursi un apmācības kursi vairāku dienu garumā. Apmācības kursi tiek piedāvāti dažādās tehniskajās jomās un apmācības sesijās (piemēram, benzīna iesmidzināšana, dīzeļdegvielas iesmidzināšana, elektrosistēma). Tehniskajos apmācības kursos Bosh sniedz plašas tehnoloģiskas zināšanas par visām Bosh standarta transportlīdzekļu sistēmām, kursos tiek pārrunātas arī citu ražotāju sistēmas. Saturs tiek īpaši pielāgots darbnīcu darbinieku prasībām.",
	BoschServiceTraining_TrainedEmployeesDesc: "Apmācīti darbinieki ir acīmredzams ieguvums automobiļu darbnīcām. Jo mūsdienu transportlīdzekļi kļūst arvien sarežģītāki, un jaunās tehnoloģijas pastāvīgi rada jaunus izaicinājumus. Tikai nepieciešamās zināšanas sniedz iespēju darbnīcas darbiniekiem efektīvi un rentabli veikt pašreizējo transportlīdzekļu modeļu diagnostikas un remonta darbus.",
	BoschServiceTraining_RegardlessOfHow: "Neatkarīgi no tā, cik labi un pieredzējuši ir automobiļu speciālisti, vienmēr būs situācijas, kad viņi nonāks saskarē ar jaunām transportlīdzekļu tehnoloģiju sistēmām vai nezināmiem defektiem. Tādējādi problēmu novēršana un risināšana var aizņemt ļoti daudz laika vai, iespējams, nedot sekmīgus rezultātus. Sliktākajā gadījumā klientiem ir jāiztiek bez sava transportlīdzekļa ilgāk nekā paredzēts.",
	BoschServiceTraining_ItIsPrecisely: "Tieši šajā brīdī palīdzēt var Bosch servisa apmācība, uzlabojot servisa kvalitāti un paātrinot procesu. Bosch šī brīža apmācība nodrošina atbalstu ātrākai problēmu noteikšanai, transportlīdzekļu saremontēšanai īsākā laikā, kā arī klientu gaidīšanas laika samazināšanai. Tādējādi sistemātiskās procedūras, ko apgūsit pie Bosch, nodrošinās ātrāku un produktīvāku darbu.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Jau vairāk nekā 125 gadus Bosch ir oriģinālo iekārtu detaļu komponentu un sistēmu piegādātājs, kam priekšroku dod daudzi vadošie automobiļu ražotāji visā pasaulē. Šī pieredze ir veidojusies no unikālām zināšanām par jaunākajām tehnoloģijām, kas ir Bosch atbalsta pamatā.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch servisa apmācības centri darbojas visur pasaulē. No Spānijas, Turcijas līdz Dienvidāfrikai, Austrālijai un Brazīlijai Bosch piedāvā darbiniekiem praktisku apmācību, kurā vienmēr ir iekļautas modernās tehnoloģijas.",
	BoschServiceTraining_AgileCompany: "Ātri reaģējošs uzņēmums",
	BoschServiceTraining_Collaboration: "Sadarbība",
	BoschServiceTraining_ConnectedMobility: "Satīklota mobilitāte",
	BoschServiceTraining_ArtificialIntelligence: "Mākslīgais intelekts",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Viedokļu līderi dialogā",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostikas iekārtu izmantošana",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Auto nozares tehnoloģiju pamati",
	BoschServiceTraining_Icon_Gasoline: "Benzīns",
	BoschServiceTraining_Icon_Diesel: "Dīzelis",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternatīva degviela",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrība un elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Komforta elektronika",
	BoschServiceTraining_Icon_ChassisAndBraking: "Šasija un bremžu sistēma",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisija un piedziņa",
	BoschServiceTraining_Icon_CommercialVehicles: "Komerctransports",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Mācību kursi tiešsaistē",
	BoschServiceTraining_Icon_BusinessSkills: "Pārdošanas apmācības",
	BoschServiceTraining_Icon_Accreditations: "Akreditācijas",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Apmācību programmas",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "PasniedzējaVārdsUzvārds",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Pasniedzēja ikdiena",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Everyday is an adventure",
	ADayInTheLifeOfATrainer_Summary: "Kopsavilkums",
	ADayInTheLifeOfATrainer_TheDayJourney: "The day's journey",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Katra diena ir saspringta, taču tā vienmēr tiek atalgota ar unikālo pieredzi. Prieks apmācīt studentus, zinot, ka viņiem ir mūsu nākotnes atslēga, ir atbildīgs, bet tajā pašā laikā tik aizraujošs. Redzot, kā studenti aug ar katru stundu, katru dienu un katru nedēļu, mums pierāda, ka mēs kopīgi strādājam ar vienu mērķa, lai pilnveidot viņu zināšanas, vienlaikus iekļaujot mūsdienu tehnoloģijas, kas virza nākotni!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "At the end of each day we have a sit down and reflect on the days work. The days go so quickly but this is proof itself that what we are achieving is not only rewarding for the students but for us as trainers too.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We can go home at the end of day satisfied that we have done a great job!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Sakums",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Apmācību materiālu sagatavošana un noslēguma pārbaude. Svarīgo e-pastu pārbaude.",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Apmācības sākas",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Dalībnieku sagaidīšana. Dienas kartības izklāsts. Iepazīšanas ar dalībniekiem, izprotot katra individuālo dinamiku",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorētiska daļa",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Lai apmācības būtu pēc iespējas interesantākas, ir nepieciešama noteikta mijiedarbība ar grupu. Izveidojiet  uzdevumus, kas ir saistīti ar teoriju, lai iesaistīt grupu",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pusdienu pārtraukums",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktiskas nodarbības",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Apmācības turpinās darbnīcā ar dažiem praktiskiem uzdevumiem. Trenerim vienmēr ir jābūt klāt lai dot norādījumus un palīdzēt jebkuru neskaidrību gadījumos",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Kopsavilkums",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Atgriešanās klasē, lai pārrunātu un apspriest apmācību laikā iegūto informāciju. Atbildes uz visiem dalībnieku jautājumiem. Gatavošanās nākamajām apmācībām",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Pēdējā diena",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Apmācību sertifikātu pasniegšana",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Mājās",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "How can we help benefit your business",
	BoschTrainingBenefits_HowOurTrainingEvolves: "How our training evolves",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "We are constantly evolving to ensure we deliver quality up-to-date-training",
	BoschTrainingBenefits_TheBoschServiceTraining: "The Bosch Service Training Center would like to assist you in developing your business through its extensive and highly relevant portfolio of technical/business training and further education courses with certified qualifications. The following pages provide you with an overview of our training program as well asthe current further education opportunities. We look forward to meeting you and your employees at our training courses and seminars.",
	BoschTrainingBenefits_LongStandingExperience: "Long-standing experience with and extensive know-how of all systems and in thea area of automobile technology.",
	BoschTrainingBenefits_QuickAndEfficient: "Quick and efficient solutions for many problems.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "The Advantages and benefits",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions aptver visu auto nozares jomu. Specializējieties noteiktās jomās vai paplašiniet savas zināšanas, izmantojot apmācību piedāvājumus no Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Acquired knowledge facilities repair maintenance and service work.",
	BoschTrainingBenefits_TheHighDegree: "The high degree of practical content and small groups guarantee target-oriented learning",
	BoschTrainingBenefits_ParticipationInTraining: "Participation in training increases employee motivation and satisfaction and supplemental qualifications convince customers",
	BoschTrainingBenefits_IncreasedSales: "Increased sales through cross-make system knowledge",
	BoschTrainingBenefits_MethodicalProcedures: "Methodical procedures reduce the wait time for customers",
	BoschTrainingBenefits_HighlyQualified: "Highly qualified instructors convey systematic procedures",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Jums vai jūsu uzņēmumam piemērota apmācības risinājuma noteikšana",
	BoschTrainingBenefits_DirectOnlineBooking: "Tieša kursu tiešsaistes rezervācija un pārvaldība jums vai jūsu darbiniekiem",
	BoschTrainingBenefits_OnlineAccess: "Tiešsaistes piekļuve mācību dokumentācijai",
	BoschTrainingBenefits_AccessToYourOwn: "Piekļuve personīgajai apmācības vēsturei un sertifikātiem",
	BoschTrainingBenefits_ToolsForManagement: "Rīki savas karjeras attīstības pārvaldībai",
	BoschTrainingBenefits_ManagerHasFull: "Vadītājam ir pilns pārskats par darbinieku kursiem sistēmā",
	BoschTrainingBenefits_Access24hours: "Piekļuve 24 stundas dienā 7 dienas nedēļā",
	ModernTrainingApproach_KnowledgeIsKey: "Zināšanas ir galvenais panākumu faktors jebkurā darbnīcā. Tāpēc Bosch piedāvā visaptverošu profesionālās apmācības attīstības programmu. Tā sniedz iespēju studentiem piemērotā laikā un vietā iegūt atbilstošas zināšanas — zināšanas, kas ir nepieciešamas, lai profesionāli apkoptu un remontētu klienta transportlīdzekli. Uzņēmums Bosch ir pievienojis tīmekļapraidi kā jaunu jau piedāvātās e-mācību paketes moduļa daļu. Tas sastāv no servisa apmācības sesijām, kas ir izstrādātas tieši straumēšanai.",
	ModernTrainingApproach_ANewLearning: "Jauna mācību iespēja",
	ModernTrainingApproach_YourOnlineTraining: "Tiešsaistes apmācības iespējas",
	ModernTrainingApproach_RecentlyTheWorld: "Pēdējā laikā pasaule ir mainījusies, aplūkojiet, kā mēs varam palīdzēt",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Tiešsaistes apmācības pasaule",
	ModernTrainingApproach_NewTeachingMethod: "Jauna mācīšanas metode",
	ModernTrainingApproach_InAdditionTo: "Papildus esošajai klātienes apmācībai mēs esam iekļāvuši apmācības programmā tīmekļapraides apmācību. Jūsu rīcībā ir treneris ar plašām teorētiskām zināšanām virtuālā klasē vai demonstrējot praktiskus piemērus. Kā parasti jūs saņemsit daudz informācijas par transportlīdzekļu sistēmām un atbilstošajiem Bosch produktiem. Protams, jums ir arī iespēja uzdot jautājumus. Uz šiem jautājumiem atbildes tiks sniegtas tieši tīmekļapraides laikā. Tīmekļapraides ilgst 60 līdz 120 minūtes. Nepieciešams stabils interneta pieslēgums. Meklējiet piemērotu tīmekļapraidi tieši mūsu apmācības katalogā, meklēšanas laukā ievadot vārdu “Webcast”.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch tīmekļapraide: Novatoriska un praktiska",
	ModernTrainingApproach_BoschWebcastingText: "Lai piedalītos Bosch tīmekļapraidē, dalībniekam vispirms ir jāiezvanās. Pirms sesijas tiek nosūtīts individuāls piekļuves kods, lai nodrošinātu pieteikšanos. Vienlaicīgi piedalīties var līdz 200 dalībniekiem neatkarīgi no atrašanās vietu skaita. Apmācību vada lektors, un jautājumus var iesniegt jebkurā laikā, izmantojot tiešsaistes tērzēšanas funkciju.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitāls papildinājums apmācībai klasē",
	ModernTrainingApproach_TheDigitalSupplementText: "Tīmekļapraide un apmācība klasē lieliski papildina viena otru. Abos gadījumos apmācību vada kvalificēts lektors. Tīmekļapraides ir piemērotas tēmām, kuras var izklāstīt tiešsaistē bez nepieciešamības strādāt ar transportlīdzekli. Piemēram, iepazīšanās ar noteiktām jaunās autovadītāja palīdzības sistēmas ESI[tronic] funkcijām. Ja ir nepieciešams efektīvs grupas darbs darbnīcā vai praktisks darbs ar transportlīdzekļa tehnoloģiju, klātienes apmācība tomēr ir labākā opcija.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Straumēšana var būt lētāka",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Izmaksas par tīmekļapraidi vienam dalībniekam parasti ir zemākas nekā par līdzīgu kursu klasē. Tas ir tāpēc, ka dalībnieku skaits ir lielāks un ir mazāk ar apmācību saistītu papildu darbību (nav jāceļo uz norises vietu un atpakaļ, nepieciešams mazāk laika).",
	ModernTrainingApproach_BookAndProfitHeading: "Rezervējiet un iegūstiet",
	ModernTrainingApproach_BookAndProfitText: "Tīmekļapraides var rezervēt mūsu tīmekļa vietnē tāpat kā citus mūsu apmācības kursus un mācību piedāvājumus. Varat izvēlēties maksāt atsevišķi par katru tīmekļapraidi vai izņemt gada abonementu noteiktam tīmekļapraižu skaitam.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch tīmekļapraides un apmācība klasē — salīdzinājums",
	ModernTrainingApproach_Webcasting: "Tīmekļapraide",
	ModernTrainingApproach_Classroom: "Klase",
	ModernTrainingApproach_WebcastingP1: "Vienlaikus var piedalīties līdz 200 dalībniekiem no jebkuras vietas pasaulē",
	ModernTrainingApproach_WebcastingP2: "Zema sarežģītība un salīdzinoši zemas izmaksas, nav nepieciešama ceļošana",
	ModernTrainingApproach_WebcastingP3: "Lieliski piemērota konkrētām un īsām atsevišķām tēmām",
	ModernTrainingApproach_WebcastingP4: "Darbnīcas var raidīt tīmekļapraidi saviem darbiniekiem jebkurā vietā un laikā",
	ModernTrainingApproach_WebcastingP5: "Nepieciešams par 50–70 % mazāk laika nekā apmācībai klasē",
	ModernTrainingApproach_ClassroomTrainingP1: "Vienlaikus tiekas ne vairāk kā 16 dalībnieki",
	ModernTrainingApproach_ClassroomTrainingP2: "Ceļa izdevumi un parasti augstākas izmaksas vienai personai",
	ModernTrainingApproach_ClassroomTrainingP3: "Lieliski piemērota metode, lai optimizētu sadarbību vai mācītos, atrodoties tieši pie transportlīdzekļa",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktiski vingrinājumi un mijiedarbība ar lektoru",
	ModernTrainingApproach_ClassroomTrainingP5: "Lektors var labāk pielāgot apgūstamo informāciju atbilstoši dalībnieku faktiskajām vajadzībām",
	ModernTrainingApproach_OnlineTrainingOfferings: "Tiešsaistes apmācības piedāvājumi (tīmekļa apmācība/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Mūsdienās patstāvīga mācīšanās pie datora ir neatņemama zināšanu pārneses sastāvdaļa. Iespēja izvēlēties laiku un vietu, neierobežotas atkārtošanas iespējas, individuāls mācīšanās ātrums un interaktīvs mācību saturs padara šo metodi par neaizstājamu mācību līdzekli.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Katrs tīmekļa apmācības elements ir rūpīgi strukturēts. Šie kursi ne tikai nodrošina plašu zināšanu bāzi, bet arī var kalpot kā efektīva sagatavošanās apmācības programmām Bosch apmācības centros.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtuālā diagnostikas simulācija piedāvā lietotāja lomu spēles ikdienas darbnīcas situācijās, piemēram, problēmu novēršanas procedūras darbplūsmā, izmantojot Bosch diagnostikas ierīces virtuālajā darbnīcā.",
	ModernTrainingApproach_Advantages: "Priekšrocības",
	ModernTrainingApproach_AdvantagesP1: "Ātra un efektīva zināšanu sniegšanas metode",
	ModernTrainingApproach_AdvantagesP2: "Daudzveidīga mācīšanās",
	ModernTrainingApproach_AdvantagesP3: "Izmantošanas biežums pēc nepieciešamības",
	ModernTrainingApproach_AdvantagesP4: "Minimāli laika un naudas resursi",
	ModernTrainingApproach_AdvantagesP5: "Individuāls mācīšanās ātrums",
	ModernTrainingApproach_AdvantagesP6: "Elastīgs mācību laiks",
	ModernTrainingApproach_AdvantagesP7: "Atkārtošanas iespējas",
	ModernTrainingApproach_AdvantagesP8: "Nav ceļa izdevumu",
	ModernTrainingApproach_AdvantagesP9: "Nav noslodzes vai plānošanas konfliktu",
	Dashboard_CoursesViewAndManageYourCourses: "KURSI | Kursu skatīšana un pārvaldība",
	Dashboard_MyCourses: "Mani kursi",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "APMĀCĪBAS PROGRAMMA | Apmācības programmu skatīšana",
	Dashboard_MyTrainingProgram: "Manas apmācību programmas",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "e-mācības | E-mācību portfeļa skatīšana un pārvaldība",
	Dashboard_MyELearning: "Manas e-mācības",
	Dashboard_AccountManageYourAccount: "KONTS | Konta pārvaldība",
	Dashboard_MyAccount: "Mans konts",
	Dashboard_MyDashboard: "Informācijas panelis",
	Dashboard_ManageYourAccount: "Konta pārvaldība",
	Dashboard_Workshops: "Darbnīcas",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRĒŠANA | Darbnīcas pārvaldība",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRĒŠANA | Komandu pārvaldība",
	Dashboard_Administration: "Administrācija",
	Dashboard_ViewYourCourses: "Kursu skatīšana",
	Dashboard_ViewYourTrainingPrograms: "Apmācības programmu skatīšana",
	Dashboard_TrainingPrograms: "Apmācību programmas",
	Dashboard_ViewAndEditYourDetails: "Detalizētas informācijas skatīšana un rediģēšana",
	Dashboard_ViewYourELearningCourses: "E-mācību kursu skatīšana",
	Dashboard_Pending: "Gaida",
	Dashboard_RequiresAdministratorSignOff: "(nepieciešama administratora izrakstīšanās)",
	Dashboard_HistoricalRecords: "Vēsturiskie ieraksti",
	Dashboard_Registered: "Reģistrēts",
	Dashboard_WaitingList: "Gaidīšanas saraksts",
	Dashboard_RecommendedForYou: "Ieteikumi jums",
	Dashboard_AccountDetails: "Detalizēta informācija par kontu",
	Dashboard_ChangeProfileImage: "Mainīt profila attēlu",
	Dashboard_Title: "Amats",
	Dashboard_Role: "Loma",
	Dashboard_FirstName: "Vārds",
	Dashboard_LastName: "Uzvārds",
	Dashboard_Telephone: "Tālrunis",
	Dashboard_Mobile: "Mobilais tālruni",
	Dashboard_Email: "E-pasts",
	Dashboard_Language: "Valoda",
	Dashboard_Save: "Saglabāt",
	Dashboard_Student: "Students",
	Dashboard_WorkshopTrainingAdministrator: "Darbnīcas apmācības administrators",
	Dashboard_WholesalerTrainingAdministrator: "Vairumtirgotāja apmācības administrators",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Uzņēmuma administrācija",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Pārvaldiet darbnīcas...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Pārvaldiet darbiniekus...",
	Dashboard_CompanyAdministration_Workshop: "Darbnīca",
	Dashboard_CompanyAdministration_Address: "Adrese",
	Dashboard_CompanyAdministration_Zip: "Pasta indekss",
	Dashboard_CompanyAdministration_City: "Pilsēta",
	Dashboard_CompanyAdministration_Manage: "Pārvaldīt",
	Dashboard_AdministrationSection_AdministrationTitle: "Administrācija",
	Dashboard_AdministrationSection_ManageTheTeam: "Komandas pārvaldīšana",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRĒŠANA | Darbinieku pārvaldība",
	Dashboard_AdministrationSection_Employees: "Darbinieki",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRĒŠANA | Vairumtirgotāju pārvaldība",
	Dashboard_AdministrationSection_Wholesalers: "Vairumtirgotāji",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRĒŠANA | Darījumu pārvaldība",
	Dashboard_AdministrationSection_Transactions: "Darījumi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Darbinieku administrēšana",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Pārvaldiet darbiniekus...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Reģistrēties",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Ieraksti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Rediģēt",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Darbinieks",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Pievienot",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Izveidot",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Reģistrēts",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Gaida",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(nepieciešama administratora izrakstīšanās)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Vēsturiskie ieraksti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Gaidīšanas saraksts",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Pašreizējais darbinieku saraksts",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Darbinieku pārvaldība",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Vārds",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Darbinieka konts",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Skatīt un rediģēt detalizētu informāciju par darbinieku...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Iestatījumi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Atļaut personisko kontu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Darbinieku darbnīcas administrēšanas starpniekservera jaunināšana",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Atļaut darbiniekam reģistrēties mācību pasākumiem",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Sūtīt e-pasta uzaicinājumu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktivizēt kontu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Saglabāt",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Sūtīt",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Rediģēt",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Vairumtirgotāju pārvaldība",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Pārvaldiet vairumtirgotājus...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Apstiprināto vairumtirgotāju saraksts",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Vārds",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Dzēst",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Izveidot",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Pievienot",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Jauna darbinieka konta izveide",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Izveidojiet detalizētu informāciju jauniem darbiniekiem...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Pievienot jaunu apstiprinātu vairumtirgotāja administratoru",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Izveidojiet detalizētu informāciju jauniem vairumtirgotājiem...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Nolaižamajā izvēlnē izvēlieties apstiprinātu vairumtirgotāju...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Darījumi",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Skatiet un pārvaldiet darījumus...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Atpakaļ",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Rēķina numurs",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Apraksts",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Maksāšanas metode",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datums",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Summa",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Lejupielādēt",
	TrainingCentres_TrainingCentres: "Apmācības centri",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Mūsu apmācības centri atrodas visā pasaulē",
	TrainingCentres_CountryCategory: "Valsts kategorija",
	TrainingCentres_CityCategory: "Pilsētas kategorija",
	TrainingCentres_Country: "Valsts",
	TrainingCentres_City: "Pilsēta",
	TrainingCatalogue_TrainingCatalogue: "Apmācības kursu katalogs",
	TrainingCatalogue_LearningAtTheHighestLevel: "Mācīšanās augstākajā līmenī",
	TrainingCatalogue_StartDate: "Sākuma datums",
	TrainingCatalogue_EndDate: "Beigu datums",
	TrainingCatalogue_TrainingName: "Apmācības nosaukums",
	TrainingCatalogue_Language: "Valoda",
	TrainingCatalogue_Days: "Dienas",
	TrainingCatalogue_Seats: "Vietas",
	TrainingCatalogue_Price: "Cena",
	TrainingCatalogue_Location: "Atrašanās vieta",
	TrainingCatalogue_PartNumber: "Daļas numurs",
	TrainingCatalogue_TrainingCourse: "Apmācības kurss",
	TrainingCatalogue_Category: "Apmācības kurss",
	TrainingCatalogue_TrainingCategory: "Training Category",
	TrainingCatalogue_CourseName: "Apmācības programma",
	TrainingCatalogue_TotalDays: "Kopējais dienu skaits",
	TrainingCatalogue_Venue: "Vieta",
	TrainingCatalogue_Details: "Detalizēta informācija",
	TrainingCatalogue_Prerequisites: "Priekšnoteikumi",
	TrainingCatalogue_CourseType: "Kursa veids",
	TrainingCatalogue_LearningMethod: "Mācību metode",
	TrainingCatalogue_AddToWaitingList: "Pievienot gaidīšanas sarakstam",
	ShoppingCart_ShoppingCart: "Iepirkumu grozs",
	ShoppingCart_ReviewAndManage: "Abonementu pārskatīšana un pārvaldība",
	ShoppingCart_YouHaveAddedItem: "Jūs pievienojāt grozam norādītās preces",
	ShoppingCart_Item: "Vienība",
	ShoppingCart_Quantity: "Daudzums",
	ShoppingCart_PricePerItem: "Vienības cena",
	ShoppingCart_TotalPrice: "Kopējā cena",
	ShoppingCart_VatIsIncluded: "Bez PVN",
	ShoppingCart_Redeem: "Ja jums nav kredīta, noklikšķiniet šeit, lai to izmantotu",
	ShoppingCart_Purchase: "Ja jums nav kredīta, noklikšķiniet šeit, lai iegādātos",
	ShoppingCart_Checkout: "Norēķini",
	ShoppingCart_ContinueShopping: "Turpināt iepirkties",
	ShoppingCart_TotalPriceInclVat: "Kopējā cena ar PVN",
	ShoppingCart_Apply: "Lietot",
	ShoppingCart_ShoppingCartEmpty: "Jūsu iepirkumu grozs ir tukšs",
	ShoppingCart_VatIncluded: "PVN",
	ShoppingCart_ManageYourShopping: "Iepirkumu pārvaldība",
	ShoppingCart_Participant: "Dalībnieks",
	ShoppingCart_Complete: "Pilnīgs",
	ShoppingCart_NetPrice: "Neto cena",
	ShoppingCart_Workshop: "Darbnīca",
	CheckOut_PreviewYourOrder: "Pasūtījuma priekšskatīšana",
	CheckOut_YourShoppingCart: "Grozs",
	CheckOut_Preview: "Priekšskatīt",
	CheckOut_Payment: "Maksājums",
	CheckOut_Summary: "Kopsavilkums",
	CheckOut_Back: "Atpakaļ",
	CheckOut_PleaseReadAndAgreeOurTerms: "Lūdzu, izlasiet un akceptējiet mūsu noteikumus un nosacījumus",
	CheckOut_IHaveReadTheTermsAndCondition: "Esmu izlasījis [noteikumus un nosacījumus], izprotu un akceptēju tos",
	CheckOut_YesIAgree: "Jā, akceptēju noteikumus un nosacījumus",
	CheckOut_Continue: "Turpināt",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Apsveicam! Esam saņēmuši jūsu pasūtījumu",
	CheckOut_OrderConfirmation: "Jūs saņemsit galīgo pasūtījuma apstiprinājumu, kad jūsu pieprasījums būs sekmīgi apstrādāts",
	CheckOut_WeWillContactYouDirectly: "Mēs sazināsimies ar jums tieši, ja mums būs ar jūsu pieprasījumu saistīti jautājumi",
	CheckOut_AddToCart: "Pievienot iepirkumu grozam",
	CheckOut_CreditCart: "Kredīta grozs",
	CheckOut_DirectDebit: "Tiešais debets",
	CheckOut_TrainingCredits: "Apmācības kredīti",
	CheckOut_PreferredWholesaler: "Vēlamais vairumtirgotājs",
	EventCalendar_EventCalendar: "Pasākumu kalendārs",
	EventCalendar_TrainingEventsForAllYourNeeds: "Apmācības pasākumi visām jūsu vajadzībām",
	TrainingCourse_TrainingCourse: "Apmācības kursu katalogs",
	TrainingCourse_LearningAtTheHighestLevel: "Mācīšanās augstākajā līmenī",
	TrainingProgram_Title: "Apmācības programmu katalogs",
	TrainingProgram_SubTitle: "Mācīšanās augstākajā līmenī",
	AccountDeactivationModal_Title: "Vai tiešām vēlaties deaktivizēt šo kontu?",
	AccountDeactivationModal_Line1: "Šī konta deaktivizēšana ir neatgriezeniska, tiks noņemts viss saturs, ieskaitot pakalpojumus, pieprasījumus, darījumus un atlikumus, kā arī komandas dalībnieki un profila iestatījumi.",
	AccountDeactivationModal_Line2Part1: "Ja tas notiek aktīvā abonēšanas periodā, lūdzu, skatiet atcelšanas politiku mūsu",
	AccountDeactivationModal_Line2Part2: "noteikumos un nosacījumos",
	AccountDeactivationModal_Line2Part3: "pirms pieprasījuma iesniegšanas.",
	AccountDeactivationModal_Line3: "Vai tiešām vēlaties iesniegt pieprasījumu deaktivizēt šo kontu?",
	AccountDeactivationModal_Cancel: "Atcelt",
	AccountDeactivationModal_Send: "Sūtīt",
	CurrentUserAccountDeactivationModal_Title: "Vai tiešām vēlaties deaktivizēt savu kontu?",
	CurrentUserAccountDeactivationModal_Line1: "Jūsu konta deaktivizēšana ir neatgriezeniska, tiks noņemts viss saturs, ieskaitot pakalpojumus, pieprasījumus, darījumus un atlikumus, kā arī komandas dalībnieki un profila iestatījumi.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Ja tas notiek aktīvā abonēšanas periodā, lūdzu, skatiet atcelšanas politiku mūsu",
	CurrentUserAccountDeactivationModal_Line3: "Vai tiešām vēlaties iesniegt pieprasījumu deaktivizēt jūsu kontu?",
	CurrentUserAccountDeactivationModal_Yes: "Jā",
	CurrentUserAccountDeactivationModal_No: "Nē",
	CourseWithdrawalModal_Title: "Atcelšana nav galīga, pirms to nav apstiprinājusi mūsu apmācības administrācija.",
	CourseWithdrawalModal_Line1: "Galīgo apstiprinājumu pēc apstiprināšanas saņemsit pa e-pastu. Ja mums būs jautājumi, mēs ar jums sazināsimies tieši. Vai tiešām vēlaties iesniegt atsaukšanas pieprasījumu?",
	CourseWithdrawalModal_Line2Part1: "Lūdzu, izlasiet mūsu",
	CourseWithdrawalModal_Line2Part2Link: "atcelšanas politiku",
	CourseWithdrawalModal_Line2Part3: "Vai turpināt ar atsaukšanas pieprasījumu?",
	CourseWithdrawalModal_Yes: "Jā",
	CourseWithdrawalModal_No: "Nē",
	Settings_Settings: "Iestatījumi",
	Settings_ManageYourSettings: "Iestatījumu pārvaldība",
	Settings_Profile: "Profils",
	Settings_MyBoschGlobalIdLoginInformation: "Mana Bosch globālā ID pieteikšanās informācija",
	Settings_Edit: "Rediģēt",
	Settings_CompanyDetails: "Informācija par uzņēmumu",
	Settings_RequestAccountDeletion: "Konta dzēšanas pieprasījums",
	Settings_AccountDeletionText: "Ja izdzēsīsit savu kontu, visi jūsu dati (tostarp adrese un maksājumu dati) tiks dzēsti un vairs nebūs pieejami.",
	Settings_DeleteAccountRequestConfirmation: "Konta dzēšanas pieprasījuma apstiprināšana",
	Settings_DeleteAccountRequestConfirmationText: "Mēs saņēmām jūsu konta dzēšanas pieprasījumu un sazināsimies ar jums pa e-pastu vai tālruni, lai veiktu galīgo apstiprināšanu",
	RecommendedHotels_RecommendedHotels: "Ieteicamās viesnīcas",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Mūsu apmācības centri atrodas visā pasaulē",
	Error_Common: "Radās kļūda.",
	Error_404: "Lapa, ko apmeklējāt, nepastāv",
	Error_401: "Jums nav piekļuves šai lapai",
	Error_500: "Serveris ziņo par kļūdu",
	Dashboard_Courses_Table_Course: "Apmācību kurss",
	Dashboard_Courses_Table_Date: "Datums",
	Dashboard_Courses_Table_Venue: "Atrašanās vieta",
	Dashboard_Courses_Table_TrainingType: "Apmācību veids",
	Dashboard_Courses_Table_Action: "Darbība",
	Dashboard_Courses_Table_TasksToComplete: "Jums jāizpilda sekojošs uzdevums",
	Dashboard_Courses_Table_Withdraw: "Atsaukt",
	Dashboard_Courses_Table_Remove: "Atcelt",
	Dashboard_Courses_Table_View: "Skats",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Ieraksts nav atrasts",
	Dashboard_Courses_Table_CompletionDate: "Pabeigšnās datums",
	Dashboard_Courses_Table_Actions_Assignments: "Norīkojumi",
	Dashboard_Courses_Table_Actions_Certification: "Sertifikācija",
	Dashboard_Courses_Table_Actions_Evaluations: "Novērtējumi",
	Dashboard_Courses_Table_Actions_Assessments: "Eksaminācija",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Pievienot kalendāram",
	Dashboard_Courses_Table_CourseDetails_Task: "Uzdevumi",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Termiņs",
	ContactUs_Headline: "Sazinies ar mums",
	ContactUs_HowCanWeHelp: "Ka mēs varam palīdzēt?",
	ContactUs_EmailTab: "E-pasts",
	ContactUs_PhoneTab: "Telefons",
	ContactUs_LetterTab: "Vēstule",
	ContactUs_Phone_Headline: "Piezvaniet mums!",
	ContactUs_Phone_Description: "Mēs gaidam Jūsu zvanu un ar prieku Jums palīdzēsim.",
	ContactUs_Phone_PhoneNumber: "Lūdzu, izmantojiet kontaktu formu, lai nosūtītu mums ziņojumu.",
	ContactUs_Phone_ChargeNotice: "Cenas var atšķirties atkarībā no pakalpojumu sniedzēja un valsts.",
	ContactUs_Letter_Headline: "Rakstiet mums",
	ContactUs_Letter_Description: "Ja jums ir jautājumi, lūgumi vai ieteikumi? Mēs gaidam no Jums zuņu..",
	ContactUs_Email_Headline: "Sūtiet e-pastu",
	ContactUs_Email_Description: "Nosūtiet mums savu ziņojumu, aizpildot zemāk esošo veidlapu. Kāds no mūsu konsultantiem ar prieku sazināsies ar Jums",
	ContactUs_Email_InputName: "Vārds*",
	ContactUs_Email_InputCompany: "Uzņēmums",
	ContactUs_Email_InputCountry: "Valsts*",
	ContactUs_Email_InputEmail: "E-pasts*",
	ContactUs_Email_InputMessage: "Ziņa*",
	ContactUs_Email_DataProtection: "Paziņojums par datu aizsardzību*",
	ContactUs_Email_DataProtectionConfirm: "Esmu izlasījis Privātuma politiku*",
	ContactUs_Email_SecurityCheck: "Drošības pārbaude",
	ContactUs_Email_SecurityCodeConfirm: "Ludzu ierakstiet autorizācijās tekstu no bildes augsā",
	ContactUs_Email_SecurityCodeInvalid: "Autorizācijas teksta kļūda",
	ContactUs_Email_MandatoryNotice: "Lūdzu aizpildiet obligātos laukus",
	ContactUs_Email_SuccessMessage: "Jūsu ziņojums ir nosūtīts",
	ContactUs_Email_TakenNoteDataProtection1: "Es izlasīju",
	ContactUs_Email_TakenNoteDataProtection2: "Privātuma politika",
	ContactUs_Email_SubmitButton: "Iesniegt",
	ContactUs_Email_FieldErrorMessage: "Lūdzu aizpildiet obligātos laukus",
	ContactUs_Email_SuccessModal_OK: "Labi",
	AccountInReviewModal_Title: "Jūsu konts pašlaik tiek pārskatīts!",
	AccountInReviewModal_Paragraph1: "Paldies, ka reģistrējāt personīgo kontu Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "Mūsu darbinieki pašlaik pārskata un konfigurē Jūsu kontu. Kad konts būs veiksmīgi konfigurēts, Jūs saņemsiet apstiprinājuma e-pastu.",
	AccountInReviewModal_Paragraph3: "Jūs varat droši sērfot mūsu vietnē lietojot ierobežotās piekļuves iespējas",
	AccountInReviewModal_Paragraph4: "Ja Jums ir jautājumi vai Jūsu konts netika apstrādāts 48 stundu laikā, izņemot nedēļas nogales un svētku dienas, varat sazināties ar mums",
	AccountInReviewModal_ContactUsLink: "šeit",
	AccountInReviewModal_OKButton: "OK, ļaujiet man ienākt!",
	Header_YesButton: "Jā",
	Header_NoButton: "Nē",
	Header_LogOutText: "Vai vēlaties iziet?",
	Footer_EnquiryText: "Mēs ceram uz jūsu pieprasījumu,",
	Footer_Telephone: "Telefons",
	Footer_TermsAndConditions: "Lietošanas noteikumi",
	Footer_HowOurTrainingEvolves: "Mūsu apmācību attīstība",
	FooterSearch_SearchFieldPlaceholder: "Meklēšana",
	TrainingCatalogue_Action: "Darbība",
	TrainingCatalogue_Duration: "Ilgums",
	TrainingCatalogue_Information: "Informācija",
	Settings_PersonalDetails: "Personas dati",
	Reset_All_Filters: "Atcelt filtrus",
	EventTable_Days: "Dienas",
	SearchField_AllResults: "Rezultāti",
	TrainingCentresLayout_ViewDetails: "Detalizēti",
	Dropdown_Placeholder: "Atlasīt…",
	WaitlistPopup_SelectButtonText: "Izvēlēties",
	WaitlistPopup_StartDate: "Sakuma datums",
	WaitlistPopup_EndDate: "Beigu datums",
	WaitlistPopup_Language: "Valoda",
	WaitlistPopup_City: "Pilsēta",
	WaitlistPopup_Workshop: "Serviss",
	WaitlistPopup_Student: "Apmācāmais",
	WaitlistPopup_PreferredLocation: "Vēlamā atrašanās vieta",
	WaitlistPopup_SignUpCourseWaitingList: "Reģistrējieties kursu velmju sarakstā",
	WaitlistPopup_SignUpWithoutSelectingDate: "Reģistrējieties kursu velmju sarakstā bez datuma izvēles bez datuma izvēles",
	WaitlistPopup_GeneralWaitList: "Kopējais velmju saraksts",
	WatinglistPopup_CourseLabel: "Apmācību kurss",
	Common_ContinueButton: "Turpināt",
	UserAccountDeactivationModal_ConfirmLabel: "Apstiprināt",
	Filter_FilterLabel: "Filtrs",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Jūs jau esat reģistrēts gaidīšanas sarakstā, vai vēlaties noņemt savu esošo reģistrāciju un izveidot jaunu?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Jā, noņemt",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nē, atcelt",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Apmācību atcelšana",
	WaitinglistPopup_SignUpButtonText: "Pierakstīties",
	MyAccount_FirstNameRequied: "Vārds ir obligāts",
	MyAccount_LastNameRequired: "Uzvārds ir obligāts",
	MyAccount_RoleRequired: "Funkcija ir obligāta",
	MyAccount_MobileRequired: "Mobila tālruņa nr. ir obligāts",
	MyAccount_EmailRequired: "E-pasta adrese ir obligāta",
	MyAccount_LanguageRequired: "Valodas izvēle ir obligāta",
	MyAccount_UpdateSuccessMessage: "Jūsu konta informācija ir veiksmīgi atjaunināta!",
	MyAccount_UpdateSuccessModal_ButtonText: "Jā, slēgt",
	Infor_CorporateInformation_Header: "Korporatīvā informācija",
	Infor_LegalNotice_Header: "Juridisks paziņojums",
	Infor_PrivacyPolicy_Header: "Paziņojums par datu aizsardzību (Privātuma politika)",
	CreateEmployeeAccountModification_FormValidation_Role: "Funkcija ir obligāta",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Vārds ir obligāts",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Uzvārds ir obligāts",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobila tālruņa nr. ir obligāts",
	CreateEmployeeAccountModification_FormValidation_Email: "E-pasta adrese ir obligāta",
	CreateEmployeeAccountModification_FormValidation_Language: "Valodas izvēle ir obligāta",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Ielūgums pievienoties Bosch Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Jūs esat veiksmīgi pievienojis jaunu darbinieku savai komandai!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Vai vēlaties, lai mēs nosūtītu e-pastu ar ielūgumu jūsu jaunām komandas biedram ar norādījumiem, kā pareizi izveidot savu personīgo Bosch Automotive Training Solutions kontu?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nē",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Jā, nosutīt",
	ShoppingCart_Venue_Fee: "Telpas lietošanās nodeva",
	ShoppingCart_Trainning_Name: "Apmācību nosaukums",
	ShoppingCart_Available_Credits: "Pieejamie kredītpunkti",
	ConfirmItsMeModal_Hi: "Sveiki",
	ConfirmItsMeModal_Description1: "Jūs esat veiksmīgi izpildījis sava uzņēmuma administratora uzaicinājumu izveidot personīgo kontu Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Pirms turpināt, lūdzu, apstipriniet vai atjauniniet sava konta informāciju.",
	ConfirmItsMeModal_Description3: "Pēc apstiprināšanas Jums būs vēlreiz jāpiesakās, lai pabeigtu konta veidošnās procesu.",
	ConfirmItsMeModal_CompanyDetails: "Uzņēmuma dati",
	ConfirmItsMeModal_YourDetails: "Jūsu dati",
	ConfirmItsMeModal_YourDetails_Title: "Uzruna",
	ConfirmItsMeModal_YourDetails_Function: "Amats",
	ConfirmItsMeModal_YourDetails_Name: "Vārds",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobila tālruņa numurs",
	ConfirmItsMeModal_YourDetails_Email: "E-pasta adrese",
	ConfirmItsMeModal_YourDetails_Language: "Valoda",
	ConfirmItsMeModal_ConfirmButton: "Apstiprināt",
	SelectWholesalerModal_WholesalerGroupLabel: "Vairumtirgotājs",
	SelectWholesalerModal_WholesalerBranchLabel: "Filiāle",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Lūdzu izvēlēties",
	ApplyButton: "Pielietot",
	SelectWholesalerModal_Title: "Izvēlieties Jūsu vairumtirgotāju",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Vai vēlaties atcelt savu reģistrāciju?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Jūsu reģistrācija ir veiksmīgi atcelta!",
	WaitlistPopup_AddToWaitListSuccess: "Jūsu reģistrācija ir veiksmīga!",
	ErrorCommon_Header: "Kļūda",
	CommonButton_Close: "Slēgt",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive e-mācības",
	MyELearning_VideoPlayerClosingMessage: "Lūdzu, aizveriet šo logu, lai turpināt!",
	EmbeddedVideoPlayer_StartButtonText: "Sākt",
	EmbeddedVideoPlayer_RefresherButtonText: "Atgādinājums",
	DeactivateWholesalerModal_ConfirmMessage: "Vai tiešām vēlaties noņemt šo vairumtirgotāju?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Ielūguma e-pasts nosūtīts veiksmīgi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Aicināt atkārtoti",
	ContactUs_Mobile_LV: "+371 67802080",
	ContactUs_Address_LV: "Robert Bosch SIA,Mūkusalas iela 101,LV-1004 Rīga,Latvia",
	ConfirmItsMeModal_CompanyDetails_Name: "Uzņēmuma nosaukums",
	ConfirmItsMeModal_CompanyDetails_Address: "Adrese",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Pasta indekss",
	ConfirmItsMeModal_CompanyDetails_Phone: "Tālrunis",
	ConfirmItsMeModal_CompanyDetails_Email: "E-pasts",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Apmācību pieraksts atcelts veiksmīgi",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Neizdevās izveidot kontu. Konts jau pastāv!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Konta atjaunināšana neizdevās. Konts jau pastāv!",
	Footer_ExploreTrainingStories: "Izpētiet visus mūsu apmācību stāstus",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Sazināties",
	Breadcrumb_Stories: "Stāsti",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Pasniedzēja ikdiena",
	Breadcrumb_Stories_BoschTrainingBenefits: "Ko jūs iegūstat  Bosch apmācībās",
	Breadcrumb_Stories_TrainingApproach: "Mūsdienīga pieeja apmācību procesam",
	Breadcrumb_Settings: "Iestatījumi",
	Breadcrumb_ShoppingCart: "Grozs",
	Breadcrumb_Checkout: "Apmaksāt",
	Breadcrumb_OrderSummary: "Pasūtījuma informācija",
	Breadcrumb_Exception: "Iebildumi",
	Breadcrumb_Dashboard: "Vadības panelis",
	Breadcrumb_MyCourses: "Manas apmācības",
	Breadcrumb_MyELearning: "Manas e-apmācības",
	Breadcrumb_CompanyAdministration: "Uzņēmuma datu pārvalde",
	Breadcrumb_EmployeeManagement: "Darbinieku datu pārvalde",
	Breadcrumb_EmployeeAccount: "Darbinieku konti",
	Breadcrumb_Administration: "Administrācija",
	Breadcrumb_EmployeeAdministration: "Darbinieku kontu administrēšana",
	Breadcrumb_WholesalerManagement: "Vairumtirgotāja datu pārvalde",
	Breadcrumb_NewWholesalerAccountCreation: "Vairumtirgotāja konta izveidošana",
	Breadcrumb_Transactions: "Transakcijas",
	Breadcrumb_MyAccount: "Mans konts",
	Breadcrumb_TrainingCentres: "Mācību centri",
	Breadcrumb_EventCalendar: "Pasākumu kalendārs",
	Breadcrumb_TrainingCourseCatalogue: "Apmācību katalogs",
	Breadcrumb_RecommendedHotels: "Ieteicamās viesnīcas",
	Breadcrumb_CorporateInformation: "Korporatīva informācija",
	Breadcrumb_LegalNotice: "Juridisks paziņojums",
	Breadcrumb_DataProtectionNotice: "Paziņojums par datu aizsardzību",
	Breadcrumb_PrivacyPolicy: "Privātuma politika",
	Breadcrumb_TermCondition: "Lietošanas noteikumi",
	Breadcrumb_Terms: "Noteikumi",
	Breadcrumb_PrivacyStatement: "Paziņojums par konfidencialitāti",
	Breadcrumb_Cookies: "Sīkdatnes",
	Breadcrumb_Provider: "Nodrošinātājs",
	Breadcrumb_RegisterAccount: "Konta reģistrācija",
	Stories_ExploreMore: "Vairāk",
	CheckOutLayout_ShoppingCartHeader: "Grozs",
	CheckOutLayout_AddedItemCartMessage: "Jūs savam grozam pievienojat",
	CheckOutLayout_CheckYourCartMessage: "Apskatīt grozu",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Meklēt darbinieku",
	CheckOutLayout_OrderWasNotPlaced_Message: "Atvainojiet! Jūsu pasūtījums netika veikts",
	CheckOutLayout_RefusalReason: "Atteikuma iemesls",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Maksājums neizdevās. Lūdzu, mēģiniet vēlreiz, izmantojot citu maksājuma veidu",
	CheckOutLayout_SubmittingApiFailed_Message: "API iesniegšana neizdevās",
	CartItem_ChooseWorkshop_Message: "Lūdzu, vispirms izvēlieties uzņēmumu, lai izvēlētos dalībnieku",
	CartItem_ChooseParticipant_Message: "Lai turpinātu, lūdzu, izvēlieties dalībnieku",
	CompanyAdministrationTable_SearchBox_Placeholder: "Meklēt uzņēmumu",
	CartItem_RemoveCartItem_ConfirmText: "Vai vēlaties izņemt šo preci no groza?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Amats",
	AddToCartSuccessModal_Message: "Jūsu iepirkumu grozs ir atjaunināts!",
	AddToCartSuccessModal_ContinueButtonText: "Turpināt iepirkties",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mans iepirkumu grozs",
	AddVoucher_Header: "Pievienot kuponu",
	AddVoucher_InputPlaceholder: "Ievadiet kodu un piesakieties",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Diemžēl šobrīd mums nav ieplānotas šādā veida apmācību kursi. Lūdzu, reģistrējieties gaidīšanas sarakstā, un mēs jūs informēsim, tiklīdz pasākums būs pieejams.",
	ADYEN_ERR_2_: "Darījums ir atteikts",
	ADYEN_ERR_3_: "Novirzīšanas kļūda",
	ADYEN_ERR_4_: "Darījums atteikts kļūdas dēļ, kas radās pircēja pusē",
	ADYEN_ERR_5_: "Darījumam izmantotā karte ir bloķēta, karte nav lietojama.",
	ADYEN_ERR_6_: "Darījumam izmantotās kartes derīguma termiņš ir beidzies. Karte nav lietojama.",
	ADYEN_ERR_7_: "Darījuma procesa laikā radās summas neatbilstība.",
	ADYEN_ERR_8_: "Norādītais kartes numurs ir nepareizs vai nederīgs.",
	ADYEN_ERR_9_: "Nav iespējams sazināties ar pircēja banku, lai apstiprināt darījumu.",
	ADYEN_ERR_10_: "Pircēja banka neatbalsta vai neatļauj šāda veida darījumus.",
	ADYEN_ERR_11_: "3D drošības pārbaude netika veikta vai tā netika veiksmīgi pabeigta.",
	ADYEN_ERR_12_: "Kartes kontā nav pietiekami daudz naudas, lai segtu maksājamo summu. Šī problēma var rasties Jūsu kartes ikmēneša maksājamās summas ierobežojuma dēļ. Lūdzu, mēģiniet izmantot citu karti, piemēram, MasterCard, vai sazinieties ar banku, lai mainīt kartes mēneša limitu.",
	ADYEN_ERR_14_: "Krāpšanas draudi",
	ADYEN_ERR_15_: "Darījums tika atcelts",
	ADYEN_ERR_16_: "Pircējs atcēla darījumu",
	ADYEN_ERR_17_: "Norādītais PIN ir nepareizs vai nederīgs",
	ADYEN_ERR_18_: "Nepareizs PIN ievadīts vairāk nekā 3 reizes pēc kārtas",
	ADYEN_ERR_19_: "Ievadīto PIN kodu nav iespējams pārbaudīt",
	ADYEN_ERR_20_: "Krāpšanas draudi",
	ADYEN_ERR_21_: "Darījums netika pareizi iesniegts apstrādei.",
	ADYEN_ERR_22_: "Risku novērtējuma pārbaudes laika darījums tika atzīmēts kā iespējami krāpniecisks (riska rādītājs >= 100); tāpēc operācija ir atcelta.",
	ADYEN_ERR_23_: "Atteikuma iemesla apraksts: Darījums nav atļauts izdevējam/kartes īpašniekam",
	ADYEN_ERR_24_: "CVC kods (kartes drošības kods) nav derīgs",
	ADYEN_ERR_25_: "Atteikuma iemesla apraksts: Karteir nederīga šajā valstī",
	ADYEN_ERR_26_: "R1: Autorizācijas rīkojuma atsaukšana / \"R3: Visu autorizācijas rīkojumu atsaukšana\" / \"R0: Maksājuma apturēšanas rīkojums\"",
	ADYEN_ERR_27_: "Šīs algoritms ietver visus atbilžu variantus, kurus nevar droši klasificēt. Tādējādi ir vieglāk atšķirt vispārīgus noraidījumus (piemēram, Mastercard atbildi \"05: Neapstiprināt\") no specifiskiem.",
	ADYEN_ERR_28_: "Maksājuma limits ir pārsniegts",
	ADYEN_ERR_29_: "Ir pārsniegts pircēja kartes atļautais maksājumu skaits.",
	ADYEN_ERR_31_: "Kartes emitents ziņoja par šo darījumu kā aizdomīgo",
	ADYEN_ERR_32_: "Norādīti nekorekti pirceja adreses dati",
	ADYEN_ERR_33_: "Banka pieprasa tiešsaistes PIN koda ievadīšanu",
	ADYEN_ERR_34_: "Lai pabeigt pirkumu, pircēja banka pieprasa pārbaudīt norēķinu kontu.",
	ADYEN_ERR_35_: "Lai pabeigt pirkumu, pircēja banka pieprasa krājkonta atvēršanu.",
	ADYEN_ERR_36_: "Pircēja banka pieprasa ievadīt PIN kodu mobilajā ierīcē.",
	ADYEN_ERR_37_: "Pircējs atteicās no darījuma pēc tam, kad neizdevās veikt bezkontakta maksājumu, un viņam tika piedāvāts izmēģināt citu kartes ievades metodi (PIN kodu vai novelkot karti nolasītajā).",
	ADYEN_ERR_38_: "Iestāde kas izsniedza karti noraidīja autentifikācijas pieprasījumu un pieprasa darījuma autentifikāciju. Mēģiniet vēlreiz, izmantojot 3D Secure.",
	ADYEN_ERR_39_: "Iestāde kas izsniedza karti vai programma nevarēja paziņot rezultātu izmantojot Rreq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Vārdā nedrīkst būt speciālās rakstzīmes (piemēram, @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Vārdam ir jābūt mazākam par 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Uzvārdā nedrīkst būt speciālās rakstzīmes (piemēram, @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Uzvārdam ir jābūt mazākam par 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobilā tālruņa numurs nedrīkst ietvert speciālās rakstzīmes (piemēram, @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobilā tālruņa numuram ir jābūt mazākam par 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Tālruņa numurs nedrīkst ietvert speciālās rakstzīmes (piemēram, @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Tālruņa numuram ir jābūt mazākam par 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "E-pasta adrese nav derīga",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-pasta adresei jābūt mazākai par 100",
	ConfirmGeolocationModal_ConfirmButton: "Turpināt",
	ConfirmGeolocationModal_CancelButton: "Mainīt",
	ConfirmGeolocationModal_CurrentCountrySelection: "Jūsu pašreizējā valsts izvēle ir:",
	ConfirmGeolocationModal_AskChangeCountry: "Vai vēlaties palikt šajā vietnē vai mainīt savas valsts preferences?",
	Addtocart_Modal_AddToCartFailTitle: "Neizdevās pievienot dalībnieku",
	Addtocart_Modal_AlreadyInCart: "Dalībnieks jau ir reģistrējies šim kursam",
	TrainingCatalogue_DateRange: "Data asortimentas",
	TrainingCatalogue_DateRange_placeholder: "Pasirinkite dienų seką",
	Dashboard_MyPendingCourses: "Pieteiktas apmācības",
	Dashboard_MyWaitingList: "Mans gaidīšanas saraksts",
	Dashboard_LatestNews: "Jaunākās ziņas",
	SettingsMainPage_EditAccountButton: "Konta iestatījumi",
	SettingsMainPage_EditBoschId: "Mainīt Bosch ID",
	EventInfoView_EventDetailTab: "Pasākuma informācija",
	EventInfoView_AssignmentsTab: "Pieteikumi",
	EventInfoView_EvaluationTab: "Novērtējumi",
	EventInfoView_AttachmentTab: "Pielikumi",
	EventInfoView_CourseDescriptionTab: "Apraksts",
	EventInfoView_DescriptionTab_General: "Vispārīgi",
	EventInfoView_DescriptionTab_PartLabel: "Sadaļa",
	AssignmentTab_Description: "Šeit jūs atradīsiet visu informāciju, kas būs nepieciešama, lai pabeigt šo kursu",
	AssignmentTab_Columns_Download: "Lejupielāde",
	AssignmentTab_Columns_Upload: "Augšupielāde",
	AssignmentTab_Columns_TaskComplete: "Uzdevums ir izpildīts",
	EventDetailTab_Location_Message_Video: "Šis ir tiešsaistes pasākums, kuram var piekļūt, izmantojot saiti \"Atskaņot video\"..",
	EventDetailTab_Location_Message_WebBased: "Šis ir tiešsaistes pasākums, kuram var piekļūt, izmantojot saiti \"Palaist WBT\".",
	EventDetailTab_Location_Message_WebCast: "Šis ir tiešsaistes pasākums, kuram var piekļūt, izmantojot saiti \"Pievienoties tūlīt\".",
	EventDetailTab_Link_Video: "Atskaņot video",
	EventDetailTab_Link_Video_Remark: "*Palaižot video, tiks atvērts jauns logs. Tiklīdz logs tiks aizvērts, jūs tiksit novirzīts atpakaļ uz šo lapu.",
	EventDetailTab_Link_WebBased: "Palaidiet Tiešsaistes apmācības (WBT)",
	EventDetailTab_Link_WebBased_Remark: "*Palaižot WBT, tiks atvērts jauns logs. Tiklīdz logs tiks aizvērts, jūs tiksit novirzīts atpakaļ uz šo lapu.",
	EventDetailTab_Link_WebCast: "Pievienoties",
	EventDetailTab_Link_WebCast_Remark: "*Saite \"Pievienoties tūlīt\" būs aktīva 30 minūtes pirms pasākuma sākuma.",
	EventDetailTab_Title_Status: "Statuss",
	EventDetailTab_Title_Duration: "Nodarbību ilgums (st)",
	EventDetailTab_Title_Trainers: "Pasniedzējs",
	EventDetailTab_Title_Language: "Valoda",
	EventDetailTab_Title_Score: "Testa rezultāts (%)",
	EventDetailTab_Title_Calendar: "Atzīmēt kalendārā",
	EventDetailTab_Title_Certificate: "Lejuplādēt sertifikātu",
	EventDetailTab_Title_Messages: "Ziņojumi",
	EventDetailTab_Title_StartDate: "Sakums",
	EventDetailTab_Title_AvailableFrom: "Pieejams no",
	EventDetailTab_Title_Time: "Laiks",
	EventDetailTab_Title_EndDate: "Beigu datums",
	EventDetailTab_Title_ExpiresOn: "Termiņš",
	EventDetailTab_Title_Room: "Telpa",
	EventDetailTab_Title_Method: "Veids",
	EventDetailTab_Title_Link: "Saite",
	EventDetailTab_Title_Location: "Atrašanas vieta",
	AttachmentTab_EmptyScreenDescription: "Šim pasākumam nav neviena pielikuma",
	AttachmentTab_Columns_Download: "Lejuplādēt",
	AttachmentTab_Description: "Šeit jūs atradīsiet svarīgu informāciju, kas palīdzēs šajā apmācību kursā",
	EvaluationTab_Annotation: "Mēs augstu vērtējam jūsu atsauksmes un labprāt uzzinātu, kā jūs vērtējat mūsu sniegum. Lūdzu, veltiet laiku, lai aizpildītu šo novērtējumu, lai palīdzētu mums nepārtraukti uzlabot jums sniegtos pakalpojumus. Paldies.",
	EvaluationTab_Notice: "Vērtējumi ir šādi",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Pirms pasākuma novērtējuma iesniegšanas, lūdzu, atbildiet uz visiem obligātajiem jautājumiem",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Jūsu atsauksmes ir veiksmīgi iesniegtas",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Novērtējuma iesniegšana neizdevās",
	TrainingCatalogueLayout_ExportButtonText: "Eksportēt",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Novērtējums ir iesniegts vai tā iesniegšanās termiņš ir beidzies",
	EventDetailTab_Link_CopiedTooltip: "Kopēts starpliktuvē",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skenējiet, lai palaistu WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skenējiet, lai palaistu video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skenējiet, lai palaistu Webcast",
	Footer_TrainingCoure_Headline: "Bosch nodrošinātie apmācības kursi",
	Footer_TrainingCourse_Content: "Kā pasaulē pazīstams Superbrand un ar vairāk nekā gadsimta pieredzi inovāciju jomā Bosch ir noteicis standartus visā pasaulē kā automobiļu sistēmu, detaļu un tehnisko risinājumu nodrošinātājs. Liela daļa mūsu spēka ir mūsu ciešā partnerība ar transportlīdzekļu ražotājiem, kuriem mēs projektējam, izstrādājam un ražojam vismodernākās sistēmas un komponentus visā pasaulē. Līdz ar to Bosch ir nostiprinājis spēcīgu vēsturi automobiļu pēcpārdošanas produktu un atbalsta jomā ar daudzveidīgu detaļu, diagnostikas risinājumu un darbnīcu pakalpojumu klāstu. Mācīšanās un attīstība ir drošas panākumu atslēgas, un šīs filozofijas pamatā ir plašs apmācību kursu klāsts visās automobiļu tehnoloģiju, transportlīdzekļu sistēmu un diagnostikas zināšanu jomās.",
	SearchField_NoRecordsFound: "Ieraksti nav atrasti",
	TrainingCatalogue_FreeTextSearch: "Bezmaksas teksts",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Meklēt",
	CheckoutLayout_WholeSalerGroup: "Izvēlieties vairumtirgotāju grupu",
	CreditInsufficientModal_Message: "Jums nepietiek līdzekļi , lai izmantotu šo maksājuma veidu. Lūdzu, mēģiniet vēlreiz, izmantojot citu maksājuma veidu.",
	ShoppingCart_Required_Credits: "Kopā",
	MaintainingInfo_Message1: "Vietne ir servisa režīmā.",
	MaintainingInfo_Message2: "Mēs drīz būsim atpakaļ.",
	CountryValidationModal_Message: "Jūs nevarējāt pieteikties [CountryA], jo jūsu konts ir izveidots [CountryB].",
	CountryValidationModal_ButtonText: "Periet uz [Country]",
	EventTable_LoginRequired: "Piesakieties, lai reģistrētos",
	TrainingPartTableLayout_Date: "Datums",
	TrainingPartTableLayout_StartTime: "Sākuma laiks",
	TrainingPartTableLayout_EndTime: "Beigu laiks",
	TrainingPartTableLayout_Duration: "Ilgums (h)",
	TrainingPartTableLayout_TrainingMethod: "Apmācības metode",
	MyCoursesTableContents_ActiveHeader: "Aktīvie kursi",
	MyCoursesTableContents_ActiveTabHeader: "Aktīvs",
	MyCoursesTableContents_ScheduledHeader: "Plānotie kursi",
	MyCoursesTableContents_ScheduledTabHeader: "Plānots",
	MyCoursesTableContents_OnlineHeader: "Tiešsaistes kursi",
	MyCoursesTableContents_OnlineTabHeader: "Tiešsaistē",
	MyCoursesTableContents_CancelledHeader: "Atceltie kursi",
	MyCoursesTableContents_CancelledTabHeader: "Atcelts",
	OnlineCoursesTable_Title_Progress: "Progress",
	ScheduledCoursesTable_Title_Date: "Datums [dienas]",
	CoursesTable_Title_Completed: "Pabeigts",
	Footer_TrainingProgram: "Apmācību programmas",
	Breadcrumb_TrainingProgram: "Apmācību programmas",
	TrainingProgram_Title_Table: "Nosaukums",
	TrainingProgram_TrainingProgram: "Apmācību programmas",
	TrainingProgram_TargetGroup: "Mērķa grupa",
	TrainingProgram_MaxLength: "Garums",
	TrainingProgram_Information: "Informācija",
	TrainingProgram_Detail: "Sīkāka informācija",
	TrainingProgram_Modules: "Moduļi",
	TrainingProgram_Course: "Kursi",
	TrainingProgram_PartNumber: "Artikula numurs",
	TrainingProgram_Max_Length: "Maksimālais garums",
	TrainingProgram_Duration: "Ilgums",
	TrainingProgram_Exam: "Pārbaude",
	TrainingProgram_Assign_Popup: "Reģistrējies apmācību programmai",
	TrainingProgram_Student: "Students",
	TrainingProgram_Assign: "Piešķirt",
	TrainingProgram_Popup_Remove_Title: "Treniņa atcelšana?",
	TrainingProgram_Description: "Apraksts",
	TrainingProgram_Days: "Dienas",
	TrainingProgram_Type: "Tips",
	TrainingProgram_Action: "Darbība",
	TrainingProgram_Enrolled: "jau ir reģistrēts šajā apmācību programmā",
	TrainingProgram_SelfAssign_Enrolled: "Jūs jau esat norīkots uz šo apmācību programmu",
	TrainingProgram_Warning: "Lūdzu, reģistrējiet citu studentu vai izvēlieties citu apmācību programmu.",
	TrainingProgram_Workshop: "Seminārs",
	TrainingProgram_Durations: "gadiem",
	TrainingProgram_Duration_DaysLabel: "dienas",
	TrainingProgram_HasExam_Yes: "Jā",
	TrainingProgram_HasExam_No: "Nē",
	AssignModal_SuccessModal_Header: "Apsveicam!",
	AssignModal_SuccessModal_Message: "ir veiksmīgi uzņemts šajā apmācību programmā.",
	AssignModal_SelfAssign_SuccessModal_Message: "Jūs esat veiksmīgi norīkots apmācību programmā.",
	Dashboard_MyTraningProgram: "Mana apmācības programma",
	Dashboard_MyTraningProgram_Title: "Nosaukums",
	Dashboard_MyTraningProgram_Status: "Statuss",
	Dashboard_MyTraningProgram_Table_View: "Skatīt",
	Dashboard_MyTraningProgram_Register: "Reģistrēties",
	Dashboard_MyTraningProgram_Records: "Vēstures ieraksti",
	Dashboard_MyTrainingProgram_In_Progress: "Notiek",
	Dashboard_MyTrainingProgram_Accredited: "Akreditēts",
	Dashboard_Program_Modal_Withdraw: "Izņemt",
	Dashboard_Program_Modal_Grade: "Novērtējums",
	Dashboard_MyTrainingProgram_Registered: "Reģistrēts",
	Dashboard_MyTrainingProgram_Completedd: "Pabeigts",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Jā",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nē",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Lūdzu, ņemiet vērā, ka izstāšanās nozīmē, ka jūs atteiksities no visas apmācības programmas, nevis tikai no atsevišķa apmācības kursa.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Vai joprojām vēlaties izstāties no apmācības programmas?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Gaida",
	WithdrawProgramModal_Withdraw_Success_Message: "Jūs esat veiksmīgi izstājies no apmācību programmas!",
	Common_Warning_Title: "Brīdinājums!",
	Common_Error_Title: "Ups, kaut kas nogāja greizi!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Pievienot grozam",
	AddEventFromCatalogToCartModal_HoursText: "stundas",
	AddElearningToCartSuccessModal_Message: "ir pievienots jūsu iepirkumu grozam!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Izvēlētais dalībnieks ir reģistrēts šim kursam vai arī Kurss jau ir pievienots grozam.",
	EventDetailTab_SubjectForChange_Message: "Var mainīties",
	Dashboard_MyTrainingProgram_Status_Failed: "Neizturēja",
	Dashboard_MyTrainingProgram_Status_Passed: "Nokārtots",
	Dashboard_MyTrainingProgram_Status_Merit: "Nopelni",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kursai",
	Dashboard_Employee_Program: "Apmācību programma",
	Dashboard_Employee_WaitList: "Gaidīšanas saraksts",
	Dashboard_Employee_Account: "Konts",
	Course_Price_Free: "Bezmaksas",
	Dashboard_Employee_Account_Update_Success_Message: "Jūs esat veiksmīgi atjauninājis savu darbinieku informāciju!",
	Dashboard_Supplier: "Piegādātājs",
	Event_Detail_Comment: "Pašlaik nav ziņojumu",
	DownLoad_PDF: "Lejupielādēt PDF",
	TrainingCatalogue_Currency: "Valūta",
	My_Wallet: "Mans maks",
	Wallet: "Maks",
	Credits_Balance: "Bilance",
	MyFullCalendar_Today: "Šodien",
	Header_Available_Languages: "Valodas",
	Header_CurrentRegion: "Šobrīd jūs atrodaties",
	Header_ChangeRegions: "Vai vēlaties mainīt valsti?",
	Header_Anonymous: "Servisa apmācības",
	Header_Anonymous_Login: "Pierakstīties/Reģistrēties",
	Header_RegisterCourse: "Reģistrēties apmācībām",
	Homepage_Start: "Sākums",
	Homepage_Login: "Pierakstīties",
	MainPage_Title: "Labākais autoservisa risinājums",
	MainPage_Content: "Jūsu autoserviss ir jūsu izvēle",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Ļauj modernajai darbnīcai profesionāli, uzticami un efektīvi veikt visu veidu transportlīdzekļu diagnostikas procedūrās, remontus un periodiskas apkopes.",
	BoschNews_TrainingNews: "Jaunumi",
	Service_Assist_Workshop: "Pilnveidojiet savu pieredzi ar Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Ko es varu darīt ar lietotni?",
	Enhance_Body_CDM3_Item_1: "Pieteikties apmācībām un kontrolēt apmācību procesu",
	Enhance_Body_CDM3_Item_2: "Lejuplādēt mācību materiālus un savus sertifikātus par apmeklētām apmācībām",
	Enhance_Body_CDM3_Item_3: "Izmantot tālmācību kursus",
	Enhance_Body_CDM3_Item_4: "Piekļūt dažādiem Auto nozares servisiem ar mobilas lietotnes palīdzību",
	Enhance_Body_CDM3_Item_5: "Video straumēšana ar Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Bezmaksas rīks automātiskai transportlīdzekļu skenēšanai",
	Training_Stories: "Pieredze",
	SettingsMyAccount_JoinCompanyButton: "Pievienot kontu uzņēmumam",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Pievienot kontu uzņēmumam",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Ievadiet savu aktivācijās kodu",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Ludzu sazināties ar sava uzņēmuma administratoru lai saņemt aktivācijās kodu",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Jūsu pieprasījums ir apstiprināts un Jūsu konts ir pievienots uzņēmumam",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Nospiediet tālāk lai apmeklēt vietni",
	ContinueButton: "Talāk",
	WelcomeBatsModal_Title: "Jūsu konts pašlaik tiek pārskatīts!",
	WelcomeBatsModal_ActivationLink: "Noklikšķiniet šeit, ja jums ir aktivizācijas kods, lai pievienotos uzņēmumam!",
	WelcomeBatsModal_ActivationLinkNotes: "Ludzu sazināties ar sava uzņēmuma administratoru lai saņemt aktivācijās kodu",
	AccountInReviewModal_Description_Paragraph1: "Pateicamies par konta izveidošanu Bosch Automotive Training Solution",
	AccountInReviewModal_Description_Paragraph2: "Mūsu darbinieki pašlaik pārskata un konfigurē jūsu kontu. Kad konts būs veiksmīgi konfigurēts, jūs saņemsiet galīgo apstiprinājumu savā e-astā.",
	AccountInReviewModal_CloseButton: "Labi, ieiet!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Mums neizdevās apstiprināt Jūsu aktivācijās kodu un e-pasta adresi",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Lūdzu, mēģiniet vēlreiz vai sazinieties ar sava uzņēmuma administratoru.",
	TryAgain_Button: "Mēģināt vēlreiz",
	Error_Title: "Oops, neizdevās!",
	SettingsMyProfileActivationSuccessModal_Title: "Veiksmīgi!",
	AccountInReviewModal_Description_Paragraph3: "Tikmer Jūs varat pārlūkot mūsu vietni ar ierobežoto piekļuvi.",
	AccountInReviewModal_Description_Paragraph4: "Ja Jums ir kādi jautājumi vai Jūsu konts netiek apstrādāts 48 stundu laikā, izņemot nedēļas nogales un svētku dienas, aicinām sazināties ar mums [šeit]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Dažas norādes, kas var Jums palīdzēt....",
	Support_FAQ_Headline: "Bieži uzdotie jautājumi",
	Support_FAQs_section1_title: "Vispārīgi",
	Support_FAQs_section1_question1: "Kas ir Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions ir platforma, kas sniedz visaptverošu pārskatu par tehniskajām apmācībām un ļauj rezervēt un pārvaldīt apmācības.",
	Support_FAQs_section1_question2: "Vai es varu izmantot savu esošo Bosch kontu (singleKey ID), lai pieteiktos Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Jā, tas ir pieejams visiem kontiem, kas izveidoti ar \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Vai varu izmantot Bosch Training Solutions izveidoto kontu ar citiem Bosch pakalpojumiem vai lietojumprogrammām?",
	Support_FAQs_section1_question3_answer1: "Jā, Jūs varat izmantot savu personīgo Bosch ID arī dažādām citām Bosch lietojumprogrammām — gan profesionālai, gan privātai lietošanai, piemēram, e-velosipēdam vai viedajai mājai.",
	Support_FAQs_section2_title: "Apmācības",
	Support_FAQs_section2_question1: "Ka es varu pieteikties apmācībām?",
	Support_FAQs_section2_question1_answer1: "Piesakieties ar savu esošo kontu vai reģistrējiet kontu portālā. Dodieties uz \"Apmācības\" vai \"Notikumu kalendārs\" izvēlieties apmācību kursu un noklikšķiniet uz pogas Pievienot kartei.",
	Support_FAQs_section2_question2: "Neder neviens no piedāvātiem datumiem. Ko es varu darīt?",
	Support_FAQs_section2_question2_answer1: "Jums ir iespēja pierakstīties \"vispārējā\" gaidīšanas sarakstā (neizvēloties datumu), un mūsu komanda Jūs informēs, tiklīdz būs pieejams jauns apmācību kurss.",
	Support_FAQs_section2_question3: ". Kur es varu redzēt savas apmācību rezervācijas?",
	Support_FAQs_section2_question3_answer1: "Pilnu pārskatu par visiem rezervētajiem un pabeigtajiem kursiem varat atrast pēc pieteikšanās savā konta informācijas panelī",
	Support_FAQs_section3_title: "Iestatījumi",
	Support_FAQs_section3_question1: "Ka es varu nomainīt savus reģistrācijas datus (e-pastu un paroli)?",
	Support_FAQs_section3_question1_answer1: "Jums ir jāpiesakās portālā, jāieiet savā personīgā profilā. Informācijas panelī varat atrast sava profila informāciju vai arī noklikšķināt uz pogas izvēlnes augšdaļā",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Šeit jums ir iespēja mainīt sava Bosch ID informāciju",
	Support_FAQs_section3_question2: "Ko darīt ja aizmirsu paroli",
	Support_FAQs_section3_question2_answer1: "Lūdzu, izmantojiet pieteikšanās/reģistrēšanās pogu un ievadiet savu e-pasta adresi",
	Support_FAQs_section3_question2_answer2: "Noklikšķiniet uz “Turpināt “un tad “Aizmirsu paroli”",
	Support_FAQs_section3_question3: "Ka var izveidot auto darbnīcas kontu?",
	Support_FAQs_section3_question3_answer1: "Ja vēlaties piešķirt savam kontam auto darbnīcas statusu, lūdzu, sazinieties ar vietējo apmācību administrāciju, piem. varat izmantot vietnē atrodamo saziņas formu.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Izvelēties valsti/reģionu",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions portāls tiks atjaunināts [maintenance_schedule]. Paredzams, ka atjaunināšana ilgs 30 minūtes. Atjaunināšanas laikā portāls nebūs pieejams.",
	MaintenanceBanner_Message_2: "\Lai saņemtu papildu informāciju, lūdzu, sazinieties ar mums izmantojot e-pastu [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions portāls pašlaik tiek atjaunināts",
	MaintenancePage_Message_2: "Mēs paredzam, ka portāls atkal būs pieejams [maintenance_online_date] plkst. [maintenance_online_time]",
	MenuHeader_Support: "Atbalsts",
	Course_Detail_No_Prerequisites: "Nav priekšnoteikumu",
	TableCell_Hours: "Stundas",
	Dashboard_MenuItem_Dashboard: "Informācijas panelis",
	Dashboard_MenuItem_Curricula: "Mācību programmas",
	Dashboard_MenuItem_Certificates: "Sertifikāti",
	Dashboard_MenuItem_Team: "Komanda",
	Dashboard_MenuItem_Workshops: "Darbnīca",
	Dashboard_MenuItem_External: "Ārpakalpojuma sniedzējs",
	Dashboard_MenuItem_Wallet: "Maks",
	Dashboard_MenuItem_Profile: "Profils",
	Dashboard_Headline_YourUpcomingCourses: "Gaidāmās apmācības",
	Dashboard_Headline_YourELearningCourses: "Tālmācību kursi",
	Dashboard_Headline_TeamOverview: "Jūsu komandas pārskats",
	Dashboard_Headline_YourOverview: "Jūsu pārskats",
	Dashboard_Headline_YourWishlist: "Vēlmju saraksts",
	Dashboard_Headline_ActivePrograms: "Aktuālās apmācību programmas",
	Dashboard_Headline_CompletedPrograms: "Pabeigtie apmācību kursi",
	Dashboard_Headline_YourCertificates: "Jūsu sertifikāti",
	Dashboard_Headline_Transactions: "Darījumi",
	Dashboard_Table_NoRecordsToDisplay: "Nav ierakstu",
	Dashboard_TableColumn_Location_Online: "Tiešsaistē",
	Dashboard_TableColumn_Duration_Hours: "Stundas",
	Dashboard_ELearningCourses_TableHeader_Title: "Nosaukums",
	Dashboard_ELearningCourses_TableHeader_Duration: "Ilgums (st.)",
	Dashboard_ELearningCourses_ActionButton_View: "Skats",
	Dashboard_UpcomingCourses_TableHeader_Title: "Nosaukums",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datums [dienas]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Vieta",
	Dashboard_UpcomingCourses_ActionButton_View: "Skats",
	Dashboard_YourOverview_TableHeader_Title: "Nosaukums",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datums / Ilgums",
	Dashboard_YourOverview_TableHeader_Location: "Vieta",
	Dashboard_YourOverview_TableHeader_Status: "Statuss",
	Dashboard_YourOverview_ActionButton_View: "Skats",
	Dashboard_YourWishlist_TableHeader_Title: "Nosaukums",
	Dashboard_YourWishlist_TableHeader_Action: "Darbība",
	Dashboard_YourWishlist_ViewAllRecords: "Skatīt ierakstus",
	Dashboard_TeamOverview_TableHeader_Employee: "Darbinieks",
	Dashboard_TeamOverview_TableHeader_Title: "Nosaukums",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datums / Ilgums",
	Dashboard_TeamOverview_TableHeader_Location: "Vieta",
	Dashboard_TeamOverview_TableHeader_Status: "Statuss",
	Dashboard_TeamOverview_ActionButton_View: "Skats",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Meklēt",
	Dashboard_ActiveProgram_TableHeader_Title: "Nosaukums",
	Dashboard_ActiveProgram_TableHeader_Status: "Statuss",
	Dashboard_ActiveProgram_ActionButton_View: "Skats",
	Dashboard_CompletedProgram_TableHeader_Title: "Nosaukums",
	Dashboard_CompletedProgram_TableHeader_Status: "Statuss",
	Dashboard_CompletedProgram_ActionButton_View: "Skats",
	Dashboard_Certificates_TableHeader_Title: "Nosaukums",
	Dashboard_Certificates_TableHeader_Date: "Datums",
	Dashboard_Certificates_TableHeader_Status: "Statuss",
	Dashboard_Certificates_TableHeader_Action: "Darbība",
	Dashboard_Certificates_ActionButton_Download: "Lejuplādēt",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Rēķina numurs",
	Dashboard_Transactions_TableHeader_Description: "Apraksts",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Maksājuma veids",
	Dashboard_Transactions_TableHeader_Date: "Datums",
	Dashboard_Transactions_TableHeader_Amount: "Summa",
	Dashboard_Transactions_ActionButton_Download: "Lejuplādēt",
	Breadcrumb_MyTraining: "Manas apmācības",
	Breadcrumb_MyTraining_CourseDetails: "Informācija par apmācībām",
	Breadcrumb_MyTraining_Curricula: "Apmācību plāns",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Informācija par apmācību programmu",
	Breadcrumb_MyTraining_Team: "Komanda",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Skatīt darbinieku",
	MyTraining_Headerbar_CourseDetails: "Informācija par apmācībām",
	MyTraining_Headerbar_TrainingProgramDetails: "Informācija par apmācību programmu",
	TrainingProgramDetails_Label_Status: "Statuss",
	TrainingProgramDetails_Label_OverallProgress: "Progress",
	TrainingProgramDetails_ProgramItems_Headline: "Programmas sadaļas",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Nosaukums",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Statuss",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Pakāpe",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datums",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Skats",
	TrainingProgramDetails_Status_InProgress: "Procesā",
	TrainingProgramDetails_Status_Completed: "Pabeigts",
	CourseDetails_Label_Status: "Statuss",
	CourseDetails_Label_Duration: "Ilgums",
	CourseDetails_Label_Trainer: "Pasniedzējs",
	CourseDetails_Label_Language: "Valoda",
	CourseDetails_Label_Score: "Rezultāts",
	CourseDetails_Label_StartDate: "Sakums",
	CourseDetails_Label_EndDate: "Beigas",
	CourseDetails_Label_Venue: "Norises vieta",
	CourseDetails_Label_Address: "Adrese",
	CourseDetails_Label_Grade: "Pakāpe",
	CourseDetails_Action_Withdraw: "Atteikties",
	CourseDetails_Action_Evaluate: "Vērtēt apmācības",
	CourseDetails_Action_Evaluate_Description: "Ludzu novērtējiet šīs apmācības lai saņemt sertifikātu!",
	CourseDetails_Action_DownloadCertificate: "Lejuplādēt sertifikātu",
	CourseDetails_Action_JoinWebcast: "Pievienoties tālmācību kursam",
	CourseDetails_Action_StartCourse: "Sākt kursu",
	CourseDetails_Action_RestartCourse: "Pārstartēt",
	CourseDetails_Action_RepeatCourse_Description: "Lūdzu, atkārtojiet un nokārtojiet šo kursu, lai lejupielādētu sertifikātu!",
	CourseDetails_PartSpecificDetails_Headline: "Informācija par sadaļu",
	CourseDetails_PartSpecificDetails_Tab_Part: "Sadaļa",
	CourseDetails_PartSpecificDetails_Description: "Apraksts",
	CourseDetails_PartSpecificDetails_StartDate: "Sakums",
	CourseDetails_PartSpecificDetails_EndDate: "Beigas",
	CourseDetails_PartSpecificDetails_StartTime: "Sakums",
	CourseDetails_PartSpecificDetails_EndTime: "Beigas",
	CourseDetails_PartSpecificDetails_Method: "Veids",
	Dashboard_Team_TableHeader_Name: "Vārds",
	Dashboard_Team_TableHeader_Role: "Funkcija",
	Dashboard_Team_TableHeader_Email: "E-pasts",
	Dashboard_Team_TableHeader_PersonalAccount: "Personīgais konts",
	Dashboard_Team_ActionButton_Select: "Izvelēties",
	Dashboard_Team_PersonalAccount_Activated: "Aktivizēts",
	Dashboard_Team_PersonalAccount_NotActivated: "Nav aktivizēts",
	Dashboard_Team_ActionButton_AddMember: "Pievienot dalībnieku",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Meklēt",
	Dashboard_External_TableHeader_Name: "Vārds",
	Dashboard_External_ActionButton_AddNew: "Pievienot",
	Dashboard_External_Headline: "Apstiprināta ārējā piekļuve",
	Dashboard_Workshops_Headline: "Darbnīca",
	Dashboard_Workshops_TableHeader_Company: "Uzņēmums",
	Dashboard_Workshops_TableHeader_Address: "Adrese",
	Dashboard_Workshops_TableHeader_Postcode: "Pasta indekss",
	Dashboard_Workshops_TableHeader_City: "Pilsēta",
	Dashboard_Workshops_ActionButton_Select: "Izvelēties",
	Wallet_ServiceCredits: "Kredītpunkti",
	Wallet_Headline_CreditBalance: "Bilance",
	Wallet_Headline_CreditActivity: "Darījumi ar kredītpunktiem",
	Wallet_Headline_BillingTransactions: "Transakcijas",
	Wallet_BillingTransaction_TableHeader_Order: "Pasūtījums",
	Wallet_BillingTransaction_TableHeader_Date: "Datums",
	Wallet_BillingTransaction_TableHeader_Amount: "Summa",
	Wallet_BillingTransaction_TableHeader_Action: "Darbība",
	Wallet_CreditActivity_TableHeader_Date: "Datums",
	Wallet_CreditActivity_TableHeader_Description: "Apraksts",
	Wallet_CreditActivity_TableHeader_User: "Lietotajs",
	Wallet_CreditActivity_TableHeader_Amount: "Summa",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, visas tiesības ir aizsargātas",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produktu drošība (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovācijas, patenti un licences",
	FooterAdditionalLinks_Navigation_Logistics: "Iepirkumi un loģistika",
	MenuHeader_MyTraining: "Manas apmācības",
	MenuHeader_TrainingServices: "Apmācību pakalpojumi",
	MenuHeader_OurLocations: "Atrašanas vietas",
	MenuHeader_ContactsUs: "Sazināties ar mums",
	MenuHeader_Logout: "Iarakstīties",
	MenuHeader_FAQ: "BUJ",
	MyProfile_MyBoschId: "Mans Bosch ID",
	MyProfile_AccountDeletion: "Dzēst kontu",
	MyProfile_Edit: "Mainīt e-pastu vai paroli",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Rediģēt profilu",
	BackButton: "Atpakaļ",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Mēs ceram uz jūsu zvanu un ar prieku jums palīdzēsim. Mēs esam pieejami 24 stundas, 7 dienas nedēļā.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Ievadiet pareizās rakstzīmes, kā norādīts instrukcijās augšējā logā",
	ContactUs_Email_MandatoryNotice_V3: "Aizpildiet visus nepieciešamos laukus",
	ContactUs_Email_SendButton: "Sūtīt",
	Breadcrumb_TrainingCenters: "Mācību centrs",
	Training_Centres_Book_Btn: "Rezervēt apmācības",
	Training_centres_booking_code: "Rezervācijas kods",
	Training_centers_header_bar_title: "Mūsu mācību centri",
	Training_centers_filter_item: "Centrs",
	TeamEdit_PersonalAccountToolTop: "Šī funkcija ļaus darbiniekam piekļūt šai vietnei. Lietotājs varēs izveidot personīgo kontu un būt daļa no jūsu uzņēmuma konta. Kad tas ir iespējots, darbiniekam varat nosūtīt ielūgumu ar aktivizācijas kodu. Uzaicinājums tiks nosūtīts uz e-pasta adresi.",
	TeamEdit_Update: "Iespējojot uzņēmuma administratora funkciju, darbiniekam automātiski tiek nodrošināta pilnīga piekļuve jūsu uzņēmuma kontam. Lai iespējotu šo funkciju, lietotājam vajadzēs izrakstīties un atkal pierakstīties  vietnē..",
	TeamEdit_PersonalAccount: "Atļaut izveidot personīgo kontu Bosch Training Solutions vietnē",
	TeamEdit_SendInvitation: "Nosūtīt ielūgumu",
	TeamEdit_Deactivate: "Deaktivizēt",
	TeamEdit_Telephone: "Tālrunis",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobila tālruņa numurs obligāts",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Tālruņa numurs obligāts",
	TeamEdit_Activate: "Aktīvs",
	TeamEdit_NotActivated: "Nav aktivizēts",
	TeamEdit_StatusPopup: "Statuss tiks rādīts kā “Aktīvs”, ja lietotājs ir veiksmīgi izveidojis personīgo kontu. Ja statuss ir iestatīts kā “Nav aktīvs”, tas nozīmē, ka lietotājs nav aizpildījis jūsu ielūgumu",
	Training_Catalog_Courses: "Apmācības",
	Training_Catalog_Course: "Apmācības",
	Reset_Filter: "Atiestatīt filtru",
	TrainingCatalogue_CourseCatalogue: "Apmācību katalogs",
	CheckoutHeader_CartItemCount_Text: "Jūsu iepirkumu grozā ir [count] prece",
	CheckoutHeader_CartItemsCount_Text: "Jūsu iepirkumu grozā ir [count] preces",
	ShoppingCart_PriceItem: "Cena",
	ShoppingCartItemParticipants_AddParticipant_Button: "Pievienot dalībnieku",
	ShoppingCartTotalPrice_SubTotalLabel: "Summa",
	ShoppingCartTotalPrice_TaxesLabel: "Nodokļi",
	ShoppingCartTotalPrice_TotalLabel: "Kopsumma",
	ShoppingCartTotalPrice_IncludedVATLabel: "Ar PVN [VAT_amount]",
	AddVoucher_AvailablePaymentMethods: "Pieejamie tiešsaistēs maksājumu veidi",
	ShoppingCartItemRow_VenueFeeLabel: "Cena vienam dalībniekam [fee_amount]",
	ShoppingCartItemRow_OnlineLabel: "Tiešsaistē",
	AddParticipantsModal_Title: "Pievienot dalībnieku",
	AddParticipantsModal_SelectEmployeeLabel: "Pievienot dalībnieku",
	AddParticipantsModal_SelectWorkshopLabel: "Izvēlēties darbnīcu",
	ShoppingCartTotalPrice_ContinueButtonText: "Uz apmaksu",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Dalībnieks nav pievienots",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Jūsu iepirkumu grozā ir viens vai vairāki kursi bez dalībnieka.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Lūdzu, pievienojiet dalībnieku, lai turpinātu norēķināšanos.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Rediģēt dalībniekus ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Vārds",
	AddParticipantsModal_ParticipantTable_Email: "E-pasts",
	AddParticipantsModal_ParticipantTable_Action: "Darbība",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nav dalībnieku...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-pasta adrese atlasītajam dalībniekam nav iestatīta",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Izvēlētā dalībnieka profilā nav ievadīta e-pasta adrese. E-pasta adrese ir obligāta, lai reģistrētos apmācību kursam.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Pievienojiet e-pasta adresi, lai turpinātu, vai atceliet, lai izvēlēties citu dalībnieku.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Pievienot e-pastu",
	CancelButton: "Atcelt",
	AddParticipantsModal_AddEmployeeButtonText: "Pievienot darbinieku",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Jūs gatavojaties dzēst kursu no sava groza",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Jūs mēģināt izņemt no groza kursu ar pievienotiem dalībniekiem.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Noklikšķiniet uz \"Turpināt\", ja vēlaties turpināt, vai noklikšķiniet uz \"Atcelt\", lai apturētu šo darbību.",
	ShoppingCartItemHeader_Participants: "Dalībnieks (-ki)",
	ShoppingCartItemHeader_ParticipantPrice: "Cena vienam dalībniekam",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Rādīt [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Rādīt kredītpunktos",
	ShoppingCart_CreditsPriceUnit: "Kredītpunkti",
	CheckOutHeader_Step1_Question: "Kā jūs vēlētos maksāt?",
	CheckOutHeader_Step2_Question: "Pārskatiet savu pasūtījumu",
	CheckOutHeader_Step3_Question: "Pasūtīt",
	CheckOutHeader_Step4_Question: "Jūsu rezervācija bija veiksmīga!",
	CheckOutHeader_Step_Payment: "Maksājums",
	CheckOutHeader_Step_Review: "Pārbaude",
	CheckOutHeader_Step_Place_Order: "Pasūtīt",
	CheckOutRightPanel_Review_Order_Btn: "Pārbaudīt pasūtījumu",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Pasūtīt",
	CheckoutRightPanelContent_OrderSummary_Title: "Kopsavilkums",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Rediģēt iepirkumu grozu",
	ShoppingCart_Participants: "Dalibnieki",
	CheckOutStepPayment_Payment_Methods_Title: "Izvēlieties maksājuma veidu",
	CheckOutStepPayment_Credit_Title: "Kredītkarte vai debetkarte",
	CheckOutStepPayment_Wholesaler_Title: "Vairumtirgotājs",
	CheckOutStepPayment_ServiceCredit_Title: "Kredītpunkti",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Jums ir [available_credit] kredītpunkti",
	CheckOutStepPayment_BoschAccount_Title: "Bosch konts",
	Checkout_Second_PaymentMethod_Headline: "Masājuma veids",
	CheckOutStepReview_Edit: "Rediģēt",
	CheckOutStepReview_Terms_Label: "Noteikumi, nosacījumi un atcelšanas politika",
	CheckOutStepReview_Terms_Text: "Esmu izlasījis, sapratis un piekrītu [terms, conditions and cancellation policy] .",
	SelectSecondPaymentMethodModal_Title: "Izvēlieties alternatīvo maksājuma veidu",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Maksāt [amount]",
	StepPaymentWholesaler_Title: "Izvēlieties vairumtirgotāju",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Pieņemt noteikumus un nosacījumus",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Pirms turpināt, lūdzu, izlasiet un piekrītiet mūsu noteikumiem, nosacījumiem un atcelšanas politikai.",
	CheckoutDescription_ThankYou: "Paldies, ka izvietojat pasūtījumu Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Mūsu biti un baiti jau ir sākuši jūsu pasūtījuma apstrādi. Tikmēr, lūdzu, ļaujiet dzinējam pastrādāt tukšgaitā.",
	CheckoutDesctiption_Text: "Mēs nosūtīsim jums galīgo apstiprinājumu e-pastā, iekļaujot visu informāciju par jūsu rezervāciju.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Jūs gatavojaties noņemt atlasīto darbnīcu",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Noņemot atlasīto darbnīcu, visi pievienotie dalībnieki tiks atiestatīti. Šīs izmaiņas ietekmēs visus kursus jūsu iepirkumu grozā.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Noklikšķiniet uz \"Turpināt\", ja vēlaties turpināt, vai noklikšķiniet uz \"Atcelt\", lai apturētu šo darbību.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Jums nav pietiekami daudz kredītpunktu. Jūsu kredīta atlikums ir [available_credit] kredītpunkti, lai norēķināties par iepirkumu grozu, jums ir nepieciešami [cart_total_credits] kredītpunkti. Lūdzu, izvēlieties citu maksājuma veidu vai izņemiet preces no iepirkumu groza.",
	Training_centers_filter_items: "Centrs",
	Training_Programs_Filter_Item: "Programma",
	Training_Program_Filter_Item: "Programmas",
	Training_Program_Year: "Gads (-i)",
	Training_Program_Detail_Title: "Apmācību programmas",
	AccountButton_Workshops_MenuItem: "Darbnīcas",
	AccountButton_Wallet_MenuItem: "Maks",
	Header_MyBoschProfile_Header: "MyBosch profils",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Pieejami",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Pievienot/rediģēt dalībnieku",
	ShoppingCart_CreditPriceUnit: "Kredītpunkti",
	Common_IncludedVAT: "Ar PVN",
	TrainingCatalogue_Length: "Ilgums",
	TrainingCatalogue_Method: "Metode",
	Course_Price_Credits: "Kredītpunkti",
	Catalogue_Grid_Register_Button: "Reģistrēt",
	Catalogue_Credit_Tilte: "Noklikšķiniet šeit, lai skatītu cenas kredītpunktos",
	Catalogue_Price_Tilte: "Noklikšķiniet šeit, lai skatītu cenas EUR",
	Catalogue_Grid_Vat: "Bez PVN",
	EventCalendar_Seats: "Vietas",
	MenuHeader_Calendar: "Kalendārs",
	MenuHeader_Catalog: "Katalogs",
	TableCell_Minutes: "Minūtes",
	ListFilter_NoResult: "Nav rezultāta",
	Course_Description: "Apmācību apraksts",
	Training_course_detail: "Sociālo mediju pamati",
	Training_course_detail_add_waiting: "Pievienot vispārējam gaidīšanas sarakstam",
	Register_now_btn: "Reģistrēties tagad !",
	Register_now_btn_add_event_to_cart: "Reģistrēties tagad !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Rādīt visus ierakstus",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Rādīt mazāk",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Rādīt visus ierakstus",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Rādīt mazāk",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Rādīt visus ierakstus",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Rādīt mazāk",
	TeamCreate_TitleError: "Nosaukums ir obligāts",
	Dashboard_Headerbar_BookCourse: "Rezervēt apmācības",
	Dashboard_Headerbar_ExitProxy: "Atslēgties no stapniekservera",
	Dashboard_Headerbar_EvaluateCourse: "Vērtēt apmācības",
	Dashboard_Headerbar_EvaluateCourse_Note: "Ludzu novērtējiet šīs apmācības lai saņemt sertifikātu!",
	Dashboard_Headerbar_DownloadCertificate: "Lejuplādēt sertifikātu",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Izņemt",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Izņemt",
	Dashboard_Headerbar_WelcomeMessage: "Laipni lūdzam jūsu mācību informācijas panelī",
	Dashboard_Headerbar_ProxingAs: "Starpniekserveris kā",
	Dashboard_TrainingProgram_Status_Completed: "Gatavs",
	Dashboard_TrainingProgram_Status_InProgress: "Procesā",
	External_SelectWholesalerModal_Title: "Pievienojiet ārējo lietotāju piekļuvi",
	External_SelectWholesalerModal_Company_Label: "Uzņēmums",
	External_SelectWholesalerModal_Outlet_Label: "Filiale",
	External_SelectWholesalerModal_Company_Placeholder: "Lūdzu izvēlieties",
	External_SelectWholesalerModal_Outlet_Placeholder: "Lūdzu izvēlieties",
	External_SelectWholesalerModal_ApplyButton: "Pieteikties",
	External_SelectWholesalerModal_CancelButton: "Atcelt",
	External_RemoveWholesalerModal_ConfirmLabel: "Apstiprinājums",
	External_SelectWholesalerModal_ConfirmMessage: "Vai tiešām vēlaties noņemt šo vairumtirgotāju?",
	External_SelectWholesalerModal_YesButton: "Jā",
	External_SelectWholesalerModal_NoButton: "Nē",
	Training_program_team_member: "Komandas biedrs",
	Training_program_assign_training_program: "Pierakstīt apmācību programmai",
	Assign_modal_student_confirmation_text1: "Jūs gatavojaties pievienot [Training Program Name] savam apmācību ceļojumam",
	Assign_modal_student_confirmation_text2: "Vai vēlaties turpināt?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mai",
	MyFullCalendar_Jun: "Jūn",
	MyFullCalendar_Jul: "Jūl",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Apmācību katalogs",
	MenuHeader_Event: "Pasakumu kalendārs",
	MenuHeader_Program: "Mācību programma",
	MenuHeader_Services: "Apmācību pakalpojumi",
	MenuHeader_Log_out: "Izrakstīties",
	Filter_Location: "Vieta",
	TeamEdit_UpgradeToCompanyAdministrator: "Pilnveidot kontu līdz uzņēmuma administratora līmenim",
	TeamEdit_PersonalWebAccountStatusIs: "Personīgā tīmekļa konta statuss ir",
	Header_CreateNewEmployee: "Izveidot jaunu darbinieku",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Vietu nav!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Atlasītajā pasākumā nav brīvu vietu.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Lai turpinātu, lūdzu, izvēlēties citu notikumu vai noņemiet jau pievienotu dalībnieku.",
	Common_SuccessModal_Title: "Apsveicam!",
	TrainingProgramDetails_Merit_StatusText: "Noperns",
	TrainingProgramDetails_Resit_StatusText: "Atkārtot",
	EmployeeProfile_UserExistErrorMessage: "Konts jau eksistē!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Maksājums neizdevās. Lūdzu, mēģiniet vēlreiz vai izmantojiet citu maksājuma veidu.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Jūs esat veiksmīgi deaktivizējis izvēlēta darbinieka kontu!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Saglabāts!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Izmaiņas ir saglabātas",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Izstāšanās nav iespējama e-mācību kursos. Lūdzu, sazinieties ar mums, ja nepieciešama sīkāka informācija.",
	Dashboard_Team_PersonalAccount_Pending: "Gaida",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Darbinieka profils ir izveidots!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Darbinieka profils ir veiksmīgi izveidots",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Šis iestatījums ļauj ierobežot darbinieka reģistrāciju apmācību pasākumiem. Ja šis iestatījums ir noņemts, tikai uzņēmuma administratori varēs reģistrēt darbinieku apmācību pasākumiem.",
	Training_catalog_sort_by: "Kārtot pēc",
	CourseDetail_EvaluationRestriction_Title: "Apmācību novērtējums nav iespējams!",
	CourseDetail_EvaluationRestriction_Message: "Apmācību vērtējumus var iesniegt tikai pats dalībnieks. \nLūdzu, uzdodiet dalībniekam pieteikties Bosch Automotive Training Solutions un pabeigt apmācību novērtēšanu",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Ja dalībniekam vēl nav konta, viņa kolēģis sava lietotāja profila iestatījumos var kopīgot ielūgumu",
	Breadcrumb_Download_Page: "Lejupielādēt",
	Download_Table_Category_Cell: "Kategorija",
	Download_Table_Title_Cell: "Tituls",
	Download_File_Filter_Item: "Fails",
	Download_File_Filter_Items: "Faili",
	Download_Header: "Lejupielādes apgabals",
	DownloadArea_Button_Download: "Lejupielādēt",
	Popup_Title_Error: "Kļūda",
	Popup_Title_Success: "Veiksmīgi",
	Popup_Title_Info: "Informācija",
	Popup_Title_Warning: "BRĪDINĀJUMS!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Pēc iespējamās notikumu reģistrācijas jau esošās, netiks atceltas, un tās būs jāatceļ individuāli.",
	TrainingProgram_PDF_Export: "PDF eksports",
	TrainingProgram_PDF_Export_Participant: "Dalībnieks:",
	TrainingProgram_PDF_Export_Status: "Statuss:",
	TrainingProgram_PDF_Export_Progress: "Kopējais progress:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Apmācības nosaukums",
	TrainingProgram_PDF_Export_TableHeader_Status: "Statuss",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Pakāpe",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datums",
	AccountButton_CompanyChange_MenuItem: "Uzņēmuma maiņa",
	SelectCompanyModal_Title: "Izvēlēties uzņēmumu",
	CloseButton: "Tuvumā",
	Change_Button: "Mainīt",
	ChangeManagerModal_Content: "Izvēlēties lietotāju",
	ChangeCompanyModal_HeadOffice: "(Galvenais birojs)",
	SelectCompanyModal_Content: "Lūdzu, atlasiet uzņēmums, lai pieteiktos ...",
	AlertSuccess_Title: "Veiksmīgi",
	CheckOutStepPayment_SecondaryPayment_Title: "Izvēlēties alternatīvo maksājuma metodi",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Pievienojiet alternatīvo maksājuma metodi",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Lūdzu, pievienojiet sekundāro maksājuma metodi zemāk vai atlasiet iepriekš minēto maksājuma metodi.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Jūsu kontā ir nepietiekams kredītpunktu daudzums , lai samaksātu par jūsu groza precēm. Lai turpinātu izmantot atlasīto maksājumu metodi, jums ir jāsamaksā starpība. Starpība [difference_amount] (bez PVN).",
	ShoppingCartTotalPrice_TotalInCredit: "Kopējā cena kredītpunktos",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Jūsu pieejamie kredīti",
	ShoppingCartTotalPrice_YourDifference: "Starpība",
	ShoppingCartTotalPrice_SubtotalExTax: "Kopsumma bez nodokļiem",
	ShoppingCartTotalPrice_SubtotalInTax: "Kopsumma ar nodokļiem",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Alternatīvā maksājuma metode",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Rediģēt maksājuma metodi",
	Checkout_Second_PaymentMethod: "Cita maksājuma metode",
	Breadcrumb_TrainingCourseDetails: "Apmācības kursa informācija",
	DownloadArea_FailedToDownload_ErrorMessage: "Dokumentu nav lejupielādēts. Lūdzu, mēģiniet vēlreiz vai sazinieties ar uzņēmuma administratoru, lai saņemtu palīdzību.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Lejupielādes fails nav pieejams. Lūdzu, mēģiniet vēlreiz vai sazinieties ar uzņēmuma administratoru, lai saņemtu palīdzību.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Lejupielādes fails ir bloķēts. Lūdzu, mēģiniet vēlreiz vai sazinieties ar uzņēmuma administratoru, lai saņemtu palīdzību.",
	SSO_Redirecting_Message: "Notiek novirzīšana uz Bosch apmācību portālu…",
	SSO_Redirecting_ParameterErrorMessage: "Nav izvelēta valsts un/vai valoda. Lūdzu mēģiniet vēlreiz vai vai sazinieties ar sava uzņēmuma administratoru",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Pieteikumu(s) neizdevās reģistrēt",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Tas var būt saistīts ar to, ka kādam no apmācību kursiem ir noteikts ierobežots pieteikšanas laiks. Ja jums ir kādi jautājumi vai pieprasījumi, lūdzu, izmantojiet sadaļu \"Sazinieties ar mums",
	TimeSlotPicker_DateFormat_Title: "Datuma pieraksta formāts",
	UpdateAccountSettingsConfirmationModal_Title: "Jūs grasāties mainīt datuma pieraksta formātu",
	UpdateAccountSettingsConfirmationModal_Message1: "Datuma pieraksta formāts tiks atainots atbilstoši jūsu izvēlei, jūs varēsiet to izmainīt jebkurā laikā",
	UpdateAccountSettingsConfirmationModal_Message2: "Turpināt?",
	ProfileSummary_Settings_Title: "Iestatījumi",
	UpdateAccountSettings_DateFormat_Title: "Datuma pieraksta formāta iestatījumi",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Datuma pieraksta formātu mainīt neizdevās",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Datuma pieraksta formāts ir mainīts",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Lejuplādēt sertifikātu",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Apmācību programmas sertifikāts",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Ja neizdevās lejuplādēt dokumentu, lūdzu pamēģiniet vēlreiz, vai sazinoties ar sava uzņēmuma administratoru",
	CourseWithdrawalModal_PendingWithdraw_Title: "Atteikuma termiņš beidzas",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Tiek piemērota neapstiprinātā atcelšana, tā ka atcelšanas pieprasījums iesnigts vēlāk par pieļaujamo. students saņems papildu informāciju e-pastā, tiklīdz pieprasījums būs apstiprināts/noraidīts",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Galīgais apstiprinājums tiks nosūtīts e-pastā",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Lai iegūtu sīkāku informāciju, lūdzu, skatiet mūsu [atcelšanās noteikumus].",
	TrainingCourse_Course_NotAvailable_Title: "Apmācības nav pieejamas",
	TrainingCourse_Course_NotAvailable_Message: "Apmācības nav pieejamas. Lūdzu pamēģiniet vēlreiz, vai sazinoties ar sava uzņēmuma administratoru",
	TrainingCourse_NotAvailable_Message_1: "Jūs mēģināt pieteikties apmācību kursam, kas nav pieejams",
	TrainingCourse_NotAvailable_Message_2: "Lūdzu pamēģiniet vēlreiz, vai izvelieties [training course]  lai iepazīties ari ar citiem apmācību kursiem",
	LanguageSelection_SelectALanguage: "Izvelēties valodu",
	LanguageSelection_SelectYourCountry: "Izvelēties valsti",
	Checkout_PaymentMethod_Headline: "Apmaksas veids",
	Download_Categories_Catalogue: "Katalogs",
	Download_Categories_Calendar: "Kalendārs",
	Download_Categories_General: "Visparīgi",
	Download_Categories_Information: "Informācija",
	Download_Categories_Userguides: "Lietotāja rokasgrāmata",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Atcelšanās noteikumi",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Atcelt",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Turpināt",
	Duration_Seconds: "Atcelt",
	Duration_Day: "Turpināt",
	Duration_Hour: "Sekundes",
	Duration_Minute: "Diena",
	Duration_Second: "Stunda",
	EventCalendar_ExternalLink_Tooltips: "Pirkums, izmantojot [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "Netiek izmantots",
	EventCalendar_ExternalLink_Cancel: "Atcelt",
	EventCalendar_ExternalLink_Continue: "Turpināt",
	EventCalendar_ExternalLinkModal_Title: "Jūs drīzumā tiksiet novirzīts",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Dalību atlasītajā pasākumā iespējams apmaksāt izmantojot [externalRegistrationName]",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Turpinot jūs novirzīs uz ārēju vietni.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Turpināt?",
	Team_ContactPerson_FirstName_Tooltips: "Studentu vārdus var mainīt tikai Bosch apmācību administrācijas komanda. Lūdzu, sazinieties ar mums, izmantojot saziņas veidlapu šajā vietnē, lai saņemt papildu atbalstu",
	Team_ContactPerson_LastName_Tooltips: "Studentu vārdus var mainīt tikai Bosch apmācību administrācijas komanda. Lūdzu, sazinieties ar mums, izmantojot saziņas veidlapu šajā vietnē, lai saņemt papildu atbalstu",
	Withdraw_ExternalLink_Cancel: "Atcelt",
	Withdraw_ExternalLink_Confirm: "Apstiprināt",
	Withdraw_ExternalLinkModal_Title: "Jūs drīzumā tiksiet novirzīts",
	Withdraw_ExternalLinkModal_Message: "Šī kursa atcelšanas procedūru veic ārējais pakalpojuma sniedzējs",
	Withdraw_ExternalLinkModal_Confirmation: "Noklikšķinot uz \"Apstiprināt\", tiks atvērta jauna cilne un Jūs novirzīs uz ārēju vietni.",
	Button_AddToWaitingList_Tooltips: "Pievienot gaidīšanās sarakstam",
	Button_AddToCart_Tooltips: "Pievienot grozam",
	Button_TrainingCourseDetail_Tooltips: "Skatīt detaļas",
	Button_TrainingProgramDetail_Tooltips: "Skatīt detaļas",
	Button_AssignTrainingProgram_Tooltips: "Nozīmēt apmācību programmu",
	Button_DeleteFromCart_Tooltips: "Izņemt no groza",
	Button_RemoveParticipant_Tooltips: "Noņemt dalībnieku",
	Button_DownloadBillingTransaction_Tooltips: "Lejuplādēt"
};