export const TRANSLATIONS_ID = {
	LandingPage_Title: "Selamat datang di Solusi Pelatihan Otomotif Bosch",
	LandingPage_SubTitle_ChooseCountry: "Pilih negara / wilayah Anda",
	Header_ContactUs: "Hubungi kami",
	Header_Search: "Mencari",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Masuk atau buat akun Anda",
	Header_LogIn: "Masuk",
	Header_Cart: "Keranjang",
	Header_ShoppingCart: "Keranjang belanja",
	Header_LogOut: "Keluar",
	Header_ChangeDetails: "Ubah Detail",
	Footer_ExploreOurTraining: "Jelajahi pelatihan kami",
	Footer_TrainingAtYourFingertips: "Berlatih di ujung jari Anda",
	Footer_DontMissAnyStory: "Jangan lewatkan cerita apa pun",
	Footer_ExploreOurStories: "Jelajahi cerita kami",
	Footer_GetInTouchWithUs: "Hubungi kami",
	Footer_SendUsAMessage: "Kirimi kami pesan",
	Footer_ToContactForm: "Untuk menghubungi formulir",
	Footer_InventedForLife: "Diciptakan seumur hidup",
	Footer_GeneralContactInfo: "Info kontak umum",
	Footer_ProductSecurity_Psirt: "Keamanan Produk (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Paten dan lisensi inovasi",
	Footer_PurchasingLogistics: "Pembelian & logistik",
	Footer_CorporateInformation: "Informasi perusahaan",
	Footer_LegalNotice: "Pemberitahuan hukum",
	Footer_DataProtectionNotice: "Pemberitahuan perlindungan data",
	Footer_PrivacySettings: "Pengaturan privasi",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch Gmbh semua hak dilindungi undang-undang",
	Footer_StillLookingForSomething: "Masih mencari sesuatu",
	Footer_TrainingServices: "Layanan Pelatihan",
	Footer_Courses: "Kursus",
	Footer_Programs: "Program",
	Footer_EventsCalendar: "Kalender Acara",
	Footer_TrainingCenters: "Pusat Pelatihan",
	Footer_Dashboard: "Dasbor",
	Footer_MyDashboard: "Dasbor saya",
	Footer_ShoppingCart: "Keranjang Belanja",
	Footer_YourShoppingCart: "Keranjang Belanja Anda",
	Footer_Administration: "Administrasi",
	Footer_Employees: "Karyawan",
	Footer_Wholesalers: "Pedagang grosir",
	Footer_Transactions: "Transaksi",
	Footer_Workshops: "Lokakarya",
	Footer_WorkLikeABoschApplyNow: "Work #LikeABosch - Lamar sekarang.",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Pembelian dan Logistik",
	Footer_BecomeABusinessPartner: "Menjadi mitra bisnis.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Bagaimana pelatihan kami berkembang untuk membantu bisnis Anda",
	Footer_Language: "Pilih negara",
	MenuPopup_MyCart: "Keranjang saya",
	Stories_OurTrainingStories: "Kisah Pelatihan Kami",
	Stories_ConnectingExperts: "Menghubungkan para ahli dan penggemar pemimpi dan pelaku inovator dan influencer - jelajahi dan alami",
	Stories_TopStories: "Cerita Teratas",
	Stories_AtHome: "Di rumah",
	Stories_Careers: "Karier",
	Stories_History: "Sejarah",
	Stories_IndustryAndTrades: "Industri dan perdagangan",
	Stories_Mobility: "Mobilitas",
	Stories_Research: "Penelitian",
	Stories_Sustainability: "Keberlanjutan",
	Stories_AllStories: "Semua cerita",
	Stories_BlogATrainersDay: "BLOG | Hari Pelatih",
	Stories_ADayinLifeOfATrainer: "Sehari dalam kehidupan seorang Pelatih",
	Stories_BoschServiceTraining: "Pelatihan Layanan Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "CERITA | Apa yang mendorong Anda mendorong kami",
	Stories_StoryHowCanWeBenefitYourBusiness: "CERITA | Bagaimana kami dapat menguntungkan bisnis Anda",
	Stories_HowOurTrainingEvolves: "Bagaimana pelatihan kami berkembang",
	Stories_AModernTrainingApproach: "Dunia pelatihan online",
	Stories_StoryAVirtualOnlineTrainingWorld: "CERITA | Peluang pelatihan online Anda",
	Stories_SubscribeToRSSFeed: "Berlangganan RSS feed",
	Title_Main: "Solusi Pelatihan Otomotif Bosch",
	Homepage_AboutUs: "Tentang kami",
	Homepage_AutomotiveAftermarket: "Aftermarket Otomotif",
	Homepage_GetInTouch: "Hubungi",
	Homepage_LatestNews: "Cari tahu mengapa kita didorong oleh kehausan akan pengetahuan",
	Homepage_MainTitle: "Solusi Pelatihan Otomotif Bosch",
	Homepage_OurOpenPositions: "Posisi terbuka kami",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Apa yang mendorong Anda mendorong kami",
	BoschServiceTraining_BoschServiceTraining: "Pelatihan Layanan Bosch",
	BoschServiceTraining_ShareThisOn: "Bagikan ini di",
	BoschServiceTraining_TheChallenge: "Tantangannya: Menguasai teknologi baru",
	BoschServiceTraining_WorldwidePresence: "Kehadiran di Seluruh Dunia",
	BoschServiceTraining_TechnicalTraining: "Kursus pelatihan teknis dari Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Penawaran dan peluang yang kami tawarkan di Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Ada kursus pelatihan yang cocok untuk setiap kelompok sasaran - baik pemula atau pro. Penawaran ini mencakup kursus pelatihan satu hari dan kursus pelatihan selama beberapa hari. Kursus pelatihan ditawarkan di berbagai bidang teknis dan seri pelatihan (seperti sistem listrik injeksi diesel injeksi bensin). Dalam kursus pelatihan teknis, Bosch memberikan pengetahuan luas di semua sistem kendaraan standar dari sistem Bosch dari produsen lain juga dibahas dalam kursus pelatihan. Konten tersebut secara khusus disesuaikan dengan persyaratan karyawan bengkel.",
	BoschServiceTraining_TrainedEmployeesDesc: "Karyawan yang terlatih adalah manfaat yang jelas untuk lokakarya otomotif. Karena kendaraan saat ini menjadi semakin kompleks dan teknologi baru terus menghadirkan tantangan baru. Hanya keahlian yang diperlukan yang memungkinkan karyawan bengkel untuk melakukan pemeliharaan diagnostik dan perbaikan pekerjaan pada model kendaraan saat ini dengan cara yang efisien dan hemat biaya.",
	BoschServiceTraining_RegardlessOfHow: "Terlepas dari seberapa baik dan berpengalaman spesialis otomotif - selalu ada situasi di mana mereka akan bersentuhan dengan sistem teknologi kendaraan baru atau kesalahan yang tidak diketahui. Dalam hal ini pemecahan masalah dan pemecahan masalah dapat menjadi sangat intensif waktu atau mungkin tidak memberikan hasil yang sukses. Dalam kasus terburuk pelanggan harus melakukannya tanpa kendaraan mereka secara signifikan lebih lama dari yang diharapkan.",
	BoschServiceTraining_ItIsPrecisely: "Justru pada titik inilah Bosch membawa Pelatihan Layanannya: meningkatkan kualitas layanan dan mempercepat proses. Pelatihan Bosch saat ini memberikan dukungan dalam melokalkan masalah dengan lebih cepat memperbaiki lebih banyak kendaraan dalam waktu yang sama dan mengurangi waktu tunggu pelanggan Anda. Dengan demikian prosedur sistematis yang dipelajari di Bosch memastikan pekerjaan yang lebih cepat dan lebih produktif.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Selama lebih dari 125 tahun Bosch telah menjadi pemasok pilihan komponen dan sistem suku cadang peralatan asli bagi banyak produsen mobil terkemuka di seluruh dunia. Pengalaman ini hadir dengan pengetahuan unik tentang teknologi terbaru yang berfungsi sebagai dasar untuk dukungan Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Pusat Pelatihan Layanan Bosch dapat ditemukan di mana-mana di dunia: dari Spanyol hingga Turki Afrika Selatan Australia hingga Brasil - Bosch menawarkan pelatihan pelatihan berorientasi praktik kepada karyawannya yang selalu sejalan dengan teknologi canggih.",
	BoschServiceTraining_AgileCompany: "Perusahaan Agile",
	BoschServiceTraining_Collaboration: "Kolaborasi",
	BoschServiceTraining_ConnectedMobility: "Mobilitas Terhubung",
	BoschServiceTraining_ArtificialIntelligence: "Kecerdasan Buatan",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Para pemimpin pemikiran dalam dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Peralatan Diagnostik dan Uji",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Dasar-dasar Teknologi Otomotif",
	BoschServiceTraining_Icon_Gasoline: "Bensin",
	BoschServiceTraining_Icon_Diesel: "diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Drive Alternatif",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Listrik dan Elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Tubuh dan Kenyamanan",
	BoschServiceTraining_Icon_ChassisAndBraking: "Sasis dan Pengereman",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisi dan Drivetrain",
	BoschServiceTraining_Icon_CommercialVehicles: "Kendaraan komersial",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Kursus Pelatihan Online",
	BoschServiceTraining_Icon_BusinessSkills: "Kemampuan bisnis",
	BoschServiceTraining_Icon_Accreditations: "Akreditasi",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Program Kursus Pelatihan",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Sehari dalam kehidupan seorang pelatih",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Setiap hari adalah petualangan",
	ADayInTheLifeOfATrainer_Summary: "Ringkasan",
	ADayInTheLifeOfATrainer_TheDayJourney: "Perjalanan hari itu",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Setiap hari adalah hari yang sibuk tetapi selalu merupakan pengalaman yang bermanfaat. Kesenangan melatih siswa mengetahui bahwa mereka memegang kunci untuk masa depan kita adalah tanggung jawab tetapi pada saat yang sama sangat menarik. Melihat siswa tumbuh dengan setiap pelajaran setiap hari dan setiap minggu membuktikan kepada kita bahwa kita sedang bekerja menuju tujuan bersama untuk meningkatkan pengetahuan mereka sambil menggabungkan teknologi mordern yang mendorong masa depan!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Pada akhir setiap hari kami memiliki duduk dan merenungkan hari kerja. Hari-hari berlalu begitu cepat tetapi ini adalah bukti itu sendiri bahwa apa yang kita capai tidak hanya bermanfaat bagi para siswa tetapi juga bagi kita sebagai pelatih.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Kita bisa pulang pada akhir hari dengan puas bahwa kita telah melakukan pekerjaan dengan baik!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Memulai",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Persiapan dan pemeriksaan akhir materi pelatihan. Periksa surat penting",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Pelatihan dimulai",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Selamat datang peserta. Presentasi jadwal harian. Kenali kelompok dengan memahami dinamika setiap individu.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teori",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Sejumlah interaksi dengan kelompok diperlukan untuk membuat pelatihan semenarik mungkin. Pastikan bahwa ada tugas yang terkait dengan teori untuk lebih melibatkan kelompok",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Istirahat makan siang",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktis",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Pelatihan berlanjut di bengkel dengan beberapa tugas praktis. Sebagai seorang pelatih, selalu ada persyaratan untuk hadir dan tersedia seperti biasa untuk segala kemungkinan dan bimbingan",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "rekap",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Kembali ke kelas sebentar, untuk membahas pelatihan hari itu. Jawab setiap pertanyaan yang mungkin muncul. Mulailah persiapan untuk sesi pelatihan berikutnya.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Hari terakhir",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Sertifikat kehadiran diberikan kepada para peserta.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Beranda,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Bagaimana kami dapat membantu bisnis Anda",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Bagaimana pelatihan kami berkembang",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Kami terus berkembang untuk memastikan kami memberikan pelatihan terkini yang berkualitas",
	BoschTrainingBenefits_TheBoschServiceTraining: "Pusat Pelatihan Layanan Bosch ingin membantu Anda dalam mengembangkan bisnis Anda melalui portofolio pelatihan teknis / bisnis yang luas dan sangat relevan serta kursus pendidikan lanjutan dengan kualifikasi bersertifikat. Halaman-halaman berikut memberi Anda gambaran umum tentang program pelatihan kami serta peluang pendidikan lanjutan saat ini. Kami berharap dapat bertemu Anda dan karyawan Anda di kursus pelatihan dan seminar kami.",
	BoschTrainingBenefits_LongStandingExperience: "Pengalaman lama dengan dan pengetahuan luas tentang semua sistem dan di bidang teknologi mobil.",
	BoschTrainingBenefits_QuickAndEfficient: "Solusi cepat dan efisien untuk banyak masalah.",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "Keuntungan dan manfaatnya",
	BoschTrainingBenefits_LMS: "Sistem Manajemen Pembelajaran - solusi Pelatihan Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "Fasilitas pengetahuan yang diperoleh memperbaiki pemeliharaan dan pekerjaan layanan.",
	BoschTrainingBenefits_TheHighDegree: "Tingkat konten praktis dan kelompok kecil yang tinggi menjamin pembelajaran yang berorientasi pada target",
	BoschTrainingBenefits_ParticipationInTraining: "Partisipasi dalam pelatihan meningkatkan motivasi dan kepuasan karyawan dan kualifikasi tambahan meyakinkan pelanggan",
	BoschTrainingBenefits_IncreasedSales: "Peningkatan penjualan melalui pengetahuan sistem lintas-membuat",
	BoschTrainingBenefits_MethodicalProcedures: "Prosedur metodis mengurangi waktu tunggu bagi pelanggan",
	BoschTrainingBenefits_HighlyQualified: "Instruktur berkualifikasi tinggi menyampaikan prosedur sistematis",
	BoschTrainingBenefits_IdentificationOftheTraining: "Identifikasi solusi pelatihan yang sesuai untuk Anda atau bisnis Anda",
	BoschTrainingBenefits_DirectOnlineBooking: "Pemesanan online langsung dan manajemen kursus baik untuk diri sendiri atau karyawan Anda",
	BoschTrainingBenefits_OnlineAccess: "Akses online ke dokumentasi pembelajaran",
	BoschTrainingBenefits_AccessToYourOwn: "Akses ke riwayat dan sertifikat pelatihan pribadi Anda",
	BoschTrainingBenefits_ToolsForManagement: "Alat untuk manajemen pengembangan karir Anda sendiri",
	BoschTrainingBenefits_ManagerHasFull: "Manajer memiliki gambaran lengkap tentang kursus karyawan mereka di dalam sistem",
	BoschTrainingBenefits_Access24hours: "Akses 24 jam 7 hari seminggu",
	ModernTrainingApproach_KnowledgeIsKey: "Pengetahuan adalah faktor kunci keberhasilan untuk setiap lokakarya. Itulah sebabnya Bosch menawarkan program pengembangan pelatihan profesional yang komprehensif. Ini memungkinkan siswa untuk memperoleh pengetahuan yang tepat pada waktu dan tempat yang tepat - pengetahuan yang diperlukan untuk dapat memelihara dan memperbaiki kendaraan pelanggan secara profesional. Bosch telah menambahkan webcasting sebagai bagian modul baru dari paket e-learning yang telah ditawarkan. Mereka terdiri dari sesi pelatihan layanan yang dirancang khusus untuk streaming.",
	ModernTrainingApproach_ANewLearning: "Kesempatan belajar baru",
	ModernTrainingApproach_YourOnlineTraining: "Peluang pelatihan online Anda",
	ModernTrainingApproach_RecentlyTheWorld: "Baru-baru ini dunia telah berubah melihat apa yang kami lakukan untuk membantu",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Dunia pelatihan online",
	ModernTrainingApproach_NewTeachingMethod: "Metode pengajaran baru",
	ModernTrainingApproach_InAdditionTo: "Selain pelatihan tatap muka yang ada, kami telah memasukkan pelatihan siaran langsung web dalam program pelatihan. Pelatih kami siap membantu Anda dengan pengetahuan teoretis yang luas di kelas virtual atau dengan contoh-contoh praktis. Seperti biasa Anda akan menerima banyak informasi tentang sistem kendaraan dan produk Bosch yang sesuai. Tentu saja Anda juga memiliki kesempatan untuk bertanya. Pertanyaan-pertanyaan ini akan dijawab langsung di siaran langsung web. Siaran web langsung berlangsung antara 60 hingga 120 menit. Diperlukan koneksi internet yang stabil. Cari webcast yang cocok langsung di katalog pelatihan kami dengan memasukkan \ Webcast \ di bidang pencarian.",
	ModernTrainingApproach_BoschWebcastingHeader: "Webcasting Bosch: Inovatif dan praktis",
	ModernTrainingApproach_BoschWebcastingText: "Untuk berpartisipasi dalam webcast Bosch terlebih dahulu, peserta harus melakukan dial-in. Kode akses individu dikirim sebelum sesi untuk mengaktifkan login. Hingga 200 peserta dapat mengambil bagian secara bersamaan dari sejumlah lokasi. Pelatihan ini dipimpin oleh seorang pelatih dan pertanyaan dapat diajukan kapan saja melalui fungsi obrolan langsung.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Suplemen digital untuk pelatihan kelas",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting dan pelatihan kelas saling melengkapi dengan sempurna. Dalam kedua kasus, pelatih yang berkualifikasi memimpin pelatihan. Webcast cocok untuk topik yang dapat dikirim secara digital tanpa perlu bekerja pada kendaraan. Misalnya mengenal fitur ESI [tronik] tertentu dari sistem bantuan pengemudi baru. Ketika datang ke kerja tim yang efisien di bengkel atau kerja praktis pada teknologi kendaraan pelatihan tatap muka masih merupakan pilihan pertama.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streaming bisa lebih murah",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Biaya per peserta biasanya lebih rendah dengan webcasting daripada dengan kursus kelas yang sebanding. Alasannya adalah bahwa lebih banyak peserta dapat memperoleh manfaat darinya dan upaya yang terkait dengan pelatihan secara signifikan lebih rendah (tidak ada perjalanan ke dan dari lokasi dan lebih sedikit waktu yang diperlukan).",
	ModernTrainingApproach_BookAndProfitHeading: "Buku dan untung",
	ModernTrainingApproach_BookAndProfitText: "Webcast dapat dipesan di situs web kami seperti kursus pelatihan dan penawaran pembelajaran kami yang lain. Anda memiliki pilihan untuk membayar biaya individu untuk setiap webcast atau mengambil langganan tahunan untuk sejumlah webcast.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch Webcasting dan pelatihan kelas dibandingkan",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Ruang kelas",
	ModernTrainingApproach_WebcastingP1: "Hingga 200 peserta dapat mengambil bagian pada saat yang sama dari mana saja di seluruh dunia",
	ModernTrainingApproach_WebcastingP2: "Kompleksitas rendah dan biaya yang relatif rendah tidak diperlukan perjalanan",
	ModernTrainingApproach_WebcastingP3: "Ideal untuk topik individual yang spesifik dan ringkas",
	ModernTrainingApproach_WebcastingP4: "Lokakarya dapat menjalankan webcast untuk karyawan mereka di mana saja dan kapan saja",
	ModernTrainingApproach_WebcastingP5: "Antara 50-70% lebih sedikit waktu yang dibutuhkan daripada untuk pelatihan di kelas",
	ModernTrainingApproach_ClassroomTrainingP1: "Hingga 16 peserta bertemu secara bersamaan",
	ModernTrainingApproach_ClassroomTrainingP2: "Biaya perjalanan dan umumnya biaya yang lebih tinggi per orang",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal untuk mengoptimalkan kolaborasi atau belajar langsung di kendaraan",
	ModernTrainingApproach_ClassroomTrainingP4: "Latihan praktis dan interaksi dengan pelatih",
	ModernTrainingApproach_ClassroomTrainingP5: "Pelatih dapat lebih menyesuaikan pengetahuan yang akan diajarkan sesuai dengan kebutuhan aktual para peserta",
	ModernTrainingApproach_OnlineTrainingOfferings: "Penawaran pelatihan online (WBT / VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Saat ini pembelajaran mandiri di komputer adalah bagian integral dari transfer pengetahuan. Kebebasan untuk memilih waktu dan menempatkan kecepatan belajar dan pembelajaran interaktif yang berulang tanpa batas menjadikan metode ini alat belajar yang sangat diperlukan.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Setiap elemen Pelatihan Berbasis Web (WBT) telah disusun dengan cermat. Selain memberikan basis pengetahuan yang luas, kursus ini dapat berfungsi sebagai persiapan yang efektif untuk program pelatihan di pusat pelatihan Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Simulasi diagnostik virtual menawarkan kemungkinan permainan peran pengguna untuk situasi lokakarya harian seperti alur kerja prosedur pemecahan masalah menggunakan perangkat diagnostik Bosch di bengkel virtual.",
	ModernTrainingApproach_Advantages: "Keuntungan",
	ModernTrainingApproach_AdvantagesP1: "Metode yang cepat dan efisien dalam memberikan pengetahuan",
	ModernTrainingApproach_AdvantagesP2: "Bervariasi pembelajaran",
	ModernTrainingApproach_AdvantagesP3: "Dapat digunakan sesering yang diinginkan",
	ModernTrainingApproach_AdvantagesP4: "Waktu dan uang minimal",
	ModernTrainingApproach_AdvantagesP5: "Kecepatan belajar individu",
	ModernTrainingApproach_AdvantagesP6: "Waktu belajar yang fleksibel",
	ModernTrainingApproach_AdvantagesP7: "Pengulangan",
	ModernTrainingApproach_AdvantagesP8: "Tidak ada biaya perjalanan",
	ModernTrainingApproach_AdvantagesP9: "Tidak ada kapasitas atau konflik penjadwalan",
	Dashboard_CoursesViewAndManageYourCourses: "KURSUS | Lihat dan kelola kursus Anda",
	Dashboard_MyCourses: "Kursus Saya",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM PELATIHAN | Lihat program pelatihan Anda",
	Dashboard_MyTrainingProgram: "Program Pelatihan Saya",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "MENGHASILKAN | Lihat dan kelola portofolio eLearning Anda",
	Dashboard_MyELearning: "ELearning saya",
	Dashboard_AccountManageYourAccount: "ACCOUNT | Kelola akun Anda",
	Dashboard_MyAccount: "Akun saya",
	Dashboard_MyDashboard: "Dasbor saya",
	Dashboard_ManageYourAccount: "Kelola akun Anda",
	Dashboard_Workshops: "Lokakarya",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRASI | Kelola bengkel Anda",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRASI | Kelola tim Anda",
	Dashboard_Administration: "Administrasi",
	Dashboard_ViewYourCourses: "Lihat kursus Anda",
	Dashboard_ViewYourTrainingPrograms: "Lihat program pelatihan Anda",
	Dashboard_TrainingPrograms: "Program Pelatihan",
	Dashboard_ViewAndEditYourDetails: "Lihat dan edit Detail Anda",
	Dashboard_ViewYourELearningCourses: "Lihat kursus eLearning Anda",
	Dashboard_Pending: "Tertunda",
	Dashboard_RequiresAdministratorSignOff: "(memerlukan Administrator keluar)",
	Dashboard_HistoricalRecords: "Catatan Sejarah",
	Dashboard_Registered: "Terdaftar",
	Dashboard_WaitingList: "Daftar Tunggu",
	Dashboard_RecommendedForYou: "Direkomendasikan untuk Anda",
	Dashboard_AccountDetails: "Detail Akun",
	Dashboard_ChangeProfileImage: "ubah gambar profil",
	Dashboard_Title: "Judul",
	Dashboard_Role: "Peran",
	Dashboard_FirstName: "Nama depan",
	Dashboard_LastName: "Nama belakang",
	Dashboard_Telephone: "Telepon",
	Dashboard_Mobile: "Mobile",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Bahasa",
	Dashboard_Save: "Menyimpan",
	Dashboard_Student: "Siswa",
	Dashboard_WorkshopTrainingAdministrator: "Administrator Pelatihan Lokakarya",
	Dashboard_WholesalerTrainingAdministrator: "Administrator Pelatihan Grosir",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administrasi Perusahaan",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Kelola bengkel Anda...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Kelola karyawan Anda...",
	Dashboard_CompanyAdministration_Workshop: "Bengkel",
	Dashboard_CompanyAdministration_Address: "Alamat",
	Dashboard_CompanyAdministration_Zip: "Zip",
	Dashboard_CompanyAdministration_City: "Kota",
	Dashboard_CompanyAdministration_Manage: "Mengelola",
	Dashboard_AdministrationSection_AdministrationTitle: "Administrasi",
	Dashboard_AdministrationSection_ManageTheTeam: "Kelola tim",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRASI | Kelola Karyawan Anda",
	Dashboard_AdministrationSection_Employees: "Karyawan",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRASI | Kelola Pedagang Besar Anda",
	Dashboard_AdministrationSection_Wholesalers: "Pedagang grosir",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRASI | Kelola Transaksi Anda",
	Dashboard_AdministrationSection_Transactions: "Transaksi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administrasi Karyawan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Kelola karyawan Anda...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Daftar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Catatan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Edit",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Karyawan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Menambahkan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Membuat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Terdaftar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Tertunda",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(memerlukan Administrator keluar)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Catatan Sejarah",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Daftar Tunggu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Daftar Karyawan Saat Ini",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Manajemen karyawan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nama",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Akun Karyawan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Lihat dan edit detail karyawan...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Pengaturan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Izinkan Akun Pribadi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Peningkatan Proxy Administrasi Lokakarya Karyawan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Izinkan Karyawan mendaftar untuk Acara Pelatihan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Kirim Undangan Email",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Nonaktifkan Akun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Menyimpan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Kirim",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Edit",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Manajemen Pedagang Besar",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Kelola grosir Anda...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Daftar Pedagang Besar yang Disetujui",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nama",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Menghapus",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Membuat",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Menambahkan",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Pembuatan Akun Karyawan Baru",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Buat detail untuk karyawan baru...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Tambahkan Administrator Pedagang Besar yang Disetujui baru",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Buat detail untuk grosir baru...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Pilih Pedagang Besar yang disetujui dari menu tarik-turun...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaksi",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Lihat dan kelola transaksi Anda...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Kembali",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Nomor Faktur",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Deskripsi",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Metode pembayaran",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Tanggal",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Jumlah",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Mengunduh",
	TrainingCentres_TrainingCentres: "Pusat Pelatihan",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Pusat pelatihan kami terletak di seluruh dunia",
	TrainingCentres_CountryCategory: "Kategori Negara",
	TrainingCentres_CityCategory: "Kategori Kota",
	TrainingCentres_Country: "Negara",
	TrainingCentres_City: "Kota",
	TrainingCatalogue_TrainingCatalogue: "Katalog Kursus Pelatihan",
	TrainingCatalogue_LearningAtTheHighestLevel: "Belajar di Tingkat tertinggi",
	TrainingCatalogue_StartDate: "Tanggal Mulai",
	TrainingCatalogue_EndDate: "Tanggal Berakhir",
	TrainingCatalogue_TrainingName: "Nama Pelatihan",
	TrainingCatalogue_Language: "Bahasa",
	TrainingCatalogue_Days: "Hari",
	TrainingCatalogue_Seats: "Kursi",
	TrainingCatalogue_Price: "Harga",
	TrainingCatalogue_Location: "Lokasi",
	TrainingCatalogue_PartNumber: "Nomor bagian",
	TrainingCatalogue_TrainingCourse: "Kursus Pelatihan",
	TrainingCatalogue_Category: "Kursus Pelatihan",
	TrainingCatalogue_CourseName: "Program Pelatihan",
	TrainingCatalogue_TotalDays: "Total Hari",
	TrainingCatalogue_Venue: "Tempat",
	TrainingCatalogue_Details: "Detail",
	TrainingCatalogue_Prerequisites: "Prasyarat",
	TrainingCatalogue_CourseType: "Jenis Kursus",
	TrainingCatalogue_LearningMethod: "Metode Belajar",
	TrainingCatalogue_AddToWaitingList: "Tambahkan ke daftar tunggu",
	ShoppingCart_ShoppingCart: "Keranjang Belanja",
	ShoppingCart_ReviewAndManage: "Tinjau dan kelola langganan Anda",
	ShoppingCart_YouHaveAddedItem: "Anda telah menambahkan item berikut ke troli Anda",
	ShoppingCart_Item: "Barang",
	ShoppingCart_Quantity: "Jumlah",
	ShoppingCart_PricePerItem: "Harga Per Item",
	ShoppingCart_TotalPrice: "Total harga",
	ShoppingCart_VatIsIncluded: "Tidak termasuk. PPN // termasuk atau dikecualikan?",
	ShoppingCart_Redeem: "Jika Anda tidak memiliki kredit klik di sini untuk menebusnya",
	ShoppingCart_Purchase: "Jika Anda tidak memiliki kredit klik di sini untuk membeli",
	ShoppingCart_Checkout: "Checkout",
	ShoppingCart_ContinueShopping: "Lanjutkan Berbelanja",
	ShoppingCart_TotalPriceInclVat: "Total harga termasuk. TONG",
	ShoppingCart_Apply: "Mendaftar",
	ShoppingCart_ShoppingCartEmpty: "Keranjang Belanja Anda kosong",
	ShoppingCart_VatIncluded: "PPN (Termasuk)",
	ShoppingCart_ManageYourShopping: "Kelola belanja Anda",
	ShoppingCart_Participant: "Peserta",
	ShoppingCart_Complete: "Lengkap",
	ShoppingCart_NetPrice: "Harga bersih",
	ShoppingCart_Workshop: "Bengkel",
	CheckOut_PreviewYourOrder: "Pratinjau Pesanan Anda",
	CheckOut_YourShoppingCart: "Keranjang",
	CheckOut_Preview: "Pratinjau",
	CheckOut_Payment: "Pembayaran",
	CheckOut_Summary: "Ringkasan",
	CheckOut_Back: "Kembali",
	CheckOut_PleaseReadAndAgreeOurTerms: "Harap baca dan setujui syarat dan ketentuan kami",
	CheckOut_IHaveReadTheTermsAndCondition: "Saya telah membaca [syarat dan ketentuan] dan saya mengerti dan menerimanya",
	CheckOut_YesIAgree: "Ya - Saya menyetujui syarat dan ketentuan",
	CheckOut_Continue: "Terus",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Selamat! Kami telah menerima pesanan Anda",
	CheckOut_OrderConfirmation: "Anda akan menerima konfirmasi pesanan akhir setelah permintaan Anda berhasil diproses",
	CheckOut_WeWillContactYouDirectly: "Kami akan menghubungi Anda secara langsung jika kami memiliki pertanyaan terkait permintaan Anda",
	CheckOut_AddtoCart: "Tambahkan ke Troli",
	CheckOut_CreditCart: "Keranjang Kredit",
	CheckOut_DirectDebit: "Debit Langsung",
	CheckOut_TrainingCredits: "Kredit Pelatihan",
	CheckOut_PreferredWholesaler: "Pedagang grosir pilihan",
	EventCalendar_EventCalendar: "Kalender Acara",
	EventCalendar_TrainingEventsForAllYourNeeds: "Acara pelatihan untuk semua kebutuhan Anda",
	TrainingCourse_TrainingCourse: "Katalog Kursus Pelatihan",
	TrainingCourse_LearningAtTheHighestLevel: "Belajar di Tingkat tertinggi",
	TrainingProgram_Title: "Katalog Program Pelatihan",
	TrainingProgram_SubTitle: "Belajar di Tingkat tertinggi",
	AccountDeactivationModal_Title: "Anda yakin ingin menonaktifkan akun ini?",
	AccountDeactivationModal_Line1: "Menonaktifkan akun ini bersifat permanen dan akan menghapus semua konten termasuk transaksi permintaan layanan dan menyeimbangkan anggota tim dan pengaturan profil.",
	AccountDeactivationModal_Line2Part1: "Jika dalam periode berlangganan aktif, silakan merujuk ke kebijakan pembatalan kami di kami",
	AccountDeactivationModal_Line2Part2: "syarat & ketentuan",
	AccountDeactivationModal_Line2Part3: "sebelum mengirimkan permintaan Anda.",
	AccountDeactivationModal_Line3: "Anda yakin ingin mengirimkan permintaan untuk menonaktifkan akun ini?",
	AccountDeactivationModal_Cancel: "Membatalkan",
	AccountDeactivationModal_Send: "Kirim",
	CurrentUserAccountDeactivationModal_Title: "Anda yakin ingin menonaktifkan akun Anda?",
	CurrentUserAccountDeactivationModal_Line1: "Menonaktifkan akun Anda bersifat permanen dan akan menghapus semua konten termasuk transaksi permintaan layanan dan menyeimbangkan anggota tim dan pengaturan profil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Jika Anda berada dalam periode berlangganan aktif, silakan merujuk ke kebijakan pembatalan kami di kami",
	CurrentUserAccountDeactivationModal_Line3: "Anda yakin ingin mengirimkan permintaan untuk menonaktifkan akun Anda?",
	CurrentUserAccountDeactivationModal_Yes: "Iya",
	CurrentUserAccountDeactivationModal_No: "Tidak",
	CourseWithdrawalModal_Title: "Pembatalan Anda belum final sebelum disetujui oleh administrasi pelatihan kami.",
	CourseWithdrawalModal_Line1: "Anda akan menerima konfirmasi akhir melalui email setelah disetujui. Jika kami memiliki pertanyaan, kami akan menghubungi Anda secara langsung. Anda yakin ingin mengirimkan permintaan penarikan Anda?",
	CourseWithdrawalModal_Line2Part1: "Silakan baca kami",
	CourseWithdrawalModal_Line2Part2Link: "Kebijakan Pembatalan",
	CourseWithdrawalModal_Line2Part3: "Lanjutkan dengan permintaan penarikan?",
	CourseWithdrawalModal_Yes: "Iya",
	CourseWithdrawalModal_No: "Tidak",
	Settings_Settings: "Pengaturan",
	Settings_ManageYourSettings: "Kelola pengaturan Anda",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Informasi Login ID Global Bosch saya",
	Settings_Edit: "Edit",
	Settings_CompanyDetails: "Detail Perusahaan",
	Settings_RequestAccountDeletion: "Minta Penghapusan Akun",
	Settings_AccountDeletionText: "Jika Anda menghapus akun Anda, semua data Anda (termasuk alamat dan data pembayaran Anda) akan dihapus dan tidak lagi tersedia.",
	Settings_DeleteAccountRequestConfirmation: "Hapus Konfirmasi Permintaan Akun",
	Settings_DeleteAccountRequestConfirmationText: "Kami telah menerima permintaan penghapusan akun Anda dan akan menghubungi Anda melalui email atau telepon untuk konfirmasi akhir",
	RecommendedHotels_RecommendedHotels: "Hotel yang Direkomendasikan",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Pusat pelatihan kami terletak di seluruh dunia",
	Error_Common: "Ada yang salah!",
	Error_404: "Halaman yang Anda kunjungi tidak ada",
	Error_401: "Anda tidak memiliki akses ke halaman ini",
	Error_500: "Server melaporkan kesalahan",
	Dashboard_Courses_Table_Course: "Kursus",
	Dashboard_Courses_Table_Date: "Tanggal [Hari]",
	Dashboard_Courses_Table_Venue: "Lokasi",
	Dashboard_Courses_Table_TrainingType: "Jenis Pelatihan",
	Dashboard_Courses_Table_Action: "Tindakan",
	Dashboard_Courses_Table_TasksToComplete: "Anda memiliki tugas berikut untuk diselesaikan",
	Dashboard_Courses_Table_Withdraw: "Menarik",
	Dashboard_Courses_Table_Remove: "Menghapus",
	Dashboard_Courses_Table_View: "Melihat",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Tidak ada catatan untuk ditampilkan",
	Dashboard_Courses_Table_CompletionDate: "tanggal penyelesaian",
	Dashboard_Courses_Table_Actions_Assignments: "Tugas",
	Dashboard_Courses_Table_Actions_Certification: "Sertifikasi",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluasi",
	Dashboard_Courses_Table_Actions_Assessments: "Penilaian",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Tambahkan ke Kalender",
	Dashboard_Courses_Table_CourseDetails_Task: "Tugas",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Batas tanggal terakhir",
	ContactUs_Headline: "Hubungi kami",
	ContactUs_HowCanWeHelp: "Bagaimana kami dapat membantu Anda?",
	ContactUs_EmailTab: "Surel",
	ContactUs_PhoneTab: "Telepon",
	ContactUs_LetterTab: "Surat",
	ContactUs_Phone_Headline: "Hubungi kami!",
	ContactUs_Phone_Description: "Kami menantikan panggilan Anda dan dengan senang hati akan membantu Anda. Kami tersedia untuk Anda 24 jam, 7 hari seminggu.",
	ContactUs_Phone_PhoneNumber: "Silakan gunakan formulir kontak untuk mengirim pesan kepada kami.",
	ContactUs_Phone_ChargeNotice: "Biaya bervariasi tergantung pada penyedia layanan dan negara Anda.",
	ContactUs_Letter_Headline: "Kirim surat!",
	ContactUs_Letter_Description: "Apakah Anda memiliki pertanyaan, permintaan, atau saran? Kami berharap untuk pertanyaan Anda.",
	ContactUs_Email_Headline: "Kirim Sebuah email!",
	ContactUs_Email_Description: "Kirimkan pesan Anda kepada kami dengan mengisi formulir di bawah ini. Salah satu penasihat kami akan dengan senang hati menghubungi Anda.",
	ContactUs_Email_InputName: "Nama*",
	ContactUs_Email_InputCompany: "Perusahaan",
	ContactUs_Email_InputCountry: "Negara*",
	ContactUs_Email_InputEmail: "Surel*",
	ContactUs_Email_InputMessage: "Pesan*",
	ContactUs_Email_DataProtection: "Pemberitahuan perlindungan data*",
	ContactUs_Email_DataProtectionConfirm: "Saya telah membaca Kebijakan Privasi.*",
	ContactUs_Email_SecurityCheck: "Pemeriksaan keamanan",
	ContactUs_Email_SecurityCodeConfirm: "Masukkan karakter yang benar yang Anda lihat di kotak di atas.",
	ContactUs_Email_SecurityCodeInvalid: "Jawaban teks captcha yang Anda masukkan salah.",
	ContactUs_Email_MandatoryNotice: "Silakan isi kolom wajib.",
	ContactUs_Email_SuccessMessage: "Pesan Anda telah terkirim",
	ContactUs_Email_TakenNoteDataProtection1: "Saya telah membaca",
	ContactUs_Email_TakenNoteDataProtection2: "Kebijakan pribadi.*",
	ContactUs_Email_SubmitButton: "Kirim",
	ContactUs_Email_FieldErrorMessage: "Silakan isi kolom wajib.",
	ContactUs_Email_SuccessModal_OK: "oke",
	AccountInReviewModal_Title: "Akun Anda sedang dalam peninjauan!",
	AccountInReviewModal_Paragraph1: "Terima kasih telah mendaftarkan akun pribadi di Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Staf pelatihan kami yang berdedikasi sedang meninjau dan mengonfigurasi akun Anda. Anda akan menerima konfirmasi akhir melalui surat, setelah akun Anda berhasil dikonfigurasi.",
	AccountInReviewModal_Paragraph3: "Sementara itu, silakan menelusuri situs web kami dengan akses terbatas.",
	AccountInReviewModal_Paragraph4: "Jika Anda memiliki pertanyaan, atau jika akun Anda tidak diproses dalam waktu 48 jam kecuali akhir pekan dan hari libur, Anda dapat menghubungi kami",
	AccountInReviewModal_ContactUsLink: "di sini",
	AccountInReviewModal_OKButton: "Oke, biarkan aku masuk!",
	Header_YesButton: "Ya",
	Header_NoButton: "Tidak",
	Header_LogOutText: "Apakah Anda ingin logout?",
	Footer_EnquiryText: "Kami berharap untuk pertanyaan Anda,",
	Footer_Telephone: "Telepon,",
	Footer_TermsAndConditions: "Syarat & Ketentuan",
	Footer_HowOurTrainingEvolves: "Bagaimana pelatihan kami berkembang",
	FooterSearch_SearchFieldPlaceholder: "Mencari",
	TrainingCatalogue_Action: "Tindakan",
	TrainingCatalogue_Duration: "Durasi",
	TrainingCatalogue_Information: "Informasi",
	Settings_PersonalDetails: "Data pribadi",
	Reset_All_Filters: "Setel ulang semua filter",
	EventTable_Days: "Hari",
	SearchField_AllResults: "Semua hasil",
	TrainingCentresLayout_ViewDetails: "Melihat rincian",
	Dropdown_Placeholder: "Memilih...",
	WaitlistPopup_SelectButtonText: "Pilih",
	WaitlistPopup_StartDate: "Mulai tanggal",
	WaitlistPopup_EndDate: "Tanggal Akhir",
	WaitlistPopup_Language: "Bahasa",
	WaitlistPopup_City: "Kota",
	WaitlistPopup_Workshop: "Bengkel",
	WaitlistPopup_Student: "Murid",
	WaitlistPopup_PreferredLocation: "Lokasi yang dipilih",
	WaitlistPopup_SignUpCourseWaitingList: "Daftar ke Daftar Tunggu Kursus",
	WaitlistPopup_SignUpWithoutSelectingDate: "Daftar ke Daftar tunggu tanpa memilih tanggal",
	WaitlistPopup_GeneralWaitList: "Daftar tunggu umum",
	WatinglistPopup_CourseLabel: "Kursus",
	Common_ContinueButton: "Melanjutkan",
	UserAccountDeactivationModal_ConfirmLabel: "Mengonfirmasi",
	Filter_FilterLabel: "Saring",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Anda sudah masuk daftar tunggu, apakah Anda ingin menghapus pendaftaran yang ada dan membuat yang baru?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ya, hapus",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Tidak, Batal",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Pembatalan kursus",
	WaitinglistPopup_SignUpButtonText: "Daftar",
	MyAccount_FirstNameRequied: "Nama Depan wajib diisi",
	MyAccount_LastNameRequired: "Nama Belakang wajib diisi",
	MyAccount_RoleRequired: "Peran diperlukan",
	MyAccount_MobileRequired: "Ponsel diperlukan",
	MyAccount_EmailRequired: "Email diperlukan",
	MyAccount_LanguageRequired: "Bahasa diperlukan",
	MyAccount_UpdateSuccessMessage: "Detail akun Anda berhasil diperbarui!",
	MyAccount_UpdateSuccessModal_ButtonText: "Ya, tutup",
	Infor_CorporateInformation_Header: "Informasi perusahaan",
	Infor_LegalNotice_Header: "Pemberitahuan Hukum",
	Infor_PrivacyPolicy_Header: "Pemberitahuan perlindungan data (Kebijakan privasi)",
	CreateEmployeeAccountModification_FormValidation_Role: "Peran diperlukan",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Nama depan wajib diisi",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Nama belakang wajib diisi",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Ponsel diperlukan",
	CreateEmployeeAccountModification_FormValidation_Email: "Email diperlukan",
	CreateEmployeeAccountModification_FormValidation_Language: "Bahasa diperlukan",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Undangan untuk bergabung dengan Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Anda telah berhasil menambahkan anggota baru ke tim Anda!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Apakah Anda ingin kami membagikan undangan email dengan anggota tim baru Anda, dengan instruksi bagaimana mereka dapat membuat akun pribadi mereka sendiri untuk Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Tidak",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ya, kirim undangan",
	ShoppingCart_Venue_Fee: "Biaya Tempat",
	ShoppingCart_Trainning_Name: "Nama Pelatihan",
	ShoppingCart_Available_Credits: "Kredit yang Tersedia",
	ConfirmItsMeModal_Hi: "Hai",
	ConfirmItsMeModal_Description1: "Anda telah berhasil menyelesaikan undangan yang dibagikan oleh administrator perusahaan Anda, untuk membuat akun pribadi Anda sendiri di Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Sebelum melanjutkan, harap konfirmasi atau perbarui detail akun Anda.",
	ConfirmItsMeModal_Description3: "Setelah mengonfirmasi, Anda akan diminta untuk masuk kembali untuk menyelesaikan pembuatan akun Anda.",
	ConfirmItsMeModal_CompanyDetails: "Detail Perusahaan",
	ConfirmItsMeModal_YourDetails: "Detail Anda",
	ConfirmItsMeModal_YourDetails_Title: "Judul",
	ConfirmItsMeModal_YourDetails_Function: "Fungsi",
	ConfirmItsMeModal_YourDetails_Name: "Nama",
	ConfirmItsMeModal_YourDetails_Mobile: "Seluler",
	ConfirmItsMeModal_YourDetails_Email: "Surel",
	ConfirmItsMeModal_YourDetails_Language: "Bahasa",
	ConfirmItsMeModal_ConfirmButton: "Mengonfirmasi",
	SelectWholesalerModal_WholesalerGroupLabel: "Grosir",
	SelectWholesalerModal_WholesalerBranchLabel: "Toko",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Silahkan pilih",
	ApplyButton: "Berlaku",
	SelectWholesalerModal_Title: "Pilih Grosir Anda",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Apakah Anda ingin menghapus pendaftaran Anda?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Pendaftaran Anda berhasil dihapus!",
	WaitlistPopup_AddToWaitListSuccess: "Pendaftaran Anda berhasil ditambahkan!",
	ErrorCommon_Header: "KESALAHAN",
	CommonButton_Close: "Menutup",
	MyELearning_OpeningVideoPlayerTitle: "eLearning Otomotif Bosch",
	MyELearning_VideoPlayerClosingMessage: "Harap tutup jendela ini untuk melanjutkan!",
	EmbeddedVideoPlayer_StartButtonText: "Awal",
	EmbeddedVideoPlayer_RefresherButtonText: "Penyegar",
	DeactivateWholesalerModal_ConfirmMessage: "Yakin ingin menghapus grosir ini?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Kirim email undangan berhasil!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Undang kembali",
	ContactUs_Mobile_ID: "+62(21)3005-5877",
	ContactUs_Address_ID: "PT. Robert Bosch,Palma Tower, 10th Floor,Jl. RA Kartini II-S Kav. 6,Pondok Pinang, Keb. Lama,Jakarta Selatan 12310,Indonesia",
	ConfirmItsMeModal_CompanyDetails_Name: "Nama perusahaan",
	ConfirmItsMeModal_CompanyDetails_Address: "Alamat",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Kode Pos",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telepon",
	ConfirmItsMeModal_CompanyDetails_Email: "Surel",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Penarikan dari Kursus berhasil!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Pembuatan akun gagal. Akun sudah ada!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Perbarui akun gagal. Akun sudah ada!",
	Footer_ExploreTrainingStories: "Jelajahi semua cerita pelatihan kami",
	Breadcrumb_Home: "Rumah",
	Breadcrumb_Contact: "Kontak",
	Breadcrumb_Stories: "cerita",
	Breadcrumb_Stories_BoschServiceTraining: "Pelatihan Servis Bosch",
	Breadcrumb_Stories_ADayInLife: "Sehari dalam kehidupan seorang pelatih",
	Breadcrumb_Stories_BoschTrainingBenefits: "Manfaat Pelatihan Bosch",
	Breadcrumb_Stories_TrainingApproach: "Pendekatan pelatihan modern",
	Breadcrumb_Settings: "Pengaturan",
	Breadcrumb_ShoppingCart: "Kereta Belanja",
	Breadcrumb_Checkout: "Periksa",
	Breadcrumb_OrderSummary: "Ringkasan Pesanan",
	Breadcrumb_Exception: "Pengecualian",
	Breadcrumb_Dashboard: "Dasbor",
	Breadcrumb_MyCourses: "Kursus Saya",
	Breadcrumb_MyELearning: "eLearning saya",
	Breadcrumb_CompanyAdministration: "Administrasi Perusahaan",
	Breadcrumb_EmployeeManagement: "Manajemen Karyawan",
	Breadcrumb_EmployeeAccount: "Akun Karyawan",
	Breadcrumb_EmployeeCreation: "Kreasi Karyawan",
	Breadcrumb_Administration: "Administrasi",
	Breadcrumb_EmployeeAdministration: "Administrasi Kepegawaian",
	Breadcrumb_WholesalerManagement: "Manajemen Grosir",
	Breadcrumb_NewWholesalerAccountCreation: "Pembuatan Akun Grosir Baru",
	Breadcrumb_Transactions: "Transaksi",
	Breadcrumb_MyAccount: "Akun saya",
	Breadcrumb_TrainingCentres: "Pusat Pelatihan",
	Breadcrumb_EventCalendar: "Kalender acara",
	Breadcrumb_TrainingCourseCatalogue: "Katalog Kursus Pelatihan",
	Breadcrumb_RecommendedHotels: "Hotel yang Direkomendasikan",
	Breadcrumb_CorporateInformation: "Informasi perusahaan",
	Breadcrumb_LegalNotice: "Pemberitahuan Hukum",
	Breadcrumb_DataProtectionNotice: "Pemberitahuan Perlindungan Data",
	Breadcrumb_PrivacyPolicy: "Kebijakan pribadi",
	Breadcrumb_TermCondition: "Syarat & Ketentuan",
	Breadcrumb_Terms: "Ketentuan",
	Breadcrumb_PrivacyStatement: "Pernyataan Privasi",
	Breadcrumb_Cookies: "Kue",
	Breadcrumb_Provider: "Pemberi",
	Breadcrumb_RegisterAccount: "Daftar Akun",
	Stories_ExploreMore: "Jelajahi lebih banyak",
	CheckOutLayout_ShoppingCartHeader: "Kereta Belanja",
	CheckOutLayout_AddedItemCartMessage: "Anda telah menambahkan yang berikut ini ke keranjang Anda",
	CheckOutLayout_CheckYourCartMessage: "Periksa keranjang Anda",
	ModernTrainingApproach_DateText: "1/10/2021 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Cari Karyawan",
	CheckOutLayout_OrderWasNotPlaced_Message: "Maaf! Pesanan Anda tidak dilakukan",
	CheckOutLayout_RefusalReason: "Alasan Penolakan",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Pembayaran gagal. Coba lagi dengan metode pembayaran lain",
	CheckOutLayout_SubmittingApiFailed_Message: "Mengirimkan API gagal",
	CartItem_ChooseWorkshop_Message: "Silakan pilih workshop untuk memilih peserta",
	CartItem_ChooseParticipant_Message: "Silakan pilih hadir untuk melanjutkan",
	CompanyAdministrationTable_SearchBox_Placeholder: "Cari Lokakarya",
	CartItem_RemoveCartItem_ConfirmText: "Apakah Anda ingin mengeluarkan item ini dari keranjang?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Fungsi",
	AddToCartSuccessModal_Message: "Masukkan kode dan terapkan",
	AddToCartSuccessModal_ContinueButtonText: "Lanjutkan Belanja",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Keranjang Belanja Saya",
	AddVoucher_Header: "Tambahkan voucher",
	AddVoucher_InputPlaceholder: "Masukkan kode dan terapkan",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Sayangnya, kami tidak memiliki acara terjadwal untuk kursus pelatihan ini saat ini. Silakan mendaftar ke daftar tunggu, dan kami akan memberi tahu Anda setelah acara tersedia.",
	ADYEN_ERR_2_: "Transaksi ditolak.",
	ADYEN_ERR_3_: "Kesalahan rujukan",
	ADYEN_ERR_4_: "Transaksi tidak berjalan karena kesalahan yang terjadi di pihak pengakuisisi.",
	ADYEN_ERR_5_: "Kartu yang digunakan untuk transaksi diblokir, sehingga tidak dapat digunakan.",
	ADYEN_ERR_6_: "Kartu yang digunakan untuk transaksi telah kedaluwarsa. Oleh karena itu tidak dapat digunakan.",
	ADYEN_ERR_7_: "Terjadi ketidaksesuaian jumlah selama proses transaksi.",
	ADYEN_ERR_8_: "Nomor kartu yang ditentukan salah atau tidak valid.",
	ADYEN_ERR_9_: "Tidak mungkin untuk menghubungi bank pembelanja untuk mengotorisasi transaksi.",
	ADYEN_ERR_10_: "Bank pembeli tidak mendukung atau tidak mengizinkan jenis transaksi ini.",
	ADYEN_ERR_11_: "Otentikasi 3D Secure tidak dijalankan, atau tidak berhasil dijalankan.",
	ADYEN_ERR_12_: "Kartu tidak memiliki cukup uang untuk menutupi jumlah yang harus dibayar. Masalah ini dapat terjadi karena batas jumlah pembayaran bulanan pada kartu Anda. Silakan coba menggunakan kartu lain seperti MasterCard, atau hubungi bank Anda untuk mengatur ulang batas bulanan kartu Anda.",
	ADYEN_ERR_14_: "Kemungkinan penipuan.",
	ADYEN_ERR_15_: "Transaksi dibatalkan.",
	ADYEN_ERR_16_: "Pembeli membatalkan transaksi sebelum menyelesaikannya.",
	ADYEN_ERR_17_: "PIN yang ditentukan salah atau tidak valid.",
	ADYEN_ERR_18_: "Pembeli memasukkan PIN yang salah lebih dari tiga kali berturut-turut.",
	ADYEN_ERR_19_: "Tidak mungkin untuk memvalidasi nomor PIN yang ditentukan.",
	ADYEN_ERR_20_: "Kemungkinan penipuan.",
	ADYEN_ERR_21_: "Transaksi tidak dikirimkan dengan benar untuk diproses.",
	ADYEN_ERR_22_: "Pemeriksaan risiko menandai transaksi tersebut sebagai penipuan (skor risiko >= 100); oleh karena itu, operasi dibatalkan.",
	ADYEN_ERR_23_: "Kode yang ditolak berikut dipetakan ke alasan penolakan ini: Transaksi tidak diizinkan untuk penerbit/pemegang kartu",
	ADYEN_ERR_24_: "CVC (kode keamanan kartu) yang ditentukan tidak valid.",
	ADYEN_ERR_25_: "Kode penolakan berikut dipetakan ke alasan penolakan ini: Kartu tidak valid di negara ini",
	ADYEN_ERR_26_: "R1: Pencabutan Perintah Otorisasi / \"R3: Pencabutan Semua Perintah Otorisasi\" / \"R0: Hentikan Perintah Pembayaran\"",
	ADYEN_ERR_27_: "Respons ini memetakan semua kode respons yang tidak dapat dipetakan dengan andal. Ini membuatnya lebih mudah untuk mengetahui penolakan umum (misalnya, respons Mastercard \"05: Jangan menghormati\") dari yang lebih spesifik.",
	ADYEN_ERR_28_: "Jumlah penarikan yang diizinkan untuk kartu pembelanja telah terlampaui.",
	ADYEN_ERR_29_: "Jumlah penarikan yang diizinkan untuk kartu pembelanja telah terlampaui.",
	ADYEN_ERR_31_: "Emiten melaporkan transaksi tersebut sebagai dugaan penipuan.",
	ADYEN_ERR_32_: "Data alamat yang dimasukkan pembeli salah.",
	ADYEN_ERR_33_: "Bank pembelanja mengharuskan pembelanja memasukkan PIN online.",
	ADYEN_ERR_34_: "Bank pembelanja memerlukan rekening giro untuk menyelesaikan pembelian.",
	ADYEN_ERR_35_: "Bank pembelanja memerlukan rekening tabungan untuk menyelesaikan pembelian.",
	ADYEN_ERR_36_: "Bank pembelanja mengharuskan pembelanja memasukkan PIN ponsel.",
	ADYEN_ERR_37_: "Pembeli mengabaikan transaksi setelah mereka mencoba pembayaran tanpa kontak dan diminta untuk mencoba metode entri kartu yang berbeda (PIN atau gesek).",
	ADYEN_ERR_38_: "Penerbit menolak permintaan pengecualian otentikasi dan memerlukan otentikasi untuk transaksi. Coba lagi dengan 3D Secure.",
	ADYEN_ERR_39_: "Penerbit atau skema tidak dapat mengomunikasikan hasilnya melalui RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Nama depan tidak boleh menyertakan karakter khusus (mis: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Nama depan harus kurang dari 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Nama belakang tidak boleh menyertakan karakter khusus (mis: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Nama belakang harus kurang dari 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Nomor ponsel tidak boleh menyertakan karakter khusus (mis: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Nomor ponsel harus kurang dari 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Nomor telepon tidak boleh menyertakan karakter khusus (mis: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Nomor telepon harus kurang dari 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Alamat email tidak valid",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Email harus kurang dari 100",
	ConfirmGeolocationModal_ConfirmButton: "Melanjutkan",
	ConfirmGeolocationModal_CancelButton: "Mengubah",
	ConfirmGeolocationModal_CurrentCountrySelection: "Pilihan negara Anda saat ini adalah:",
	ConfirmGeolocationModal_AskChangeCountry: "Apakah Anda ingin tetap berada di situs ini atau mengubah preferensi negara Anda?",
	Addtocart_Modal_AddToCartFailTitle: "Tambah peserta gagal",
	Addtocart_Modal_AlreadyInCart: "Peserta sudah terdaftar di Acara ini",
	TrainingCatalogue_DateRange: "Rentang Tanggal",
	TrainingCatalogue_DateRange_placeholder: "Pilih filter rentang tanggal",
	Dashboard_MyPendingCourses: "Kursus Tertunda Saya",
	Dashboard_MyWaitingList: "Daftar Tunggu Saya",
	Dashboard_LatestNews: "Berita Terbaru",
	SettingsMainPage_EditAccountButton: "Mengedit akun",
	SettingsMainPage_EditBoschId: "Edit ID Bosch",
	EventInfoView_EventDetailTab: "detail acara",
	EventInfoView_AssignmentsTab: "Tugas",
	EventInfoView_EvaluationTab: "Evaluasi",
	EventInfoView_AttachmentTab: "lampiran",
	EventInfoView_CourseDescriptionTab: "Keterangan",
	EventInfoView_DescriptionTab_General: "Umum",
	EventInfoView_DescriptionTab_PartLabel: "Bagian",
	AssignmentTab_Description: "Di sini Anda akan menemukan semua informasi yang Anda perlukan untuk menyelesaikan kursus ini.",
	AssignmentTab_Columns_Download: "Unduh",
	AssignmentTab_Columns_Upload: "Mengunggah",
	AssignmentTab_Columns_TaskComplete: "Tugas Selesai",
	EventDetailTab_Location_Message_Video: "Ini adalah acara online yang dapat diakses dengan menggunakan tautan \"Luncurkan Video\".",
	EventDetailTab_Location_Message_WebBased: "Ini adalah acara online yang dapat diakses dengan menggunakan tautan \"Luncurkan WBT\".",
	EventDetailTab_Location_Message_WebCast: "Ini adalah acara online yang dapat diakses dengan menggunakan tautan \"Bergabung Sekarang\".",
	EventDetailTab_Link_Video: "Luncurkan Video",
	EventDetailTab_Link_Video_Remark: "*Meluncurkan video akan membuka jendela baru. Anda akan diarahkan kembali ke halaman ini setelah jendela ditutup.",
	EventDetailTab_Link_WebBased: "Luncurkan WBT",
	EventDetailTab_Link_WebBased_Remark: "*Meluncurkan WBT akan membuka jendela baru. Anda akan diarahkan kembali ke halaman ini setelah jendela ditutup.",
	EventDetailTab_Link_WebCast: "Bergabung sekarang",
	EventDetailTab_Link_WebCast_Remark: "*Link Bergabung Sekarang akan aktif 30 menit sebelum acara dimulai.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Durasi (jam)",
	EventDetailTab_Title_Trainers: "Pelatih",
	EventDetailTab_Title_Language: "Bahasa",
	EventDetailTab_Title_Score: "Skor (%)",
	EventDetailTab_Title_Calendar: "Tambahkan ke kalender",
	EventDetailTab_Title_Certificate: "Unduh sertifikat",
	EventDetailTab_Title_Messages: "Pesan",
	EventDetailTab_Title_StartDate: "Mulai tanggal",
	EventDetailTab_Title_AvailableFrom: "tersedia dari",
	EventDetailTab_Title_Time: "Waktu",
	EventDetailTab_Title_EndDate: "Tanggal akhir",
	EventDetailTab_Title_ExpiresOn: "Kadaluarsa pada",
	EventDetailTab_Title_Room: "Ruang",
	EventDetailTab_Title_Method: "metode",
	EventDetailTab_Title_Link: "Tautan*",
	EventDetailTab_Title_Location: "Lokasi",
	AttachmentTab_EmptyScreenDescription: "Tidak ada lampiran untuk acara ini.",
	AttachmentTab_Columns_Download: "Unduh",
	AttachmentTab_Description: "Di sini Anda akan menemukan beberapa informasi penting untuk membantu Anda dengan kursus ini",
	EvaluationTab_Annotation: "Kami menghargai umpan balik Anda dan akan senang mendengar dari Anda untuk melihat bagaimana kinerja kami. Mohon luangkan waktu untuk mengisi evaluasi berikut untuk membantu kami untuk terus meningkatkan layanan kami kepada Anda. Terima kasih.",
	EvaluationTab_Notice: "Peringkatnya adalah sebagai berikut",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Harap jawab semua pertanyaan wajib sebelum mengirimkan evaluasi acara Anda",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Umpan balik Anda telah berhasil dikirim",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Pengiriman evaluasi gagal",
	TrainingCatalogueLayout_ExportButtonText: "Ekspor",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Evaluasi telah selesai atau telah kedaluwarsa.",
	EventDetailTab_Link_CopiedTooltip: "Disalin ke clipboard",
	EventDetailTab_QRCode_ExplanationText_WBT: "Pindai untuk meluncurkan Pelatihan Basis Web",
	EventDetailTab_QRCode_ExplanationText_Video: "Pindai untuk meluncurkan Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Pindai untuk meluncurkan Webcast",
	Footer_TrainingCoure_Headline: "Kursus pelatihan yang disediakan oleh Bosch",
	Footer_TrainingCourse_Content: "Sebagai Superbrand yang terkenal di dunia dan dengan lebih dari satu abad pengalaman dalam inovasi, Bosch telah menetapkan standar secara global sebagai penyedia sistem otomotif, suku cadang dan solusi teknis. Sebagian besar kekuatan kami berasal dari kemitraan erat kami dengan produsen kendaraan, untuk siapa kami merancang, mengembangkan, dan memproduksi sistem dan komponen tercanggih di seluruh dunia. Akibatnya, Bosch telah mengokohkan sejarah yang kuat dalam produk dan dukungan aftermarket otomotif dengan beragam portofolio suku cadang, solusi diagnostik, dan layanan bengkel. Pembelajaran dan pengembangan adalah kunci sukses yang pasti dan filosofi ini didukung oleh berbagai kursus pelatihan, di semua bidang teknologi otomotif, sistem kendaraan, dan keahlian diagnostik.",
	SearchField_NoRecordsFound: "Tidak ada catatan yang ditemukan",
	TrainingCatalogue_FreeTextSearch: "Teks Gratis",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Mencari",
	CheckoutLayout_WholeSalerGroup: "Pilih Grup Grosir",
	CreditInsufficientModal_Message: "Anda tidak memiliki kredit yang cukup untuk menggunakan metode pembayaran ini. Silakan coba lagi dengan metode pembayaran lain.",
	ShoppingCart_Required_Credits: "Total harga",
	MaintainingInfo_Message1: "Situs web ini sedang dalam mode pemeliharaan.",
	MaintainingInfo_Message2: "Kami akan segera kembali.",
	CountryValidationModal_Message: "Anda tidak dapat masuk ke [CountryA] karena akun Anda dibuat di [CountryB].",
	CountryValidationModal_ButtonText: "Lanjutkan ke [Country]",
	EventTable_LoginRequired: "Login untuk mendaftar",
	TrainingPartTableLayout_Date: "Tanggal",
	TrainingPartTableLayout_StartTime: "Waktu Mulai",
	TrainingPartTableLayout_EndTime: "Waktu Akhir",
	TrainingPartTableLayout_Duration: "Durasi (jam)",
	TrainingPartTableLayout_TrainingMethod: "Metode Pelatihan",
	MyCoursesTableContents_ActiveHeader: "Kursus Aktif",
	MyCoursesTableContents_ActiveTabHeader: "Aktif",
	MyCoursesTableContents_ScheduledHeader: "Kursus Terjadwal",
	MyCoursesTableContents_ScheduledTabHeader: "Terjadwal",
	MyCoursesTableContents_OnlineHeader: "Kursus Online",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Kursus yang Dibatalkan",
	MyCoursesTableContents_CancelledTabHeader: "Dibatalkan",
	OnlineCoursesTable_Title_Progress: "Kemajuan",
	ScheduledCoursesTable_Title_Date: "Tanggal [Hari]",
	CoursesTable_Title_Completed: "Selesai",
	Footer_TrainingProgram: "Program Pelatihan",
	Breadcrumb_TrainingProgram: "Program Pelatihan",
	TrainingProgram_Title_Table: "Judul",
	TrainingProgram_TrainingProgram: "Program Pelatihan",
	TrainingProgram_TargetGroup: "Kelompok Sasaran",
	TrainingProgram_MaxLength: "Maks. Durasi",
	TrainingProgram_Information: "Informasi",
	TrainingProgram_Detail: "Detail",
	TrainingProgram_Modules: "Modul",
	TrainingProgram_Course: "Kursus",
	TrainingProgram_PartNumber: "Nomor Bagian",
	TrainingProgram_Max_Length: "Panjang Maksimum",
	TrainingProgram_Duration: "Durasi",
	TrainingProgram_Exam: "Ujian",
	TrainingProgram_Assign_Popup: "Daftar untuk program pelatihan",
	TrainingProgram_Student: "Siswa",
	TrainingProgram_Assign: "Menetapkan",
	TrainingProgram_Popup_Remove_Title: "Pembatalan pelatihan?",
	TrainingProgram_Description: "Deskripsi",
	TrainingProgram_Days: "Hari",
	TrainingProgram_Type: "Jenis",
	TrainingProgram_Action: "Tindakan",
	TrainingProgram_Enrolled: "Anda sudah terdaftar dalam program pelatihan ini",
	TrainingProgram_SelfAssign_Enrolled: "Anda sudah terdaftar di program pelatihan ini",
	TrainingProgram_Warning: "Silakan daftarkan siswa lain atau pilih program pelatihan yang berbeda.",
	TrainingProgram_Workshop: "Lokakarya",
	TrainingProgram_Durations: "tahun",
	TrainingProgram_Duration_DaysLabel: "hari",
	TrainingProgram_HasExam_Yes: "Ya",
	TrainingProgram_HasExam_No: "Tidak",
	AssignModal_SuccessModal_Header: "Selamat!",
	AssignModal_SuccessModal_Message: "Anda telah berhasil terdaftar dalam program pelatihan ini.",
	AssignModal_SelfAssign_SuccessModal_Message: "Anda telah berhasil terdaftar dalam program pelatihan ini.",
	Dashboard_MyTraningProgram: "Program Pelatihan Saya",
	Dashboard_MyTraningProgram_Title: "Judul",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Lihat",
	Dashboard_MyTraningProgram_Register: "Daftar",
	Dashboard_MyTraningProgram_Records: "Catatan Sejarah",
	Dashboard_MyTrainingProgram_In_Progress: "Sedang berlangsung",
	Dashboard_MyTrainingProgram_Accredited: "Terakreditasi",
	Dashboard_Program_Modal_Withdraw: "Menarik diri",
	Dashboard_Program_Modal_Grade: "Nilai",
	Dashboard_MyTrainingProgram_Registered: "Terdaftar",
	Dashboard_MyTrainingProgram_Completedd: "Selesai",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ya",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Tidak",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Perlu diketahui bahwa mengundurkan diri berarti Anda akan mengundurkan diri dari program pelatihan secara keseluruhan, bukan hanya dari kursus pelatihan individu.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Apakah Anda masih ingin mengundurkan diri dari program pelatihan?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Tertunda",
	WithdrawProgramModal_Withdraw_Success_Message: "Anda telah berhasil mengundurkan diri dari program pelatihan!",
	Common_Warning_Title: "Peringatan!",
	Common_Error_Title: "Ups, ada yang tidak beres!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Tambahkan ke Troli",
	AddEventFromCatalogToCartModal_HoursText: "jam",
	AddElearningToCartSuccessModal_Message: "telah ditambahkan ke keranjang belanja Anda!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Peserta yang dipilih telah terdaftar di Kursus ini, atau Kursus sudah ditambahkan ke keranjang.",
	EventDetailTab_SubjectForChange_Message: "Subjek dapat berubah",
	Dashboard_MyTrainingProgram_Status_Failed: "Gagal",
	Dashboard_MyTrainingProgram_Status_Passed: "Lulus",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Perbedaan",
	Dashboard_Employee_Courses: "Kursus",
	Dashboard_Employee_Program: "Program Pelatihan",
	Dashboard_Employee_WaitList: "Daftar Tunggu",
	Dashboard_Employee_Account: "Akun",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "Anda telah berhasil memperbarui informasi karyawan Anda!",
	Dashboard_Supplier: "Reseller",
	Event_Detail_Comment: "Saat ini tidak ada pesan",
	DownLoad_PDF: "Unduh PDF",
	TrainingCatalogue_Currency: "Mata Uang",
	My_Wallet: "Dompet Saya",
	Wallet: "Dompet",
	Credits_Balance: "Saldo Kredit Pelatihan",
	MyFullCalendar_Today: "Hari ini",
	Header_Available_Languages: "Bahasa yang Tersedia",
	Header_CurrentRegion: "Anda saat ini berada di",
	Header_ChangeRegions: "Apakah Anda ingin mengubah negara?",
	Header_Anonymous: "Pelatihan Layanan",
	Header_Anonymous_Login: "Masuk / Daftar",
	Header_RegisterCourse: "Pesan Pelatihan",
	Homepage_Start: "Daftar",
	Homepage_Login: "Masuk",
	MainPage_Title: "Solusi Bengkel Terbaik",
	MainPage_Content: "Bengkel Anda, pilihan Anda.",
	MainPage_ImageTitle: "Solusi Pelatihan Otomotif Bosch",
	MainPage_IntroContent: "Memungkinkan bengkel modern untuk bekerja secara profesional, andal & efisien dalam prosedur diagnostik, pemecahan masalah, perbaikan, dan servis semua jenis kendaraan.",
	BoschNews_TrainingNews: "Berita Pelatihan",
	Service_Assist_Workshop: "Tingkatkan pengalaman Anda dengan Bantuan Layanan Bengkel",
	Enhance_Title_CDM3_v2: "Apa yang dapat saya lakukan dengan aplikasi ini?",
	Enhance_Body_CDM3_Item_1: "Pesan kursus dan pertahankan perjalanan belajar Anda",
	Enhance_Body_CDM3_Item_2: "Unduh materi pelatihan dan sertifikat kursus",
	Enhance_Body_CDM3_Item_3: "Meluncurkan kursus eLearning",
	Enhance_Body_CDM3_Item_4: "Mengakses berbagai layanan otomotif dalam aplikasi seluler",
	Enhance_Body_CDM3_Item_5: "Transmisi video langsung dengan Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Alat gratis untuk pemindaian kendaraan otomatis",
	Training_Stories: "Cerita Pelatihan",
	SettingsMyAccount_JoinCompanyButton: "Bergabung dengan Bisnis",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Bergabung dengan Bisnis",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Silakan masukkan kode aktivasi Anda di bawah ini",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Catatan: Silakan hubungi administrator BATS bisnis Anda untuk mendapatkan kode aktivasi di situs BATS.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Aktivasi Anda telah berhasil diproses dan akun Anda sekarang telah terhubung dengan bisnis Anda.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Silakan klik Lanjutkan untuk masuk ke situs web.",
	ContinueButton: "Lanjutkan",
	WelcomeBatsModal_Title: "Akun Anda saat ini sedang ditinjau!",
	WelcomeBatsModal_ActivationLink: "Klik di sini jika Anda memiliki Kode Aktivasi untuk bergabung dengan akun bisnis Anda!",
	WelcomeBatsModal_ActivationLinkNotes: "Catatan: Anda perlu menghubungi administrator bisnis Anda untuk mendapatkan kode aktivasi.",
	AccountInReviewModal_Description_Paragraph1: "Terima kasih telah mendaftarkan akun pribadi di Solusi Pelatihan Otomotif Bosch.",
	AccountInReviewModal_Description_Paragraph2: "Staf pelatihan kami yang berdedikasi saat ini sedang meninjau dan mengonfigurasi akun Anda. Anda akan menerima konfirmasi akhir melalui email, setelah akun Anda berhasil dikonfigurasi.",
	AccountInReviewModal_CloseButton: "Oke, izinkan saya masuk!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Kami tidak dapat memvalidasi kode aktivasi dan alamat email Anda.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Silakan coba lagi atau hubungi administrator Bisnis Anda untuk mendapatkan bantuan.",
	TryAgain_Button: "Coba Lagi",
	Error_Title: "Ups, ada yang salah!",
	SettingsMyProfileActivationSuccessModal_Title: "Berhasil!",
	AccountInReviewModal_Description_Paragraph3: "Sementara itu, silakan menjelajahi situs web kami dengan akses terbatas.",
	AccountInReviewModal_Description_Paragraph4: "Jika Anda memiliki pertanyaan, atau jika akun Anda tidak diproses dalam waktu 72 jam tidak termasuk akhir pekan dan hari libur, Anda dapat menghubungi kami [di sini]",
	Support_Headline: "Solusi Pelatihan Otomotif Bosch",
	Support_SubHeadline: "Beberapa panduan untuk membantu Anda...",
	Support_FAQ_Headline: "Pertanyaan yang sering diajukan",
	Support_FAQs_section1_title: "Umum",
	Support_FAQs_section1_question1: "Apa yang dimaksud dengan Bosch Automotive Training Solutions (BATS)?",
	Support_FAQs_section1_question1_answer1: "Bosch Automotive Training Solutions adalah platform yang memberikan gambaran umum yang komprehensif tentang pelatihan teknis dan memungkinkan Anda untuk memesan & mengelola kursus pelatihan.",
	Support_FAQs_section1_question2: "Dapatkah saya menggunakan akun Bosch saya yang sudah ada (SingleKey ID) untuk masuk ke Bosch Automotive Training Solutions (BATS)?",
	Support_FAQs_section1_question2_answer1: "Ya, BATS dapat diakses dengan \"ID Bosch Saya\".",
	Support_FAQs_section1_question3: "Dapatkah saya menggunakan akun yang dibuat untuk Bosch Automotive Training Solutions untuk layanan atau aplikasi Bosch lainnya?",
	Support_FAQs_section1_question3_answer1: "Ya, Anda juga dapat menggunakan ID Bosch pribadi Anda untuk berbagai aplikasi Bosch lainnya - baik secara profesional maupun pribadi, seperti e-bike atau rumah pintar.",
	Support_FAQs_section2_title: "Pelatihan",
	Support_FAQs_section2_question1: "Bagaimana cara memesan pelatihan?",
	Support_FAQs_section2_question1_answer1: "Masuk dengan akun Anda yang sudah ada atau daftar ke portal. Buka \"Layanan Pelatihan\" atau \"Kalender Acara\", pilih kursus pelatihan dan klik tombol tambahkan ke keranjang (ikon keranjang belanja)",
	Support_FAQs_section2_question2: "Tidak ada tanggal pelatihan yang sesuai yang tersedia. Apa yang bisa saya lakukan?",
	Support_FAQs_section2_question2_answer1: "Anda dapat memasukkan diri Anda ke dalam daftar tunggu \"umum\" (tanpa memilih tanggal) dan tim kami akan memberi tahu Anda segera setelah tanggal pelatihan baru tersedia.",
	Support_FAQs_section2_question3: "Di mana saya dapat menemukan gambaran umum dari kursus yang telah saya pesan?",
	Support_FAQs_section2_question3_answer1: "Anda dapat menemukan ikhtisar lengkap dari semua kursus yang telah dipesan & diselesaikan di dasbor Anda melalui: Pelatihan Saya > Ikhtisar Anda",
	Support_FAQs_section3_title: "Pengaturan",
	Support_FAQs_section3_question1: "Bagaimana cara mengubah Email & Kata Sandi saya?",
	Support_FAQs_section3_question1_answer1: "Anda harus terlebih dahulu masuk ke portal dan kemudian Anda dapat mengubah informasi profil pribadi Anda melalui: Pelatihan Saya > Akun Bosch Saya",
	Support_FAQs_section3_question1_answer3: "Di sana Anda dapat mengubah detail (email/kata sandi) ID Bosch Anda",
	Support_FAQs_section3_question2: "Apa yang dapat saya lakukan jika saya lupa kata sandi?",
	Support_FAQs_section3_question2_answer1: "Silakan gunakan tombol Masuk atau Daftar dan isi alamat email Anda",
	Support_FAQs_section3_question2_answer2: "Jika Anda mengklik Lanjutkan, Anda dapat mengklik \"Lupa Kata Sandi\"",
	Support_FAQs_section3_question3: "Bagaimana cara membuat akun manajer bengkel?",
	Support_FAQs_section3_question3_answer1: "Jika Anda perlu mengakses semua informasi pelatihan staf bisnis Anda, Anda memerlukan peran manajer bengkel. Silakan hubungi Administrasi Pelatihan setempat, misalnya Anda dapat menggunakan formulir kontak di situs web.",
	LandingPage_Header_Title: "Solusi Pelatihan Otomotif Bosch",
	LandingPage_ChooseCountry_Title: "Pilih Negara / Wilayah Anda",
	MaintenanceBanner_Message_1: "Portal Solusi Pelatihan Otomotif Bosch akan diperbarui pada [maintenance_schedule]. Pembaruan diperkirakan akan memakan waktu 30 menit. Portal tidak akan tersedia selama pembaruan berlangsung.",
	MaintenanceBanner_Message_2: "Untuk bantuan lebih lanjut, silakan hubungi kami melalui email [support_email]",
	MaintenancePage_Message_1: "Portal Solusi Pelatihan Otomotif Bosch saat ini sedang diperbarui",
	MaintenancePage_Message_2: "Kami berharap Portal akan tersedia kembali pada [maintenance_online_date] pada [maintenance_online_time]",
	MenuHeader_Support: "Dukungan",
	Course_Detail_No_Prerequisites: "Tanpa Prasyarat",
	TableCell_Hours: "Jam",
	Dashboard_MenuItem_Dashboard: "Dasbor",
	Dashboard_MenuItem_Curricula: "Program Pelatihan",
	Dashboard_MenuItem_Certificates: "Sertifikat",
	Dashboard_MenuItem_Team: "Tim",
	Dashboard_MenuItem_Workshops: "Lokakarya",
	Dashboard_MenuItem_External: "Pengecer",
	Dashboard_MenuItem_Wallet: "Dompet",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Kursus Anda yang Akan Datang",
	Dashboard_Headline_YourELearningCourses: "Kursus eLearning Anda",
	Dashboard_Headline_TeamOverview: "Gambaran Umum Tim",
	Dashboard_Headline_YourOverview: "Gambaran Umum Anda",
	Dashboard_Headline_YourWishlist: "Program Pelatihan Anda",
	Dashboard_Headline_ActivePrograms: "Sertifikat Anda",
	Dashboard_Headline_CompletedPrograms: "Program yang Sudah Selesai",
	Dashboard_Headline_YourCertificates: "Sertifikat Anda",
	Dashboard_Headline_Transactions: "Transaksi",
	Dashboard_Table_NoRecordsToDisplay: "Tidak ada catatan untuk ditampilkan",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "Jam",
	Dashboard_ELearningCourses_TableHeader_Title: "Judul",
	Dashboard_ELearningCourses_TableHeader_Duration: "Durasi (jam)",
	Dashboard_ELearningCourses_ActionButton_View: "Lihat",
	Dashboard_UpcomingCourses_TableHeader_Title: "Judul",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Tanggal [Hari]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokasi",
	Dashboard_UpcomingCourses_ActionButton_View: "Lihat",
	Dashboard_YourOverview_TableHeader_Title: "Judul",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Tanggal / Durasi",
	Dashboard_YourOverview_TableHeader_Location: "Lokasi",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Lihat",
	Dashboard_YourWishlist_TableHeader_Title: "Judul",
	Dashboard_YourWishlist_TableHeader_Action: "Tindakan",
	Dashboard_YourWishlist_ViewAllRecords: "Lihat semua catatan",
	Dashboard_TeamOverview_TableHeader_Employee: "Karyawan",
	Dashboard_TeamOverview_TableHeader_Title: "Judul",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Tanggal / Dura",
	Dashboard_TeamOverview_TableHeader_Location: "Lokasi",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Melihat",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Mencari",
	Dashboard_ActiveProgram_TableHeader_Title: "Judul",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Melihat",
	Dashboard_CompletedProgram_TableHeader_Title: "Judul",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Melihat",
	Dashboard_Certificates_TableHeader_Title: "Judul",
	Dashboard_Certificates_TableHeader_Date: "Tanggal",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Tindakan",
	Dashboard_Certificates_ActionButton_Download: "Unduh",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Nomor faktur",
	Dashboard_Transactions_TableHeader_Description: "Keterangan",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Cara Pembayaran",
	Dashboard_Transactions_TableHeader_Date: "Tanggal",
	Dashboard_Transactions_TableHeader_Amount: "Jumlah",
	Dashboard_Transactions_ActionButton_Download: "Unduh",
	Breadcrumb_MyTraining: "Pelatihan saya",
	Breadcrumb_MyTraining_CourseDetails: "Detail Kursus",
	Breadcrumb_MyTraining_Curricula: "Program latihan",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detail Program Pelatihan",
	Breadcrumb_MyTraining_Team: "Tim",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Lihat Karyawan",
	MyTraining_Headerbar_CourseDetails: "Detail Kursus",
	MyTraining_Headerbar_TrainingProgramDetails: "Detail Program Pelatihan",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Kemajuan secara keseluruhan",
	TrainingProgramDetails_ProgramItems_Headline: "Item Program",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Judul",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Nilai",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Tanggal",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Melihat",
	TrainingProgramDetails_Status_InProgress: "Sedang berlangsung",
	TrainingProgramDetails_Status_Completed: "Lengkap",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Durasi",
	CourseDetails_Label_Trainer: "Pelatih",
	CourseDetails_Label_Language: "Bahasa",
	CourseDetails_Label_Score: "Skor:",
	CourseDetails_Label_StartDate: "Mulai tanggal",
	CourseDetails_Label_EndDate: "Tanggal akhir",
	CourseDetails_Label_Venue: "Lokasi",
	CourseDetails_Label_Address: "Alamat",
	CourseDetails_Label_Grade: "Nilai:",
	CourseDetails_Action_Withdraw: "Menarik",
	CourseDetails_Action_Evaluate: "Evaluasi kursus",
	CourseDetails_Action_Evaluate_Description: "Harap evaluasi kursus ini untuk menghasilkan sertifikat Anda.",
	CourseDetails_Action_DownloadCertificate: "Unduh sertifikat",
	CourseDetails_Action_JoinWebcast: "Bergabunglah dengan Webcast",
	CourseDetails_Action_StartCourse: "Mulai Kursus",
	CourseDetails_Action_RestartCourse: "Mulai Ulang Kursus",
	CourseDetails_Action_RepeatCourse_Description: "Sertifikat tidak dapat diunduh karena tanda lulus tidak tercapai. Harap ulangi dan lulus kursus ini untuk mengunduh sertifikat Anda.",
	CourseDetails_PartSpecificDetails_Headline: "Detail Spesifik Bagian",
	CourseDetails_PartSpecificDetails_Tab_Part: "Bagian",
	CourseDetails_PartSpecificDetails_Description: "Keterangan",
	CourseDetails_PartSpecificDetails_StartDate: "Mulai tanggal",
	CourseDetails_PartSpecificDetails_EndDate: "Tanggal Berakhir",
	CourseDetails_PartSpecificDetails_StartTime: "Waktu mulai",
	CourseDetails_PartSpecificDetails_EndTime: "Akhir waktu",
	CourseDetails_PartSpecificDetails_Method: "metode",
	Dashboard_Team_TableHeader_Name: "Nama",
	Dashboard_Team_TableHeader_Role: "Peran",
	Dashboard_Team_TableHeader_Email: "Surel",
	Dashboard_Team_TableHeader_PersonalAccount: "Akun pribadi",
	Dashboard_Team_ActionButton_Select: "Pilih",
	Dashboard_Team_PersonalAccount_Activated: "Aktif",
	Dashboard_Team_PersonalAccount_NotActivated: "Tidak aktif",
	Dashboard_Team_ActionButton_AddMember: "Tambahkan Anggota Tim",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Mencari",
	Dashboard_External_TableHeader_Name: "Nama",
	Dashboard_External_ActionButton_AddNew: "Tambahkan Pengecer",
	Dashboard_External_Headline: "Daftar Grosir yang Disetujui",
	Dashboard_Workshops_Headline: "Lokakarya",
	Dashboard_Workshops_TableHeader_Company: "Perusahaan",
	Dashboard_Workshops_TableHeader_Address: "Alamat",
	Dashboard_Workshops_TableHeader_Postcode: "Kode Pos",
	Dashboard_Workshops_TableHeader_City: "Kota",
	Dashboard_Workshops_ActionButton_Select: "Pilih",
	Wallet_ServiceCredits: "Kredit Pelatihan",
	Wallet_Headline_CreditBalance: "Saldo Kredit Pelatihan",
	Wallet_Headline_CreditActivity: "Kegiatan Kredit Pelatihan",
	Wallet_Headline_BillingTransactions: "Transaksi Penagihan",
	Wallet_BillingTransaction_TableHeader_Order: "Memesan",
	Wallet_BillingTransaction_TableHeader_Date: "Tanggal",
	Wallet_BillingTransaction_TableHeader_Amount: "Jumlah",
	Wallet_BillingTransaction_TableHeader_Action: "Tindakan",
	Wallet_CreditActivity_TableHeader_Date: "Tanggal",
	Wallet_CreditActivity_TableHeader_Description: "Keterangan",
	Wallet_CreditActivity_TableHeader_User: "Pengguna",
	Wallet_CreditActivity_TableHeader_Amount: "Jumlah",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, semua hak dilindungi undang-undang",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Keamanan Produk (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovasi, Paten dan Lisensi",
	FooterAdditionalLinks_Navigation_Logistics: "Pembelian dan Logistik",
	MenuHeader_MyTraining: "Pelatihan saya",
	MenuHeader_TrainingServices: "Katalog & Kalender Pelatihan",
	MenuHeader_OurLocations: "Lokasi Kami",
	MenuHeader_ContactsUs: "Hubungi kami",
	MenuHeader_Logout: "Keluar",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "ID Bosch saya",
	MyProfile_AccountDeletion: "Penghapusan Akun",
	MyProfile_Edit: "Ubah email atau kata sandi",
	MyProfile_Profile: "Akun Bosch saya",
	MyProfile_EditUserProfile: "Edit Profil Pengguna",
	BackButton: "Kembali",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Kami menantikan telepon Anda dan akan dengan senang hati membantu Anda.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Masukkan karakter yang benar, seperti yang ditunjukkan pada kotak di atas.",
	ContactUs_Email_MandatoryNotice_V3: "Harap isi semua kolom yang wajib diisi.",
	ContactUs_Email_SendButton: "Mengirim",
	Breadcrumb_TrainingCenters: "Pusat Pelatihan",
	Training_Centres_Book_Btn: "Pelatihan Buku",
	Training_centres_booking_code: "Kode Pemesanan",
	Training_centers_header_bar_title: "Pusat Pelatihan Kami",
	Training_centers_filter_item: "Pusat",
	TeamEdit_PersonalAccountToolTop: "Mengaktifkan fitur ini memungkinkan karyawan membuat akun pribadi dan menjadi bagian dari akun bisnis Anda. Setelah diaktifkan, Anda dapat mengirim undangan email dengan kode aktivasi ke karyawan tersebut. Karyawan harus memasukkan kode aktivasi saat mereka mendaftar untuk ditautkan ke akun bisnis Anda",
	TeamEdit_Update: "Mengaktifkan fitur administrator perusahaan memberi karyawan ini akses penuh ke akun bisnis Anda. Mengaktifkan fitur ini mengharuskan pengguna keluar dan kembali ke situs web, jika pengguna sudah masuk.",
	TeamEdit_PersonalAccount: "Izinkan akun web Bosch Training Solutions pribadi",
	TeamEdit_SendInvitation: "Kirim undangan",
	TeamEdit_Deactivate: "Menonaktifkan",
	TeamEdit_Telephone: "Telepon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Ponsel harus berisi angka saja",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telepon harus berisi nomor saja",
	TeamEdit_Activate: "Aktif",
	TeamEdit_NotActivated: "Tidak aktif",
	TeamEdit_StatusPopup: "Status akan ditampilkan sebagai 'Aktif' jika pengguna berhasil membuat akun pribadi. Jika statusnya ditetapkan sebagai 'Tidak Aktif', itu berarti pengguna tidak memiliki akun - Anda dapat mengirimi mereka undangan untuk menyelesaikan ini jika Anda belum melakukannya",
	Training_Catalog_Courses: "Kursus",
	Training_Catalog_Course: "Kursus",
	Reset_Filter: "Setel Ulang Filter",
	TrainingCatalogue_CourseCatalogue: "Katalog Kursus Pelatihan",
	CheckoutHeader_CartItemCount_Text: "Anda memiliki [count] item di keranjang belanja Anda",
	CheckoutHeader_CartItemsCount_Text: "Anda memiliki [count] item di keranjang belanja Anda",
	ShoppingCart_PriceItem: "Harga barang",
	ShoppingCartItemParticipants_AddParticipant_Button: "Tambah Peserta",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Pajak",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Termasuk VAT sebesar [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Tersedia metode pembayaran online",
	ShoppingCartItemRow_VenueFeeLabel: "Biaya tempat [fee_amount] per peserta",
	ShoppingCartItemRow_OnlineLabel: "On line",
	AddParticipantsModal_Title: "Tambah Peserta",
	AddParticipantsModal_SelectEmployeeLabel: "Tambah Peserta",
	AddParticipantsModal_SelectWorkshopLabel: "Pilih Bengkel",
	ShoppingCartTotalPrice_ContinueButtonText: "Lanjutkan ke Pembayaran",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Peserta belum ditambahkan!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Anda memiliki satu atau lebih kursus di keranjang belanja tanpa peserta.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Tambahkan peserta untuk melanjutkan ke Checkout.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Edit peserta ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nama",
	AddParticipantsModal_ParticipantTable_Email: "Surel",
	AddParticipantsModal_ParticipantTable_Action: "Tindakan",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Tidak ada peserta…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Alamat email tidak dikonfigurasi pada peserta yang dipilih",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Peserta yang dipilih tidak memiliki alamat email yang terkonfigurasi di profilnya. Alamat email adalah wajib untuk mendaftar ke kursus pelatihan.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Klik 'Tambahkan Email' untuk melanjutkan atau 'Batalkan' untuk memilih peserta lain.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Tambahkan Email",
	CancelButton: "Membatalkan",
	AddParticipantsModal_AddEmployeeButtonText: "Tambahkan Karyawan",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Anda akan menghapus kursus dari keranjang Anda",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Anda mencoba menghapus kursus dengan peserta tambahan dari keranjang Anda.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klik \"Lanjutkan\" jika Anda ingin melanjutkan atau klik \"Batal\".",
	ShoppingCartItemHeader_Participants: "Peserta",
	ShoppingCartItemHeader_ParticipantPrice: "Harga per kursi",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Tampilkan dalam [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Tampilkan dalam Kredit",
	ShoppingCart_CreditsPriceUnit: "Kredit",
	CheckOutHeader_Step1_Question: "Bagaimana Anda ingin membayar?",
	CheckOutHeader_Step2_Question: "Tinjau pesanan Anda",
	CheckOutHeader_Step3_Question: "Selesaikan pesanan Anda",
	CheckOutHeader_Step4_Question: "Pemesanan Anda berhasil!",
	CheckOutHeader_Step_Payment: "Pembayaran",
	CheckOutHeader_Step_Review: "Tinjauan",
	CheckOutHeader_Step_Place_Order: "Tempatkan Pesanan",
	CheckOutRightPanel_Review_Order_Btn: "Tinjau Pesanan",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Tempatkan Pesanan",
	CheckoutRightPanelContent_OrderSummary_Title: "Ringkasan Pesanan",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Mengedit keranjang belanja",
	ShoppingCart_Participants: "Peserta",
	CheckOutStepPayment_Payment_Methods_Title: "Pilih metode pembayaran",
	CheckOutStepPayment_Credit_Title: "Kredit atau Debit",
	CheckOutStepPayment_Wholesaler_Title: "Grosir",
	CheckOutStepPayment_ServiceCredit_Title: "Kredit Pelatihan",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Anda memiliki Kredit Pelatihan [available_credit].",
	CheckOutStepPayment_BoschAccount_Title: "Akun Bosch",
	Checkout_Second_PaymentMethod_Headline: "Cara Pembayaran",
	CheckOutStepReview_Edit: "Sunting",
	CheckOutStepReview_Terms_Label: "Syarat & Ketentuan dan Kebijakan Pembatalan",
	CheckOutStepReview_Terms_Text: "Saya telah membaca, memahami, dan menyetujui [syarat, ketentuan, dan kebijakan pembatalan] penjualan.",
	SelectSecondPaymentMethodModal_Title: "Pilih metode pembayaran sekunder",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Bayar [amount]",
	StepPaymentWholesaler_Title: "Pilih Pengecer",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Terima Syarat & Ketentuan",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Harap baca dan setujui Syarat & Ketentuan dan Kebijakan Pembatalan kami sebelum melanjutkan.",
	CheckoutDescription_ThankYou: "Terima kasih telah mengirimkan pesanan Anda di Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Bit dan byte kami sudah mulai memproses pesanan Anda. Sementara itu, harap biarkan mesin Anda dalam keadaan idle.",
	CheckoutDesctiption_Text: "Kami akan mengirimkan konfirmasi akhir melalui email termasuk semua rincian pemesanan Anda.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Anda akan menghapus bengkel yang dipilih",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Dengan menghapus lokakarya yang dipilih, semua peserta yang ditambahkan akan dihapus.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klik \"Lanjutkan\" jika Anda ingin melanjutkan atau klik \"Batal\" untuk menghentikan tindakan ini.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Anda memiliki Kredit yang tidak mencukupi. Saldo Kredit Anda adalah Kredit [available_credit], tetapi Anda memerlukan [cart_total_credits] Kredit untuk melanjutkan ke Checkout. Silakan pilih metode pembayaran lain atau hapus item dari keranjang belanja Anda.",
	Training_centers_filter_items: "Pusat",
	Training_Programs_Filter_Item: "Program",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Bertahun-tahun)",
	Training_Program_Detail_Title: "Program Kursus Pelatihan",
	AccountButton_Workshops_MenuItem: "Lokakarya",
	AccountButton_Wallet_MenuItem: "Dompet",
	Header_MyBoschProfile_Header: "Profil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Tersedia",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Tambah/Edit peserta",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "Termasuk VAT",
	TrainingCatalogue_Length: "Panjang",
	TrainingCatalogue_Method: "metode",
	Course_Price_Credits: "Kredit",
	Catalogue_Grid_Register_Button: "Daftar",
	Catalogue_Credit_Tilte: "Klik di sini untuk melihat harga dalam Kredit",
	Catalogue_Price_Tilte: "Klik di sini untuk melihat harga dalam EUR",
	Catalogue_Grid_Vat: "VAT kecuali",
	EventCalendar_Seats: "Tempat duduk",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Menit",
	ListFilter_NoResult: "Tidak ada hasil",
	Course_Description: "Deskripsi Kursus",
	Training_course_detail: "Dasar Media Sosial",
	Training_course_detail_add_waiting: "Tambahkan ke daftar tunggu umum",
	Register_now_btn: "Daftar sekarang !",
	Register_now_btn_add_event_to_cart: "Daftar sekarang !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Lihat semua rekaman",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Lihat lebih sedikit",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Lihat semua rekaman",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Lihat lebih sedikit",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Lihat semua rekaman",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Lihat lebih sedikit",
	TeamCreate_TitleError: "Judul diperlukan",
	Dashboard_Headerbar_BookCourse: "Kursus buku",
	Dashboard_Headerbar_ExitProxy: "Keluar dari Proksi",
	Dashboard_Headerbar_EvaluateCourse: "Evaluasi kursus",
	Dashboard_Headerbar_EvaluateCourse_Note: "Harap evaluasi kursus ini untuk menghasilkan sertifikat Anda!",
	Dashboard_Headerbar_DownloadCertificate: "Unduh Sertifikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Menarik",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Menarik",
	Dashboard_Headerbar_WelcomeMessage: "Selamat datang di dasbor pembelajaran Anda",
	Dashboard_Headerbar_ProxingAs: "Proksi sebagai",
	Dashboard_TrainingProgram_Status_Completed: "Lengkap",
	Dashboard_TrainingProgram_Status_InProgress: "Sedang berlangsung",
	External_SelectWholesalerModal_Title: "Tambahkan akses pengguna eksternal",
	External_SelectWholesalerModal_Company_Label: "Perusahaan",
	External_SelectWholesalerModal_Outlet_Label: "Toko",
	External_SelectWholesalerModal_Company_Placeholder: "Silahkan pilih",
	External_SelectWholesalerModal_Outlet_Placeholder: "Silahkan pilih",
	External_SelectWholesalerModal_ApplyButton: "Menerapkan",
	External_SelectWholesalerModal_CancelButton: "Membatalkan",
	External_RemoveWholesalerModal_ConfirmLabel: "Konfirmasi",
	External_SelectWholesalerModal_ConfirmMessage: "Yakin ingin menghapus grosir ini?",
	External_SelectWholesalerModal_YesButton: "Ya",
	External_SelectWholesalerModal_NoButton: "TIDAK",
	Training_program_team_member: "Anggota tim",
	Training_program_assign_training_program: "Tetapkan program pelatihan",
	Assign_modal_student_confirmation_text1: "Anda akan menetapkan [Training Program Name] ke perjalanan pembelajaran Anda.",
	Assign_modal_student_confirmation_text2: "Apakah Anda ingin melanjutkan?",
	MyFullCalendar_Jan: "Januari",
	MyFullCalendar_Feb: "Februari",
	MyFullCalendar_Mar: "Merusak",
	MyFullCalendar_Apr: "April",
	MyFullCalendar_May: "Mungkin",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Juli",
	MyFullCalendar_Aug: "Agustus",
	MyFullCalendar_Sep: "September",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "November",
	MyFullCalendar_Dec: "Desember",
	MenuHeader_CourseCatalog: "Katalog Kursus Pelatihan",
	MenuHeader_Event: "Kalender pelatihan",
	MenuHeader_Program: "Program pelatihan",
	MenuHeader_Services: "Layanan pelatihan",
	MenuHeader_Log_out: "Keluar",
	Filter_Location: "Lokasi",
	TeamEdit_UpgradeToCompanyAdministrator: "Tingkatkan ke administrator Perusahaan",
	TeamEdit_PersonalWebAccountStatusIs: "Status akun web pribadi adalah",
	Header_CreateNewEmployee: "Buat Karyawan Baru",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Tidak ada kursi yang tersedia!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Tidak ada kursi yang tersedia pada acara yang dipilih.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Pilih acara lain atau hapus peserta yang sudah ditambahkan untuk melanjutkan.",
	Common_SuccessModal_Title: "Selamat!",
	TrainingProgramDetails_Merit_StatusText: "Kemampuan",
	TrainingProgramDetails_Resit_StatusText: "Duduk kembali",
	EmployeeProfile_UserExistErrorMessage: "Akun sudah ada!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Pembayaran gagal. Silakan coba lagi atau gunakan metode pembayaran lain.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Anda telah berhasil menonaktifkan karyawan terpilih!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Diselamatkan!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Perubahan yang Anda buat sudah disimpan.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Penarikan dari kursus eLearning tidak dimungkinkan. Silahkan hubungi kami jika rincian lebih lanjut diperlukan.",
	Dashboard_Team_PersonalAccount_Pending: "Tertunda",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Karyawan dibuat!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Karyawan baru Anda telah berhasil dibuat.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Pengaturan ini memungkinkan Anda membatasi karyawan untuk mendaftar ke acara pelatihan. Hanya administrator perusahaan yang dapat mendaftarkan karyawan ke acara pelatihan jika setelan ini tidak dipilih.",
	Training_catalog_sort_by: "Sortir dengan",
	CourseDetail_EvaluationRestriction_Title: "Evaluasi pelatihan tidak mungkin dilakukan!",
	CourseDetail_EvaluationRestriction_Message: "Evaluasi pelatihan hanya dapat disampaikan oleh peserta didik itu sendiri. Harap instruksikan siswa untuk masuk ke Bosch Automotive Training Solutions dan menyelesaikan evaluasi pelatihan.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Jika siswa belum memiliki akun, undangan dapat dibagikan di pengaturan profil pengguna anggota tim.",
	Breadcrumb_Download_Page: "Unduh",
	Download_Table_Category_Cell: "Kategori",
	Download_Table_Title_Cell: "Judul",
	Download_File_Filter_Item: "Mengajukan",
	Download_File_Filter_Items: "File",
	Download_Header: "Unduh Area",
	DownloadArea_Button_Download: "Unduh",
	Popup_Title_Error: "Kesalahan",
	Popup_Title_Success: "Kesuksesan",
	Popup_Title_Info: "Informasi",
	Popup_Title_Warning: "Peringatan!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Pendaftaran acara berikut sudah ada dan tidak akan dibatalkan secara otomatis dengan mengundurkan diri dari Program Pelatihan. Harap batalkan ini satu per satu.",
	TrainingProgram_PDF_Export: "Ekspor PDF",
	TrainingProgram_PDF_Export_Participant: "Peserta:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Kemajuan secara keseluruhan:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Judul Pelatihan",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Nilai",
	TrainingProgram_PDF_Export_TableHeader_Date: "Tanggal",
	AccountButton_CompanyChange_MenuItem: "Perubahan Perusahaan",
	SelectCompanyModal_Title: "Pilih Perusahaan",
	CloseButton: "Menutup",
	Change_Button: "Mengubah",
	ChangeManagerModal_Content: "Pilih Pengguna",
	ChangeCompanyModal_HeadOffice: "(Kantor pusat)",
	SelectCompanyModal_Content: "Silakan pilih Perusahaan untuk login…",
	AlertSuccess_Title: "Kesuksesan",
	CheckOutStepPayment_SecondaryPayment_Title: "Pilih metode pembayaran sekunder",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Tambahkan metode pembayaran sekunder",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Silakan tambahkan metode pembayaran sekunder di bawah atau pilih metode pembayaran lain di atas.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Kredit Layanan di akun Anda tidak mencukupi untuk membayar item keranjang Anda. Untuk terus menggunakan metode pembayaran yang dipilih, Anda harus memperoleh Kredit Layanan lebih banyak atau menggunakan metode pembayaran tambahan untuk membayar selisihnya. Perbedaannya adalah [difference_amount] (tidak termasuk gst).",
	ShoppingCartTotalPrice_TotalInCredit: "Total harga dalam kredit",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Kredit Anda yang tersedia",
	ShoppingCartTotalPrice_YourDifference: "Perbedaan",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal tidak termasuk. pertama",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal termasuk. pertama",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Metode pembayaran sekunder",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Edit metode pembayaran",
	Checkout_Second_PaymentMethod: "Metode Pembayaran Kedua",
	Breadcrumb_TrainingCourseDetails: "Detail kursus pelatihan",
	DownloadArea_FailedToDownload_ErrorMessage: "Pengunduhan dokumen gagal. Silakan coba lagi atau hubungi Administrator Perusahaan Anda untuk mendapatkan bantuan.",
	DownloadArea_FileNotAvailable_ErrorMessage: "File unduhan tidak tersedia. Silakan coba lagi atau hubungi Administrator Perusahaan Anda untuk mendapatkan bantuan.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Dilarang mengunduh file. Silakan coba lagi atau hubungi Administrator Perusahaan Anda untuk mendapatkan bantuan.",
	SSO_Redirecting_Message: "Anda mengalihkan ke portal BATS...",
	SSO_Redirecting_ParameterErrorMessage: "Negara dan/atau Bahasa tidak ditentukan. Silakan coba lagi atau hubungi Administrator Perusahaan Anda untuk mendapatkan bantuan.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Permintaan pendaftaran tidak diterima.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Hal ini mungkin disebabkan karena masih dalam batas hari pendaftaran kursus untuk satu atau lebih kursus. Silakan gunakan area 'Hubungi kami' jika Anda memiliki pertanyaan atau permintaan.",
	TimeSlotPicker_DateFormat_Title: "Format tanggal",
	UpdateAccountSettingsConfirmationModal_Title: "Anda akan mengubah format tanggal!",
	UpdateAccountSettingsConfirmationModal_Message1: "Format tanggal akan ditampilkan sesuai pilihan Anda dan dapat diubah sewaktu-waktu.",
	UpdateAccountSettingsConfirmationModal_Message2: "Apakah Anda ingin melanjutkan?",
	ProfileSummary_Settings_Title: "Pengaturan",
	UpdateAccountSettings_DateFormat_Title: "Pengaturan format tanggal",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Gagal memperbarui format Tanggal.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Pengaturan format tanggal telah berhasil diperbarui.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Unduh Sertifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Sertifikat Program Pelatihan",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Pengunduhan gagal. Silakan coba lagi. Jika masalah masih berlanjut, silakan hubungi kami melalui formulir kontak kami.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Ambang batas penarikan terlampaui",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Ambang batas untuk mengundurkan diri dari kursus ini telah terlampaui dan memerlukan pemrosesan manual oleh Administrasi Pelatihan kami.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Konfirmasi akhir akan dikirim melalui email.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Silakan lihat [kebijakan pembatalan] kami untuk rincian lebih lanjut.",
	TrainingCourse_Course_NotAvailable_Title: "Kursus pelatihan tidak tersedia",
	TrainingCourse_Course_NotAvailable_Message: "Kursus pelatihan tidak tersedia. Silakan coba lagi atau hubungi Administrator Perusahaan Anda untuk bantuan lebih lanjut.",
	TrainingCourse_NotAvailable_Message_1: "Anda mencoba melihat kursus pelatihan yang tidak tersedia.",
	TrainingCourse_NotAvailable_Message_2: "Silakan kembali ke [katalog pelatihan] kami untuk menemukan semua kursus yang tersedia.",
	LanguageSelection_SelectALanguage: "Pilih bahasa",
	LanguageSelection_SelectYourCountry: "Pilih negaramu",
	Checkout_PaymentMethod_Headline: "Cara Pembayaran",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Umum",
	Download_Categories_Information: "Informasi",
	Download_Categories_Userguides: "Panduan pengguna",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Kebijakan pembatalan",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Membatalkan",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Terus",
	Duration_Seconds: "Detik",
	Duration_Day: "Hari",
	Duration_Hour: "Jam",
	Duration_Minute: "Menit",
	Duration_Second: "Kedua",
	EventCalendar_ExternalLink_Tooltips: "Beli melalui (Nama Registrasi eksternal)",
	EventCalendar_ExternalLink_Seat_NA: "N/a",
	EventCalendar_ExternalLink_Cancel: "Membatalkan",
	EventCalendar_ExternalLink_Continue: "Terus",
	EventCalendar_ExternalLinkModal_Title: "Anda akan dialihkan ...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Event yang dipilih dijual melalui (Nama Pendaftaran eksternal).",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Melanjutkan akan mengarahkan Anda ke situs web eksternal.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Apakah Anda ingin melanjutkan?",
	Team_ContactPerson_FirstName_Tooltips: "Nama siswa hanya dapat diubah oleh tim administrasi pelatihan Bosch. Silakan hubungi kami melalui formulir kontak di situs web ini untuk dukungan lebih lanjut.",
	Team_ContactPerson_LastName_Tooltips: "Nama siswa hanya dapat diubah oleh tim administrasi pelatihan Bosch. Silakan hubungi kami melalui formulir kontak di situs web ini untuk dukungan lebih lanjut.",
	Withdraw_ExternalLink_Cancel: "Membatalkan",
	Withdraw_ExternalLink_Confirm: "Mengkonfirmasi",
	Withdraw_ExternalLinkModal_Title: "Anda akan dialihkan",
	Withdraw_ExternalLinkModal_Message: "Penarikan untuk kursus ini ditangani oleh penyedia eksternal.",
	Withdraw_ExternalLinkModal_Confirmation: "Mengklik \"Konfirmasi\" akan membuka tab baru dan mengarahkan Anda ke situs web eksternal.",
	Button_AddToWaitingList_Tooltips: "Tambahkan ke Daftar Tunggu",
	Button_AddToCart_Tooltips: "Tambah ke ketere",
	Button_TrainingCourseDetail_Tooltips: "Lihat Detail",
	Button_TrainingProgramDetail_Tooltips: "Lihat Detail",
	Button_AssignTrainingProgram_Tooltips: "Tetapkan program pelatihan",
	Button_DeleteFromCart_Tooltips: "Hapus dari Keranjang",
	Button_RemoveParticipant_Tooltips: "Menghapus peserta",
	Button_DownloadBillingTransaction_Tooltips: "Mengunduh",
	Button_RemoveExternal_Tooltips: "Mengalihkan"
};