export const TRANSLATIONS_FR = {
	LandingPage_Title: "Bienvenue sur Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Choisissez votre pays/région",
	Header_ContactUs: "Contactez-nous",
	Header_Search: "Chercher",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Connectez-vous ou créez votre compte",
	Header_LogIn: "Connectez-vous",
	Header_Cart: "Panier",
	Header_ShoppingCart: "Panier",
	Header_LogOut: "Déconnexion",
	Header_ChangeDetails: "Modifier certains détails",
	Footer_ExploreOurTraining: "Découvrez nos formations",
	Footer_TrainingAtYourFingertips: "Des formations au bout des doigts",
	Footer_DontMissAnyStory: "Ne manquez pas nos histoires",
	Footer_ExploreOurStories: "Découvrez nos histoires",
	Footer_GetInTouchWithUs: "Contactez-nous",
	Footer_SendUsAMessage: "Envoyez-nous un message",
	Footer_ToContactForm: "Formulaire de contact",
	Footer_InventedForLife: "Des technologies pour la vie",
	Footer_GeneralContactInfo: "Coordonnées",
	Footer_ProductSecurity_Psirt: "Produit Sécurité (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Brevets et licences",
	Footer_PurchasingLogistics: "Achats & logistiques",
	Footer_CorporateInformation: "Informations sur l'entreprise",
	Footer_LegalNotice: "Mentions légales",
	Footer_DataProtectionNotice: "Avis relatif à la protection des données",
	Footer_PrivacySettings: "Paramètres de confidentialité",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch S.A. 2023, tous droits réservés",
	Footer_StillLookingForSomething: "Cherchez-vous toujours quelque chose",
	Footer_TrainingServices: "Catalogue des formations",
	Footer_Courses: "Formations",
	Footer_Programs: "Programmes",
	Footer_EventsCalendar: "Calendrier des formations",
	Footer_TrainingCenters: "Centres de formation",
	Footer_Dashboard: "Tableau de bord",
	Footer_MyDashboard: "Mon tableau de bord",
	Footer_ShoppingCart: "Panier",
	Footer_YourShoppingCart: "Votre panier",
	Footer_Administration: "Administration",
	Footer_Employees: "Employés",
	Footer_Wholesalers: "Grossistes",
	Footer_Transactions: "Transactions",
	Footer_Workshops: "Ateliers",
	Footer_WorkLikeABoschApplyNow: "Travaillez  - Postulez dès maintenant.",
	Footer_BoschGlobalCareers: "Carrières mondiales Bosch",
	Footer_PurchasingAndLogistics: "Achats & logistiques",
	Footer_BecomeABusinessPartner: "Devenez un partenaire commercial.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Comment nos formations évoluent pour aider votre entreprise",
	Footer_Language: "Sélection du pays",
	MenuPopup_MyCart: "Mon panier",
	Stories_OurTrainingStories: "Les histoires de nos formations",
	Stories_ConnectingExperts: "Créer un lien entre experts et passionnés, rêveurs et acteurs, inventeurs et influenceurs – découvrez et expérimentez",
	Stories_TopStories: "Dernières nouvelles",
	Stories_AtHome: "Chez vous",
	Stories_Careers: "Carrières",
	Stories_History: "Historique",
	Stories_IndustryAndTrades: "Secteur et métiers",
	Stories_Mobility: "Mobilité",
	Stories_Research: "Recherche",
	Stories_Sustainability: "Durabilité",
	Stories_AllStories: "Toutes nos histoires",
	Stories_BlogATrainersDay: "BLOG | La journée d'un formateur",
	Stories_ADayinLifeOfATrainer: "Un jour dans la vie d'un formateur",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "STORY | Vos passions sont nos passions",
	Stories_StoryHowCanWeBenefitYourBusiness: "STORY | Comment nous pouvons booster vos activités",
	Stories_HowOurTrainingEvolves: "Comment nos formations évoluent",
	Stories_AModernTrainingApproach: "Le monde de la formation en ligne",
	Stories_StoryAVirtualOnlineTrainingWorld: "STORY | Vos options de formation en ligne",
	Stories_SubscribeToRSSFeed: "Abonnez-vous au flux RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "À propos de nous",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Contactez-nous",
	Homepage_LatestNews: "Voici pourquoi nous avons une telle soif de connaissances",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Nos postes vacants",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Vos passions sont nos passions",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Partagez sur",
	BoschServiceTraining_TheChallenge: "Le défi : Maîtriser les nouvelles technologies",
	BoschServiceTraining_WorldwidePresence: "Présence mondiale",
	BoschServiceTraining_TechnicalTraining: "Formations techniques Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Les offres et options chez Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Il existe des formations adaptées à chaque groupe-cible – novice ou pro. L'offre comprend des formations d'une journée et des formations de plusieurs jours. Les cours sont proposés dans différents domaines techniques et séries de formation (par exemple injection essence, injection diesel, systèmes électriques). Dans les cours de formations techniques, Bosch partage son vaste savoir-faire dans tous les systèmes automobiles standard de Bosch. Les systèmes d'autres fabricants sont également décrits dans ces cours. Les contenus sont spécialement adaptés aux besoins des employés de l'atelier.",
	BoschServiceTraining_TrainedEmployeesDesc: "Des employés correctement formés améliorent la qualité des ateliers automobiles. En effet, les véhicules d'aujourd'hui sont de plus en plus complexes et l'introduction continue de nouvelles technologies présente de nombreux défis. Seule l'expertise nécessaire permet aux employés de l'atelier d'effectuer des travaux efficaces et économiques de diagnostic, entretien et réparation sur les modèles actuels de véhicules.",
	BoschServiceTraining_RegardlessOfHow: "Même si les spécialistes de l'automobile sont excellents et expérimentés, ils auront à faire face à des défis liés à de nouvelles technologies ou des défaillances qu'ils ne connaissent pas. À cet égard la détection et la résolution des problèmes peuvent prendre beaucoup de temps et même ne pas donner de résultats satisfaisants. Au pire les clients devront se passer de leur véhicule beaucoup plus longtemps que prévu.",
	BoschServiceTraining_ItIsPrecisely: "C'est justement là que Bosch met à profit sa formation en matière de services, pour l'amélioration de la qualité du service et l'accélération des processus. Les formations actuelles de Bosch facilitent la localisation plus rapide des problèmes la réparation d'un plus grand nombre de véhicules dans le même temps et la réduction des temps d'attente pour vos clients. Ainsi, les procédures systématiques apprises chez Bosch permettent un travail plus rapide et plus productif.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Depuis plus de 125 ans, Bosch est le fournisseur de premier choix d'équipements, pièces, composants et systèmes originaux pour de nombreux constructeurs automobiles de premier plan dans le monde. Cette expérience s'accompagne de connaissances uniques sur les plus récentes technologies qui sont à la base de l'assistance Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Il y a des Service Training Centers Bosch partout dans le monde : Espagne, Turquie, Afrique du Sud, Australie, Brésil, etc. Bosch offre à ses employés des formations pratiques toujours adaptées aux technologies les plus modernes.",
	BoschServiceTraining_AgileCompany: "Société dynamique",
	BoschServiceTraining_Collaboration: "Collaboration",
	BoschServiceTraining_ConnectedMobility: "Mobilité connectée",
	BoschServiceTraining_ArtificialIntelligence: "Intelligence artificielle",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Dialogue entre leaders d'opinion",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostics et équipements d'atelier",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Fondamentaux Automotive Technology",
	BoschServiceTraining_Icon_Gasoline: "Essence",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "E-mobilité",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Electrique et électroniques",
	BoschServiceTraining_Icon_BodyAndComfort: "Confort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Sécurité",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmission",
	BoschServiceTraining_Icon_CommercialVehicles: "PassThru",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Formations en ligne",
	BoschServiceTraining_Icon_BusinessSkills: "Compténces professionnelles",
	BoschServiceTraining_Icon_Accreditations: "Certifications",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programmes de formation",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "FormateurPrénomNom",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Une journée dans la vie d'un formateur",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Chaque jour est une aventure",
	ADayInTheLifeOfATrainer_Summary: "Résumé",
	ADayInTheLifeOfATrainer_TheDayJourney: "L'agenda du jour",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Chaque journée est bien remplie mais c'est toujours une expérience enrichissante.  Le plaisir de former les participants, sachant qu'ils ont entre leurs mains les clés du futur est une responsabilité mais en même temps c'est passionant. Voir les participants évoluer au travers des formations est pour nous une preuve que nous participons à un objectif commun, celui d'accroitre leurs connaissances tout en incorparant la technologie moderne qui incarne le futur!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "En chaque fin de journée nous nous réunissons por discuter de la journée. Les journées passent rapidement mais ces discussions sont la preuve que ce que nous atteignons n'est pas seulement enrichissant pour le participant mais également pour nous en tant que formateur.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Nous pouvons rentrer chez nous le soir avec le sentiment du travail bien fait !",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Commencer",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Préparation et contrôle du matériel de formation. Contrôle des mails important",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "La formation débute",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Acceuillir le participant. Agenda de la journée. Apprendre à connaitre le groupe en comprenant la dynamique de chaque individu.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Théorie",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Un certain nombre d'interactions avec le groupe sont nécessaires afin de rendre la formation aussi interessante que possible. Assurez-vous qu'il y ait des exercices en rapport avec la théorie de façon à impliquer le groupe.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pause déjeuner",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Pratique",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "La formation continue dans l'atelier avec des exercices pratiques.Il est important pour un formateur de toujurs être présent et disponible pour donner un coup de main.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Récapitulatif",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Retour en classe pour faire un débrief sur la journée de formation. Répondre aux questions qui sont posées. Préparation de la prochaine session de formation.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Fin de journée",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Les attestions de participation sont transmises aux participant.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Acceuil",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Comment pouvons nous vous aider à améliorer votre société?",
	BoschTrainingBenefits_HowOurTrainingEvolves: "L'évolution de nos formations",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Nous évoluons constamment afin de pouvoir garantir une qualité de formation optimale",
	BoschTrainingBenefits_TheBoschServiceTraining: "Le centre de formation Bosch souhaite vous aider à développer votre entreprise grâce à notre offre de formations techniques étendue et très pertinente ainsi qu'aux  formations complémentaires aux quallifications certifiées. Vous trouverez notre programme de formation sur les pages suivantes. Nous nous réjouissons de vous rencontrer ou vous revoir lors de l'une de ces formations.",
	BoschTrainingBenefits_LongStandingExperience: "Longue expérience avec des connaissances élargies de tous les systèmes en matière de technique automobile.",
	BoschTrainingBenefits_QuickAndEfficient: "Des solutions rapides et efficaces pour de nombreux problèmes.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Avantages et bénéfices",
	BoschTrainingBenefits_LMS: "Système de gestion de l'apprentissage – Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Soluations couvre toute la gamme Automobile. Spédialisez vous dans un domaine spécifique ou élargissez vos compétences grâce aux formations Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Connaissances acquises installations, réparations, entretien et service.",
	BoschTrainingBenefits_TheHighDegree: "Des contenus de haut niveau et des cours en petits groupes garantissent un apprentissage ciblé",
	BoschTrainingBenefits_ParticipationInTraining: "La participation à des formations augmente la motivation et la satisfaction des employés et les qualifications supplémentaires sont attrayantes pour les clients",
	BoschTrainingBenefits_IncreasedSales: "Augmentation du chiffre d'affaires grâce à la connaissance des systèmes de fabrication transversale",
	BoschTrainingBenefits_MethodicalProcedures: "Les procédures méthodiques réduisent le temps d'attente pour les clients",
	BoschTrainingBenefits_HighlyQualified: "Des instructeurs hautement qualifiés enseignent les procédures systématiques",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identification de la solution de formation correspondant à vos besoins ou à ceux de votre entreprise",
	BoschTrainingBenefits_DirectOnlineBooking: "Réservation directe en ligne et gestion des cours, soit pour vous-même soit pour vos employés",
	BoschTrainingBenefits_OnlineAccess: "Accès en ligne à la documentation des formations",
	BoschTrainingBenefits_AccessToYourOwn: "Accès à votre propre historique de formation et à vos certificats",
	BoschTrainingBenefits_ToolsForManagement: "Outils de gestion de votre propre développement de carrière",
	BoschTrainingBenefits_ManagerHasFull: "Le manager a un aperçu complet des cours de ses employés dans le système",
	BoschTrainingBenefits_Access24hours: "Accès 24 heures sur 24, 7 jours sur 7",
	ModernTrainingApproach_KnowledgeIsKey: "La connaissance est un facteur-clé de réussite de tout atelier. C'est pourquoi Bosch offre un programme complet de développement et de formation professionnels. Ce programme permet aux étudiants d'acquérir les bonnes connaissances au bon moment, c'est-à-dire les connaissances qui leur permettent d'entretenir et de réparer les véhicules de leurs clients de manière professionnelle. Bosch a ajouté le webcasting comme nouveau module dans le cadre du e-learning package déjà proposé. Il s'agit de sessions de formation au service spécialement conçues pour le streaming.",
	ModernTrainingApproach_ANewLearning: "Une nouvelle option d'apprentissage",
	ModernTrainingApproach_YourOnlineTraining: "Vos options d'apprentissage en ligne",
	ModernTrainingApproach_RecentlyTheWorld: "Le monde a changé récemment voyez ce que nous faisons pour faciliter les transitions",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Le monde de la formation en ligne",
	ModernTrainingApproach_NewTeachingMethod: "Nouvelle méthode d'enseignement",
	ModernTrainingApproach_InAdditionTo: "Outre la formation existante en présentiel, nous avons inclus au programme des sessions par webcast en direct. Notre formateur est à votre disposition pour vous transmettre ses vastes connaissances théoriques dans la classe virtuelle ou par des exemples pratiques. Comme d'habitude, vous recevrez des informations détaillées sur les systèmes automobiles sur les produits Bosch correspondants. Vous aurez bien sûr l'occasion de poser des questions. Nous répondrons à ces questions durant le webcast en direct. Les webcasts en direct durent entre 60 et 120 minutes. Il faut disposer d'une connexion Internet stable. Retrouvez le webcast que vous cherchez dans notre catalogue de formations en saisissant \Webcast\ dans le champ de recherche.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch webcasting : innovant et pratique",
	ModernTrainingApproach_BoschWebcastingText: "Pour participer à un webcast Bosch, le participant doit d'abord se connecter. Un code d'accès individuel permettant la connexion est envoyé avant la session. 200 participants au maximum situés partout dans le monde peuvent participer simultanément au webcast. La formation est dispensée par un spécialiste et les questions peuvent être soumises à tout moment depuis la fonction chat en direct.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Le supplément numérique à la formation en classe",
	ModernTrainingApproach_TheDigitalSupplementText: "Le webcasting et la formation en classe se complètent parfaitement. Dans les deux cas, la formation est menée par un formateur qualifié. Les webcasts conviennent aux sujets qui peuvent être enseignés en format numérique sans besoin de travailler sur le véhicule. Par exemple, l'apprentissage de certaines fonctionnalités ESI[tronic] d'un nouveau système d'assistance à la conduite. Lorsqu'il s'agit de travail d'équipe efficace à l'atelier ou de tâches pratiques sur la technologie d'un véhicule, la formation en présentiel est toujours préférable.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Le streaming peut être plus économique",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Le coût par participant est habituellement moins élevé pour le webcasting que pour une session comparable en salle de classe. En effet, le webcasting vise un plus grand nombre de participants et le travail de formation est nettement moins intense (pas de déplacement et gain de temps).",
	ModernTrainingApproach_BookAndProfitHeading: "Réservez et profitez-en",
	ModernTrainingApproach_BookAndProfitText: "Les webcasts peuvent être réservés sur notre site Web, exactement comme nos autres formations et offres d'apprentissage. Vous pouvez soit payer le prix individuel de chaque webcast soit opter pour l'inscription annuelle à un certain nombre de webcasts.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Comparaison entre le webcasting et la formation en classe Bosch",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Salle de classe",
	ModernTrainingApproach_WebcastingP1: "200 participants au maximum situés partout dans le monde peuvent participer simultanément au webcast.",
	ModernTrainingApproach_WebcastingP2: "Simplicité et coûts relativement bas, aucun déplacement requis",
	ModernTrainingApproach_WebcastingP3: "Idéal pour des sujets individuels spécifiques et concis",
	ModernTrainingApproach_WebcastingP4: "Les ateliers peuvent organiser un webcast pour leurs employés partout et à tout moment",
	ModernTrainingApproach_WebcastingP5: "Gain de temps de 50 à 70 % par rapport aux formations en salle de classe",
	ModernTrainingApproach_ClassroomTrainingP1: "Jusqu'à 16 participants par session",
	ModernTrainingApproach_ClassroomTrainingP2: "Frais de déplacement et frais par personne généralement plus élevés",
	ModernTrainingApproach_ClassroomTrainingP3: "Idéal lorsqu'il s'agit d'optimiser la collaboration ou d'apprendre directement sur un véhicule",
	ModernTrainingApproach_ClassroomTrainingP4: "Exercices pratiques et interaction avec le formateur",
	ModernTrainingApproach_ClassroomTrainingP5: "Le formateur peut plus facilement adapter les matières à enseigner aux besoins réels des participants",
	ModernTrainingApproach_OnlineTrainingOfferings: "Offres de formation en ligne (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Aujourd'hui, l'apprentissage sur ordinateur est partie intégrante du transfert de connaissances. La liberté de choisir le moment et le lieu, répétabilité, rythme individuel, d'apprentissage et contenus d'apprentissage interactifs font de cette méthode un outil d'étude indispensable.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Chaque élément de la formation sur le Web (Web-Based Training, WBT) a été structuré avec soin. Ces cours offrent une vaste base de connaissances, mais ils servent également de préparation effective aux programmes de formations dispensés dans les centres de formation Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "La simulation de diagnostic virtuelle propose à l'utilisateur des options de jeu de rôle pour des situations d'atelier quotidiennes telles que le flux de travail d'une procédure de dépannage à l'aide des appareils de diagnostic Bosch dans un atelier virtuel.",
	ModernTrainingApproach_Advantages: "Avantages",
	ModernTrainingApproach_AdvantagesP1: "Méthode rapide et efficace pour transmettre le savoir",
	ModernTrainingApproach_AdvantagesP2: "Apprentissage varié",
	ModernTrainingApproach_AdvantagesP3: "Peut être utilisé aussi souvent que souhaité",
	ModernTrainingApproach_AdvantagesP4: "Moins de temps et de frais",
	ModernTrainingApproach_AdvantagesP5: "Rythme d'apprentissage individuel",
	ModernTrainingApproach_AdvantagesP6: "Temps d'étude flexible",
	ModernTrainingApproach_AdvantagesP7: "Répétabilité",
	ModernTrainingApproach_AdvantagesP8: "Pas de frais de déplacement",
	ModernTrainingApproach_AdvantagesP9: "Aucun conflit de capacité ou de programmation",
	Dashboard_CoursesViewAndManageYourCourses: "FORMATIONS| Visualisez et gérez vos formations",
	Dashboard_MyCourses: "Mes formations",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMME DE FORMATION | Affichez vos programmes de formation",
	Dashboard_MyTrainingProgram: "Mon programme de formation",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Visualisez et gérez votre portefeuille eLearning",
	Dashboard_MyELearning: "Mon eLearning",
	Dashboard_AccountManageYourAccount: "COMPTE | Gérez votre compte",
	Dashboard_MyAccount: "Mon compte",
	Dashboard_MyDashboard: "Mon tableau de bord",
	Dashboard_ManageYourAccount: "Gérez votre compte",
	Dashboard_Workshops: "Ateliers",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRATION | Gérez votre atelier",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRATION | Gérez vos équipes",
	Dashboard_Administration: "Administration",
	Dashboard_ViewYourCourses: "Afficher vos formations",
	Dashboard_ViewYourTrainingPrograms: "Affichez vos programmes de formation",
	Dashboard_TrainingPrograms: "Programmes de formation",
	Dashboard_ViewAndEditYourDetails: "Affichez et modifiez vos coordonnées",
	Dashboard_ViewYourELearningCourses: "Affichez vos eLearning",
	Dashboard_Pending: "En attente",
	Dashboard_RequiresAdministratorSignOff: "(nécessite la signature de l'administrateur)",
	Dashboard_HistoricalRecords: "Historique",
	Dashboard_Registered: "Formations planifiées",
	Dashboard_WaitingList: "Liste d'attente",
	Dashboard_RecommendedForYou: "Recommandé pour vous",
	Dashboard_AccountDetails: "Détails du compte",
	Dashboard_ChangeProfileImage: "Modifier l'image de profil",
	Dashboard_Title: "Titre",
	Dashboard_Role: "Rôle",
	Dashboard_FirstName: "Prénom",
	Dashboard_LastName: "Nom",
	Dashboard_Telephone: "Numéro de téléphone",
	Dashboard_Mobile: "Mobile",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Langue",
	Dashboard_Save: "Sauvegarder",
	Dashboard_Student: "Étudiant",
	Dashboard_WorkshopTrainingAdministrator: "Responsable des formations dans l'atelier",
	Dashboard_WholesalerTrainingAdministrator: "Responsable des formations grossiste",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administration de l'entreprise",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Gérez vos ateliers...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Gérez vos employés...",
	Dashboard_CompanyAdministration_Workshop: "Atelier",
	Dashboard_CompanyAdministration_Address: "Adresse",
	Dashboard_CompanyAdministration_Zip: "Code postal",
	Dashboard_CompanyAdministration_City: "Ville",
	Dashboard_CompanyAdministration_Manage: "Gérer",
	Dashboard_AdministrationSection_AdministrationTitle: "Administration",
	Dashboard_AdministrationSection_ManageTheTeam: "Gérez l'équipe",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRATION | Gérez vos employés",
	Dashboard_AdministrationSection_Employees: "Employés",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRATION | Gérez vos grossistes",
	Dashboard_AdministrationSection_Wholesalers: "Grossistes",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRATION | Gérez vos transactions",
	Dashboard_AdministrationSection_Transactions: "Transactions",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administration des employés",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Gérez vos employés...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Enregistrer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Dossiers",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Éditer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Employé",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Ajouter",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Créer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Enregistré",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "En attente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(nécessite la signature de l'administrateur)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historique",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Liste d'attente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Liste des employés actuels",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Gestion des employés",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nom",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Compte employé",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Afficher et éditer les coordonnées de l'employé...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Paramètres",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Autoriser un compte personnel BATS",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Mise à niveau mandataire administration atelier employé",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Autoriser l'employé à s'inscrire aux formations",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Envoyer l'e-mail d'invitation",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Désactiver le compte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Sauvegarder",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Envoyer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Éditer",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Gestion des grossistes",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Gérez vos grossistes...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Liste de grossistes agréés",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nom",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Supprimer",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Créer",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Ajouter",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Création d'un compte nouvel employé",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Créer les coordonnées des nouveaux employés...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Ajouter un nouvel administrateur grossiste agréé",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Créez les coordonnées des nouveaux grossistes...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Choisissez un grossiste agréé dans le menu déroulant...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transactions",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Affichez et gérez vos transactions...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Retour",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Numéro de facture",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Description",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Méthode de paiement",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Date",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Montant",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Télécharger",
	TrainingCentres_TrainingCentres: "Centres de formation",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nous avons des centres de formation partout dans le monde",
	TrainingCentres_CountryCategory: "Catégorie de pays",
	TrainingCentres_CityCategory: "Catégorie de ville",
	TrainingCentres_Country: "Pays",
	TrainingCentres_City: "Ville",
	TrainingCatalogue_TrainingCatalogue: "Catalogue des formations",
	TrainingCatalogue_LearningAtTheHighestLevel: "Apprendre au plus haut niveau",
	TrainingCatalogue_StartDate: "Date de début",
	TrainingCatalogue_EndDate: "Date de fin",
	TrainingCatalogue_TrainingName: "Nom de la formation",
	TrainingCatalogue_Language: "Langue",
	TrainingCatalogue_Days: "Jours",
	TrainingCatalogue_Seats: "Places disponibles",
	TrainingCatalogue_Price: "Prix",
	TrainingCatalogue_Location: "Lieu",
	TrainingCatalogue_PartNumber: "Référence",
	TrainingCatalogue_TrainingCourse: "Formation",
	TrainingCatalogue_Category: "Catégorie",
	TrainingCatalogue_TrainingCategory: "Catégorie de formation",
	TrainingCatalogue_CourseName: "Intitulé de formation",
	TrainingCatalogue_TotalDays: "Nombre total de jours",
	TrainingCatalogue_Venue: "Lieu",
	TrainingCatalogue_Details: "Détails",
	TrainingCatalogue_Prerequisites: "Conditions préalables",
	TrainingCatalogue_CourseType: "Type de formation",
	TrainingCatalogue_LearningMethod: "Méthode d'apprentissage",
	TrainingCatalogue_AddToWaitingList: "Ajouter à la liste d'attente",
	ShoppingCart_ShoppingCart: "Panier",
	ShoppingCart_ReviewAndManage: "Vérifiez et gérez vos inscriptions",
	ShoppingCart_YouHaveAddedItem: "Vous avez ajouté les articles suivants à votre panier",
	ShoppingCart_Item: "Article",
	ShoppingCart_Quantity: "Quantité",
	ShoppingCart_PricePerItem: "Prix par article",
	ShoppingCart_TotalPrice: "Prix total",
	ShoppingCart_VatIsIncluded: "Hors TVA",
	ShoppingCart_Redeem: "Si vous n'avez plus de crédit, cliquez ici pour en racheter",
	ShoppingCart_Purchase: "Si vous n'avez pas de crédit, cliquez ici pour en acheter",
	ShoppingCart_Checkout: "Régler",
	ShoppingCart_ContinueShopping: "Continuer vos achats",
	ShoppingCart_TotalPriceInclVat: "Prix total TVA incluse",
	ShoppingCart_Apply: "Appliquer",
	ShoppingCart_ShoppingCartEmpty: "Votre panier est vide",
	ShoppingCart_VatIncluded: "TVA (incluse)",
	ShoppingCart_ManageYourShopping: "Gérez vos achats",
	ShoppingCart_Participant: "Participant",
	ShoppingCart_Complete: "Accompli",
	ShoppingCart_NetPrice: "Prix net",
	ShoppingCart_Workshop: "Atelier",
	CheckOut_PreviewYourOrder: "Aperçu de votre commande",
	CheckOut_YourShoppingCart: "Panier",
	CheckOut_Preview: "Aperçu",
	CheckOut_Payment: "Paiement",
	CheckOut_Summary: "Résumé",
	CheckOut_Back: "Retour",
	CheckOut_PleaseReadAndAgreeOurTerms: "Veuillez lire et accepter nos conditions générales",
	CheckOut_IHaveReadTheTermsAndCondition: "J'ai lu les [conditions générales] , je les comprends et les accepte",
	CheckOut_YesIAgree: "Oui – J'accepte les conditions générales",
	CheckOut_Continue: "Continuer",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Félicitations ! Nous avons bien reçu votre commande",
	CheckOut_OrderConfirmation: "Vous recevrez la confirmation définitive de votre commande dès que votre demande aura été traitée",
	CheckOut_WeWillContactYouDirectly: "Nous vous contacterons directement en cas de questions concernant votre demande",
	CheckOut_AddToCart: "Ajouter au panier",
	CheckOut_CreditCart: "Carte de crédit",
	CheckOut_DirectDebit: "Prélèvement automatique",
	CheckOut_TrainingCredits: "Crédit formation",
	CheckOut_PreferredWholesaler: "Grossiste préféré",
	EventCalendar_EventCalendar: "Calendrier des formations",
	EventCalendar_TrainingEventsForAllYourNeeds: "Des formations pour tous vos besoins",
	TrainingCourse_TrainingCourse: "Catalogue des formations",
	TrainingCourse_LearningAtTheHighestLevel: "Apprendre au plus haut niveau",
	TrainingProgram_Title: "Catalogue des programmes de formation",
	TrainingProgram_SubTitle: "Apprendre au plus haut niveau",
	AccountDeactivationModal_Title: "Êtes-vous sûr(e) de vouloir désactiver ce compte ?",
	AccountDeactivationModal_Line1: "La désactivation de ce compte est définitive et supprime tout le contenu, y compris les services, demandes, transactions et soldes, membres d'équipe et paramètres de profil.",
	AccountDeactivationModal_Line2Part1: "Si la désactivation se produit durant une période d'inscription active, veuillez vous référer à nos conditions d'annulation dans nos",
	AccountDeactivationModal_Line2Part2: "conditions générales",
	AccountDeactivationModal_Line2Part3: "avant de soumettre votre demande.",
	AccountDeactivationModal_Line3: "Êtes-vous sûr(e) de vouloir soumettre une demande de désactivation de ce compte ?",
	AccountDeactivationModal_Cancel: "Annuler",
	AccountDeactivationModal_Send: "Envoyer",
	CurrentUserAccountDeactivationModal_Title: "Êtes-vous sûr(e) de vouloir désactiver votre compte ?",
	CurrentUserAccountDeactivationModal_Line1: "La désactivation de votre compte est définitive et supprime tout le contenu, y compris les services, demandes, transactions et soldes, membres d'équipe et paramètres de profil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Si vous êtes dans une période d'inscription active veuillez vous référer à nos conditions d'annulation dans nos",
	CurrentUserAccountDeactivationModal_Line3: "Êtes-vous sûr(e) de vouloir soumettre une demande de désactivation de votre compte ?",
	CurrentUserAccountDeactivationModal_Yes: "Oui",
	CurrentUserAccountDeactivationModal_No: "Non",
	CourseWithdrawalModal_Title: "Annulation de formation",
	CourseWithdrawalModal_Line1: "Etes-vous certain de vouloir annuler votre inscription à la formation?",
	CourseWithdrawalModal_Line2Part1: "Il peut y avoir des frais d'annulation, ceux-ci seront facturés selon les conditions d'annulations mentionnées dans les",
	CourseWithdrawalModal_Line2Part2Link: "Conditions générales",
	CourseWithdrawalModal_Line2Part3: "de notre service",
	CourseWithdrawalModal_Yes: "Oui, annuler",
	CourseWithdrawalModal_No: "Non, abandonner",
	Settings_Settings: "Paramètres",
	Settings_ManageYourSettings: "Gérez vos paramètres",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Informations de connexion à mon ID global Bosch",
	Settings_Edit: "Éditer",
	Settings_CompanyDetails: "Détails de l'entreprise",
	Settings_RequestAccountDeletion: "Demander la suppression de votre compte",
	Settings_AccountDeletionText: "Si vous supprimez votre compte, toutes vos données (y compris votre adresse et vos données de paiement) seront effacées et ne seront plus accessibles.",
	Settings_DeleteAccountRequestConfirmation: "Confirmation demande de suppression du compte",
	Settings_DeleteAccountRequestConfirmationText: "Nous avons reçu votre demande de suppression du compte et nous vous contacterons par e-mail ou par téléphone pour la confirmation définitive",
	RecommendedHotels_RecommendedHotels: "Hôtels recommandés",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nous avons des centres de formation partout dans le monde",
	Error_Common: "Il y a eu un problème !",
	Error_404: "La page que vous avez visitée n'existe pas",
	Error_401: "Vous n'avez pas accès à cette page",
	Error_500: "Le serveur signale une erreur",
	Dashboard_Courses_Table_Course: "Formation",
	Dashboard_Courses_Table_Date: "Date []",
	Dashboard_Courses_Table_Venue: "Lieu",
	Dashboard_Courses_Table_TrainingType: "Type de formation",
	Dashboard_Courses_Table_Action: "Action",
	Dashboard_Courses_Table_TasksToComplete: "Vous devez completer les tâches suivantes",
	Dashboard_Courses_Table_Withdraw: "Annuler",
	Dashboard_Courses_Table_Remove: "Supprimer",
	Dashboard_Courses_Table_View: "Aperçu",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Pas de données disponibles",
	Dashboard_Courses_Table_CompletionDate: "Fin de formation",
	Dashboard_Courses_Table_Actions_Assignments: "Attribution",
	Dashboard_Courses_Table_Actions_Certification: "Certification",
	Dashboard_Courses_Table_Actions_Evaluations: "Evalutations",
	Dashboard_Courses_Table_Actions_Assessments: "Appréciation",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Ajouter au calendrier",
	Dashboard_Courses_Table_CourseDetails_Task: "Tâche",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Date butoir",
	ContactUs_Headline: "Contactez nous",
	ContactUs_HowCanWeHelp: "Comment pouvons-nous vous aider ?",
	ContactUs_EmailTab: "Email",
	ContactUs_PhoneTab: "Tel",
	ContactUs_LetterTab: "Lettre",
	ContactUs_Phone_Headline: "Appelez nous!",
	ContactUs_Phone_Description: "Nous attendons votre appel et nous nous ferons un plaisir de vous assister.",
	ContactUs_Phone_PhoneNumber: "Veuillez utiliser le formulaire de contact pour nous envoyer un message",
	ContactUs_Phone_ChargeNotice: "Les charges varient en fonction de votre fournisseur et de votre pays.",
	ContactUs_Letter_Headline: "Envoyez-nous une lettre!",
	ContactUs_Letter_Description: "Vous avez des questions, des remarques ou des suggestions? Nous attendons votre demande",
	ContactUs_Email_Headline: "Envoyez-nous un email",
	ContactUs_Email_Description: "Envoyez-nous votre message en remplissant le formulaire ci-dessous. Un de nos collaborateurs prendra contact avec vous.",
	ContactUs_Email_InputName: "Nom*",
	ContactUs_Email_InputCompany: "Entreprise",
	ContactUs_Email_InputCountry: "Pays*",
	ContactUs_Email_InputEmail: "Email*",
	ContactUs_Email_InputMessage: "Message*",
	ContactUs_Email_DataProtection: "Protection des données*",
	ContactUs_Email_DataProtectionConfirm: "J'ai lu la politique de protection des données*",
	ContactUs_Email_SecurityCheck: "Contrôle de sécurité",
	ContactUs_Email_SecurityCodeConfirm: "Saisissez les caractères qui apparaissent dans le cadre ci-dessus",
	ContactUs_Email_SecurityCodeInvalid: "Le captcha n'est pas correct",
	ContactUs_Email_MandatoryNotice: "Veuillez remplir les champs obligatoires",
	ContactUs_Email_SuccessMessage: "Votre message a été envoyé",
	ContactUs_Email_TakenNoteDataProtection1: "J'ai lu la",
	ContactUs_Email_TakenNoteDataProtection2: "Politique de confidentialité*",
	ContactUs_Email_SubmitButton: "Transmettre",
	ContactUs_Email_FieldErrorMessage: "Veuillez remplir les champs obligatoires",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Votre compte est actuellement en révision!",
	AccountInReviewModal_Paragraph1: "Merci d'avoir créé un compte personnel sur Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "Notre équipe des formations en entrain d'examiner et de configurer votre compte. Vous recevrez un mail de confirmation dès que votre compte a été configuré",
	AccountInReviewModal_Paragraph3: "Entretemps, prenez le temps de parcourir notre site avec un accès restreint",
	AccountInReviewModal_Paragraph4: "Pour toutes questions, ou si votre demande de compte n'a pas été traité en déans 48h (hors jours fériés), n'hésitez pas à nous contacter",
	AccountInReviewModal_ContactUsLink: "Ici",
	AccountInReviewModal_OKButton: "OK, allons-y!",
	Header_YesButton: "Oui",
	Header_NoButton: "Non",
	Header_LogOutText: "Voulez-vous vous déconnecter?",
	Footer_EnquiryText: "Nous nous réjouissons de votre demande,",
	Footer_Telephone: "Tel",
	Footer_TermsAndConditions: "Conditions générales",
	Footer_HowOurTrainingEvolves: "Evolution de nos formations",
	FooterSearch_SearchFieldPlaceholder: "Chercher",
	TrainingCatalogue_Action: "Action",
	TrainingCatalogue_Duration: "Durée",
	TrainingCatalogue_Information: "Information",
	Settings_PersonalDetails: "Données personnelles",
	Reset_All_Filters: "Remise à zéro des filtres",
	EventTable_Days: "Jours",
	SearchField_AllResults: "Tous les résultats",
	TrainingCentresLayout_ViewDetails: "Détails",
	Dropdown_Placeholder: "Choisir ...",
	WaitlistPopup_SelectButtonText: "Sélectionner",
	WaitlistPopup_StartDate: "Date de début",
	WaitlistPopup_EndDate: "Date de fin",
	WaitlistPopup_Language: "Langue",
	WaitlistPopup_City: "Ville",
	WaitlistPopup_Workshop: "Workshop",
	WaitlistPopup_Student: "Etudiant",
	WaitlistPopup_PreferredLocation: "Site de prédilection",
	WaitlistPopup_SignUpCourseWaitingList: "Inscrivez-vous sur liste d'attente",
	WaitlistPopup_SignUpWithoutSelectingDate: "Inscrivez-vous sur liste d'attente sans sélectionner de date",
	WaitlistPopup_GeneralWaitList: "Liste d'attente générale",
	WatinglistPopup_CourseLabel: "Formation",
	Common_ContinueButton: "Continuer",
	UserAccountDeactivationModal_ConfirmLabel: "Confirmer",
	Filter_FilterLabel: "Filtre",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Vous êtes déjà inscrit sur la liste d'attente. Voulez-vous supprimer celle-ci et recommencer?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Oui, supprimer",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Non, annuler",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Annuler formation",
	WaitinglistPopup_SignUpButtonText: "S'inscrire",
	MyAccount_FirstNameRequied: "Prénom requis",
	MyAccount_LastNameRequired: "Nom de famille requis",
	MyAccount_RoleRequired: "Rôle requis",
	MyAccount_MobileRequired: "N° de gsm requis",
	MyAccount_EmailRequired: "Email requis",
	MyAccount_LanguageRequired: "Langue requise",
	MyAccount_UpdateSuccessMessage: "Vos données ont été mises à jour",
	MyAccount_UpdateSuccessModal_ButtonText: "Oui, fermer",
	Infor_CorporateInformation_Header: "Information d'entreprise",
	Infor_LegalNotice_Header: "Notice légale",
	Infor_PrivacyPolicy_Header: "Notice protection des données",
	CreateEmployeeAccountModification_FormValidation_Role: "Rôle requis",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Prénom requis",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Nom de famille requis",
	CreateEmployeeAccountModification_FormValidation_Mobile: "N° de gsm requis",
	CreateEmployeeAccountModification_FormValidation_Email: "Email requis",
	CreateEmployeeAccountModification_FormValidation_Language: "Langue requise",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitation à rejoindre Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Le nouveau membre de votre équipe a bien été ajouté",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Voulez-vous envoyer une invitation à votre nouveau membre d'équipe? Cette invitation comprendra les explications quant à comment créer un compte personnel sur Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Non",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Oui, envoyer invitation",
	ShoppingCart_Venue_Fee: "Frais de salle",
	ShoppingCart_Trainning_Name: "Intitulé de formation",
	ShoppingCart_Available_Credits: "Crédits disponibles",
	ConfirmItsMeModal_Hi: "Bonjour",
	ConfirmItsMeModal_Description1: "Vous avez correctement complèté l'invitation partagée par votre administrateur afin de créer votre compte personnel sur Bosch Automotive Training Solutions",
	ConfirmItsMeModal_Description2: "Avant de continuer, veuillez confirmer ou mettez à jour vos données.",
	ConfirmItsMeModal_Description3: "Après confirmation il vous sera demandé de vous connecter à nouveau afin de finaliser votre création de compte",
	ConfirmItsMeModal_CompanyDetails: "Détails de l'entreprise",
	ConfirmItsMeModal_YourDetails: "Vos données",
	ConfirmItsMeModal_YourDetails_Title: "Titre",
	ConfirmItsMeModal_YourDetails_Function: "Fonction",
	ConfirmItsMeModal_YourDetails_Name: "Nom",
	ConfirmItsMeModal_YourDetails_Mobile: "N° de gsm",
	ConfirmItsMeModal_YourDetails_Email: "Email",
	ConfirmItsMeModal_YourDetails_Language: "Langue",
	ConfirmItsMeModal_ConfirmButton: "Confirmer",
	SelectWholesalerModal_WholesalerGroupLabel: "Grossiste",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Veuillez selectionner",
	ApplyButton: "Appliquer",
	SelectWholesalerModal_Title: "Chosissez votre grossiste",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Voulez-vous supprimer votre enregistrement?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Votre enregistrement a bien été supprimé",
	WaitlistPopup_AddToWaitListSuccess: "Votre enregistrement a bien été ajouté",
	ErrorCommon_Header: "ERREUR",
	CommonButton_Close: "Fermer",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Veuilez fermer cette fenêtre pour continuer!",
	EmbeddedVideoPlayer_StartButtonText: "Démarrer",
	EmbeddedVideoPlayer_RefresherButtonText: "Rafraichir",
	DeactivateWholesalerModal_ConfirmMessage: "Etes-vous certain de vouloir retirer ce grossiste?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "L'invitation a bien été envoyée!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Inviter à nouveau",
	ContactUs_Mobile_FR: "+91 99999999",
	ContactUs_Address_FR: "Robert Bosch N.V.,Henri Genessestraat 1,Brussel 1070,België",
	ConfirmItsMeModal_CompanyDetails_Name: "Nom de l'entreprise",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresse",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Code postale",
	ConfirmItsMeModal_CompanyDetails_Phone: "Téléphone",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "L'annulation de votre inscription est bien enregistrée!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "La création de votre compte a échouée. Ce compte existe déjà!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "La mise à jour de votre compté a échouée. Ce compte existe déjà!",
	Footer_ExploreTrainingStories: "Découvrez toutes nos histoires",
	Breadcrumb_Home: "Acceuil",
	Breadcrumb_Contact: "Contact",
	Breadcrumb_Stories: "Histoires",
	Breadcrumb_Stories_BoschServiceTraining: "Formation Bosch Service",
	Breadcrumb_Stories_ADayInLife: "Une journée dans la vie du formateur",
	Breadcrumb_Stories_BoschTrainingBenefits: "Les avantages des formations Bosch",
	Breadcrumb_Stories_TrainingApproach: "Une approche moderne en matière de formation",
	Breadcrumb_Settings: "Paramètres",
	Breadcrumb_ShoppingCart: "Panier",
	Breadcrumb_Checkout: "Check out",
	Breadcrumb_OrderSummary: "Récapitulatif de la commande",
	Breadcrumb_Exception: "Exception",
	Breadcrumb_Dashboard: "Tableau de bord",
	Breadcrumb_MyCourses: "Mes formations",
	Breadcrumb_MyELearning: "Mes formations en ligne",
	Breadcrumb_CompanyAdministration: "Administration de l'entreprisse",
	Breadcrumb_EmployeeManagement: "Gestion du personnel",
	Breadcrumb_EmployeeAccount: "Compte employé",
	Breadcrumb_EmployeeCreation: "Ajouter un employé",
	Breadcrumb_Administration: "Administration",
	Breadcrumb_EmployeeAdministration: "Gestion des employés",
	Breadcrumb_WholesalerManagement: "Gestion du grossiste",
	Breadcrumb_NewWholesalerAccountCreation: "Créer un nouveau compte grossiste",
	Breadcrumb_Transactions: "Transactions",
	Breadcrumb_MyAccount: "Mon compte",
	Breadcrumb_TrainingCentres: "Centre(s) de formation",
	Breadcrumb_EventCalendar: "Calendrier formations",
	Breadcrumb_TrainingCourseCatalogue: "Catalogue des formations",
	Breadcrumb_RecommendedHotels: "Hôtels recommandés",
	Breadcrumb_CorporateInformation: "Information d'entreprise",
	Breadcrumb_LegalNotice: "Mention légale",
	Breadcrumb_DataProtectionNotice: "Protection des données",
	Breadcrumb_PrivacyPolicy: "Politique de confidentialité",
	Breadcrumb_TermCondition: "Conditions générales",
	Breadcrumb_Terms: "Conditions",
	Breadcrumb_PrivacyStatement: "Charte de confidentialité",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Fournisseur",
	Breadcrumb_RegisterAccount: "Enregistrer le compte",
	Stories_ExploreMore: "Découvrir plus",
	CheckOutLayout_ShoppingCartHeader: "Panier",
	CheckOutLayout_AddedItemCartMessage: "Vous avez ajoutez les choses suivantes à votre panier",
	CheckOutLayout_CheckYourCartMessage: "Vérifier votre panier",
	ModernTrainingApproach_DateText: "28.02.2022",
	ADayInLifeofATrainer_DateText: "28.02.2022",
	BoschTrainingBenefits_DateText: "28.02.2022",
	BoschServiceTraining_DateText: "28.02.2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Rechercher des employés",
	CheckOutLayout_OrderWasNotPlaced_Message: "Désolé, votre commande n'est pas passée!",
	CheckOutLayout_RefusalReason: "Raison de refus",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Le paiement a échoué. Veuillez réessayer avec un mode de paiement différent.",
	CheckOutLayout_SubmittingApiFailed_Message: "La transmission des données a échouée",
	CartItem_ChooseWorkshop_Message: "Veuillez choisir un atelier afin de sélectionner un employé",
	CartItem_ChooseParticipant_Message: "Veuillez choisir un employé afin de continuer",
	CompanyAdministrationTable_SearchBox_Placeholder: "Rechercher des ateliers",
	CartItem_RemoveCartItem_ConfirmText: "Voulez-vous supprimer l'objet de votre panier?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Fonction",
	AddToCartSuccessModal_Message: "Votre panier a été mis à jour",
	AddToCartSuccessModal_ContinueButtonText: "Continuer mes achats",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mon panier",
	AddVoucher_Header: "Ajouter un voucher",
	AddVoucher_InputPlaceholder: "Indiquez votre code et confirmez",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Malheureusement, nous n'avons pas de sessions programmées pour cette formation pour le moment. Veuillez vous inscrire sur la liste d'attente et nous vous informerons dès qu'une session sera planifiée.",
	ADYEN_ERR_2_: "La transaction a été refusée",
	ADYEN_ERR_3_: "Raison du refus",
	ADYEN_ERR_4_: "La transaction n'a pu avoir lieu en raison d'une erreur qui est survenue chez l'acquéreur",
	ADYEN_ERR_5_: "La carte utilisée pour la transaction est bloquée et donc inutilisable",
	ADYEN_ERR_6_: "La carte utilisée pour la transaction n'est plus valable et donc inutilisable",
	ADYEN_ERR_7_: "Une discordance au niveau du montant est survenue durant la transaction",
	ADYEN_ERR_8_: "Le numéro de la carte est incorrecte ou non valable",
	ADYEN_ERR_9_: "Il n'est pas possible de contacter la banque de l'utilisateur afin d'autoriser la transaction",
	ADYEN_ERR_10_: "La banque de l'utilisateur n'autorise pas ce genre de transaction",
	ADYEN_ERR_11_: "La vérification d'authentification 3D n'a pu être exécutée ou pas de manière complète",
	ADYEN_ERR_12_: "Le solde de votre carte est insuffisant pour effectuer cette transaction",
	ADYEN_ERR_14_: "Fraude possible",
	ADYEN_ERR_15_: "La transaction a été annulée",
	ADYEN_ERR_16_: "L'acheteur a annulé la transaction avant de l'avoir confirmée",
	ADYEN_ERR_17_: "Le code PIN est incorrect ou non valable",
	ADYEN_ERR_18_: "L'acheteur a introduit un mauvais code PIN plus de trois fois d'affilé",
	ADYEN_ERR_19_: "Il n'est pas possible de valider le code PIN",
	ADYEN_ERR_20_: "Fraude possible",
	ADYEN_ERR_21_: "La transaction n'a pas été soumise correctement",
	ADYEN_ERR_22_: "Le contrôle des risques à indiqué cette transaction comme étant frauduleuse (score >= 100); l'opération est donc annulée",
	ADYEN_ERR_23_: "Les codes correspondent à la raison de refus suivant :  cette transaction n'est pas authorisée pour cet émetteur/détenteur de carte",
	ADYEN_ERR_24_: "Le code CVC (card security code) n'est pas valable",
	ADYEN_ERR_25_: "Les codes correspondent à la raison de refus suivante : Carte non valable dans ce pays",
	ADYEN_ERR_26_: "R1 : annulation de l'autorisation / R3: Annulation de toutes les autorisations / R0: Annulation du paiement",
	ADYEN_ERR_27_: "Cette réponse regroupe toutes les réponses qui n'ont pas pu être cartographiées correctement. Cela permet de différencier plus facilement les refus génériques (par exemple, Mastercard \"05:  \"n'honore pas\")  des autres.",
	ADYEN_ERR_28_: "Le montant de retrait autorisé avec cette carte est dépassé.",
	ADYEN_ERR_29_: "Le nombre de retraits autorisés avec cette carte est dépassé",
	ADYEN_ERR_31_: "L'émetteur de la carte a indiqué cette transaction comme frauduleuse",
	ADYEN_ERR_32_: "Les coordonnées introduites par l'acheteur ne sont pas correctes",
	ADYEN_ERR_33_: "La banque de l'acheteur demande l'introduction d'un code PIN en ligne",
	ADYEN_ERR_34_: "La banque de l'acheteur requiert un compte courant pour cloturer la commande",
	ADYEN_ERR_35_: "La banque de l'acheteur requiert un compte d'épargne pour cloturer la commande",
	ADYEN_ERR_36_: "La banque de l'acheteur demande l'introducition d'un code PIN mobile",
	ADYEN_ERR_37_: "L'acheteur a abandonné la transaction après la tentative de payement sans contact et a été invité à essayer un autre moyen de paiement par carte (PIN ou glissement)",
	ADYEN_ERR_38_: "L'émetteur a refusé la demande d'exemption d'authentification et requiert une authentification pour la transaction. Réessayez avec 3D sécurisé",
	ADYEN_ERR_39_: "L'émetteur ou le dispositif n'a pu communiquer le résultat via Rreq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Le prénon ne doit pas contenir de caractères spéciaux (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Le prénom doit être plus court que 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Le nom de famille ne doit pas contenir de caractères spéciaux (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Le nom de famille doit être plus court que 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Le numéro du mobile ne doit pas contenir de caractères spéciaux (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Le numéro du mobile doit comporter rmaximum 45 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Le numéro de téléphone ne doit pas contenir de caractères spéciaux (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Le numéro de téléphone doit comporter rmaximum 45 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "L'adresse mail est incorrecte",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "L'adresse mail doit comporter rmaximum 100 caracteres",
	ConfirmGeolocationModal_ConfirmButton: "Continuer",
	ConfirmGeolocationModal_CancelButton: "Modification",
	ConfirmGeolocationModal_CurrentCountrySelection: "Votre sélection de pays actuelle est :",
	ConfirmGeolocationModal_AskChangeCountry: "Souhaitez-vous rester sur ce site ou modifier votre préférence de pays ?",
	Addtocart_Modal_AddToCartFailTitle: "L'ajout d'un participant a échoué",
	Addtocart_Modal_AlreadyInCart: "Le participant est déjà inscrit pour cette formation",
	TrainingCatalogue_DateRange: "Période",
	TrainingCatalogue_DateRange_placeholder: "Selectionner le filtre période",
	Dashboard_MyPendingCourses: "Mes formations en attente",
	Dashboard_MyWaitingList: "Ma liste d'attente",
	Dashboard_LatestNews: "Dernières informations",
	SettingsMainPage_EditAccountButton: "Modifier compte",
	SettingsMainPage_EditBoschId: "Modifier Bosch ID",
	EventInfoView_EventDetailTab: "Details de session",
	EventInfoView_AssignmentsTab: "Tâches",
	EventInfoView_EvaluationTab: "Evaluation",
	EventInfoView_AttachmentTab: "Annexes",
	EventInfoView_CourseDescriptionTab: "Description",
	EventInfoView_DescriptionTab_General: "Général",
	EventInfoView_DescriptionTab_PartLabel: "Partie",
	AssignmentTab_Description: "Vous trouverez ici toutes les informations dont vous avez besoin pour completer cette formation",
	AssignmentTab_Columns_Download: "Telecharger",
	AssignmentTab_Columns_Upload: "Charger",
	AssignmentTab_Columns_TaskComplete: "Tâche achevée",
	EventDetailTab_Location_Message_Video: "Ceci est une session en ligne à laquelle vous accèdez en utilisant le lien  \"Lancer la Video\"",
	EventDetailTab_Location_Message_WebBased: "Ceci est une session en ligne à laquelle vous accèdez en utilisant le lien  \"Lancer le WBT\"",
	EventDetailTab_Location_Message_WebCast: "Ceci est une session en ligne à laquelle vous accèdez en utilisant le lien  \"Rejoindre maintenant\"",
	EventDetailTab_Link_Video: "Lancer la video",
	EventDetailTab_Link_Video_Remark: "Le lancement de la video va ouvrir une nouvelle fenêtre. Vous serez redirigé vers cette page une fois la fenêtre fermée",
	EventDetailTab_Link_WebBased: "Lancer le WBT",
	EventDetailTab_Link_WebBased_Remark: "Le lancement du WBT va ouvrir une nouvelle fenêtre. Vous serez redirigé vers cette page une fois la fenêtre fermée",
	EventDetailTab_Link_WebCast: "Joindre maintenant",
	EventDetailTab_Link_WebCast_Remark: "Le lien \"Joindre maintenant\" sera accessible 30 minutes avant le début de la session",
	EventDetailTab_Title_Status: "Statut",
	EventDetailTab_Title_Duration: "Durée",
	EventDetailTab_Title_Trainers: "Formateur(s)",
	EventDetailTab_Title_Language: "Langue",
	EventDetailTab_Title_Score: "Score (%)",
	EventDetailTab_Title_Calendar: "Ajouter au calendrier",
	EventDetailTab_Title_Certificate: "Telecharger l'attestation",
	EventDetailTab_Title_Messages: "Messages",
	EventDetailTab_Title_StartDate: "Date de début",
	EventDetailTab_Title_AvailableFrom: "Disponible à partir du",
	EventDetailTab_Title_Time: "Temps",
	EventDetailTab_Title_EndDate: "Date de fin",
	EventDetailTab_Title_ExpiresOn: "Expire le",
	EventDetailTab_Title_Room: "Local",
	EventDetailTab_Title_Method: "Méthode",
	EventDetailTab_Title_Link: "Lien*",
	EventDetailTab_Title_Location: "Lieu",
	AttachmentTab_EmptyScreenDescription: "Il n'y a pas d'annexes pour cette session",
	AttachmentTab_Columns_Download: "Télécharger",
	AttachmentTab_Description: "Vous trouverez ici quelques informations qui vous aideront pour cette formation",
	EvaluationTab_Annotation: "Nous apprecions vos retours et aimerions connaître votre avis sur notre façon de faire. Merci de bien vouloir prendre le temps pour remplir l'évalution qui nous permet de continuer à nous améliorer.",
	EvaluationTab_Notice: "Les notes sont les suivantes",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Veuillez répondre à toutes les questions requises avant de transmettre votre évaluation",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Votre retour a bien été transmis",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "L'envoi de votre évalutation a échoué",
	TrainingCatalogueLayout_ExportButtonText: "Export",
	EvaluationTab_FeedbackHasBeenGiven_Message: "L'évaluation a été complètée ou est expirée",
	EventDetailTab_Link_CopiedTooltip: "Copié dans le presse-papier",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scannez pour lancer WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scannez pour lancer la video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scannez pour lancer le Webcast",
	Footer_TrainingCoure_Headline: "Formations dispensées par Bosch",
	Footer_TrainingCourse_Content: "En tant que marque de renommée mondiale et avec plus d'un siècle d'expérience dans l'innovation, Bosch a établi les normes mondiales en tant que fournisseur de systèmes automobiles, de pièces et de solutions techniques. Une grande partie de notre force provient de nos partenariats étroits avec les constructeurs automobiles, pour lesquels nous concevons, développons et produisons des systèmes et des composants de pointe dans le monde entier. Par conséquent, Bosch a cimenté une solide histoire dans les produits et l'assistance du marché secondaire de l'automobile avec un portefeuille diversifié de pièces, de solutions de diagnostic et de services d'atelier. L'apprentissage et le développement sont des clés sûres du succès et cette philosophie est soutenue par un large éventail de formations, dans tous les domaines de la technologie automobile, des systèmes de véhicules et de l'expertise en diagnostic.",
	SearchField_NoRecordsFound: "Aucun résultat ne correspond à votre recherche",
	TrainingCatalogue_FreeTextSearch: "Texte libre",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Chercher",
	CheckoutLayout_WholeSalerGroup: "Choisissez votre distributeur",
	CreditInsufficientModal_Message: "Vous ne disposez pas d'un crédit suffisant pour utiliser ce mode de paiement. Veuillez réessayer avec un autre moyen de paiement.",
	ShoppingCart_Required_Credits: "Prix ​​total",
	MaintainingInfo_Message1: "Le site est en maintenance.",
	MaintainingInfo_Message2: "Nous serons de retour bientôt.",
	CountryValidationModal_Message: "Vous n'avez pas pu vous connecter à [CountryA] car votre compte a été créé dans [CountryB].",
	CountryValidationModal_ButtonText: "Continuer vers [Country]",
	EventTable_LoginRequired: "Connectez-vous pour vous inscrire",
	TrainingPartTableLayout_Date: "Date",
	TrainingPartTableLayout_StartTime: "Heure de début",
	TrainingPartTableLayout_EndTime: "Heure de fin",
	TrainingPartTableLayout_Duration: "Durées (h)",
	TrainingPartTableLayout_TrainingMethod: "Méthode de formation",
	MyCoursesTableContents_ActiveHeader: "Cours actifs",
	MyCoursesTableContents_ActiveTabHeader: "Actif",
	MyCoursesTableContents_ScheduledHeader: "Formations programmées",
	MyCoursesTableContents_ScheduledTabHeader: "Programmé",
	MyCoursesTableContents_OnlineHeader: "Formations en ligne",
	MyCoursesTableContents_OnlineTabHeader: "En ligne",
	MyCoursesTableContents_CancelledHeader: "Formations annulées",
	MyCoursesTableContents_CancelledTabHeader: "Annulé",
	OnlineCoursesTable_Title_Progress: "Progression",
	ScheduledCoursesTable_Title_Date: "Date [Jours]",
	CoursesTable_Title_Completed: "Complété",
	Footer_TrainingProgram: "Programmes de formation",
	Breadcrumb_TrainingProgram: "Programmes de formation",
	TrainingProgram_Title_Table: "Titre",
	TrainingProgram_TrainingProgram: "Programmes de formation",
	TrainingProgram_TargetGroup: "Groupe cible",
	TrainingProgram_MaxLength: "Longueur maximale",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Détails",
	TrainingProgram_Modules: "Modules",
	TrainingProgram_Course: "Formations",
	TrainingProgram_PartNumber: "Numéro d'article",
	TrainingProgram_Max_Length: "Longueur maximale",
	TrainingProgram_Duration: "Durée",
	TrainingProgram_Exam: "Examen",
	TrainingProgram_Assign_Popup: "Inscrivez-vous au programme de formation",
	TrainingProgram_Student: "Participant",
	TrainingProgram_Assign: "Attribuer",
	TrainingProgram_Popup_Remove_Title: "Annulation de la formation ?",
	TrainingProgram_Description: "Description",
	TrainingProgram_Days: "Jours",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Action",
	TrainingProgram_Enrolled: "a déjà été inscrit à ce programme de formation",
	TrainingProgram_SelfAssign_Enrolled: "Vous êtes déjà inscrit à ce programme de formation",
	TrainingProgram_Warning: "Veuillez inscrire un autre participant ou sélectionner un programme de formation différent.",
	TrainingProgram_Workshop: "Atelier",
	TrainingProgram_Durations: "années",
	TrainingProgram_Duration_DaysLabel: "jours",
	TrainingProgram_HasExam_Yes: "Oui",
	TrainingProgram_HasExam_No: "Non",
	AssignModal_SuccessModal_Header: "Félicitations !",
	AssignModal_SuccessModal_Message: "a été inscrit à ce programme de formation.",
	AssignModal_SelfAssign_SuccessModal_Message: "Vous avez été affecté avec succès au programme de formation.",
	Dashboard_MyTraningProgram: "Mon programme de formation",
	Dashboard_MyTraningProgram_Title: "Titre",
	Dashboard_MyTraningProgram_Status: "Statut",
	Dashboard_MyTraningProgram_Table_View: "Voir",
	Dashboard_MyTraningProgram_Register: "S'inscrire",
	Dashboard_MyTraningProgram_Records: "Historique",
	Dashboard_MyTrainingProgram_In_Progress: "En cours",
	Dashboard_MyTrainingProgram_Accredited: "Accrédité",
	Dashboard_Program_Modal_Withdraw: "Se désister",
	Dashboard_Program_Modal_Grade: "Note",
	Dashboard_MyTrainingProgram_Registered: "Inscrit",
	Dashboard_MyTrainingProgram_Completedd: "Completé",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Oui",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Non",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Veuillez noter que le retrait signifie que vous vous retirerez du programme de formation complet, et pas seulement d'un cours de formation individuel.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Souhaitez-vous toujours vous retirer du programme de formation?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "En attente",
	WithdrawProgramModal_Withdraw_Success_Message: "Vous avez réussi à vous retirer du programme de formation !",
	Common_Warning_Title: "Avertissement!",
	Common_Error_Title: "Oups, quelque chose s'est mal passé !",
	AddEventFromCatalogToCartModal_AddToCartButton: "Ajouter au panier",
	AddEventFromCatalogToCartModal_HoursText: "heures",
	AddElearningToCartSuccessModal_Message: "a été ajouté à votre panier !",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Le participant sélectionné est inscrit à ce cours, ou le cours a déjà été ajouté au panier.",
	EventDetailTab_SubjectForChange_Message: "Objet du changement",
	Dashboard_MyTrainingProgram_Status_Failed: "Echoué",
	Dashboard_MyTrainingProgram_Status_Passed: "Passé",
	Dashboard_MyTrainingProgram_Status_Merit: "Mérite",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinction",
	Dashboard_Employee_Courses: "Formation",
	Dashboard_Employee_Program: "Programme de formation",
	Dashboard_Employee_WaitList: "Liste d'attente",
	Dashboard_Employee_Account: "Compte",
	Course_Price_Free: "Libre",
	Dashboard_Employee_Account_Update_Success_Message: "Vous avez mis à jour avec succès vos informations d'employé !",
	Dashboard_Supplier: "Le fournisseur",
	Event_Detail_Comment: "Il n'y a actuellement aucun message",
	DownLoad_PDF: "Téléchargement PDF",
	TrainingCatalogue_Currency: "Devise",
	My_Wallet: "Mon portefeuille",
	Wallet: "Portefeuille",
	Credits_Balance: "Solde",
	MyFullCalendar_Today: "Aujourd'hui",
	Header_Available_Languages: "Langues disponibles",
	Header_CurrentRegion: "Vous vous trouvez actuellement dans",
	Header_ChangeRegions: "Voulez-vous modifier le pays ?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Se connecter/S'enregistrer",
	Header_RegisterCourse: "S'inscrire à une formation",
	Homepage_Start: "Commencer",
	Homepage_Login: "Connexion",
	MainPage_Title: "La solution idéale pour l'atelier",
	MainPage_Content: "Votre atelier, votre choix",
	MainPage_ImageTitle: "Bosch Automotive Training Solutions",
	MainPage_IntroContent: "Permet à l'atelier moderne de travailler de manière professionnelle, fiable et efficace dans le cadre des procédures de diagnostic, de dépannage, de réparation et d'entretien de tous les types de véhicules.",
	BoschNews_TrainingNews: "Nouvelles",
	Service_Assist_Workshop: "Améliorez votre expérience avec Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Que puis-je faire avec l'application ?",
	Enhance_Body_CDM3_Item_1: "Inscrivez-vous et maintenez votre trajet d'apprentissage",
	Enhance_Body_CDM3_Item_2: "Télécharger le matériel de formation et les attestations de participation",
	Enhance_Body_CDM3_Item_3: "Lancer les Elearning",
	Enhance_Body_CDM3_Item_4: "Accéder à divers services automobiles à partir d'une application mobile",
	Enhance_Body_CDM3_Item_5: "Transmission vidéo en direct avec Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Outil gratuit pour l'analyse automatique des véhicules",
	Training_Stories: "Histoires de formation",
	SettingsMyAccount_JoinCompanyButton: "Rejoindre l'entreprise",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Rejoindre l'entreprise",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Veuillez saisir votre code d'activation ci-dessous",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Attention! Vous devez contacter l'administration de votre entreprise pour obtenir le code d'activation",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Votre activation a été traitée avec succès et votre compte est désormais lié à votre entreprise.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Veuillez cliquer sur Continuer pour accèder au site web",
	ContinueButton: "Continuer",
	WelcomeBatsModal_Title: "Votre compte est actuellement en révision",
	WelcomeBatsModal_ActivationLink: "Cliquez ici si vous avez un code d'activation pour rejoindre l'entreprise !",
	WelcomeBatsModal_ActivationLinkNotes: "Attention! Vous devez contacter l'administration de votre entreprise pour obtenir le code d'activation",
	AccountInReviewModal_Description_Paragraph1: "Merci d'avoir créé un compte personnel sur Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Notre équipe spécialisée est en train d'examiner et de configurer votre compte. Vous recevrez une confirmation finale par mail, une fois que votre compte aura été configuré avec succès.",
	AccountInReviewModal_CloseButton: "OK, laissez-moi entrer!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nos n'avons pas pu valider votre code d'activation et votre adresse mail",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Veuillez réessayer ou contacter l'administration de votre entreprise pour obtenir de l'aide",
	TryAgain_Button: "Réessayer",
	Error_Title: "Oups, il y a eu un problème!",
	SettingsMyProfileActivationSuccessModal_Title: "Bonne chance!",
	AccountInReviewModal_Description_Paragraph3: "Entretemps n'hésitez pas à découvrir notre site avec un accès limité",
	AccountInReviewModal_Description_Paragraph4: "Si vous avez des questions, ou si votre compte n'est pas traité dans les 48 heures hors week-ends et jours fériés, vous pouvez nous contacter [ici].",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Quelques conseils pour vous aider …",
	Support_FAQ_Headline: "FAQ",
	Support_FAQs_section1_title: "En génerale",
	Support_FAQs_section1_question1: "Qu'est que Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions est une plateforme qui fournit une vue d'ensemble des formations techniques et permet de réserver et de gérer les formations.",
	Support_FAQs_section1_question2: "Puis-je utiliser mon compte Bosch existant (singleKey ID) pour me connecter à Bosch Training Solutions ?",
	Support_FAQs_section1_question2_answer1: "Oui, il est accessible avec tous les comptes créés avec \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Puis-je utiliser un compte créé pour Bosch Training Solutions avec d'autres services ou applications Bosch ?",
	Support_FAQs_section1_question3_answer1: "Oui, vous pouvez également utiliser votre identifiant Bosch personnel pour toute une série d'autres applications Bosch - à la fois professionnelles et privées, telles que le vélo électrique ou la maison intelligente.",
	Support_FAQs_section2_title: "Formation",
	Support_FAQs_section2_question1: "Comment puis-je réserver une formation?",
	Support_FAQs_section2_question1_answer1: "Connectez-vous avec votre compte existant OU créez un compte sur le portail. Allez dans  \"Training Services\" OU \"Events Calendar\" , sélectionnez une formation et cliquez sur le bouton \"Ajouter au panier\".",
	Support_FAQs_section2_question2: "Aucune date de formation n'est disponible. Que puis-je faire?",
	Support_FAQs_section2_question2_answer1: "Vous avez la possibilité de vous inscrire sur une liste d'attente \"general\" et notre équipe vous informera dès qu'une nouvelle date sera disponible.",
	Support_FAQs_section2_question3: "Ou puis-je trouver un aperçu des formations que j'ai réservées?",
	Support_FAQs_section2_question3_answer1: "Vous trouverez un aperçu complet des formations réservées et suivies après vous être connecté à votre tableau de bord",
	Support_FAQs_section3_title: "Paramètres",
	Support_FAQs_section3_question1: "Comment puis-je modifier mon adresse mail & mon mot de passe?",
	Support_FAQs_section3_question1_answer1: "Vous devez vous connecter au portail et accéder à votre profil personnel. (Vous trouverez les informations relatives à votre profil dans votre tableau de bord ou si vous cliquez sur le bouton suivant dans l'en-tête)",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Vous avez la possibilité de modifier les détails de votre identifiant Bosch ici.",
	Support_FAQs_section3_question2: "Que puis-je faire lorsque j'ai oublié mon mot de passe?",
	Support_FAQs_section3_question2_answer1: "Veuillez utiliser le bouton de connexion/enregistrement et indiquer votre adresse mail.",
	Support_FAQs_section3_question2_answer2: "Si vous cliquez sur continuer, vous avez la possibilité de cliquer sur \"Forgot Password\"",
	Support_FAQs_section3_question3: "Comment créer un compte atelier?",
	Support_FAQs_section3_question3_answer1: "Si vous souhaitez obtenir un rôle atelier, veuillez contacter votre administration locale des formations, par exemple en utilisant le formulaire de contact sur le site web.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Choisissez votre pays/région",
	MaintenanceBanner_Message_1: "Le site Bosch Automotive Training Solutions sera mis à jour le [maintenance_schedule]. La mise à jour devrait durer 30 minutes. Le site ne sera pas accèssible durant la mise à jour.",
	MaintenanceBanner_Message_2: "\nPour toute assistance supplémentaire, veuillez nous contacter par mail [support_email].",
	MaintenancePage_Message_1: "Le site Bosch Automotive Training Solutions est en cours de mise à jour",
	MaintenancePage_Message_2: "Nous prévoyons que le site sera à nouveau accessible le [maintenance_online_date] à [maintenance_online_time]",
	MenuHeader_Support: "Support",
	Course_Detail_No_Prerequisites: "Aucune conditions préalables",
	TableCell_Hours: "Horaire",
	Dashboard_MenuItem_Dashboard: "Tableau de bord",
	Dashboard_MenuItem_Curricula: "Curriculum",
	Dashboard_MenuItem_Certificates: "Attestations",
	Dashboard_MenuItem_Team: "Equipe",
	Dashboard_MenuItem_Workshops: "Ateliers",
	Dashboard_MenuItem_External: "Externe",
	Dashboard_MenuItem_Wallet: "Portefeuille",
	Dashboard_MenuItem_Profile: "Profile",
	Dashboard_Headline_YourUpcomingCourses: "Vos formations à venir",
	Dashboard_Headline_YourELearningCourses: "Vos Elearning",
	Dashboard_Headline_TeamOverview: "Aperçu d'équipe",
	Dashboard_Headline_YourOverview: "Votre aperçu",
	Dashboard_Headline_YourWishlist: "Votre liste de souhaits",
	Dashboard_Headline_ActivePrograms: "Programmes actifs",
	Dashboard_Headline_CompletedPrograms: "Programmes complètés",
	Dashboard_Headline_YourCertificates: "Vos attestations",
	Dashboard_Headline_Transactions: "Transactions",
	Dashboard_Table_NoRecordsToDisplay: "Pas de données à afficher",
	Dashboard_TableColumn_Location_Online: "En ligne",
	Dashboard_TableColumn_Duration_Hours: "Horaire",
	Dashboard_ELearningCourses_TableHeader_Title: "Titre",
	Dashboard_ELearningCourses_TableHeader_Duration: "Durée (heures)",
	Dashboard_ELearningCourses_ActionButton_View: "Voir",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titre",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Date (jours)",
	Dashboard_UpcomingCourses_TableHeader_Location: "Localisation",
	Dashboard_UpcomingCourses_ActionButton_View: "Voir",
	Dashboard_YourOverview_TableHeader_Title: "Titre",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Date / Durée",
	Dashboard_YourOverview_TableHeader_Location: "Localisation",
	Dashboard_YourOverview_TableHeader_Status: "Statut",
	Dashboard_YourOverview_ActionButton_View: "Voir",
	Dashboard_YourWishlist_TableHeader_Title: "Titre",
	Dashboard_YourWishlist_TableHeader_Action: "Action",
	Dashboard_YourWishlist_ViewAllRecords: "Voir toutes les données",
	Dashboard_TeamOverview_TableHeader_Employee: "Employé",
	Dashboard_TeamOverview_TableHeader_Title: "Titre",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Date / Durée",
	Dashboard_TeamOverview_TableHeader_Location: "Localisation",
	Dashboard_TeamOverview_TableHeader_Status: "Statut",
	Dashboard_TeamOverview_ActionButton_View: "Voir",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Chercher",
	Dashboard_ActiveProgram_TableHeader_Title: "Titre",
	Dashboard_ActiveProgram_TableHeader_Status: "Statut",
	Dashboard_ActiveProgram_ActionButton_View: "Voir",
	Dashboard_CompletedProgram_TableHeader_Title: "Titre",
	Dashboard_CompletedProgram_TableHeader_Status: "Statut",
	Dashboard_CompletedProgram_ActionButton_View: "Voir",
	Dashboard_Certificates_TableHeader_Title: "Titre",
	Dashboard_Certificates_TableHeader_Date: "Date",
	Dashboard_Certificates_TableHeader_Status: "Statut",
	Dashboard_Certificates_TableHeader_Action: "Action",
	Dashboard_Certificates_ActionButton_Download: "Télécharger",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Référence facture",
	Dashboard_Transactions_TableHeader_Description: "Description",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Méthode de paiement",
	Dashboard_Transactions_TableHeader_Date: "Date",
	Dashboard_Transactions_TableHeader_Amount: "Montant",
	Dashboard_Transactions_ActionButton_Download: "Télécharger",
	Breadcrumb_MyTraining: "MaFormation",
	Breadcrumb_MyTraining_CourseDetails: "Détails de la formation",
	Breadcrumb_MyTraining_Curricula: "Cirriculum",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Détails du programme de formation",
	Breadcrumb_MyTraining_Team: "Equipe",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Aperçu des employés",
	MyTraining_Headerbar_CourseDetails: "Détails de la formation",
	MyTraining_Headerbar_TrainingProgramDetails: "Détails du programme de formation",
	TrainingProgramDetails_Label_Status: "Statut",
	TrainingProgramDetails_Label_OverallProgress: "Progrès globaux",
	TrainingProgramDetails_ProgramItems_Headline: "Eléments du programme",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titre",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Statut",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Score :",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Date",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Aperçu",
	TrainingProgramDetails_Status_InProgress: "En cours",
	TrainingProgramDetails_Status_Completed: "Complèté",
	CourseDetails_Label_Status: "Statut",
	CourseDetails_Label_Duration: "Durée",
	CourseDetails_Label_Trainer: "Formateur",
	CourseDetails_Label_Language: "Langue",
	CourseDetails_Label_Score: "Score :",
	CourseDetails_Label_StartDate: "Date de début",
	CourseDetails_Label_EndDate: "Date de fin",
	CourseDetails_Label_Venue: "Lieu",
	CourseDetails_Label_Address: "Adresse",
	CourseDetails_Label_Grade: "Score :",
	CourseDetails_Action_Withdraw: "Se désinscrire",
	CourseDetails_Action_Evaluate: "Evaluez la formation",
	CourseDetails_Action_Evaluate_Description: "Veuillez évaluer la formation afin d'obtenir votre attestation!",
	CourseDetails_Action_DownloadCertificate: "Télécharger l'attestation",
	CourseDetails_Action_JoinWebcast: "Rejoindre le webcast",
	CourseDetails_Action_StartCourse: "Démarrer la formation",
	CourseDetails_Action_RestartCourse: "Redemarrer la formation",
	CourseDetails_Action_RepeatCourse_Description: "Veuillez refaire la formation et réussir pour télécharger votre attestation!",
	CourseDetails_PartSpecificDetails_Headline: "Détails spécifiques de la partie",
	CourseDetails_PartSpecificDetails_Tab_Part: "Partie",
	CourseDetails_PartSpecificDetails_Description: "Description",
	CourseDetails_PartSpecificDetails_StartDate: "Date de début",
	CourseDetails_PartSpecificDetails_EndDate: "Date de fin",
	CourseDetails_PartSpecificDetails_StartTime: "Heure de début",
	CourseDetails_PartSpecificDetails_EndTime: "Heure de fin",
	CourseDetails_PartSpecificDetails_Method: "Méthode",
	Dashboard_Team_TableHeader_Name: "Nom",
	Dashboard_Team_TableHeader_Role: "Rôle",
	Dashboard_Team_TableHeader_Email: "Mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Compte personnel",
	Dashboard_Team_ActionButton_Select: "Selectionner",
	Dashboard_Team_PersonalAccount_Activated: "Activé",
	Dashboard_Team_PersonalAccount_NotActivated: "Non activé",
	Dashboard_Team_ActionButton_AddMember: "Ajouter un membre",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Chercher",
	Dashboard_External_TableHeader_Name: "Nom",
	Dashboard_External_ActionButton_AddNew: "Ajouter un nouveau",
	Dashboard_External_Headline: "Accès externe approuvé",
	Dashboard_Workshops_Headline: "Ateliers",
	Dashboard_Workshops_TableHeader_Company: "Entreprise",
	Dashboard_Workshops_TableHeader_Address: "Adresse",
	Dashboard_Workshops_TableHeader_Postcode: "Code Postal",
	Dashboard_Workshops_TableHeader_City: "Ville",
	Dashboard_Workshops_ActionButton_Select: "Sélectionner",
	Wallet_ServiceCredits: "Crédits de service",
	Wallet_Headline_CreditBalance: "Solde",
	Wallet_Headline_CreditActivity: "Activité",
	Wallet_Headline_BillingTransactions: "Opérations de facturations",
	Wallet_BillingTransaction_TableHeader_Order: "Commande",
	Wallet_BillingTransaction_TableHeader_Date: "Date",
	Wallet_BillingTransaction_TableHeader_Amount: "Montant",
	Wallet_BillingTransaction_TableHeader_Action: "Action",
	Wallet_CreditActivity_TableHeader_Date: "Date",
	Wallet_CreditActivity_TableHeader_Description: "Description",
	Wallet_CreditActivity_TableHeader_User: "Utilisateur",
	Wallet_CreditActivity_TableHeader_Amount: "Montant",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, tous droits réservés",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Sécurité des produits (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovations, brevets et licences",
	FooterAdditionalLinks_Navigation_Logistics: "Achats et logistiques",
	MenuHeader_MyTraining: "Ma formation",
	MenuHeader_TrainingServices: "Catalogue des formations",
	MenuHeader_OurLocations: "Nos sites",
	MenuHeader_ContactsUs: "Contactez-nous",
	MenuHeader_Logout: "Deconnexion",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Mon identifiant Bosch",
	MyProfile_AccountDeletion: "Suppression de compte",
	MyProfile_Edit: "Modifier l'adresse mail ou editer le mot de passe",
	MyProfile_Profile: "MonBosch",
	MyProfile_EditUserProfile: "Modifier le profil d'utilisateur",
	BackButton: "Retour",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Nous attendons votre appel et serons heureux de vous aider. Nous sommes à votre disposition 24 heures sur 24, 7 jours sur 7.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Saisissez les caractères corrects, comme indiqué dans l'encadré ci-dessus.",
	ContactUs_Email_MandatoryNotice_V3: "Remplissez les champs obligatoires",
	ContactUs_Email_SendButton: "Envoyer",
	Breadcrumb_TrainingCenters: "Centres de formation",
	Training_Centres_Book_Btn: "Réserver une formation",
	Training_centres_booking_code: "Code de réservation",
	Training_centers_header_bar_title: "Nos centres de formation",
	Training_centers_filter_item: "Centre",
	TeamEdit_PersonalAccountToolTop: "L'activation de cette fonction permet à l'employé d'accéder à ce site web. L'utilisateur pourra créer un compte personnel et faire partie de votre compte d'entreprise. Une fois la fonction activée, vous pouvez envoyer à l'employé une invitation contenant un code d'activation. L'invitation sera envoyée à l'adresse mail.",
	TeamEdit_Update: "L'activation de la fonction d'administrateur d'entreprise donne automatiquement à cet employé un accès complet à votre compte d'entreprise. L'activation de cette fonction nécessite que l'utilisateur se déconnecte et se reconnecte au site web, s'il est déjà connecté.",
	TeamEdit_PersonalAccount: "Permettre l'ouverture d'un compte personnel sur le site web de Bosch Training Solutions",
	TeamEdit_SendInvitation: "Envoyer une invitation",
	TeamEdit_Deactivate: "Désactiver",
	TeamEdit_Telephone: "Téléphone",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Cela doit être des numéros pour le portable",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Cela doit être des numéros pour le téléphone",
	TeamEdit_Activate: "Actif",
	TeamEdit_NotActivated: "Non actif",
	TeamEdit_StatusPopup: "Le statut sera \"Actif\" si l'utilisateur a créé un compte personnel avec succès. Si le statut est \"Non actif\", cela signifie que l'utilisateur n'a pas répondu à votre invitation.",
	Training_Catalog_Courses: "Formations",
	Training_Catalog_Course: "Formation",
	Reset_Filter: "Réinitialiser le filtre",
	TrainingCatalogue_CourseCatalogue: "Catalogue des formations",
	CheckoutHeader_CartItemCount_Text: "Vous avez [count] articles dans votre panier",
	CheckoutHeader_CartItemsCount_Text: "Vous avez [count] articles dans votre panier",
	ShoppingCart_PriceItem: "Prix",
	ShoppingCartItemParticipants_AddParticipant_Button: "Ajouter des participants",
	ShoppingCartTotalPrice_SubTotalLabel: "Soustotal",
	ShoppingCartTotalPrice_TaxesLabel: "Taxes",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Inclut la TVA de [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Mode de paiements en ligne disponibles",
	ShoppingCartItemRow_OnlineLabel: "En ligne",
	AddParticipantsModal_Title: "Ajouter un participant",
	AddParticipantsModal_SelectEmployeeLabel: "Ajouter un participant",
	AddParticipantsModal_SelectWorkshopLabel: "Selectionner un atelier",
	ShoppingCartTotalPrice_ContinueButtonText: "Paiement",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Le participant n'a pas été ajouté!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Vous avez une ou plusieurs formations sans participant dans votre panier",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Veuillez ajouter un participant afin de poursuivre le paiement",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Modifier les participants ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nom",
	AddParticipantsModal_ParticipantTable_Email: "Mail",
	AddParticipantsModal_ParticipantTable_Action: "Action",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Pas de participants …",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Pas d'adrese mail enregistrée pour le participant sélectionné",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Le participant sélectionné n'a pas d'adresse mail configurée dans son profil. L'adresse mail est obligatoire pour s'inscrire à une formation.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Ajouter une adresse mail pour continuer ou annuler pour sélectionner un autre participant.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Ajouter une adresse mail",
	CancelButton: "Annuler",
	AddParticipantsModal_AddEmployeeButtonText: "Ajouter un employé",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Vous êtes sur le point d'effacer une formation dans votre panier",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Vous essayez de supprimer de votre panier une formation pour laquelle des participants ont été ajoutés",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Cliquez sur \"Continue\" si vous souhaitez poursuivre ou cliquez sur \"Cancel\".",
	ShoppingCartItemHeader_Participants: "Participant(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Prix unitaire",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Afficher en [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Afficher en crédits",
	ShoppingCart_CreditsPriceUnit: "Crédits",
	CheckOutHeader_Step1_Question: "Comment souhaitez-vous payer?",
	CheckOutHeader_Step2_Question: "Vérifier votre commande",
	CheckOutHeader_Step3_Question: "Completer votre commande",
	CheckOutHeader_Step4_Question: "Votre inscription a été effectuée!",
	CheckOutHeader_Step_Payment: "Paiement",
	CheckOutHeader_Step_Review: "Vérifier",
	CheckOutHeader_Step_Place_Order: "Placer une commande",
	CheckOutRightPanel_Review_Order_Btn: "Vérifier la commande",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Placer une commande",
	CheckoutRightPanelContent_OrderSummary_Title: "Résumé de la commande",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Modifier le panier",
	ShoppingCart_Participants: "Participants",
	CheckOutStepPayment_Payment_Methods_Title: "Sélectionner un mode de paiement",
	CheckOutStepPayment_Credit_Title: "Crédit ou débit",
	CheckOutStepPayment_Wholesaler_Title: "Distributeur",
	CheckOutStepPayment_ServiceCredit_Title: "Crédits de service",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Vous avez [available_credit] credits disponibles",
	CheckOutStepPayment_BoschAccount_Title: "Compte Bosch",
	Checkout_Second_PaymentMethod_Headline: "Mode de paiement",
	CheckOutStepReview_Edit: "Modifier",
	CheckOutStepReview_Terms_Label: "Conditions générales et politique d'annulation",
	CheckOutStepReview_Terms_Text: "J'ai lu, compris et accepté les [conditions et modalités d'annulation] de la vente.",
	SelectSecondPaymentMethodModal_Title: "Sélectionner le mode de paiment secondaire",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Payer [amount]",
	StepPaymentWholesaler_Title: "Sélectionner le distributeru",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Accepter les conditions générales",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Veuillez lire et accepter nos conditions générales et notre politique d'annulation avant de continuer.",
	CheckoutDescription_ThankYou: "Merci d'avoir passé une commande sur Bosch Automotive Training Solutions !",
	CheckoutDescription_Message: "Notre équipe de bits et d'octets a déjà commencé à traiter votre commande. En attendant, veuillez laisser tourner votre moteur au ralenti.",
	CheckoutDesctiption_Text: "Nous vous enverrons une confirmation finale par mail comprenant tous les détails de votre commande.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Vous êtes sur le point de supprimer l'atelier sélectionné",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "En supprimant l'atelier sélectionné, tous les participants ajoutés seront réinitialisés. Cette modification affectera toutes les formations figurant dans votre panier d'achat.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Cliquez sur \"Continue\" si vous souhaitez poursuivre ou sur \"Cancel\" pour interrompre cette action.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Vous n'avez pas assez de crédits. Votre solde de crédits est de [available_credit] crédits, mais vous avez besoin de [cart_total_credits]",
	Training_centers_filter_items: "Centres",
	Training_Programs_Filter_Item: "Programmes",
	Training_Program_Filter_Item: "Programme",
	Training_Program_Year: "Année(s)",
	Training_Program_Detail_Title: "Programmes de formation",
	AccountButton_Workshops_MenuItem: "Ateliers",
	AccountButton_Wallet_MenuItem: "Portefeuille",
	Header_MyBoschProfile_Header: "Mon profile Bosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponible",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Ajouter/editer participant",
	ShoppingCart_CreditPriceUnit: "Crédit",
	Common_IncludedVAT: "TVA incluse",
	TrainingCatalogue_Length: "Durée",
	TrainingCatalogue_Method: "Méthode",
	Course_Price_Credits: "Crédits",
	Catalogue_Grid_Register_Button: "S'inscrire",
	Catalogue_Credit_Tilte: "Cliquez ici pour voir le prix en crédits",
	Catalogue_Price_Tilte: "Cliquez ic pour voir le prix en EUR",
	Catalogue_Grid_Vat: "TVA incluse",
	EventCalendar_Seats: "Places disponibles",
	MenuHeader_Calendar: "Calendrier",
	MenuHeader_Catalog: "Catalogue",
	TableCell_Minutes: "Minutes",
	ListFilter_NoResult: "Pas de résultat",
	Course_Description: "Description de la formation",
	Training_course_detail: "Contenu de la formation",
	Training_course_detail_add_waiting: "S'inscrire sur la liste d'attente",
	Register_now_btn: "S'inscrire maintenant !",
	Register_now_btn_add_event_to_cart: "S'inscrire maintenant !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Aperçu générale",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Moins",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Aperçu générale",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Moins",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Aperçu générale",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Moins",
	TeamCreate_TitleError: "Titre requit",
	Dashboard_Headerbar_BookCourse: "Réserver une formation",
	Dashboard_Headerbar_ExitProxy: "Exit proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluer la formation",
	Dashboard_Headerbar_EvaluateCourse_Note: "Veuillez évaluer la formation afin d'obtenir votre attestation de participation!",
	Dashboard_Headerbar_DownloadCertificate: "Télécharger l'attestation",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Se désister",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Se désister",
	Dashboard_Headerbar_WelcomeMessage: "Bienvenue sur votre tableau de bord des formations",
	Dashboard_Headerbar_ProxingAs: "Procuration en tant que",
	Dashboard_TrainingProgram_Status_Completed: "Achevé",
	Dashboard_TrainingProgram_Status_InProgress: "En cours",
	External_SelectWholesalerModal_Title: "Ajout d'un accès utilisateur externe",
	External_SelectWholesalerModal_Company_Label: "Société",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Veuillez sélectionner",
	External_SelectWholesalerModal_Outlet_Placeholder: "Veuillez sélectionner",
	External_SelectWholesalerModal_ApplyButton: "Appliquer",
	External_SelectWholesalerModal_CancelButton: "Annuler",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmation",
	External_SelectWholesalerModal_ConfirmMessage: "Etes-vous certain de vouloir retirer ce distributeur?",
	External_SelectWholesalerModal_YesButton: "Oui",
	External_SelectWholesalerModal_NoButton: "Non",
	Training_program_team_member: "Membre d'équipe",
	Training_program_assign_training_program: "Assigner un programme de formation",
	Assign_modal_student_confirmation_text1: "Vous êtes sur le point d'affecter [Training Program Name] à votre parcours de formation.",
	Assign_modal_student_confirmation_text2: "Voulez vous continuer?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Fev",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Avr",
	MyFullCalendar_May: "Mail",
	MyFullCalendar_Jun: "Juin",
	MyFullCalendar_Jul: "Jui",
	MyFullCalendar_Aug: "Août",
	MyFullCalendar_Sep: "Sept",
	MyFullCalendar_Oct: "Oct",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Catalogue des formations",
	MenuHeader_Event: "Calendrier des formations",
	MenuHeader_Program: "Programme de formation",
	MenuHeader_Services: "Services",
	MenuHeader_Log_out: "Deconnexion",
	Filter_Location: "Lieu",
	TeamEdit_UpgradeToCompanyAdministrator: "Passage au statut d'administrateur d'entreprise",
	TeamEdit_PersonalWebAccountStatusIs: "Statut du compte web personnel :",
	Header_CreateNewEmployee: "Ajouter un nouvel employé",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Pas de places disponibles",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Il n'y a plus de place disponible pour cette session.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Veuillez sélectionner une autre session ou annuler l'inscription d'un participant déjà inscrit pour continuer",
	Common_SuccessModal_Title: "Félicitations !",
	EmployeeProfile_UserExistErrorMessage: "Ce compte existe déjà!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Le paiement a échoué. Veuillez réessayer ou utiliser un autre mode de paiement.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Désactivation de l'employé sélectionnée réussie!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Sauvegardé!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Vous changements ont été enregistrés.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Il est impossible de se désincrire d'un eLearning. Veuillez nous contacter pour plus de détails.",
	Dashboard_Team_PersonalAccount_Pending: "En attente",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Employé ajouté!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "La création d'un nouveau compte employé a été enregistré avec succes",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Ce paramètre vous permet d'empêcher un employé de s'inscrire à des formations. Seuls les administrateurs pourront inscrire l'employé à des formations lorsque ce paramètre sera décoché.",
	Training_catalog_sort_by: "Trier par",
	CourseDetail_EvaluationRestriction_Title: "Évaluation de la formation impossible !",
	CourseDetail_EvaluationRestriction_Message: "Les évaluations de formation ne peuvent être soumises que par l'étudiant lui-même. \nVeuillez demander à l'élève de se connecter à Bosch Automotive Training Solutions et de terminer l'évaluation de la formation.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Si l'étudiant n'a pas encore de compte, une invitation peut être partagée dans les paramètres du profil utilisateur du membre de l'équipe.",
	Breadcrumb_Download_Page: "Télécharger",
	Download_Table_Category_Cell: "Catégorie",
	Download_Table_Title_Cell: "Titre",
	Download_File_Filter_Item: "Fichier",
	Download_File_Filter_Items: "Fichiers",
	Download_Header: "Zone de téléchargement",
	DownloadArea_Button_Download: "Télécharger",
	Popup_Title_Error: "Erreur",
	Popup_Title_Success: "Réussite",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Attention!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Les inscriptions à des événements potentiels déjà existants ne seront pas annulées et devront être annulées individuellement.",
	TrainingProgram_PDF_Export: "Exporter en PDF",
	TrainingProgram_PDF_Export_Participant: "Participant:",
	TrainingProgram_PDF_Export_Status: "Statut :",
	TrainingProgram_PDF_Export_Progress: "Progès global :",
	TrainingProgram_PDF_Export_TableHeader_Title: "Intitulé de la formation",
	TrainingProgram_PDF_Export_TableHeader_Status: "Statut",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Niveau",
	TrainingProgram_PDF_Export_TableHeader_Date: "Date",
	AccountButton_CompanyChange_MenuItem: "Changement d'entreprise",
	SelectCompanyModal_Title: "Sélectionner une entreprise",
	CloseButton: "Fermer",
	Change_Button: "Changer",
	ChangeManagerModal_Content: "Sélectionner un utilisateur",
	ChangeCompanyModal_HeadOffice: "(Maison mère)",
	SelectCompanyModal_Content: "Veulliez sélectionner une société pour vous connecter…",
	AlertSuccess_Title: "Réussite",
	CheckOutStepPayment_SecondaryPayment_Title: "Selectionner un mode de paiement secondaire",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Ajouter un mode de paiement secondaire",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Veuillez ajouter un mode de paiement secondaire ci-dessous ou sélectionner un autre mode de paiement ci-dessus.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Vous n'avez pas assez de crédits de service sur votre compte pour payer les articles de votre panier. Pour continuer à utiliser le mode de paiement sélectionné, vous devez payer la différence. La différence est de [difference_amount] (hors TVA).",
	ShoppingCartTotalPrice_TotalInCredit: "Prix total en crédits",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Vos crédits disponibles",
	ShoppingCartTotalPrice_YourDifference: "Différence",
	ShoppingCartTotalPrice_SubtotalExTax: "Soustotal hors TVA",
	ShoppingCartTotalPrice_SubtotalInTax: "Soustotal TVA comrise",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Mode de paiement secondaire",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Modifier mode de paiement",
	Checkout_Second_PaymentMethod: "Deuxième mode de paiement",
	Breadcrumb_TrainingCourseDetails: "Détails de la formation",
	DownloadArea_FailedToDownload_ErrorMessage: "Le téléchargement du document a échoué. Veuillez réessayer ou contacter l'administrateur de votre entreprise pour obtenir de l'aide.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Le fichier à télécharger n'est pas disponible. Veuillez réessayer ou contacter l'administrateur de votre entreprise pour obtenir de l'aide.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Le téléchargement du fichier est interdit. Veuillez réessayer ou contacter l'administrateur de votre entreprise pour obtenir de l'aide.",
	SSO_Redirecting_Message: "Vous allez être redirigé vers le portail de formation...",
	SSO_Redirecting_ParameterErrorMessage: "Le pays et/ou la langue ne sont pas indiqués. Veuillez réessayer ou contacter l'administrateur de votre entreprise pour obtenir de l'aide.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Demande(s) d'inscription refusée(s).",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Cela peut être dû au fait que la période d'inscription à une ou plusieurs formations n'a pas encore été atteinte. Veuillez utiliser la section \"Contact\" si vous avez des questions ou des demandes.",
	TimeSlotPicker_DateFormat_Title: "Format de date",
	UpdateAccountSettingsConfirmationModal_Title: "Vous êtes sur le point de changer le format de la date !",
	UpdateAccountSettingsConfirmationModal_Message1: "Le format de la date sera affiché selon votre sélection et pourra être modifié à tout moment.",
	UpdateAccountSettingsConfirmationModal_Message2: "Voulez-vous continuer?",
	ProfileSummary_Settings_Title: "Paramètres",
	UpdateAccountSettings_DateFormat_Title: "Paramètres de format de date",
	UpdateAccountSettings_DateFormat_ErrorMessage: "La mise à jour du format de date n'a pas pu aboutir.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "La mise à jour des paramètres de format de date a été effectuée.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Télécharger le certificat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certificat Programme de formation",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Échec du téléchargement du document. Veuillez réessayer ou contacter l'administrateur de votre entreprise pour obtenir de l'aide.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Seuil de retrait dépassé",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Le seuil d'abandon de ce cours a été dépassé et nécessite un traitement manuel par notre administration de la formation.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "La confirmation finale sera envoyée par email.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Veuillez vous référer à notre [politique d'annulation] pour plus de détails.",
	TrainingCourse_Course_NotAvailable_Title: "La formation n'est pas disponible.",
	TrainingCourse_Course_NotAvailable_Message: "La formation n'est pas disponible. Veuillez réessayez ou contactez le responsable des formations de votre entreprise pour de l'aide.",
	TrainingCourse_NotAvailable_Message_1: "Vous accédez à la formation qui n'est pas disponible.",
	TrainingCourse_NotAvailable_Message_2: "Veuillez réessayer ou vérifiez les disponibilités via [training courses]",
	LanguageSelection_SelectALanguage: "Sélectionnez votre langue",
	LanguageSelection_SelectYourCountry: "Sélectionnez votre pays",
	Checkout_PaymentMethod_Headline: "Mode de paiement",
	Download_Categories_Catalogue: "Catalogue",
	Download_Categories_Calendar: "Calendrier",
	Download_Categories_General: "Général",
	Download_Categories_Information: "Information",
	Download_Categories_Userguides: "Guides d'utilisation",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Conditions d'annulation",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Annuler",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Continuer",
	Duration_Seconds: "Secondes",
	Duration_Day: "Jour",
	Duration_Hour: "Heure",
	Duration_Minute: "Minute",
	Duration_Second: "Seconde",
	EventCalendar_ExternalLink_Tooltips: "Achat via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "p.a.",
	EventCalendar_ExternalLink_Cancel: "Annuler",
	EventCalendar_ExternalLink_Continue: "Continuer",
	EventCalendar_ExternalLinkModal_Title: "Vous êtes sur le point d'être redirigé",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "L'événement sélectionné est vendu via [externalRegistrationName]",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "En continuant vous serez rediriger vers un site web externe",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Voulez-vous continuer?",
	Team_ContactPerson_FirstName_Tooltips: "Les noms des employés ne peuvent être modifiés que par l'équipe d'administration des formations Bosch. Veuillez nous contacter via le formulaire de contact de ce site web pour obtenir de l'aide",
	Team_ContactPerson_LastName_Tooltips: "Les noms des employés ne peuvent être modifiés que par l'équipe d'administration des formations Bosch. Veuillez nous contacter via le formulaire de contact de ce site web pour obtenir de l'aide",
	Withdraw_ExternalLink_Cancel: "Annuler",
	Withdraw_ExternalLink_Confirm: "Confirmer",
	Withdraw_ExternalLinkModal_Title: "Vous êtes sur le point d'être redirigé",
	Withdraw_ExternalLinkModal_Message: "Les annulations pour cette formation sont gérées par un prestataire externe",
	Withdraw_ExternalLinkModal_Confirmation: "En cliquant sur \"Confirmer\", vous ouvrirez un nouvel onglet et serez redirigé vers un site web externe",
	Button_AddToWaitingList_Tooltips: "Ajouter à la liste d'attente",
	Button_AddToCart_Tooltips: "Ajouter au panier",
	Button_TrainingCourseDetail_Tooltips: "Voir les détails",
	Button_TrainingProgramDetail_Tooltips: "Voir les détails",
	Button_AssignTrainingProgram_Tooltips: "Attribuer un programme de formation",
	Button_DeleteFromCart_Tooltips: "Effacer du panier",
	Button_RemoveParticipant_Tooltips: "Retirer le participant",
	Button_DownloadBillingTransaction_Tooltips: "Télécharger",
	Button_RemoveExternal_Tooltips: "Retirer"
};