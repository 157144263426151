import { FC } from "react";
import { Grid } from "semantic-ui-react";
import CustomImageCard from "./CustomImageCard";
import { useTranslation } from 'react-i18next';
import BoschServiceTraining from "../../assets/stories/BoschServiceTrainingMain.jpg"
import DayInLifeOfATrainer from "../../assets/stories/Theorietraining_Hochvolt_86275.jpg"
import TheonlineTrainingWorld from "../../assets/stories/Bild_Webcasting_3_85796.jpg"
import BoschTrainingBenfits from "../../assets/stories/EntryLevelPS_Original.jpg"

const ExploreTrainingEqual: FC<any> = (props) => {
    const { t } = useTranslation();
    return (
        <>
        <Grid style={{padding:0}}>
                <CustomImageCard
                    cardStyle="sameSizeStory"                   
                    linkTextBig={{ label: `${t('Stories_ADayinLifeOfATrainer')}`, link: '/stories/a-day-in-the-life-of-a-trainer' }}
                    linkTextSmall={{ label: `${t('Stories_BoschServiceTraining')}`, link: '/stories/bosch-service-training' }}
                    cardImageBig={BoschServiceTraining}
                    cardImageSmall={DayInLifeOfATrainer}
                    miniHeaderBig={t('Stories_BlogATrainersDay')}
                    miniHeaderSmall={t('Stories_StoryWhatDrivesYouDrivesUs')}
                    
                />
                <CustomImageCard
                    cardStyle="sameSizeStory"
                    cardImageBig={BoschTrainingBenfits}
                    linkTextBig={{ label: `${t('Stories_AModernTrainingApproach')}`, link: '/stories/a-modern-training-approach' }}
                    cardImageSmall={TheonlineTrainingWorld}
                    linkTextSmall={{ label: `${t('Footer_HowOurTrainingEvolves')}`, link: '/stories/bosch-training-benefits' }}
                    miniHeaderBig={t('Stories_StoryAVirtualOnlineTrainingWorld')}
                    miniHeaderSmall={t('Stories_StoryHowCanWeBenefitYourBusiness')}
                />
            </Grid>
        </>
    )
}


export default ExploreTrainingEqual;