import { FC, Fragment, useState } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import "./Footer.css";
import FooterAdditionalLinks from "./FooterAdditionalLinks";
import FooterSearch from "./FooterSearch";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/linkedin.svg";
import { ReactComponent as TwitterX } from "../../assets/svg/twitter_x.svg";
import { ReactComponent as ForwardRight } from "../../assets/svg/forwardRight.svg";
import { useTranslation } from "react-i18next";
import FooterCardStories from "./FooterCardStories";
import ExploreTrainingEqual from "./ExploreTrainingEqual";
import { Link, useHistory } from "react-router-dom";
import LabelledLink from "../LabelledLink/LabelledLink";
import { useSelector } from "react-redux";
import { selectCountryCode } from "../../redux-toolkit/authReducer";

const Footer: FC = () => {
  const { t } = useTranslation();
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const country = useSelector(selectCountryCode);
  const history = useHistory();
  history.listen((location, action) => {
    setCurrentUrl(location.pathname);
  });
  // above change so that footer can change properly based on url of page - earlier on travel footer was not updating properly

  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      {!window.location.href.includes("/regions") && (
        <div
          className="grid-responsive-width footer-cards"
          style={{
            paddingTop:
              window.location.pathname === "/stories" ? "1rem" : "4rem",
          }}
        >
          {/* {!(
            window.location.href.includes("/checkout") ||
            window.location.href.includes("/regions") ||
            window.location.href.includes("/privacy-policy") ||
            window.location.href.includes("/stories")
          ) && <Header as="h2" content={t('Footer_TrainingAtYourFingertips')} textAlign="left" className="headline" />} */}

          <Grid
            container
            id="stories-header-wrapper"
            className="our-services-and-get-in-touch-container get-in-touch-margin"
          >
            {/* {!(
              window.location.href.includes("/checkout") ||
              window.location.href.includes("/regions") ||
              window.location.href.includes("/privacy-policy") ||
              window.location.href.includes("/stories")
            ) && <TrainingAtYourFingertips />} */}

            {/* below code lines is for specifying which pages the explore our training component is required */}
            {window.location.pathname === "/stories" && (
              <Grid
                className="stories-headline-wrapper"
                container
                columns={2}
                style={{
                  paddingLeft: 0,
                  marginLeft: 0,
                  display: "flex",
                  paddingRight: 0,
                  alignItems: "center",
                  paddingTop: window.location.pathname === "/stories" ? 0 : "",
                }}
              >
                <Grid.Column
                  className="explore-training-headline"
                  width={16}
                  style={{
                    paddingLeft: 0,
                    marginLeft: 0,
                    paddingTop:
                      window.location.pathname === "/stories" ? 0 : "",
                    paddingBottom:
                      window.location.pathname === "/stories" ? 0 : "",
                  }}
                >
                  <Header
                    as="h2"
                    content={`${t("Footer_ExploreOurTraining")}`}
                    textAlign="left"
                    style={{ padding: 0 }}
                    className="headline"
                  />
                  {!(window.location.pathname === "/stories") && (
                    <LabelledLink
                      to="/stories"
                      icon={<ForwardRight />}
                      content={t("Footer_ExploreTrainingStories")}
                      iconLeft={false}
                    ></LabelledLink>
                  )}
                </Grid.Column>
                {/* <Grid.Column mobile={16} computer={6} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 0 }}>
                {!(window.location.pathname === "/stories") &&
                  <LabelledLink to="/stories" icon={<ForwardRight />} content={t("Footer_ExploreTrainingStories")} iconLeft={false}></LabelledLink>
                }
              </Grid.Column> */}
              </Grid>
            )}

            {/* {
              window.location.pathname === "/" &&
              <Grid id="stories-header-wrapper" className="stories-headline-wrapper" container columns={2} style={{ paddingLeft: 0, marginLeft: 0, display: "flex", paddingRight: 0, alignItems: "center" }}>
                <Grid.Column className="explore-training-headline" width={16} style={{ paddingLeft: 0, marginLeft: 0 }}>
                  <Header as="h2" content={`${t('Footer_TrainingCoure_Headline')}`} textAlign="left" style={{ padding: 0 }} className="headline" />
                </Grid.Column>
              </Grid>
            } */}

            {window.location.href.includes("/stories") &&
              !(
                window.location.href.includes(
                  "/stories/bosch-service-training"
                ) ||
                window.location.href.includes(
                  "/stories/a-modern-training-approach"
                ) ||
                window.location.href.includes(
                  "/stories/a-day-in-the-life-of-a-trainer"
                ) ||
                window.location.href.includes(
                  "/stories/bosch-training-benefits"
                )
              ) && (
                <Grid
                  container
                  id="homepage-stories-content"
                  className={
                    !window.location.href.includes("/stories")
                      ? "our-services-and-get-in-touch-container non-padding"
                      : "our-services-and-get-in-touch-container"
                  }
                >
                  {/* {(
                  window.location.pathname === "/"
                ) && <ExploreTraining />} */}
                  {window.location.href.includes("/stories") &&
                    !(
                      window.location.href.includes(
                        "/stories/bosch-service-training"
                      ) ||
                      window.location.href.includes(
                        "/stories/a-modern-training-approach"
                      ) ||
                      window.location.href.includes(
                        "/stories/a-day-in-the-life-of-a-trainer"
                      ) ||
                      window.location.href.includes(
                        "/stories/bosch-training-benefits"
                      )
                    ) && <ExploreTrainingEqual />}
                </Grid>
              )}

            {!(
              window.location.href.includes("/contacts") ||
              window.location.href.includes("/privacy-policy")
            ) && (
              <Grid container className="get-in-touch-container">
                {/* {(countryCode.toLowerCase() !== "de" && countryCode.toLowerCase() !== "at" && countryCode.toLowerCase() !== "ch") && */}
                <div className="get-in-touch-wrapper">
                  <div className="get-in-touch-content">
                    <div className="get-in-touch-content-headline">
                      {t("Footer_GetInTouchWithUs")}
                    </div>
                    <div className="get-in-touch-content-body">
                      <div className="get-in-touch-content-details">
                        {t("Footer_EnquiryText")}
                      </div>
                      <div className="get-in-touch-content-details mobile-text">
                        <div>{t("Footer_Telephone")}</div>
                        <a href={"tel:"}>
                          {/* {translateResource.Footer_GetInTouchContainer_GetInTouchColumn_TelephoneNumber} */}
                          {t(`ContactUs_Mobile_${country.toUpperCase()}`)}
                        </a>
                      </div>
                      <div className="get-in-touch-content-share-icons">
                        <Icon
                          className="social-media-links-icon"
                          onClick={() =>
                            openLinkInNewTab(
                              "https://www.facebook.com/BoschGlobal"
                            )
                          }
                        >
                          <Facebook />
                        </Icon>
                        <Icon
                          className="social-media-links-icon"
                          style={{ margin: "0rem 30px" }}
                          onClick={() =>
                            openLinkInNewTab(
                              "https://www.twitter.com/BoschGlobal"
                            )
                          }
                        >
                          <TwitterX />
                        </Icon>
                        <Icon
                          className="social-media-links-icon"
                          onClick={() =>
                            openLinkInNewTab(
                              "https://www.instagram.com/boschglobal/"
                            )
                          }
                        >
                          <Linkedin />
                        </Icon>
                      </div>
                    </div>
                  </div>
                  <div className="get-in-touch-content">
                    <div className="get-in-touch-content-headline">
                      {t("Footer_SendUsAMessage")}
                    </div>
                    <div className="get-in-touch-content-details">
                      <Link to="/contacts" style={{ display: "flex" }}>
                        {t("Footer_ToContactForm")}{" "}
                        <Icon style={{ fontSize: "1.2rem", marginLeft: "4px" }}>
                          <ForwardRight style={{ fill: "#006ead" }} />
                        </Icon>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* } */}
              </Grid>
            )}
          </Grid>
        </div>
      )}

      {!window.location.href.includes("/regions") && (
        <>
          {/* {!window.location.href.includes("/stories") && (
      <FooterCards />)} */}
          {window.location.href.includes("/stories") &&
            !(
              window.location.href.includes(
                "/stories/bosch-service-training"
              ) ||
              window.location.href.includes(
                "/stories/a-day-in-the-life-of-a-trainer"
              ) ||
              window.location.href.includes(
                "/stories/bosch-training-benefits"
              ) ||
              window.location.href.includes(
                "/stories/a-modern-training-approach"
              )
            ) && <FooterCardStories />}

          <FooterSearch />
        </>
      )}

      <FooterAdditionalLinks />
    </Fragment>
  );
};

export default Footer;
