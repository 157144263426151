import "./FooterSearch.css";
import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import SearchField from "../Header/SearchField";
const FooterSearch:FC = () => {
  const { t } = useTranslation();
  const [isSearch, setIsSearch] = useState(false);
  const closeSearch = () => {
    setIsSearch(false);
  };
  return (
    <div className="search-form-box">
      <div className="grid-responsive-width">
        <div className="footer-search">
        <div className="search-form-headline">
        {t('Footer_StillLookingForSomething')}?
        </div>
        <div
          className="search-text-field"
          style={{ paddingLeft: "0rem", paddingRight: "0rem" }}
        >
          <SearchField
            footerMode={true}
            showResults={true}
            emitCloseEvent={true}
            closeSearch={closeSearch}
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSearch;
