import "semantic-ui-css/semantic.min.css";
import "./App.css";
import Router from "./router";
import i18n from "i18next";
import { findWindows } from "windows-iana";
import ct from "countries-and-timezones";
import { Fragment, useEffect, useState } from "react";
import { Region } from "./models/RegionState";
import { AppDispatch, useAppDispatch } from "./redux-toolkit/store";
import {
  getGeolocationAsync,
  getRegionsAsync,
  setBatsRegions,
} from "./redux-toolkit/regionReducer";
import { AuthService } from "./services/AuthService";
import {
  authFetchState,
  authSetNewState,
  AuthState,
} from "./redux-toolkit/authReducer";
import RedirectHelper from "./helpers/RedirectHelper";
import { CountryValidationModal } from "./Common-components/CountryValidationModal/CountryValidationModal";
import GlobalLoader from "./Common-components/loadable/Loading/GlobalLoader";
import { useSelector } from "react-redux";
import {
  getAccountSimpleAsync,
  selectCiamId,
} from "./redux-toolkit/accountReducer";
import { Constants } from "./helpers/Constants";
import ForbiddenPage from "./components/views/ErrorPages/ForbiddenPage";
import MaintenancePage from "./components/views/MaintainingInfo/MaintenancePage";

const App = () => {
  const isMaintainingMode =
    !!process.env.REACT_APP_ISMAINTAINING &&
    process.env.REACT_APP_ISMAINTAINING.toLowerCase() === "on";
  const [regions, setRegions] = useState<Region[]>([]);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);

  const [isRestrictedGeoLocation, setIsRestrictedGeoLocation] =
    useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const country = window.location.search !== "" ? urlParams.get("c") : "";
  const originCountry =
    window.location.search !== "" ? urlParams.get("oc") : "";
  const lang = window.location.search !== "" ? urlParams.get("l") : "";
  const onSetAuthState = async (region: Region | undefined) => {
    if (!!country && country !== "" && !!region) {
      let timeZoneId = "";
      if (country.trim().toLowerCase() === "uk") {
        const countryName = ct.getCountry("GB");
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      } else {
        const countryName = ct.getCountry(country);
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      }
      let defaultLanguage = !!region
        ? region.languages.find((lan) => lan.defaultLanguage)
        : null;

      let authLanguage = !!defaultLanguage ? defaultLanguage.lcid : lang;
      if (!!originCountry && originCountry.trim().toLowerCase() === "sl")
        authLanguage = "hr-SL";

      let authDateFormat = region?.dateFormat ?? Constants.DEFAULT_DATE_FORMAT;

      const newAuthState = {
        domain: region.url,
        language: authLanguage,
        countryCode: country,
        originCountryCode: originCountry,
        countryName: region.countryName,
        clientId: region.clientId,
        timeZoneId: timeZoneId,
        languages: region.languages,
        dateFormat: authDateFormat,
      } as AuthState;
      AuthService.setAuthState(newAuthState);
      await dispatchReduxToolkit(authSetNewState(newAuthState));
      i18n.changeLanguage(!!authLanguage ? authLanguage : "EN");
    } else {
      await dispatchReduxToolkit(authFetchState());
    }
  };

  const detectCountry = async () => {
    let resGeoLocation = await dispatchReduxToolkit(getGeolocationAsync());
    if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
      if (
        !!resGeoLocation.payload &&
        resGeoLocation.payload.success &&
        !!resGeoLocation.payload.dataObject
      ) {
        AuthService.setGeolocation(resGeoLocation.payload.dataObject);
        let countryCodeGeolocation =
          resGeoLocation.payload.dataObject.countryCode;

        if (countryCodeGeolocation) {
          if (
            Constants.RESTRICTED_GEO_COUNTRIES.includes(countryCodeGeolocation)
          ) {
            setIsRestrictedGeoLocation(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    detectCountry();
  }, []);

  useEffect(() => {
    if (!isMaintainingMode) {
      const fetchRegions = async () => {
        //Get Regions Languages
        let regions = AuthService.getRegionsLanguages();
        let regionSelection = null;
        if (regions.length === 0) {
          let resGetRegions = await dispatchReduxToolkit(getRegionsAsync());
          if (getRegionsAsync.fulfilled.match(resGetRegions)) {
            if (!!resGetRegions.payload && !!resGetRegions.payload.dataObject) {
              AuthService.setRegionsLanguages(
                !!resGetRegions.payload.dataObject
                  ? resGetRegions.payload.dataObject
                  : []
              );
              regions = resGetRegions.payload.dataObject;
              await dispatchReduxToolkit(
                setBatsRegions(resGetRegions.payload.dataObject)
              );
            }
          }
        }
        let authState = AuthService.getAuthState();
        //First Time Access The Website
        if (
          authState.countryCode === "" &&
          country?.trim().toLowerCase().length === 0 &&
          !window.location.href.toLowerCase().includes("/regions") &&
          !window.location.href
            .toLowerCase()
            .includes("/registerpersonalaccount")
        ) {
          let resGeoLocation = await dispatchReduxToolkit(
            getGeolocationAsync()
          );
          if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
            if (
              !!resGeoLocation.payload &&
              resGeoLocation.payload.success &&
              !!resGeoLocation.payload.dataObject
            ) {
              AuthService.setGeolocation(resGeoLocation.payload.dataObject);
              let countryCodeGeolocation =
                resGeoLocation.payload.dataObject.countryCode;
              regionSelection = regions.find(
                (x) =>
                  x.country.trim().toLowerCase() ===
                  countryCodeGeolocation.trim().toLowerCase()
              );
              if (!!regionSelection) {
                const countryCodeCheck = !!regionSelection
                  ? regionSelection.country.trim().toUpperCase()
                  : "";
                const language = !!regionSelection
                  ? regionSelection.languages.find((l) => l.defaultLanguage)
                  : null;
                if (countryCodeCheck.length > 0) {
                  switch (countryCodeCheck) {
                    case "BA":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "hr");
                      break;
                    case "SL":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "sl");
                      break;
                    default:
                      RedirectHelper.redirectBasedOnGeolocation(
                        countryCodeCheck,
                        !!language ? language.lcid : ""
                      );
                      break;
                  }
                }
              } else {
                window.location.href = "/regions";
              }
            }
          }
        }

        //Setup based on country in url
        if (
          country?.trim().toLowerCase().length !== 0 ||
          authState.countryCode.trim().toLowerCase().length !== 0
        ) {
          let selectedCountry = country?.trim().toLowerCase();
          if (
            selectedCountry?.length === 0 &&
            authState.countryCode.trim().toLowerCase().length > 0
          )
            selectedCountry = authState.countryCode.trim().toLowerCase();
          regionSelection = regions.find(
            (x) => x.country.trim().toLowerCase() === selectedCountry
          );
          await onSetAuthState(regionSelection);
        }

        setRegions(regions);
        window.history.scrollRestoration = "manual";
        
      };

      fetchRegions();
    }
  }, []);

  useEffect(() => {
    if (
      !!ciamId &&
      !window.location.pathname.startsWith("/signoutcallback") &&
      !window.location.pathname.startsWith("/signincallback")
    ) {
      dispatchReduxToolkit(getAccountSimpleAsync(ciamId));
    }
  }, [ciamId]);

  return (
    <Fragment>
      {isRestrictedGeoLocation ? (
        <ForbiddenPage />
      ) : isMaintainingMode ? (
        <MaintenancePage />
      ) : (
        !!regions && regions.length > 0 && <Router regions={regions} />
      )}

      <CountryValidationModal />
      <GlobalLoader />
    </Fragment>
  );
};
export default App;
