import { FC, Fragment, useState } from "react";

import Boschicon from "../BoschIcon/Boschicon";
import AddEventFromCatalogToCartModal from "./Modals/AddEventFromCatalogToCartModal";
import { useTranslation } from "react-i18next";
import { Button, Popup } from "semantic-ui-react";

interface AddEventFromCatalogToCartComponentProps {
  eventInfo: any;
  isDisabled?: boolean;
  iconStyle?: any;
  buttonStyle?: any;
  isHeader?: boolean;
}
const AddEventFromCatalogToCartComponent: FC<
  AddEventFromCatalogToCartComponentProps
> = (props) => {
  const [courseData, setCourseData] = useState<any>();
  const [isDisplayAddCourseModal, setIsDisplayAddCourseModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const handleRegisterClick = () => {
    setCourseData(props.eventInfo);
    setIsDisplayAddCourseModal(true);
  };

  const setCloseAddCourseModalFunc = () => {
    setIsDisplayAddCourseModal(false);
  };

  return (
    <Fragment>
      {!props.isHeader ? (
        <Popup
          size="tiny"
          content={t("Button_AddToCart_Tooltips")}
          trigger={
            <Button
              primary
              disabled={props?.isDisabled ?? false}
              onClick={handleRegisterClick}
              style={props?.buttonStyle ?? null}
              icon={
                <Boschicon
                  name="shopping-cart"
                  style={props?.iconStyle ?? null}
                />
              }
            />
          }
        />
      ) : (
        <Button
          primary
          disabled={props?.isDisabled ?? false}
          onClick={handleRegisterClick}
          style={props?.buttonStyle ?? null}
        >
          <Boschicon name="shopping-cart" style={props?.iconStyle ?? null} />
          {t("Catalogue_Grid_Register_Button")}
        </Button>
      )}
      <AddEventFromCatalogToCartModal
        onDisplayModal={isDisplayAddCourseModal}
        courseData={courseData}
        onCloseModalTrigger={setCloseAddCourseModalFunc}
      ></AddEventFromCatalogToCartModal>
    </Fragment>
  );
};

export default AddEventFromCatalogToCartComponent;
