export const TRANSLATIONS_CS_CZ = {
	LandingPage_Title: "Vítejte ve školícím středisku automobilové techniky Bosch",
	LandingPage_SubTitle_ChooseCountry: "Zvolte Váš kraj / zemi",
	Header_ContactUs: "Kontaktujte nás",
	Header_Search: "Vyhledat",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Přihlásit se nebo založit účet",
	Header_LogIn: "Přihlásit se",
	Header_Cart: "Košík",
	Header_ShoppingCart: "Nákupní košík",
	Header_LogOut: "Odhlásit se",
	Header_ChangeDetails: "Změna údajů",
	Footer_ExploreOurTraining: "Prohlédněte si naše školení",
	Footer_TrainingAtYourFingertips: "Školení na dosah",
	Footer_DontMissAnyStory: "Nenechte si ujít žádný příběh",
	Footer_ExploreOurStories: "Prohlédněte si naše příběhy",
	Footer_GetInTouchWithUs: "Spojte se s námi",
	Footer_SendUsAMessage: "Zašlete nám zprávu",
	Footer_ToContactForm: "Kontaktní formulář",
	Footer_InventedForLife: "Stvořeno pro život",
	Footer_GeneralContactInfo: "Obecné kontaktní údaje",
	Footer_ProductSecurity_Psirt: "Ochrana produktu (Product Security, PSIRT - Product Security Incident Response Team)",
	Footer_InnovationsPatentsAndLicenses: "Licence a patenty inovací",
	Footer_PurchasingLogistics: "Obchod a logistika",
	Footer_CorporateInformation: "Korporátní informace",
	Footer_LegalNotice: "Zákonné oznámení",
	Footer_DataProtectionNotice: "Oznámení o ochraně dat",
	Footer_PrivacySettings: "Nastavení soukromí",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "© Robert Bosch odbytová s.r.o., všechna práva vyhrazena",
	Footer_StillLookingForSomething: "Vyhledávání",
	Footer_TrainingServices: "Nabídka školení",
	Footer_Courses: "Školení",
	Footer_Programs: "Programy školení",
	Footer_EventsCalendar: "Kalendář akcí",
	Footer_TrainingCenters: "Školící střediska",
	Footer_Dashboard: "Údaje",
	Footer_MyDashboard: "Moje údaje",
	Footer_ShoppingCart: "Nákupní košík",
	Footer_YourShoppingCart: "Váš nákupní košík",
	Footer_Administration: "Správa",
	Footer_Employees: "Zaměstnanci",
	Footer_Wholesalers: "Velkoobchody",
	Footer_Transactions: "Transakce",
	Footer_Workshops: "Servisy",
	Footer_WorkLikeABoschApplyNow: "Work #LikeABosch - pracovní nabídky, požádejte nyní",
	Footer_BoschGlobalCareers: "Kariéra v Bosch",
	Footer_PurchasingAndLogistics: "Obchod a logistika",
	Footer_BecomeABusinessPartner: "Staňte se obchodním partnerem",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Jak naše školení pomáhají Vašemu podnikání",
	Footer_Language: "Zvolte zemi",
	MenuPopup_MyCart: "Můj košík",
	Stories_OurTrainingStories: "O našich školení",
	Stories_ConnectingExperts: "Propojujeme odborníky a nadšence, snílky a tvůrce, inovátory a influencery – prozkoumejte a zažijte to",
	Stories_TopStories: "Nejlepší příběhy",
	Stories_AtHome: "Doma",
	Stories_Careers: "Kariéra",
	Stories_History: "Historie",
	Stories_IndustryAndTrades: "Průmysl a obchod",
	Stories_Mobility: "Mobilita",
	Stories_Research: "Výzkum",
	Stories_Sustainability: "Udržitelnost",
	Stories_AllStories: "Všechny příběhy",
	Stories_BlogATrainersDay: "BLOG | Den školitele",
	Stories_ADayinLifeOfATrainer: "Den v životě školitele",
	Stories_BoschServiceTraining: "Servisní školení Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "*PŘÍBĚH | Pohání nás, co pohání Vás",
	Stories_StoryHowCanWeBenefitYourBusiness: "* PŘÍBĚH | Jak můžeme přispět Vašemu podnikání",
	Stories_HowOurTrainingEvolves: "Jak se vyvíjí naše školení",
	Stories_AModernTrainingApproach: "Školení online",
	Stories_StoryAVirtualOnlineTrainingWorld: "PŘÍBĚH | Možnosti online školení",
	Stories_SubscribeToRSSFeed: "Odebírat RSS zprávy",
	Title_Main: "Školení automobilové techniky Bosch",
	Homepage_AboutUs: "O nás",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Spojte se s námi",
	Homepage_LatestNews: "Proč nás pohání touha za poznáním",
	Homepage_MainTitle: "Školení automobilové techniky Bosch",
	Homepage_OurOpenPositions: "Volné pozice u nás",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Pohání nás, co pohání nás",
	BoschServiceTraining_BoschServiceTraining: "Servisní školení Bosch",
	BoschServiceTraining_ShareThisOn: "Sdílejte",
	BoschServiceTraining_TheChallenge: "*Výzva: zvládnutí nových technologií",
	BoschServiceTraining_WorldwidePresence: "Celosvětová přítomnost",
	BoschServiceTraining_TechnicalTraining: "Technické školení Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Nabídky a příležitosti v nabídce Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "*Vhodná školení pro všechny cílové skupiny - ať už začátečník nebo profesionál. Nabídka zahrnuje jednodenní či několikadenní kurzy. Školení jsou nabízena v různých technických oblastech a sériích (například benzínové a naftové vstřikovací systémy nebo elektrické systémy). V technických školeních Bosch jsou zahrnuty rozsáhlé znalosti ve všech standartních vozidlových systémech Bosch i systémech jiných výrobců. Obsah školení je přesně nastaven pro požadavky pracovníků v autoservisech.",
	BoschServiceTraining_TrainedEmployeesDesc: "Vyškolení pracovníci jsou jasnou výhodou pro autoservisy. Jelikož se současná vozidla stávají čím dál komplexnějšími a nové technologie v nich obsažené představují nové výzvy. Pouze nutná znalost umožňuje servisním technikům diagnostikovat a opravovat moderní vozidla efektivním a úsporným způsobem",
	BoschServiceTraining_RegardlessOfHow: "Nehledě na kvalitu a zkušenosti technika, vždy nastanou situace, ve kterých přijde do kontaktu s novou technologií automobilového systému nebo s neznámou chybou. V takových případech se může hledání chyb a jejich řešení stát časově náročné, nebo nemusí přinášet úspěšné výsledky. V nejhorších případech se musí zákazník obejít bez svého vozu mnohem déle, než bylo původně očekáváno.",
	BoschServiceTraining_ItIsPrecisely: "Přesně v tento okamžik přichází Bosch se svými technickými školeními, která zlepšují kvalitu práce autoservisu a urychlují opravy. Současná školení Bosch poskytují podporu v lokalizování problémů a rychlé opravě více vozidel najednou, čímž je redukována čekací doba Vašich zákazníků. Díky těmto systematickým procedurám, jež jsou v Bosch vyučovány, jsou pracovní činnosti v autoservisu rychlejší a produktivnější.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Po více než 125 let je Bosch preferovaným dodavatelem originálních dílů, komponentů a systémů mnoha světových výrobců automobilů. Tato zkušenost přichází s unikátní znalostí nejnovějších technologií, která slouží jako základ podpory Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Školící střediska Bosch se nachází po celém světě: od Španělska po Turecko, Jižní Afriku, Austrálii či Brazílii - Bosch svým zaměstnancům nabízí prakticky orientovanou výuku, jež je vždy sladěna s nejšpičkovějšími technologiemi.",
	BoschServiceTraining_AgileCompany: "Agilní společnost",
	BoschServiceTraining_Collaboration: "Spolupráce",
	BoschServiceTraining_ConnectedMobility: "Propojená mobilita",
	BoschServiceTraining_ArtificialIntelligence: "Umělá inteligence",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Průkopníci v dialogu",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostika a testovací vybavení",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Základy automobilové technologie",
	BoschServiceTraining_Icon_Gasoline: "Benzín",
	BoschServiceTraining_Icon_Diesel: "Nafta",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternativní pohony",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrika a elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Karosérie a komfortní systémy",
	BoschServiceTraining_Icon_ChassisAndBraking: "Podvozek a brzdy",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Převodovka a pohonná jednotka",
	BoschServiceTraining_Icon_CommercialVehicles: "Komerční vozidla",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online školení",
	BoschServiceTraining_Icon_BusinessSkills: "Obchodní dovednosti",
	BoschServiceTraining_Icon_Accreditations: "Akreditace",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programy školení",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "ŠkolitelJménoPřijmení",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Den v životě školitele",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Každý den je dobrodružství",
	ADayInTheLifeOfATrainer_Summary: "Shrnutí",
	ADayInTheLifeOfATrainer_TheDayJourney: "Každodenní rutina",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Každý den je náročný, avšak vždy odměňující zkušenost. Potěšení z výuky účastníků našich kurzů s vědomím, že drží klíč k naší budoucnosti, je zodpovědností, avšak vzrušující zároveň. Vidět účastníky kurzů růst s každou další lekcí na denní a týdenní bázi dokazuje, že společně pracujeme ke společnému cíli. Zlepšit jejich vědomosti o moderních technologiích, jež pohání budoucnost!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Na konci každého dne je potřeba si sednout a zamyslet se nad uplynulým dnem. Dny ubíhají rychle, ale toto je důkazem, že to, co dokážeme není pouze odměnou studentům, ale i pro nás jako školitele.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Na konci dne můžeme jít domů spokojeni, že jsme odvedli skvělou práci!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Začátek",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Příprava a finální kontrola školícího materiálu. Zkontrolujte důležitou zprávu",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Začátek školení",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Uvítání účastníků. Prezentace náplně dne. Krátké představení.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorie",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Interakce a komunikace se skupinou dělá školení zajímavějším.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Přestávka na oběd",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktická část",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Školení pokračuje v dílně několika praktickými úkoly. Školitel je vždy přítomen a k dispozici pro případné dotazy.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Shrnutí",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Zpět do učebny na rychlé shrnutí náplně školní daného dne. Zodpovězení všech případných otázek. Příprava na následující lekce.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Poslední den",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Účastníkům jsou předány certifikáty o proškolení",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Domů",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Jak můžeme pomoci Vašemu podnikání",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Jak se vyvíjí naše školení",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Neustále se vyvíjíme, abychom zajistili kvalitu a aktuálnost školení",
	BoschTrainingBenefits_TheBoschServiceTraining: "Školící středisko Bosch by rádo pomohlo Vašemu podnikání skrze své rozsáhlé a vysoce relevantní portfolio technických a obchodních školení a dalšími vzdělávacími kurzy s certifikovanou kvalifikací. Následující strany Vám poskytují přehled našeho programu školení a dalších současných příležitostí ke vzdělávání. Těšíme se na setkání s Vámi a Vašimi zaměstnanci na našich kurzech a seminářích.",
	BoschTrainingBenefits_LongStandingExperience: "Dlouholetá zkušenost a rozsáhlé znalosti všech systémů v oblasti automobilové technologie.",
	BoschTrainingBenefits_QuickAndEfficient: "Rychlé a efektivní řešení mnoha problémů",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Výhody a benefity",
	BoschTrainingBenefits_LMS: "Systém vzdělávání - školení Bosch",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Školení Bosch pokrývají celý rozsah automobilových oblastí. Specializujte se ve specifických oblastech nebo rozšiřte Vaše kompetence s nabídkou školení Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "Získané znalosti o opravách, údržbě a servisních pracích.",
	BoschTrainingBenefits_TheHighDegree: "Vysoký stupeň praktického obsahu a malé skupiny garantují cílově orientovanou výuku",
	BoschTrainingBenefits_ParticipationInTraining: "Účast na školeních zvyšuje motivaci a spokojenost zaměstnanců a doplněná kvalifikace přesvědčuje zákazníka",
	BoschTrainingBenefits_IncreasedSales: "Zvýšení prodejů díky znalostem systémů napříč značkami",
	BoschTrainingBenefits_MethodicalProcedures: "Metodické procedury snižují čekací dobu zákazníků",
	BoschTrainingBenefits_HighlyQualified: "Vysoce kvalifikovaní školitelé předávají metodický přístup",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identifikace vhodného školení pro Vás a Vaše podnikání",
	BoschTrainingBenefits_DirectOnlineBooking: "Přímé online rezervace a správa kurzů pro Vás nebo Vaše zaměstnance",
	BoschTrainingBenefits_OnlineAccess: "Online přístup k výukové dokumentaci",
	BoschTrainingBenefits_AccessToYourOwn: "Přístup k historii Vašich školení a certifikátům",
	BoschTrainingBenefits_ToolsForManagement: "Nástroje pro správu Vašeho vlastního vývoje kariéry",
	BoschTrainingBenefits_ManagerHasFull: "Vedoucí má v systému plný přehled o školeních svých zaměstnanců",
	BoschTrainingBenefits_Access24hours: "Přístup 24 hodin denně 7 dní v týdnu",
	ModernTrainingApproach_KnowledgeIsKey: "Vědomosti jsou klíčovým faktorem úspěchu jakéhokoliv servisu. Z toho důvodu Bosch nabízí rozsáhlý profesionální program školení. To umožňuje účastníkům získat správné vědomosti na správném místě - vědomosti potřebné k profesionální údržbě a opravě vozidel zákazníků. Bosch zařadil webcasty jako nový modul e-learningového balíčku, který již byl v nabídce. Skládají se ze servisních školení navržených speciálně pro streamování.",
	ModernTrainingApproach_ANewLearning: "Nová příležitost ke vzdělání",
	ModernTrainingApproach_YourOnlineTraining: "Vaše příležitosti k online školení",
	ModernTrainingApproach_RecentlyTheWorld: "V poslední době se svět změnil, podívejte se, co děláme, abychom vám pomohli",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Svět online školení",
	ModernTrainingApproach_NewTeachingMethod: "Nové metody výuky",
	ModernTrainingApproach_InAdditionTo: "K existujícím prezenčním školení jsme přidali do nabídky školení formou webcastu. Náš školitel je Vám k dispozici s rozsáhlou teoretickou znalostí ve virtuální učebně nebo s praktickými příklady. Jako obvykle obdržíte mnoho informací o vozidlových systémech a příslušných produktech Bosch. Samozřejmě máte i příležitost pokládat otázky. Ty budou zodpovězeny přímo v rámci živého webcastu. Tyto živé webcasty trvají mezi 60 a 120 minutami. Stabilní internetové připojení je vyžadováno. Odpovídající webcast lze nalézt zadáním slova \Webcast\ ve vyhledávacím řádku v katalogu našich školení.",
	ModernTrainingApproach_BoschWebcastingHeader: "Webcasting Bosch/ internetové vysílání Bosch: Inovativní a praktické",
	ModernTrainingApproach_BoschWebcastingText: "K účasti na webcastu (internetovém vysílání) se musí účastník nejprve přihlásit. K tomu je v předstihu zaslán individuální přístupový kód. Až 200 účastníku najednou se může připojit z různých lokací. Školení je vedeno školitelem a dotazy mohou být položeny kdykoliv skrz funkci živého chatu.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitální doplněk výuky ve třídě",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting (internetové vysílání) a výuka v učebně se navzájem perfektně doplňují. V obou případech je školení vedeno kvalifikovaným školitelem. Webcasty jsou vhodné pro témata, jež mohou být předána digitálně bez potřeby úkonů přímo na voze. Například seznámení s různými funkcemi ESI[tronic] nebo asistenčními systémy řidiče. Pro účinnou týmovou spolupráci v dílně nebo praktické lekce na vozidle je prezenční školení stále nejlepší volbou.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streamování může být levnější (z anglického stream = proud, je technologie kontinuálního přenosu audiovizuálního materiálu mezi zdrojem a koncovým uživatelem)",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Náklady na účastníka jsou většinou nižší při online vzdělávání v porovnání se srovnatelným prezenčním školením. Důvodem je, že více účastníků může ze školení benefitovat, vedlejší náklady spojené se školením jsou výrazně nižší (žádné cestování na a z místa školení, celkově nižší časová náročnost)",
	ModernTrainingApproach_BookAndProfitHeading: "Zamluvit si a profitovat",
	ModernTrainingApproach_BookAndProfitText: "Online školení mohou být rezervována na našich webových stránkách stejně tak jako jiné nabídky školení. Máte možnost zaplatit za jednotlivá online školení, nebo si předplatit roční balíček určitého počtu školení.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Srovnání Bosch online školení a prezenčních školení",
	ModernTrainingApproach_Webcasting: "Online školení",
	ModernTrainingApproach_Classroom: "Učebna",
	ModernTrainingApproach_WebcastingP1: "Až 200 účastníků se může zúčastnit najednou z celého světa",
	ModernTrainingApproach_WebcastingP2: "Nízká složitost a relativně nízké náklady, žádné cestování",
	ModernTrainingApproach_WebcastingP3: "Ideální pro specifická a stručná individuální témata",
	ModernTrainingApproach_WebcastingP4: "Autoservisy mohou pouštět online školení svým zaměstnancům kdekoliv a kdykoliv",
	ModernTrainingApproach_WebcastingP5: "Je vyžadováno o 50-70% méně času než na prezenční školení",
	ModernTrainingApproach_ClassroomTrainingP1: "Až 16 účastníků se setká najednou",
	ModernTrainingApproach_ClassroomTrainingP2: "Cestovní náklady a obecně vyšší cena na osobu",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideální k optimalizaci spolupráce nebo výuce přímo na vozidle",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktická cvičení a interakce se školitelem",
	ModernTrainingApproach_ClassroomTrainingP5: "Školitel může lépe přizpůsobit předávané vědomosti podle aktuálních potřeb účastníků",
	ModernTrainingApproach_OnlineTrainingOfferings: "Nabídka online školení (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "V dnešní době je nezávislé vzdělávání u počítače důležitou součástí předávání znalostí. Volnost ve výběru času a místa, neomezená opakovatelnost, individuální rychlost výuky a interaktivní vzdělávání činí tuto metodu neodmyslitelným vzdělávacím nástrojem",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Každý komponent online školení (Web-Based Training WBT) byl pečlivě a strukturovaně připraven. Díky tomu tak  poskytuje široký vědomostní základ a zároveň slouží jako efektivní příprava na školící programy ve školících střediscích Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtuální simulace diagnostiky nabízí uživateli možnosti přípravy na každodenní situace v autoservisu jako například vyhledávání příčiny závady pomocí zařízení diagnostiky Bosch.",
	ModernTrainingApproach_Advantages: "Výhody",
	ModernTrainingApproach_AdvantagesP1: "Rychlá a účinná metoda poskytování vědomostí",
	ModernTrainingApproach_AdvantagesP2: "Pestrá výuka",
	ModernTrainingApproach_AdvantagesP3: "Lze použít tak často, jak je potřeba",
	ModernTrainingApproach_AdvantagesP4: "Šetří čas a peníze",
	ModernTrainingApproach_AdvantagesP5: "Individuální rychlost výuky",
	ModernTrainingApproach_AdvantagesP6: "Flexibilní studijní čas",
	ModernTrainingApproach_AdvantagesP7: "Opakovatelnost",
	ModernTrainingApproach_AdvantagesP8: "Žádné náklady na cestování",
	ModernTrainingApproach_AdvantagesP9: "Žádné kolize kapacity a plánování",
	Dashboard_CoursesViewAndManageYourCourses: "KURZY | Zobrazit a spravovat kurzy",
	Dashboard_MyCourses: "Moje kurzy",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "ŠKOLÍCÍ PROGRAM | Zobrazit Vaše školící programy",
	Dashboard_MyTrainingProgram: "Moje školící programy",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Zobrazit a spravovat eLearningové portfolio",
	Dashboard_MyELearning: "Můj eLearning",
	Dashboard_AccountManageYourAccount: "ÚČET | Spravovat Váš účet",
	Dashboard_MyAccount: "Můj účet",
	Dashboard_MyDashboard: "Domů",
	Dashboard_ManageYourAccount: "Správa účtu",
	Dashboard_Workshops: "Servisy/ Autoservisy",
	Dashboard_AdministrationManageYourWorkshop: "SPRÁVA | ADMINISTRACE | Správa Vašich servisů",
	Dashboard_AdministrationManageYourTeams: "SPRÁVA | ADMINISTRACE | Správa Vašich týmů",
	Dashboard_Administration: "Správa/ Administrace",
	Dashboard_ViewYourCourses: "Zobrazit kurzy",
	Dashboard_ViewYourTrainingPrograms: "Zobrazit školící programy",
	Dashboard_TrainingPrograms: "Školící programy",
	Dashboard_ViewAndEditYourDetails: "Zobrazit a upravit Vaše údaje",
	Dashboard_ViewYourELearningCourses: "Zobrazit eLearningové kurzy",
	Dashboard_Pending: "Čekající",
	Dashboard_RequiresAdministratorSignOff: "(vyžaduje schválení Administrátorem)",
	Dashboard_HistoricalRecords: "Minulé záznamy",
	Dashboard_Registered: "Registrováno",
	Dashboard_WaitingList: "Čekací list",
	Dashboard_RecommendedForYou: "Doporučeno pro Vás",
	Dashboard_AccountDetails: "Detaily o účtu",
	Dashboard_ChangeProfileImage: "změnit profilovou fotku",
	Dashboard_Title: "Titul",
	Dashboard_Role: "Role",
	Dashboard_FirstName: "Křestní jméno",
	Dashboard_LastName: "Příjmení",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobil",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Jazyk",
	Dashboard_Save: "Uložit",
	Dashboard_Student: "Účastník",
	Dashboard_WorkshopTrainingAdministrator: "Správce školení v servisu, administrátor školení",
	Dashboard_WholesalerTrainingAdministrator: "Správce školení velkoobchodu",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Správce společnosti",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Spravujte Vaše servisy …",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Spravujte Vaše zaměstnance…",
	Dashboard_CompanyAdministration_Workshop: "Autoservis",
	Dashboard_CompanyAdministration_Address: "Adresa",
	Dashboard_CompanyAdministration_Zip: "PSČ",
	Dashboard_CompanyAdministration_City: "Město",
	Dashboard_CompanyAdministration_Manage: "Spravovat",
	Dashboard_AdministrationSection_AdministrationTitle: "Správa/ Administrace",
	Dashboard_AdministrationSection_ManageTheTeam: "Spravovat tým",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "SPRÁVA | ADMINISTRACE Spravujte Vaše zaměstnance",
	Dashboard_AdministrationSection_Employees: "Zaměstnanci",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "SPRÁVA | ADMINISTRACE Správa velkoobchodních partnerů",
	Dashboard_AdministrationSection_Wholesalers: "Velkoobchodní partneři",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "SPRÁVA | ADMINISTRACE Spravujte Vaše zaměstnance",
	Dashboard_AdministrationSection_Transactions: "Transakce",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Správa zaměstnanců",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Spravujte Vaše zaměstnance…",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registrovat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Záznamy",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Upravit",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Zaměstnanec",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Přidat nový",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Vytvořit",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrováno",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Čekající",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(vyžaduje schválení Administrátorem)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Minulé záznamy",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Čekací list",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Seznam současných zaměstnanců",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Správa zaměstnanců",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Jméno",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Účet zaměstnance",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Zobrazit a upravit údaje zaměstnance…",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Nastavení",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Povolit osobní účet Školení automobilové techniky Bosch",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Povýšit zaměstnance na správce společnosti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Povolit registraci na kurz",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Poslat pozvání e-mailem",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktivovat účet",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Uložit",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Pozvat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Upravit",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Správa velkoobchodních partnerů",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Spravujte Vaše velkoobchodní partnery …",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Seznam schválených velkoobchodních partnerů",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Jméno",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Odstranit",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Vytvořit",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Přidat",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Vytvořit nový účet zaměstnance",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Zadat údaje nových zaměstnanců",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Přidat nového správce schválených velkoobchodních partnerů",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Zadat údaje nových velkoobchodních partnerů",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Zvolte schváleného velkoobchodního partnera z rozbalovacího seznamu",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transakce",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Zobrazte a spravujte Vaše transakce",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Zpět",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Číslo faktury",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Popis",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Způsob platby",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Množství",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Stáhnout",
	TrainingCentres_TrainingCentres: "Školící střediska",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naše školící střediska se nachází po celém světě",
	TrainingCentres_CountryCategory: "Kategorie země",
	TrainingCentres_CityCategory: "Kategorie města",
	TrainingCentres_Country: "Země",
	TrainingCentres_City: "Město",
	TrainingCatalogue_TrainingCatalogue: "Katalog školení",
	TrainingCatalogue_LearningAtTheHighestLevel: "Výuka na nejvyšší úrovni",
	TrainingCatalogue_StartDate: "Datum začátku",
	TrainingCatalogue_EndDate: "Datum konce",
	TrainingCatalogue_TrainingName: "Název školení",
	TrainingCatalogue_Language: "Jazyk",
	TrainingCatalogue_Days: "Dny",
	TrainingCatalogue_Seats: "Volných míst",
	TrainingCatalogue_Price: "Cena (bez DPH)",
	TrainingCatalogue_Location: "Místo",
	TrainingCatalogue_PartNumber: "Objednací číslo",
	TrainingCatalogue_TrainingCourse: "Školení",
	TrainingCatalogue_Category: "Kategorie",
	TrainingCatalogue_TrainingCategory: "Kategorie školeni",
	TrainingCatalogue_CourseName: "Název kurzu",
	TrainingCatalogue_TotalDays: "Celkem dnů",
	TrainingCatalogue_Venue: "Místo",
	TrainingCatalogue_Details: "Detaily",
	TrainingCatalogue_Prerequisites: "Předpoklady",
	TrainingCatalogue_CourseType: "Typ školení",
	TrainingCatalogue_LearningMethod: "Způsob výuky",
	TrainingCatalogue_AddToWaitingList: "Přidat na čekací list",
	ShoppingCart_ShoppingCart: "Nákupní košík",
	ShoppingCart_ReviewAndManage: "Zkontrolujte a spravujte vaše předplatné",
	ShoppingCart_YouHaveAddedItem: "Přidali jste následující položky do Vašeho košíku",
	ShoppingCart_Item: "Položka",
	ShoppingCart_Quantity: "Počet",
	ShoppingCart_PricePerItem: "Cena za položku",
	ShoppingCart_TotalPrice: "Cena celkem",
	ShoppingCart_VatIsIncluded: "Bez DPH",
	ShoppingCart_Redeem: "Pokud nemáte žádné kredity, klikněte zde pro získání",
	ShoppingCart_Purchase: "Pokud nemáte žádné kredity, klikněte zde pro zakoupení",
	ShoppingCart_Checkout: "Pokladna",
	ShoppingCart_ContinueShopping: "Pokračovat v nákupu",
	ShoppingCart_TotalPriceInclVat: "Cena celkem vč. DPH",
	ShoppingCart_Apply: "Potvrdit/ odeslat",
	ShoppingCart_ShoppingCartEmpty: "Váš nákupní košík je prázdný",
	ShoppingCart_VatIncluded: "DPH (včetně)",
	ShoppingCart_ManageYourShopping: "Správa Vašich nákupů",
	ShoppingCart_Participant: "Účastník",
	ShoppingCart_Complete: "Hotovo",
	ShoppingCart_NetPrice: "Cena",
	ShoppingCart_Workshop: "Autoservis",
	CheckOut_PreviewYourOrder: "Náhled Vaší objednávky",
	CheckOut_YourShoppingCart: "Košík",
	CheckOut_Preview: "Náhled",
	CheckOut_Payment: "Platba",
	CheckOut_Summary: "Shrnutí",
	CheckOut_Back: "Zpět",
	CheckOut_PleaseReadAndAgreeOurTerms: "Prosím přečtěte si a potvrďte souhlas s našimi pravidly a podmínkami",
	CheckOut_IHaveReadTheTermsAndCondition: "Přečetl jsem si pravidla a podmínky, rozumím jim a souhlasím s nimi",
	CheckOut_YesIAgree: "Ano - Souhlasím s pravidly a podmínkami",
	CheckOut_Continue: "Pokračovat",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Gratulujeme! Přijali jsme Vaši objednávku",
	CheckOut_OrderConfirmation: "Jakmile bude Váš požadavek úspěšně zpracován, obdržíte finální potvrzovací e-mail",
	CheckOut_WeWillContactYouDirectly: "V případě dotazů k Vaší objednávce Vás budeme kontaktovat",
	CheckOut_AddToCart: "Přidat do košíku",
	CheckOut_CreditCart: "Kreditní karta",
	CheckOut_DirectDebit: "Debetní karta",
	CheckOut_TrainingCredits: "Kredity školení",
	CheckOut_PreferredWholesaler: "Preferovaný velkoobchodní partner",
	EventCalendar_EventCalendar: "Termíny školení",
	EventCalendar_TrainingEventsForAllYourNeeds: "Školení pro všechny Vaše potřeby",
	TrainingCourse_TrainingCourse: "Katalog školících kurzů",
	TrainingCourse_LearningAtTheHighestLevel: "Výuka na nejvyšší úrovni",
	TrainingProgram_Title: "Katalog školících programů",
	TrainingProgram_SubTitle: "Výuka na nejvyšší úrovni",
	AccountDeactivationModal_Title: "Jste si jisti, že chcete deaktivovat tento účet?",
	AccountDeactivationModal_Line1: "Deaktivace tohoto účtu je permanentní a spolu s ní se odstraní všechen obsah včetně služeb, požadavků, transakcí a zůstatku, členů týmu a nastavení profilu.",
	AccountDeactivationModal_Line2Part1: "Pokud máte stále aktivní předplatná, prosím postupujte dle našich pravidel zrušení na našem …",
	AccountDeactivationModal_Line2Part2: "Podmínky a pravidla",
	AccountDeactivationModal_Line2Part3: "před odesláním Vašeho požadavku.",
	AccountDeactivationModal_Line3: "Jste si jisti, že chcete odeslat žádost ke zrušení tohoto účtu?",
	AccountDeactivationModal_Cancel: "Zrušit",
	AccountDeactivationModal_Send: "Odeslat",
	CurrentUserAccountDeactivationModal_Title: "Jste si jisti, že chcete deaktivovat Váš účet?",
	CurrentUserAccountDeactivationModal_Line1: "Deaktivace Vašeho účtu je permanentní a spolu s ní se odstraní všechen obsah včetně služeb, požadavků, transakcí a zůstatku, členů týmu a nastavení profilu.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Pokud máte stále aktivní předplatná, prosím postupujte dle našich pravidel zrušení na našem …",
	CurrentUserAccountDeactivationModal_Line3: "Jste si jisti, že chcete odeslat žádost ke zrušení Vašeho účtu?",
	CurrentUserAccountDeactivationModal_Yes: "Ano",
	CurrentUserAccountDeactivationModal_No: "Ne",
	CourseWithdrawalModal_Title: "Zrušení kurzu",
	CourseWithdrawalModal_Line1: "Jste si jisti, že se chcete odhlásit z kurzu?",
	CourseWithdrawalModal_Line2Part1: "Odhlášení podléhají poplatkům za zrušení a budou účtovány dle",
	CourseWithdrawalModal_Line2Part2Link: "podmínek a pravidel",
	CourseWithdrawalModal_Line2Part3: "našich služeb.",
	CourseWithdrawalModal_Yes: "Ano, odhlásit",
	CourseWithdrawalModal_No: "Ne, zrušit",
	Settings_Settings: "Nastavení",
	Settings_ManageYourSettings: "Správa Vašeho nastavení",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Moje přihlašovací údaje k Bosch Global ID Login",
	Settings_Edit: "Upravit",
	Settings_CompanyDetails: "Údaje o společnosti",
	Settings_RequestAccountDeletion: "Žádost o smazání účtu",
	Settings_AccountDeletionText: "Pokud smažete Váš účet, všechny Vaše data (včetně Vaší adresy a platebních údajů) budou odstraněna a již nebudou dále přístupna.",
	Settings_DeleteAccountRequestConfirmation: "Potvrzení žádosti o odstranění účtu",
	Settings_DeleteAccountRequestConfirmationText: "Obdrželi jsme Vaši žádost o odstranění účtu a budeme Vás kontaktovat e-mailem nebo telefonicky ohledně konečného potvrzení",
	RecommendedHotels_RecommendedHotels: "Doporučené hotely",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naše školící střediska se nachází po celém světě",
	Error_Common: "Něco se pokazilo!",
	Error_404: "Vámi hledaná stránka neexistuje",
	Error_401: "Nemáte přístup k této stránce",
	Error_500: "Server hlásí chybu",
	Dashboard_Courses_Table_Course: "Kurz",
	Dashboard_Courses_Table_Date: "Datum [trvání]",
	Dashboard_Courses_Table_Venue: "Místo konání",
	Dashboard_Courses_Table_TrainingType: "Typ školení",
	Dashboard_Courses_Table_Action: "Akce",
	Dashboard_Courses_Table_TasksToComplete: "Máte k dokončení následující úkony",
	Dashboard_Courses_Table_Withdraw: "Zrušit",
	Dashboard_Courses_Table_Remove: "Odstranit",
	Dashboard_Courses_Table_View: "Zobrazit",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Žádné záznamy k zobrazení",
	Dashboard_Courses_Table_CompletionDate: "Datum dokončení",
	Dashboard_Courses_Table_Actions_Assignments: "Úkoly",
	Dashboard_Courses_Table_Actions_Certification: "Certifikace",
	Dashboard_Courses_Table_Actions_Evaluations: "Hodnocení",
	Dashboard_Courses_Table_Actions_Assessments: "Posouzení",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Přidat do kalendáře",
	Dashboard_Courses_Table_CourseDetails_Task: "Úkol",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Datum splatnosti",
	ContactUs_Headline: "Kontaktujte nás",
	ContactUs_HowCanWeHelp: "Jak Vám můžeme pomoci?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Dopis",
	ContactUs_Phone_Headline: "Zavolejte nám!",
	ContactUs_Phone_Description: "Těšíme se na Váš telefonát a bude nám potěšením Vám pomoci.",
	ContactUs_Phone_PhoneNumber: "K odeslání zprávy prosím využijte kontaktního formuláře.",
	ContactUs_Phone_ChargeNotice: "Poplatky se liší dle Vašeho poskytovatele služeb a země.",
	ContactUs_Letter_Headline: "Pošlete dopis!",
	ContactUs_Letter_Description: "Máte dotazy, požadavky, nebo návrhy? Těšíme se na Váš dotaz.",
	ContactUs_Email_Headline: "Pošlete e-mail!",
	ContactUs_Email_Description: "Pošlete nám Vaši zprávu vyplněním formuláře níže. Jeden z našich poradců se s Vámi rád spojí.",
	ContactUs_Email_InputName: "Jméno*",
	ContactUs_Email_InputCompany: "Společnost",
	ContactUs_Email_InputCountry: "Země*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Zpráva*",
	ContactUs_Email_DataProtection: "Zpráva o ochraně dat*",
	ContactUs_Email_DataProtectionConfirm: "Přečetl jsem si pravidla o soukromí*",
	ContactUs_Email_SecurityCheck: "Bezpečnostní kontrola",
	ContactUs_Email_SecurityCodeConfirm: "Prosím vložte správné znaky z tabulky výše",
	ContactUs_Email_SecurityCodeInvalid: "Vaše odpověď CAPTCHA je nesprávná (CAPTCHA je označení pro automatický Turingův test, který slouží k odlišení počítačů od lidí. V angličtině vznikl tento termín zkrácením celého názvu – completely automated public Turing test to tell computers and humans apart).",
	ContactUs_Email_MandatoryNotice: "Prosím vyplňte povinná pole.",
	ContactUs_Email_SuccessMessage: "Vaše zpráva byla odeslána",
	ContactUs_Email_TakenNoteDataProtection1: "Přečetl jsem",
	ContactUs_Email_TakenNoteDataProtection2: "pravidla o ochraně soukromí. *",
	ContactUs_Email_SubmitButton: "Odeslat",
	ContactUs_Email_FieldErrorMessage: "Prosím vyplňte povinná pole.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Váš účet je momentálně zpracováván.",
	AccountInReviewModal_Paragraph1: "Děkujeme Vám za registraci osobního účtu Školení Automobilové Techniky Bosch.",
	AccountInReviewModal_Paragraph2: "Naši pověření pracovníci právě kontrolují a schvalují Váš účet.",
	AccountInReviewModal_Paragraph3: "Jakmile bude Váš účet úspěšně dokončen, obdržíte e-mail s potvrzením.",
	AccountInReviewModal_Paragraph4: "Pokud máte jakékoliv dotazy, nebo Váš účet nebyl zpracován během 48 hodin (vyjma víkendů), můžete nás kontaktovat",
	AccountInReviewModal_ContactUsLink: "zde",
	AccountInReviewModal_OKButton: "OK",
	Header_YesButton: "Ano",
	Header_NoButton: "Ne",
	Header_LogOutText: "Opravdu se chcete odhlásit?",
	Footer_EnquiryText: "Těšíme se na Váš požadavek,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Podmínky a pravidla",
	Footer_HowOurTrainingEvolves: "Jak se vyvíjí naše školení",
	FooterSearch_SearchFieldPlaceholder: "Hledat",
	TrainingCatalogue_Action: "Akce",
	TrainingCatalogue_Duration: "Trvání",
	TrainingCatalogue_Information: "Informace",
	Settings_PersonalDetails: "Osobní údaje",
	Reset_All_Filters: "Smazat všechny filtry",
	EventTable_Days: "Dny",
	SearchField_AllResults: "Všechny výsledky",
	TrainingCentresLayout_ViewDetails: "Zobrazit detaily",
	Dropdown_Placeholder: "Zvolte…",
	WaitlistPopup_SelectButtonText: "Zvolit",
	WaitlistPopup_StartDate: "Datum začátku",
	WaitlistPopup_EndDate: "Datum konce",
	WaitlistPopup_Language: "Jazyk",
	WaitlistPopup_City: "Město",
	WaitlistPopup_Workshop: "Dílna",
	WaitlistPopup_Student: "Účastník",
	WaitlistPopup_PreferredLocation: "Preferovaná lokace",
	WaitlistPopup_SignUpCourseWaitingList: "Přihlaste se na čekací listinu kurzu",
	WaitlistPopup_SignUpWithoutSelectingDate: "Přihlaste se na čekací listinu bez zvolení datumu",
	WaitlistPopup_GeneralWaitList: "Obecný čekací list",
	WatinglistPopup_CourseLabel: "Kurz",
	Common_ContinueButton: "Pokračovat",
	UserAccountDeactivationModal_ConfirmLabel: "Potvrdit",
	Filter_FilterLabel: "Filtrovat",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Již jste zapsán na čekací listinu, chcete zrušit Vaši existující registraci a vytvořit novou?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ano, odstranit",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ne, zrušit",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Zrušení kurzu",
	WaitinglistPopup_SignUpButtonText: "Přihlásit se",
	MyAccount_FirstNameRequied: "Je vyžadováno křestní jméno",
	MyAccount_LastNameRequired: "Je vyžadováno příjmení",
	MyAccount_RoleRequired: "Je vyžadována role",
	MyAccount_MobileRequired: "Je vyžadován mobil",
	MyAccount_EmailRequired: "Je vyžadován e-mail",
	MyAccount_LanguageRequired: "Je vyžadována volba jazyka",
	MyAccount_UpdateSuccessMessage: "Údaje Vašeho účtu byly úspěšně aktualizovány.",
	MyAccount_UpdateSuccessModal_ButtonText: "Ano, zavřít",
	Infor_CorporateInformation_Header: "Korporátní údaje",
	Infor_LegalNotice_Header: "Zákonné oznámení",
	Infor_PrivacyPolicy_Header: "Oznámení o ochraně osobních údajů",
	CreateEmployeeAccountModification_FormValidation_Role: "Je vyžadována role",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Je vyžadováno křestní jméno",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Je vyžadováno příjmení",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Je vyžadován mobil",
	CreateEmployeeAccountModification_FormValidation_Email: "Je vyžadován e-mail",
	CreateEmployeeAccountModification_FormValidation_Language: "Je vyžadována volba jazyka",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Pozvánka na Školení Automobilové Techniky Bosch",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Úspěšně jste přidali nového člena Vašeho týmu.",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Chcete, abychom sdíleli s novými členy Vašeho týmu e-mailové pozvánky s instrukcemi, jak si mohou vytvořit jejich osobní účet na Školení Automobilové Techniky Bosch?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ne",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ano, odeslat pozvánku",
	ShoppingCart_Venue_Fee: "Poplatek za místo",
	ShoppingCart_Trainning_Name: "Název školení",
	ShoppingCart_Available_Credits: "Dostupné kredity",
	ConfirmItsMeModal_Hi: "Ahoj",
	ConfirmItsMeModal_Description1: "Úspěšně jste dokončili pozvánku sdílenou správcem Vaší společnosti k vytvoření Vašeho osobního účtu Školení Automobiové Techniky Bosch.",
	ConfirmItsMeModal_Description2: "Než budete pokračovat, prosím potvrďte nebo aktualizujte Vaše osobní údaje",
	ConfirmItsMeModal_Description3: "Až potvrdíte, bude po Vás vyžadováno opětovné přihlášení k dokončení Vaší registrace.",
	ConfirmItsMeModal_CompanyDetails: "Údaje o společnosti",
	ConfirmItsMeModal_YourDetails: "Vaše údaje",
	ConfirmItsMeModal_YourDetails_Title: "Titul",
	ConfirmItsMeModal_YourDetails_Function: "Funkce",
	ConfirmItsMeModal_YourDetails_Name: "Jméno",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobil",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Jazyk",
	ConfirmItsMeModal_ConfirmButton: "Potvrdit",
	SelectWholesalerModal_WholesalerGroupLabel: "Velkoobchodní partner",
	SelectWholesalerModal_WholesalerBranchLabel: "Pobočka",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Prosím zvolte",
	ApplyButton: "Použít",
	SelectWholesalerModal_Title: "Zvolte Vašeho velkoobchodního partnera",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Chcete odstranit Vaši registraci?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Vaše registrace byla úspěšně odstraněna.",
	WaitlistPopup_AddToWaitListSuccess: "Vaše registrace byla úspěšně přidána.",
	ErrorCommon_Header: "CHYBA",
	CommonButton_Close: "Zavřít",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Pro pokračování prosím zavřete toto okno.",
	EmbeddedVideoPlayer_StartButtonText: "Začít",
	EmbeddedVideoPlayer_RefresherButtonText: "Obnovit",
	DeactivateWholesalerModal_ConfirmMessage: "Jste si jisti, že chcete odebrat tohoto velkoobchodního partnera?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "E-mailová pozvánka úspěšně odeslána.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Znovu pozvat",
	ContactUs_Mobile_CZ: "420 255 706 289",
	ContactUs_Mobile_SK: "421 248 703 219",
	ContactUs_Address_CZ: "Robert Bosch odbytová s.r.o. | Radlická 350/107d | 15800 Praha 5 | CZECH REPUBLIC",
	ContactUs_Address_SK: "Robert Bosch odbytová s.r.o. | Radlická 350/107d | 15800 Praha 5 | CZECH REPUBLIC",
	ConfirmItsMeModal_CompanyDetails_Name: "Název společnosti",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresa",
	ConfirmItsMeModal_CompanyDetails_Postcode: "PSČ",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Odhlášení z kurzu proběhlo úspěšně.",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Vytvoření účtu selhalo. Tento účet již existuje!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Aktualizace účtu selhala. Tento účet již existuje!",
	Footer_ExploreTrainingStories: "Prozkoumejte naše školení",
	Breadcrumb_Home: "Domů",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Příběhy",
	Breadcrumb_Stories_BoschServiceTraining: "Servisní školení Bosch",
	Breadcrumb_Stories_ADayInLife: "Den v životě školitele",
	Breadcrumb_Stories_BoschTrainingBenefits: "Výhody školení Bosch",
	Breadcrumb_Stories_TrainingApproach: "Moderní přístup ke školení",
	Breadcrumb_Settings: "Nastavení",
	Breadcrumb_ShoppingCart: "Nákupní košík",
	Breadcrumb_Checkout: "Zaplatit",
	Breadcrumb_OrderSummary: "Shrnutí objednávky",
	Breadcrumb_Exception: "Výjimka",
	Breadcrumb_Dashboard: "Hlavní strana",
	Breadcrumb_MyCourses: "Moje kurzy",
	Breadcrumb_MyELearning: "Můj eLearning",
	Breadcrumb_CompanyAdministration: "Správa společnosti",
	Breadcrumb_EmployeeManagement: "Správa zaměstnanců",
	Breadcrumb_EmployeeAccount: "Účet zaměstnance",
	Breadcrumb_EmployeeCreation: "Vytvořit zaměstnance",
	Breadcrumb_Administration: "Správa",
	Breadcrumb_EmployeeAdministration: "Správa zaměstnanců",
	Breadcrumb_WholesalerManagement: "Správa velkoobchodních partnerů",
	Breadcrumb_NewWholesalerAccountCreation: "Vytvoření nového účtu velkoobchodního partnera",
	Breadcrumb_Transactions: "Transakce",
	Breadcrumb_MyAccount: "Můj účet",
	Breadcrumb_TrainingCentres: "Školící střediska",
	Breadcrumb_EventCalendar: "Kalendář událostí",
	Breadcrumb_TrainingCourseCatalogue: "Katalog školení",
	Breadcrumb_RecommendedHotels: "Doporučené hotely",
	Breadcrumb_CorporateInformation: "Korporátní údaje",
	Breadcrumb_LegalNotice: "Právní oznámení",
	Breadcrumb_DataProtectionNotice: "Oznámení o ochraně dat",
	Breadcrumb_PrivacyPolicy: "pravidla o ochraně soukromí",
	Breadcrumb_TermCondition: "Podmínky a pravidla",
	Breadcrumb_Terms: "Podmínky",
	Breadcrumb_PrivacyStatement: "Prohlášení o ochraně osobních údajů",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Poskytovatel",
	Breadcrumb_RegisterAccount: "Registrovat účet",
	Stories_ExploreMore: "Objevit více",
	CheckOutLayout_ShoppingCartHeader: "Nákupní košík",
	CheckOutLayout_AddedItemCartMessage: "Přidali jste následující do vašeho košíku",
	CheckOutLayout_CheckYourCartMessage: "Zkontrolovat košík",
	ModernTrainingApproach_DateText: "2/28/2022",
	ADayInLifeofATrainer_DateText: "2/28/2022",
	BoschTrainingBenefits_DateText: "2/28/2022",
	BoschServiceTraining_DateText: "2/28/2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Hledat zaměstnance",
	CheckOutLayout_OrderWasNotPlaced_Message: "Omlouváme se! Vaše objednávka nebyla přijata",
	CheckOutLayout_RefusalReason: "Důvod odmítnutí",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Platba selhala. Prosím zkuste platbu zopakovat jinou metodou",
	CheckOutLayout_SubmittingApiFailed_Message: "Odesílání API se nepodařilo",
	CartItem_ChooseWorkshop_Message: "Prosím vyberte autoservis pro výběr účastníka",
	CartItem_ChooseParticipant_Message: "Prosím zvolte účastníka pro pokračování",
	CompanyAdministrationTable_SearchBox_Placeholder: "Hledat autoservisy",
	CartItem_RemoveCartItem_ConfirmText: "Chcete odebrat tuto položku z nákupního košíku?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkce",
	AddToCartSuccessModal_Message: "Váš nákupní košík byl aktualizován",
	AddToCartSuccessModal_ContinueButtonText: "Pokračovat v nákupu",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Můj nákupní košík",
	AddVoucher_Header: "Přidat kupón/ voucher",
	AddVoucher_InputPlaceholder: "Zadejte kód a potvrďte",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Bohužel momentálně nemáme žádné naplánované termíny tohoto školení. Prosím zaregistrujte se na čekací listinu, my Vás budeme informovat, jakmile budou vypsány nové termíny tohoto školení.",
	ADYEN_ERR_2_: "Transakce byla odmítnuta.",
	ADYEN_ERR_3_: "Chyba doporučení/ chybný odkaz",
	ADYEN_ERR_4_: "Transakce neproběhla z důvodu chyby na straně příjemce.",
	ADYEN_ERR_5_: "Platební karta použitá pro transakci je zablokována, tudíž není možné jí použít.",
	ADYEN_ERR_6_: "Platební karta použitá pro transakci je neplatná, tudíž jí nelze použít.",
	ADYEN_ERR_7_: "Během transakce nastala nesrovnalost v částce.",
	ADYEN_ERR_8_: "Vložené číslo platební karty je nesprávné nebo neplatné.",
	ADYEN_ERR_9_: "Nebylo možné navázat kontakt s bankou kupujícího a schválit transakci",
	ADYEN_ERR_10_: "Banka kupujícího tento typ transakce nepodporuje nebo neumožňuje.",
	ADYEN_ERR_11_: "Ověření 3D Secure nebylo provedeno nebo nebylo provedeno úspěšně.",
	ADYEN_ERR_12_: "Na použité platební kartě není dostatečný zůstatek k pokrytí platby. Tento problém mohl nastat z důvodu nastavených měsíčních limitů na kartě. Prosím zkuste zaplatit jinou kartou, nebo kontaktujte Vaši banku.",
	ADYEN_ERR_14_: "Možný podvod.",
	ADYEN_ERR_15_: "Transakce byla zrušena.",
	ADYEN_ERR_16_: "Kupující zrušil transakci před jejím dokončením.",
	ADYEN_ERR_17_: "Zadaný PIN je nesprávný nebo neplatný.",
	ADYEN_ERR_18_: "Kupující zadal nesprávný PIN více než tříkrát v řadě.",
	ADYEN_ERR_19_: "Zadaný PIN nebylo možné ověřit.",
	ADYEN_ERR_20_: "Možný podvod.",
	ADYEN_ERR_21_: "Transakce nebyla správně odeslána ke zpracování.",
	ADYEN_ERR_22_: "Zhodnocení rizika označilo tuto transakci jako podvodnou (risk score >= 100), tudíž byla operace zrušena",
	ADYEN_ERR_23_: "Transakce nebyla povolena na straně držitele karty",
	ADYEN_ERR_24_: "Bezpečnostní kód CVC není platný",
	ADYEN_ERR_25_: "Neplatná karta v této zemi.",
	ADYEN_ERR_26_: "R1: Zrušení příkazu k autorizaci / \"R3: Zrušení příkazu k autorizaci\" / \"R0: Zastavení platebního příkazu\"",
	ADYEN_ERR_27_: "Tato odpověď mapuje všechny ty kódy odpovědí, které nelze spolehlivě zmapovat. To usnadňuje odlišení obecných odmítnutí (například odpověď Mastercard „05: Don't honor“) od konkrétnějších.",
	ADYEN_ERR_28_: "Povolený limit karty byl překročen.",
	ADYEN_ERR_29_: "Počet transakcí překročil povolený limit.",
	ADYEN_ERR_31_: "Zprostředkovatel nahlásil transakci jako podezření na podvod.",
	ADYEN_ERR_32_: "Adresa kupujícího byla zadána chybně.",
	ADYEN_ERR_33_: "Banka nakupujícího vyžaduje, aby nakupující zadal online PIN.",
	ADYEN_ERR_34_: "Banka kupujícího požaduje kontrolu účtu k dokončení nákupu.",
	ADYEN_ERR_35_: "Banka kupujícího vyžaduje k dokončení nákupu spořicí účet.",
	ADYEN_ERR_36_: "Banka kupujícího požaduje zadání mobilního PIN.",
	ADYEN_ERR_37_: "Nakupující opustil transakci poté, co se pokusil o bezkontaktní platbu, a byl vyzván, aby zkusil jinou metodu zadání karty (PIN nebo přejetí prstem).",
	ADYEN_ERR_38_: "Vydavatel odmítl žádost o výjimku z autentizace a vyžaduje ověření transakce. Zkuste to znovu s 3D Secure.",
	ADYEN_ERR_39_: "Vydavatel nebo schéma nebyli schopni sdělit výsledek prostřednictvím Rreq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Křestní jméno nemůže obsahovat speciální znaky (např.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Křestní jméno musí mít méně než 75 znaků",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Příjmení nemůže obsahovat speciální znaky (např.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Příjmení musí mít méně než 75 znaků",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobilní číslo nemůže obsahovat speciální znaky (např.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobilní číslo musí mít méně než 45 znaků",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonní číslo nesmí obsahovat speciální znaky (např.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonní číslo musí mít méně než 45 znaků",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "E-mailová adresa je neplatná",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-mailová adresa musí mít méně než 100 znaků",
	ConfirmGeolocationModal_ConfirmButton: "Pokračovat",
	ConfirmGeolocationModal_CancelButton: "Změnit",
	ConfirmGeolocationModal_CurrentCountrySelection: "Vámi zvolená země je:",
	ConfirmGeolocationModal_AskChangeCountry: "Přejete si setrvat na této stránce nebo si přejete změnit výběr Vaší země?",
	Addtocart_Modal_AddToCartFailTitle: "Přidání účastníka selhalo",
	Addtocart_Modal_AlreadyInCart: "Účastník byl již na tuto událost přihlášen",
	TrainingCatalogue_DateRange: "Rozsah datumů",
	TrainingCatalogue_DateRange_placeholder: "Změnit rozsah datumů",
	Dashboard_MyPendingCourses: "Moje čekající kurzy",
	Dashboard_MyWaitingList: "Moje čekací listina",
	Dashboard_LatestNews: "Nejnovější zprávy",
	SettingsMainPage_EditAccountButton: "Upravit účet",
	SettingsMainPage_EditBoschId: "Upravit Bosch ID",
	EventInfoView_EventDetailTab: "Podrobnosti o události",
	EventInfoView_AssignmentsTab: "Úkoly",
	EventInfoView_EvaluationTab: "Zhodnocení",
	EventInfoView_AttachmentTab: "Přílohy",
	EventInfoView_CourseDescriptionTab: "Popis",
	EventInfoView_DescriptionTab_General: "Obecné",
	EventInfoView_DescriptionTab_PartLabel: "Část",
	AssignmentTab_Description: "Zde najdete všechny potřebné informace k dokončení kurzu.",
	AssignmentTab_Columns_Download: "Soubory ke stažení",
	AssignmentTab_Columns_Upload: "Nahrát",
	AssignmentTab_Columns_TaskComplete: "Úkol dokončen",
	EventDetailTab_Location_Message_Video: "Toto je online událost, ke které můžete vstoupit pomocí \"Spustit video\" odkazu.",
	EventDetailTab_Location_Message_WebBased: "Toto je online událost, ke které můžete vstoupit pomocí \"Spustit WBT\" odkazu.",
	EventDetailTab_Location_Message_WebCast: "Toto je online událost, ke které můžete vstoupit pomocí \"Připojit se nyní\" odkazu.",
	EventDetailTab_Link_Video: "Spustit video",
	EventDetailTab_Link_Video_Remark: "*Spuštění videa otevře nové okno. Budete přesměrováni zpět na tuto stránku, jakmile okno zavřete.",
	EventDetailTab_Link_WebBased: "Spustit WBT",
	EventDetailTab_Link_WebBased_Remark: "*Spuštění WBT otevře nové okno. Budete přesměrováni zpět na tuto stránku, jakmile okno zavřete.",
	EventDetailTab_Link_WebCast: "Spustit nyní",
	EventDetailTab_Link_WebCast_Remark: "*Odkaz \"Spustit nyní\" bude aktivní 30 minut před začátkem události.",
	EventDetailTab_Title_Status: "Stav",
	EventDetailTab_Title_Duration: "Trvání (h)",
	EventDetailTab_Title_Trainers: "Školitel(é)",
	EventDetailTab_Title_Language: "Jazyk",
	EventDetailTab_Title_Score: "Skóre (%)",
	EventDetailTab_Title_Calendar: "Přidat do kalendáře",
	EventDetailTab_Title_Certificate: "Stáhnout certifikát",
	EventDetailTab_Title_Messages: "Zprávy",
	EventDetailTab_Title_StartDate: "Datum začátku",
	EventDetailTab_Title_AvailableFrom: "Dostupné od",
	EventDetailTab_Title_Time: "Čas",
	EventDetailTab_Title_EndDate: "Datum konce",
	EventDetailTab_Title_ExpiresOn: "Platné do",
	EventDetailTab_Title_Room: "Místnost",
	EventDetailTab_Title_Method: "Metoda",
	EventDetailTab_Title_Link: "Odkaz*",
	EventDetailTab_Title_Location: "Lokace",
	AttachmentTab_EmptyScreenDescription: "K této události nejsou žádné přílohy.",
	AttachmentTab_Columns_Download: "Stáhnout",
	AttachmentTab_Description: "Zde najdete důležité informace, které Vám pomohou s tímto kurzem",
	EvaluationTab_Annotation: "Ceníme si Vaší odezvy a rádi bychom znali Váš názor. Prosím, věnujte pár minut vyplnění následujícího hodnocení. Pomůžete nám zlepšovat naše služby. Děkujeme Vám.",
	EvaluationTab_Notice: "Hodnocení jsou následující",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Prosím zodpovězte všechny povinné otázky před odesláním Vašeho hodnocení školení.",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Vaše zpětná vazba byla úspěšně odeslána.",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Odeslání hodnocení selhalo",
	TrainingCatalogueLayout_ExportButtonText: "Exportovat",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Hodnocení bylo dokončeno nebo vypršelo",
	EventDetailTab_Link_CopiedTooltip: "Zkopírováno do schránky",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skenujte ke spuštění WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skenujte ke spuštění Videa",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skenujte ke spuštění Webcastu",
	Footer_TrainingCoure_Headline: "Nabízená školení Bosch",
	Footer_TrainingCourse_Content: "Jako světově renomovaná značka s více než stoletím zkušeností v inovacích, nastavil Bosch globální standarty jako dodavatel automobilových systémů, náhradních dílů a technických řešení. Naše silné stránky vychází z našeho blízkého partnerství s výrobci vozidel, pro které navrhujeme, vyvíjíme a produkujeme špičkové systémy a komponenty, po celém světě. Díky tomu Bosch upevnil silnou historii nezávislém automobilovém trhu. Vzdělávání, diagnostická řešení a vývoj jsou zaručené klíče k úspěchu a tato filosofie je podložena širokou nabídkou školících kurzů, ve všech oblastech automobilové technologie, vozidlových systémů a diagnostické expertízy.",
	SearchField_NoRecordsFound: "Žádné záznamy nenalezeny",
	TrainingCatalogue_FreeTextSearch: "Volný text",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Hledat",
	CheckoutLayout_WholeSalerGroup: "Zvole skupinu velkoobchodního partnera",
	CreditInsufficientModal_Message: "Nemáte dostatečný obnos kreditů pro použití této platební metody. Prosím zkuste to znovu jinou metodou.",
	ShoppingCart_Required_Credits: "Celková cena",
	MaintainingInfo_Message1: "Tato stránka je v režimu údržby.",
	MaintainingInfo_Message2: "Budeme brzy zpět.",
	CountryValidationModal_Message: "Nemohli jsme Vás přihlásit v [CountryA], jelikož Váš účet byl registrován v [CountryB]",
	CountryValidationModal_ButtonText: "Pokračovat do [Country]",
	EventTable_LoginRequired: "Přihlaste se k registraci",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Čas začátku",
	TrainingPartTableLayout_EndTime: "Čas konce",
	TrainingPartTableLayout_Duration: "Trvání (h)",
	TrainingPartTableLayout_TrainingMethod: "Metoda školení",
	MyCoursesTableContents_ActiveHeader: "Aktivní kurzy",
	MyCoursesTableContents_ActiveTabHeader: "Aktivní",
	MyCoursesTableContents_ScheduledHeader: "Naplánované kurzy",
	MyCoursesTableContents_ScheduledTabHeader: "Naplánováno",
	MyCoursesTableContents_OnlineHeader: "Online kurzy",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Zrušené kurzy",
	MyCoursesTableContents_CancelledTabHeader: "Zrušeno",
	OnlineCoursesTable_Title_Progress: "Průběh",
	ScheduledCoursesTable_Title_Date: "Datum [Dny]",
	CoursesTable_Title_Completed: "Dokončeno",
	Footer_TrainingProgram: "Školící programy",
	Breadcrumb_TrainingProgram: "Školící programy",
	TrainingProgram_Title_Table: "Titul",
	TrainingProgram_TrainingProgram: "Školící programy",
	TrainingProgram_TargetGroup: "Cílová skupina",
	TrainingProgram_MaxLength: "Délka",
	TrainingProgram_Information: "Informace",
	TrainingProgram_Detail: "Detaily",
	TrainingProgram_Modules: "Moduly",
	TrainingProgram_Course: "Kurzy",
	TrainingProgram_PartNumber: "Číslo dílu",
	TrainingProgram_Max_Length: "Maximální délka",
	TrainingProgram_Duration: "Trvání",
	TrainingProgram_Exam: "Zkouška",
	TrainingProgram_Assign_Popup: "Registrace pro školící program",
	TrainingProgram_Student: "Účastník",
	TrainingProgram_Assign: "Přidělit",
	TrainingProgram_Popup_Remove_Title: "Zrušit školení?",
	TrainingProgram_Description: "Popis",
	TrainingProgram_Days: "Dny",
	TrainingProgram_Type: "Typ",
	TrainingProgram_Action: "Akce",
	TrainingProgram_Enrolled: "již byl přiřazen k tomuto školícímu programu",
	TrainingProgram_SelfAssign_Enrolled: "Již jste byli zapsáni do tohoto školícího programu",
	TrainingProgram_Warning: "Prosím zaregistrujte jiného účastníka nebo vyberte jiný školící program",
	TrainingProgram_Workshop: "Autoservis",
	TrainingProgram_Durations: "roky",
	TrainingProgram_Duration_DaysLabel: "dny",
	TrainingProgram_HasExam_Yes: "Ano",
	TrainingProgram_HasExam_No: "Ne",
	AssignModal_SuccessModal_Header: "Gratulujeme!",
	AssignModal_SuccessModal_Message: "byl úspěšně přiřazen k tomuto školícího programu",
	AssignModal_SelfAssign_SuccessModal_Message: "Byli jste úspěšně přihlášení do školícího programu.",
	Dashboard_MyTraningProgram: "Můj školicí program",
	Dashboard_MyTraningProgram_Title: "Titul",
	Dashboard_MyTraningProgram_Status: "Stav",
	Dashboard_MyTraningProgram_Table_View: "Zobrazit",
	Dashboard_MyTraningProgram_Register: "Registrovat",
	Dashboard_MyTraningProgram_Records: "Historické záznamy",
	Dashboard_MyTrainingProgram_In_Progress: "V procesu",
	Dashboard_MyTrainingProgram_Accredited: "Akreditováno",
	Dashboard_Program_Modal_Withdraw: "Odhlásit",
	Dashboard_Program_Modal_Grade: "Známka",
	Dashboard_MyTrainingProgram_Registered: "Registrováno",
	Dashboard_MyTrainingProgram_Completedd: "Hotovo",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ano",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Ne",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Prosím berte v potaz, že se odhlásíte z celého školícího programu, ne jenom ze samostatného školení.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Přejete si odhlásit se ze školícího programu?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Probíhá",
	WithdrawProgramModal_Withdraw_Success_Message: "Úspěšně jste se odhlásili ze školícího programu!",
	Common_Warning_Title: "Varování!",
	Common_Error_Title: "Něco se pokazilo!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Přidat do košíku",
	AddEventFromCatalogToCartModal_HoursText: "hodiny",
	AddElearningToCartSuccessModal_Message: "bylo přidáno do Vašeho košíku.",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Zvolený účastník již byl zaregistrován do tohoto kurzu, nebo byl kurz již přidán do košíku.",
	EventDetailTab_SubjectForChange_Message: "Změna vyhrazena",
	Dashboard_MyTrainingProgram_Status_Failed: "Selhalo",
	Dashboard_MyTrainingProgram_Status_Passed: "Prošlo",
	Dashboard_MyTrainingProgram_Status_Merit: "Hodnota",
	Dashboard_MyTrainingProgram_Status_Distinction: "Rozlišení",
	Dashboard_Employee_Courses: "Kurzy",
	Dashboard_Employee_Program: "Školící program",
	Dashboard_Employee_WaitList: "Čekací listina",
	Dashboard_Employee_Account: "Účet",
	Course_Price_Free: "Volné",
	Dashboard_Employee_Account_Update_Success_Message: "Úspěšně jste aktualizovali informace o Vašem zaměstnanci.",
	Dashboard_Supplier: "Dodavatel",
	Event_Detail_Comment: "Momentálně nemáte žádné zprávy",
	DownLoad_PDF: "Stáhnout PDF",
	TrainingCatalogue_Currency: "Měna",
	My_Wallet: "Moje peněženka",
	Wallet: "Peněženka",
	Credits_Balance: "Zůstatek kreditů",
	MyFullCalendar_Today: "Dnes",
	Header_Available_Languages: "Dostupné jazyky",
	Header_CurrentRegion: "Momentálně jste v",
	Header_ChangeRegions: "Chcete změnit zemi?",
	Header_Anonymous: "Servisní školení",
	Header_Anonymous_Login: "Přihlásit se/Zaregistrovat",
	Header_RegisterCourse: "Registrovat kurz",
	Homepage_Start: "Registrovat se",
	Homepage_Login: "Přihlásit se",
	MainPage_Title: "Špičkové servisní řešení",
	MainPage_Content: "Váš autoservis, vaše volba.",
	MainPage_ImageTitle: "Bosch Automotive \n Řešení školení",
	MainPage_IntroContent: "Umožňuje modernímu autoservisu pracovat profesionálně, spolehlivě a efektivně v rámci diagnostických postupů, odstraňování závad, oprav a servisu všech typů vozidel.",
	BoschNews_TrainingNews: "Novinky ze školení",
	Service_Assist_Workshop: "Vylepšete své zkušenosti s Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Co mohu s aplikací dělat?",
	Enhance_Body_CDM3_Item_1: "Rezervujte si kurzy a vzdělávejte se",
	Enhance_Body_CDM3_Item_2: "Stáhněte si školicí materiály a certifikáty o kurzu",
	Enhance_Body_CDM3_Item_3: "Spusťte eLearningové kurzy",
	Enhance_Body_CDM3_Item_4: "Získejte přístup k různým automobilovým službám v mobilní aplikaci",
	Enhance_Body_CDM3_Item_5: "Živý přenos videa s Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Bezplatný nástroj pro automatické skenování vozidel",
	Training_Stories: "Příběhy ze školení",
	SettingsMyAccount_JoinCompanyButton: "Připojte se ke společnosti",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Připojte se ke společnosti",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Níže prosím zadejte svůj aktivační kód",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Poznámka! Chcete-li získat aktivační kód, musíte kontaktovat správce společnosti.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Vaše aktivace byla úspěšně zpracována a váš účet je nyní propojen s vaší společností.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Klikněte prosím na Pokračovat pro vstup na web.",
	ContinueButton: "Pokračovat",
	WelcomeBatsModal_Title: "Váš účet právě prochází kontrolou!",
	WelcomeBatsModal_ActivationLink: "Klikněte sem, pokud máte aktivační kód pro vstup do společnosti!",
	WelcomeBatsModal_ActivationLinkNotes: "Poznámka! Chcete-li získat aktivační kód, musíte kontaktovat správce společnosti.",
	AccountInReviewModal_Description_Paragraph1: "Děkujeme vám za registraci osobního účtu na Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Naši pracovníci v současné době kontrolují a konfigurují váš účet. Jakmile bude váš účet úspěšně nakonfigurován, obdržíte konečné potvrzení e-mailem.",
	AccountInReviewModal_CloseButton: "Dobře, pusťte mě prosím dovnitř.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nepodařilo se nám ověřit váš aktivační kód a e-mailovou adresu.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Zkuste to znovu nebo požádejte o pomoc správce společnosti.",
	TryAgain_Button: "Zkus to znovu",
	Error_Title: "Jejda! Něco se pokazilo!",
	SettingsMyProfileActivationSuccessModal_Title: "Povedlo se!",
	AccountInReviewModal_Description_Paragraph3: "Mezitím prosím neváhejte procházet naše webové stránky s omezeným přístupem.",
	AccountInReviewModal_Description_Paragraph4: "Pokud máte nějaké dotazy nebo pokud váš účet není zpracován do 48 hodin kromě víkendů a svátků, můžete nás kontaktovat [zde]",
	Support_Headline: "Řešení školení pro automobilový průmysl Bosch",
	Support_SubHeadline: "Nějaký návod, který vám pomůže...",
	Support_FAQ_Headline: "Často kladené otázky",
	Support_FAQs_section1_title: "Všeobecné",
	Support_FAQs_section1_question1: "Co je řešení Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions je platforma, která poskytuje komplexní přehled o technických školeních a umožňuje rezervovat a organizovat vaše školení.",
	Support_FAQs_section1_question2: "Mohu použít svůj stávající účet Bosch (singleKey ID) k přihlášení do Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Ano, je přístupný se všemi účty vytvořenými pomocí \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Mohu použít účet vytvořený pro Bosch Training Solutions s jinými službami nebo aplikacemi Bosch?",
	Support_FAQs_section1_question3_answer1: "Ano, své osobní Bosch ID můžete použít také pro řadu dalších aplikací Bosch – jak profesionálně, tak soukromě, jako je např. elektrokolo nebo chytrá domácnost.",
	Support_FAQs_section2_title: "Školení",
	Support_FAQs_section2_question1: "Jak si mohu zarezervovat školení?",
	Support_FAQs_section2_question1_answer1: "Přihlaste se pomocí svého stávajícího účtu NEBO si zaregistrujte účet na portálu. Přejděte na \"Služby školení\" NEBO \"Kalendář akcí\" vyberte školení a klikněte na tlačítko přidat na kartu.",
	Support_FAQs_section2_question2: "Není k dispozici vhodný termín školení. Co můžu dělat?",
	Support_FAQs_section2_question2_answer1: "Máte možnost se zařadit do \"obecného\" pořadníku (bez výběru termínu) a náš tým vás bude informovat, jakmile bude k dispozici nové školení.",
	Support_FAQs_section2_question3: "Kde najdu přehled mých rezervovaných kurzů?",
	Support_FAQs_section2_question3_answer1: "Kompletní přehled všech rezervovaných a absolvovaných kurzů naleznete po přihlášení na vašem dashboardu (panelu přístrojů).",
	Support_FAQs_section3_title: "Nastavení",
	Support_FAQs_section3_question1: "Jak mohu změnit svůj e-mail a heslo?",
	Support_FAQs_section3_question1_answer1: "Musíte se přihlásit na portál a změnit svůj osobní profil. (Informace o svém profilu můžete najít uvnitř svého Dashboardu, nebo pokud kliknete na následující tlačítko v záhlaví",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Zde máte možnost změnit podrobnosti svého Bosch ID",
	Support_FAQs_section3_question2: "Co mohu dělat, když jsem zapomněl heslo?",
	Support_FAQs_section3_question2_answer1: "Použijte prosím tlačítko přihlášení/registrace a vyplňte svou e-mailovou adresu",
	Support_FAQs_section3_question2_answer2: "Pokud kliknete na pokračovat, máte možnost kliknout na \"Zapomenuté heslo\"",
	Support_FAQs_section3_question3: "Jak založit účet autoservisu?",
	Support_FAQs_section3_question3_answer1: "Pokud chcete získat autoservisní roli, obraťte se na vašeho správce školení, administrátora,  např. můžete použít kontaktní formulář na webu.",
	LandingPage_Header_Title: "Řešení školení pro automobilový průmysl Bosch",
	LandingPage_ChooseCountry_Title: "Vyberte svou zemi/oblast",
	MaintenanceBanner_Message_1: "Portál Bosch Automotive Training Solutions Portal bude aktualizován dne [maintenance_schedule]. Očekává se, že aktualizace zabere 30 minut. Portál nebude během aktualizace dostupný.",
	MaintenanceBanner_Message_2: "\nPro další pomoc nás prosím kontaktujte e-mailem [support_email]",
	MaintenancePage_Message_1: "Portál Bosch Automotive Training Solutions Portal je v současné době aktualizován",
	MaintenancePage_Message_2: "Očekáváme, že portál bude znovu dostupný dne [maintenance_online_date] v [maintenance_online_time]",
	MenuHeader_Support: "Podpora",
	Course_Detail_No_Prerequisites: "Žádné předpoklady",
	TableCell_Hours: "Hodiny",
	Dashboard_MenuItem_Dashboard: "Přehled školení",
	Dashboard_MenuItem_Curricula: "Osnovy",
	Dashboard_MenuItem_Certificates: "Certifikáty",
	Dashboard_MenuItem_Team: "Tým",
	Dashboard_MenuItem_Workshops: "Autoservisy",
	Dashboard_MenuItem_External: "Externí",
	Dashboard_MenuItem_Wallet: "Peněženka",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Vaše nadcházející kurzy",
	Dashboard_Headline_YourELearningCourses: "Vaše eLearningové kurzy",
	Dashboard_Headline_TeamOverview: "Přehled týmu",
	Dashboard_Headline_YourOverview: "Váš přehled",
	Dashboard_Headline_YourWishlist: "Váš seznam přání",
	Dashboard_Headline_ActivePrograms: "Aktivní programy",
	Dashboard_Headline_CompletedPrograms: "Dokončené programy",
	Dashboard_Headline_YourCertificates: "Vaše certifikáty",
	Dashboard_Headline_Transactions: "Transakce",
	Dashboard_Table_NoRecordsToDisplay: "Žádný záznam k zobrazení",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "hodin",
	Dashboard_ELearningCourses_TableHeader_Title: "Titul",
	Dashboard_ELearningCourses_TableHeader_Duration: "Doba trvání (h)",
	Dashboard_ELearningCourses_ActionButton_View: "Náhled",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titul",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum [dny]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Umístění",
	Dashboard_UpcomingCourses_ActionButton_View: "Pohled",
	Dashboard_YourOverview_TableHeader_Title: "Titul",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum / Doba trvání",
	Dashboard_YourOverview_TableHeader_Location: "Umístění",
	Dashboard_YourOverview_TableHeader_Status: "Postavení",
	Dashboard_YourOverview_ActionButton_View: "Pohled",
	Dashboard_YourWishlist_TableHeader_Title: "Titul",
	Dashboard_YourWishlist_TableHeader_Action: "Akce",
	Dashboard_YourWishlist_ViewAllRecords: "Zobrazit všechny záznamy",
	Dashboard_TeamOverview_TableHeader_Employee: "Zaměstnanec",
	Dashboard_TeamOverview_TableHeader_Title: "Titul",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum / Doba trvání",
	Dashboard_TeamOverview_TableHeader_Location: "Umístění",
	Dashboard_TeamOverview_TableHeader_Status: "Postavení",
	Dashboard_TeamOverview_ActionButton_View: "Pohled",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Vyhledávání",
	Dashboard_ActiveProgram_TableHeader_Title: "Titul",
	Dashboard_ActiveProgram_TableHeader_Status: "Postavení",
	Dashboard_ActiveProgram_ActionButton_View: "Pohled",
	Dashboard_CompletedProgram_TableHeader_Title: "Titul",
	Dashboard_CompletedProgram_TableHeader_Status: "Postavení",
	Dashboard_CompletedProgram_ActionButton_View: "Pohled",
	Dashboard_Certificates_TableHeader_Title: "Titul",
	Dashboard_Certificates_TableHeader_Date: "datum",
	Dashboard_Certificates_TableHeader_Status: "Postavení",
	Dashboard_Certificates_TableHeader_Action: "Akce",
	Dashboard_Certificates_ActionButton_Download: "Stažení",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Číslo faktury",
	Dashboard_Transactions_TableHeader_Description: "Popis",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Způsob platby",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Množství",
	Dashboard_Transactions_ActionButton_Download: "Stažení",
	Breadcrumb_MyTraining: "Můj trénink",
	Breadcrumb_MyTraining_CourseDetails: "Detaily kurzu",
	Breadcrumb_MyTraining_Curricula: "Osnovy",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Podrobnosti o tréninkovém programu",
	Breadcrumb_MyTraining_Team: "Tým",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Zobrazit zaměstnance",
	MyTraining_Headerbar_CourseDetails: "detaily kurzu",
	MyTraining_Headerbar_TrainingProgramDetails: "Podrobnosti o tréninkovém programu",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Celkový postup",
	TrainingProgramDetails_ProgramItems_Headline: "Programové položky",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titul",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Školní známka",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Pohled",
	TrainingProgramDetails_Status_InProgress: "Probíhá",
	TrainingProgramDetails_Status_Completed: "Dokončeno",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Doba trvání",
	CourseDetails_Label_Trainer: "Školitel",
	CourseDetails_Label_Language: "Jazyk",
	CourseDetails_Label_Score: "Skóre:",
	CourseDetails_Label_StartDate: "Datum zahájení",
	CourseDetails_Label_EndDate: "Datum ukončení",
	CourseDetails_Label_Venue: "Místo",
	CourseDetails_Label_Address: "Adresa",
	CourseDetails_Label_Grade: "Školní známka",
	CourseDetails_Action_Withdraw: "Ustoupit",
	CourseDetails_Action_Evaluate: "Vyhodnotit kurz",
	CourseDetails_Action_Evaluate_Description: "Vyhodnoťte prosím tento kurz, abyste získali certifikát.",
	CourseDetails_Action_DownloadCertificate: "Stáhnout certifikát",
	CourseDetails_Action_JoinWebcast: "Připojte se k Webcastu",
	CourseDetails_Action_StartCourse: "Zahájit kurz",
	CourseDetails_Action_RestartCourse: "Znovu spustit kurz",
	CourseDetails_Action_RepeatCourse_Description: "Pro stažení certifikátu opakujte a absolvujte prosím tento kurz.",
	CourseDetails_PartSpecificDetails_Headline: "Podrobnosti specifické pro díl",
	CourseDetails_PartSpecificDetails_Tab_Part: "Část",
	CourseDetails_PartSpecificDetails_Description: "Popis",
	CourseDetails_PartSpecificDetails_StartDate: "Datum zahájení",
	CourseDetails_PartSpecificDetails_EndDate: "Datum ukončení",
	CourseDetails_PartSpecificDetails_StartTime: "Doba spuštění",
	CourseDetails_PartSpecificDetails_EndTime: "Čas ukončení",
	CourseDetails_PartSpecificDetails_Method: "Metoda",
	Dashboard_Team_TableHeader_Name: "název",
	Dashboard_Team_TableHeader_Role: "Role",
	Dashboard_Team_TableHeader_Email: "E-mailem",
	Dashboard_Team_TableHeader_PersonalAccount: "Osobní účet",
	Dashboard_Team_ActionButton_Select: "Vybrat",
	Dashboard_Team_PersonalAccount_Activated: "Aktivováno",
	Dashboard_Team_PersonalAccount_NotActivated: "Neaktivováno",
	Dashboard_Team_ActionButton_AddMember: "Přidat člena",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Vyhledávání",
	Dashboard_External_TableHeader_Name: "název",
	Dashboard_External_ActionButton_AddNew: "Přidat nový",
	Dashboard_External_Headline: "Schválený externí přístup",
	Dashboard_Workshops_Headline: "Workshopy",
	Dashboard_Workshops_TableHeader_Company: "Společnost",
	Dashboard_Workshops_TableHeader_Address: "Adresa",
	Dashboard_Workshops_TableHeader_Postcode: "PSČ",
	Dashboard_Workshops_TableHeader_City: "Město",
	Dashboard_Workshops_ActionButton_Select: "Vybrat",
	Wallet_ServiceCredits: "Servisní kredity",
	Wallet_Headline_CreditBalance: "Zůstatek kreditu",
	Wallet_Headline_CreditActivity: "Pohyb kreditů",
	Wallet_Headline_BillingTransactions: "Fakturační transakce",
	Wallet_BillingTransaction_TableHeader_Order: "Objednat",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Množství",
	Wallet_BillingTransaction_TableHeader_Action: "Akce",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Popis",
	Wallet_CreditActivity_TableHeader_User: "Uživatel",
	Wallet_CreditActivity_TableHeader_Amount: "Množství",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, všechna práva vyhrazena",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Zabezpečení produktu (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovace, patenty a licence",
	FooterAdditionalLinks_Navigation_Logistics: "Nákup a logistika",
	MenuHeader_MyTraining: "Moje školení",
	MenuHeader_TrainingServices: "Nabídka školení",
	MenuHeader_OurLocations: "Kde nás najdete",
	MenuHeader_ContactsUs: "Kontaktujte nás",
	MenuHeader_Logout: "Odhlásit se",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Moje Bosch Id",
	MyProfile_AccountDeletion: "Smazání účtu",
	MyProfile_Edit: "Změňte e-mail nebo upravte heslo",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Upravit uživatelský profil",
	BackButton: "Zpět",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Těšíme se na vaše zavolání a rádi vám pomůžeme. Jsme vám k dispozici 24 hodin, 7 dní v týdnu.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Zadejte správné znaky, jak je uvedeno v poli výše.",
	ContactUs_Email_MandatoryNotice_V3: "Vyplňte všechna povinná pole.",
	ContactUs_Email_SendButton: "Poslat",
	Breadcrumb_TrainingCenters: "Školicí střediska",
	Training_Centres_Book_Btn: "Zarezervujte si kurz",
	Training_centres_booking_code: "Rezervační kód",
	Training_centers_header_bar_title: "Naše školicí střediska",
	Training_centers_filter_item: "Centrum",
	TeamEdit_PersonalAccountToolTop: "Povolení této funkce umožní zaměstnanci přístup k této webové stránce. Uživatel si bude moci vytvořit osobní účet a být součástí vašeho firemního účtu. Po aktivaci můžete zaměstnanci poslat pozvánku s aktivačním kódem. Pozvánka bude zaslána na e-mailovou adresu.",
	TeamEdit_Update: "Povolením funkce správce společnosti automaticky poskytnete tomuto zaměstnanci úplný přístup k vašemu firemnímu účtu. Povolení této funkce vyžaduje, aby se uživatel odhlásil a vrátil se na web, pokud je již přihlášen.",
	TeamEdit_PersonalAccount: "Povolte osobní webový účet Bosch Training Solutions",
	TeamEdit_SendInvitation: "Poslat pozvánku",
	TeamEdit_Deactivate: "Deaktivovat",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobil musí být čísla",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon musí obsahovat čísla",
	TeamEdit_Activate: "Aktivní",
	TeamEdit_NotActivated: "Neaktivovat",
	TeamEdit_StatusPopup: "Stav se zobrazí jako „Aktivní“, pokud uživatel úspěšně vytvořil osobní účet. Pokud je stav nastaven jako ‚Neaktivní‘, znamená to, že uživatel nedokončil vaši pozvánku.",
	Training_Catalog_Courses: "Kurzy",
	Training_Catalog_Course: "Chod",
	Reset_Filter: "Resetovat filtr",
	TrainingCatalogue_CourseCatalogue: "Katalog kurzů",
	CheckoutHeader_CartItemCount_Text: "V nákupním košíku máte [count] položku",
	CheckoutHeader_CartItemsCount_Text: "V nákupním košíku máte [count] položek",
	ShoppingCart_PriceItem: "Cena položky",
	ShoppingCartItemParticipants_AddParticipant_Button: "Přidejte účastníky",
	ShoppingCartTotalPrice_SubTotalLabel: "Mezisoučet",
	ShoppingCartTotalPrice_TaxesLabel: "Daně",
	ShoppingCartTotalPrice_TotalLabel: "Celkový",
	ShoppingCartTotalPrice_IncludedVATLabel: "Včetně DPH ve výši [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Dostupné online platební metody",
	ShoppingCartItemRow_VenueFeeLabel: "Poplatek za místo [fee_amount] na účastníka",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Přidat účastníka",
	AddParticipantsModal_SelectEmployeeLabel: "Přidat účastníka",
	AddParticipantsModal_SelectWorkshopLabel: "Vyberte Workshop",
	ShoppingCartTotalPrice_ContinueButtonText: "Pokračujte k pokladně",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Účastník nebyl přidán!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "V nákupním košíku máte jeden nebo více kurzů bez účastníka.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Chcete-li pokračovat v placení, přidejte účastníka.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Upravit účastníky ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "název",
	AddParticipantsModal_ParticipantTable_Email: "E-mailem",
	AddParticipantsModal_ParticipantTable_Action: "Akce",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Žádní účastníci…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-mailová adresa není u vybraného účastníka nakonfigurována",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Vybraný účastník nemá ve svém profilu nakonfigurovanou e-mailovou adresu. E-mailová adresa je povinná pro přihlášení do kurzu.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Chcete-li pokračovat, přidejte e-mailovou adresu nebo zrušte výběr jiného účastníka.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Přidat e-mail",
	CancelButton: "zrušení",
	AddParticipantsModal_AddEmployeeButtonText: "Přidat zaměstnance",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Chystáte se smazat kurz z košíku",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Pokoušíte se z košíku odebrat kurz s přidanými účastníky.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Chcete-li pokračovat, klikněte na \"Pokračovat\" nebo klikněte na \"Zrušit\".",
	ShoppingCartItemHeader_Participants: "Účastníci",
	ShoppingCartItemHeader_ParticipantPrice: "Cena za sedadlo",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Zobrazit v [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Zobrazit v kreditech",
	ShoppingCart_CreditsPriceUnit: "Kredity",
	CheckOutHeader_Step1_Question: "Jak byste rád platil?",
	CheckOutHeader_Step2_Question: "zkontrolujte svou objednávku",
	CheckOutHeader_Step3_Question: "Dokončete objednávku",
	CheckOutHeader_Step4_Question: "Vaše rezervace byla úspěšná!",
	CheckOutHeader_Step_Payment: "Způsob platby",
	CheckOutHeader_Step_Review: "Posouzení",
	CheckOutHeader_Step_Place_Order: "Objednejte si",
	CheckOutRightPanel_Review_Order_Btn: "Zkontrolovat objednávku",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Objednejte si",
	CheckoutRightPanelContent_OrderSummary_Title: "Přehled objednávky",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Upravit nákupní košík",
	ShoppingCart_Participants: "Účastníci",
	CheckOutStepPayment_Payment_Methods_Title: "Vyberte způsob platby",
	CheckOutStepPayment_Credit_Title: "Kreditní nebo debetní",
	CheckOutStepPayment_Wholesaler_Title: "Velkoobchodník",
	CheckOutStepPayment_ServiceCredit_Title: "Servisní kredity",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Máte [available_credit] kredity",
	CheckOutStepPayment_BoschAccount_Title: "Účet Bosch",
	Checkout_Second_PaymentMethod_Headline: "Způsob platby",
	CheckOutStepReview_Edit: "Upravit",
	CheckOutStepReview_Terms_Label: "Smluvní podmínky a storno podmínky",
	CheckOutStepReview_Terms_Text: "Přečetl(a) jsem si [smluvní podmínky a storno podmínky] prodeje, rozumím jim a souhlasím s nimi.",
	SelectSecondPaymentMethodModal_Title: "Vyberte sekundární platební metodu",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Zaplatit [amount]",
	StepPaymentWholesaler_Title: "Vyberte velkoobchodníka",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Přijměte smluvní podmínky",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Než budete pokračovat, přečtěte si a přijměte naše smluvní podmínky a storno podmínky.",
	CheckoutDescription_ThankYou: "Děkujeme za odeslání objednávky na řešení Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Naše bity a bajty již začaly zpracovávat vaši objednávku. Mezitím mějte motor na volnoběh.",
	CheckoutDesctiption_Text: "Konečné potvrzení vám zašleme e-mailem se všemi podrobnostmi vaší rezervace.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Chystáte se odstranit vybranou dílnu",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Odebráním vybraného workshopu budou všichni přidaní účastníci resetováni. Tato změna se dotkne všech kurzů ve vašem nákupním košíku.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Chcete-li pokračovat, klikněte na \"Pokračovat\", nebo klikněte na \"Zrušit\" pro zastavení této akce.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Nemáte dostatek kreditů. Váš zůstatek kreditu je [available_credit] kreditů, ale pro pokračování v placení potřebujete kredity [cart_total_credits]. Vyberte prosím jiný způsob platby nebo odeberte položky z nákupního košíku.",
	Training_centers_filter_items: "Střediska",
	Training_Programs_Filter_Item: "Programy",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "rok(y)",
	Training_Program_Detail_Title: "Programy školení",
	AccountButton_Workshops_MenuItem: "Workshopy",
	AccountButton_Wallet_MenuItem: "Peněženka",
	Header_MyBoschProfile_Header: "Profil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Dostupný",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Přidat/upravit účastníka",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "Vč. DPH",
	TrainingCatalogue_Length: "Délka",
	TrainingCatalogue_Method: "Metoda",
	Course_Price_Credits: "Kredity",
	Catalogue_Grid_Register_Button: "Registrovat",
	Catalogue_Credit_Tilte: "Kliknutím sem zobrazíte ceny v kreditech",
	Catalogue_Price_Tilte: "Kliknutím sem zobrazíte ceny v EUR",
	Catalogue_Grid_Vat: "bez DPH",
	EventCalendar_Seats: "Volná místa",
	MenuHeader_Calendar: "Kalendář",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minut",
	ListFilter_NoResult: "Žádný výsledek",
	Course_Description: "Popis kurzu",
	Training_course_detail: "Základy sociálních médií",
	Training_course_detail_add_waiting: "Přidat do obecné čekací listiny",
	Register_now_btn: "Zaregistrujte se nyní !",
	Register_now_btn_add_event_to_cart: "Zaregistrujte se nyní !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Zobrazit všechny záznamy",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Zobrazit méně",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Zobrazit všechny záznamy",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Zobrazit méně",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Zobrazit všechny záznamy",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Zobrazit méně",
	TeamCreate_TitleError: "Název je povinný",
	Dashboard_Headerbar_BookCourse: "Rezervovat školení",
	Dashboard_Headerbar_ExitProxy: "Ukončit proxy",
	Dashboard_Headerbar_EvaluateCourse: "Vyhodnotit kurz",
	Dashboard_Headerbar_EvaluateCourse_Note: "Vyhodnoťte prosím tento kurz, abyste získali certifikát!",
	Dashboard_Headerbar_DownloadCertificate: "Stáhnout certifikát",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Zrušit",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Zrušit",
	Dashboard_Headerbar_WelcomeMessage: "Vítejte na vašem přehledu školení",
	Dashboard_Headerbar_ProxingAs: "Proxy jako",
	Dashboard_TrainingProgram_Status_Completed: "Dokončeno",
	Dashboard_TrainingProgram_Status_InProgress: "Probíhá",
	External_SelectWholesalerModal_Title: "Přidejte přístup externího uživatele",
	External_SelectWholesalerModal_Company_Label: "Společnost",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Prosím vyberte",
	External_SelectWholesalerModal_Outlet_Placeholder: "Prosím vyberte",
	External_SelectWholesalerModal_ApplyButton: "Aplikovat",
	External_SelectWholesalerModal_CancelButton: "zrušení",
	External_RemoveWholesalerModal_ConfirmLabel: "potvrzení",
	External_SelectWholesalerModal_ConfirmMessage: "Opravdu chcete tohoto velkoobchodníka odstranit?",
	External_SelectWholesalerModal_YesButton: "Ano",
	External_SelectWholesalerModal_NoButton: "Ne",
	Training_program_team_member: "Člen týmu",
	Training_program_assign_training_program: "Přiřadit tréninkový program",
	Assign_modal_student_confirmation_text1: "Chystáte se přiřadit [Training Program Name] ke své cestě učení.",
	Assign_modal_student_confirmation_text2: "Chcete pokračovat?",
	MyFullCalendar_Jan: "Leden",
	MyFullCalendar_Feb: "Únor",
	MyFullCalendar_Mar: "Březen",
	MyFullCalendar_Apr: "Duben",
	MyFullCalendar_May: "Květen",
	MyFullCalendar_Jun: "Červen",
	MyFullCalendar_Jul: "Červenec",
	MyFullCalendar_Aug: "Srpen",
	MyFullCalendar_Sep: "Září",
	MyFullCalendar_Oct: "Říjen",
	MyFullCalendar_Nov: "Listopad",
	MyFullCalendar_Dec: "Prosicec",
	MenuHeader_CourseCatalog: "Katalog kurzů",
	MenuHeader_Event: "Kalendář akcí",
	MenuHeader_Program: "Tréninkový program",
	MenuHeader_Services: "Školicí služby",
	MenuHeader_Log_out: "Odhlásit se",
	Filter_Location: "Umístění",
	TeamEdit_UpgradeToCompanyAdministrator: "Upgradujte na správce společnosti",
	TeamEdit_PersonalWebAccountStatusIs: "Stav osobního webového účtu je",
	Header_CreateNewEmployee: "Vytvořit nového zaměstnance",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Nejsou k dispozici žádná místa!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Na vybrané události nejsou žádná volná místa.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Chcete-li pokračovat, vyberte jinou událost nebo odeberte již přidaného účastníka.",
	Common_SuccessModal_Title: "Gratulujeme!",
	TrainingProgramDetails_Merit_StatusText: "Za zásluhy",
	TrainingProgramDetails_Resit_StatusText: "Znovu",
	EmployeeProfile_UserExistErrorMessage: "Účet již existuje!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Platba selhala. Zkuste to znovu nebo použijte jinou platební metodu.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Úspěšně jste deaktivovali vybraného zaměstnance!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Uložené!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Vaše změny byly uloženy.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "U eLearningových kurzů není výběr možný. V případě potřeby dalších podrobností nás prosím kontaktujte.",
	Dashboard_Team_PersonalAccount_Pending: "čekající",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Zaměstnanec vytvořen!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Vaše vytvoření zaměstnance bylo úspěšně dokončeno.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Toto nastavení umožňuje omezit registraci zaměstnance na školení. Pokud toto nastavení nebude vybráno, budou moci zaměstnance přihlásit na školení pouze správci společnosti.",
	Training_catalog_sort_by: "Seřazeno podle",
	CourseDetail_EvaluationRestriction_Title: "Vyhodnocení školení není možné!",
	CourseDetail_EvaluationRestriction_Message: "Hodnocení školení může zadávat pouze student sám. \nPožádejte studenta, aby se přihlásil do Bosch Automotive Training Solutions a dokončil hodnocení školení.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Pokud student ještě nemá účet, pozvánku lze sdílet v nastavení uživatelského profilu člena týmu.",
	Breadcrumb_Download_Page: "Ke stažení",
	Download_Table_Category_Cell: "Kategorie",
	Download_Table_Title_Cell: "Titul",
	Download_File_Filter_Item: "Soubor",
	Download_File_Filter_Items: "Soubory",
	Download_Header: "Oblast stahování",
	DownloadArea_Button_Download: "Stažení",
	Popup_Title_Error: "Chyba",
	Popup_Title_Success: "Úspěch",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Varování!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Na základě již existujících potenciálních přihlášek na akce nebudou zrušeny a budou muset být zrušeny jednotlivě.",
	TrainingProgram_PDF_Export: "Export PDF",
	TrainingProgram_PDF_Export_Participant: "Účastník:",
	TrainingProgram_PDF_Export_Status: "Postavení:",
	TrainingProgram_PDF_Export_Progress: "Celkový postup:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Název školení",
	TrainingProgram_PDF_Export_TableHeader_Status: "Postavení",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Školní známka",
	TrainingProgram_PDF_Export_TableHeader_Date: "datum",
	AccountButton_CompanyChange_MenuItem: "Změna společnosti",
	SelectCompanyModal_Title: "Vyberte Společnost",
	CloseButton: "Zavřít",
	Change_Button: "Změna",
	ChangeManagerModal_Content: "Vyberte Uživatel",
	ChangeCompanyModal_HeadOffice: "(Hlavní kancelář)",
	SelectCompanyModal_Content: "Pro přihlášení vyberte společnost…",
	AlertSuccess_Title: "Úspěch",
	CheckOutStepPayment_SecondaryPayment_Title: "Vyberte sekundární platební metodu",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Přidejte sekundární platební metodu",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Níže přidejte sekundární platební metodu nebo výše vyberte jinou platební metodu.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Na svém účtu nemáte dostatek servisních kreditů k zaplacení položek v košíku. Chcete-li nadále používat vybranou platební metodu, musíte zaplatit rozdíl. Rozdíl je [difference_amount] (bez DPH).",
	ShoppingCartTotalPrice_TotalInCredit: "Celková cena v kreditech",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Vaše dostupné kredity",
	ShoppingCartTotalPrice_YourDifference: "Rozdíl",
	ShoppingCartTotalPrice_SubtotalExTax: "Mezisoučet bez daň",
	ShoppingCartTotalPrice_SubtotalInTax: "Mezisoučet vč. daň",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundární způsob platby",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Upravit způsob platby",
	Checkout_Second_PaymentMethod: "Druhý způsob platby",
	Breadcrumb_TrainingCourseDetails: "Podrobnosti o školení",
	DownloadArea_FailedToDownload_ErrorMessage: "Nepodařilo se stáhnout dokument. Zkuste to znovu nebo požádejte o pomoc správce společnosti.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Stáhnout soubor není k dispozici. Zkuste to znovu nebo požádejte o pomoc správce společnosti.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Stahování souboru je zakázáno. Zkuste to znovu nebo požádejte o pomoc správce společnosti.",
	SSO_Redirecting_Message: "Přesměrováváte se na portál Bosch Training...",
	SSO_Redirecting_ParameterErrorMessage: "Země a/nebo jazyk nejsou specifikovány. Zkuste to znovu nebo požádejte o pomoc správce společnosti.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Žádost(y) o registraci nebyla přijata.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "To může být způsobeno tím, že se na jeden nebo více kurzů nacházíte v rámci limitu pro registraci kurzu. Pokud máte nějaké dotazy nebo požadavky, použijte oblast „Kontaktujte nás“.",
	TimeSlotPicker_DateFormat_Title: "Datový formát",
	UpdateAccountSettingsConfirmationModal_Title: "Chystáte se změnit formát data!",
	UpdateAccountSettingsConfirmationModal_Message1: "Formát data se zobrazí podle vašeho výběru a lze jej kdykoli změnit.",
	UpdateAccountSettingsConfirmationModal_Message2: "Přejete si pokračovat?",
	ProfileSummary_Settings_Title: "Nastavení",
	UpdateAccountSettings_DateFormat_Title: "Nastavení formátu data",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nepodařilo se aktualizovat formát data.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Nastavení formátu data bylo úspěšně aktualizováno.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Stáhnout certifikát",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certifikát vzdělávacího programu",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Nepodařilo se stáhnout dokument. Zkuste to znovu nebo požádejte o pomoc administrátora ve vaší společnosti.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Překročena hranice výběru",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Hranice pro odstoupení z tohoto kurzu byla překročena a vyžaduje ruční zpracování naší školicí administrativou.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Konečné potvrzení bude zasláno e-mailem.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Další podrobnosti naleznete v našich [zásadách zrušení].",
	TrainingCourse_Course_NotAvailable_Title: "Školicí kurz není k dispozici.",
	TrainingCourse_Course_NotAvailable_Message: "Školicí kurz není k dispozici. Zkuste to znovu nebo požádejte o pomocadministrátora ve vaší společnosti.",
	TrainingCourse_NotAvailable_Message_1: "Vstupujete do školícího kurzu, který není dostupný.",
	TrainingCourse_NotAvailable_Message_2: "Zkuste to znovu nebo přejděte na [školící kurzy] a najděte další školicí kurzy.",
	LanguageSelection_SelectALanguage: "Vyberte jazyk",
	LanguageSelection_SelectYourCountry: "Vyberte vaší zemi",
	Checkout_PaymentMethod_Headline: "Způsob platby",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalendář",
	Download_Categories_General: "Všeobecné",
	Download_Categories_Information: "Informace",
	Download_Categories_Userguides: "Uživatelské příručky",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "zasady anulowania",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Anulować",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Kontynuować",
	Duration_Seconds: "Vteřiny",
	Duration_Day: "Den",
	Duration_Hour: "Hodina",
	Duration_Minute: "Minuta",
	Duration_Second: "Druhý",
	EventCalendar_ExternalLink_Tooltips: "Nákup přes [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Zrušit",
	EventCalendar_ExternalLink_Continue: "Pokračovat",
	EventCalendar_ExternalLinkModal_Title: "Budete přesměrováni...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Vybraný přenos se prodává prostřednictvím [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Při pokračování budete přesměrováni na externí webovou stránku.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Chcete pokračovat?",
	Team_ContactPerson_FirstName_Tooltips: "Jména účastníků může upravovat pouze tým správy školení Bosch. Pro další podporu nás prosím kontaktujte prostřednictvím kontaktního formuláře na této webové stránce.",
	Team_ContactPerson_LastName_Tooltips: "Jména účastníků může upravovat pouze tým správy školení Bosch. Pro další podporu nás prosím kontaktujte prostřednictvím kontaktního formuláře na této webové stránce.",
	Withdraw_ExternalLink_Cancel: "Zrušit",
	Withdraw_ExternalLink_Confirm: "Potvrdit",
	Withdraw_ExternalLinkModal_Title: "Budete přesměrováni",
	Withdraw_ExternalLinkModal_Message: "Výběry u tohoto kurzu vyřizuje externí poskytovatel.",
	Withdraw_ExternalLinkModal_Confirmation: "Kliknutím na \"Potvrdit\" se otevře nová karta a přesměruje vás na externí web.",
	Button_AddToWaitingList_Tooltips: "Přidat na čekací listinu",
	Button_AddToCart_Tooltips: "Přidat do košíku",
	Button_TrainingCourseDetail_Tooltips: "Zobrazit podrobnosti",
	Button_TrainingProgramDetail_Tooltips: "Zobrazit podrobnosti",
	Button_AssignTrainingProgram_Tooltips: "Přiřadit tréninkový program",
	Button_DeleteFromCart_Tooltips: "Smazat z košíku",
	Button_RemoveParticipant_Tooltips: "Odebrat účastníka",
	Button_DownloadBillingTransaction_Tooltips: "Stáhnout, uložit",
	Button_RemoveExternal_Tooltips: "Odstranit"
};