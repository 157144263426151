export const TRANSLATIONS_BG = {
	LandingPage_Title: "Добре дошли в  Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Изберете Вашата страна/регион",
	Header_ContactUs: "Свържете се с нас",
	Header_Search: "Търсене",
	Header_Menu: "Меню",
	Header_LogInOrCreateYourAccount: "Вход или създаване на акаунт",
	Header_LogIn: "Вход",
	Header_Cart: "Количка",
	Header_ShoppingCart: "Пазарска количка",
	Header_LogOut: "Изход",
	Header_ChangeDetails: "Промяна на детайлите",
	Footer_ExploreOurTraining: "Запознайте се с нашите обучения",
	Footer_TrainingAtYourFingertips: "Обучения на една ръка разстояние",
	Footer_DontMissAnyStory: "Не пропускайте нито една история",
	Footer_ExploreOurStories: "Разгледайте нашите истории",
	Footer_GetInTouchWithUs: "Свържете се с нас",
	Footer_SendUsAMessage: "Изпратете ни съобщение",
	Footer_ToContactForm: "Към формуляра за контакт",
	Footer_InventedForLife: "Техника за живота",
	Footer_GeneralContactInfo: "Обща информация за контакт",
	Footer_ProductSecurity_Psirt: "Сигурност на продуктите (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Патенти и лицензи за иновации",
	Footer_PurchasingLogistics: "Доставчици и логистика",
	Footer_CorporateInformation: "Корпоративна информация",
	Footer_LegalNotice: "Правна информация",
	Footer_DataProtectionNotice: "Декларация за защита на данните",
	Footer_PrivacySettings: "Настройки за поверителност",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Роберт Бош ЕООД, 2022, Всички права запазени",
	Footer_StillLookingForSomething: "Все още търсите нещо",
	Footer_TrainingServices: "Обучения",
	Footer_Courses: "Курсове",
	Footer_Programs: "Програми",
	Footer_EventsCalendar: "Календар с обучения",
	Footer_TrainingCenters: "Центрове за обучение",
	Footer_Dashboard: "Табло",
	Footer_MyDashboard: "Моето табло",
	Footer_ShoppingCart: "Количка за пазаруване",
	Footer_YourShoppingCart: "Вашата количка за пазаруване",
	Footer_Administration: "Администрация",
	Footer_Employees: "Служители",
	Footer_Wholesalers: "Търговци на едро",
	Footer_Transactions: "Транзакции",
	Footer_Workshops: "Сервизи",
	Footer_WorkLikeABoschApplyNow: "Търсите работа в Бош - Кандидатствайте сега.",
	Footer_BoschGlobalCareers: "Кариера в Бош",
	Footer_PurchasingAndLogistics: "Доставчици и логистика",
	Footer_BecomeABusinessPartner: "Станете бизнес партньор.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Как нашите обучения ще помогнат на Вашия бизнес",
	Footer_Language: "Изберете държава",
	MenuPopup_MyCart: "Моята количка",
	Stories_OurTrainingStories: "Нашите истории за обучения",
	Stories_ConnectingExperts: "Свързване на експерти и ентусиасти, на мечтатели и изпълнители, на новатори и инфлуенсъри - изследвайте и изживейте",
	Stories_TopStories: "Топ истории",
	Stories_AtHome: "Начало",
	Stories_Careers: "Кариери",
	Stories_History: "История",
	Stories_IndustryAndTrades: "Отрасли и специалности",
	Stories_Mobility: "Мобилност",
	Stories_Research: "Изследователска дейност",
	Stories_Sustainability: "Устойчивост",
	Stories_AllStories: "Всички истории",
	Stories_BlogATrainersDay: "БЛОГ | Един ден на треньора",
	Stories_ADayinLifeOfATrainer: "Един ден от живота на треньора",
	Stories_BoschServiceTraining: "Сервизни обучения на Бош",
	Stories_StoryWhatDrivesYouDrivesUs: "ИСТОРИЯ | Каквото задвижва вас, задвижва и нас",
	Stories_StoryHowCanWeBenefitYourBusiness: "ИСТОРИЯ | Как можем да подкрепим Вашия бизнес",
	Stories_HowOurTrainingEvolves: "Как се усъвършенстват нашите обучения",
	Stories_AModernTrainingApproach: "Светът на онлайн обученията",
	Stories_StoryAVirtualOnlineTrainingWorld: "ИСТОРИЯ | Вашите възможности за онлайн обучение",
	Stories_SubscribeToRSSFeed: "Абонирайте се за RSS (новини)",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "За нас",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Свържете се с нас",
	Homepage_LatestNews: "Вижте защо ни мотивира жаждата за знания",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Отворени позиции",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Каквото задвижва вас, задвижва и нас",
	BoschServiceTraining_BoschServiceTraining: "Сервизни обучения на Бош",
	BoschServiceTraining_ShareThisOn: "Споделете това с",
	BoschServiceTraining_TheChallenge: "Предизвикателството: Овладяване на нови технологии",
	BoschServiceTraining_WorldwidePresence: "Глобално присъствие",
	BoschServiceTraining_TechnicalTraining: "Технически курсове за обучение от Бош",
	BoschServiceTraining_TheOffersAndOpportunities: "Офертите и възможностите, които предлагаме в Бош",
	BoschServiceTraining_TechnicalCoursesDesc: "Има подходящи курсове за обучение за всяка целева група - независимо дали сте начинаещ или професионалист. Офертата включва еднодневни и няколкодневни курсове. Предлагат се обучения в различни технически области и серии от курсове (напр. бензиново и дизелово впръскване, електрически системи и т.н.). В техническите обучения Бош предоставя обширно ноу-хау за всички стандартни автомобилни системи на Бош и други производители. Съдържанието е специално съобразено с изискванията на сервизните техници.",
	BoschServiceTraining_TrainedEmployeesDesc: "Обучените служители носят очевидни предимства на сервизите, защото модерните автомобили стават все по-комплексни и новите технологии постоянно поставят нови предизвикателства. Само експертизата дава възможност на сервизните техници да извършват ефективна и рентабилна диагностика, поддръжка и ремонт на актуалните модели автомобили.",
	BoschServiceTraining_RegardlessOfHow: "Колкото и добри и опитни да са автомобилните специалисти - винаги има ситуации, в които те ще влязат в контакт с новите технологични системи на автомобила или ще се натъкнат на непознати дефекти. В тези случаи отстраняването на неизправности и решаването на проблема може да отнеме много време и е вероятно да не се получат успешни резултати. В най-лошия случай клиентите трябва да останат без автомобила си значително по-дълго от очакваното време.",
	BoschServiceTraining_ItIsPrecisely: "Ето защо са нужни техническите обучения на Бош: за повишаване качество на обслужване и  ускоряване на процесите. Актуалните обучения на Бош помагат за по-бързо локализиране на проблемите, по-бързи ремонти и намаляване на престоя на автомобила в сервиза. Така системните процедури, на които се обучавате в Бош, гарантират по-бърза и по-продуктивна работа.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Повече от 125 години Бош е предпочитан доставчик на компоненти и системи за оригинално оборудване за много от водещите производители на автомобили по света. Тази експертиза е комбинирана с уникални познания за най-новите технологии, които са в основата на поддръжката от Бош.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Ще откриете Центровете за сервизно обучение на Бош навсякъде по света: от Испания до Турция, от Южна Африка до Австралия и Бразилия - Бош предлага  практически ориентирани обучения в съответствие с най-съвременните технологии.",
	BoschServiceTraining_AgileCompany: "Гъвкава компания",
	BoschServiceTraining_Collaboration: "Сътрудничество",
	BoschServiceTraining_ConnectedMobility: "Свързана мобилност",
	BoschServiceTraining_ArtificialIntelligence: "Изкуствен интелект",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Диалог на лидерите",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Диагностично оборудване",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Основи на аутомотив технологиите",
	BoschServiceTraining_Icon_Gasoline: "Бензин",
	BoschServiceTraining_Icon_Diesel: "Дизел",
	BoschServiceTraining_Icon_AlternativeDrives: "Алтернативни задвижвания",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Електротехника и електроника",
	BoschServiceTraining_Icon_BodyAndComfort: "Купе и комфортни системи",
	BoschServiceTraining_Icon_ChassisAndBraking: "Шаси и спирачки",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Скоростна кутия и задвижване",
	BoschServiceTraining_Icon_CommercialVehicles: "Товарни автомобили",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Онлайн обучения",
	BoschServiceTraining_Icon_BusinessSkills: "Бизнес умения",
	BoschServiceTraining_Icon_Accreditations: "Акредитации",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Програма с обучения",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Име и фамилия на треньора",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Един ден от живота на треньора",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Всеки ден е ново приключение",
	ADayInTheLifeOfATrainer_Summary: "Резюме",
	ADayInTheLifeOfATrainer_TheDayJourney: "Пътешествието през деня",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Всеки ден е натоварен, но винаги е обогатяващо преживяване. Удоволствието да обучаваме курсистите с мисълта,  че те държат ключа към нашето бъдеще, е отговорност, но в същото време е толкова вълнуващо. Виждаме как участниците израстват с всяко занятие и това е доказателство, че работим за общата цел да увеличим знанията им за модерните технологии, които движат бъдещето!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "В края на всеки ден сядаме и размишляваме върху извършената работа. Дните минават толкова бързо, но това всъщност доказва, че постигнатото е от полза не само за курсистите, но и за нас като треньори.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Можем да се приберем вкъщи доволни, че сме свършили страхотна работа!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Старт",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Подготовка и финална проверка на учебните материали. Проверка на важни имейли",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Обучението започва",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Посрещане на участниците. Представяне на дневния график. Запознаване с групата.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Теоретична част",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Необходимо е активно взаимодействие с групата за да бъде обучението възможно най-интересно. Поставяне на задачи свързани с теоретичната част с цел активно участие на групата в обучението.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Обедна почивка",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Практическа част",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Обучението продължава с практически задачи в сервиза. Треньорът винаги е на разположение за насоки и въпроси.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Резюме",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Следва кратко връщане в класната стая  за обсъждане на обучението до сега. Треньорът отговаря на въпроси. Следва подготовка за следващата тренинг сесия.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Последен ден",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "На участниците се връчват сертификати за участие.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Вкъщи,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Как можем да помогнем за успеха на бизнеса ви",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Как се усъвършенстват нашите обучения",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Ние непрекъснато се развиваме, за да ви гарантираме качествено и актуално обучение",
	BoschTrainingBenefits_TheBoschServiceTraining: "Центърът за сервизно обучение на Бош желае да ви помогне да разширите бизнеса си чрез своята обширна и изключително актуална програма с технически/бизнес обучения и курсове за допълнителна сертифицирана квалификация. На следващите страници ще намерите преглед на нашата програма за обучение, както и актуалните възможности за допълнителна квалификация. С нетърпение очакваме да се срещнем с вас и с Вашите  служители на нашите курсове и семинари.",
	BoschTrainingBenefits_LongStandingExperience: "Дългогодишна експертиза и обширно ноу-хау за всички системи в областта на автомобилните технологии.",
	BoschTrainingBenefits_QuickAndEfficient: "Бързи и ефективни решения за много проблеми.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Предимства и ползи",
	BoschTrainingBenefits_LMS: "Система за управление на обученията - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions покрива пълната гама на автомобилните области. Специализирайте се в конкретни области или разширете компетентността си с предложенията за обучение от Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Придобити знания, оборудване, ремонт, поддръжка и сервиз.",
	BoschTrainingBenefits_TheHighDegree: "Високата степен на практическо съдържание и малките групи гарантират целево ориентирано обучение",
	BoschTrainingBenefits_ParticipationInTraining: "Участието в обучения повишава мотивацията и удовлетворението на служителите, а допълнителната квалификация убеждава клиентите",
	BoschTrainingBenefits_IncreasedSales: "Увеличени продажби благодарение на познаването на системите на различни марки автомобили",
	BoschTrainingBenefits_MethodicalProcedures: "Методичните процедури намаляват времето за чакане на клиентите",
	BoschTrainingBenefits_HighlyQualified: "Висококвалифицираните треньори преподават систематично процедурите",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Намиране на най-подходящите обучения за вас и за Вашия бизнес",
	BoschTrainingBenefits_DirectOnlineBooking: "Директни онлайн резервации и управление на курсовете за Вас или за Вашите служители",
	BoschTrainingBenefits_OnlineAccess: "Онлайн достъп до учебната документация",
	BoschTrainingBenefits_AccessToYourOwn: "Достъп до Вашата лична история на обучение и сертификати",
	BoschTrainingBenefits_ToolsForManagement: "Инструменти за управление на кариерното ви развитие",
	BoschTrainingBenefits_ManagerHasFull: "Мениджърът има пълен преглед на курсовете на  служителите в системата",
	BoschTrainingBenefits_Access24hours: "Достъп 24 часа 7 дни в седмицата",
	ModernTrainingApproach_KnowledgeIsKey: "Знанията са ключов фактор за успеха на всеки сервиз. Ето защо Бош предлага цялостна програма за професионално обучение. Това дава възможност на курсистите да придобият подходящите знания в подходящото време и място - знания, необходими за професионалната поддръжка и ремонт на автомобилa. Бош добави онлайн обучения, като нов модул - част от предлагания пакет за електронно обучение. Това са сесии от сервизни обучения, предназначени специално за стрийминг.",
	ModernTrainingApproach_ANewLearning: "Нова възможност за обучение",
	ModernTrainingApproach_YourOnlineTraining: "Вашите възможности за онлайн обучение",
	ModernTrainingApproach_RecentlyTheWorld: "Напоследък светът се промени, вижте какво правим, за да Ви помогнем",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Светът на онлайн обучението",
	ModernTrainingApproach_NewTeachingMethod: "Нов метод за обучение",
	ModernTrainingApproach_InAdditionTo: "В допълнение към съществуващото присъствено обучение, включихме в програмата и онлайн обучение с излъчване на живо. Нашият треньор с обширни теоретични познания е на Ваше разположение  във виртуалната класна стая и с практически примери. Както обикновено, ще получите много информация за автомобилните системи и за съответните продукти на Бош. Разбира се, имате възможност и да задавате въпроси. Ще получите отговорите директно в предаването на живо.  Необходима е стабилна интернет връзка. Потърсете подходящото онлайн обучение в нашия каталог с обучения, като въведете \онлайн обучение\ в полето за търсене.",
	ModernTrainingApproach_BoschWebcastingHeader: "Онлайн обучение на Бош: Иновативно и практично",
	ModernTrainingApproach_BoschWebcastingText: "За да се включи в онлайн обучение на Бош, участникът първо трябва да набере кода. Индивидуален код за достъп се изпраща преди сесията, за да се даде възможност за влизане. До 100 участници от произволен брой локации могат да участват едновременно. Обучението се води от треньор и въпросите могат да се изпращат по всяко време чрез функцията за чат на живо.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Дигитално допълнение към обучението в класната стая",
	ModernTrainingApproach_TheDigitalSupplementText: "Онлайн и обучението в класната стая се допълват перфектно. И в двата случая обучението се води от квалифициран треньор. Онлайн обучението е подходящо за теми, които могат да се преподават дигитално, без да е необходимо да се работи върху автомобила. Например, запознаване с някои функции на ESI[tronic] за новите системи за подпомагане на водача. Когато става въпрос за ефективна екипна работа в сервиза или за практически задачи по автомобилната техника, присъственото обучение си остава първия избор.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Стриймингът може да е по-евтин",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Разходите на участника обикновено са по-ниски при онлайн обучение, отколкото при сравним присъствен курс. Причината е, че повече участници могат да се възползват, а и усилията, свързани с обучението, са значително по-малко (без пътуване до и от мястото и отнема по-малко време).",
	ModernTrainingApproach_BookAndProfitHeading: "Резервирай и спечели",
	ModernTrainingApproach_BookAndProfitText: "Онлайн обучения могат да бъдат резервирани на нашия уебсайт, също като другите ни курсове за обучение.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Сравнение на онлайн и обучение в класна стая",
	ModernTrainingApproach_Webcasting: "Онлайн обучение",
	ModernTrainingApproach_Classroom: "Класна стая",
	ModernTrainingApproach_WebcastingP1: "До 100 участници могат да участват едновременно от всяка точка на света",
	ModernTrainingApproach_WebcastingP2: "Ниска сложност и относително ниски разходи, не е необходимо пътуване",
	ModernTrainingApproach_WebcastingP3: "Идеален за конкретни и кратки индивидуални теми",
	ModernTrainingApproach_WebcastingP4: "Сервизите могат да провеждат онлайн обучения за своите служители навсякъде и по всяко време",
	ModernTrainingApproach_WebcastingP5: "Необходимо е 50-70% по-малко време, отколкото за обучения в класната стая",
	ModernTrainingApproach_ClassroomTrainingP1: "До 16 участници се срещат едновременно",
	ModernTrainingApproach_ClassroomTrainingP2: "Пътни разходи и като цяло по-високи разходи на човек",
	ModernTrainingApproach_ClassroomTrainingP3: "Идеално, когато става въпрос за оптимизиране на екипната работа или за учене директно върху автомобил",
	ModernTrainingApproach_ClassroomTrainingP4: "Практически упражнения и взаимодействие с треньора",
	ModernTrainingApproach_ClassroomTrainingP5: "Треньорът може по-добре да адаптира знанията за преподаване според действителните нужди на участниците",
	ModernTrainingApproach_OnlineTrainingOfferings: "Предложения за уеб-базирано обучение (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Днес самостоятелното обучение на компютър е неразделна част от трансфера на знания. Свободата да избирате времето и мястото, неограничената повторяемост, индивидуалната скорост на учене и интерактивното учебно съдържание правят този метод незаменим инструмент за обучение.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Всеки елемент от уеб-базираното обучение (WBT) е внимателно структуриран. Освен че предоставят широка база от знания, тези курсове могат да послужат като ефективна подготовка за обучителните програми в Центровете за обучение на Бош.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Виртуалната диагностична симулация предлага на потребителя ролеви игри с ежедневни ситуации в сервиза, напр. работни процеси при отстраняване на дефекти с помощта на диагностични тестери на Бош  във виртуален сервиз.",
	ModernTrainingApproach_Advantages: "Предимства",
	ModernTrainingApproach_AdvantagesP1: "Бърз и ефективен метод за предоставяне на знания",
	ModernTrainingApproach_AdvantagesP2: "Разнообразно обучение",
	ModernTrainingApproach_AdvantagesP3: "Може да се използва толкова често, колкото желаете",
	ModernTrainingApproach_AdvantagesP4: "Минимално време и пари",
	ModernTrainingApproach_AdvantagesP5: "Индивидуална скорост на учене",
	ModernTrainingApproach_AdvantagesP6: "Гъвкаво време за обучение",
	ModernTrainingApproach_AdvantagesP7: "Повторяемост",
	ModernTrainingApproach_AdvantagesP8: "Няма пътни разходи",
	ModernTrainingApproach_AdvantagesP9: "Няма проблеми с капацитета или графика",
	Dashboard_CoursesViewAndManageYourCourses: "КУРСОВЕ | Преглед и управление на Вашите курсове",
	Dashboard_MyCourses: "Моите курсове",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "ПРОГРАМА ЗА ОБУЧЕНИЕ | Преглед и управление на Вашите програми за обучение",
	Dashboard_MyTrainingProgram: "Моята програма за обучение",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "Е-ОБУЧЕНИЕ | Преглед и управление на Вашето портфолио за електронно обучение",
	Dashboard_MyELearning: "Моето електронно обучение",
	Dashboard_AccountManageYourAccount: "АКАУНТ | Управление на Вашия акаунт",
	Dashboard_MyAccount: "Моят акаунт",
	Dashboard_MyDashboard: "Моето табло",
	Dashboard_ManageYourAccount: "Управление на Вашия акаунт",
	Dashboard_Workshops: "Сервизи",
	Dashboard_AdministrationManageYourWorkshop: "АДМИНИСТРАЦИЯ | Управление на Вашия сервиз",
	Dashboard_AdministrationManageYourTeams: "АДМИНИСТРАЦИЯ | Управление на Вашите екипи",
	Dashboard_Administration: "Администрация",
	Dashboard_ViewYourCourses: "Преглед на Вашите курсовете",
	Dashboard_ViewYourTrainingPrograms: "Преглед на Вашите програми за обучение",
	Dashboard_TrainingPrograms: "Програми за обучение",
	Dashboard_ViewAndEditYourDetails: "Преглед и редактиране на Вашите данни",
	Dashboard_ViewYourELearningCourses: "Преглед на Вашите курсове за електронно обучение",
	Dashboard_Pending: "Изчакващ",
	Dashboard_RequiresAdministratorSignOff: "(изисква се приключване от администратор)",
	Dashboard_HistoricalRecords: "История на записите",
	Dashboard_Registered: "Регистриран",
	Dashboard_WaitingList: "Списък с чакащите",
	Dashboard_RecommendedForYou: "Препоръчано за вас",
	Dashboard_AccountDetails: "Данни за акаунта",
	Dashboard_ChangeProfileImage: "Промяна на профилната снимка",
	Dashboard_Title: "Обръщение",
	Dashboard_Role: "Позиция",
	Dashboard_FirstName: "Име",
	Dashboard_LastName: "Фамилия",
	Dashboard_Telephone: "Телефон",
	Dashboard_Mobile: "Мобилен телефон",
	Dashboard_Email: "Електронна поща",
	Dashboard_Language: "Eзик",
	Dashboard_Save: "Запазване",
	Dashboard_Student: "Курсист",
	Dashboard_WorkshopTrainingAdministrator: "Администратор (сервиз) обучения",
	Dashboard_WholesalerTrainingAdministrator: "Администратор (търговец) обучения",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Администрация на компанията",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Управление на Вашите сервизи...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Управление на Вашите служители...",
	Dashboard_CompanyAdministration_Workshop: "Сервиз",
	Dashboard_CompanyAdministration_Address: "Адрес",
	Dashboard_CompanyAdministration_Zip: "Пощенски код",
	Dashboard_CompanyAdministration_City: "Град",
	Dashboard_CompanyAdministration_Manage: "Управление",
	Dashboard_AdministrationSection_AdministrationTitle: "Администрация",
	Dashboard_AdministrationSection_ManageTheTeam: "Управление на екипа",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "АДМИНИСТРАЦИЯ | Управление на Вашите служители",
	Dashboard_AdministrationSection_Employees: "Служители",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "АДМИНИСТРАЦИЯ | Управление на Вашите търговци",
	Dashboard_AdministrationSection_Wholesalers: "Търговци на едро",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "АДМИНИСТРАЦИЯ | Управление на Вашите транзакции",
	Dashboard_AdministrationSection_Transactions: "Транзакции",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Администрация на служителите",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Управление на Вашите служители...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Регистриране",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Записи",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Редактиране",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Служител",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Добавяне",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Създаване",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Регистриран",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Изчакващ",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(изисква се приключване от администратор)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "История на записите",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Списък с чакащите",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Актуален списък на служителите",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Управление на служителите",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Име",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Акаунт на служителя",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Преглед и редактиране на данните за служителя...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Настройки",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Разрешаване на личен акаунт",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Надстройка на прокси администриране на сервизния служител",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Разрешаване на служителя да се регистрира за обучения",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Изпращане на покана по имейл",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Деактивиране на акаунта",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Запазване",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Изпращане",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Редактиране",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Управление на търговците",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Управление на Вашите търговци...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Списък на одобрените търговци",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Име",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Изтриване",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Създаване",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Добавяне",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Създаване на акаунт на нов служител",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Добавете данни за новите служители...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Добавяне на нов одобрен администратор търговци",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Въведете данни за нови търговци на едро...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Изберете одобрен търговец от падащото меню...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Транзакции",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Преглед и управление на Вашите транзакции...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Назад",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Номер на фактура",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Описание",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Начин на плащане",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Дата",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Сума",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Изтегляне",
	TrainingCentres_TrainingCentres: "Центрове за обучение",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Имаме центрове за обучение в целия свят",
	TrainingCentres_CountryCategory: "Категория държава",
	TrainingCentres_CityCategory: "Категория град",
	TrainingCentres_Country: "Страна",
	TrainingCentres_City: "Град",
	TrainingCatalogue_TrainingCatalogue: "Каталог с обучения",
	TrainingCatalogue_LearningAtTheHighestLevel: "Обучение на най-високо ниво",
	TrainingCatalogue_StartDate: "Дата на започване",
	TrainingCatalogue_EndDate: "Дата на завършване",
	TrainingCatalogue_TrainingName: "Име на курса",
	TrainingCatalogue_Language: "Език",
	TrainingCatalogue_Days: "Дни",
	TrainingCatalogue_Seats: "Места",
	TrainingCatalogue_Price: "Цена",
	TrainingCatalogue_Location: "Локация",
	TrainingCatalogue_PartNumber: "Артикулен номер",
	TrainingCatalogue_TrainingCourse: "Обучение",
	TrainingCatalogue_Category: "Категория",
	TrainingCatalogue_TrainingCategory: "Категория на обучението",
	TrainingCatalogue_CourseName: "Програма с обучения",
	TrainingCatalogue_TotalDays: "Общо дни",
	TrainingCatalogue_Venue: "Място на провеждане",
	TrainingCatalogue_Details: "Детайли",
	TrainingCatalogue_Prerequisites: "Изисквания",
	TrainingCatalogue_CourseType: "Тип на курса",
	TrainingCatalogue_LearningMethod: "Метод на обучение",
	TrainingCatalogue_AddToWaitingList: "Добавяне към списъка с чакащи",
	ShoppingCart_ShoppingCart: "Пазарска количка",
	ShoppingCart_ReviewAndManage: "Преглед и управление на Вашите абонаменти",
	ShoppingCart_YouHaveAddedItem: "Добавихте следните артикули във Вашата количка",
	ShoppingCart_Item: "Артикул",
	ShoppingCart_Quantity: "Количество",
	ShoppingCart_PricePerItem: "Единична цена",
	ShoppingCart_TotalPrice: "Общо",
	ShoppingCart_VatIsIncluded: "Без ДДС",
	ShoppingCart_Redeem: "Ако нямате кредит, кликнете тук за плащане",
	ShoppingCart_Purchase: "Ако нямате кредит, кликнете тук за покупка",
	ShoppingCart_Checkout: "Към плащане",
	ShoppingCart_ContinueShopping: "Продължeте пазаруването",
	ShoppingCart_TotalPriceInclVat: "Обща цена с вкл. ДДС",
	ShoppingCart_Apply: "Приложи",
	ShoppingCart_ShoppingCartEmpty: "Вашата пазарска количка е празна",
	ShoppingCart_VatIncluded: "С включен ДДС",
	ShoppingCart_ManageYourShopping: "Управление на пазаруването",
	ShoppingCart_Participant: "Участник",
	ShoppingCart_Complete: "Готово",
	ShoppingCart_NetPrice: "Нето цена",
	ShoppingCart_Workshop: "Сервиз",
	CheckOut_PreviewYourOrder: "Преглед на Вашата поръчка",
	CheckOut_YourShoppingCart: "Количка",
	CheckOut_Preview: "Визуализация",
	CheckOut_Payment: "Плащане",
	CheckOut_Summary: "Резюме",
	CheckOut_Back: "Назад",
	CheckOut_PleaseReadAndAgreeOurTerms: "Моля, прочетете и приемете Общите ни условия",
	CheckOut_IHaveReadTheTermsAndCondition: "Прочетох [Общите условия], разбирам ги и ги приемам",
	CheckOut_YesIAgree: "Да - съгласен съм с Общите условия",
	CheckOut_Continue: "Продължи",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Поздравления! Получихме Вашата поръчка",
	CheckOut_OrderConfirmation: "Ще получите окончателно потвърждение на поръчката, след като заявката Ви бъде обработена успешно",
	CheckOut_WeWillContactYouDirectly: "Ще се свържем директно с Вас, ако имаме въпроси във връзка с поръчката Ви",
	CheckOut_AddToCart: "Добави в количката",
	CheckOut_CreditCart: "Кредитна количка",
	CheckOut_DirectDebit: "Директен дебит",
	CheckOut_TrainingCredits: "Кредити за обучения",
	CheckOut_PreferredWholesaler: "Предпочитан търговец",
	EventCalendar_EventCalendar: "Календар с обучения",
	EventCalendar_TrainingEventsForAllYourNeeds: "Обучения за всички Ваши нужди",
	TrainingCourse_TrainingCourse: "Каталог с обучения",
	TrainingCourse_LearningAtTheHighestLevel: "Обучение на най-високо ниво",
	TrainingProgram_Title: "Каталог с програма за обучения",
	TrainingProgram_SubTitle: "Обучение на най-високо ниво",
	AccountDeactivationModal_Title: "Наистина ли искате да деактивирате този акаунт?",
	AccountDeactivationModal_Line1: "Деактивирането на този акаунт е за постоянно и ще премахне цялото съдържание, включително транзакциите за заявки на услуги и балансите на членовете на екипа и настройките на профила.",
	AccountDeactivationModal_Line2Part1: "Ако е в рамките на активен абонаментен период, моля, вижте политиката ни за анулиране в",
	AccountDeactivationModal_Line2Part2: "Общите условия",
	AccountDeactivationModal_Line2Part3: "преди да подадете заявката си.",
	AccountDeactivationModal_Line3: "Наистина ли искате да изпратите заявка за деактивиране на този акаунт?",
	AccountDeactivationModal_Cancel: "Отказ",
	AccountDeactivationModal_Send: "Изпращане",
	CurrentUserAccountDeactivationModal_Title: "Наистина ли искате да деактивирате акаунта си?",
	CurrentUserAccountDeactivationModal_Line1: "Деактивирането на Вашия акаунт е за постоянно и ще премахне цялото съдържание, включително транзакциите за заявки на услуги и балансите на членовете на екипа и настройки на профила.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Ако сте в рамките на активен абонаментен период, моля, вижте политиката ни за анулиране в",
	CurrentUserAccountDeactivationModal_Line3: "Наистина ли искате да изпратите заявка за деактивиране на акаунта ви?",
	CurrentUserAccountDeactivationModal_Yes: "Да",
	CurrentUserAccountDeactivationModal_No: "Не",
	CourseWithdrawalModal_Title: "Отмяна на обучението",
	CourseWithdrawalModal_Line1: "Сигурни ли сте, че искате да откажете обучението?",
	CourseWithdrawalModal_Line2Part1: "Отказите на курсове подлежат на такси за анулиране и ще бъдат таксувани съгласно",
	CourseWithdrawalModal_Line2Part2Link: "общите условия",
	CourseWithdrawalModal_Line2Part3: "на нашите услуги.",
	CourseWithdrawalModal_Yes: "Да",
	CourseWithdrawalModal_No: "Не",
	Settings_Settings: "Настройки",
	Settings_ManageYourSettings: "Управление на настройките",
	Settings_Profile: "Профил",
	Settings_MyBoschGlobalIdLoginInformation: "Информация за вход с моя Bosch Global ID",
	Settings_Edit: "Редактиране",
	Settings_CompanyDetails: "Данни за компанията",
	Settings_RequestAccountDeletion: "Заявка за изтриване на акаунт",
	Settings_AccountDeletionText: "Ако изтриете акаунта си, всичките ви данни (включително Вашия адрес и данни за плащане) ще бъдат изтрити и вече няма да са налични.",
	Settings_DeleteAccountRequestConfirmation: "Потвърждение на заявка за изтриване на акаунт",
	Settings_DeleteAccountRequestConfirmationText: "Получихме заявката Ви за изтриване на акаунта и ще се свържем с Вас по имейл или по телефона за окончателно потвърждение",
	RecommendedHotels_RecommendedHotels: "Препоръчани хотели",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Имаме центрове за обучение в целия свят",
	Error_Common: "Нещо се обърка!",
	Error_404: "Страницата, която търсите, не съществува",
	Error_401: "Нямате достъп до тази страница",
	Error_500: "Сървърът съобщава за грешка",
	Dashboard_Courses_Table_Course: "Курс",
	Dashboard_Courses_Table_Date: "Дата[дни]",
	Dashboard_Courses_Table_Venue: "Място на провеждане",
	Dashboard_Courses_Table_TrainingType: "Тип на обучението",
	Dashboard_Courses_Table_Action: "Акция",
	Dashboard_Courses_Table_TasksToComplete: "Трябва да изпълните следните задачи",
	Dashboard_Courses_Table_Withdraw: "Отменям",
	Dashboard_Courses_Table_Remove: "Премахни",
	Dashboard_Courses_Table_View: "Преглед",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Няма записи за показване",
	Dashboard_Courses_Table_CompletionDate: "Дата на завършване",
	Dashboard_Courses_Table_Actions_Assignments: "Задачи",
	Dashboard_Courses_Table_Actions_Certification: "Сертифициране",
	Dashboard_Courses_Table_Actions_Evaluations: "Оценки",
	Dashboard_Courses_Table_Actions_Assessments: "Оценки",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Добавяне към календара",
	Dashboard_Courses_Table_CourseDetails_Task: "Задача",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Краен срок",
	ContactUs_Headline: "Свържете се с нас",
	ContactUs_HowCanWeHelp: "Как можем да Ви помогнем?",
	ContactUs_EmailTab: "Имейл",
	ContactUs_PhoneTab: "Телефон",
	ContactUs_LetterTab: "Писмо",
	ContactUs_Phone_Headline: "Обадете ни се!",
	ContactUs_Phone_Description: "Очакваме с нетърпение вашето обаждане и ще се радваме да Ви помогнем. Ние сме на разположение за вас 24 часа, 7 дни в седмицата.",
	ContactUs_Phone_PhoneNumber: "Моля, използвайте формата за контакт, за да ни изпратите съобщение.",
	ContactUs_Phone_ChargeNotice: "Таксите варират в зависимост от Вашия доставчик на услуги и държава.",
	ContactUs_Letter_Headline: "Изпратете писмо!",
	ContactUs_Letter_Description: "Имате ли въпроси, искания или предложения? Очакваме с нетърпение Вашето запитване.",
	ContactUs_Email_Headline: "Изпратете имейл!",
	ContactUs_Email_Description: "Изпратете ни Вашето съобщение, като попълните формата по-долу. Един от нашите сътрудници ще се радва да се свърже с Вас.",
	ContactUs_Email_InputName: "Име*",
	ContactUs_Email_InputCompany: "Компания",
	ContactUs_Email_InputCountry: "Страна*",
	ContactUs_Email_InputEmail: "Имейл*",
	ContactUs_Email_InputMessage: "Съобщение*",
	ContactUs_Email_DataProtection: "Уведомление за защита на данните*",
	ContactUs_Email_DataProtectionConfirm: "Прочетох декларация за поверителност*",
	ContactUs_Email_SecurityCheck: "Проверка за безопасност",
	ContactUs_Email_SecurityCodeConfirm: "Моля, въведете правилните знаци, които виждате в полето по-горе.",
	ContactUs_Email_SecurityCodeInvalid: "Отговорът на текста на captcha, който сте въвели е неправилен.",
	ContactUs_Email_MandatoryNotice: "Моля, попълнете задължителните полета.",
	ContactUs_Email_SuccessMessage: "Вашето съобщение е изпратено",
	ContactUs_Email_TakenNoteDataProtection1: "Прочетох",
	ContactUs_Email_TakenNoteDataProtection2: "Декларация за поверителност*",
	ContactUs_Email_SubmitButton: "Изпращане",
	ContactUs_Email_FieldErrorMessage: "Моля, попълнете задължителните полета.",
	ContactUs_Email_SuccessModal_OK: "ОК",
	AccountInReviewModal_Title: "Вашият акаунт в момента се преглежда!",
	AccountInReviewModal_Paragraph1: "Благодарим ви, че регистрирахте личен акаунт в Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Нашият специализиран персонал за обучения в момента преглежда и конфигурира Вашия акаунт. Ще получите окончателно потвърждение на електронната Ви пощата, след като акаунтът Ви бъде успешно конфигуриран.",
	AccountInReviewModal_Paragraph3: "Междувременно, моля не се колебайте да разглеждате нашия уебсайт с ограничен достъп.",
	AccountInReviewModal_Paragraph4: "Ако имате въпроси или ако акаунтът Ви не бъде обработен в рамките на 48 часа, с изключение на почивните дни и празниците, можете да се свържете с нас",
	AccountInReviewModal_ContactUsLink: "тук",
	AccountInReviewModal_OKButton: "Добре, разреши ми да вляза!",
	Header_YesButton: "Да",
	Header_NoButton: "Не",
	Header_LogOutText: "Искате ли да излезете?",
	Footer_EnquiryText: "Очакваме Вашето запитване,",
	Footer_Telephone: "Телефон,",
	Footer_TermsAndConditions: "Общи условия",
	Footer_HowOurTrainingEvolves: "Как се развива нашето обучение",
	FooterSearch_SearchFieldPlaceholder: "Търсене",
	TrainingCatalogue_Action: "Акция",
	TrainingCatalogue_Duration: "Продължителност",
	TrainingCatalogue_Information: "Информация",
	Settings_PersonalDetails: "Лични данни",
	Reset_All_Filters: "Нулирайте всички филтри",
	EventTable_Days: "Дни",
	SearchField_AllResults: "Всички резултати",
	TrainingCentresLayout_ViewDetails: "Виж детайлите",
	Dropdown_Placeholder: "Избери...",
	WaitlistPopup_SelectButtonText: "Изберете",
	WaitlistPopup_StartDate: "Дата на започване",
	WaitlistPopup_EndDate: "Дата на завършване",
	WaitlistPopup_Language: "Език",
	WaitlistPopup_City: "Град",
	WaitlistPopup_Workshop: "Сервиз",
	WaitlistPopup_Student: "Обучаващ се",
	WaitlistPopup_PreferredLocation: "Предпочитано място",
	WaitlistPopup_SignUpCourseWaitingList: "Регистрирайте се в списъка с чакащи за курс",
	WaitlistPopup_SignUpWithoutSelectingDate: "Регистрирайте се в списъка с чакащи, без да избирате дата",
	WaitlistPopup_GeneralWaitList: "Общ списък на чакащите",
	WatinglistPopup_CourseLabel: "Курс",
	Common_ContinueButton: "Избери...",
	UserAccountDeactivationModal_ConfirmLabel: "Потвърдете",
	Filter_FilterLabel: "Филтър",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Вече сте в списъка на чакащите, искате ли да премахнете съществуващата си регистрация и да направите нова?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Да, премахни",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Не, отмени",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Отмяна на курс",
	WaitinglistPopup_SignUpButtonText: "Регистриране",
	MyAccount_FirstNameRequied: "Изисква се собствено име",
	MyAccount_LastNameRequired: "Изисква се фамилия",
	MyAccount_RoleRequired: "Изисква се роля",
	MyAccount_MobileRequired: "Изисква се мобилен телефон",
	MyAccount_EmailRequired: "Изисква се имейл",
	MyAccount_LanguageRequired: "Изисква се език",
	MyAccount_UpdateSuccessMessage: "Данните за вашия акаунт са актуализирани успешно!",
	MyAccount_UpdateSuccessModal_ButtonText: "Да, затвори",
	Infor_CorporateInformation_Header: "Корпоративна информация",
	Infor_LegalNotice_Header: "Правна информация",
	Infor_PrivacyPolicy_Header: "Уведомление за защита на данните (Декларация за поверителност)",
	CreateEmployeeAccountModification_FormValidation_Role: "Изисква се роля",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Изисква се собствено име",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Изисква се фамилия",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Изисква се мобилен телефон",
	CreateEmployeeAccountModification_FormValidation_Email: "Изисква се имейл",
	CreateEmployeeAccountModification_FormValidation_Language: "Изисква се език",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Покана за присъединяване към Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Успешно добавихте нов член към вашия екип!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Искате ли да споделим покана по имейл с вашия новия член на екипа, с инструкции как да създаде свой собствен личен акаунт за Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Не",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Да, изпрати покана",
	ShoppingCart_Venue_Fee: "Такса за място",
	ShoppingCart_Trainning_Name: "Име на обучението",
	ShoppingCart_Available_Credits: "Налични кредити",
	ConfirmItsMeModal_Hi: "Здравейте",
	ConfirmItsMeModal_Description1: "Успешно изпълнихте поканата, споделена от администратора на вашата компания, да създадете свой личен акаунт в Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Преди да продължите, моля, потвърдете или актуализирайте данните за акаунта си.",
	ConfirmItsMeModal_Description3: "След потвърждение, ще трябва да влезете отново, за да завършите създаването на акаунта си.",
	ConfirmItsMeModal_CompanyDetails: "Данни за компанията",
	ConfirmItsMeModal_YourDetails: "Вашите детайли",
	ConfirmItsMeModal_YourDetails_Title: "Титла",
	ConfirmItsMeModal_YourDetails_Function: "Функция",
	ConfirmItsMeModal_YourDetails_Name: "Име",
	ConfirmItsMeModal_YourDetails_Mobile: "Мобилен телефон",
	ConfirmItsMeModal_YourDetails_Email: "Имейл",
	ConfirmItsMeModal_YourDetails_Language: "Език",
	ConfirmItsMeModal_ConfirmButton: "Потвърдете",
	SelectWholesalerModal_WholesalerGroupLabel: "Търговец на едро",
	SelectWholesalerModal_WholesalerBranchLabel: "Клон",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Моля изберете",
	ApplyButton: "Приложи",
	SelectWholesalerModal_Title: "Изберете вашия търговец на едро",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Искате ли да премахнете регистрацията си?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Вашата регистрация е премахната успешно !",
	WaitlistPopup_AddToWaitListSuccess: "Вашата регистрация е добавена успешно!",
	ErrorCommon_Header: "ГРЕШКА",
	CommonButton_Close: "Затвори",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Моля, затворете този прозорец, за да продължите!",
	EmbeddedVideoPlayer_StartButtonText: "Старт",
	EmbeddedVideoPlayer_RefresherButtonText: "Опресняване",
	DeactivateWholesalerModal_ConfirmMessage: "Наистина ли искате да премахнете този търговец на едро?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Изпращането на имейл с покана е успешно!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Поканете отново",
	ContactUs_Mobile_BG: "+359 700 10 668",
	ContactUs_Address_BG: "Роберт Бош ЕООД,бул. \"Черни връх\" 51б,Бизнес център FPI,София 1407",
	ConfirmItsMeModal_CompanyDetails_Name: "Име на компанията",
	ConfirmItsMeModal_CompanyDetails_Address: "Адрес",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Пощенски код на компанията",
	ConfirmItsMeModal_CompanyDetails_Phone: "Телефонен номер",
	ConfirmItsMeModal_CompanyDetails_Email: "Имейл",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Оттеглянето от курса е успешно!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Създаването на акаунт е неуспешно. Акаунтът вече съществува!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Актуализирането на акаунта не бе успешно. Акаунтът вече съществува!",
	Footer_ExploreTrainingStories: "Разгледайте всички наши истории за обучения",
	Breadcrumb_Home: "Начало",
	Breadcrumb_Contact: "Контакт",
	Breadcrumb_Stories: "Истории",
	Breadcrumb_Stories_BoschServiceTraining: "Сервизни обучения на Бош",
	Breadcrumb_Stories_ADayInLife: "Един ден от живота на треньора",
	Breadcrumb_Stories_BoschTrainingBenefits: "Ползите от обученията на Бош",
	Breadcrumb_Stories_TrainingApproach: "Модерен подход към обучението",
	Breadcrumb_Settings: "Настройки",
	Breadcrumb_ShoppingCart: "Количка за пазаруване",
	Breadcrumb_Checkout: "Check Out",
	Breadcrumb_OrderSummary: "Обобщение на поръчката",
	Breadcrumb_Exception: "Изключение",
	Breadcrumb_Dashboard: "Табло",
	Breadcrumb_MyCourses: "Моите курсове",
	Breadcrumb_MyELearning: "Моето електронно обучение",
	Breadcrumb_CompanyAdministration: "Администрация на компанията",
	Breadcrumb_EmployeeManagement: "Управление на служителите",
	Breadcrumb_EmployeeAccount: "Акаунт на служител",
	Breadcrumb_EmployeeCreation: "Създаване на служител",
	Breadcrumb_Administration: "Администрация",
	Breadcrumb_EmployeeAdministration: "Администриране на служители",
	Breadcrumb_WholesalerManagement: "Управление на търговците на едро",
	Breadcrumb_NewWholesalerAccountCreation: "Създаване на нов акаунт на търговец на едро",
	Breadcrumb_Transactions: "Транзакции",
	Breadcrumb_MyAccount: "Моят акаунт",
	Breadcrumb_TrainingCentres: "Центрове за обучение",
	Breadcrumb_EventCalendar: "Календар с обучения",
	Breadcrumb_TrainingCourseCatalogue: "Каталог с обучения",
	Breadcrumb_RecommendedHotels: "Препоръчани хотели",
	Breadcrumb_CorporateInformation: "Корпоративна информация",
	Breadcrumb_LegalNotice: "Правна информация",
	Breadcrumb_DataProtectionNotice: "Уведомление за защита на данните",
	Breadcrumb_PrivacyPolicy: "Декларация за поверителност",
	Breadcrumb_TermCondition: "Общите условия",
	Breadcrumb_Terms: "Условия",
	Breadcrumb_PrivacyStatement: "Декларация за поверителност",
	Breadcrumb_Cookies: "Бисквитки",
	Breadcrumb_Provider: "Доставчик",
	Breadcrumb_RegisterAccount: "Регистър акаунт",
	Stories_ExploreMore: "Разгледайте повече",
	CheckOutLayout_ShoppingCartHeader: "Количка за пазаруване",
	CheckOutLayout_AddedItemCartMessage: "Добавихте следното към вашата количка",
	CheckOutLayout_CheckYourCartMessage: "Проверете количката си за пазаруване",
	ModernTrainingApproach_DateText: "10/1/2021 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "9/29/2021 12:00:00 AM",
	BoschTrainingBenefits_DateText: "9/30/2021 12:00:00 AM",
	BoschServiceTraining_DateText: "9/28/2021 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Търсене на служители",
	CheckOutLayout_OrderWasNotPlaced_Message: "Съжалявам! Вашата поръчка не е направена",
	CheckOutLayout_RefusalReason: "Причина за отказ",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Плащането не бе успешно. Моля, опитайте отново с друг начин на плащане",
	CheckOutLayout_SubmittingApiFailed_Message: "Изпращането на API не бе успешно",
	CartItem_ChooseWorkshop_Message: "Моля, изберете сервиз, за да изберете участник",
	CartItem_ChooseParticipant_Message: "Моля, изберете участник, за да продължите",
	CompanyAdministrationTable_SearchBox_Placeholder: "Търсене в сервизи",
	CartItem_RemoveCartItem_ConfirmText: "Искате ли да премахнете този артикул от количката?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Функция",
	AddToCartSuccessModal_Message: "Вашата пазарска количка беше актуализирана!",
	AddToCartSuccessModal_ContinueButtonText: "Продължи с пазаруването",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Моята пазарска количка",
	AddVoucher_Header: "Добави ваучер",
	AddVoucher_InputPlaceholder: "Въведете код и кандидатствувайте",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "За съжаление, в момента нямаме планирани събития от този курс за обучение. Моля, регистрирайте се в списъка с чакащи и ние ще ви информираме, когато дадено събитие стане налично.",
	ADYEN_ERR_2_: "Транзакцията беше отказана.",
	ADYEN_ERR_3_: "Грешка при препращане",
	ADYEN_ERR_4_: "Транзакцията не е осъществена поради грешка, възникнала от страна на придобиващия.",
	ADYEN_ERR_5_: "Картата, използвана за транзакцията, е блокирана, поради което не може да се използва.",
	ADYEN_ERR_6_: "Картата, използвана за транзакцията, е изтекла. Следователно е неизползваема.",
	ADYEN_ERR_7_: "По време на процеса на транзакция възникна несъответствие на сумата.",
	ADYEN_ERR_8_: "Посоченият номер на картата е неправилен или невалиден.",
	ADYEN_ERR_9_: "Не е възможно да се свържем с банката на купувача, за да се разреши транзакцията.",
	ADYEN_ERR_10_: "Банката на купувача не поддържа или не разрешава този тип транзакции.",
	ADYEN_ERR_11_: "3D Secure удостоверяване не беше изпълнено или не се изпълни успешно.",
	ADYEN_ERR_12_: "Картата няма достатъчно пари за покриване на дължимата сума. Този проблем може да възникне поради месечен лимит на дължима сума на вашата карта. Моля, опитайте да използвате друга карта, като например MasterCard, или се свържете с банката си, за да нулирате месечния лимит на вашата карта.",
	ADYEN_ERR_14_: "Възможна измама.",
	ADYEN_ERR_15_: "Транзакцията беше анулирана.",
	ADYEN_ERR_16_: "Купувачът анулира транзакцията, преди да я завърши.",
	ADYEN_ERR_17_: "Посоченият ПИН е неправилен или невалиден.",
	ADYEN_ERR_18_: "Купувачът е посочил неправилен ПИН повече от три пъти подред.",
	ADYEN_ERR_19_: "Не е възможно да се потвърди посоченият ПИН номер.",
	ADYEN_ERR_20_: "Възможна измама.",
	ADYEN_ERR_21_: "Транзакцията не е изпратена правилно за обработка.",
	ADYEN_ERR_22_: "Проверката за риска отбеляза транзакцията като измамна (рисков резултат >= 100); следователно операцията се отменя.",
	ADYEN_ERR_23_: "Следните отхвърлени кодове са съпоставени с тази причина за отказ:  Транзакцията не е разрешена на издателя/картодържателя",
	ADYEN_ERR_24_: "Посоченият CVC (код за защита на картата) е невалиден.",
	ADYEN_ERR_25_: "Следните кодове за отказ са съпоставени с тази причина за отказ:  Невалидна карта в тази държава",
	ADYEN_ERR_26_: "R1: Отмяна на нареждане за упълномощаване / „R3: Отмяна на поръчка за всички разрешения“ / „R0: Спиране на нареждане за плащане“",
	ADYEN_ERR_27_: "Този отговор е свързан с всички кодове на отговор, които не могат да бъдат надеждно свързани. Това улеснява разграничаването на общ отказ (например отговор на Mastercard „05: Не приемай“) от по-специфичен отказ.",
	ADYEN_ERR_28_: "Разрешената сума за теглене на картата на купувача е надвишена.",
	ADYEN_ERR_29_: "Броят на разрешените тегления за картата на купувача е надхвърлен.",
	ADYEN_ERR_31_: "Издателят е декларирал, че за транзакцията съществува съмнение за приемане.",
	ADYEN_ERR_32_: "Адресните данни, въведени от купувача, са неправилни.",
	ADYEN_ERR_33_: "Банката на купувача изисква от купувача да въведе онлайн ПИН.",
	ADYEN_ERR_34_: "Банката на купувача изисква разплащателна сметка, за да завърши покупката.",
	ADYEN_ERR_35_: "Банката на купувача изисква спестовна сметка, за да завърши покупката.",
	ADYEN_ERR_36_: "Банката на купувача изисква от купувача да въведе мобилен ПИН.",
	ADYEN_ERR_37_: "Купувачът е изоставил транзакцията, след като е опитал безконтактно плащане и е бил подканен да опита различен метод за въвеждане на карта (ПИН или плъзгане).",
	ADYEN_ERR_38_: "Издателят отхвърли искането за освобождаване от удостоверяване и изисква удостоверяване за транзакцията. Опитайте отново с 3D Secure",
	ADYEN_ERR_39_: "Издателят или схемата не бяха в състояние да съобщават резултата чрез RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Собственото име не трябва да включва специални знаци (напр.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Името трябва да е по-малко от 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Фамилното име не трябва да включва специални знаци (напр. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Фамилното име трябва да е по-малко от 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Мобилният номер не трябва да включва специални знаци (напр. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Мобилният номер трябва да е по-малък от 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Телефонният номер не трябва да включва специални знаци (напр. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Телефонният номер трябва да бъде по-малък от 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Имейл адресът е невалиден",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Имейлът трябва да е по-малко от 100",
	ConfirmGeolocationModal_ConfirmButton: "Продължи",
	ConfirmGeolocationModal_CancelButton: "Промяна",
	ConfirmGeolocationModal_CurrentCountrySelection: "Вашият текущ избор на държава е:",
	ConfirmGeolocationModal_AskChangeCountry: "Искате ли да останете на този сайт или да промените предпочитанията си за държава?",
	Addtocart_Modal_AddToCartFailTitle: "Участникът вече е регистриран за това събитие",
	Addtocart_Modal_AlreadyInCart: "Участник вече е регистриран за това събитие",
	TrainingCatalogue_DateRange: "Период от време",
	TrainingCatalogue_DateRange_placeholder: "Изберете филтъра за период от време",
	Dashboard_MyPendingCourses: "Изчакващ",
	Dashboard_MyWaitingList: "Моят списък с чакащи",
	Dashboard_LatestNews: "Последни новини",
	SettingsMainPage_EditAccountButton: "Редактиране на акаунт",
	SettingsMainPage_EditBoschId: "Редактиране на Bosch ID",
	EventInfoView_EventDetailTab: "Подробности за обучението",
	EventInfoView_AssignmentsTab: "Задачи",
	EventInfoView_EvaluationTab: "Оценка",
	EventInfoView_AttachmentTab: "Прикачени файлове",
	EventInfoView_CourseDescriptionTab: "Описание",
	EventInfoView_DescriptionTab_General: "Общо",
	EventInfoView_DescriptionTab_PartLabel: "Част",
	AssignmentTab_Description: "Тук ще намерите цялата информация, от която се нуждаете, за да завършите този курс.",
	AssignmentTab_Columns_Download: "Изтегляне",
	AssignmentTab_Columns_Upload: "Качване",
	AssignmentTab_Columns_TaskComplete: "Задачата е изпълнена",
	EventDetailTab_Location_Message_Video: "Това е онлайн събитие, което може да бъде достъпно чрез връзката \"Стартиране на видео\".",
	EventDetailTab_Location_Message_WebBased: "Това е онлайн събитие, което може да бъде достъпно чрез връзката \"Стартиране на WBT\".",
	EventDetailTab_Location_Message_WebCast: "Това е онлайн събитие, което може да бъде достъпно чрез връзката \"Присъединете се сега\".",
	EventDetailTab_Link_Video: "Стартирайте видео",
	EventDetailTab_Link_Video_Remark: "*Стартирането на видеото ще отвори нов прозорец. Ще бъдете пренасочени обратно към тази страница, след като прозорецът бъде затворен.",
	EventDetailTab_Link_WebBased: "Стартирайте WBT",
	EventDetailTab_Link_WebBased_Remark: "*Стартирането на WBT ще отвори нов прозорец. Ще бъдете пренасочени обратно към тази страница, след като прозорецът бъде затворен.",
	EventDetailTab_Link_WebCast: "Присъедини се сега",
	EventDetailTab_Link_WebCast_Remark: "*Връзката за присъединяване сега ще бъде активна 30 минути преди началото на събитието.",
	EventDetailTab_Title_Status: "Статус",
	EventDetailTab_Title_Duration: "Продължителност (часове)",
	EventDetailTab_Title_Trainers: "Тренери",
	EventDetailTab_Title_Language: "Език",
	EventDetailTab_Title_Score: "Резултат (%)",
	EventDetailTab_Title_Calendar: "Добавяне към календара",
	EventDetailTab_Title_Certificate: "Изтегляне на сертификат",
	EventDetailTab_Title_Messages: "Съобщения",
	EventDetailTab_Title_StartDate: "Дата на започване",
	EventDetailTab_Title_AvailableFrom: "Достъпен от",
	EventDetailTab_Title_Time: "Време",
	EventDetailTab_Title_EndDate: "Дата на завършване",
	EventDetailTab_Title_ExpiresOn: "Изтича на",
	EventDetailTab_Title_Room: "Стая",
	EventDetailTab_Title_Method: "Метод",
	EventDetailTab_Title_Link: "Линк*",
	EventDetailTab_Title_Location: "Локация",
	AttachmentTab_EmptyScreenDescription: "Няма прикачени файлове за това обучение.",
	AttachmentTab_Columns_Download: "Изтегляне",
	AttachmentTab_Description: "Тук ще намерите важна информация, която да ви помогне с този курс",
	EvaluationTab_Annotation: "Ние ценим вашите отзиви и ще се радваме да чуем от вас как се справяме. Моля, отделете време, за да попълните следния въпросник за оценяване, за да ни помогнете непрекъснато да подобряваме услугите си за вас. Благодаря Ви.",
	EvaluationTab_Notice: "Оценките са както следва",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Моля, отговорете на всички задължителни въпроси, преди да изпратите своята оценка за обучението",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Вашата оценка е изпратена успешно",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Подаването на оценката е неуспешно",
	TrainingCatalogueLayout_ExportButtonText: "Експортиране",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Оценката е завършена или е изтекла.",
	EventDetailTab_Link_CopiedTooltip: "Копирано в клипборда",
	EventDetailTab_QRCode_ExplanationText_WBT: "Сканирайте, за да стартирате WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Сканирайте, за да стартирате видеото",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Сканирайте, за да стартирате онлайн обучението",
	Footer_TrainingCoure_Headline: "Курсове за обучение, провеждани от Bosch",
	Footer_TrainingCourse_Content: "Като световноизвестна марка и с над един век опит в иновациите, Bosch наложи глобални стандарти като доставчик на автомобилни системи, части и технически решения. Голяма част от нашата сила идва от близките ни партньорства с производителите на превозни средства, за които проектираме, разработваме и произвеждаме най-съвременни системи и компоненти по целия свят. В резултат на това Bosch има дългогодишна история в авточастите  и услугите за вторичния пазар с разнообразно портфолио от части, диагностични решения и сервизни услуги. Обучението и професионалното развитието са сигурни ключове към успеха и тази философия е подкрепена от широка гама от курсове във всички области на автомобилната технология, автомобилните системи и диагностиката.",
	SearchField_NoRecordsFound: "Не са намерени записи",
	TrainingCatalogue_FreeTextSearch: "Свободен текст",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Търсене",
	CheckoutLayout_WholeSalerGroup: "Изберете Група търговци на едро",
	CreditInsufficientModal_Message: "Нямате достатъчен кредит за използването на този метод на плащане. Моля, опитайте отново с друг метод на плащане.",
	ShoppingCart_Required_Credits: "Обща цена",
	MaintainingInfo_Message1: "Сайтът е в режим на поддръжка.",
	MaintainingInfo_Message2: "Ще се върнем скоро.",
	CountryValidationModal_Message: "Не можахте да влезете в [CountryA], тъй като вашият акаунт е създаден в [CountryB].",
	CountryValidationModal_ButtonText: "Продължете към [Country]",
	EventTable_LoginRequired: "Влезте, за да се регистрирате",
	TrainingPartTableLayout_Date: "Дата",
	TrainingPartTableLayout_StartTime: "Начален час",
	TrainingPartTableLayout_EndTime: "Час на завършване",
	TrainingPartTableLayout_Duration: "Продължителност (часове)",
	TrainingPartTableLayout_TrainingMethod: "Метод на обучение",
	MyCoursesTableContents_ActiveHeader: "Активни курсове",
	MyCoursesTableContents_ActiveTabHeader: "Активен",
	MyCoursesTableContents_ScheduledHeader: "Планирани курсове",
	MyCoursesTableContents_ScheduledTabHeader: "Планиран",
	MyCoursesTableContents_OnlineHeader: "Онлайн курсове",
	MyCoursesTableContents_OnlineTabHeader: "Онлайн",
	MyCoursesTableContents_CancelledHeader: "Отменени курсове",
	MyCoursesTableContents_CancelledTabHeader: "Отменен",
	OnlineCoursesTable_Title_Progress: "Напредък",
	ScheduledCoursesTable_Title_Date: "Дата [дни]",
	CoursesTable_Title_Completed: "Завършен",
	Footer_TrainingProgram: "Програми за обучение",
	Breadcrumb_TrainingProgram: "Програми за обучение",
	TrainingProgram_Title_Table: "Заглавие",
	TrainingProgram_TrainingProgram: "Програми за обучение",
	TrainingProgram_TargetGroup: "Целева група",
	TrainingProgram_MaxLength: "Макс. Дължина",
	TrainingProgram_Information: "Информация",
	TrainingProgram_Detail: "Подробности",
	TrainingProgram_Modules: "Модули",
	TrainingProgram_Course: "Курсове",
	TrainingProgram_PartNumber: "Артикулен номер",
	TrainingProgram_Max_Length: "Максимална дължина",
	TrainingProgram_Duration: "Продължителност",
	TrainingProgram_Exam: "Преглед",
	TrainingProgram_Assign_Popup: "Регистрирайте се за програма за обучение",
	TrainingProgram_Student: "Курсист",
	TrainingProgram_Assign: "Записан",
	TrainingProgram_Popup_Remove_Title: "Отмяна на обучение?",
	TrainingProgram_Description: "Описание",
	TrainingProgram_Days: "Дни",
	TrainingProgram_Type: "Тип",
	TrainingProgram_Action: "Акция",
	TrainingProgram_Enrolled: "вече е записан в тази програма за обучение",
	TrainingProgram_SelfAssign_Enrolled: "Вие вече сте записан за тази програма за обучение",
	TrainingProgram_Warning: "Моля, регистрирайте друг курсист или изберете друга програма за обучение.",
	TrainingProgram_Workshop: "Сервиз",
	TrainingProgram_Durations: "години",
	TrainingProgram_Duration_DaysLabel: "дни",
	TrainingProgram_HasExam_Yes: "Да",
	TrainingProgram_HasExam_No: "Не",
	AssignModal_SuccessModal_Header: "Честито!",
	AssignModal_SuccessModal_Message: "е успешно записан в тази програма за обучение.",
	AssignModal_SelfAssign_SuccessModal_Message: "Вие бяхте успешно записан за програмата за обучение.",
	Dashboard_MyTraningProgram: "Моята програма за обучение",
	Dashboard_MyTraningProgram_Title: "Заглавие",
	Dashboard_MyTraningProgram_Status: "Статус",
	Dashboard_MyTraningProgram_Table_View: "Преглед",
	Dashboard_MyTraningProgram_Register: "Регистър",
	Dashboard_MyTraningProgram_Records: "Стари записи",
	Dashboard_MyTrainingProgram_In_Progress: "В ход",
	Dashboard_MyTrainingProgram_Accredited: "Акредитиран",
	Dashboard_Program_Modal_Withdraw: "Отменям",
	Dashboard_Program_Modal_Grade: "Степен",
	Dashboard_MyTrainingProgram_Registered: "Регистриран",
	Dashboard_MyTrainingProgram_Completedd: "Завършен",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Да",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Не",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Моля, имайте предвид, че оттеглянето означава, че ще се оттеглите от цялата програма за обучение, а не само от индивидуален курс за обучение.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Все още ли искате да отмените програмата за обучение?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Изчакващ",
	WithdrawProgramModal_Withdraw_Success_Message: "Успешно се оттеглихте от програмата за обучение!",
	Common_Warning_Title: "Внимание!",
	Common_Error_Title: "Ups, нещо се обърка!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Добави в количката",
	AddEventFromCatalogToCartModal_HoursText: "часа",
	AddElearningToCartSuccessModal_Message: "е добавен към Вашата количка за пазаруване !",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Избраният участник е регистриран за този курс или курсът вече е добавен в количката.",
	EventDetailTab_SubjectForChange_Message: "Подлежи на промяна",
	Dashboard_MyTrainingProgram_Status_Failed: "Се провали",
	Dashboard_MyTrainingProgram_Status_Passed: "премина",
	Dashboard_MyTrainingProgram_Status_Merit: "заслуги",
	Dashboard_MyTrainingProgram_Status_Distinction: "Разграничение",
	Dashboard_Employee_Courses: "Курсове",
	Dashboard_Employee_Program: "Програма за обучение",
	Dashboard_Employee_WaitList: "Лист с чакащи",
	Dashboard_Employee_Account: "Акаунт",
	Course_Price_Free: "Безплатно",
	Dashboard_Employee_Account_Update_Success_Message: "Успешно актуализирахте информацията за вашия служител!",
	Dashboard_Supplier: "Доставчик",
	Event_Detail_Comment: "В момента няма съобщения",
	DownLoad_PDF: "Изтегляне на PDF",
	TrainingCatalogue_Currency: "Валута",
	My_Wallet: "Моят портфейл",
	Wallet: "Портфейл",
	Credits_Balance: "Баланс на кредитите",
	MyFullCalendar_Today: "Днес",
	Header_Available_Languages: "Налични езици",
	Header_CurrentRegion: "В момента сте в",
	Header_ChangeRegions: "Искате ли да смените държавата?",
	Header_Anonymous: "Сервизно обучение",
	Header_Anonymous_Login: "Вход/Регистрация",
	Header_RegisterCourse: "Регистриране курс",
	Homepage_Start: "Първи стъпки",
	Homepage_Login: "Вход",
	MainPage_Title: "Най-доброто решение за сервиза",
	MainPage_Content: "Вашия сервиз, вашият избор.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Позволява на съвременния сервиз да работи професионално, надеждно и ефективно в рамките на диагностични процедури, отстраняване на неизправности, ремонти и обслужване на всички видове превозни средства.",
	BoschNews_TrainingNews: "Новини за обучение",
	Service_Assist_Workshop: "Подобрете изживяването си с Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Какво мога да правя с приложението?",
	Enhance_Body_CDM3_Item_1: "Резервирайте курсове и поддържайте обучението си",
	Enhance_Body_CDM3_Item_2: "Изтеглете учебни материали и сертификати за курсове",
	Enhance_Body_CDM3_Item_3: "Стартирайте курсове за електронно обучение",
	Enhance_Body_CDM3_Item_4: "Достъп до различни автомобилни услуги в мобилното приложение",
	Enhance_Body_CDM3_Item_5: "Предаване на видео на живо с Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Безплатен инструмент за автоматично сканиране на превозни средства",
	Training_Stories: "Истории за обучение",
	SettingsMyAccount_JoinCompanyButton: "Присъединете се към компанията",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Присъединете се към компанията",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Моля, въведете своя код за активиране по-долу",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Забележка! Трябва да се свържете с администратора на вашата компанията, за да получите код за активиране.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Вашата активация е обработена успешно и вашият акаунт вече е свързан с вашата компания.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Моля, кликнете върху Продължи, за да влезете в уебсайта.",
	ContinueButton: "Продължи",
	WelcomeBatsModal_Title: "Вашият акаунт в момента се преглежда!",
	WelcomeBatsModal_ActivationLink: "Кликнете тук, ако имате код за активиране, за да се присъедините към компания!",
	WelcomeBatsModal_ActivationLinkNotes: "Забележка! Трябва да се свържете с администратора на вашата компанията, за да получите код за активиране.",
	AccountInReviewModal_Description_Paragraph1: "Благодарим ви, че регистрирате личен акаунт в Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Нашият специализиран персонал за обучение в момента преглежда и конфигурира вашия акаунт. Ще получите окончателно потвърждение по пощата, след като акаунтът ви бъде успешно конфигуриран.",
	AccountInReviewModal_CloseButton: "Добре, пуснете ме да вляза!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Не успяхме да потвърдим вашия код за активиране и имейл адрес.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	TryAgain_Button: "Опитайте отново",
	Error_Title: "Нещо се обърка!",
	SettingsMyProfileActivationSuccessModal_Title: "Успешно!",
	AccountInReviewModal_Description_Paragraph3: "Междувременно, моля, не се колебайте да разглеждате нашия уебсайт с ограничен достъп.",
	AccountInReviewModal_Description_Paragraph4: "Ако имате някакви въпроси или ако вашият акаунт не бъде обработен в рамките на 48 часа, с изключение на почивните дни и празниците, можете да се свържете с нас [тук]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Някои насоки, които да ви помогнат...",
	Support_FAQ_Headline: "Често задавани въпроси",
	Support_FAQs_section1_title: "Общ",
	Support_FAQs_section1_question1: "Какво е Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions е платформа, която предоставя изчерпателен преглед на техническите обучения и дава възможност за резервиране и управление на обучения.",
	Support_FAQs_section1_question2: "Мога ли да използвам съществуващия си Bosch акаунт (singleKey ID), за да вляза в Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Да, достъпен е с всички акаунти, създадени с \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Мога ли да използвам акаунт, създаден за Bosch Training Solutions с други услуги или приложения на Bosch?",
	Support_FAQs_section1_question3_answer1: "Да, можете да използвате своя личен Bosch ID и за различни други приложения на Bosch - както професионални, така и частни, като например e-bike или интелигентен дом.",
	Support_FAQs_section2_title: "Обучения",
	Support_FAQs_section2_question1: "Как мога да запиша обучение?",
	Support_FAQs_section2_question1_answer1: "Влезте със съществуващия си акаунт ИЛИ регистрирайте акаунт в портала. Отидете на \"Услуги за обучение\" ИЛИ \"Календар с обучения\" изберете обучение и кликнете върху бутона за добавяне към количката.",
	Support_FAQs_section2_question2: "Няма подходяща дата за обучение. Какво мога да направя?",
	Support_FAQs_section2_question2_answer1: "Имате възможност да се запишете в \"общ\" списък с чакащи (без да избирате дата) и нашият екип ще ви информира веднага щом има ново обучение.",
	Support_FAQs_section2_question3: "Къде мога да намеря преглед на записаните от мен курсове?",
	Support_FAQs_section2_question3_answer1: "Можете да намерите пълен преглед на всички записани и завършени курсове, след като влезете в таблото си за управление.",
	Support_FAQs_section3_title: "Настройки",
	Support_FAQs_section3_question1: "Как мога да променя своя имейл и парола?",
	Support_FAQs_section3_question1_answer1: "Трябва да влезете в портала и да промените личния си профил. (В таблото за управление можете да намерите информацията за вашия профил или ако кликнете върху следния бутон в заглавната част",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Там имате възможност да промените данните за вашия Bosch ID",
	Support_FAQs_section3_question2: "Какво мога да направя, ако забравя паролата си?",
	Support_FAQs_section3_question2_answer1: "Моля, използвайте бутона за вход/регистрация и попълнете своя имейл адрес",
	Support_FAQs_section3_question2_answer2: "Ако кликнете върху продължи, имате възможност да кликнете върху \"Забравена парола\"",
	Support_FAQs_section3_question3: "Как да настроите акаунт на сервиз?",
	Support_FAQs_section3_question3_answer1: "Ако желаете вашият акаунт да получи специална роля за вашия сервиз, моля, свържете се с местния администратор за обучение, напр. можете да използвате формата за контакт на сайта.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Изберете вашата държава/регион",
	MaintenanceBanner_Message_1: "Порталът Bosch Automotive Training Solutions ще бъде актуализиран на [maintenance_schedule]. Очаква се актуализацията да отнеме 30 минути. Порталът няма да бъде достъпен по време на актуализацията.",
	MaintenanceBanner_Message_2: "\nЗа допълнителна помощ, моля, свържете се с нас по имейл [support_email]",
	MaintenancePage_Message_1: "Порталът Bosch Automotive Training Solutions в момента се актуализира",
	MaintenancePage_Message_2: "Очакваме порталът да бъде достъпен отново на [maintenance_online_date] в [maintenance_online_time]",
	MenuHeader_Support: "Поддържка",
	Course_Detail_No_Prerequisites: "Без предпоставки",
	TableCell_Hours: "Часа",
	Dashboard_MenuItem_Dashboard: "Табло",
	Dashboard_MenuItem_Curricula: "Учебни програми",
	Dashboard_MenuItem_Certificates: "Сертификати",
	Dashboard_MenuItem_Team: "Екип",
	Dashboard_MenuItem_Workshops: "Сервизи",
	Dashboard_MenuItem_External: "Външен",
	Dashboard_MenuItem_Wallet: "Портфейл",
	Dashboard_MenuItem_Profile: "Профил",
	Dashboard_Headline_YourUpcomingCourses: "Вашите предстоящи курсове",
	Dashboard_Headline_YourELearningCourses: "Вашите предстоящи eLearning курсове",
	Dashboard_Headline_TeamOverview: "Преглед на екипа",
	Dashboard_Headline_YourOverview: "Вашият преглед",
	Dashboard_Headline_YourWishlist: "Вашият списък с желания",
	Dashboard_Headline_ActivePrograms: "Активни програми",
	Dashboard_Headline_CompletedPrograms: "Завършени програми",
	Dashboard_Headline_YourCertificates: "Вашите сертификати",
	Dashboard_Headline_Transactions: "Транзакции",
	Dashboard_Table_NoRecordsToDisplay: "Няма запис за показване",
	Dashboard_TableColumn_Location_Online: "Онлайн",
	Dashboard_TableColumn_Duration_Hours: "часа",
	Dashboard_ELearningCourses_TableHeader_Title: "Заглавие",
	Dashboard_ELearningCourses_TableHeader_Duration: "Продължителност (часове)",
	Dashboard_ELearningCourses_ActionButton_View: "Преглед",
	Dashboard_UpcomingCourses_TableHeader_Title: "Заглавие",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Дата [дни]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Местоположение",
	Dashboard_UpcomingCourses_ActionButton_View: "Преглед",
	Dashboard_YourOverview_TableHeader_Title: "Заглавие",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Дата / Продължителност",
	Dashboard_YourOverview_TableHeader_Location: "Местоположение",
	Dashboard_YourOverview_TableHeader_Status: "Статус",
	Dashboard_YourOverview_ActionButton_View: "Преглед",
	Dashboard_YourWishlist_TableHeader_Title: "Заглавие",
	Dashboard_YourWishlist_TableHeader_Action: "Акция",
	Dashboard_YourWishlist_ViewAllRecords: "Вижте всички записи",
	Dashboard_TeamOverview_TableHeader_Employee: "Служител",
	Dashboard_TeamOverview_TableHeader_Title: "Заглавие",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Дата / Продължителност",
	Dashboard_TeamOverview_TableHeader_Location: "Местоположение",
	Dashboard_TeamOverview_TableHeader_Status: "Статус",
	Dashboard_TeamOverview_ActionButton_View: "Преглед",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Търсене",
	Dashboard_ActiveProgram_TableHeader_Title: "Заглавие",
	Dashboard_ActiveProgram_TableHeader_Status: "Статус",
	Dashboard_ActiveProgram_ActionButton_View: "Преглед",
	Dashboard_CompletedProgram_TableHeader_Title: "Заглавие",
	Dashboard_CompletedProgram_TableHeader_Status: "Статус",
	Dashboard_CompletedProgram_ActionButton_View: "Преглед",
	Dashboard_Certificates_TableHeader_Title: "Заглавие",
	Dashboard_Certificates_TableHeader_Date: "Дата",
	Dashboard_Certificates_TableHeader_Status: "Статус",
	Dashboard_Certificates_TableHeader_Action: "Акция",
	Dashboard_Certificates_ActionButton_Download: "Изтегли",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Номер на фактура",
	Dashboard_Transactions_TableHeader_Description: "Описание",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Начин на плащане",
	Dashboard_Transactions_TableHeader_Date: "Дата",
	Dashboard_Transactions_TableHeader_Amount: "Сума",
	Dashboard_Transactions_ActionButton_Download: "Изтегли",
	Breadcrumb_MyTraining: "Моите обучения",
	Breadcrumb_MyTraining_CourseDetails: "Подробности за курса",
	Breadcrumb_MyTraining_Curricula: "Учебни програми",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Подробности за програмата за обучение",
	Breadcrumb_MyTraining_Team: "Екип",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Вижте служител",
	MyTraining_Headerbar_CourseDetails: "Подробности за курса",
	MyTraining_Headerbar_TrainingProgramDetails: "Подробности за програмата за обучение",
	TrainingProgramDetails_Label_Status: "Статус",
	TrainingProgramDetails_Label_OverallProgress: "Цялостен прогрес",
	TrainingProgramDetails_ProgramItems_Headline: "Елементи на програмата",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Заглавие",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Статус",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Степен",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Дата",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Преглед",
	TrainingProgramDetails_Status_InProgress: "В ход",
	TrainingProgramDetails_Status_Completed: "Завършен",
	CourseDetails_Label_Status: "Статус",
	CourseDetails_Label_Duration: "Продължителност",
	CourseDetails_Label_Trainer: "Тренер",
	CourseDetails_Label_Language: "Език",
	CourseDetails_Label_Score: "Резултат:",
	CourseDetails_Label_StartDate: "Дата на започване",
	CourseDetails_Label_EndDate: "Дата на завършване",
	CourseDetails_Label_Venue: "Място на провеждане",
	CourseDetails_Label_Address: "Адрес",
	CourseDetails_Label_Grade: "Степен",
	CourseDetails_Action_Withdraw: "Оттегляне",
	CourseDetails_Action_Evaluate: "Оценете курса",
	CourseDetails_Action_Evaluate_Description: "Моля, оценете този курс, за да генерирате своя сертификат!",
	CourseDetails_Action_DownloadCertificate: "Изтегляне на сертификат",
	CourseDetails_Action_JoinWebcast: "Присъединете се към Webcast",
	CourseDetails_Action_StartCourse: "Стартирайте курса",
	CourseDetails_Action_RestartCourse: "Рестартирайте курса",
	CourseDetails_Action_RepeatCourse_Description: "Моля, повторете и преминете този курс, за да изтеглите своя сертификат!",
	CourseDetails_PartSpecificDetails_Headline: "Подробности за специфични части",
	CourseDetails_PartSpecificDetails_Tab_Part: "Част",
	CourseDetails_PartSpecificDetails_Description: "Описание",
	CourseDetails_PartSpecificDetails_StartDate: "Дата на започване",
	CourseDetails_PartSpecificDetails_EndDate: "Дата на завършване",
	CourseDetails_PartSpecificDetails_StartTime: "Начален час",
	CourseDetails_PartSpecificDetails_EndTime: "Час на завършване",
	CourseDetails_PartSpecificDetails_Method: "Метод",
	Dashboard_Team_TableHeader_Name: "Име",
	Dashboard_Team_TableHeader_Role: "Роля",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Личен акаунт",
	Dashboard_Team_ActionButton_Select: "Изберете",
	Dashboard_Team_PersonalAccount_Activated: "Активиран",
	Dashboard_Team_PersonalAccount_NotActivated: "Не е активиран",
	Dashboard_Team_ActionButton_AddMember: "Добавете член на екипа",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Търсене",
	Dashboard_External_TableHeader_Name: "Име",
	Dashboard_External_ActionButton_AddNew: "Добави нов",
	Dashboard_External_Headline: "Одобрен външен достъп",
	Dashboard_Workshops_Headline: "Сервизи",
	Dashboard_Workshops_TableHeader_Company: "Компания",
	Dashboard_Workshops_TableHeader_Address: "Адрес",
	Dashboard_Workshops_TableHeader_Postcode: "Пощенски код",
	Dashboard_Workshops_TableHeader_City: "Град",
	Dashboard_Workshops_ActionButton_Select: "Избери",
	Wallet_ServiceCredits: "Кредити",
	Wallet_Headline_CreditBalance: "Кредитен баланс",
	Wallet_Headline_CreditActivity: "Кредитна активност",
	Wallet_Headline_BillingTransactions: "Транзакции",
	Wallet_BillingTransaction_TableHeader_Order: "Поръчка",
	Wallet_BillingTransaction_TableHeader_Date: "Дата",
	Wallet_BillingTransaction_TableHeader_Amount: "Сума",
	Wallet_BillingTransaction_TableHeader_Action: "Акция",
	Wallet_CreditActivity_TableHeader_Date: "Дата",
	Wallet_CreditActivity_TableHeader_Description: "Описание",
	Wallet_CreditActivity_TableHeader_User: "Потребител",
	Wallet_CreditActivity_TableHeader_Amount: "Сума",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, all rights reserved",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Сигурност на продукта (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Иновации, патенти и лицензи",
	FooterAdditionalLinks_Navigation_Logistics: "Закупуване и логистика",
	MenuHeader_MyTraining: "Моите обучения",
	MenuHeader_TrainingServices: "Обучения",
	MenuHeader_OurLocations: "Нашите локации",
	MenuHeader_ContactsUs: "Свържете се с нас",
	MenuHeader_Logout: "Изход",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Моя Bosch Id",
	MyProfile_AccountDeletion: "Изтриване на акаунт",
	MyProfile_Edit: "Променете имейл или редактирайте паролата",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Редактиране на потребителски профил",
	BackButton: "Обратно",
	ContactUs_Phone_Description_V3: "Очакваме вашето обаждане и ще се радваме да ви помогнем. Ние сме на Ваше разположение 24 часа, 7 дни в седмицата.​",
	ContactUs_Email_SecurityCodeConfirm_V3: "Въведете правилните знаци, както е посочено в полето по-горе.",
	ContactUs_Email_MandatoryNotice_V3: "Попълнете всички задължителни полета.",
	ContactUs_Email_SendButton: "Изпратете",
	Breadcrumb_TrainingCenters: "Центрове за обучение",
	Training_Centres_Book_Btn: "Запишете курс",
	Training_centres_booking_code: "Код за резервация",
	Training_centers_header_bar_title: "Нашите центрове за обучение",
	Training_centers_filter_item: "Център",
	TeamEdit_PersonalAccountToolTop: "Активирането на тази функция позволява на служителя да има достъп до този уебсайт. Потребителят ще може да създаде личен акаунт и да бъде част от вашия корпоративен акаунт. След като бъде активирано, можете да изпратите покана с код за активиране на служителя. Поканата ще бъде изпратена на имейл адреса.",
	TeamEdit_Update: "Активирането на функцията за фирмен администратор автоматично предоставя на този служител пълен достъп до вашия корпоративен акаунт. Активирането на тази функция изисква потребителят да излезе и да влезе отново в уебсайта, ако вече е влязъл.",
	TeamEdit_PersonalAccount: "Разрешаване на личен уеб акаунт на Bosch Training Solutions",
	TeamEdit_SendInvitation: "Изпрати покана",
	TeamEdit_Deactivate: "Деактивирайте",
	TeamEdit_Telephone: "Телефон",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Мобилният номер трябва да съдържа цифри",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Телефонният номер трябва да съдържа цифри",
	TeamEdit_Activate: "Активен",
	TeamEdit_NotActivated: "Не се активен",
	TeamEdit_StatusPopup: "Статусът ще бъде показан като „Активен“, ако потребителят успешно е създал личен акаунт. Ако статусът е зададен като „Не е активен“, това означава, че потребителят не е завършил вашата покана.",
	Training_Catalog_Courses: "Курсове",
	Training_Catalog_Course: "Курсове",
	Reset_Filter: "Нулиране на филтъра",
	TrainingCatalogue_CourseCatalogue: "Каталог с курсове",
	CheckoutHeader_CartItemCount_Text: "Имате [count] артикул в количката си за пазаруване",
	CheckoutHeader_CartItemsCount_Text: "Имате [count] артикули в количката си за пазаруване",
	ShoppingCart_PriceItem: "Цена на артикул",
	ShoppingCartItemParticipants_AddParticipant_Button: "Добавете участници",
	ShoppingCartTotalPrice_SubTotalLabel: "Междинна сума",
	ShoppingCartTotalPrice_TaxesLabel: "Данъци",
	ShoppingCartTotalPrice_TotalLabel: "Обща сума",
	ShoppingCartTotalPrice_IncludedVATLabel: "Включва ДДС  [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Налични методи за плащане онлайн",
	ShoppingCartItemRow_VenueFeeLabel: "Такса за локация [fee_amount] на участник",
	ShoppingCartItemRow_OnlineLabel: "Онлайн",
	AddParticipantsModal_Title: "Добавете участник",
	AddParticipantsModal_SelectEmployeeLabel: "Добавете Участник",
	AddParticipantsModal_SelectWorkshopLabel: "Изберете сервиз",
	ShoppingCartTotalPrice_ContinueButtonText: "Продължете към касата",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Участник не е добавен!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Имате един или повече курсове в пазарската си количка без участник.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Моля, добавете участник, за да продължите с плащането.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Редактиране на участници ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Име",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Акция",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Няма участници…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Имейл адресът не е конфигуриран за избрания участник",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Избраният участник няма конфигуриран имейл адрес в своя профил. Имейл адресът е задължителен за записване на курс за обучение.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Добавете имейл адрес, за да продължите или откажете, за да изберете друг участник.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Добавете имейл",
	CancelButton: "Отказ",
	AddParticipantsModal_AddEmployeeButtonText: "Добавете служител",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "На път сте да изтриете курс от вашата кошница",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Опитвате се да премахнете курс с добавени участници от вашата количка.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Кликнете върху \"Продължи\", ако искате да продължите или кликнете върху \"Отказ\".",
	ShoppingCartItemHeader_Participants: "Участник(ци)",
	ShoppingCartItemHeader_ParticipantPrice: "Цена за 1 участник",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Показване в [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Показване в Кредити",
	ShoppingCart_CreditsPriceUnit: "Кредити",
	CheckOutHeader_Step1_Question: "Как бихте искали да платите?",
	CheckOutHeader_Step2_Question: "Прегледайте поръчката си",
	CheckOutHeader_Step3_Question: "Завършете поръчката си",
	CheckOutHeader_Step4_Question: "Вашата резервация беше успешна!",
	CheckOutHeader_Step_Payment: "Плащане",
	CheckOutHeader_Step_Review: "Преглед",
	CheckOutHeader_Step_Place_Order: "Направете поръчка",
	CheckOutRightPanel_Review_Order_Btn: "Преглед на поръчката",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Направете поръчка",
	CheckoutRightPanelContent_OrderSummary_Title: "Резюме на поръчката",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Редактиране на количката за пазаруване",
	ShoppingCart_Participants: "Участници",
	CheckOutStepPayment_Payment_Methods_Title: "Изберете метод на плащане",
	CheckOutStepPayment_Credit_Title: "Кредитна или дебитна",
	CheckOutStepPayment_Wholesaler_Title: "Търговец на едро",
	CheckOutStepPayment_ServiceCredit_Title: "Кредити",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Имате [available_credit] Кредити",
	CheckOutStepPayment_BoschAccount_Title: "Бош акаунт",
	Checkout_Second_PaymentMethod_Headline: "Начин на плащане",
	CheckOutStepReview_Edit: "Редактиране",
	CheckOutStepReview_Terms_Label: "Общи условия и правила за анулиране",
	CheckOutStepReview_Terms_Text: "Прочетох, разбрах и съм съгласен с [условията и правилата за анулиране] на продажбата.",
	SelectSecondPaymentMethodModal_Title: "Изберете вторичен метод на плащане",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Плащане [amount]",
	StepPaymentWholesaler_Title: "Изберете търговец на едро",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Приемане Общите условия",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Моля, прочетете и приемете нашите Общи условия и правила за анулиране, преди да продължите.",
	CheckoutDescription_ThankYou: "Благодарим ви, че подадохте поръчка чрез Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Нашите битове и байтове вече започнаха да обработват вашата поръчка. Междувременно, моля, дръжте двигателя си на празен ход.",
	CheckoutDesctiption_Text: "Ще ви изпратим окончателно потвърждение по пощата, включително всички подробности за вашата резервация.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "На път сте да премахнете избрания сервиз",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "С премахването на избрания сервиз всички добавени участници ще бъдат нулирани. Тази промяна ще засегне всички курсове във вашата количка за пазаруване.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Кликнете върху \"Продължи\", ако искате да продължите или Кликнете върху \"Отказ\", за да спрете това действие.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Нямате достатъчно кредити. Вашият кредитен баланс е [available_credit] кредити, но имате нужда от [cart_total_credits] кредити, за да продължите плащането. Моля, изберете друг метод на плащане или премахнете артикулите от пазарската си количка.",
	Training_centers_filter_items: "Центрове",
	Training_Programs_Filter_Item: "Програми",
	Training_Program_Filter_Item: "Програма",
	Training_Program_Year: "Година/и",
	Training_Program_Detail_Title: "Програми  за обучение",
	AccountButton_Workshops_MenuItem: "Сервизи",
	AccountButton_Wallet_MenuItem: "Портфейл",
	Header_MyBoschProfile_Header: "MyBosch профил",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "На разположение",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Добавяне/редактиране на участник",
	ShoppingCart_CreditPriceUnit: "Кредит",
	Common_IncludedVAT: "вкл. ДДС",
	TrainingCatalogue_Length: "Дължина",
	TrainingCatalogue_Method: "Метод",
	Course_Price_Credits: "Кредити",
	Catalogue_Grid_Register_Button: "Регистриране",
	Catalogue_Credit_Tilte: "Щракнете тук, за да видите цените в Кредити",
	Catalogue_Price_Tilte: "Щракнете тук, за да видите цените в евро",
	Catalogue_Grid_Vat: "без ДДС",
	EventCalendar_Seats: "Места",
	MenuHeader_Calendar: "Календар",
	MenuHeader_Catalog: "Каталог",
	TableCell_Minutes: "Минути",
	ListFilter_NoResult: "Без резултат",
	Course_Description: "Описание на курса",
	Training_course_detail: "Основи на социалните медии​",
	Training_course_detail_add_waiting: "Добавете към общия списък на чакащи",
	Register_now_btn: "Регистрирай се сега !",
	Register_now_btn_add_event_to_cart: "Регистрирай се сега !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Вижте всички записи",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Вижте по-малко",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Вижте всички записи",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Вижте по-малко",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Вижте всички записи",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Вижте по-малко",
	TeamCreate_TitleError: "Заглавието е задължително",
	Dashboard_Headerbar_BookCourse: "Запиши се за курс",
	Dashboard_Headerbar_ExitProxy: "Изход от прокси",
	Dashboard_Headerbar_EvaluateCourse: "Оценете курса",
	Dashboard_Headerbar_EvaluateCourse_Note: "Моля, оценете този курс, за да генерирате своя сертификат!",
	Dashboard_Headerbar_DownloadCertificate: "Изтегляне на сертификат",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Отменям",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Отменям",
	Dashboard_Headerbar_WelcomeMessage: "Добре дошли в таблото за обучение",
	Dashboard_Headerbar_ProxingAs: "Заместващ като",
	Dashboard_TrainingProgram_Status_Completed: "Завършен",
	Dashboard_TrainingProgram_Status_InProgress: "В ход",
	External_SelectWholesalerModal_Title: "Добавете външен потребителски достъп",
	External_SelectWholesalerModal_Company_Label: "Компания",
	External_SelectWholesalerModal_Outlet_Label: "Клон",
	External_SelectWholesalerModal_Company_Placeholder: "Моля изберете",
	External_SelectWholesalerModal_Outlet_Placeholder: "Моля изберете",
	External_SelectWholesalerModal_ApplyButton: "Приложи",
	External_SelectWholesalerModal_CancelButton: "Отказ",
	External_RemoveWholesalerModal_ConfirmLabel: "Потвърждение",
	External_SelectWholesalerModal_ConfirmMessage: "Сигурни ли сте, че искате да премахнете този търговец на едро?",
	External_SelectWholesalerModal_YesButton: "Да",
	External_SelectWholesalerModal_NoButton: "Не",
	Training_program_team_member: "Член на екипа",
	Training_program_assign_training_program: "Задайте програма за обучение",
	Assign_modal_student_confirmation_text1: "На път сте да зададете [Training Program Name] към вашето учебна програма.",
	Assign_modal_student_confirmation_text2: "Искате ли да продължите?",
	MyFullCalendar_Jan: "Яну",
	MyFullCalendar_Feb: "Фев",
	MyFullCalendar_Mar: "Мар",
	MyFullCalendar_Apr: "Апр",
	MyFullCalendar_May: "Май",
	MyFullCalendar_Jun: "Юни",
	MyFullCalendar_Jul: "Юли",
	MyFullCalendar_Aug: "Авг",
	MyFullCalendar_Sep: "Сеп",
	MyFullCalendar_Oct: "Окт",
	MyFullCalendar_Nov: "Ное",
	MyFullCalendar_Dec: "Дек",
	MenuHeader_CourseCatalog: "Каталог с курсове",
	MenuHeader_Event: "Календар с обучения",
	MenuHeader_Program: "Програма с обучения",
	MenuHeader_Services: "Обучения",
	MenuHeader_Log_out: "Изход",
	Filter_Location: "Локация",
	TeamEdit_UpgradeToCompanyAdministrator: "Надстройте до фирмен администратор",
	TeamEdit_PersonalWebAccountStatusIs: "Състоянието на личния уеб акаунт е",
	Header_CreateNewEmployee: "Създаване на нов служител",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Няма свободни места!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Няма свободни места за избраното обучение.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Моля, изберете друго обучение или премахнете вече добавен участник, за да продължите.",
	Common_SuccessModal_Title: "Честито!",
	TrainingProgramDetails_Merit_StatusText: "Качество",
	TrainingProgramDetails_Resit_StatusText: "Отново",
	EmployeeProfile_UserExistErrorMessage: "Акаунтът вече съществува!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Неуспешно плащане. Моля, опитайте отново или използвайте друг метод на плащане.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Успешно деактивирахте избрания служител!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Запазено!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Вашите промени са запазени.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Отказването не е възможно за курсове за електронно обучение. Моля, свържете се с нас, ако са необходими допълнителни подробности.",
	Dashboard_Team_PersonalAccount_Pending: "Изчакващ",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Създаден служител!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Създаването на вашия служител е завършено успешно.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Тази настройка ви позволява да ограничите регистрацията на служител в обучителни събития. Само администраторите на компанията ще могат да регистрират служителя за обучителни събития, когато тази настройка не е избрана",
	Training_catalog_sort_by: "Сотирай по",
	CourseDetail_EvaluationRestriction_Title: "Оценяването на обучението не е възможно!",
	CourseDetail_EvaluationRestriction_Message: "Оценките на обучението могат да бъдат изпращани само от самия ученик. \nМоля, инструктирайте ученика да влезе в Bosch Automotive Training Solutions и да завърши оценката на обучението.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Ако участникът все още няма акаунт, покана може да бъде споделена в настройките на потребителския профил на члена на екипа.",
	Breadcrumb_Download_Page: "Изтегляне",
	Download_Table_Category_Cell: "Категория",
	Download_Table_Title_Cell: "Заглавие",
	Download_File_Filter_Item: "Файл",
	Download_File_Filter_Items: "Файлове",
	Download_Header: "Зона за изтегляне",
	DownloadArea_Button_Download: "Изтегляне",
	Popup_Title_Error: "Грешка",
	Popup_Title_Success: "Успех",
	Popup_Title_Info: "Информация",
	Popup_Title_Warning: "Внимание!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Следните вече съществуващи потенциални регистрации за обучения няма да бъдат анулирани и ще трябва да бъдат анулирани индивидуално.",
	TrainingProgram_PDF_Export: "PDF експорт",
	TrainingProgram_PDF_Export_Participant: "Участник:",
	TrainingProgram_PDF_Export_Status: "Статус:",
	TrainingProgram_PDF_Export_Progress: "Цялостен прогрес:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Заглавие на обучението",
	TrainingProgram_PDF_Export_TableHeader_Status: "Статус",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Степен",
	TrainingProgram_PDF_Export_TableHeader_Date: "Дата",
	AccountButton_CompanyChange_MenuItem: "Промяна на компанията",
	SelectCompanyModal_Title: "Изберете компания",
	CloseButton: "Затвори",
	Change_Button: "Промяна",
	ChangeManagerModal_Content: "Изберете потребител",
	ChangeCompanyModal_HeadOffice: "(Централа)",
	SelectCompanyModal_Content: "Моля, изберете компания, за да влезете...",
	AlertSuccess_Title: "Успех",
	CheckOutStepPayment_SecondaryPayment_Title: "Изберете вторичен метод на плащане",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Добавете вторичен начин на плащане",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Моля, добавете вторичен метод на плащане по-долу или изберете друг метод на плащане по-горе.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Нямате достатъчно кредити за услуги в акаунта си, за да платите артикулите в количката си. За да продължите да използвате избрания метод на плащане, трябва да заплатите разликата. Разликата е [difference_amount] (без ДДС).",
	ShoppingCartTotalPrice_TotalInCredit: "Обща цена в кредити",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Вашите налични кредити",
	ShoppingCartTotalPrice_YourDifference: "Разлика",
	ShoppingCartTotalPrice_SubtotalExTax: "Междинна сума без данък",
	ShoppingCartTotalPrice_SubtotalInTax: "Междинна сума с данък",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Вторичен метод на плащане",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Редактиране на начина на плащане",
	Checkout_Second_PaymentMethod: "Втори начин на плащане",
	Breadcrumb_TrainingCourseDetails: "Подробности за курса на обучение",
	DownloadArea_FailedToDownload_ErrorMessage: "Неуспешно изтегляне на документа. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Файлът за изтегляне не е наличен. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Изтеглянето на файла е забранено. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	SSO_Redirecting_Message: "Вие сте пренасочен към Automotive Training Solutions...",
	SSO_Redirecting_ParameterErrorMessage: "Държава и/или език не са посочени. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Заявките за записване не са приети.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Това може да се дължи на това, че сте в рамките на дните за регистрация на курса за един или повече от курсовете. Моля, използвайте зоната „Свържете се с нас“, ако имате въпроси или искания.",
	TimeSlotPicker_DateFormat_Title: "Формат на датата",
	UpdateAccountSettingsConfirmationModal_Title: "На път сте да промените формата на датата!",
	UpdateAccountSettingsConfirmationModal_Message1: "Форматът на датата ще се покаже според вашия избор и може да бъде променен по всяко време.",
	UpdateAccountSettingsConfirmationModal_Message2: "Искате ли да продължите?",
	ProfileSummary_Settings_Title: "Настройки",
	UpdateAccountSettings_DateFormat_Title: "Настройки на формата на датата",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Неуспешно актуализиране на формата на датата.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Настройката за формат на датата е актуализирана успешно.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Изтегляне на сертификат",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Сертификат за програма за обучение",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Неуспешно изтегляне на документа. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Предстоящото оттегляне е надхвърлило прага за анулиране",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Предстоящото оттегляне е надхвърлило прага за анулиране и изсква ръчна обработка от администратора.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Окончателното потвърждение ще бъде изпратено по имейл.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Моля, вижте нашата [cancellation policy] за повече подробности.",
	TrainingCourse_Course_NotAvailable_Title: "Курсът за обучение не е наличен.",
	TrainingCourse_Course_NotAvailable_Message: "Курсът за обучение не е наличен. Моля, опитайте отново или се свържете с вашия фирмен администратор за съдействие.",
	TrainingCourse_NotAvailable_Message_1: "Имате достъп до обучителния курс, който не е наличен.",
	TrainingCourse_NotAvailable_Message_2: "Моля, опитайте отново или отворете [курсове за обучение], за да откриете още курсове за обучение.",
	LanguageSelection_SelectALanguage: "Изберете език",
	LanguageSelection_SelectYourCountry: "Избери твоята държава",
	Checkout_PaymentMethod_Headline: "Начин на плащане",
	Download_Categories_Catalogue: "Каталог",
	Download_Categories_Calendar: "Календар",
	Download_Categories_General: "Общо",
	Download_Categories_Information: "Информация",
	Download_Categories_Userguides: "Ръководства за потребителя",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "правила за анулиране",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Отказ",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Продължи",
	Duration_Seconds: "Секунди",
	Duration_Day: "Ден",
	Duration_Hour: "Час",
	Duration_Minute: "Минута",
	Duration_Second: "Секунда",
	EventCalendar_ExternalLink_Tooltips: "Купете чрез [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Отказ",
	EventCalendar_ExternalLink_Continue: "Продължи",
	EventCalendar_ExternalLinkModal_Title: "Ще бъдете пренасочени...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Избраното събитие се продава чрез [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Продължаването ще ви пренасочи към външен уебсайт.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Искате ли да продължите?",
	Team_ContactPerson_FirstName_Tooltips: "Имената на участниците могат да се променят само от административния екип на Bosch за обучение. Моля, свържете се с нас чрез формата за контакт на този уебсайт за допълнителна поддръжка.",
	Team_ContactPerson_LastName_Tooltips: "Имената на участниците могат да се променят само от административния екип на Bosch за обучение. Моля, свържете се с нас чрез формата за контакт на този уебсайт за допълнителна поддръжка.",
	Withdraw_ExternalLink_Cancel: "Отказ",
	Withdraw_ExternalLink_Confirm: "Потвърдете",
	Withdraw_ExternalLinkModal_Title: "Ще бъдете пренасочени",
	Withdraw_ExternalLinkModal_Message: "Оттегляне за този курс се обработват от външен доставчик.",
	Withdraw_ExternalLinkModal_Confirmation: "Щракването върху \"Потвърждаване\" ще отвори нов раздел и ще ви пренасочи към външен уебсайт.",
	Button_AddToWaitingList_Tooltips: "Добавяне към списъка с чакащи",
	Button_AddToCart_Tooltips: "Количка",
	Button_TrainingCourseDetail_Tooltips: "Виж детайлите",
	Button_TrainingProgramDetail_Tooltips: "Виж детайлите",
	Button_AssignTrainingProgram_Tooltips: "Задайте програма за обучение",
	Button_DeleteFromCart_Tooltips: "Изтриване от количката",
	Button_RemoveParticipant_Tooltips: "Премахване на участник",
	Button_DownloadBillingTransaction_Tooltips: "Изтегляне",
	Button_RemoveExternal_Tooltips: "Премахване"
};