import LoadableComponent from "../Common-components/loadable/index";

export const applicationRouters: any[] = [
  {
    path: "/",
    name: "Home",
    title: "Home",
    crumb: "Breadcrumb_Home",
    icon: "Home",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/Home")),
  },
  {
    path: "/sso",
    name: "SSO",
    title: "SSO",
    crumb: "SSO",
    icon: "SSO",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/sso/SSOPage")
    ),
  },
  {
    path: "/contacts",
    name: "Contact",
    title: "Contact",
    crumb: "Breadcrumb_Contact",
    icon: "Contact",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/ContactUs")),
  },

  {
    path: "/support",
    name: "Support",
    title: "Support",
    icon: "Support",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Support/Support")
    ),
  },
  // {
  //   path: "/login",
  //   name: "My Account",
  //   title: "My Account",
  //   icon: "My Account",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(() => import("../components/views/Login")),
  // },
  // {
  //   path: "/support",
  //   name: "Support",
  //   title: "Support",
  //   icon: "Support",
  //   exact: true,
  //   isProtected: false,
  //   permission: "",
  //   component: LoadableComponent(() => import("../components/views/Support")),
  // },
  // {
  //   path: "/login",
  //   name: "My Account",
  //   title: "My Account",
  //   icon: "My Account",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(() => import("../components/views/Login")),
  // },
  {
    path: "/stories",
    name: "Stories",
    title: "stories",
    crumb: "Breadcrumb_Stories",
    icon: "stories",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Stories/Stories")
    ),
  },

  {
    path: "/stories/bosch-service-training",
    name: "Bosch Service Training",
    title: "Bosch Service Training",
    crumb: "Breadcrumb_Stories_BoschServiceTraining",
    icon: "bosch-training-service",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Stories/BoschServiceTraining")
    ),
  },
  {
    path: "/stories/a-day-in-the-life-of-a-trainer",
    name: "A day in the life of a trainer",
    title: "A day in the life of a trainer",
    crumb: "Breadcrumb_Stories_ADayInLife",
    icon: "A day in the life of a trainer",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Stories/ADayInLifeOfTrainer")
    ),
  },
  {
    path: "/stories/bosch-training-benefits",
    name: "Bosch Training Benefits",
    title: "Bosch Training Benefits",
    crumb: "Breadcrumb_Stories_BoschTrainingBenefits",
    icon: "Bosch Training Benefits",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Stories/BoschTrainingBenefits")
    ),
  },
  {
    path: "/stories/a-modern-training-approach",
    name: "A modern training approach",
    title: "A modern training approach",
    crumb: "Breadcrumb_Stories_TrainingApproach",
    icon: "A modern training approach",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Stories/AModernTrainingApproach")
    ),
  },
  {
    path: "/settings",
    name: "Settings",
    title: "Settings",
    crumb: "Breadcrumb_Settings",
    icon: "Settings",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/Settings/SettingsMainPage")
    ),
  },
  // {
  //   path: "/my-profile",
  //   name: "Breadcrumb_Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsMyProfile")
  //   ),
  // },
  // {
  //   path: "/my-team",
  //   name: "Breadcrumb_Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsTeam")
  //   ),
  // },
  // {
  //   path: "/my-hardware",
  //   name: "Breadcrumb_Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsMyHardware")
  //   ),
  // },
  // {
  //   path: "/my-subscriptions",
  //   name: "Breadcrumb_Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsMySubscriptions")
  //   ),
  // },
  // {
  //   path: "/my-billing",
  //   name: "Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsMyBilling")
  //   ),
  // },
  // {
  //   path: "/update-personal-info",
  //   name: "Settings",
  //   title: "Settings",
  //   icon: "Settings",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../components/views/MySettings/SettingsUpdatePersonalInfo")
  //   ),
  // },
  // {
  //     path: "/shopping-cart2", name: "Shopping Cart", title: "Shopping Cart", icon: "Shopping Cart", exact: true,
  //     isProtected: false, permission: '',
  //     component: LoadableComponent(() => import('../components/views/ShoppingCart'))
  // },
  {
    path: "/shopping-cart/:workshopId?",
    name: "Shopping Cart",
    title: "Shopping Cart",
    crumb: "Breadcrumb_ShoppingCart",
    icon: "Shopping Cart",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/ShoppingCart")
    ),
  },
  {
    path: "/checkout",
    name: "Check Out",
    title: "Check Out",
    crumb: "Breadcrumb_Checkout",
    icon: "Check Out",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(() => import("../components/views/Checkout")),
  },
  {
    path: "/order-summary/:id",
    name: "Order Summary",
    title: "My Order",
    crumb: "Breadcrumb_OrderSummary",
    icon: "My Orders",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/OrderSummaryComponent")
    ),
  },
  // {
  //     path: '/privacy-policy/:lang', exact: true, name: 'Privacy Policy', title: 'Privacy Policy',
  //     isProtected: false, permission: '',
  //     component: LoadableComponent(() => import('../Common-components/PrivacyPolicy/PrivacyPolicy')),
  // },
  {
    path: "/exception?:type",
    exact: true,
    name: "exception",
    title: "exception",
    crumb: "Breadcrumb_Exception",
    icon: "info-circle",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/Exceptions/Exceptions")
    ),
  },
  // {
  //   path: "/my-dashboardv2/:activetab?",
  //   name: "Dashboard",
  //   title: "My Dashboard",
  //   crumb: "Breadcrumb_Dashboard",
  //   icon: "My Dashboard",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../Common-components/MyDashboard/MyDashboardV2")
  //   ),
  // },
  // {
  //   path: "/my-dashboard/my-courses",
  //   name: "My Courses",
  //   title: "My Courses",
  //   crumb: "Breadcrumb_MyCourses",
  //   icon: "My Dashboard",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../Common-components/MyDashboard/MyCourses")
  //   ),
  // },
  // {
  //   path: "/my-dashboard/my-elearning",
  //   name: "My eLearning",
  //   title: "My eLearning",
  //   crumb: "Breadcrumb_MyELearning",
  //   icon: "My Dashboard",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../Common-components/MyDashboard/MyELearning")
  //   ),
  // },
  {
    path: "/my-dashboard/workshops",
    name: "Company Administration",
    title: "Company Administration",
    crumb: "Breadcrumb_CompanyAdministration",
    icon: "Company Administration",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/MyDashboard/CompanyAdministration")
    ),
  },
  {
    path: "/my-dashboard/activate-account",
    name: "Active Account",
    title: "Active Account",
    crumb: "Breadcrumb_CompanyAdministration",
    icon: "Active Account",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/MyDashboard/JoinCompanyPage")
    ),
  },
  {
    path: "/my-dashboard/workshops/employee-management/:id",
    name: "Employee Management",
    title: "Company Employee Management",
    crumb: "Breadcrumb_EmployeeManagement",
    icon: "Company Employee Management",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/CompanyAdministration/CompanyEmployeeAdministration"
        )
    ),
  },
  {
    path: "/my-dashboard/workshops/employee-management/employee-account/:guid",
    name: "Employee Account",
    title: "Employee Account",
    crumb: "Breadcrumb_EmployeeAccount",
    icon: "Employee Account",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/EmployeeAccountModification"
        )
    ),
  },
  {
    path: "/my-dashboard/workshops/employee-management/employee-creation",
    name: "Employee Creation",
    title: "Employee Creation",
    crumb: "Breadcrumb_EmployeeCreation",
    icon: "Employee Account",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/EmployeeAccountCreation"
        )
    ),
  },
  {
    path: "/my-dashboard/administration",
    name: "Administration",
    title: "Administration",
    crumb: "Breadcrumb_Administration",
    icon: "Administration",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/MyDashboard/Administration")
    ),
  },
  {
    path: "/administration/employee-administration/employee-creation/:workshopId?",
    name: "Employee Administration",
    title: "Employee Administration",
    crumb: "Breadcrumb_EmployeeAdministration",
    icon: "Employee Administration",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/Dashboard/Team/Administration")
    ),
  },
  {
    path: "/my-dashboard/administration/employee-administration",
    name: "Employee Administration",
    title: "Employee Administration",
    crumb: "Breadcrumb_EmployeeAdministration",
    icon: "Employee Administration",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/EmployeeAdministration"
        )
    ),
  },
  {
    path: "/my-dashboard/administration/employee-administration/employee-account/:guid",
    name: "Employee Account",
    title: "Employee Account",
    crumb: "Breadcrumb_EmployeeAccount",
    icon: "Employee Account",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/EmployeeAccountModification"
        )
    ),
  },
  {
    path: "/my-dashboard/administration/employee-administration/employee-creation/:workshopId?",
    name: "Employee Creation",
    title: "Employee Creation",
    crumb: "Breadcrumb_EmployeeCreation",
    icon: "Employee Creation",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/EmployeeAccountCreation"
        )
    ),
  },
  {
    path: "/my-dashboard/administration/wholesaler-management",
    name: "Wholesaler Management",
    title: "Wholesaler Management",
    crumb: "Breadcrumb_WholesalerManagement",
    icon: "Wholesaler Management",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/WholesalerManagement"
        )
    ),
  },
  {
    path: "/my-dashboard/administration/wholesaler-management/wholesaler-creation",
    name: "New Wholesaler Account Creation",
    title: "New Wholesaler Account Creation",
    crumb: "Breadcrumb_NewWholesalerAccountCreation",
    icon: "New Wholesaler Account Creation",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/MyDashboard/Administration/WholesalerAccountCreation"
        )
    ),
  },
  {
    path: "/my-dashboard/administration/transactions",
    name: "Transactions",
    title: "Transactions",
    crumb: "Breadcrumb_Transactions",
    icon: "Transactions",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () =>
        import("../Common-components/MyDashboard/Administration/Transactions")
    ),
  },
  {
    path: "/mytraining/curricula/training-program-details",
    name: "Training program details",
    title: "Training program details",
    crumb: "Breadcrumb_MyTraining_Curricula_TrainingProgramDetails",
    icon: "Training program details",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/Dashboard/Curricula/TrainingProgramDetails"
        )
    ),
  },
  {
    path: "/mytraining/course-details",
    name: "Course details",
    title: "Course details",
    crumb: "Breadcrumb_MyTraining_CourseDetails",
    icon: "Course details",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/Dashboard/Courses/CourseDetails/CourseDetails"
        )
    ),
  },
  // {
  //   path: "/my-dashboard/my-account",
  //   name: "My Account",
  //   title: "My Account",
  //   crumb: "Breadcrumb_MyAccount",
  //   icon: "My Dashboard",
  //   exact: true,
  //   isProtected: true,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../Common-components/MyDashboard/MyAccount")
  //   ),
  // },
  {
    path: "/training-centers",
    name: "Training Centers",
    title: "Training Centers",
    crumb: "Breadcrumb_TrainingCenters",
    icon: "Training Centers",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/TrainingCentres")
    ),
  },
  {
    path: "/event-calendar",
    name: "Event Calendar",
    title: "Event Calendar",
    crumb: "Breadcrumb_EventCalendar",
    icon: "Subscriptions",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/EventCalendar")
    ),
  },
  {
    path: "/training-course-catalogue",
    name: "Training Course Catalogue",
    title: "Training Course Catalogue",
    crumb: "Breadcrumb_TrainingCourseCatalogue",
    icon: "Training Course Catalogue",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/TrainingCourseCatalogue")
    ),
  },
  {
    path: "/training-course-catalogue/training-course-detail",
    name: "Training Course Details",
    title: "Training Course Details",
    crumb: "Breadcrumb_TrainingCourseDetails",
    icon: "Training Course Details",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/TrainingCatalogue/CourseDetailView")
    ),
  },
  {
    path: "/:country/course/:id",
    name: "Training Course Details",
    title: "Training Course Details",
    crumb: "Breadcrumb_TrainingCourseDetails",
    icon: "Training Course Details",
    exact: false,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/TrainingCatalogue/TrainingCourseDetail/TrainingCourseDetail"
        )
    ),
  },
  {
    path: "/course-detail",
    name: "Training Course Details",
    title: "Training Course Details",
    crumb: "Breadcrumb_TrainingCourseDetails",
    icon: "Training Course Details",
    exact: false,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/TrainingCatalogue/TrainingCourseDetail/CourseDetail"
        )
    ),
  },
  // {
  //   path: "/training-course-detail",
  //   name: "Training Course Catalogue",
  //   title: "Training Course Catalogue",
  //   crumb: "Breadcrumb_TrainingCourseCatalogue",
  //   icon: "Training Course Catalogue",
  //   exact: true,
  //   isProtected: false,
  //   permission: "",
  //   component: LoadableComponent(
  //     () => import("../Common-components/TrainingCatalogue/CourseDetailView")
  //   ),
  // },
  {
    path: "/training-centers/recommended-hotel",
    name: "Recommended Hotels",
    title: "Recommended Hotels",
    crumb: "Breadcrumb_RecommendedHotels",
    icon: "Recommended Hotels",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/RecommendedHotels")
    ),
  },
  {
    path: "/legal/index",
    exact: false,
    name: "Data Protection Notice",
    title: "Data Protection Notice",
    crumb: "Breadcrumb_DataProtectionNotice",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Info/InfoRedirect")
    ),
  },
  {
    path: "/corporate-information",
    exact: true,
    name: "Corporate Information",
    title: "Corporate Information",
    crumb: "Breadcrumb_CorporateInformation",
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/Info/Info")),
  },
  {
    path: "/legal-notice",
    exact: true,
    name: "Legal Notice",
    title: "Legal Notice",
    crumb: "Breadcrumb_LegalNotice",
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/Info/Info")),
  },
  {
    path: "/data-protection-notice/:tabindexparam",
    exact: true,
    name: "Data Protection Notice",
    title: "Data Protection Notice",
    crumb: "Breadcrumb_DataProtectionNotice",
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/Info/Info")),
  },
  {
    path: "/privacy-policy/:lang",
    exact: true,
    name: "Privacy Policy",
    crumb: "Breadcrumb_PrivacyPolicy",
    title: "Privacy Policy",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/PrivacyPolicy/PrivacyPolicy")
    ),
  },
  {
    path: "/term-condition/:lang",
    exact: true,
    name: "Term & Condition",
    title: "Term & Condition",
    crumb: "Breadcrumb_TermCondition",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/TermAndCondition/TermAndCondition")
    ),
  },
  {
    path: "/gb/terms-of-use/terms/",
    exact: true,
    name: "Terms",
    title: "Term | Bosch Training Solution",
    crumb: "Breadcrumb_Terms",
    isProtected: false,
    permission: "",
    component: LoadableComponent(() => import("../components/views/gb/term")),
  },
  {
    path: "/gb/terms-of-use/privacy-statement/",
    exact: true,
    name: "Privacy Statement",
    title: "Privacy Statement | Bosch Training Solution",
    crumb: "Breadcrumb_PrivacyStatement",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/gb/privacy-statement")
    ),
  },
  {
    path: "/gb/terms-of-use/cookies/",
    exact: true,
    name: "Cookies",
    title: "Cookies | Bosch Training Solution",
    crumb: "Breadcrumb_Cookies",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/gb/cookies")
    ),
  },
  {
    path: "/gb/terms-of-use/provider/",
    exact: true,
    name: "Provider",
    title: "Provider | Bosch Training Solution",
    crumb: "Breadcrumb_Provider",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/gb/provider")
    ),
  },
  {
    path: "/gb/terms-of-use/legal-notice/",
    exact: true,
    name: "Legal Notice",
    title: "Legal Notice | Bosch Training Solution",
    crumb: "Breadcrumb_LegalNotice",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/gb/legal-notice")
    ),
  },
  {
    path: "/RegisterPersonalAccount/:paramCountryCode/:paramLanguageCode/:guid",
    exact: true,
    name: "Register Account",
    title: "Register Account | Bosch Training Solution",
    crumb: "Breadcrumb_RegisterAccount",
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Registration/RegisterPersonalAccount")
    ),
  },
  {
    path: "/my-dashboard/:activetab?",
    name: "Dashboard",
    title: "My Dashboard",
    crumb: "Breadcrumb_MyTraining",
    icon: "My Dashboard",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/MyDashboard/MyDashboardV2")
    ),
  },
  {
    path: "/dashboard/:activetab?",
    name: "MyTraining",
    title: "My Dashboard",
    crumb: "Breadcrumb_MyTraining",
    icon: "My Dashboard",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/Dashboard/MyDashboardV3")
    ),
  },
  {
    path: "/training-program",
    name: "Training Program Catalogue",
    title: "Training Program Catalogue",
    crumb: "Breadcrumb_TrainingProgram",
    icon: "Subscriptions",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/TrainingProgram")
    ),
  },
  {
    path: "/employee-dashboard/employee/:activetab?",
    name: "Employee Management",
    title: "Company Employee Management",
    crumb: "Breadcrumb_EmployeeManagement",
    icon: "Company Employee Management",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../Common-components/EmployeeDashboard/EmployeeDashboard")
    ),
  },
  {
    path: "/myprofile",
    name: "My Profile",
    title: "My Profile",
    crumb: "MyProfile_Profile",
    icon: "My Profile",
    exact: true,
    isProtected: true,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/MyProfile/MyProfile")
    ),
  },
  {
    path: "/training-program/training-program-detail",
    name: "Employee Management",
    title: "Company Employee Management",
    crumb: "Breadcrumb_MyTraining_Curricula_TrainingProgramDetails",
    icon: "Company Employee Management",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () =>
        import(
          "../Common-components/TrainingProgramLayout/TrainingProgramDetailPage"
        )
    ),
  },
  {
    path: "/download-files",
    name: "Download Page",
    title: "Download Page",
    crumb: "Breadcrumb_Download_Page",
    icon: "Download Page",
    exact: true,
    isProtected: false,
    permission: "",
    component: LoadableComponent(
      () => import("../components/views/Support/downloadPage/downloadPage")
    ),
  },
];
