import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Footer from "../Common-components/Footer/Footer";
import Header from "../Common-components/Header/Header";
import RegionAndLanguage from "../components/views/LandingPage";
import {
  selectCountryCode,
  selectIsSignIn,
} from "../redux-toolkit/authReducer";
import { AuthService } from "../services/AuthService";
import ScrollToTop from "../utils/ScrollToTop";
import NotFoundRouter from "./NotFoundRouter";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { applicationRouters } from "./router.config";
import SignInCallback from "../scenes/SignInCallback";
import SignOutCallback from "../scenes/SignOutCallback";
import EditProfileCallback from "../scenes/EditProfileCallback";
import { Region } from "../models/RegionState";
import LaunchLinkRedirect from "../components/views/LaunchLinkRedirect";
import SSOPage from "../components/views/sso/SSOPage";

interface IBatsRouter {
  regions: Region[];
}

const Router: React.FC<IBatsRouter> = ({ regions }) => {
  //const authService = AuthService.getInstance();
  const isSignIn = useSelector(selectIsSignIn);
  const countryCode = useSelector(selectCountryCode);
  const auth = AuthService.getAuthState();
  useEffect(() => {}, [isSignIn]);
  useEffect(() => {}, [countryCode]);

  return (
    <div>
      <ScrollToTop />
      <div className="w-screen h-screen flex justify-center p-8">
        <Switch>
          <Route path="/regions" exact component={RegionAndLanguage} />
          <Route
            path="/editprofilecallback"
            exact
            component={EditProfileCallback}
          />
          <Route path="/signincallback" exact component={SignInCallback} />
          <Route path="/signoutcallback" exact component={SignOutCallback} />
          <Route path="/sso" exact component={SSOPage} />
          <Route
            path="/launchlinkredirect"
            exact
            component={LaunchLinkRedirect}
          />
          <Route path="/de/" exact>
            <Redirect to="/" />
          </Route>
          {applicationRouters.map((route: any, index: any) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(routeProps) => {
                const crumbs = applicationRouters
                  .filter(({ path }) => routeProps.match.path.includes(path))
                  .map(({ path, ...rest }) => ({
                    path: path,
                    ...rest,
                  }));
                const renderRoute = route.isProtected ? (
                  <ProtectedRoute
                    key={index}
                    {...routeProps}
                    exact={route.exact}
                    isSignIn={isSignIn}
                    countryCode={countryCode}
                    domain={auth.domain}
                    component={route.component}
                    permission={route.permission}
                  />
                ) : (
                  <PublicRoute
                    key={index}
                    {...routeProps}
                    exact={route.exact}
                    countryCode={countryCode}
                    domain={auth.domain}
                    path={route.path}
                    component={route.component}
                  />
                );

                return (
                  <div className="p-8">
                    <span></span>
                    <Header crumbs={crumbs}></Header>
                    <div
                      className={`grid-responsive-width body-container ${
                        window.location.pathname === "/"
                          ? "home-page-wrapper"
                          : ""
                      } ${
                        window.location.pathname === "/checkout"
                          ? "checkout-page-wrapper"
                          : ""
                      }`}
                    >
                      {renderRoute}
                    </div>
                  </div>
                );
              }}
            />
          ))}
          <Route component={NotFoundRouter} />
        </Switch>
      </div>
      {!(
        window.location.href.includes("/signincallback") ||
        window.location.href.includes("/signoutcallback") ||
        window.location.href.includes("/sso") ||
        window.location.href.includes("/editprofilecallback") ||
        window.location.href.includes("/launchlinkredirect")
      ) && <Footer />}
    </div>
  );
};

export default Router;
