export const TRANSLATIONS_ZH_SG = {
	LandingPage_Title: "欢迎使用博世汽车培训解决方案。",
	LandingPage_SubTitle_ChooseCountry: "选择您的国家/地区。",
	Header_ContactUs: "联系我们。",
	Header_Search: "搜索。",
	Header_Menu: "菜单。",
	Header_LogInOrCreateYourAccount: "登录或创建您的帐户。",
	Header_LogIn: "登录。",
	Header_Cart: "购物车。",
	Header_ShoppingCart: "购物车。",
	Header_LogOut: "注销。",
	Header_ChangeDetails: "更改详细信息。",
	Footer_ExploreOurTraining: "探索我们的培训。",
	Footer_TrainingAtYourFingertips: "触手可及的培训。",
	Footer_DontMissAnyStory: "不要错过任何故事。",
	Footer_ExploreOurStories: "探索我们的故事。",
	Footer_GetInTouchWithUs: "与我们联系。",
	Footer_SendUsAMessage: "给我们发送消息。",
	Footer_ToContactForm: "联系表格。",
	Footer_InventedForLife: "终身发明。",
	Footer_GeneralContactInfo: "一般联系信息。",
	Footer_ProductSecurity_Psirt: "产品安全性(PSIRT)。",
	Footer_InnovationsPatentsAndLicenses: "创新专利和许可证。",
	Footer_PurchasingLogistics: "采购与物流。",
	Footer_CorporateInformation: "公司信息。",
	Footer_LegalNotice: "法律声明。",
	Footer_DataProtectionNotice: "数据保护通知。",
	Footer_PrivacySettings: "隐私设置。",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch Gmbh 2021保留所有权利。",
	Footer_StillLookingForSomething: "仍在寻找东西。",
	Footer_TrainingServices: "培训服务。",
	Footer_Courses: "课程。",
	Footer_Programs: "程序。",
	Footer_EventsCalendar: "活动日历。",
	Footer_TrainingCenters: "培训中心。",
	Footer_Dashboard: "仪表板。",
	Footer_MyDashboard: "我的仪表板。",
	Footer_ShoppingCart: "购物车。",
	Footer_YourShoppingCart: "您的购物车。",
	Footer_Administration: "管理。",
	Footer_Employees: "员工。",
	Footer_Wholesalers: "批发商。",
	Footer_Transactions: "交易。",
	Footer_Workshops: "讲习班。",
	Footer_WorkLikeABoschApplyNow: "工作-立即申请。.",
	Footer_BoschGlobalCareers: "博世全球职业。",
	Footer_PurchasingAndLogistics: "采购和物流。",
	Footer_BecomeABusinessPartner: "成为业务合作伙伴。.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "我们的培训如何发展以帮助您开展业务。",
	Footer_Language: "Country select",
	MenuPopup_MyCart: "我的购物车。",
	Stories_OurTrainingStories: "我们的培训故事。",
	Stories_ConnectingExperts: "连接专家和发烧友梦想家和行动者创新者和影响者-探索和经验。",
	Stories_TopStories: "热门故事。",
	Stories_AtHome: "在家。",
	Stories_Careers: "职业。",
	Stories_History: "历史。",
	Stories_IndustryAndTrades: "工业和贸易。",
	Stories_Mobility: "流动性。",
	Stories_Research: "研究。",
	Stories_Sustainability: "可持续性。",
	Stories_AllStories: "所有故事。",
	Stories_BlogATrainersDay: "博客|培训师的一天。",
	Stories_ADayinLifeOfATrainer: "培训师一生中的一天。",
	Stories_BoschServiceTraining: "博世服务培训。",
	Stories_StoryWhatDrivesYouDrivesUs: "故事|是什么驱使您驱使我们。",
	Stories_StoryHowCanWeBenefitYourBusiness: "故事|我们如何使您的业务受益。",
	Stories_HowOurTrainingEvolves: "我们的培训如何发展。",
	Stories_AModernTrainingApproach: "在线培训世界。",
	Stories_StoryAVirtualOnlineTrainingWorld: "故事|您的在线培训机会。",
	Stories_SubscribeToRSSFeed: "订阅RSS feed。",
	Title_Main: "博世汽车培训解决方案。",
	Homepage_AboutUs: "关于我们。",
	Homepage_AutomotiveAftermarket: "汽车售后市场。",
	Homepage_GetInTouch: "取得联系。",
	Homepage_LatestNews: "找出为什么我们受到对知识的渴望。",
	Homepage_MainTitle: "博世汽车培训解决方案。",
	Homepage_OurOpenPositions: "我们的职位空缺。",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "是什么驱使您驱动我们。",
	BoschServiceTraining_BoschServiceTraining: "博世服务培训。",
	BoschServiceTraining_ShareThisOn: "分享这个。",
	BoschServiceTraining_TheChallenge: "挑战:掌握新技术。",
	BoschServiceTraining_WorldwidePresence: "全球存在。",
	BoschServiceTraining_TechnicalTraining: "博世的技术培训课程。",
	BoschServiceTraining_TheOffersAndOpportunities: "我们在博世提供的优惠和机会。",
	BoschServiceTraining_TechnicalCoursesDesc: "每个目标群体都有合适的培训课程-无论是新手还是专业人士。. 该产品包括为期一天的培训课程和为期几天的培训课程。. 培训课程在不同的技术领域和系列培训(例如汽油喷射柴油喷射电气系统)中提供。. 在技术培训课程中,培训课程还讨论了博世从其他制造商的博世系统在所有标准车辆系统中提供的广泛专业知识。. 内容与车间员工的要求特别匹配。.",
	BoschServiceTraining_TrainedEmployeesDesc: "受过培训的员工对于汽车车间是显而易见的好处。. 因为当今的车辆变得越来越复杂,新技术不断带来新的挑战。. 只有必要的专业知识,车间员工才能以有效且经济高效的方式对当前车型进行诊断维护和维修工作。.",
	BoschServiceTraining_RegardlessOfHow: "无论汽车专家有多出色和有经验,总会在某些情况下他们会接触到新的车辆技术系统或未知的故障。. 在这方面,故障排除和问题解决可能会特别耗时,或者可能不会产生成功的结果。. 在最坏的情况下,客户必须比预期更长的时间没有车辆。.",
	BoschServiceTraining_ItIsPrecisely: "正是在这一点上,博世才承担了服务培训:提高服务质量和加快流程。. 博世目前的培训为更快地定位问题提供了支持,从而可以同时修复更多车辆并减少客户的等待时间。. 因此,在博世学习的系统程序可确保更快,更高效的工作。.",
	BoschServiceTraining_WorldwidePresenceDesc1: "125多年来,博世一直是全球许多领先汽车制造商的首选原始设备零件组件和系统的供应商。. 这种经验来自对最新技术的独特了解,这些知识是博世支持的基础。.",
	BoschServiceTraining_WorldwidePresenceDesc2: "博世服务培训中心遍布世界各地:从西班牙到土耳其南非澳大利亚再到巴西-博世为其员工提供面向实践的培训培训,该培训始终与最先进的技术保持一致。.",
	BoschServiceTraining_AgileCompany: "敏捷公司。",
	BoschServiceTraining_Collaboration: "合作。",
	BoschServiceTraining_ConnectedMobility: "互联移动。",
	BoschServiceTraining_ArtificialIntelligence: "人工智能。",
	BoschServiceTraining_ThoughtLeadersInDialogue: "对话中的思想领袖。",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "诊断和测试设备",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "汽车技术基础",
	BoschServiceTraining_Icon_Gasoline: "汽油",
	BoschServiceTraining_Icon_Diesel: "柴油机",
	BoschServiceTraining_Icon_AlternativeDrives: "替代驱动器",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "电气和电子",
	BoschServiceTraining_Icon_BodyAndComfort: "身体和舒适",
	BoschServiceTraining_Icon_ChassisAndBraking: "底盘和制动",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "变速箱和传动系统",
	BoschServiceTraining_Icon_CommercialVehicles: "商务车辆",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "在线培训课程",
	BoschServiceTraining_Icon_BusinessSkills: "商业技能",
	BoschServiceTraining_Icon_Accreditations: "认证",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "培训课程计划",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName。",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "培训师生活中的一天。",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "每天都是一次冒险。",
	ADayInTheLifeOfATrainer_Summary: "摘要。",
	ADayInTheLifeOfATrainer_TheDayJourney: "一天的旅程。",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "每天都是忙碌的一天,但始终是一种有益的经历。. 培训学生知道他们掌握了我们未来的关键是一种责任,但同时又如此令人兴奋。. 每天和每周看到每节课的学生成长,这向我们证明,我们正在朝着一个共同的目标努力,以提高他们的知识水平,同时采用推动未来的现代技术。!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "每天结束时,我们都会坐下来思考工作日。. 日子过得如此之快,但这本身就证明,我们所取得的成就不仅对学生有益,而且对我们作为培训师也有益。.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "我们可以在一天结束时回家,对我们做得很好感到满意。!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "开始",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "培训材料的准备和最终检查。 检查重要邮件",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "培训开始",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "欢迎参加者。 每日时间表演示。 通过了解每个人的动态来了解团队。",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "理论",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "需要与小组进行一定程度的互动才能使培训尽可能有趣。 确保有与理论交织在一起的任务，以进一步参与小组",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "午休",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "实际的",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "培训在研讨会上继续进行，有一些实际任务。 作为一名培训师，始终需要在场并随时可用以应对任何可能发生的情况和指导",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "回顾",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "回到教室做一个简短的讨论，讨论当天的训练。 回答可能出现的任何问题。 开始为接下来的培训课程做准备。",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "最后一天",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "出席证书颁发给参与者。",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "家，",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "我们如何帮助您的业务受益。",
	BoschTrainingBenefits_HowOurTrainingEvolves: "我们的培训如何发展。",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "我们正在不断发展,以确保我们提供最新的质量培训。",
	BoschTrainingBenefits_TheBoschServiceTraining: "博世服务培训中心希望通过其广泛且高度相关的技术/业务培训以及具有认证资格的继续教育课程来协助您发展业务。. 以下页面为您提供了我们培训计划的概述以及当前的继续教育机会。. 我们期待在我们的培训课程和研讨会上与您和您的员工见面。.",
	BoschTrainingBenefits_LongStandingExperience: "所有系统和汽车技术领域的长期经验和丰富的专业知识。.",
	BoschTrainingBenefits_QuickAndEfficient: "针对许多问题的快速有效的解决方案。.",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "优势和好处。",
	BoschTrainingBenefits_LMS: "学习管理系统-博世培训解决方案。",
	BoschTrainingBenefits_AcquiredKnowledge: "获得的知识设施维修维护和服务工作。.",
	BoschTrainingBenefits_TheHighDegree: "高度的实用内容和小组可以保证以目标为导向的学习。",
	BoschTrainingBenefits_ParticipationInTraining: "参加培训可以提高员工的积极性和满意度,补充资格可以说服客户。",
	BoschTrainingBenefits_IncreasedSales: "通过交叉制作系统知识增加销售。",
	BoschTrainingBenefits_MethodicalProcedures: "方法程序减少了客户的等待时间。",
	BoschTrainingBenefits_HighlyQualified: "高素质的讲师传达系统的程序。",
	BoschTrainingBenefits_IdentificationOftheTraining: "确定适合您或您的业务的培训解决方案。",
	BoschTrainingBenefits_DirectOnlineBooking: "直接为自己或您的员工在线预订和管理课程。",
	BoschTrainingBenefits_OnlineAccess: "在线访问学习文档。",
	BoschTrainingBenefits_AccessToYourOwn: "访问您自己的个人培训历史和证书。",
	BoschTrainingBenefits_ToolsForManagement: "管理自己职业发展的工具。",
	BoschTrainingBenefits_ManagerHasFull: "经理对系统内的员工课程有完整的概述。",
	BoschTrainingBenefits_Access24hours: "每周7天24小时访问。",
	ModernTrainingApproach_KnowledgeIsKey: "知识是任何研讨会的关键成功因素。. 因此,博世提供了全面的专业培训发展计划。. 这使学生能够在正确的时间和地点获得正确的知识-能够专业维护和维修客户车辆所需的知识。. 博世已将网络广播作为已经提供的电子学习软件包的新模块部分添加。. 它们包括专门为流媒体设计的服务培训课程。.",
	ModernTrainingApproach_ANewLearning: "一个新的学习机会。",
	ModernTrainingApproach_YourOnlineTraining: "您的在线培训机会。",
	ModernTrainingApproach_RecentlyTheWorld: "最近,世界发生了变化,看看我们正在做些什么来帮助。",
	ModernTrainingApproach_TheOnlineTrainingWorld: "在线培训世界。",
	ModernTrainingApproach_NewTeachingMethod: "新的教学方法。",
	ModernTrainingApproach_InAdditionTo: "除了现有的面对面培训外,我们还将实时网络广播培训纳入了培训计划。. 我们的培训师可为您提供虚拟教室中广泛的理论知识或实际示例。. 与往常一样,您将收到许多有关车辆系统和相应的博世产品的信息。. 当然,您也有机会提出问题。. 这些问题将直接在实时网络广播中回答。. 实时网络广播持续60到120分钟。. 需要稳定的互联网连接。. 通过在搜索字段中输入\ Webcast \,直接在我们的培训目录中搜索合适的网络广播。.",
	ModernTrainingApproach_BoschWebcastingHeader: "博世网络广播:创新和实用。",
	ModernTrainingApproach_BoschWebcastingText: "要首先参加博世网络广播,参与者必须拨入。. 在会话之前发送单个访问代码以启用登录。. 最多200位参与者可以同时从任意多个位置参加。. 培训由培训师主持,可以通过实时聊天功能随时提交问题。.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "课堂培训的数字补充。",
	ModernTrainingApproach_TheDigitalSupplementText: "网络广播和课堂培训完美地互补。. 在这两种情况下,都有合格的培训师领导培训。. 网络广播适用于无需在车辆上工作即可进行数字传输的主题。. 例如,了解新的驾驶员辅助系统的某些ESI [tronic]功能。. 当涉及车间的高效团队合作或车辆技术的实际工作时,面对面的培训仍然是首选。.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "流媒体可能更便宜。",
	ModernTrainingApproach_StreamingCanBeCheaperText: "通过网络广播,每位参与者的费用通常比类似的课堂课程要低。. 原因是更多的参与者可以从中受益,并且与培训相关的精力大大降低(不往返于该地点,所需时间更少)。.",
	ModernTrainingApproach_BookAndProfitHeading: "预定和利润。",
	ModernTrainingApproach_BookAndProfitText: "就像我们的其他培训课程和学习优惠一样,可以在我们的网站上预订网络广播。. 您可以选择为每个网络广播支付个人费用,也可以选择每年订阅一定数量的网络广播。.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "比较了博世网络广播和课堂培训。",
	ModernTrainingApproach_Webcasting: "网络广播。",
	ModernTrainingApproach_Classroom: "教室。",
	ModernTrainingApproach_WebcastingP1: "来自世界各地的最多200名参与者可以同时参加。",
	ModernTrainingApproach_WebcastingP2: "低复杂性和相对较低的成本,无需旅行。",
	ModernTrainingApproach_WebcastingP3: "特定且简洁的单个主题的理想选择。",
	ModernTrainingApproach_WebcastingP4: "讲习班可以随时随地为员工运行网络广播。",
	ModernTrainingApproach_WebcastingP5: "与课堂培训相比,所需时间减少了50-70%。",
	ModernTrainingApproach_ClassroomTrainingP1: "多达16位参与者同时开会。",
	ModernTrainingApproach_ClassroomTrainingP2: "差旅费和通常每人更高的费用。",
	ModernTrainingApproach_ClassroomTrainingP3: "在优化协作或直接在车辆上学习方面非常理想。",
	ModernTrainingApproach_ClassroomTrainingP4: "实际练习和与培训师的互动。",
	ModernTrainingApproach_ClassroomTrainingP5: "培训师可以根据参与者的实际需求更好地适应要教授的知识。",
	ModernTrainingApproach_OnlineTrainingOfferings: "在线培训产品(WBT / VBS)。",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "如今,计算机上的独立学习已成为知识转移不可或缺的一部分。. 选择时间和放置无限可重复性的自由,个人学习速度和交互式学习内容使这种方法成为必不可少的学习工具。.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "每个基于Web的培训(WBT)元素都经过精心设计。. 这些课程不仅提供广泛的知识基础,还可以有效地为博世培训中心的培训计划做准备。.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "虚拟诊断模拟为日常车间情况(例如在虚拟车间中使用Bosch诊断设备进行故障排除过程的工作流程)提供了用户角色扮演的可能性。.",
	ModernTrainingApproach_Advantages: "优势。",
	ModernTrainingApproach_AdvantagesP1: "一种快速有效的知识提供方法。",
	ModernTrainingApproach_AdvantagesP2: "学习多种多样。",
	ModernTrainingApproach_AdvantagesP3: "可以根据需要经常使用。",
	ModernTrainingApproach_AdvantagesP4: "最少的时间和金钱。",
	ModernTrainingApproach_AdvantagesP5: "个人学习速度。",
	ModernTrainingApproach_AdvantagesP6: "灵活的学习时间。",
	ModernTrainingApproach_AdvantagesP7: "可重复性。",
	ModernTrainingApproach_AdvantagesP8: "没有旅行费用。",
	ModernTrainingApproach_AdvantagesP9: "没有容量或计划冲突。",
	Dashboard_CoursesViewAndManageYourCourses: "课程|查看和管理您的课程。",
	Dashboard_MyCourses: "我的课程。",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "培训计划|查看您的培训计划。",
	Dashboard_MyTrainingProgram: "我的培训计划。",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "学习|查看和管理您的电子学习产品组合。",
	Dashboard_MyELearning: "我的电子学习。",
	Dashboard_AccountManageYourAccount: "帐户|管理您的帐户。",
	Dashboard_MyAccount: "我的帐户。",
	Dashboard_MyDashboard: "我的仪表板。",
	Dashboard_ManageYourAccount: "管理您的帐户。",
	Dashboard_Workshops: "讲习班。",
	Dashboard_AdministrationManageYourWorkshop: "管理|管理您的车间。",
	Dashboard_AdministrationManageYourTeams: "管理|管理您的团队。",
	Dashboard_Administration: "管理。",
	Dashboard_ViewYourCourses: "查看您的课程。",
	Dashboard_ViewYourTrainingPrograms: "查看您的培训计划。",
	Dashboard_TrainingPrograms: "培训计划。",
	Dashboard_ViewAndEditYourDetails: "查看和编辑您的详细信息。",
	Dashboard_ViewYourELearningCourses: "查看您的电子学习课程。",
	Dashboard_Pending: "待定。",
	Dashboard_RequiresAdministratorSignOff: "(需要管理员注销)。",
	Dashboard_HistoricalRecords: "历史记录。",
	Dashboard_Registered: "已注册。",
	Dashboard_WaitingList: "等待清单。",
	Dashboard_RecommendedForYou: "推荐给你。",
	Dashboard_AccountDetails: "帐户详细信息。",
	Dashboard_ChangeProfileImage: "更改个人资料图像。",
	Dashboard_Title: "标题。",
	Dashboard_Role: "角色。",
	Dashboard_FirstName: "名字。",
	Dashboard_LastName: "姓。",
	Dashboard_Telephone: "电话。",
	Dashboard_Mobile: "移动。",
	Dashboard_Email: "电子邮件。",
	Dashboard_Language: "语言。",
	Dashboard_Save: "保存。",
	Dashboard_Student: "学生。",
	Dashboard_WorkshopTrainingAdministrator: "讲习班培训管理员。",
	Dashboard_WholesalerTrainingAdministrator: "批发商培训管理员。",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "公司管理。",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "管理您的研讨会。...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "管理您的员工。...",
	Dashboard_CompanyAdministration_Workshop: "车间。",
	Dashboard_CompanyAdministration_Address: "地址。",
	Dashboard_CompanyAdministration_Zip: "邮编。",
	Dashboard_CompanyAdministration_City: "市。",
	Dashboard_CompanyAdministration_Manage: "管理。",
	Dashboard_AdministrationSection_AdministrationTitle: "管理。",
	Dashboard_AdministrationSection_ManageTheTeam: "管理团队。",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "管理|管理您的员工。",
	Dashboard_AdministrationSection_Employees: "员工。",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "管理|管理批发商。",
	Dashboard_AdministrationSection_Wholesalers: "批发商。",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "管理|管理您的交易。",
	Dashboard_AdministrationSection_Transactions: "交易。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "员工管理。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "管理您的员工。...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "注册。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "记录。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "编辑。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "员工。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "添加。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "创建。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "已注册。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "待定。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(需要管理员注销)。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "历史记录。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "等待清单。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "当前员工名单。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "员工管理。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "名称。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "员工帐户。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "查看和编辑员工详细信息。...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "设置。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "允许个人帐户。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "员工工作坊管理代理升级。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "允许员工注册培训活动。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "发送电子邮件邀请。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "停用帐户。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "保存。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "发送。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "编辑。",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "批发商管理。",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "管理您的批发商。...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "批准的批发商名单。",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "名称。",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "删除。",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "创建。",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "添加。",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "新员工帐户创建。",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "为新员工创建详细信息。...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "添加新的批准的批发商管理员。",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "为新批发商创建详细信息。...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "从下拉菜单中选择批准的批发商。...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "交易。",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "查看和管理您的交易。...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "背部。",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "发票编号。",
	Dashboard_AdministrationSection_TransactionsSection_Description: "说明。",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "付款方式。",
	Dashboard_AdministrationSection_TransactionsSection_Date: "日期。",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "金额。",
	Dashboard_AdministrationSection_TransactionsSection_Download: "下载。",
	TrainingCentres_TrainingCentres: "培训中心。",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "我们的培训中心遍布全球。",
	TrainingCentres_CountryCategory: "国家类别。",
	TrainingCentres_CityCategory: "城市类别。",
	TrainingCentres_Country: "国家。",
	TrainingCentres_City: "市。",
	TrainingCatalogue_TrainingCatalogue: "培训课程目录。",
	TrainingCatalogue_LearningAtTheHighestLevel: "最高水平的学习。",
	TrainingCatalogue_StartDate: "开始日期。",
	TrainingCatalogue_EndDate: "结束日期。",
	TrainingCatalogue_TrainingName: "培训名称。",
	TrainingCatalogue_Language: "语言。",
	TrainingCatalogue_Days: "天。",
	TrainingCatalogue_Seats: "座位。",
	TrainingCatalogue_Price: "价格。",
	TrainingCatalogue_Location: "位置。",
	TrainingCatalogue_PartNumber: "零件号。",
	TrainingCatalogue_TrainingCourse: "培训课程。",
	TrainingCatalogue_Category: "培训课程。",
	TrainingCatalogue_CourseName: "培训计划。",
	TrainingCatalogue_TotalDays: "总天数。",
	TrainingCatalogue_Venue: "场地。",
	TrainingCatalogue_Details: "细节。",
	TrainingCatalogue_Prerequisites: "先决条件。",
	TrainingCatalogue_CourseType: "课程类型。",
	TrainingCatalogue_LearningMethod: "学习方法。",
	TrainingCatalogue_AddToWaitingList: "添加到等待列表。",
	ShoppingCart_ShoppingCart: "购物车。",
	ShoppingCart_ReviewAndManage: "查看和管理您的订阅。",
	ShoppingCart_YouHaveAddedItem: "您已将以下项目添加到购物车中。",
	ShoppingCart_Item: "项目。",
	ShoppingCart_Quantity: "数量。",
	ShoppingCart_PricePerItem: "每件价格。",
	ShoppingCart_TotalPrice: "总价格。",
	ShoppingCart_VatIsIncluded: "不含. 增值税//包括或排除。?",
	ShoppingCart_Redeem: "如果您没有信用,请单击此处兑换。",
	ShoppingCart_Purchase: "如果您没有信用,请单击此处购买。",
	ShoppingCart_Checkout: "结帐。",
	ShoppingCart_ContinueShopping: "继续购物。",
	ShoppingCart_TotalPriceInclVat: "总价格含. 增值税。",
	ShoppingCart_Apply: "申请。",
	ShoppingCart_ShoppingCartEmpty: "您的购物车为空。",
	ShoppingCart_VatIncluded: "增值税(已包括在内)。",
	ShoppingCart_ManageYourShopping: "管理您的购物。",
	ShoppingCart_Participant: "参与者。",
	ShoppingCart_Complete: "完成。",
	ShoppingCart_NetPrice: "净价。",
	ShoppingCart_Workshop: "车间。",
	CheckOut_PreviewYourOrder: "预览您的订单。",
	CheckOut_YourShoppingCart: "购物车。",
	CheckOut_Preview: "预览。",
	CheckOut_Payment: "付款。",
	CheckOut_Summary: "摘要。",
	CheckOut_Back: "背部。",
	CheckOut_PleaseReadAndAgreeOurTerms: "请阅读并同意我们的条款和条件。",
	CheckOut_IHaveReadTheTermsAndCondition: "我已经阅读了[条款和条件],并且理解并接受它们。",
	CheckOut_YesIAgree: "是的-我同意条款和条件。",
	CheckOut_Continue: "继续。",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "恭喜你! 我们已收到您的订单。",
	CheckOut_OrderConfirmation: "成功处理请求后,您将收到最终订单确认。",
	CheckOut_WeWillContactYouDirectly: "如果对您的要求有任何疑问,我们将直接与您联系。",
	CheckOut_AddtoCart: "添加到购物车。",
	CheckOut_CreditCart: "信用车。",
	CheckOut_DirectDebit: "直接付款。",
	CheckOut_TrainingCredits: "培训学分。",
	CheckOut_PreferredWholesaler: "首选批发商。",
	EventCalendar_EventCalendar: "活动日历。",
	EventCalendar_TrainingEventsForAllYourNeeds: "满足您所有需求的培训活动。",
	TrainingCourse_TrainingCourse: "培训课程目录。",
	TrainingCourse_LearningAtTheHighestLevel: "最高水平的学习。",
	TrainingProgram_Title: "培训计划目录。",
	TrainingProgram_SubTitle: "最高水平的学习。",
	AccountDeactivationModal_Title: "您确定要停用此帐户吗??",
	AccountDeactivationModal_Line1: "停用此帐户是永久性的,将删除所有内容,包括服务请求交易,并平衡团队成员和个人资料设置。.",
	AccountDeactivationModal_Line2Part1: "如果在有效订阅期内,请参阅我们的取消政策。",
	AccountDeactivationModal_Line2Part2: "条款和条件。",
	AccountDeactivationModal_Line2Part3: "在提交您的请求之前。.",
	AccountDeactivationModal_Line3: "您确定要提交停用该帐户的请求吗??",
	AccountDeactivationModal_Cancel: "取消。",
	AccountDeactivationModal_Send: "发送。",
	CurrentUserAccountDeactivationModal_Title: "您确定要停用您的帐户吗??",
	CurrentUserAccountDeactivationModal_Line1: "停用您的帐户是永久性的,它将删除所有内容,包括服务请求交易,并平衡团队成员和个人资料设置。.",
	CurrentUserAccountDeactivationModal_Line2Part1: "如果您处于有效订阅期内,请参阅我们的取消政策。",
	CurrentUserAccountDeactivationModal_Line3: "您确定要提交停用帐户的请求吗??",
	CurrentUserAccountDeactivationModal_Yes: "是。",
	CurrentUserAccountDeactivationModal_No: "没有。",
	CourseWithdrawalModal_Title: "您的取消不是最终取消,直到我们的培训管理部门批准。.",
	CourseWithdrawalModal_Line1: "批准后,您将通过电子邮件收到最终确认。. 如有任何疑问,我们将直接与您联系。. 您确定要提交提款请求吗??",
	CourseWithdrawalModal_Line2Part1: "请阅读我们的。",
	CourseWithdrawalModal_Line2Part2Link: "取消政策。",
	CourseWithdrawalModal_Line2Part3: "继续撤回请求。?",
	CourseWithdrawalModal_Yes: "是。",
	CourseWithdrawalModal_No: "没有。",
	Settings_Settings: "设置。",
	Settings_ManageYourSettings: "管理您的设置。",
	Settings_Profile: "个人资料。",
	Settings_MyBoschGlobalIdLoginInformation: "我的博世全球ID登录信息。",
	Settings_Edit: "编辑。",
	Settings_CompanyDetails: "公司详细信息。",
	Settings_RequestAccountDeletion: "请求删除帐户。",
	Settings_AccountDeletionText: "如果您删除帐户,则所有数据(包括地址和付款数据)将被删除,并且将不再可用。.",
	Settings_DeleteAccountRequestConfirmation: "删除帐户请求确认。",
	Settings_DeleteAccountRequestConfirmationText: "我们已收到您的帐户删除请求,并将通过电子邮件或电话与您联系以进行最终确认。",
	RecommendedHotels_RecommendedHotels: "推荐酒店。",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "我们的培训中心遍布全球。",
	Error_Common: "出问题了。!",
	Error_404: "您访问的页面不存在。",
	Error_401: "您无权访问此页面。",
	Error_500: "服务器正在报告错误。",
	Dashboard_Courses_Table_Course: "课程",
	Dashboard_Courses_Table_Date: "日期 [天]",
	Dashboard_Courses_Table_Venue: "场地",
	Dashboard_Courses_Table_TrainingType: "培训类型",
	Dashboard_Courses_Table_Action: "行动",
	Dashboard_Courses_Table_TasksToComplete: "您有以下任务要完成",
	Dashboard_Courses_Table_Withdraw: "提取",
	Dashboard_Courses_Table_Remove: "消除",
	Dashboard_Courses_Table_View: "看法",
	Dashboard_Courses_Table_NoRecordsToDisplay: "无记录可显示",
	Dashboard_Courses_Table_CompletionDate: "完成日期",
	Dashboard_Courses_Table_Actions_Assignments: "作业",
	Dashboard_Courses_Table_Actions_Certification: "认证",
	Dashboard_Courses_Table_Actions_Evaluations: "评价",
	Dashboard_Courses_Table_Actions_Assessments: "评估",
	Dashboard_Courses_Table_Actions_AddToCalendar: "添加到日历",
	Dashboard_Courses_Table_CourseDetails_Task: "任务",
	Dashboard_Courses_Table_CourseDetails_DueDate: "截止日期",
	ContactUs_Headline: "联系我们",
	ContactUs_HowCanWeHelp: "我们该怎样帮助你？",
	ContactUs_EmailTab: "电子邮件",
	ContactUs_PhoneTab: "电话",
	ContactUs_LetterTab: "信件",
	ContactUs_Phone_Headline: "打电话给我们！",
	ContactUs_Phone_Description: "我们期待您的来电，并很乐意为您提供帮助。我们每周 7 天，每天 24 小时为您服务。",
	ContactUs_Phone_PhoneNumber: "请使用联系表格向我们发送消息。",
	ContactUs_Phone_ChargeNotice: "费用因您的服务提供商和国家/地区而异。",
	ContactUs_Letter_Headline: "寄封信！",
	ContactUs_Letter_Description: "您有问题、要求或建议吗？我们期待您的垂询。",
	ContactUs_Email_Headline: "发送电子邮件！",
	ContactUs_Email_Description: "通过填写下面的表格向我们发送您的信息。我们的一位顾问将很乐意与您取得联系。",
	ContactUs_Email_InputName: "姓名*",
	ContactUs_Email_InputCompany: "公司",
	ContactUs_Email_InputCountry: "国家*",
	ContactUs_Email_InputEmail: "电子邮件*",
	ContactUs_Email_InputMessage: "信息*",
	ContactUs_Email_DataProtection: "数据保护通知*",
	ContactUs_Email_DataProtectionConfirm: "我已阅读隐私政策。*",
	ContactUs_Email_SecurityCheck: "安全检查",
	ContactUs_Email_SecurityCodeConfirm: "请输入您在上面的框中看到的正确字符。",
	ContactUs_Email_SecurityCodeInvalid: "您输入的验证码文本答案不正确。",
	ContactUs_Email_MandatoryNotice: "请填写必填字段。",
	ContactUs_Email_SuccessMessage: "您的留言已提交",
	ContactUs_Email_TakenNoteDataProtection1: "我已阅读",
	ContactUs_Email_TakenNoteDataProtection2: "隐私政策。*",
	ContactUs_Email_SubmitButton: "提交",
	ContactUs_Email_FieldErrorMessage: "请填写必填字段。",
	ContactUs_Email_SuccessModal_OK: "好的",
	AccountInReviewModal_Title: "您的帐户目前正在审核中！",
	AccountInReviewModal_Paragraph1: "感谢您在博世汽车培训解决方案上注册个人帐户。",
	AccountInReviewModal_Paragraph2: "“我们的专门培训人员目前正在审查和配置您的帐户。一旦您的帐户配置成功，您将通过邮件收到最终确认。”",
	AccountInReviewModal_Paragraph3: "同时，请在访问受限的情况下随意浏览我们的网站。",
	AccountInReviewModal_Paragraph4: "如果您有任何疑问，或者您的帐户在周末和节假日除外的 48 小时内没有得到处理，您可以联系我们",
	AccountInReviewModal_ContactUsLink: "这里",
	AccountInReviewModal_OKButton: "好，让我进去！",
	Header_YesButton: "是的",
	Header_NoButton: "不",
	Header_LogOutText: "您要退出吗？",
	Footer_EnquiryText: "我们期待您的垂询，",
	Footer_Telephone: "电话，",
	Footer_TermsAndConditions: "条款和条件",
	Footer_HowOurTrainingEvolves: "我们的培训如何发展",
	FooterSearch_SearchFieldPlaceholder: "搜索",
	TrainingCatalogue_Action: "行动",
	TrainingCatalogue_Duration: "期间",
	TrainingCatalogue_Information: "信息",
	Settings_PersonalDetails: "个人资料",
	Reset_All_Filters: "重置所有过滤器",
	EventTable_Days: "天",
	SearchField_AllResults: "所有结果",
	TrainingCentresLayout_ViewDetails: "查看详情",
	Dropdown_Placeholder: "选择...",
	WaitlistPopup_SelectButtonText: "选择",
	WaitlistPopup_StartDate: "开始日期",
	WaitlistPopup_EndDate: "结束日期",
	WaitlistPopup_Language: "语言",
	WaitlistPopup_City: "城市",
	WaitlistPopup_Workshop: "作坊",
	WaitlistPopup_Student: "学生",
	WaitlistPopup_PreferredLocation: "首选地点",
	WaitlistPopup_SignUpCourseWaitingList: "注册课程等候名单",
	WaitlistPopup_SignUpWithoutSelectingDate: "无需选择日期即可注册等候名单",
	WaitlistPopup_GeneralWaitList: "一般等候名单",
	WatinglistPopup_CourseLabel: "课程",
	Common_ContinueButton: "继续",
	UserAccountDeactivationModal_ConfirmLabel: "确认",
	Filter_FilterLabel: "筛选",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "您已经在候补名单上，是否要删除现有注册并重新注册？",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "是的，删除",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "不，取消",
	WaitinglistPopup_RemoveWaitlistItem_Title: "课程取消",
	WaitinglistPopup_SignUpButtonText: "注册",
	MyAccount_FirstNameRequied: "名字是必填项",
	MyAccount_LastNameRequired: "姓氏是必填项",
	MyAccount_RoleRequired: "角色是必需的",
	MyAccount_MobileRequired: "需要手机",
	MyAccount_EmailRequired: "电子邮件是必需的",
	MyAccount_LanguageRequired: "语言是必需的",
	MyAccount_UpdateSuccessMessage: "您的账户信息更新成功！",
	MyAccount_UpdateSuccessModal_ButtonText: "是的，关闭",
	Infor_CorporateInformation_Header: "公司信息",
	Infor_LegalNotice_Header: "法律声明",
	Infor_PrivacyPolicy_Header: "数据保护通知（隐私政策）",
	CreateEmployeeAccountModification_FormValidation_Role: "角色是必需的",
	CreateEmployeeAccountModification_FormValidation_Firstname: "名字是必填项",
	CreateEmployeeAccountModification_FormValidation_Lastname: "姓氏是必填项",
	CreateEmployeeAccountModification_FormValidation_Mobile: "需要手机",
	CreateEmployeeAccountModification_FormValidation_Email: "电子邮件是必需的",
	CreateEmployeeAccountModification_FormValidation_Language: "语言是必需的",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "邀请加入博世汽车培训解决方案",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "您已成功将新成员添加到您的团队！",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "您希望我们与您的新团队成员分享一封电子邮件邀请函，并说明他们如何为博世汽车培训解决方案创建自己的个人帐户吗？",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "不",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "是的，发送邀请",
	ShoppingCart_Venue_Fee: "场地费",
	ShoppingCart_Trainning_Name: "培训名称",
	ShoppingCart_Available_Credits: "可用积分",
	ConfirmItsMeModal_Hi: "你好",
	ConfirmItsMeModal_Description1: "您已成功完成公司管理员分享的邀请，以在 Bosch Automotive Training Solutions 创建您自己的个人帐户。",
	ConfirmItsMeModal_Description2: "在继续之前，请确认或更新您的帐户详细信息。",
	ConfirmItsMeModal_Description3: "确认后，您将需要重新登录以完成您的帐户创建。",
	ConfirmItsMeModal_CompanyDetails: "公司详情",
	ConfirmItsMeModal_YourDetails: "你的资料",
	ConfirmItsMeModal_YourDetails_Title: "标题",
	ConfirmItsMeModal_YourDetails_Function: "功能",
	ConfirmItsMeModal_YourDetails_Name: "姓名",
	ConfirmItsMeModal_YourDetails_Mobile: "移动的",
	ConfirmItsMeModal_YourDetails_Email: "电子邮件",
	ConfirmItsMeModal_YourDetails_Language: "语言",
	ConfirmItsMeModal_ConfirmButton: "确认",
	SelectWholesalerModal_WholesalerGroupLabel: "批发商",
	SelectWholesalerModal_WholesalerBranchLabel: "出口",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "请选择",
	ApplyButton: "申请",
	SelectWholesalerModal_Title: "选择您的批发商",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "您想删除您的注册吗？",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "您的注册被删除成功！",
	WaitlistPopup_AddToWaitListSuccess: "您的注册添加成功！",
	ErrorCommon_Header: "错误",
	CommonButton_Close: "关闭",
	MyELearning_OpeningVideoPlayerTitle: "博世汽车电子学习",
	MyELearning_VideoPlayerClosingMessage: "请关闭此窗口以继续！",
	EmbeddedVideoPlayer_StartButtonText: "开始",
	EmbeddedVideoPlayer_RefresherButtonText: "复习",
	DeactivateWholesalerModal_ConfirmMessage: "您确定要删除此批发商吗？",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "发送邀请邮件成功！",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "重新邀请",
	ContactUs_Mobile_SG: "+65(6)571-2409",
	ContactUs_Address_SG: "Robert Bosch (SEA) Pte Ltd,11 Bishan Street 21,Singapore 573943",
	ConfirmItsMeModal_CompanyDetails_Name: "公司名",
	ConfirmItsMeModal_CompanyDetails_Address: "地址",
	ConfirmItsMeModal_CompanyDetails_Postcode: "公司邮政编码",
	ConfirmItsMeModal_CompanyDetails_Phone: "电话",
	ConfirmItsMeModal_CompanyDetails_Email: "电子邮件",
	CourseWithdrawalModal_WithdrawSuccessMessage: "退出课程成功！",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "账号创建失败，账号已经存在！",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "更新帐号失败。帐号已存在！",
	Footer_ExploreTrainingStories: "探索我们所有的培训故事",
	Breadcrumb_Home: "家",
	Breadcrumb_Contact: "接触",
	Breadcrumb_Stories: "故事",
	Breadcrumb_Stories_BoschServiceTraining: "博世服务培训",
	Breadcrumb_Stories_ADayInLife: "培训师生活中的一天",
	Breadcrumb_Stories_BoschTrainingBenefits: "博世培训福利",
	Breadcrumb_Stories_TrainingApproach: "现代培训方法",
	Breadcrumb_Settings: "设置",
	Breadcrumb_ShoppingCart: "购物车",
	Breadcrumb_Checkout: "退房",
	Breadcrumb_OrderSummary: "订单摘要",
	Breadcrumb_Exception: "例外",
	Breadcrumb_Dashboard: "仪表板",
	Breadcrumb_MyCourses: "我的课程",
	Breadcrumb_MyELearning: "我的在线学习",
	Breadcrumb_CompanyAdministration: "公司管理",
	Breadcrumb_EmployeeManagement: "员工管理",
	Breadcrumb_EmployeeAccount: "员工账户",
	Breadcrumb_EmployeeCreation: "员工创造",
	Breadcrumb_Administration: "行政",
	Breadcrumb_EmployeeAdministration: "员工管理",
	Breadcrumb_WholesalerManagement: "批发商管理",
	Breadcrumb_NewWholesalerAccountCreation: "创建新的批发商帐户",
	Breadcrumb_Transactions: "交易",
	Breadcrumb_MyAccount: "我的账户",
	Breadcrumb_TrainingCentres: "培训中心",
	Breadcrumb_EventCalendar: "活动日历",
	Breadcrumb_TrainingCourseCatalogue: "培训课程目录",
	Breadcrumb_RecommendedHotels: "推荐酒店",
	Breadcrumb_CorporateInformation: "公司信息",
	Breadcrumb_LegalNotice: "法律声明",
	Breadcrumb_DataProtectionNotice: "数据保护通知",
	Breadcrumb_PrivacyPolicy: "隐私政策",
	Breadcrumb_TermCondition: "条款和条件",
	Breadcrumb_Terms: "条款",
	Breadcrumb_PrivacyStatement: "隐私声明",
	Breadcrumb_Cookies: "饼干",
	Breadcrumb_Provider: "提供者",
	Breadcrumb_RegisterAccount: "注册账户",
	Stories_ExploreMore: "探索更多",
	CheckOutLayout_ShoppingCartHeader: "购物车",
	CheckOutLayout_AddedItemCartMessage: "您已将以下内容添加到您的购物车",
	CheckOutLayout_CheckYourCartMessage: "检查您的购物车",
	ModernTrainingApproach_DateText: "2021 年 1 月 10 日",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "搜索员工",
	CheckOutLayout_OrderWasNotPlaced_Message: "抱歉！您的订单未下达",
	CheckOutLayout_RefusalReason: "拒绝原因",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "付款失败。请使用其他付款方式重试",
	CheckOutLayout_SubmittingApiFailed_Message: "提交 API 失败",
	CartItem_ChooseWorkshop_Message: "请选择工作坊来选择参加者",
	CartItem_ChooseParticipant_Message: "请选择参加继续",
	CompanyAdministrationTable_SearchBox_Placeholder: "搜索研讨会",
	CartItem_RemoveCartItem_ConfirmText: "您想从购物车中删除此商品吗？",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "功能",
	AddToCartSuccessModal_Message: "您的购物车已更新！",
	AddToCartSuccessModal_ContinueButtonText: "继续购物",
	AddToCartSuccessModal_MyShoppingCartButtonText: "我的购物车",
	AddVoucher_Header: "添加优惠券",
	AddVoucher_InputPlaceholder: "输入代码并申请",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "不幸的是，我们目前没有任何预定的此培训课程活动。请注册等候名单，一旦有活动我们会通知您。",
	ADYEN_ERR_2_: "交易被拒绝。",
	ADYEN_ERR_3_: "引荐错误",
	ADYEN_ERR_4_: "由于收单方发生错误，交易未完成。",
	ADYEN_ERR_5_: "用于交易的卡被锁定，因此无法使用。",
	ADYEN_ERR_6_: "用于交易的卡已过期。因此无法使用。",
	ADYEN_ERR_7_: "交易过程中出现金额不匹配。",
	ADYEN_ERR_8_: "指定的卡号不正确或无效。",
	ADYEN_ERR_9_: "无法联系购物者的银行来授权交易。",
	ADYEN_ERR_10_: "购物者的银行不支持或不允许此类交易。",
	ADYEN_ERR_11_: "3D 安全认证未执行，或未成功执行。",
	ADYEN_ERR_12_: "该卡没有足够的钱来支付应付金额。由于您的卡上的每月应付金额限制，可能会出现此问题。请尝试使用其他卡，如万事达卡，或联系您的银行重置您的卡的每月限额。",
	ADYEN_ERR_14_: "可能的欺诈行为。",
	ADYEN_ERR_15_: "交易被取消。",
	ADYEN_ERR_16_: "购物者在完成交易之前取消了交易。",
	ADYEN_ERR_17_: "指定的 PIN 不正确或无效。",
	ADYEN_ERR_18_: "购物者连续 3 次以上指定了错误的 PIN。",
	ADYEN_ERR_19_: "无法验证指定的 PIN 码。",
	ADYEN_ERR_20_: "可能的欺诈行为。",
	ADYEN_ERR_21_: "交易未正确提交以供处理。",
	ADYEN_ERR_22_: "风险检查将交易标记为欺诈（风险评分 >= 100）；因此，操作被取消。",
	ADYEN_ERR_23_: "以下拒绝代码映射到此拒绝原因： 不允许发卡行/持卡人进行交易",
	ADYEN_ERR_24_: "指定的 CVC（卡安全码）无效。",
	ADYEN_ERR_25_: "以下拒绝代码映射到此拒绝原因： 该国家/地区的卡无效",
	ADYEN_ERR_26_: "R1：撤销授权令/“R3：撤销所有授权令”/“R0：止付令”",
	ADYEN_ERR_27_: "此响应映射所有无法可靠映射的响应代码。这使得更容易区分一般拒绝（例如，万事达卡“05：不兑现”响应）和更具体的拒绝。",
	ADYEN_ERR_28_: "已超出购物者卡允许的提款金额。",
	ADYEN_ERR_29_: "已超过购物者卡允许的取款次数。",
	ADYEN_ERR_31_: "发行人将该交易报告为涉嫌欺诈。",
	ADYEN_ERR_32_: "购物者输入的地址数据不正确。",
	ADYEN_ERR_33_: "购物者的银行要求购物者输入在线 PIN。",
	ADYEN_ERR_34_: "购物者的银行需要一个支票账户才能完成购买。",
	ADYEN_ERR_35_: "购物者的银行需要一个储蓄账户才能完成购买。",
	ADYEN_ERR_36_: "购物者的银行要求购物者输入手机 PIN。",
	ADYEN_ERR_37_: "购物者在尝试非接触式支付后放弃了交易，并被提示尝试不同的卡输入方法（PIN 或刷卡）。",
	ADYEN_ERR_38_: "发行人拒绝了身份验证豁免请求，并要求对交易进行身份验证。 使用 3D Secure 重试。",
	ADYEN_ERR_39_: "发行人或计划无法通过 RReq 传达结果",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "名字不得包含特殊字符（例如：@#$...）",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "名字必须小于 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "姓氏不得包含特殊字符（例如：@#$...）",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "姓氏必须小于 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "手机号码不得包含特殊字符（例如：@#$...）",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "手机号码必须小于45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "电话号码不得包含特殊字符（例如：@#$...）",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "电话号码必须小于 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "电子邮箱地址不可用",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "电子邮件必须小于 100",
	ConfirmGeolocationModal_ConfirmButton: "继续",
	ConfirmGeolocationModal_CancelButton: "改变",
	ConfirmGeolocationModal_CurrentCountrySelection: "您当前的国家选择是：",
	ConfirmGeolocationModal_AskChangeCountry: "您希望留在本网站上还是更改您的国家偏好？",
	Addtocart_Modal_AddToCartFailTitle: "添加参与者失败",
	Addtocart_Modal_AlreadyInCart: "参与者已注册此活动",
	TrainingCatalogue_DateRange: "日期范围",
	Dashboard_MyPendingCourses: "我的待定课程",
	Dashboard_MyWaitingList: "我的等候名单",
	Dashboard_LatestNews: "最新消息",
	SettingsMainPage_EditAccountButton: "编辑账户",
	SettingsMainPage_EditBoschId: "编辑博世 ID",
	EventInfoView_EventDetailTab: "活动详情",
	EventInfoView_AssignmentsTab: "作业",
	EventInfoView_EvaluationTab: "评估",
	EventInfoView_AttachmentTab: "附件",
	EventInfoView_CourseDescriptionTab: "描述",
	EventInfoView_DescriptionTab_General: "一般的",
	EventInfoView_DescriptionTab_PartLabel: "部分",
	AssignmentTab_Description: "在这里，您将找到完成本课程所需的所有信息。",
	AssignmentTab_Columns_Download: "下载",
	AssignmentTab_Columns_Upload: "上传",
	AssignmentTab_Columns_TaskComplete: "任务完成",
	EventDetailTab_Location_Message_Video: "这是一个在线活动，可以使用“启动视频”链接访问。",
	EventDetailTab_Location_Message_WebBased: "这是一个在线活动，可以使用“启动 WBT”链接访问。",
	EventDetailTab_Location_Message_WebCast: "这是一个在线活动，可以使用“立即加入”链接访问。",
	EventDetailTab_Link_Video: "启动视频",
	EventDetailTab_Link_Video_Remark: "*启动视频将打开一个新窗口。关闭窗口后，您将被重定向回此页面。",
	EventDetailTab_Link_WebBased: "启动 WBT",
	EventDetailTab_Link_WebBased_Remark: "*启动 WBT 将打开一个新窗口。关闭窗口后，您将被重定向回此页面。",
	EventDetailTab_Link_WebCast: "现在加入",
	EventDetailTab_Link_WebCast_Remark: "*现在加入链接将在活动开始前 30 分钟激活。",
	EventDetailTab_Title_Status: "地位",
	EventDetailTab_Title_Duration: "持续时间（小时）",
	EventDetailTab_Title_Trainers: "培训师",
	EventDetailTab_Title_Language: "语言",
	EventDetailTab_Title_Score: "分数 （％）",
	EventDetailTab_Title_Calendar: "添加到日历",
	EventDetailTab_Title_Certificate: "下载证书",
	EventDetailTab_Title_Messages: "留言",
	EventDetailTab_Title_StartDate: "开始日期",
	EventDetailTab_Title_AvailableFrom: "可从",
	EventDetailTab_Title_Time: "时间",
	EventDetailTab_Title_EndDate: "结束日期",
	EventDetailTab_Title_ExpiresOn: "到期",
	EventDetailTab_Title_Room: "房间",
	EventDetailTab_Title_Method: "方法",
	EventDetailTab_Title_Link: "关联*",
	EventDetailTab_Title_Location: "地点",
	AttachmentTab_EmptyScreenDescription: "本次活动无附件。",
	AttachmentTab_Columns_Download: "下载",
	AttachmentTab_Description: "在这里，您将找到一些重要信息来帮助您完成本课程",
	EvaluationTab_Annotation: "我们重视您的反馈，并希望收到您的来信，了解我们的工作情况。请抽空填写以下评价，以帮助我们不断改进对您的服务。谢谢你。",
	EvaluationTab_Notice: "评分如下",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "请在提交活动评估之前回答所有必答问题",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "您的反馈已提交成功",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "评估提交失败",
	TrainingCatalogueLayout_ExportButtonText: "出口",
	EvaluationTab_FeedbackHasBeenGiven_Message: "评估已完成或已过期。",
	EventDetailTab_Link_CopiedTooltip: "已复制到剪贴板",
	EventDetailTab_QRCode_ExplanationText_WBT: "扫描以启动 在线培训",
	EventDetailTab_QRCode_ExplanationText_Video: "扫描启动视频",
	EventDetailTab_QRCode_ExplanationText_Webcast: "扫描启动网络直播",
	Footer_TrainingCoure_Headline: "博世提供的培训课程",
	Footer_TrainingCourse_Content: "作为世界知名的超级品牌，拥有一个多世纪的创新经验，博世作为汽车系统、零部件和技术解决方案的供应商在全球树立了标准。我们的大部分优势来自与汽车制造商的密切合作，我们为他们设计、开发和生产世界各地最先进的系统和组件。因此，博世凭借多样化的零部件、诊断解决方案和维修服务组合，巩固了汽车售后市场产品和支持的悠久历史。学习和发展无疑是成功的关键，这一理念得到了广泛的培训课程的支持，涵盖汽车技术、车辆系统和诊断专业知识的所有领域。",
	SearchField_NoRecordsFound: "没有找到记录",
	TrainingCatalogue_FreeTextSearch: "自由文本",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "搜索",
	CheckoutLayout_WholeSalerGroup: "选择批发商集团",
	CreditInsufficientModal_Message: "您没有足够的信用额度来使用此付款方式。请使用其他付款方式重试。",
	ShoppingCart_Required_Credits: "总价格",
	MaintainingInfo_Message1: "本网站处于维护模式。",
	MaintainingInfo_Message2: "我们很快会回来的。",
	CountryValidationModal_Message: "您无法登录到 [CountryA] ，因为您的帐户已在 [CountryB] 中创建。",
	CountryValidationModal_ButtonText: "继续前往 [Country]",
	EventTable_LoginRequired: "登录以注册",
	TrainingPartTableLayout_Date: "日期",
	TrainingPartTableLayout_StartTime: "开始时间",
	TrainingPartTableLayout_EndTime: "结束时间",
	TrainingPartTableLayout_Duration: "持续时间 (小时)",
	TrainingPartTableLayout_TrainingMethod: "培训方法",
	MyCoursesTableContents_ActiveHeader: "活动课程",
	MyCoursesTableContents_ActiveTabHeader: "活动",
	MyCoursesTableContents_ScheduledHeader: "计划课程",
	MyCoursesTableContents_ScheduledTabHeader: "已计划",
	MyCoursesTableContents_OnlineHeader: "在线课程",
	MyCoursesTableContents_OnlineTabHeader: "在线",
	MyCoursesTableContents_CancelledHeader: "已取消课程",
	MyCoursesTableContents_CancelledTabHeader: "已取消",
	OnlineCoursesTable_Title_Progress: "进展",
	ScheduledCoursesTable_Title_Date: "日期 [ 天 ]",
	CoursesTable_Title_Completed: "已完成",
	Footer_TrainingProgram: "培训方案",
	Breadcrumb_TrainingProgram: "培训方案",
	TrainingProgram_Title_Table: "标题",
	TrainingProgram_TrainingProgram: "培训方案",
	TrainingProgram_TargetGroup: "目标组",
	TrainingProgram_MaxLength: "最大 长度",
	TrainingProgram_Information: "信息",
	TrainingProgram_Detail: "详细信息",
	TrainingProgram_Modules: "模块",
	TrainingProgram_Course: "课程",
	TrainingProgram_PartNumber: "零件号",
	TrainingProgram_Max_Length: "最大长度",
	TrainingProgram_Duration: "持续时间",
	TrainingProgram_Exam: "检查",
	TrainingProgram_Assign_Popup: "注册参加培训计划",
	TrainingProgram_Student: "学生",
	TrainingProgram_Assign: "分配",
	TrainingProgram_Popup_Remove_Title: "培训取消？",
	TrainingProgram_Description: "描述",
	TrainingProgram_Days: "天",
	TrainingProgram_Type: "类型",
	TrainingProgram_Action: "此培训计划已注册了相关行动",
	TrainingProgram_Enrolled: "您已被分配参加此培训计划",
	TrainingProgram_SelfAssign_Enrolled: "请注册其他学生或选择其他培训计划。",
	TrainingProgram_Warning: "车间年天数",
	TrainingProgram_Workshop: "是的",
	TrainingProgram_Durations: "否",
	TrainingProgram_Duration_DaysLabel: "恭喜！",
	TrainingProgram_HasExam_Yes: "已成功注册参加此培训计划。",
	TrainingProgram_HasExam_No: "已成功分配给您参加培训计划。",
	AssignModal_SuccessModal_Header: "我的培训计划",
	AssignModal_SuccessModal_Message: "标题",
	AssignModal_SelfAssign_SuccessModal_Message: "状态",
	Dashboard_MyTraningProgram: "视图",
	Dashboard_MyTraningProgram_Title: "注册",
	Dashboard_MyTraningProgram_Status: "历史记录",
	Dashboard_MyTraningProgram_Table_View: "正在进行中",
	Dashboard_MyTraningProgram_Register: "获得认可",
	Dashboard_MyTraningProgram_Records: "撤回",
	Dashboard_MyTrainingProgram_In_Progress: "等级",
	Dashboard_MyTrainingProgram_Accredited: "已注册",
	Dashboard_Program_Modal_Withdraw: "已完成",
	Dashboard_Program_Modal_Grade: "是的",
	Dashboard_MyTrainingProgram_Registered: "否",
	Dashboard_MyTrainingProgram_Completedd: "请注意，退出意味着您将退出完整的培训计划，而不仅仅是从单独的培训课程中退出。",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "您仍想退出培训计划吗？",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "待定",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "您已成功退出培训计划！",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "警告！",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "哎呀，出了点问题！",
	WithdrawProgramModal_Withdraw_Success_Message: "“添加到购物车时间”已添加到您的购物车中！",
	Common_Warning_Title: "选定的参与者已注册到本课程，或者该课程已添加到购物车中。",
	Common_Error_Title: "可能会有变化",
	AddEventFromCatalogToCartModal_AddToCartButton: "失败",
	AddEventFromCatalogToCartModal_HoursText: "通过",
	AddElearningToCartSuccessModal_Message: "功绩",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "区别",
	EventDetailTab_SubjectForChange_Message: "课程",
	Dashboard_MyTrainingProgram_Status_Failed: "培训计划",
	Dashboard_MyTrainingProgram_Status_Passed: "等待列表",
	Dashboard_MyTrainingProgram_Status_Merit: "帐户",
	Dashboard_MyTrainingProgram_Status_Distinction: "免费",
	Dashboard_Employee_Courses: "您已成功更新您的员工信息！",
	Dashboard_Employee_Program: "经销商",
	Dashboard_Employee_WaitList: "目前没有消息",
	Dashboard_Employee_Account: "PDF 下载",
	Course_Price_Free: "货币",
	Dashboard_Employee_Account_Update_Success_Message: "我的钱包",
	Dashboard_Supplier: "钱包",
	Event_Detail_Comment: "培训信用余额",
	DownLoad_PDF: "今天",
	TrainingCatalogue_Currency: "可用语言",
	My_Wallet: "您目前在",
	Wallet: "是否要更改国家 / 地区？",
	Credits_Balance: "服务培训",
	MyFullCalendar_Today: "登录 / 注册",
	Header_Available_Languages: "书籍培训",
	Header_CurrentRegion: "注册",
	Header_ChangeRegions: "登录",
	Header_Anonymous: "终极研讨会解决方案",
	Header_Anonymous_Login: "您的研讨会，您的选择。",
	Header_RegisterCourse: "博世汽车培训解决方案",
	Homepage_Start: "使现代车间能够在所有类型车辆的诊断程序，故障排除，维修和保养方面专业，可靠和高效地工作。",
	Homepage_Login: "培训新闻",
	MainPage_Title: "增强您的车间维修辅助体验",
	MainPage_Content: "我可以使用该应用程序做什么？",
	MainPage_ImageTitle: "预订课程并维护您的学习之旅",
	MainPage_IntroContent: "下载培训材料和课程证书",
	BoschNews_TrainingNews: "启动在线学习课程",
	Service_Assist_Workshop: "访问移动应用程序中的各种汽车服务",
	Enhance_Title_CDM3_v2: "使用 Visual Connect Pro 进行实时视频传输",
	Enhance_Body_CDM3_Item_1: "用于自动车辆扫描的免费工具",
	Enhance_Body_CDM3_Item_2: "培训故事",
	Enhance_Body_CDM3_Item_3: "加入企业",
	Enhance_Body_CDM3_Item_4: "加入企业",
	Enhance_Body_CDM3_Item_5: "请在下面输入您的激活码",
	Enhance_Body_CDM3_Item_6: "注意： 请联系贵公司的蝙蝠管理员，以获取蝙蝠网站上的激活代码。",
	Training_Stories: "您的激活已成功处理，您的帐户现在已链接到您的业务。",
	SettingsMyAccount_JoinCompanyButton: "请单击“继续”进入网站。",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "继续",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "您的账户目前正在审核中！",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "如果您有激活代码加入企业帐户，请单击此处！",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "注意：您需要联系您的企业管理员以获取激活代码。",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line3: "感谢您在博世汽车培训解决方案上注册个人帐户。",
	ContinueButton: "我们的专业培训人员目前正在检查和配置您的帐户。 成功配置帐户后，您将通过电子邮件收到最终确认。",
	WelcomeBatsModal_Title: "好的，让我来吧！",
	WelcomeBatsModal_ActivationLink: "我们无法验证您的激活码和电子邮件地址。",
	WelcomeBatsModal_ActivationLinkNotes: "请重试或联系您企业的管理员以获得帮助。",
	AccountInReviewModal_Description_Paragraph1: "请重试",
	AccountInReviewModal_Description_Paragraph2: "哎呀，出了点问题！",
	AccountInReviewModal_CloseButton: "成功！",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "同时，请随意浏览我们的网站，但访问权限有限。",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "如果您有任何疑问，或者如果您的账户在 72 小时内 (周末和节假日除外) 未得到处理，您可以联系我们 [ 此处 ]",
	TryAgain_Button: "博世汽车培训解决方案",
	Error_Title: "一些帮助您的指南 ...",
	SettingsMyProfileActivationSuccessModal_Title: "常见问题",
	AccountInReviewModal_Description_Paragraph3: "一般",
	AccountInReviewModal_Description_Paragraph4: "什么是 Bosch 汽车培训解决方案 (蝙蝠) ？",
	Support_Headline: "博世汽车培训解决方案",
	Support_SubHeadline: "一些帮助您的指南 ...",
	Support_FAQ_Headline: "常见问题",
	Support_FAQs_section1_title: "一般",
	Support_FAQs_section1_question1: "什么是 Bosch 汽车培训解决方案 (蝙蝠) ？",
	Support_FAQs_section1_question1_answer1: "博世汽车培训解决方案是一个平台，可提供有关技术培训的全面概述，并使您能够预订和管理培训课程。",
	Support_FAQs_section1_question2: "我可以使用现有的博世帐户 (SingleKey ID) 登录博世汽车培训解决方案 (蝙蝠) 吗？",
	Support_FAQs_section1_question2_answer1: "是的，可以使用“我的博世 ID ”访问蝙蝠。",
	Support_FAQs_section1_question3: "我可以将为博世汽车培训解决方案创建的帐户用于其他博世服务或应用吗？",
	Support_FAQs_section1_question3_answer1: "是的，您还可以将您的个人博世 ID 用于博世的各种其他应用，包括专业应用和私人应用，如电子自行车或智能家居。",
	Support_FAQs_section2_title: "培训",
	Support_FAQs_section2_question1: "如何预订培训？",
	Support_FAQs_section2_question1_answer1: "使用现有帐户登录或注册到门户。 转至“培训服务”或“事件日历”选择培训课程，然后单击添加到购物车 (购物车图标) 按钮",
	Support_FAQs_section2_question2: "没有合适的培训日期。 我该怎么办？",
	Support_FAQs_section2_question2_answer1: "您可以将自己加入“常规”候补名单 (无需选择日期) ，我们的团队将在有新的培训日期时立即通知您。",
	Support_FAQs_section2_question3: "在哪里可以找到我预订课程的概览？",
	Support_FAQs_section2_question3_answer1: "您可以通过我的培训 > 您的概述在仪表板中找到所有已预订和已完成课程的完整概述",
	Support_FAQs_section3_title: "设置",
	Support_FAQs_section3_question1: "如何更改我的电子邮件和密码？",
	Support_FAQs_section3_question1_answer1: "您需要先登录门户网站，然后通过我的培训 > 我的博世帐户更改个人配置文件信息",
	Support_FAQs_section3_question1_answer3: "您可以在此处更改博世 ID 的详细信息 (电子邮件 / 密码)",
	Support_FAQs_section3_question2: "忘记密码后该怎么办？",
	Support_FAQs_section3_question2_answer1: "请使用“登录”或“注册”按钮并填写您的电子邮件地址",
	Support_FAQs_section3_question2_answer2: "如果您单击“继续”，您就可以单击“忘记密码”",
	Support_FAQs_section3_question3: "如何设置车间经理账户？",
	Support_FAQs_section3_question3_answer1: "如果您需要了解所有商务员工的培训信息，您需要车间经理的角色。 请联系您当地的培训管理部门，例如，您可以使用网站上的联系表。",
	LandingPage_Header_Title: "博世汽车培训解决方案",
	LandingPage_ChooseCountry_Title: "选择您所在的国家 / 地区",
	MaintenanceBanner_Message_1: "博世汽车培训解决方案门户将在 [maintenance _schedule] 更新。 更新预计需要 30 分钟。 更新期间门户将不可用。",
	MaintenanceBanner_Message_2: "如需进一步帮助， 请通过电子邮件 [support_email] 联系我们",
	MaintenancePage_Message_1: "博世汽车培训解决方案门户目前正在更新",
	MaintenancePage_Message_2: "我们预计该门户将在 [maintenance_online_date] [maintenance_online_time] 再次可用",
	MenuHeader_Support: "支持",
	Course_Detail_No_Prerequisites: "无前提条件",
	TableCell_Hours: "小时",
	Dashboard_MenuItem_Dashboard: "仪表板",
	Dashboard_MenuItem_Curricula: "培训方案",
	Dashboard_MenuItem_Certificates: "证书",
	Dashboard_MenuItem_Team: "团队",
	Dashboard_MenuItem_Workshops: "讲习班",
	Dashboard_MenuItem_External: "经销商",
	Dashboard_MenuItem_Wallet: "钱包",
	Dashboard_MenuItem_Profile: "配置文件",
	Dashboard_Headline_YourUpcomingCourses: "您即将开始的课程",
	Dashboard_Headline_YourELearningCourses: "您的在线学习课程",
	Dashboard_Headline_TeamOverview: "团队概述",
	Dashboard_Headline_YourOverview: "您的 概述",
	Dashboard_Headline_YourWishlist: "您的培训计划",
	Dashboard_Headline_ActivePrograms: "您的证书",
	Dashboard_Headline_CompletedPrograms: "已完成的计划",
	Dashboard_Headline_YourCertificates: "您的证书",
	Dashboard_Headline_Transactions: "交易",
	Dashboard_Table_NoRecordsToDisplay: "没有要显示的记录",
	Dashboard_TableColumn_Location_Online: "在线",
	Dashboard_TableColumn_Duration_Hours: "小时",
	Dashboard_ELearningCourses_TableHeader_Title: "标题",
	Dashboard_ELearningCourses_TableHeader_Duration: "持续时间 (小时)",
	Dashboard_ELearningCourses_ActionButton_View: "视图",
	Dashboard_UpcomingCourses_TableHeader_Title: "标题",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "日期 [ 天 ]",
	Dashboard_UpcomingCourses_TableHeader_Location: "位置",
	Dashboard_UpcomingCourses_ActionButton_View: "视图",
	Dashboard_YourOverview_TableHeader_Title: "标题",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "日期 / 持续时间",
	Dashboard_YourOverview_TableHeader_Location: "位置",
	Dashboard_YourOverview_TableHeader_Status: "状态",
	Dashboard_YourOverview_ActionButton_View: "视图",
	Dashboard_YourWishlist_TableHeader_Title: "标题",
	Dashboard_YourWishlist_TableHeader_Action: "行动",
	Dashboard_YourWishlist_ViewAllRecords: "查看所有记录",
	Dashboard_TeamOverview_TableHeader_Employee: "员工",
	Dashboard_TeamOverview_TableHeader_Title: "标题",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "日期 / 持续时间",
	Dashboard_TeamOverview_TableHeader_Location: "位置",
	Dashboard_TeamOverview_TableHeader_Status: "状态",
	Dashboard_TeamOverview_ActionButton_View: "视图",
	Dashboard_TeamOverview_SearchBox_Placeholder: "搜索",
	Dashboard_ActiveProgram_TableHeader_Title: "标题",
	Dashboard_ActiveProgram_TableHeader_Status: "状态",
	Dashboard_ActiveProgram_ActionButton_View: "视图",
	Dashboard_CompletedProgram_TableHeader_Title: "标题",
	Dashboard_CompletedProgram_TableHeader_Status: "状态",
	Dashboard_CompletedProgram_ActionButton_View: "视图",
	Dashboard_Certificates_TableHeader_Title: "标题",
	Dashboard_Certificates_TableHeader_Date: "日期",
	Dashboard_Certificates_TableHeader_Status: "状态",
	Dashboard_Certificates_TableHeader_Action: "行动",
	Dashboard_Certificates_ActionButton_Download: "下载",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "发票编号",
	Dashboard_Transactions_TableHeader_Description: "描述",
	Dashboard_Transactions_TableHeader_PaymentMethod: "付款方式",
	Dashboard_Transactions_TableHeader_Date: "日期",
	Dashboard_Transactions_TableHeader_Amount: "金额",
	Dashboard_Transactions_ActionButton_Download: "下载",
	Breadcrumb_MyTraining: "我的训练",
	Breadcrumb_MyTraining_CourseDetails: "课程详细信息",
	Breadcrumb_MyTraining_Curricula: "培训方案",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "培训计划详细信息",
	Breadcrumb_MyTraining_Team: "团队",
	Breadcrumb_MyTraining_Team_ViewEmployee: "查看员工",
	MyTraining_Headerbar_CourseDetails: "课程详细信息",
	MyTraining_Headerbar_TrainingProgramDetails: "培训计划详细信息",
	TrainingProgramDetails_Label_Status: "状态",
	TrainingProgramDetails_Label_OverallProgress: "总体进展",
	TrainingProgramDetails_ProgramItems_Headline: "方案项目",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "标题",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "状态",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "等级",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "日期",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "视图",
	TrainingProgramDetails_Status_InProgress: "正在进行中",
	TrainingProgramDetails_Status_Completed: "已完成",
	CourseDetails_Label_Status: "状态",
	CourseDetails_Label_Duration: "持续时间",
	CourseDetails_Label_Trainer: "培训师",
	CourseDetails_Label_Language: "语言",
	CourseDetails_Label_Score: "分数：",
	CourseDetails_Label_StartDate: "开始日期",
	CourseDetails_Label_EndDate: "结束日期",
	CourseDetails_Label_Venue: "地点",
	CourseDetails_Label_Address: "地址",
	CourseDetails_Label_Grade: "等级：",
	CourseDetails_Action_Withdraw: "撤回",
	CourseDetails_Action_Evaluate: "评估课程",
	CourseDetails_Action_Evaluate_Description: "请评估本课程以生成证书。",
	CourseDetails_Action_DownloadCertificate: "下载证书",
	CourseDetails_Action_JoinWebcast: "加入网络广播",
	CourseDetails_Action_StartCourse: "开始课程",
	CourseDetails_Action_RestartCourse: "重新开始课程 \" 无法下载证书，因为未达到通过标记。",
	CourseDetails_Action_RepeatCourse_Description: "请重复并通过本课程以下载您的证书。 ”",
	CourseDetails_PartSpecificDetails_Headline: "零件特定详细信息",
	CourseDetails_PartSpecificDetails_Tab_Part: "零件",
	CourseDetails_PartSpecificDetails_Description: "描述",
	CourseDetails_PartSpecificDetails_StartDate: "开始日期",
	CourseDetails_PartSpecificDetails_EndDate: "结束日期",
	CourseDetails_PartSpecificDetails_StartTime: "开始时间",
	CourseDetails_PartSpecificDetails_EndTime: "结束时间",
	CourseDetails_PartSpecificDetails_Method: "方法",
	Dashboard_Team_TableHeader_Name: "姓名",
	Dashboard_Team_TableHeader_Role: "角色",
	Dashboard_Team_TableHeader_Email: "电子邮件",
	Dashboard_Team_TableHeader_PersonalAccount: "个人账户",
	Dashboard_Team_ActionButton_Select: "选择",
	Dashboard_Team_PersonalAccount_Activated: "活动",
	Dashboard_Team_PersonalAccount_NotActivated: "非活动",
	Dashboard_Team_ActionButton_AddMember: "添加团队成员",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "搜索",
	Dashboard_External_TableHeader_Name: "姓名",
	Dashboard_External_ActionButton_AddNew: "添加经销商",
	Dashboard_External_Headline: "批准的批发商列表",
	Dashboard_Workshops_Headline: "讲习班",
	Dashboard_Workshops_TableHeader_Company: "公司",
	Dashboard_Workshops_TableHeader_Address: "地址",
	Dashboard_Workshops_TableHeader_Postcode: "邮编",
	Dashboard_Workshops_TableHeader_City: "城市",
	Dashboard_Workshops_ActionButton_Select: "选择",
	Wallet_ServiceCredits: "培训学分",
	Wallet_Headline_CreditBalance: "培训信用余额",
	Wallet_Headline_CreditActivity: "培训信贷活动",
	Wallet_Headline_BillingTransactions: "计费事务",
	Wallet_BillingTransaction_TableHeader_Order: "订单",
	Wallet_BillingTransaction_TableHeader_Date: "日期",
	Wallet_BillingTransaction_TableHeader_Amount: "金额",
	Wallet_BillingTransaction_TableHeader_Action: "行动",
	Wallet_CreditActivity_TableHeader_Date: "日期",
	Wallet_CreditActivity_TableHeader_Description: "描述",
	Wallet_CreditActivity_TableHeader_User: "用户",
	Wallet_CreditActivity_TableHeader_Amount: "金额",
	FooterAdditionalLinks_Copyright: "© Robert Bosch ，保留所有权利",
	FooterAdditionalLinks_Navigation_ProductSecurity: "产品安全 (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "创新， 专利和许可证",
	FooterAdditionalLinks_Navigation_Logistics: "采购和物流",
	MenuHeader_MyTraining: "我的训练",
	MenuHeader_TrainingServices: "培训目录和日历",
	MenuHeader_OurLocations: "我们的位置",
	MenuHeader_ContactsUs: "联系我们",
	MenuHeader_Logout: "注销",
	MenuHeader_FAQ: "常见问题解答",
	MyProfile_MyBoschId: "我的博世 ID",
	MyProfile_AccountDeletion: "帐户删除",
	MyProfile_Edit: "更改电子邮件或密码",
	MyProfile_Profile: "我的博世帐户",
	MyProfile_EditUserProfile: "编辑用户配置文件",
	BackButton: "返回",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "我们期待您的来电，并将很乐意为您提供帮助。",
	ContactUs_Email_SecurityCodeConfirm_V3: "输入正确的字符，如上框所示。",
	ContactUs_Email_MandatoryNotice_V3: "请填写所有必填字段。",
	ContactUs_Email_SendButton: "发送",
	Breadcrumb_TrainingCenters: "培训中心",
	Training_Centres_Book_Btn: "书籍培训",
	Training_centres_booking_code: "预订代码",
	Training_centers_header_bar_title: "我们的培训中心",
	Training_centers_filter_item: "中心",
	TeamEdit_PersonalAccountToolTop: "启用此功能后，员工可以创建个人帐户并成为企业帐户的一部分。 启用后，您可以向员工发送带有激活代码的电子邮件邀请。 员工注册后需要输入激活代码才能链接到您的企业账户",
	TeamEdit_Update: "启用公司管理员功能后，该员工可以完全访问您企业的帐户。 启用此功能需要用户注销并重新登录网站 (如果用户已登录)。",
	TeamEdit_PersonalAccount: "允许个人博世培训解决方案 Web 帐户",
	TeamEdit_SendInvitation: "发送邀请",
	TeamEdit_Deactivate: "停用",
	TeamEdit_Telephone: "电话",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "手机必须仅包含数字",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "电话必须仅包含数字",
	TeamEdit_Activate: "活动",
	TeamEdit_NotActivated: "非活动",
	TeamEdit_StatusPopup: "如果用户成功创建了个人帐户，则状态将显示为 ‘Active ’ (活动)。 如果状态设置为 ‘非活动 ' ， 这意味着用户没有帐户 - 如果您还没有帐户，您可以向他们发送完成此操作的邀请",
	Training_Catalog_Courses: "课程",
	Training_Catalog_Course: "课程",
	Reset_Filter: "重置过滤器",
	TrainingCatalogue_CourseCatalogue: "培训课程目录",
	CheckoutHeader_CartItemCount_Text: "您的购物车中有 [count] 件商品",
	CheckoutHeader_CartItemsCount_Text: "您的购物车中有 [count] 件商品",
	ShoppingCart_PriceItem: "商品价格",
	ShoppingCartItemParticipants_AddParticipant_Button: "添加参与者",
	ShoppingCartTotalPrice_SubTotalLabel: "小计",
	ShoppingCartTotalPrice_TaxesLabel: "税",
	ShoppingCartTotalPrice_TotalLabel: "共计",
	ShoppingCartTotalPrice_IncludedVATLabel: "包括 [vat_amount] 的 GST",
	AddVoucher_AvailablePaymentMethods: "可用的在线付款方式",
	ShoppingCartItemRow_VenueFeeLabel: "每位参与者的场地费 [fee_amount ]",
	ShoppingCartItemRow_OnlineLabel: "在线",
	AddParticipantsModal_Title: "添加参与者",
	AddParticipantsModal_SelectEmployeeLabel: "添加参与者",
	AddParticipantsModal_SelectWorkshopLabel: "选择 Workshop",
	ShoppingCartTotalPrice_ContinueButtonText: "继续检查",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "未添加与会者！",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "您的购物车中有一门或多门课程，但没有参与者。",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "请添加一名参与者以继续结帐。",
	ShoppingCartItemParticipants_ShowMoreLinkText: "编辑参与者 ([num_participants ])",
	AddParticipantsModal_ParticipantTable_Name: "姓名",
	AddParticipantsModal_ParticipantTable_Email: "电子邮件",
	AddParticipantsModal_ParticipantTable_Action: "行动",
	AddParticipantsModal_ParticipantTable_NoParticipant: "无参与者…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "未在选定与会者上配置电子邮件地址",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "所选与会者的配置文件中没有配置电子邮件地址。 注册参加培训课程时必须提供电子邮件地址。",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "单击“添加电子邮件”继续，或单击“取消”选择其他参与者。",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "添加电子邮件",
	CancelButton: "取消",
	AddParticipantsModal_AddEmployeeButtonText: "添加员工",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "您即将从购物车中删除课程",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "您正在尝试从购物车中删除包含已添加参与者的课程。",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "如果要继续，请单击“继续”，或者单击“取消”。",
	ShoppingCartItemHeader_Participants: "参与者",
	ShoppingCartItemHeader_ParticipantPrice: "每个座位的价格",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "以 [currency] 显示",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "在积分中显示",
	ShoppingCart_CreditsPriceUnit: "积分",
	CheckOutHeader_Step1_Question: "您希望如何支付？",
	CheckOutHeader_Step2_Question: "查看您的订单",
	CheckOutHeader_Step3_Question: "完成您的订单",
	CheckOutHeader_Step4_Question: "您的预订成功！",
	CheckOutHeader_Step_Payment: "付款",
	CheckOutHeader_Step_Review: "回顾",
	CheckOutHeader_Step_Place_Order: "下订单",
	CheckOutRightPanel_Review_Order_Btn: "查看订单",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "下订单",
	CheckoutRightPanelContent_OrderSummary_Title: "订单摘要",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "编辑购物车",
	ShoppingCart_Participants: "参与者",
	CheckOutStepPayment_Payment_Methods_Title: "选择付款方式",
	CheckOutStepPayment_Credit_Title: "贷项或借项",
	CheckOutStepPayment_Wholesaler_Title: "批发商",
	CheckOutStepPayment_ServiceCredit_Title: "培训学分",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "您有 [available_credit] 培训积分",
	CheckOutStepPayment_BoschAccount_Title: "博世帐户",
	Checkout_Second_PaymentMethod_Headline: "付款方式",
	CheckOutStepReview_Edit: "编辑",
	CheckOutStepReview_Terms_Label: "条款和条件以及取消政策",
	CheckOutStepReview_Terms_Text: "我已阅读，理解并同意 [ 条款， 销售条件和取消政策 ]。",
	SelectSecondPaymentMethodModal_Title: "选择二级付款方式",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "支付 [amount ]",
	StepPaymentWholesaler_Title: "选择经销商",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "接受条款和条件",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "请阅读并接受我们的条款和条件以及取消政策，然后再继续。",
	CheckoutDescription_ThankYou: "感谢您提交有关博世汽车培训解决方案的订单！",
	CheckoutDescription_Message: "我们的位和字节已经开始处理您的订单。 同时，请保持发动机怠速运转。",
	CheckoutDesctiption_Text: "我们将通过电子邮件向您发送最终确认函，包括您预订的所有详细信息。",
	WorkshopSelection_RemoveWorkshopWarning_Title: "您即将移除选定的车间",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "通过删除选定的研讨会，所有添加的与会者都将被删除。",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "如果要继续，请单击“继续”，或者单击“取消”停止此操作。",
	CheckOutStepPayment_Insufficient_Credit_Message: "您的积分不足。 您的信用余额是 [Available 信用卡 ] 积分，但您需要 [cart_total_credits] 积分才能继续结算。 请选择其他付款方式或从购物车中删除商品。",
	Training_centers_filter_items: "中心",
	Training_Programs_Filter_Item: "程序",
	Training_Program_Filter_Item: "计划",
	Training_Program_Year: "年份",
	Training_Program_Detail_Title: "培训课程",
	AccountButton_Workshops_MenuItem: "讲习班",
	AccountButton_Wallet_MenuItem: "钱包",
	Header_MyBoschProfile_Header: "MyBosch 配置文件",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "可用",
	AddParticipantsModal_AddOrEditParticipantsTitle: "添加/编辑参与者",
	ShoppingCart_CreditPriceUnit: "信用",
	Common_IncludedVAT: "包括。海温",
	TrainingCatalogue_Length: "长度",
	TrainingCatalogue_Method: "方法",
	Course_Price_Credits: "制作人员",
	Catalogue_Grid_Register_Button: "登记",
	Catalogue_Credit_Tilte: "单击此处查看积分价格",
	Catalogue_Price_Tilte: "单击此处查看欧元价格",
	Catalogue_Grid_Vat: "SST 除外。",
	EventCalendar_Seats: "座位数",
	MenuHeader_Calendar: "日历",
	MenuHeader_Catalog: "目录",
	TableCell_Minutes: "分钟",
	ListFilter_NoResult: "没有结果",
	Course_Description: "课程简介",
	Training_course_detail: "社交媒体基础知识",
	Training_course_detail_add_waiting: "添加到一般等待名单",
	Register_now_btn: "现在注册 ！",
	Register_now_btn_add_event_to_cart: "现在注册 ！",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "查看所有记录",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "查看较少",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "查看所有记录",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "查看较少",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "查看所有记录",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "查看较少",
	TeamCreate_TitleError: "标题为必填项",
	Dashboard_Headerbar_BookCourse: "预订课程",
	Dashboard_Headerbar_ExitProxy: "退出代理",
	Dashboard_Headerbar_EvaluateCourse: "评估课程",
	Dashboard_Headerbar_EvaluateCourse_Note: "请评估本课程以生成您的证书！",
	Dashboard_Headerbar_DownloadCertificate: "下载证书",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "提取",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "提取",
	Dashboard_Headerbar_WelcomeMessage: "欢迎来到您的学习仪表板",
	Dashboard_Headerbar_ProxingAs: "代理为",
	Dashboard_TrainingProgram_Status_Completed: "完全的",
	Dashboard_TrainingProgram_Status_InProgress: "进行中",
	External_SelectWholesalerModal_Title: "添加外部用户访问",
	External_SelectWholesalerModal_Company_Label: "公司",
	External_SelectWholesalerModal_Outlet_Label: "分公司",
	External_SelectWholesalerModal_Company_Placeholder: "请选择",
	External_SelectWholesalerModal_Outlet_Placeholder: "请选择",
	External_SelectWholesalerModal_ApplyButton: "申请",
	External_SelectWholesalerModal_CancelButton: "取消",
	External_RemoveWholesalerModal_ConfirmLabel: "确认",
	External_SelectWholesalerModal_ConfirmMessage: "您确定要删除该批发商吗？",
	External_SelectWholesalerModal_YesButton: "是的",
	External_SelectWholesalerModal_NoButton: "不",
	Training_program_team_member: "队员",
	Training_program_assign_training_program: "分配培训计划",
	Assign_modal_student_confirmation_text1: "您即将为您的学习旅程分配[Training Program Name]。",
	Assign_modal_student_confirmation_text2: "你想继续吗？",
	MyFullCalendar_Jan: "扬",
	MyFullCalendar_Feb: "二月",
	MyFullCalendar_Mar: "三月",
	MyFullCalendar_Apr: "四月",
	MyFullCalendar_May: "可能",
	MyFullCalendar_Jun: "君",
	MyFullCalendar_Jul: "七月",
	MyFullCalendar_Aug: "八月",
	MyFullCalendar_Sep: "九月",
	MyFullCalendar_Oct: "十月",
	MyFullCalendar_Nov: "十一月",
	MyFullCalendar_Dec: "十二月",
	MenuHeader_CourseCatalog: "培训课程目录",
	MenuHeader_Event: "培训日历",
	MenuHeader_Program: "培训计划",
	MenuHeader_Services: "培训服务",
	MenuHeader_Log_out: "登出",
	Filter_Location: "地点",
	TeamEdit_UpgradeToCompanyAdministrator: "升级为公司管理员",
	TeamEdit_PersonalWebAccountStatusIs: "个人网络帐户状态为",
	Header_CreateNewEmployee: "创建新员工",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "没有座位了！",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "所选活动没有可用座位。",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "请选择其他活动或删除已添加的参与者以继续。",
	Common_SuccessModal_Title: "恭喜！",
	TrainingProgramDetails_Merit_StatusText: "优点",
	TrainingProgramDetails_Resit_StatusText: "重新坐",
	EmployeeProfile_UserExistErrorMessage: "该帐户已存在！",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "支付失败。请重试或使用其他付款方式。",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "您已成功停用所选员工！",
	EmployeeProfile_ProfileUpdateSuccess_Title: "得救了！",
	EmployeeProfile_ProfileUpdateSuccess_Message: "你的修改已被保存。",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "无法退出电子学习课程。如果需要更多详细信息，请联系我们。",
	Dashboard_Team_PersonalAccount_Pending: "待办的",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "员工已创建！",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "您的新员工已成功创建。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "此设置使您能够限制员工注册参加培训活动。取消选择此设置时，只有公司管理员才能将员工注册到培训活动。",
	Training_catalog_sort_by: "排序方式",
	CourseDetail_EvaluationRestriction_Title: "无法进行培训评估！",
	CourseDetail_EvaluationRestriction_Message: "培训评估只能由学生本人提交。请指导学员登录博世汽车培训解决方案并完成培训评估。",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "如果学生还没有帐户，可以在团队成员的用户个人资料设置中共享邀请。",
	Breadcrumb_Download_Page: "下载",
	Download_Table_Category_Cell: "类别",
	Download_Table_Title_Cell: "标题",
	Download_File_Filter_Item: "文件",
	Download_File_Filter_Items: "文件",
	Download_Header: "下载区",
	DownloadArea_Button_Download: "下载",
	Popup_Title_Error: "错误",
	Popup_Title_Success: "成功",
	Popup_Title_Info: "信息",
	Popup_Title_Warning: "警告！",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "以下活动注册已存在，并且不会因退出培训计划而自动取消。请单独取消这些。",
	TrainingProgram_PDF_Export: "PDF导出",
	TrainingProgram_PDF_Export_Participant: "参加者：",
	TrainingProgram_PDF_Export_Status: "地位：",
	TrainingProgram_PDF_Export_Progress: "总体进程：",
	TrainingProgram_PDF_Export_TableHeader_Title: "培训名称",
	TrainingProgram_PDF_Export_TableHeader_Status: "地位",
	TrainingProgram_PDF_Export_TableHeader_Grade: "年级",
	TrainingProgram_PDF_Export_TableHeader_Date: "日期",
	AccountButton_CompanyChange_MenuItem: "公司变更",
	SelectCompanyModal_Title: "选择公司",
	CloseButton: "关闭",
	Change_Button: "改变",
	ChangeManagerModal_Content: "选择用户",
	ChangeCompanyModal_HeadOffice: "（总公司）",
	SelectCompanyModal_Content: "请选择公司登录...",
	AlertSuccess_Title: "成功",
	CheckOutStepPayment_SecondaryPayment_Title: "选择辅助付款方式",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "添加辅助付款方式",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "请在下面添加辅助付款方式或在上面选择其他付款方式。",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "您的帐户中没有足够的服务积分来支付您的购物车商品。要继续使用所选的付款方式，您必须获得更多服务积分或使用其他付款方式来支付差额。差额为 [difference_amount]（不含 GST）。",
	ShoppingCartTotalPrice_TotalInCredit: "总价（积分）",
	ShoppingCartTotalPrice_YourAvaliableCredit: "您的可用积分",
	ShoppingCartTotalPrice_YourDifference: "不同之处",
	ShoppingCartTotalPrice_SubtotalExTax: "不包括小计消费税",
	ShoppingCartTotalPrice_SubtotalInTax: "小计包括消费税",
	CheckOutStepPayment_Second_Payment_Methods_Title: "二次付款方式",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "编辑付款方式",
	Checkout_Second_PaymentMethod: "第二种付款方式",
	Breadcrumb_TrainingCourseDetails: "培训课程详情",
	DownloadArea_FailedToDownload_ErrorMessage: "文档下载失败。请重试或联系您的公司管理员寻求帮助。",
	DownloadArea_FileNotAvailable_ErrorMessage: "下载文件不可用。请重试或联系您的公司管理员寻求帮助。",
	DownloadArea_FileAccessForbidden_ErrorMessage: "禁止下载文件。请重试或联系您的公司管理员寻求帮助。",
	SSO_Redirecting_Message: "您正在重定向至 BATS 门户...",
	SSO_Redirecting_ParameterErrorMessage: "未指定国家/地区和/或语言。 请重试或联系您的公司管理员寻求帮助。",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "不接受注册请求。",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "这可能是因为其中一门或多门课程处于课程注册阈值天内。 如果您有任何疑问或要求，请使用“联系我们”区域。",
	TimeSlotPicker_DateFormat_Title: "日期格式",
	UpdateAccountSettingsConfirmationModal_Title: "您即将更改日期格式！",
	UpdateAccountSettingsConfirmationModal_Message1: "日期格式将根据您的选择显示，并且可以随时更改。",
	UpdateAccountSettingsConfirmationModal_Message2: "你想继续吗？",
	ProfileSummary_Settings_Title: "设置",
	UpdateAccountSettings_DateFormat_Title: "日期格式设置",
	UpdateAccountSettings_DateFormat_ErrorMessage: "更新日期格式失败。",
	UpdateAccountSettings_DateFormat_SuccessMessage: "日期格式设置已成功更新。",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "下载证书",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "培训项目证书",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "下载失败。 请重试。 如果问题仍然存在，请通过联系表联系我们。",
	CourseWithdrawalModal_PendingWithdraw_Title: "已超过提取阈值",
	CourseWithdrawalModal_PendingWithdraw_Message1: "退出本课程的阈值已超过，需要我们的培训管理部门手动处理。",
	CourseWithdrawalModal_PendingWithdraw_Message2: "最终确认将通过电子邮件发送。",
	CourseWithdrawalModal_PendingWithdraw_Message3: "请参阅我们的 [ 取消政策 ] 了解更多详情。",
	TrainingCourse_Course_NotAvailable_Title: "培训课程不可用",
	TrainingCourse_Course_NotAvailable_Message: "培训课程不可用。 请重试或联系您的公司管理员以获得进一步帮助。",
	TrainingCourse_NotAvailable_Message_1: "您正在尝试查看不可用的培训课程。",
	TrainingCourse_NotAvailable_Message_2: "请返回我们的 [ 培训目录 ] 查找所有可用课程。",
	LanguageSelection_SelectALanguage: "选择语言",
	LanguageSelection_SelectYourCountry: "选择您所在的国家 / 地区",
	Checkout_PaymentMethod_Headline: "付款方式",
	Download_Categories_Catalogue: "目录",
	Download_Categories_Calendar: "日历",
	Download_Categories_General: "一般信息",
	Download_Categories_Information: "信息",
	Download_Categories_Userguides: "用户指南",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "取消政策",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "取消",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "继续",
	Duration_Seconds: "秒",
	Duration_Day: "日",
	Duration_Hour: "小时",
	Duration_Minute: "分钟",
	Duration_Second: "第二",
	EventCalendar_ExternalLink_Tooltips: "通过[外部注册名称]购买",
	EventCalendar_ExternalLink_Seat_NA: "不适用",
	EventCalendar_ExternalLink_Cancel: "取消",
	EventCalendar_ExternalLink_Continue: "继续",
	EventCalendar_ExternalLinkModal_Title: "你即将被重定向...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "所选活动通过[外部注册名称]进行销售。",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "继续会将您重定向到外部网站。",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "是否要继续？",
	Team_ContactPerson_FirstName_Tooltips: "学生姓名只能由博世培训管理团队修改。请通过本网站上的联系表格与我们联系以获得进一步的支持。",
	Team_ContactPerson_LastName_Tooltips: "学生姓名只能由博世培训管理团队修改。请通过本网站上的联系表格与我们联系以获得进一步的支持。",
	Withdraw_ExternalLink_Cancel: "取消",
	Withdraw_ExternalLink_Confirm: "确认",
	Withdraw_ExternalLinkModal_Title: "您即将被重定向",
	Withdraw_ExternalLinkModal_Message: "本课程的提款由外部提供商处理。",
	Withdraw_ExternalLinkModal_Confirmation: "单击“确认”将打开一个新选项卡并将您重定向到外部网站。",
	Button_AddToWaitingList_Tooltips: "加入等候名单",
	Button_AddToCart_Tooltips: "加入购物车",
	Button_TrainingCourseDetail_Tooltips: "查看详情",
	Button_TrainingProgramDetail_Tooltips: "查看详情",
	Button_AssignTrainingProgram_Tooltips: "分配培训计划",
	Button_DeleteFromCart_Tooltips: "从购物车中删除",
	Button_RemoveParticipant_Tooltips: "删除参与者",
	Button_DownloadBillingTransaction_Tooltips: "下载",
	Button_RemoveExternal_Tooltips: "删除"
};