import { FC } from "react";
import { Grid, Image, Card, Label } from "semantic-ui-react";
import "./CustomImageCard.css";
import Boschicon from "../BoschIcon/Boschicon";
import { useMediaPredicate } from "react-media-hook";
import { useHistory } from "react-router-dom";
interface CustomImageCardProps {
  cardImageBig: string;
  miniHeaderBig: string;
  linkTextBig: linkTextProps;

  cardImageSmall?: string;
  miniHeaderSmall?: string;
  linkTextSmall?: linkTextProps;
  cardStyle: string;
  hideSmallCard?: boolean;

  cardImageThree?: string;
  miniHeaderThree?: string;
  linkTextThree?: linkTextProps;

  cardImageFour?: string;
  miniHeaderFour?: string;
  linkTextFour?: linkTextProps;

  className?: string;
}

interface linkTextProps {
  label: string;
  link?: string;
}
// interface newscommonlinksprops {
//   label: string;
//   link:string;
// }
const CustomImageCard: FC<CustomImageCardProps> = (ImageCardProps) => {
  const screenSmallerThan768px = useMediaPredicate("(max-width: 768px)");
  const screenSmallerThan500px = useMediaPredicate("(max-width: 500px)");

  const setWidth50Percent = screenSmallerThan768px ? true : false;
  const setWidth100Percent = screenSmallerThan500px ? true : false;

  const history = useHistory();
  const redirectToPage = (url: string | undefined) => {
    if (url) history.push(url);
  };

  return (
    <>
      {ImageCardProps.cardStyle === "bigFirst" && (
        <Grid stackable doubling style={{ marginBottom: "1%" }}>
          <Card
            style={{
              height: "fit-content",
              width: setWidth100Percent
                ? "100%"
                : setWidth50Percent
                  ? "48.5%"
                  : "57%",
            }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
          {!ImageCardProps.hideSmallCard && (
            <Card
              style={{
                height: "fit-content",
                width: setWidth100Percent
                  ? "100%"
                  : setWidth50Percent
                    ? "48.5%"
                    : "40%",
                marginLeft: setWidth100Percent ? "0%" : "3%",
              }}
              className="custom-image-card story-image-card"
              onClick={() => {
                redirectToPage(ImageCardProps.linkTextSmall?.link);
              }}
            >
              <Image src={ImageCardProps.cardImageSmall} />
              <Card.Content extra>
                <div className="custom-image-card-extension inline-arrow">
                  <Label className="small-header-label">
                    {ImageCardProps.miniHeaderSmall}
                  </Label>
                  <div style={{ fontWeight: 700 }}>
                    <a
                      className="custom-image-card-extension-link"
                      href="javascript:void(0)"
                    >
                      {ImageCardProps.linkTextSmall?.label}
                      <Boschicon
                        name="bosch-forward-right"
                        style={{
                          verticalAlign: "sub",
                          fontSize: "2rem",
                          textDecoration: "none",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </Card.Content>
            </Card>
          )}
        </Grid>
      )}

      {ImageCardProps.cardStyle === "smallFirst" && (
        <Grid stackable doubling>
          <Card
            style={{
              height: "fit-content",
              width: setWidth100Percent
                ? "100%"
                : setWidth50Percent
                  ? "48.5%"
                  : "40%",
            }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextSmall?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageSmall} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderSmall}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextSmall?.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
          <Card
            style={{
              height: "fit-content",
              width: setWidth100Percent
                ? "100%"
                : setWidth50Percent
                  ? "48.5%"
                  : "57%",
              marginLeft: setWidth100Percent ? "0%" : "3%",
            }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid>
      )}
      {ImageCardProps.cardStyle === "fullWidthOneCard" && (
        <Grid stackable doubling style={{ width: "100%" }}>
          <Card
            style={{ height: "fit-content", width: "100%" }}
            className="custom-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid>
      )}
      {ImageCardProps.cardStyle === "fullWidthOneCardv2" && (
        <Grid doubling className="full-width-one-card-v2" style={{ width: "100%" }}>
          <Card
            style={{ height: "fit-content", width: "100%" }}
            className="custom-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid>
      )}
      {ImageCardProps.cardStyle === "sameSize" && (
        <Grid stackable doubling style={{ marginBottom: "1%", width: "100%" }}>
          <Card
            style={{ height: "100%", width: "48.5%" }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>

          <Card
            style={{ height: "100%", width: "48.5%", marginLeft: "3%" }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextSmall?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageSmall} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderSmall}
                </Label>
                <div style={{ fontWeight: 700 }}>
                  <a
                    className="custom-image-card-extension-link"
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextSmall?.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{
                        verticalAlign: "sub",
                        fontSize: "2rem",
                        textDecoration: "none",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid>
      )}

      {ImageCardProps.cardStyle === "sameSizeStory" && (
        <Grid stackable doubling style={{ marginBottom: "1%" }}>
          <Card
            style={{ height: "100%", width: "48.5%" }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextSmall?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageBig} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderSmall}
                </Label>
                <div>
                  <a
                    className="custom-image-card-extension-link"
                    style={{ fontWeight: 700, fontSize: "1.25rem" }}
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextSmall?.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{ verticalAlign: "sub", fontSize: "2rem" }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
          <Card
            style={{ height: "100%", width: "48.5%", marginLeft: "3%" }}
            className="custom-image-card story-image-card"
            onClick={() => {
              redirectToPage(ImageCardProps.linkTextBig?.link);
            }}
          >
            <Image src={ImageCardProps.cardImageSmall} />
            <Card.Content extra>
              <div className="custom-image-card-extension inline-arrow">
                <Label className="small-header-label">
                  {ImageCardProps.miniHeaderBig}
                </Label>
                <div>
                  <a
                    className="custom-image-card-extension-link"
                    style={{ fontWeight: 700, fontSize: "1.25rem" }}
                    href="javascript:void(0)"
                  >
                    {ImageCardProps.linkTextBig.label}
                    <Boschicon
                      name="bosch-forward-right"
                      style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                    />
                  </a>
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid>
      )}
      {ImageCardProps.cardStyle === "sameSizeThree" && (
        <Grid container stackable doubling className={ImageCardProps.className + " sameSizeThree-container"}>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextSmall?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageBig} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderSmall}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextSmall?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextBig?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageSmall} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderBig}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextBig.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextThree?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageThree} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderThree}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextThree?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {ImageCardProps.cardStyle === "sameSizeThreeHome" && (
        <Grid container stackable doubling id="image-card-wrapper" className={ImageCardProps.className + " sameSizeThree-container"}>
          <Grid.Row columns={3}>
            <Grid.Column className="first-col">
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextSmall?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageBig} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderSmall}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextSmall?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column className="second-col">
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextBig?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageSmall} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderBig}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextBig.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column className="third-col">
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextThree?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageThree} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderThree}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextThree?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}


      {/* {ImageCardProps.style === "oneBigTwoSmall" && (
        <Grid container stackable doubling className="oneBigTwoSmall-container">
          <Grid.Row>
            <Card
              style={{ width: '200%' }}
              className="custom-image-card"
              onClick={() => {
                redirectToPage(ImageCardProps.linkTextSmall?.link);
              }}
            >
              <Image src={ImageCardProps.cardImageBig} />
              <Card.Content extra>
                <div className="custom-image-card-extension inline-arrow">
                  <Label className="small-header-label">
                    {ImageCardProps.miniHeaderSmall}
                  </Label>
                  <div>
                    <a
                      className="custom-image-card-extension-link"
                      style={{ fontWeight: 700 }}
                      href="javascript:void(0)"
                    >
                      {ImageCardProps.linkTextSmall?.label}
                      <Boschicon
                        name="bosch-forward-right"
                        style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                      />
                    </a>
                  </div>
                </div>
              </Card.Content>
            </Card>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card
                style={{ width: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextBig?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageSmall} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderBig}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextBig.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card
                style={{ width: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextThree?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageThree} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderThree}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextThree?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )} */}
      {ImageCardProps.cardStyle === "sameSizeFour" && (
        <Grid container doubling className="sameSizeFour-container">
          <Grid.Row>
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextSmall?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageBig} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderSmall}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextSmall?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextBig?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageSmall} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderBig}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextBig.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextThree?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageThree} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderThree}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextThree?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <Card
                style={{ height: "100%" }}
                className="custom-image-card"
                onClick={() => {
                  redirectToPage(ImageCardProps.linkTextFour?.link);
                }}
              >
                <Image src={ImageCardProps.cardImageFour} />
                <Card.Content extra>
                  <div className="custom-image-card-extension inline-arrow">
                    <Label className="small-header-label">
                      {ImageCardProps.miniHeaderFour}
                    </Label>
                    <div>
                      <a
                        className="custom-image-card-extension-link"
                        style={{ fontWeight: 700 }}
                        href="javascript:void(0)"
                      >
                        {ImageCardProps.linkTextFour?.label}
                        <Boschicon
                          name="bosch-forward-right"
                          style={{ verticalAlign: "sub", fontSize: "1.1rem" }}
                        />
                      </a>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

export default CustomImageCard;
