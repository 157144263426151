import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "./LabelledLink.css";
import courses from "../../assets/pdf/courses.pdf";
interface LabelledLinkProps {
  iconLeft: boolean,
  style?: Object,
  to: string,
  content: string,
  icon: ReactNode,
  scrollToTopOnClick?:boolean,
  minimizeCard?:any
  // pdf?:boolean
}
const LabelledLink:FC<LabelledLinkProps> = (props) => {
  const scrollToTopAndMinimizeMenu = () => {
    window.scrollTo({
      top: 0
    });
    if(props.minimizeCard)
    props.minimizeCard();


  }
  // if(props.pdf ){
  //   return (<a href={courses} target="_blank" className="labelled-link"><span>{props.content}</span><Icon className="labelled-link-icon">{props.icon}</Icon></a>)
  // }
  if (props.iconLeft) {
    return(
      <Link style={props.style} to={props.to} className="labelled-link"><Icon className="labelled-link-icon">{props.icon}</Icon><span>{props.content}</span></Link>
    );
  }
  if(props.scrollToTopOnClick){
    return(
      <Link style={props.style} to={props.to} className="labelled-link" onClick={()=>{scrollToTopAndMinimizeMenu()}}><span >{props.content}</span><Icon className="labelled-link-icon">{props.icon}</Icon></Link>
    )
  }
  return(
    <Link style={props.style} to={props.to} className="labelled-link"><span>{props.content}</span><Icon className="labelled-link-icon">{props.icon}</Icon></Link>
  );
}

export default LabelledLink;