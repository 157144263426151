import { FC, Fragment, useState } from "react";
import { Button } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";
import { useTranslation } from "react-i18next";
import RDAlertCustomTitle from "../RDComponents/RDAlertCustomTitle";

interface AddEventToCartComponentProps {
  eventInfo: any;
  isDisabled?: boolean;
  iconStyle?: any;
  buttonStyle?: any;
  addedToCartSuccess?: Function;
  isRegister?: boolean;
}

const ExternalRegistrationComponent: FC<AddEventToCartComponentProps> = (
  props
) => { 

  const { t } = useTranslation();
  const [showExternalRegistrationModal, setShowExternalRegistrationModal] =
    useState<boolean>(false);

  const handleExternalLinkClick = () => {
    setShowExternalRegistrationModal(true);
  };

  const redirectExternalLink = (url: string) => {
    setShowExternalRegistrationModal(false);
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <Button
        style={props.buttonStyle ?? null}
        disabled={props.isDisabled}
        onClick={handleExternalLinkClick}
        icon={
          <Boschicon
            name="bosch-ic-externallink"
            style={props.iconStyle ?? null}
          />
        }
        title={t("EventCalendar_ExternalLink_Tooltips").replace(
          "externalRegistrationName",
          props?.eventInfo?.externalRegistrationName
        )}
      />

      {showExternalRegistrationModal && (
        <RDAlertCustomTitle
          size="tiny"
          isOpen={showExternalRegistrationModal}
          type={"warning"}
          title={t("EventCalendar_ExternalLinkModal_Title")}
          messages={[
            t("EventCalendar_ExternalLinkModal_ExternalRegistrationName").replace(
              "externalRegistrationName",
              props?.eventInfo?.externalRegistrationName
            ),
            t("EventCalendar_ExternalLinkModal_ContinueRedirectMessage"),
            t("EventCalendar_ExternalLinkModal_ContinueRedirectConfirm"),
          ]}
          cancelButtonText={t("EventCalendar_ExternalLink_Cancel")}
          onCancel={() => setShowExternalRegistrationModal(false)}
          acceptButtonText={t("EventCalendar_ExternalLink_Continue")}
          onAccept={() => redirectExternalLink(props?.eventInfo?.externalRegistrationLink)}
        />
      )}
    </Fragment>
  );
};

export default ExternalRegistrationComponent;
